<div class="help-bar">
    <div class='logo pl-4 ml-4'>
        <div>
            <div class="pt-4 text-left active">ACCOUNT: {{loginInfoResponse.userName}}</div>
            <div class="p-0 m-0 pt-3">Last Login</div>
            <div class="time-disp mt-2">{{lastLogin | date:'dd MMM yyyy hh:mm a'}}</div>
        </div>
    </div>
    <div>
        <ul class="nav py-3 px-4 d-flex align-items-start flex-column flex-nowrap w-100">
            <li class="d-flex align-items-center flex-row flex-nowrap user-profile w-100 mb-4" (click)="openProfileSettingDialog();closePanel();">
                <!--  <i class="fa fa-user mr-3"></i> -->
                <img src='assets/core/layout/right_panel/user.svg' alt="Settings">
                <a class="menu-text">Profile Settings</a>
            </li>
            <li class="d-flex align-items-center flex-row flex-nowrap user-profile w-100 mb-4" (click)="openAdminDialog();closePanel();"
                *ngIf="hasGroupRole">
                <img style="height: 21px;" src='assets/core/layout/right_panel/admin.svg' alt="Admin">
                <a class="menu-text">Admin</a>
            </li>
            <li class="d-flex align-items-center flex-row flex-nowrap user-profile w-100 mb-4" (click)="openSuperAdminDialog();closePanel();"
                *ngIf="isSuperAdminRole">
                <img style="height: 21px;" src='assets/core/layout/right_panel/admin.svg' alt="Org Admin">
                <a class="menu-text">Org Admin</a>
            </li>
            <li class="w-100  mb-4" *ngIf="isQMAAdmin">
                <div [class.active]='activeMenuArray[7]' (click)="onMenuClick(7, true)" class="nav d-flex flex-column">
                    <div class="image-holder">
                        <img class="qma-admin" style="height: 21px;" src='assets/core/layout/right_panel/qma_admin.svg' alt="">
                        <a class="menu-text qma-admin">QMA Admin</a>
                        <em class="fa qma-admin" [ngClass]="activeMenuArray[7] ? 'fa-chevron-up': 'fa-chevron-down'"></em>
                    </div>
                </div>
                <div>
                    <ul *ngIf="activeMenuArray[7]" [@panelInOut] class="d-flex flex-column flex-nowrap subMenu">
                        <li *ngIf="isUserAdmin"><a (click)="openQMAAdmin();closePanel();">User Admin</a><span class="pull-right"></span></li>
                        <li *ngIf="isGrpSetupAdmin"><a (click)="openQMAdmin();closePanel();">Group Setup</a><span class="pull-right"></span></li>
                        <li *ngIf="canSendNotification"><a (click)="sendEmailNotification();closePanel();">Send User Notification</a><span class="pull-right"></span></li>
                        <li *ngIf="isDBAdmin || isProdSupport"><a (click)="openDatabaseAdmin();closePanel();">Config Administration</a><span class="pull-right"></span></li>
                        <li *ngIf="isMailBoxDLMapping"><a (click)="openMailBoxDLMapping();closePanel();">Mailbox Onboarding</a><span class="pull-right"></span></li>
                        <li *ngIf="isUserGroupAssignment"><a (click)="openUserGroupAssignment();closePanel();">User Group Assignment</a><span class="pull-right"></span></li>
						<li *ngIf="displayAdminSetup"><a (click)="openAdminSetup();closePanel();">Bulk Admin Setup</a><span class="pull-right"></span></li>
                    </ul>
                </div>
            </li>
            <li class="d-flex align-items-center flex-row flex-nowrap user-profile w-100 mb-4" (click)="logout()">
                <!--  <i class="fa fa-user mr-3"></i> -->
                <img style="height: 21px;" src='assets/core/layout/right_panel/logout.svg' alt="Logout">
                <a class="menu-text">Logout</a>
            </li>
        </ul>
    </div>
</div>
<!-- C153176-4618 Responsive Design-->
<p-dialog (onShow)="initAutoResponseContent()" [(visible)]="display" [modal]="true" [responsive]="false" [maximizable]="false"
    [appendTo]="'body'" [styleClass]="(inquiryFlag == 4)?'inquiryRedisgn':'contextMenuModal groupModal'" [closable]="false" [dismissableMask]="true"
    [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'height':'830px','max-width':'1334px', 'width': '100%','overflow-x': 'scroll'}" [resizable]="false">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between" style="padding: 20px 27px 0px 24px;">
            <span class="gfid-title" style="font-size: 20px;width: 12em;">GROUP ADMINISTRATION  </span>
            <!-- /* C153176-4445 inquiry rule redesign */ -->
            <!-- <span *ngIf="showGroupName" style="font-size: 13px;color: gray;font-family: 'interstateLight';width: 71em;">{{selectedAdminGroupName}}</span> -->
            <div class="d-flex flex-row align-items-start">
                <label for="from" style="margin-top: 8px;margin-right: 40px;" class="flex-grow">Group Name: </label>
                <p-dropdown [styleClass]="'prime-dd-override group-details-width'" class="drop-down group-details-inp drop-admin" optionLabel="name"
                    [(ngModel)]="selectedGroup" (onChange)="groupChangesForInit()" [options]="selectedGroupList" [filter]="true" [virtualScroll]="true" itemSize="5"  [showTransitionOptions]="'0ms'" [hideTransitionOptions]="'0ms'">
                </p-dropdown>
            </div>

            <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1">
                        <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
                        <h6>CLOSE</h6>
                    </button>
        </div>
        <div *ngIf="inquiryFlag == 4">
            <div class="d-flex flex-row mt-4" style="width:50%;float:left;">
                <span class="mr-9" style="width:50%;">
                                    <div class="back-btn" (click)="onBack()">
                                        <span style="text-decoration: none; display: inline-block;">
                                            <img class="inqArrow" src="assets/dashboard/back-arrow.svg" alt="Back">
                                        </span>
                <span style="margin-left:3%">BACK TO SETTINGS</span>
            </div>
            </span>
        </div>
        </div>
    </p-header>

    <div class="gfid-body admin-gfid-padding">
        <!-- Loader when hit the save/submit button -->
        <ng-template #customLoadingTemplate>
            <div class="custom-class-loader">
                <div class="spinner-container">
                    <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                        <circle cx="170" cy="170" r="160" stroke="#021e48" />
                        <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                        <circle cx="170" cy="170" r="110" stroke="#021e48" />
                        <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
                    </svg>
                </div>
            </div>
        </ng-template>
        <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>

        <!-- <form #gfcForm="ngForm" (ngSubmit)="gfcForm.valid && validateAndSearch(gfcForm)"> -->
        <div class="d-flex flex-row pr-4">
            <!-- /* C153176-4445 inquiry rule redesign start */ -->
            <!-- height value change C153176-5900 Dropdown view is cutting off in Group Admin screen-->
            <p-tabView class="adminview" #adminTabs (onChange)="onTabChange($event)" [styleClass]="'admin-tabview'" [scrollable]="true"
                [ngClass]="{'redesign': inquiryFlag== 4}" [style]="{'height':'935px','min-width':'70em','max-width':'90em', 'margin-left': '24px', overflow: 'hidden', 'position': 'relative'}"
                [activeIndex]="inquiryFlag">

                <!-- <div [style]="{'background': '#ffffff', 'border': '1px solid #002d72'}" class="d-flex flex-column"> -->

                <p-tabPanel header="GROUP DETAILS" class="tab-heading">
					<div style="margin-top: 20px;">
                        <span style="color: red; margin-left: 2.3em; font-style: italic">Please select a Group Manager
                            at an L7 or L8 level to ensure that all reporting is captured accurately for this DL.
                        </span>
                    <div class="d-flex flex-row mt-4 mrg-left-4em mrg-lef mrg-left-7em">
                        <div class="w-20 d-flex flex-row align-items-start ">
                            <label for="from" class="flex-grow">Group Manager: </label>
                            <span style="width:388px;">
                                    <input type="text" style="width: 92% !important;" [(ngModel)]="groupManager" placeholder="Search by SOEID" class="w-50 t-box inp-txt-mng group-details-input-mng"
                                    />
                                    <span *ngIf="validationFlag" style="color: red; font-size: 14px; padding-top:10px; margin-left: 21px;  font-style: italic">{{validationMsg}} The user soeid entered is not valid. </span>
                                    <span *ngIf="this.isLookupClicked && showBlankValidation()" style="color: red;font-size: 14px;padding-top:10px; margin-left: 21px;  font-style: italic">{{blankStringValidationMsg}} </span>
                                </span>
                            <span style="padding-top:10px;">
                                <button style="width: 86px !important;opacity:1" type="button" class="close qma-text grp-details-mng-lookup"
                                    (click)="callLookupService(true)">
                                    <img class="qma-text" style="opacity:1" src="assets/Inbox/search_icon_selected_u42.svg" alt="Lookup">
                                    <h6 style="font-size:14px;padding-left:3px;">LOOKUP</h6>
                                </button>
                            </span>
                        </div>
                        <div style="padding-left:10em;" class="d-flex flex-row">
                            <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_group_details)" alt="">
                            <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_group_details [baseZIndex]="9999">
                                <div class="d-flex flex-row mb-0.3">
                                    <span>
                                            <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                        </span>
                                    <span class="info-content">{{infoGroup}}</span>
                                </div>
                            </p-overlayPanel>
                        </div>
						</div>
                        <div *ngIf="isL6AndBelow" class="custom-control custom-checkbox checkboxDiv">
                            <input type="checkbox" class="overrideCheckbox" (change)="overrideChange($event)"
                                [checked]="overrideCheckbox">
                            <label class="overrideCheckboxLabel" for="overrideCheckbox">I understand that I have
                                selected a Group Manager at an L6 level or above, and I am aware that this selection may
                                impact reporting and published metrics for this DL.</label>
                        </div>
                    </div>
                    <div class="hierarchyBox" *ngIf="getMgtData()">
                        <div class="mgtHeader"> 
                            <label class="mgtHeaderText">Managed Hierarchy</label>
                        </div>
                        <div class="mgtBox">
                            <div class="boxLabel1">Level 7 Hierarchy:</div>
                            <div class="boxTextLabel">{{this.managedSegmentData.psL7}}</div>
                        </div>
                        <div class="upIcon">
                            <em aria-hidden="true" class="lmnicon lmnicon-up lmnicon-18 lmn-mr-4"></em>
                        </div>
                        <div class="mgtBox">
                            <div class="boxLabel1">Level 6 Hierarchy:</div>
                            <div class="boxTextLabel">{{this.managedSegmentData.psL6}}</div>
                        </div>
                        <div class="upIcon">
                            <em aria-hidden="true" class="lmnicon lmnicon-up lmnicon-18 lmn-mr-4"></em>
                        </div>
                        <div class="mgtBox">
                            <div class="boxLabel1">Level 5 Hierarchy:</div>
                            <div class="boxTextLabel">{{this.managedSegmentData.psL5}}</div>
                        </div>
                        <div class="upIcon">
                            <em aria-hidden="true" class="lmnicon lmnicon-up lmnicon-18 lmn-mr-4"></em>
                        </div>
                        <div class="mgtBoxBSeg">
                            <div class="boxLabel1">Business Segment:</div>
                            <div class="boxTextLabel">{{this.managedSegmentData.msL6}}</div>
                        </div>
                    </div>

                </p-tabPanel>
                <!-- /* C153176-4445 inquiry rule redesign end */ -->
                <p-tabPanel header="USER ENTITLEMENTS" class="tab-heading">
                    <div class="d-flex flex-row" style="margin: 30px 0 0 16px">
                        <div class="d-flex flex-row align-items-center input-holder my-2">
                            <div (click)="onEntitlementUserChange(0)" [ngClass]="['mr-3']" [class.isInactive]="!tabArray[0]">
                                <ins>Current User</ins>
                            </div>
                            <div (click)="onEntitlementUserChange(1)" [ngClass]="['mr-3']" [class.isInactive]="!tabArray[1]">
                                <ins>ADD NEW USER</ins>
                            </div>
                            <div style="padding-left:26.9em;" class="d-flex flex-row">
                                <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_user_entitlement)"
                                    (mouseout)="info_user_entitlement.hide()" alt="Info">
                                <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_user_entitlement [baseZIndex]="9999">
                                    <div class="d-flex flex-row mb-0.3">
                                        <span>
                                            <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                        </span>
                                        <span class="info-content">{{infoEntitlement}}</span>
                                    </div>
                                </p-overlayPanel>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!tabArray[1]" class="firstView" style="margin-left: 28px;">
                        <div *ngIf="entitleDelAndGropFlag" class="d-flex flex-row mt-4">
                            <label for="from" class="flex-grow">USERS</label>
                        </div>
                        <div [hidden]="entitleDelAndGropFlag">
                            <div class="d-flex flex-row mt-4">
                                <div class="d-flex flex-row flex-wrap">
                                    <div style="margin-right: 39px">
                                        <label for="from" class="flex-grow">{{entitleSelUser}} USERS SELECTED</label>
                                    </div>
                                    <div style="margin-right:16px">
                                        <!-- Jira:4703 : Confirmation Dialog before delete -->
                                        <span class="add-new-btn" (click)="setConfirmation('userEntitlement')">
                                            <span style="text-decoration: none; display: inline-block;">
                                                <img style="width:11px;height:11px;" class="qma-text" src="assets/context/delete-button.svg" alt="Delete">
                                            </span>Delete
                                        </span>
                                    </div>
                                    <div>
                                        <span class="add-new-btn" (click)="showAddtoGrpPanel($event, addToGrp)">
                                            <span style="text-decoration: none; display: inline-block;">
                                                <span style="text-decoration: none; display: inline-block;">
                                                    <img style="width:11px;height:11px;" class="qma-text" src="assets/context/add-button.svg" alt="Add">
                                                </span>
                                        </span>
                                        Add to group
                                        </span>
                                    </div>

                                    <div style="padding-left:24.3em;position:relative;" class="d-flex flex-row">
                                        <p-overlayPanel [styleClass]="'addtogroup-user-entitlement'" [appendTo]="'body'" #addToGrp [baseZIndex]="9999">
                                            <div class="d-flex flex-row mb-0.3">
                                                <!-- <p-multiSelect [defaultLabel]="'Add to group'" [options]="entGrpName" [(ngModel)]="selectedEntGrpName" [styleClass]="'preference-multiselect user-entitlement-override'"
                                                    [style]="{width:'386px'}" [showToggleAll]="false"></p-multiSelect> -->
                                                <app-checkbox-list [placeholder]="'Add to group'" [name]="'groups'" [items]="entGrpName" [listStyle]="{'height':'158px'}"
                                                    [scroll]="scroll" (onItemSelect)="getAssignedGroups($event)">
                                                </app-checkbox-list>
                                            </div>
                                            <div class="d-flex flex-row mb-0.3" style="text-align:center;padding:5px;margin-top:20px;">
                                                <span class="flex-row flex-nowrap align-items-end w-135" style="width:100%;">
                                                    <button type="submit" class="btn search-btn loadChart" (click)="entitleAggGroup($event,addToGrp)">Submit</button>
                                                </span>
                                            </div>
                                        </p-overlayPanel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row mt-4">
                            <!-- Jira : C153176-4323 Issue- Multiple delete actions needed to delete the user from Group Admin. -->
                            <!-- Jira : C153176-5182 Group Admin - issue- Design change -->
                            <ag-grid-angular style="width: 54.5%; height: 36.7em;" class="ag-theme-balham" id="userEntitlement" [rowData]="entitleData"
                                [columnDefs]="entitleColumns" [stopEditingWhenGridLosesFocus]="true" [defaultColDef]="entitleColDef"
                                [singleClickEdit]="true" (selectionChanged)="onSelectionChanged($event)" (cellEditingStopped)="onCellEditingStopped($event)"
                                (gridReady)="onEntitleGridReady($event)" [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="true"
                                [modules]="modules">
                            </ag-grid-angular>
                        </div>
                    </div>
                    <div *ngIf="!tabArray[0]" class="secondView" style="margin-left: 16px; margin-top: 31px;">
                        <div class="d-flex flex-row">
                            <div class="col-8">
                                <div class="d-flex flex-row mt-6">
                                    <label for="from" class="flex-grow">SEARCH by SOEID</label>
                                </div>
                                <div class="d-flex flex-row mt-0.5">
                                    <input type="text" [(ngModel)]="newUserSoeid" (keydown)="keyDownFunction($event)" placeholder="Search..." class="w-50 search-box"
                                    />
                                </div>
                                <!-- /*C153176-5006 show and hide error message if user id is available or not start*/ -->
                                <div style="margin-top: 1em !important; font-family: Interstate"><span *ngIf="userEntitlementFlag" style="color: red;">{{entitlementValidationMsg}} </span></div>
                                <div style="margin-top: 1em !important; font-family: Interstate">
                                    <span *ngIf="newUserSoeIdValidationFlag" style="color: red;">{{blankStringValidationMsg}} </span></div>
                                <!-- /*C153176-5006 error message if user id is not available end */ -->
                                <div [hidden]="newUserGridFlag" class="d-flex flex-row mt-4">
                                    <!-- Jira : C153176-5182 Group Admin - issue- Design change -->
                                    <!-- <ag-grid-angular #agAddNewData style="width: 90.6%; height: 33em;" class="ag-theme-balham" id="userEntitlementAddUser" [rowData]="addNewUserData" [singleClickEdit]="true" [suppressHorizontalScroll] ="true"
                                        [columnDefs]="addNewUserColumns" [defaultColDef]="defaultColDef" (gridReady)="onAddNewDataGridReady($event)" [rowHeight] ="43" [headerHeight] ="44" [stopEditingWhenGridLosesFocus]="true">
                                    </ag-grid-angular> -->
                                    <ag-grid-angular #agAddNewData style="width: 90%; height: 36.7em;" class="ag-theme-balham" id="userEntitlementAddUser" [rowData]="addNewUserData"
                                        [columnDefs]="addNewUserColumns" [stopEditingWhenGridLosesFocus]="true" [defaultColDef]="entitleColDef"
                                        [singleClickEdit]="true" (selectionChanged)="onSelectionChanged($event)" (cellEditingStopped)="onCellEditingStopped($event)"
                                        (gridReady)="onAddNewDataGridReady($event)" [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="true"
                                        [modules]="modules">
                                    </ag-grid-angular>
                                </div>
                            </div>
                            <div class="col-4" style="margin-left:-10px">
                                <label style="font-size:16px;" for="from" class="flex-grow">BULK UPLOAD</label>
                                <div class="d-flex flex-row preference-radio ">
                                    <p-radioButton class="radioBulkUpload" value="CSV" label="CSV" [(ngModel)]="selectedFormat"></p-radioButton>
                                    <p-radioButton class="radioBulkUpload" value="XLS" label="XLS" [(ngModel)]="selectedFormat"></p-radioButton>
                                    <p-radioButton class="radioBulkUpload" value="XLSX" label="XLSX" [(ngModel)]="selectedFormat"></p-radioButton>
                                </div>
                                <div style="margin-top: 24px; font-size:16px; display:inline-flex" (click)="download_csv()">
                                    <span>
                                        <img src='assets/Inbox/download_icon_u70.svg' alt="Download">
                                    </span>
                                    <label style="margin-left:7px" for="from" class="d-flex flex-column mt-6">Download form</label>
                                </div>
                                <label style="margin-top: 57px; font-size:16px" for="from" class="d-flex flex-column mt-6">2. Please upload filled form</label>
                                <div style="margin-top: 26px; font-size:16px; display:inline-flex" (click)="upload_csv()">
                                    <span>
                                        <img style="transform: rotate(180deg);" src='assets/Inbox/download_icon_u70.svg' alt="Upload">
                                    </span>
                                    <label style="margin-left:7px" for="from" class="d-flex flex-column mt-6">Upload form
                                    </label>
                                </div>
                                <input name="files" id="files" type="file" multiple="false" style="display:none" #uploadFiles (change)="fileEvent($event)"
                                    accept=".xlsx, .xls, .csv" />
                            </div>
                        </div>
                    </div>
                </p-tabPanel>

                <p-dialog [(visible)]="isGroupAdmin" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal ModelClose'"
                    [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '25%'}">
                    <p-header>
                        <div class="d-flex flex-row align-items-center justify-content-between">
                            <!-- <h6 class="success-title" *ngIf="isManagerSelected">Success Confirmation</h6>
							<h6 class="success-title" *ngIf="!isManagerSelected">Error Message</h6> -->
                            <h6 class="success-title" *ngIf="this.isManagerSelected && this.isUserActiveGD">Success Confirmation</h6>
							<h6 class="success-title" *ngIf="!this.isManagerSelected || !this.isUserActiveGD">Error Message</h6>
                            <!-- <button type="button" (click)="closeErrorUserEnt()" class="d-flex flex-row close qma-text">
                            <img src="assets/Inbox/close_icon_u73.svg">
                            <h6>CLOSE</h6>
                        </button> -->
                        </div>
                    </p-header>
                    <div class="success-msg">{{errorMessageForGroupAdmin}}</div>
                </p-dialog>

                <p-tabPanel header="METADATA" *ngIf="isSuperUser">
                    <div style="padding-left:20px">
                        <div class="d-flex flex-row mt-4 mrg-left-4em mrg-lef ">
                            <div class="w-20 d-flex flex-row align-items-start ">
                                <label for="from" class="flex-grow meta-margin">Metadata type: </label>
                                <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" [options]="metadataTypeList" [(ngModel)]="selectedMetaDataType"
                                    (onChange)="selectMetaDataType($event.value,'onchange')">
                                </p-dropdown>
                            </div>
                            <div style="padding-left:24.3em;" class="d-flex flex-row">
                                <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_metadata)" (mouseout)="info_metadata.hide()" alt="Info">
                                <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_metadata [baseZIndex]="9999">
                                    <div class="d-flex flex-row mb-0.3">
                                        <span>
                                            <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                        </span>
                                        <span class="info-content">{{infoMetadata}}</span>
                                    </div>
                                </p-overlayPanel>
                            </div>
                        </div>

                        <div *ngIf="metaDataType !== 'reqTypeCauseMapping' " class="d-flex flex-row mt-4" style="margin-top: 1em !important; font-family: Interstate">
                            <div [hidden]="disableAddMetaDataOption"> <!--C170665-185 disable when org admin disable to edit org level edit in hard code-->
                                <span class="mr-9" style="padding-right: 10px;">
                                    <div class="add-new-btn" (click)="onBtStartEditing(null, null, 'top')">
                                        <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                            <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/context/add-button.svg" alt="Add">
                                        </span>
                                <span>Add New</span>
                            </div>
                            </span>
                            <span [hidden]="metaDeleteFlag">
                                    <!-- Jira:4703 : Confirmation Dialog before delete --> 
                                    <div class="add-new-btn" style="margin-left: 0.8em;" (click)="setConfirmation('metaData')">
                                        <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                            <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/context/delete-button.svg" alt="Delete">
                                        </span>
                            <span>Delete</span>
                        </div>
                        </span>
                            </div>
        </div>

        <!-- <div class="d-flex flex-row mt-4"
                                *ngIf="metaDataType === 'Root Cause'"> -->
        <div *ngIf="metaDataType === 'Root Cause'" style="margin-top: 1em !important; font-family: Interstate">
          <div *ngIf="!disableAddMetaDataOption"><!--C170665-185 disable when org admin disable group level edit in hard code-->
            <ul class="ul-checkbox">
                <li>
                    <input type="checkbox" name="tag" value="tagMandatory" [(ngModel)]="isRootCauseMandatory"> Mandatory
                    for resolve
                    <div *ngIf="isRootCauseMandatory">
                        <ul style="padding-left: 15px;">
                            <li>
                                <input type="radio" name="rootcause" [value]="false" [(ngModel)]="isRootCauseMandatoryWOReply" (click)="setIsRootCauseMandatoryWOReply(false)">                                For all resolve actions
                                <br>
                            </li>
                            <li>
                                <input type="radio" name="rootcause" [value]="true" [(ngModel)]="isRootCauseMandatoryWOReply" (click)="setIsRootCauseMandatoryWOReply(true)">                                For resolve without reply</li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
        </div>
        <!-- Tags -->
        <div *ngIf="metaDataType === 'Tags'" class="checkbox-font" style="margin-top: 1em !important; font-family: Interstate">
            <ul class="ul-checkbox">
                <input type="checkbox" name="tag" value="tagMandatory" [(ngModel)]="isTagMandatory"> Mandatory for resolve
            </ul>
        </div>
        <!-- processing -->
        <div *ngIf="metaDataType === 'Processing Region'" class="checkbox-font" style="margin-top: 1em !important; font-family: Interstate">
            <ul class="ul-checkbox">
                <input type="checkbox" name="Processing" value="processingRegionMandatory" [(ngModel)]="isProcessingRegionMandatory">                Mandatory for resolve
            </ul>
        </div>
        <div style="margin-top: 1em !important; font-family: Interstate">
            <span *ngIf="metaTagValidationFlag" style="color: red;">{{metaTagValidationMsg}} </span>
        </div>
        <!--C170665-185 display message when org admin disable group level edit in hard code data point-->
        <div style="margin-top: 1em !important; font-family: Interstate">
            <span *ngIf="disableAddMetaDataOption" style="color: red;">*Request Type/Root cause metadata managed at org level. please contact Org admin for any changes, to see org metadata refer org metadata tab </span>
        </div>
        <!-- Meta Data Grid-->
        <div *ngIf="metaDataType !== 'reqTypeCauseMapping'" class="d-flex flex-row mt-4">
            <ag-grid-angular #agGrid style="width: 55.9%; height: 440px" id="metadata" class="ag-theme-balham headerPart" [columnDefs]="metaColumnDefs"
                [rowData]="metaRowData" (selectionChanged)="onSelectionCheck()" [defaultColDef]="metaCheckbox" [singleClickEdit]="true"
                [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onMetaDataGridReady($event)"
                (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="true"
                (cellKeyDown)="onkeydown($event)">
            </ag-grid-angular>
        </div>
        <!-- Resquest Type and Root Cause Mapping -->
        <div *ngIf="metaDataType === 'reqTypeCauseMapping'" class="d-flex flex-column">
         <!-- <div [ngClass]="{'disabled':disableAddMetaDataOption}"> commenting it to see request to root cause mapping if org disable group level metadata--> 
            <app-reqtype-rootcause [requestTypes]='groupRequestTypes' [requestCauses]='groupRootCauses' [reqTypeRootCauseList]="reqTypeRootCauseMap"
                (updateMapping)="onApplyMapping($event)">
            </app-reqtype-rootcause>
        <!-- </div> -->
        </div>
    </div>
    </p-tabPanel>
    <p-dialog [(visible)]="isExist" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal errorModal ModelClose'"
        [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
        <p-header>
            <div class="d-flex flex-row align-items-center justify-content-between">
                <h6 class="success-title">Error Message</h6>
                <!-- <button type="button" (click)="closeErrorMetadata()" class="d-flex flex-row close qma-text">
                            <img src="assets/Inbox/close_icon_u73.svg">
                            <h6>CLOSE</h6>
                        </button> -->
            </div>
        </p-header>
        <div class="success-msg">{{errorMessageForMetadata}}</div>
    </p-dialog>
    <p-tabPanel header="PREFERENCES">
        <div class="d-flex flex-row ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font mt-2">Peer review email</label>
            </div>
            <div style="margin-right: 150px;" class="flex-row flex-nowrap align-items-end">
                <p-inputSwitch [(ngModel)]="makerCheckerRqd" (onChange)="enablePeerReview()" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
            <div style="padding-left:19.8em; padding-right: 15px; padding-top: 25px;" class="d-flex flex-row">
                <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_preferences)"
                    (mouseout)="info_preferences.hide()" alt="Info">
                <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_preferences [baseZIndex]="9999">
                    <div class="d-flex flex-row mb-0.3">
                        <span>
                                        <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                    </span>
                        <span class="info-content">{{infoPreferences}}</span>
                    </div>
                </p-overlayPanel>
            </div>
        </div>
        <div class="d-flex flex-row ml-4 w-75 preference-radio">
            <div class="flex-row flex-nowrap w-75" [hidden]="enablePeerReviewDiv">
                <div class="d-flex flex-row">
                    <p-radioButton class="radio-margin-left" value="All" label="All emails to external clients" [(ngModel)]="peerRevDomain"></p-radioButton>
                </div>
                <div class="d-flex flex-row mt-2">
                    <p-radioButton value="Attachments" class="radio-margin-left" label="Emails to external clients with attachments/images only"
                        [(ngModel)]="peerRevDomain"></p-radioButton>
                </div>
            </div>
        </div>
        <div *ngIf="isAutoAssignmentRollOutOrgs" class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Enable Auto Assignment </label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="enableAutoAssignment" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <div *ngIf="isAutoAssignmentRollOutOrgs" class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Enable Trade Status Suggestion </label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="enableAutoReplySuggestion" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Enable Predictive Recipients </label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="predictiveRecipients" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Block users from adding external email(s) to Top Contacts </label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="isEmailSharingBlocked" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Make Inquiry Source mandatory for resolve
                            <span><img src="assets/Inbox/info-icon.svg" class="icon-preference" (mouseover)="showAddtoGrpPanel($event, info_sub_preferences)" (mouseout)="info_sub_preferences.hide()" alt="Info">
                                <p-overlayPanel [styleClass]="'icon-preference-submenu'" [appendTo]="'body'" #info_sub_preferences [baseZIndex]="9999">
                                    <div class="d-flex flex-row mb-0.3">
                                        <span class="info-preference-inside">
                                            <ul>
                                                <li style="list-style:none;padding-bottom:7px;text-decoration:underline;" >Inquiry Source List</li>
                                                <li style="padding-bottom:7px;list-style-type:disc;">Email</li>
                                                <li style="padding-bottom:7px;list-style-type:disc;">Fax</li>
                                                <li style="padding-bottom:7px;list-style-type:disc;">Phone</li>
                                                <li style="padding-bottom:7px;list-style-type:disc;">Swift</li>
                                                <li style="padding-bottom:disc;list-style-type:disc;">Lock</li>
                                            </ul>
                                        </span>
                                    </div>
                                </p-overlayPanel></span></label>

            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="isInquirySourceMandatory" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Make GFPID/GFCID mandatory for resolve
                            </label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="isGfidMandatory" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font"> Add Inquiry reference Id to email response</label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="isInqReferenceIdReq" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Exclude My Group While Replying All </label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="excludeMyGroupWhileReplyAll" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <!-- C153176-5630 | Enable/disable client contact list visibility for group members. -->
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Hide client contact list</label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="hideClientList" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <!-- C153176-6148 | Group configuration : Re-age need supervisor approval. -->
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Disable supervisor approval for Inquiry re age</label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="supervisiorApprovalForReage" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <!--C170665-10 | Enable Request Type and Root Cause Mapping -->
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Enable Request Type and Root Cause Mapping</label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="requestTypeRootCauseFilter" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <div *ngIf="displayEnableOrgMetaDataOption" class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font">Disable Organization Level Meta Data</label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="enableOrgMetaData" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
         <!-- [C170665-1719] DCC Requirement: Add Case status field -->
         <div *ngIf="enableInquirySubStatusFlag" class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font"> Enable Inquiry Sub-Status
                            </label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [disabled]="isInquirySubStatusEnabledByOrgAdmin" [(ngModel)]="enableInquirySubStatus" (onChange)="enableInquirySubStatusChanged()" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
        <div class="d-flex flex-row my-2 ml-4">
            <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font"> Enable work shift and holiday based age calculation
                            </label>
            </div>
            <div class="flex-row flex-nowrap align-items-end w-75 preference-switch">
                <p-inputSwitch [(ngModel)]="holidayAndShiftDetails" (onChange)="enableShiftCng()" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </div>
        </div>
       
        <div class="d-flex flex-row my-2 ml-4">
            <div *ngIf="workshiftErrMsg" class="error-div"><span class="errorMsg">{{workshiftErrMsg}}</span></div>
        </div>
        <div class="d-flex flex-row radio-margin-left">
            <div [hidden]="enableWorkShiftDiv" class="w-100 work-shift-container">
                <div class="d-flex flex-row flex-nowrap justify-content-between w-100  my-3">
                    <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between w-50 pr-4">
                        <div class="d-flex flex-row align-items-center w-75">
                            <label for="from" class="label_font">Country* </label>
                        </div>
                        <div class="w-100 d-flex justify-content-start">
                            <p-dropdown class="drop-down" [options]="countryList" [style]="{width:'200px'}" [(ngModel)]="selectedCountry" optionLabel="countryName"
                                [styleClass]="'prime-dd-override'"></p-dropdown>
                        </div>
                    </div>
                    <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between w-50 pr-4">
                        <div class="d-flex flex-row align-items-center w-75">
                            <label for="from" class="label_font">Time zone* </label>
                        </div>
                        <div class="w-100 d-flex justify-content-start">
                            <p-dropdown [styleClass]="'prime-dd-override'" [options]="timeZones" [style]="{width:'200px'}" [(ngModel)]="selectedTimeZone"
                                optionLabel="timeZoneName" styleClass="override"></p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between w-100  my-3">
                    <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between w-50 pr-4">
                        <div class="d-flex flex-row align-items-center w-75">
                            <label for="from" class="flex-grow label_font">Shift start time* </label>
                        </div>
                        <div class="w-100 d-flex justify-content-start">
                            <p-dropdown [styleClass]="'prime-dd-override'" optionLabel="value" [placeholder]="'Select start time'" [options]="shifttimeList"
                                [style]="{width:'200px'}" [(ngModel)]="selectedStartTime" styleClass="override"></p-dropdown>
                        </div>
                    </div>
                    <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between w-50 pr-4">
                        <div class="d-flex flex-row align-items-center w-75">
                            <label for="from" class="flex-grow shift.end-label label_font">Shift end time*
                                        </label>
                        </div>
                        <div class="w-100 d-flex justify-content-start">
                            <p-dropdown [styleClass]="'prime-dd-override'" optionLabel="value" [placeholder]="'Select end time'" [options]="shifttimeList"
                                [style]="{width:'200px'}" [(ngModel)]="selectedEndTime" styleClass="override"></p-dropdown>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-row flex-nowrap align-items-center justify-content-between w-50 my-3 pr-4">
                    <div class="d-flex flex-row align-items-center w-75">
                        <label for="from" class="flex-grow label_font">Non working days </label>
                    </div>
                    <div class="w-100 d-flex justify-content-start">
                        <p-multiSelect [defaultLabel]="'Select non working days'" [options]="weekelyOff" [(ngModel)]="selectedWeekelyOff" [styleClass]="'preference-multiselect'"
                            [style]="{width:'200px'}" [showToggleAll]="false"></p-multiSelect>
                    </div>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="INQUIRY RULES">
        <div style="padding-left:20px">
            <h6 style="color:#002D72">INQUIRY RULES</h6>
            <div style="text-align:right">
                <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_inquiry_rules)"
                    (mouseout)="info_inquiry_rules.hide()" alt="Info">
                <!--  C153176-4445 : Info Icon overlay panel should come in proper place  -->
                <p-overlayPanel [styleClass]="'icon-info inquiry-rules-icon-info'" [appendTo]="'body'" #info_inquiry_rules [baseZIndex]="9999">
                    <div class="d-flex flex-row mb-0.3">
                        <span>
                                            <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                        </span>
                        <span class="info-content">{{infoInquiryRules}}<br /><b>{{infoInquiryRulesExtTitle}}</b>{{infoInquiryRulesExt}}</span>
                    </div>
                </p-overlayPanel>
            </div>

            <div class="mt-4">
                <span class="disclaimer">{{ruleDisclaimer}}</span>
            </div>
            <div class="d-flex align-items-center w-100 py-2">
                <span class="inbox-options-item inbox-search-option " [ngClass]="{'hide':filterIconHide}">
                                    <img class="inbox-search-option" src='assets/Inbox/search_icon_selected_u42.svg'
                                        alt='Kiwi standing on oval' (click)="filterIconClick()">
                                </span>
                <div class="inbox-input" [ngClass]="{'hide':!filterIconHide}">
                    <input class="inbox-filter-txtbox" type="text" placeholder="Filter..." (keyup)="onKey($event)" [(ngModel)]="filterText" />
                    <img src="assets/Inbox/close_icon_u73.svg" width=25 (click)="filterCloseClick()" class="mail-box-filter-close-img" alt="Close"/>
                </div>
                <div class="d-flex justify-content-start" style="width: 60%;">
                    <span class="mr-9" style="width:18%;">
                                    <div class="add-new-btn" (click)="addRules(null, null, 'top')">
                                        <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                            <img class="qma-text" style="height:13px;width:13px;opacity:2;"
                                                src="assets/context/add-button.svg" alt="Add">
                                        </span>
                    <span>Add new rule</span>
                </div>
                </span>
                <!-- <span [hidden]="rulesDeleteFlag" style="width:8%"> -->
                <span style="width:11%">
                                    <!-- Jira:4703 : Confirmation Dialog before delete -->
                                    <span class="add-new-btn" (click)="setConfirmation('inquiryRules')">
                                        <span style="text-decoration: none; display: inline-block;">
                                            <img style="width:11px;height:11px;" class="qma-text"
                                                src="assets/context/delete-button.svg" alt="Delete">
                                        </span>Delete
                </span>

                </span>
                <span class="mr-9" style="width:16%;">
                                    <div class="add-new-btn" (click)="copyInquiryRecords()">
                                        <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                            <img class="qma-text" style="height:13px;width:13px;opacity:2;"
                                                src="assets/Inbox/copy_icon.svg" alt="Copy">
                                        </span>
                <span>Make a copy</span>
            </div>
            </span>
            <span class="mr-9" style="width:22%;">
                                    <div class="add-new-btn" (click)="checkDuplicate($event, duplicate)">
                                        <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                            <img class="qma-text" style="height:13px;width:13px;opacity:2;"
                                                src="assets/context/add-button.svg" alt="Add">
                                        </span>
            <span>Check duplicates</span>
        </div>
        </span>
        <!--C153176-5633 regarding restore button implementation for restore rules -->
        <span class="mr-9" style="width:33%;">
                                    <div class="add-new-btn" *ngIf ="true"
                                        (click)="restoreRulesShowPopUp($event, restoreRules)">
                                        <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                            <img class="qma-text"
                                                style="height:13px;width:13px;opacity:2;"
                                                src="assets/context/restore-rules.svg" alt="Restore">
                                        </span>
        <span>Restore rules</span>
        </div>
        </span>
        </div>
        <div class="d-flex justify-content-end" style="width: 32%;text-align: right">
            <span class="mr-9" style="width:39%;">
                                    <div class="add-new-btn" (click)="uploadInquiry()">
                                        <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                            <img class="qma-text"
                                                style="height:13px;width:13px;opacity:2;transform: rotate(180deg);"
                                                src="assets/Inbox/download_icon_u70.svg" alt="Upload">
                                        </span>
            <span>Upload</span>
        </div>
        </span>
        <input name="files" id="files" type="file" multiple="false" style="display:none" #uploadFilesForInquiry (change)="fileEventForInquiry($event)"
            accept=".xlsx" value="" />

        <span class="mr-9" style="width:27%;">
                                    <div class="add-new-btn" (click)="download_rules()">
                                        <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                            <img class="qma-text" style="height:13px;width:13px;opacity:2;"
                                                src="assets/Inbox/download_icon_u70.svg" alt="Download">
                                        </span>
        <span>Download</span>
        </div>
        </span>
        </div>
        <span class="mr-9" *ngIf="invalidRowMessage.length > 0">
                                    <div class="add-new-btn" (click)="onDownloadLogs()">
                                        <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                            <img class="qma-text" style="height:13px;width:13px;opacity:2;"
                                                src="assets/Inbox/download_icon_u70.svg" alt="Download">
                                        </span>
        <span>Download failed rules</span>
        </div>
        </span>
        </div>
        <div *ngIf="!this.validationCheckForAction || !this.validationCheckForEmpty">
            <span class="errorMsg">{{validationRules}}</span>
        </div>

        <div class="d-flex flex-row mt-4 inquiry-rule">
            <ag-grid-angular #inquiryGrid style="width: 100%; height: 441px; " class="ag-theme-balham admindrp" id="inquiryRules" [rowData]="routesList"
                [columnDefs]="routesColumn" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true"
                [pagination]="true" [defaultColDef]="inquiryRulesChecbox" [singleClickEdit]="true" [rowSelection]="rowSelection"
                [paginationPageSize]="100" (gridReady)="onGridReadyInquiry($event)" suppressPaginationPanel=true (paginationChanged)="onPaginationChanged()"
                (selectionChanged)="onSelectionRules()" [suppressPaginationPanel]="true"
                [suppressMenuHide]="true" [suppressDragLeaveHidesColumns]="true" [suppressMakeColumnVisibleAfterUnGroup]="true" [suppressColumnVirtualisation]="true" [rowHeight]="43"
                [headerHeight]="44">
            </ag-grid-angular>
        </div>
        <!-- //C153176-4445 check duplicate -->
        <p-overlayPanel [styleClass]="'duplicate_panel duplicate_overlay'" [appendTo]="'body'" [dismissable]="true" #duplicate [baseZIndex]="9999">
            <p-header>
                <div class="d-flex flex-row align-items-center justify-content-between">
                    <div class="d-flex justify-content-start" style="width:80%">
                        <div style="width:16%">
                            <h6 class="new-rule">NEW RULES </h6>
                        </div>
                        <div style="width:84%">
                            <h6 class="duplicate-rule"> DUPLICATES</h6>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end" style="width:20%">
                        <button type="button" (click)="hideDuplicateModal(duplicate)" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1">
                                                <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
                                                <h6>CLOSE</h6>
                                            </button>
                    </div>
                </div>
            </p-header>
            <div style="width: 100%; height :1px; border: 1px solid #002d72"></div>
            <div style="background-color: rgba(0, 189, 242, 0.06);">
                <div class="d-flex flex-row align-items-center justify-content-between" style="width: 100%; height: 43px;">
                    <span class="gfid-title" style="font-size: 14px;margin-left: 7em;">FROM </span>
                    <span class="gfid-title" style="font-size: 14px;width: 3em;">SUBJECT </span>
                    <span class="gfid-title" style="font-size: 14px;width: 26em;">TO </span>
                </div>
                <div style="width: 100%; height :1px;"></div>
            </div>
            <div class="d-flex flex-row inquiry-rule">
                <ag-grid-angular #inquiryGridDuplicate style="width: 100%; height: 29vh; " class="ag-theme-balham admindrp" id="inquiryRulesGridDuplicate"
                    [rowData]="duplicateList" [columnDefs]="duplicateColumn" [stopEditingWhenGridLosesFocus]="true"
                    [suppressRowClickSelection]="true" [pagination]="true" [defaultColDef]="duplicateCheckBox" [singleClickEdit]="true"
                    [rowSelection]="rowSelection" [paginationPageSize]="100" (gridReady)="onGridDuplicate($event)" suppressPaginationPanel=true
                    (paginationChanged)="onPaginationChanged()" (selectionChanged)="onSelectionRules()" [suppressDragLeaveHidesColumns]="true"
                    suppressMenu=true [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="false" (rowDataChanged)="dupGridRowDataChanged($event)">
                    <!-- C153176-4453-->
                </ag-grid-angular>
            </div>
        </p-overlayPanel>
        <p-overlayPanel [styleClass]="'inq-rules-restore-overlay'" [appendTo]="'body'" [dismissable]="false" #restoreRules [baseZIndex]="9999">
            <p-header>
                <div class="inq-rules-restore-header-container">
                    <span class="qma-inq-rule-popup-header">RESTORE INQUIRY RULES</span>
                    <span class="qma-inq-rules-reorder-close" (click)="closeRestorePopup($event, restoreRules)">
                                        <img [src]="'assets/Inbox/close_secondary_columns.svg'"
                                            class="qma-secondary-close-icon" alt="Close">
                                        <span>
                                            CLOSE
                                        </span>
                    </span>
                </div>
            </p-header>
            <div *ngIf="restoreStatus" class="inq-restore-content">
                <div>
                    The inquiry rules will be restored to the version saved last on
                </div>
                <div class="inq-restore-calender">
                    <i class="pi pi-calendar"></i> <span class="inq-retore-date">{{restoreInquiryDate}}</span>
                </div>
                <div>
                    Are you sure you want to go head with the restore ?
                </div>
            </div>
            <div *ngIf="!restoreStatus" class="inq-restore-content">
                <div>
                    There are no rules to be restored
                </div>
            </div>
            <p-footer>
                <div class="d-flex flex-row footer-btn-container"></div>
                <div class="d-flex flex-row flex-wrap bottom-button-holder pt-2 justify-content-end">
                    <button type="submit" class="btn save-btn mr-4 btn-sm restore-button" [disabled]="!restoreStatus" (click)="restoreRule($event, restoreRules)">Restore</button>
                    <button class="btn clear-btn" (click)="cancelRestorePopup($event, restoreRules)">Cancel</button>
                </div>
            </p-footer>
        </p-overlayPanel>

        <div style="margin-top: 1%;height:8vh!important" class="d-flex justify-content-end qma-mailbox-pagination-container">
            <span class="value" id="lbPageSize">{{lbPageSize}}</span>
            <span class="separator"> of </span>
            <span class="value separator" id="lbTotalRecords">{{lbTotalRecords}}</span>
            <span class="separator separator-right"> Rules </span>
            <span>
                                    <img [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}"
                                        (click)="onBtFirst()" id="btFirst" class="pagination-img"
                                        src="assets/Inbox/pagination-arrow-first.svg" alt="First"/>
                                </span>
            <span class="separator-right">
                                    <img id="btPrevious" [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}"
                                        (click)="onBtPrevious()" class="pagination-img"
                                        src="assets/Inbox/pagination-arrow-prev.svg" alt="Previous"/>
                                </span>
            <span class="separator-right pagination-text-padding">Page </span>
            <span id="lbCurrentPage" class="separator-right pagination-text-padding">{{lbCurrentPage}}</span>
            <span class="separator-right pagination-text-padding">of</span>
            <span id="lbTotalPages" class="separator-right pagination-text-padding">{{lbTotalPages}}</span>
            <span>
                                    <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btNext"
                                        (click)="onBtNext()" class="pagination-img"
                                        src="assets/Inbox/pagination-arrow-dark.svg" alt="Next"/>
                                </span>
            <span class="separator-right">
                                    <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btLast"
                                        (click)="onBtLast()" class="pagination-img"
                                        src="assets/Inbox/pagination-arrow-dark1.svg" alt="Last" />
                                </span>
        </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="ESCALATION CRITERIA">
        <div style="margin-left:2em;">
            <div style="padding-left:62em;padding-top:15px;" class="d-flex flex-row">
                <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_esclation_criteria)"
                    (mouseout)="info_esclation_criteria.hide()" alt="Info">
                <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_esclation_criteria [baseZIndex]="9999">
                    <div class="d-flex flex-row mb-0.3">
                        <span>
                                        <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                    </span>
                        <span class="info-content">{{infoEsclaition}}</span>
                    </div>
                </p-overlayPanel>
            </div>
            <div class="d-flex flex-row mt-4">
                <label for="from" class="flex-grow">Conversation count threshold: </label>
                <input type="number" pKeyFilter="int" [(ngModel)]="converThreshold" min="0" class="w-100 t-box inp-txt-mng group-details-input-mng"
                    (keydown)=onEnterNumber($event) />
            </div>
            <div class="d-flex flex-row mt-4">
                <label for="from" class="flex-grow">Response time threshold: </label>
                <input style="margin-left: 51.8px;" placeholder="hrs" pKeyFilter="int" type="number" min="0" [(ngModel)]="resTimeThreHr"
                    class="w-25 t-box inp-txt-mng group-details-input-mng" (keydown)=onEnterNumber($event)/>
                <input type="number" pKeyFilter="int" placeholder="mins" min="0" [(ngModel)]="resTimeThreMin" class="w-25 t-box inp-txt-mng group-details-input-mng"
                    (keydown)=onEnterNumber($event)/>

            </div>
            <div class="d-flex flex-row mt-4">
                <label for="from" class="flex-grow">Pending approval threshold: </label>
                <input style="margin-left: 35px;" placeholder="hrs" pKeyFilter="int" type="number" min="0" [(ngModel)]="penApprThreHr" class="w-25 t-box inp-txt-mng group-details-input-mng"
                    (keydown)=onEnterNumber($event)/>
                <input type="number" placeholder="mins" pKeyFilter="int" min="0" [(ngModel)]="penApprThreMin" class="w-25 t-box inp-txt-mng group-details-input-mng"
                    (keydown)=onEnterNumber($event)/>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="AUTOMATED RESPONSE">
        <div [hidden]="autoResFlagUI" style="margin-left:3em;">
            <div class="w-20 d-flex flex-row mt-4 ">
                <span>
                                <label for="from" class="flex-grow">
                                    <p>Enable Automated </p>
                                    <p> Client acknowledgement </p>
                                </label>
                            </span>
                <span style="padding-left:2em">
                                <p-inputSwitch [(ngModel)]="enableAutCliAck" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                            </span>
                <span>
                                <div style="padding-left:47em;" class="d-flex flex-row">
                                    <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_automated_response)" (mouseout)="info_automated_response.hide()" alt="Info">
                                    <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_automated_response [baseZIndex]="9999">
                                        <div class="d-flex flex-row mb-0.3">
                                            <span>
                                                <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                            </span>
                <span class="info-content">{{infoAutoResponse}}</span>
            </div>
            </p-overlayPanel>
        </div>
        </span>
        </div>


        <div class="d-flex flex-row mt-4">
            <label for="from" class="flex-grow">Automated Response</label>
        </div>

        <div *ngIf="editorFlag" style="margin-right:3em">
            <editor-app [elementId]="'autoResponse'" [height]="300"></editor-app>
        </div>
        <label for="from" (click)="previewMail()" class="flex-grow" style="text-decoration-line: underline;margin-top:2em;">Preview</label>
        </div>


        <div [hidden]="autoResFlagDetails" style="margin-left:2.5em;margin-top:2em">
            <div>
                <label for="from" class="flex-grow">Automated response preview </label>
            </div>
            <div>
                <p style="padding-top:2em; padding-bottom:0.5em">Thank you for your email. Your mail has been assigned to [user_firstname user_lastname] for resolution. [user_firstname]
                    can be contacted on [user_contact_number].</p>
                <p style="padding-top:1.5em; padding-bottom:1.5em;font-weight:bold;">If escalation is required, the managerâ€™s contact details are:</p>
                <p style="color:#002D72;font-weight:bold;">[manager_name]</p>
                <p style="color:#002D72">[manager_phone_number]</p>
                <p style="color:#002D72">[manager_email_address]</p>
                <p style="padding-top:2em; padding-bottom:1em;font-weight:bold;"> We will provide a response to your query as soon as possible.</p>
                <!-- Automated response preview issue -->
                <p [innerHTML]="sanetize(autoResponse)" class="myAutoScrollbar" style=""></p>
            </div>
            <div style="padding-top:20px">
                <!-- Back Button was not displaying -->
                <label for="from" (click)="backToEditor()" class="flex-grow" style="text-decoration-line: underline;">Back to editor</label>
            </div>
        </div>

    </p-tabPanel>
    <p-tabPanel header="EMAIL DISCLAIMER">
        <div style="margin-left:2em;margin-top:2em;">
            <div style="padding-left:62.5em;" class="d-flex flex-row">
                <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_email_disclaimer)"
                    (mouseout)="info_email_disclaimer.hide()" alt="Info">
                <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_email_disclaimer [baseZIndex]="9999">
                    <div class="d-flex flex-row mb-0.3">
                        <span>
                                        <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                    </span>
                        <span class="info-content">{{infoEmailDisclaimer}}</span>
                    </div>
                </p-overlayPanel>
            </div>
            <div *ngIf="editorFlag" style="margin: 2em 7em 5em;">
                <editor-app [elementId]="'emailDisc'" [height]="300"></editor-app>
            </div>
        </div>

    </p-tabPanel>
    <p-tabPanel header="PEER REVIEW DOMAIN">
        <div style="margin-left:2em;margin-top: 2em;">
            <div class="d-flex flex-row mt-4" style="margin-top: 1.2em !important; font-family: Interstate">
                <div class="add-new-btn" (click)="addDomain(null, null, 'top')">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/context/add-button.svg" alt="Add">
                            </span>
                    <span>Add New</span>
                </div>
                <span [hidden]="domainDeleteFlag">
                                <!-- Jira:4703 : Confirmation Dialog before delete --> 
                                <div class="add-new-btn" (click)="setConfirmation('peerReview')" style="margin-left: 0.8em;">
                                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                        <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/context/delete-button.svg" alt="Delete">
                                    </span>
                <span>Delete</span>
            </div>
            </span>

        </div>

        <div style="padding-left:62.5em;" class="d-flex flex-row">
            <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_peer_review_domain)"
                (mouseout)="info_peer_review_domain.hide()">
            <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_peer_review_domain [baseZIndex]="9999">
                <div class="d-flex flex-row mb-0.3">
                    <span>
                                        <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                    </span>
                    <span class="info-content">{{infoPeerReview}}</span>
                </div>
            </p-overlayPanel>
        </div>
        <div class="d-flex flex-row mt-4">
            <label for="from" class="flex-grow">Domain</label>
        </div>
        <span *ngIf="domainValidationFlag" style="color: red;">{{domainMsg}}</span>
        <div id="domain" class="d-flex flex-row mt-4">
            <ag-grid-angular #agDomain style="width: 65%; height: 311px" id="adminDomain" class="ag-theme-balham headerPart" [columnDefs]="prdColumn"
                [rowData]="prdData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" [defaultColDef]="domainCheckbox"
                (gridReady)="onGridReady($event)" (cellValueChanged)="onCellValueTxt($event)" [rowSelection]="rowSelection" (selectionChanged)="onDomainCheck()"
                [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="true"></ag-grid-angular>
        </div>
        </div>


    </p-tabPanel>
    <!-- /* C153176-4445 inquiry rule redesign */ responsive fixed 05/03/2020 -->
    <p-tabPanel header="CLIENT CATEGORY">
        <div style="padding-left:20px">
            <div style="padding-left:63em; padding-top: 15px;" class="d-flex flex-row">
                <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_client_category)"
                    (mouseout)="info_client_category.hide()" alt="Info">
                <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_client_category [baseZIndex]="9999">
                    <div class="d-flex flex-row mb-0.3">
                        <span>
                                                <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                            </span>
                        <span class="info-content">{{infoClientCat}}</span>
                    </div>
                </p-overlayPanel>
            </div>
            <div class="d-flex flex-row mt-4" style="margin-top: 1.5em !important; font-family: Interstate; margin-bottom: 1.5em;">
                <span>
                                <div class="add-new-btn" (click)="onAddCustomCategory()">
                                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                        <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/context/add-button.svg" alt="Add">
                                    </span>
                <span>Add New</span>
            </div>
            </span>
        </div>

        <div class="d-flex flex-row"><span *ngIf="ClientNameValidation" style="color: red;padding-top:5px;padding-bottom:5px;">{{ClientNameValidation}} </span></div>
        <div class="d-flex flex-row"><span>*Platinum, Priority and Client are default categories; the color codes for these three categories cannot be maintained from this screen.</span></div>
        <!--C153176-4566 | Show the system default categories - Starts-->
        <div class="d-flex flex-row align-items-center">
            <ng-template ngFor let-category [ngForOf]="clientCategories" let-idx="index">
                <span>{{category.categoryName}}</span>
                <span class="clientCategory" [style.backgroundColor]="category.colorCode"> </span>
                <span class="mr-1" *ngIf="idx != clientCategories.length-1">,</span>
            </ng-template>
        </div>
        <!--C153176-4566 | Show the system default categories - Ends-->
        <div class="client_category d-flex flex-row mt-4">
            <ag-grid-angular #agGrid style="width: 95%; height: 128px" class="ag-theme-balham headerPart myClientCategory clientCategoryHeader"
                [rowHeight]="44" [columnDefs]="clientCategoryColumnDefs" [rowData]="clientCategoryRowData" (selectionChanged)="onSelectionCheck()"
                [defaultColDef]="" [singleClickEdit]="true" [stopEditingWhenGridLosesFocus]="true" [suppressHorizontalScroll]="true"
                [suppressRowClickSelection]="true" (gridReady)="onGridReadyClient($event)" (cellValueChanged)="onCellValueChangedClient($event)">
            </ag-grid-angular>
        </div>
        </div>
    </p-tabPanel>
     <!---C170665-850 for display org level metdata-->
    <p-tabPanel *ngIf="displayOrgMetadata" header="ORG METADATA">
        <app-org-metadata [orgName]="orgData"></app-org-metadata>
    </p-tabPanel> 
    <p-dialog [(visible)]="errorMessage" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal errorModal ModelClose'"
        [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
        <p-header>
            <div class="d-flex flex-row align-items-center justify-content-between">
                <h6 class="success-title">Error Message</h6>
                <!-- <button type="button" (click)="closeErrorModal()" class="d-flex flex-row close qma-text">
                            <img src="assets/Inbox/close_icon_u73.svg">
                            <h6>CLOSE</h6>
                        </button> -->
            </div>
        </p-header>
        <div class="success-msg">{{errorMessageForClientCategory}}</div>
    </p-dialog>

    <!-- /* C153176-4445 inquiry rule redesign */ -->
    <span *ngIf="inquiryFlag !== 4">
                <p-footer style="position: absolute; width: 100%; bottom : 0; right: 0">
                <div class="d-flex flex-row footer-btn-container"></div>
                <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
                    
                        <button type="submit" class="btn save-btn mr-4" (click)="saveGroup()">Submit</button>
                        <button class="btn clear-btn" (click)="resetForm()">Reset</button>
                    </div>
            </p-footer>
            </span>
    <div style="display: inline-flex;" *ngIf="inquiryFlag === 4">
        <p-footer style="position: absolute; width: 100%; top: 675px; right: 0px;">
            <div class="d-flex flex-row footer-btn-container"></div>
            <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
                <div class="saveinq-btn"><span style="font-weight: bold">Note:</span> This save will just save the inquiry rules</div>
                <button type="submit" class="btn save-btn mr-4" (click)="saveInquiryRules()">Save</button>
                <button class="btn inqclr-btn" (click)="resetForm()">Reset</button>
            </div>
        </p-footer>
    </div>
    </p-tabView>
    </div>
    </div>
</p-dialog>
<!--Profile Setting-->
<p-dialog [(visible)]="displayProfile" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal profileModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'height':'830px','max-width':'1484px', 'width': '100%'}"
    [resizable]="false">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between profile-padding">
            <h6 class="gfid-title" style="font-size: 20px">PROFILE SETTING</h6>

            <button type="button" (click)="hideProfileModal(false)" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1">
                <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <div class="settings-padding">
        <app-profile-setting *ngIf="editorFlagProfile" [showFlag]="editorFlagProfile" (onSubmit)="closeProfileSetting($event)"></app-profile-setting>
    </div>
</p-dialog>
<!-- for sucess and error -->
<p-dialog [(visible)]="displaySuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
    [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Confirmation</h6>
            <!-- <button type="button" (click)="closeModal('success')" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg">
            <h6>CLOSE</h6>
        </button> -->
        </div>
    </p-header>
    <div class="success-msg"> Inquiry rule has been saved successfully</div>
</p-dialog>
<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal failureModal ModelClose'"
    [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Information</h6>
            <!-- <button type="button" (click)="closeModal('error')" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg">
            <h6>CLOSE</h6>
        </button> -->
        </div>
    </p-header>
    <div class="success-msg">Error while saving Inquiry rule Please save again!</div>
</p-dialog>

<!--Admin Save-->

<p-dialog [(visible)]="closeFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
    [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title" *ngIf="isManagerSelected">Success Confirmation</h6>
			<h6 class="success-title" *ngIf="!isManagerSelected">Error Message</h6>
            <!-- <button type="button" (click)="groupSave.hide()" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg">
                <h6>CLOSE</h6>
            </button> -->
        </div>
    </p-header>
    <div class="success-msg">{{grpSaveMsg}}</div>
</p-dialog>

<!-- Sucess msg for add to group user entitlement -->

<p-dialog [(visible)]="closeFlagAddGroup" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
    [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%', 'margin-left':'150px'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Confirmation</h6>
            <!-- <button type="button" (click)="closeAddGroup()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg">
            <h6>CLOSE</h6>
        </button> -->
        </div>
    </p-header>
    <div class="success-msg">{{userAddGroupMsg}}</div>
</p-dialog>


<!-- Profile Setting Save -->

<p-dialog [(visible)]="profileFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
    [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Confirmation</h6>
        </div>
    </p-header>
    <div class="success-msg"> Profile Setting Saved Successfully!</div>
</p-dialog>

<!--Confirmation popup window-->
<!-- Jira:4703 : Confirmation Dialog before delete -->
<p-dialog [(visible)]="showPopUpDelete" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="reage-title">CONFIRM ACTION</h6>
            <!-- Jira:4703 : close button on dialog for confirmation of delete -->
            <button type="button" (click)="onCancel()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">            
        </button>
        </div>
    </p-header>
    <div class="reage-body mx-2">
        <div class="d-flex flex-row align-items-center mb-4 pb-4">
            <label>{{confirmMsgText}}</label>
        </div>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button class="btn search-btn mr-4" (click)="onOk()">Ok</button>
            <button type="reset" class="btn clear-btn" (click)="onCancel()">Cancel</button>
        </div>
    </p-footer>
</p-dialog>

<!-- C153176-4445 |  Inquiry Rules Upload Message -->
<p-dialog [(visible)]="inquiryRulesUpload" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
    [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">{{inquiryRulesMsgTitle}}</h6>
        </div>
    </p-header>
    <div class="success-msg">{{inquiryRulesMsg}}</div>
</p-dialog>

<!-- C153176-4445 |  Inquiry Rules Upload Message - Confirmation box -->
<p-dialog [(visible)]="inquiryRulesConfirm" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{'max-width':'500px','width':'50%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="reage-title">CONFIRM ACTION</h6>
            <button type="button" (click)="onInquiryRulesCancel()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">            
        </button>
        </div>
    </p-header>
    <div class="reage-body mx-2">
        <div class="d-flex flex-row align-items-center mb-4 pb-4">
            <label>{{inquiryRulesMsg}}</label>
        </div>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button class="btn search-btn mr-4" (click)="onInquiryRulesConfirm()">Ok</button>
            <button type="reset" class="btn clear-btn" (click)="onInquiryRulesCancel()">Cancel</button>
        </div>
    </p-footer>
</p-dialog>

<p-dialog [(visible)]="sessionAlertPopup" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{'max-width':'500px','width':'50%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="reage-title">CONFIRM ACTION</h6>
            <button type="button" (click)="closeSessionPopup()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">            
        </button>
        </div>
    </p-header>
    <div class="reage-body mx-2">
        <div class="d-flex flex-row align-items-center mb-4 pb-4">
            <label>Session will time out in 5 mins. click Ok to continue.</label>
        </div>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button class="btn search-btn mr-4" (click)="activateSession()">Ok</button>
            <button type="reset" class="btn clear-btn" (click)="closeSessionPopup()">Cancel</button>
        </div>
    </p-footer>
</p-dialog>

<!-- C153176-4445 |  Inquiry Rules Upload - Reject Row Message -->
<p-dialog [(visible)]="inquiryRulesRejected" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{'max-width':'550px','width':'100%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="reage-title">WARNING</h6>
            <button type="button" (click)="onInquiryRulesRejectedConfirm()" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg" alt="Close">            
            </button>
        </div>
    </p-header>
    <div class="reage-body mx-2">
        <div class="d-flex flex-column reject-list">
            <label *ngFor="let rowMessage of invalidRowMessage">{{rowMessage}}</label>
        </div>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button class="btn search-btn mr-4" (click)="onInquiryRulesRejectedConfirm()">Ok</button>
        </div>
    </p-footer>
</p-dialog>

<p-toast position="center" (onClose)="onReject()" styleClass="custom-toast" key="custom" [baseZIndex]="5000"></p-toast>

<!-- group setup -->
<div *ngIf="groupSetupBoolean">
    <app-group-setup [display]="groupSetupBoolean" (modalClosed)="onGrpSetupMdlClosed($event)"></app-group-setup>
</div>

<!-- User Amdin -->
<div *ngIf="displayUserAdmin">
    <app-user-admin [isOpen]="displayUserAdmin" (modalClosed)="closeQMAAdmin($event)"></app-user-admin>
</div>
<!-- Send User Notification -->
<div *ngIf="displaySendUserNotification">
    <app-send-user-notification [isOpen]="displaySendUserNotification" (modalClosed)="closeSendEmailNotification($event)"></app-send-user-notification>
</div>
<!-- C153176-5516 -->
<div *ngIf="displayDatabaseAdmin">
    <app-database-admin [isOpen]="displayDatabaseAdmin" (modalClosed)="closeDatabaseAdmin($event)" [isDBAdmin] = "isDBAdmin"></app-database-admin>
</div>
<!-- Mailbox-DL Mapping setup -->
<div *ngIf="isMailBoxDLMappingPopup">
    <app-mailbox-dl-mapping [display]="isMailBoxDLMappingPopup" (modalClosed)="closeMailboxDLMappingPopup($event)"></app-mailbox-dl-mapping>
</div>
<!-- User-group-assignment setup -->
<div *ngIf="isUserGroupAssignmentPopup">
    <app-user-group-assignment [isOpen]="isUserGroupAssignmentPopup" (modalClosed)="closeUserGroupAssignment($event)"></app-user-group-assignment>
</div>
<!-- bulk admin setup-->
<div *ngIf="displayAdminSetupPopup">
    <app-admin-setup [isOpen]="displayAdminSetupPopup" (modalClosed)="closeAdminSetup($event)"></app-admin-setup>
</div>
<!--for super admin setup-->
<div *ngIf="displaySuperAdminSetup">
    <app-super-admin-setup [isOpen]="displaySuperAdminSetup" (modalClosed)="closeSuperAdminDialog($event)"></app-super-admin-setup>
</div>
