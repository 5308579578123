import { Component, OnInit, Input, OnDestroy, ViewChildren, QueryList, ElementRef, OnChanges, SimpleChanges, SimpleChange, Renderer2, ViewChild, HostListener, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AllToCcDBUser } from '../../model/LoginUserInfo/AllToCcDBUser';
import { UserDataService } from '../../services/user-data.service';
import { AllActiveGroup } from 'src/app/model/LoginUserInfo/AllActiveGroup';
import { ExpTinyComponent } from 'src/app/common/component/exp-tiny/exp-tiny.component';
import { HttpClient, HttpHeaders } from "@angular/common/http";
//C153176-4156 this 'to' work same as new message 'to'
import  * as _ from 'underscore';

import { InboxService } from 'src/app/services/inbox.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SaveDraft, SendInquiry } from '../../model/NewMessage/SaveDraft';
import { NewMessageService } from '../../services/newMessage/new-message.service';
import { AppUtils } from '../../common/utility/appUtil';
import { ConfirmationService } from 'primeng/api';
import { QmaConstant } from "src/app/constant/qma-constant";
import { DatePipe } from "@angular/common";
/* import { OverlayPanel } from "primeng//overlaypanel"; */
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { Observable, Observer, Subscription } from 'rxjs';
import { GfidSearchComponent } from "src/app/common/component/gfid-search/gfid-search.component";
import { RequestCacheService } from "src/app/services/cache/request-cache.service";
import { UserSignature } from 'src/app/model/LoginUserInfo/UserSignature';
import { GlobalDirectoryService } from 'src/app/services/global-directory/global-directory.service';
const EMAIL_ADDR = "EMAIL_ADDR";
const USER_NAME = "USER_NAME";
import * as $ from 'jquery';
import { SelectedMailModel } from '../model/selected-mail.model';
import { SymphonyChatServiceService } from '../service/symphony-chat-service.service';
import { OverlayPanel } from 'primeng/overlaypanel';

interface attachmentOption {
  name: string,
  id: string,
  fileInfo: any
}

interface Recipient {
  displayName: string,
  emailAddr: string,
  groupId: number,
  toFrom: string,
  userId: string,
  isExternal: boolean,
  participantName: string
}

//C153176-4793: User Feedback 
const USER_FEEDBACK_MSG = "Help us improve the chat experience by identifying unwanted information what you see in the chat bubble."

@Component({
  selector: 'app-symphony-chat-view',
  templateUrl: './symphony-chat-view.component.html',
  styleUrls: ['./symphony-chat-view.component.scss'],
  providers: [ConfirmationService]
})
export class SymphonyChatViewComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy { // C153176-5082
  chatForm: FormGroup = this.builder.group({
    toUsers: new FormControl('', [Validators.required]),
    ccUsers: new FormControl('', []),
    bccUsers: new FormControl('', []),
    requestType: new FormControl('', [Validators.required]),
    inquirySource: new FormControl('', []),
    processingRegion: new FormControl('', []),
    queryCount: new FormControl('', []), // C153176-5397 : Query count
    rootCause: new FormControl('', []),
    editorData: new FormControl('', []),
    attachments: new FormControl('', []),
    tagList: new FormControl('', []),
    gfcId: new FormControl('', []),
    gfcName: new FormControl('', []),
    gfpId: new FormControl('', []),
    gfpName: new FormControl('', [])
  });


  @Input() selectedMail: any;
  @Input() tabNameforChatView: string = "";
  // Mail sent from assigned from 
  @Input() assignedGroup: string;

  // Emitter for Attachments, Notes and Audits
  @Output() attachmentEvent = new EventEmitter();
  @Output() notesEvent = new EventEmitter();
  @Output() auditsEvent = new EventEmitter();

  resolve: boolean = false;
  groupName: string = '';
  allActiveGroups: AllActiveGroup[] = [];
  allToCcDBUser: AllToCcDBUser[] = [];
  toUsersList: AllToCcDBUser[] = [];
  finalUsersList: AllToCcDBUser[] = [];
  ccUsersList: AllToCcDBUser[] = [];
  tempCCUserList: AllToCcDBUser[] = [];
  requestTypeMap = new Map<string, string[]>();
  rootCauseMap = new Map<string, string[]>();
  tagsMap = new Map<string, string[]>();
  processingRegionMap = new Map<string, string[]>();
  requestTypes = [];
  rootCauses = [];
  inquirySourceList = [];
  processingRegions = [];
  tags = [];
  toUsers = [];
  filteredToUsersMultiple = [];
  formatFlg: boolean = false;
  bottomBarExpand: boolean = false;
  allUsers = [];
  allUsersSymphony = [];
  MAX_USER_DISPLAY: number = 5;
  displayParticipant = false;
  displayParticipantSymphony = false;
  subject: string = "";
  @ViewChild('uploadFiles') uploadFiles: ElementRef;
  @ViewChild('chatBody') chatBody: ElementRef;
  files: FileList;
  attachmentPwdProtectionFlag: string = '';
  postDataForAttachmentFile: any = [];
  attachmentLength: any = 0;
  isElipsisShow: boolean = false;
  attachmentsOptions: any = [];
  gfpidgfcid = '';
  @ViewChild(GfidSearchComponent, { static: true }) GfidSearchComponent: any;
  MAX_HEIGHT: number = 295;
  conversationList: Array<any>;
  readMore: Array<any>;
  displayFullMsg = false;
  originalMsg: boolean = false;
  originalContent: any;
  fullContent: any;
  closeFlagInvalid: boolean = false;
  displayInfo: boolean = false;

  replyActionObj: any = {};
  showDataForAttachmentFile: any = [];
  editorId = "";

  loginUserInfo: any;
  delayLoad = false;
  WILD_CHARACTERS = ['&lt;![endif]--&gt;&lt;![endif]--&gt;', '&lt;![endif]--&gt;', '<![endif]--><![endif]-->', '<![endif]-->', '<!--[if gte mso 10]>']; // C153176-5833

  @ViewChildren('contentHolder') contentHolder: QueryList<ElementRef>;
  @ViewChild('participantToggle') ptoggleButton: ElementRef;
  @ViewChild('participantOverlay', { static: true }) participantOverlay: ElementRef;
  @ViewChild('bottomBar') bottomBar: ElementRef;
  @ViewChild('resolveFlag') resolveFlag: ElementRef;
  @ViewChild('editorApp') editorApp: ExpTinyComponent;
  @Input('setChatText') setChatText: string;
  chatText = "";
  @Output() clearChatTextFromInbox = new EventEmitter();
  mandatoryForResolveFlags = [];

  audits: any[] = [];  // Audits
  notes: any[] = []; // Notes
  attachments: any[] = [];// Attachments
  attachmentList: any[] = [];// Attachments

  //C153176-4793: User feedback
  userFeedbackMsg: string;
  feedbackItems: any;
  selectedFeedbackItems: any[] = [];
  feedbackFlag: boolean = false;

  // C153176-4660: selectedMailRequest from parent component
  @Input() selectedMailRequest: any;
  @Input() parentComponent: any;
  // C153176-5082: websocket subscription
  private webSocketSubscription: Subscription;
  recipientsMap = new Map<string, any[]>();
  extRecipients = [];

  //display suggestion popup
  displaySuggestion: boolean = false;
  suggestionRecords: any[];
  suggestionField: string = '';
  chatConveration: string = '';
  isSymphonyServiceError = false;
  //below will allow only letter,digit,space and ',' 
  userNameAndSoeIdRegEx = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 ,]*)$/;
  showSpinner = true;
  constructor(private renderer: Renderer2, private userDataService: UserDataService, private builder: FormBuilder, private inboxService: InboxService, private sanatized: DomSanitizer,
    private newMessageService: NewMessageService, private confirmationService: ConfirmationService, private datePipe: DatePipe,
    private wsService: WebsocketService, private httpClient: HttpClient, private requestCacheService: RequestCacheService,
    private globalDirectoryService: GlobalDirectoryService, private symphonyChatServiceService: SymphonyChatServiceService) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.ptoggleButton && !this.ptoggleButton.nativeElement.contains(event.target) && !this.participantOverlay.nativeElement.contains(event.target)) {
        this.displayParticipant = false;
      }
      // if (this.bottomBar && !this.bottomBar.nativeElement.contains(event.target)) {
      //   this.bottomBarExpand = false;
      // }

    });
  }


  ngOnInit() {
    //this.getUserData();
    if (this.editorId === "")
      this.editorId = 'chat_editor_' + '_symphony' + sessionStorage.getItem("currentTab").replace(/ /g, "_");
    //C153176-4793: User feedback options
    this.displayInfo = false;
    this.userFeedbackMsg = USER_FEEDBACK_MSG;
    this.feedbackItems = QmaConstant.FEEDBACK_OPTIONS;
    this.loadAllSymphonyChatMmbersWS();
  }

  time = new Observable<string>((observer: Observer<string>) => {
    setTimeout(() => {
      if (this.editorId === "")
        //C153176-4301 - tab name space remove
        this.editorId = this.tabNameforChatView.replace(/ /g, "_");
      //this.editorId = 'chat_editor_' + sessionStorage.getItem("currentTab").replace(/ /g, "_");
      observer.next(new Date().toString());
    }, 300);
  });

  getUserData() {
    this.requestTypeMap = new Map<string, string[]>();
    this.rootCauseMap = new Map<string, string[]>();
    this.tagsMap = new Map<string, string[]>();
    this.requestTypes = [];
    this.rootCauses = [];
    this.allToCcDBUser = [];
    this.allActiveGroups = [];

    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.loginUserInfo = loginUserInfo;
      this.attachmentPwdProtectionFlag = loginUserInfo.attachmentPwdProtectionFlag;
      loginUserInfo.myGroups.forEach(myGroup => {
        this.requestTypeMap.set(myGroup.groupName, myGroup.requestTypes);
        this.rootCauseMap.set(myGroup.groupName, myGroup.rootCauseList);
        this.processingRegionMap.set(myGroup.groupName, myGroup.processingRegionList);
        this.tagsMap.set(myGroup.groupName, myGroup.tags);

        // C153176-5496 | Get the recipients of the group.
        let recipents = null;
        // Populate the recipients only when predictiveRecipients flag is true for the group.
        if (myGroup.predictiveRecipients && myGroup.recipients) {
          recipents = myGroup.recipients.map(rec => ({ text: rec, value: rec, email: rec }));
          this.recipientsMap.set(myGroup.groupName, recipents);
        }
      });
      //Get To,CC,BCC users list
      loginUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
        this.allToCcDBUser.push(toCcBccUsers);
      });
      //Get All groups
      loginUserInfo.allActiveGroups.forEach(activeGroup => {
        this.allActiveGroups.push(activeGroup);
      });

    });
  }
  // C153176-3245 - check for chat bubble should come in left or right
  isLoggedInUser(value) {
    if (this.loginUserInfo.userId == value || this.loginUserInfo.email == value || value.indexOf(this.loginUserInfo.userId) > -1)
      return true;
    else
      return false;
  }

  populateRequestType(groupName) {
    this.requestTypes = [];
    this.requestTypes.push({ label: 'Select', value: '' });

    this.requestTypeMap.forEach((value: string[], key: string) => {
      if (!this.isNull(value)) {
        if (key === groupName) {
          for (let i = 0; i < value.length; i++) {
            if (value[i]) //C153176-5146 : Issue-1 : Resolve in Chat view needs the 3 mandatory fields.
              this.requestTypes.push({ label: value[i], value: value[i] });
          }
        }
      }
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.requestTypes.length == 2) {
      this.chatForm.get('requestType').setValue(this.requestTypes[1].value);
    }
  }

  populateRootCause(groupName) {
    this.rootCauses = [];
    this.rootCauses.push({ label: 'Select', value: '' });

    this.rootCauseMap.forEach((value: string[], key: string) => {
      if (!this.isNull(value)) {
        if (key === groupName) {
          for (let i = 0; i < value.length; i++) {
            this.rootCauses.push({ label: value[i], value: value[i] });
          }
        }
      }
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.rootCauses.length == 2) {
      this.chatForm.get('rootCause').setValue(this.rootCauses[1].value);
    }
  }

  populateTags(groupName) {
    this.tags = [];
    this.tags.push({ label: 'Select', value: '' });

    this.tagsMap.forEach((value: string[], key: string) => {
      if (!this.isNull(value)) {
        if (key === groupName) {
          for (let i = 0; i < value.length; i++) {
            this.tags.push({ label: value[i], value: value[i] });
          }
        }
      }
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.tags.length == 2) {
      this.chatForm.get('tagList').setValue(this.tags[1].value);
    }
  }

  // C153176-5146: populate processing region
  populateProcessingRegion(groupName) {
    this.processingRegions = [];
    this.processingRegions.push({ label: 'Select', value: '' });

    this.processingRegionMap.forEach((value: string[], key: string) => {
      if (!this.isNull(value)) {
        if (key === groupName) {
          for (let i = 0; i < value.length; i++) {
            this.processingRegions.push({ label: value[i], value: value[i] });
          }
        }
      }
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.processingRegions.length == 2) {
      this.chatForm.get('processingRegion').setValue(this.processingRegions[1].value);
    }
  }

  // C153176-5146: populate inquiry source
  populateInquirySource() {
    this.inquirySourceList = [];
    this.inquirySourceList.push({ label: 'Select', value: '' });
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      //Get Inquiry Source
      loginUserInfo.inquirySourceList.forEach(inquirySource => {
        this.inquirySourceList.push({ label: inquirySource, value: inquirySource });
      });
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.inquirySourceList.length == 2) {
      this.chatForm.get('inquirySource').setValue(this.inquirySourceList[1].value);
    }
  }

  // C153176-5496 : Populate the extRecipients for the selected group.
  populateRecipients(groupName): void {
    this.extRecipients = [];
    this.extRecipients = this.recipientsMap.get(groupName) || [];
  }


  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    // skip first load without input
    /* if (changes.selectedMail && changes.selectedMail.firstChange) {
      return;
    } */
    // skip load for context menu 
    if (changes && changes.selectedMailRequest && changes.selectedMailRequest.currentValue && changes.selectedMailRequest.currentValue.streamId
      && changes.selectedMailRequest.previousValue && changes.selectedMailRequest.currentValue.streamId === changes.selectedMailRequest.previousValue.streamId
    ) {
      return;
    }
    if (changes.setChatText)
      this.chatText = changes.setChatText.currentValue;

    if (changes.selectedMail) {
      if (changes.selectedMail.previousValue)
        if (changes.selectedMail.currentValue.inquiry.id !== changes.selectedMail.previousValue.inquiry.id) {
          this.clearEditor('y'); this.chatText = "";
          // fix for C153176-5291 Chat view issues
          this.validateFormFields('N');
        }

      this.replyActionObj = {};
      this.getUserData();
      const mail: SimpleChange = changes.selectedMail;
      if (!mail.currentValue) {
        return;
      }
      let users = mail.currentValue.conversationList[0].recipients;
      // populate group members in header
      this.populateGroupMembers(mail.currentValue.inquiry.recipientGrps, mail.currentValue.inquiry.recipientSoeIds)
      /* users = users.map(_item => {
        return this.getModifiedRecipient(_item);
      });
      this.allUsers = this.removeDuplicates(users, "emailAddr"); */
      this.selectedMail = mail.currentValue;

      this.groupName = this.assignedGroup;
      this.populateRequestType(this.groupName);
      // C153176-5146: populate resolve-mandatory fields
      this.populateProcessingRegion(this.groupName);
      this.populateRootCause(this.groupName);
      this.populateTags(this.groupName);
      this.populateInquirySource();

      // C153176-5496 | Populate the recipients for the group, that will availbale as suggestion.
      this.populateRecipients(this.groupName);

      //setting non EDITABLE fields for Chat view
      this.subject = this.selectedMail.inquiry.subject;
      this.onReplyConversation(QmaConstant.REPLY_ALL, mail.currentValue.conversationList[0]);

      this.audits = [];
      this.notes = [];
      this.attachments = [];
      this.attachmentList = [];

      this.audits = this.selectedMail.inquiry.workflowAudit;
      this.notes = this.selectedMail.inquiry.userNotes;

      // Pass the audits and notes to parent
      this.notesEvent.emit(this.notes);
      this.auditsEvent.emit(this.audits);

      //  C153176-4660: Load all the chat view content
      if (changes.selectedMailRequest && changes.selectedMailRequest.currentValue && mail.currentValue) {
        // ws redesign change
        this.conversationList = [];
        this.loadAllContent(Object.assign({}, changes.selectedMailRequest.currentValue), mail.currentValue.inquiry);
        // load all participants of symphony Chat
        this.loadAllSymphonyChatMmbers();
        // load symphony data related to inqId like audit and notes
        this.loadNotesAndAudits(changes)
      }

    }
    //C153176-4301 - tab name space remove
    this.editorId = this.tabNameforChatView.replace(/ /g, "_");
  }

  updateInquiryChanges(tempInquiry: any) {
    this.replyActionObj = {};
    this.getUserData();
    const mail = tempInquiry;
    let users = mail.conversationList[0].recipients;
    users = users.map(_item => {
      return this.getModifiedRecipient(_item);
    });
    this.allUsers = this.removeDuplicates(users, "emailAddr");
    this.selectedMail = mail;

    this.groupName = this.assignedGroup;
    this.populateRequestType(this.groupName);
    this.populateRootCause(this.groupName);
    this.populateTags(this.groupName);
    this.populateProcessingRegion(this.groupName);
    this.populateInquirySource();

    // C153176-5496 | Populate the recipients for the group, that will availbale as suggestion.
    this.populateRecipients(this.groupName);

    //setting non EDITABLE fields for Chat view
    this.subject = this.selectedMail.inquiry.subject;
    this.onReplyConversation(QmaConstant.REPLY_ALL, mail.conversationList[0]);
    this.conversationList = [];
    this.attachments = [];
    this.attachmentList = [];

    //  C153176-4660: Load all the chat view content after chat is send
    if (this.selectedMailRequest && mail) {
      this.loadAllContent(Object.assign({}, this.selectedMailRequest), mail);
    }
  }

  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  setRecipentFields(replyActionObj: any) {
    this.resolve = false;  //Jira : C153176-5146 : issue-3.If user selects resolve for one inquiry in chat view selection appears for other inquiries also.
    //fetching from to
    let usersList = replyActionObj.templateData[0].shareToGrpList;
    this.toUsersList = usersList.filter((set => f => !set.has(f.id) && set.add(f.id))(new Set));
    //this.toUsersList = this.toUsersList.filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index);
    //fetching from cc
    this.ccUsersList = replyActionObj.templateData[0].ccList;
    //this.ccUsersList = this.ccUsersList.filter((value, index, self) => self.map(x => x.id).indexOf(value.id) == index)
    //merging both TO + CC
    if (this.ccUsersList) {
      this.toUsersList = this.toUsersList.concat(this.ccUsersList);
    }

    this.tempCCUserList = this.ccUsersList;
    // C153176-5402 : Auto Population if single data is available
    this.chatForm.get('requestType').setValue(this.getRequestType(replyActionObj.recipientData));
    this.chatForm.get('rootCause').setValue(this.getRootCause(replyActionObj.recipientData));
    this.chatForm.get('tagList').setValue(replyActionObj.recipientData.tag);
    this.setAttachments(replyActionObj.attachments);

    //Jira : C153176-5146 : issue-2.Gfpid remaining same for all the inquiries and appearing in chat view
    this.chatForm.get('inquirySource').setValue(this.getInquirySrc(replyActionObj.recipientData));
    this.chatForm.get('processingRegion').setValue(this.getProcessingRegion(replyActionObj.recipientData));
    this.chatForm.get('queryCount').setValue(replyActionObj.recipientData.queryCount); // C153176-5397 : Query count
    this.chatForm.get('gfcName').setValue(replyActionObj.recipientData.gfcName);
    this.chatForm.get('gfcId').setValue(replyActionObj.recipientData.gfcid);
    this.chatForm.get('gfpName').setValue(replyActionObj.recipientData.gfpName);
    this.chatForm.get('gfpId').setValue(replyActionObj.recipientData.gfpid);
    this.gfpidgfcid = (replyActionObj.recipientData.gfpid && replyActionObj.recipientData.gfcid) ? replyActionObj.recipientData.gfpid + " / " + replyActionObj.recipientData.gfcid : '';
  }

  setAttachments(attachments) {
    this.postDataForAttachmentFile = []; // making attachment empty for new reply action
    try {
      if (!this.isNull(attachments)) {
        for (let attachment of attachments) {
          this.postDataForAttachmentFile.push({
            "name": attachment.name, "id": attachment.id, "secure": attachment.secure, "fileInfo": attachment.fileInfo, "isSecuredByQMA": attachment.isSecuredByQMA
          })
        }
        this.chatForm.get('attachments').setValue(this.postDataForAttachmentFile);
      }
    } catch (e) {
    }
  }

  // C153176-5146: open GFID dialog
  callGFIDWindow() {
    this.GfidSearchComponent.defaultVal = this.chatForm.get('gfcId').value;
    this.GfidSearchComponent.triggerFalseClick();
  }

  getGFID(val: any) {
    this.gfpidgfcid = val.gfpId + " / " + val.gfcId;;
    this.chatForm.get('gfcId').setValue(val.gfcId);
    this.chatForm.get('gfcName').setValue(val.gfcName);
    this.chatForm.get('gfpId').setValue(val.gfpId);
    this.chatForm.get('gfpName').setValue(val.gfpName);
  }
  //C153176-4156 this 'to' work same as new message 'to'
  /**
       * Method to get the recipient list based on type of recipient
       * 
       * @param recipients - Mail Recipients 
       * @param type - Type of recipient
       */
  getRecipients(recipients: any, type: string): any {
    return recipients.filter(recipient => recipient.toFrom === type).map(recipient => {

      let emailAddr = recipient.emailAddr;
      if (emailAddr === null) {
        emailAddr = AppUtils.extractEmailAddrOrName(EMAIL_ADDR, recipient.displayName);
      }

      // C153176-4429 : Need to show alias name on reply
      let displayName = recipient.displayName;
      if (displayName.indexOf('<') > -1) {
        displayName = AppUtils.extractEmailAddrOrName(USER_NAME, recipient.displayName);
      }
      return {
        text: displayName,
        value: emailAddr,
        email: emailAddr,
        id: recipient.groupId,
        country: '',
        timeZone: '',
        active: true
      };
    });
  }

  tempWCData = "";

  ngAfterViewInit() {
    // passing wsUpdate from grid no need to subscribe again 
    // C153176-5082:: websocket handler subscribed to WebSocketService instead of opening a new connection
    this.webSocketSubscription = this.wsService.getwebSocketUpdate().subscribe(
      (response: MessageEvent): any => {
        try {
          if (!response.data) {
            return;
          }
          let wsData = JSON.parse(response.data);
          if (!wsData.symphonyNotificationList) {
            return;
          }
          this.updateOpenChatWindowByWs(wsData.symphonyNotificationList);
        }
        catch (ex) {
          console.log(ex);
        }
      }
    );
  }

  //code refector for char scroll
  viewUpdate() {
    //code to set hight for view more message popup
    setTimeout(() => {
      this.contentHolder.forEach((ele, idx) => {
        let currentHeight = ele.nativeElement.offsetHeight;
        if (currentHeight > this.MAX_HEIGHT) {
          this.readMore[idx].display = true;
        }
      })

      this.chatBody.nativeElement.scrollTop = this.chatBody.nativeElement.scrollHeight; //Scroll to Bottom on Load
    }, 100);
  }

  // C153176-5082: refactor websocket handler
  handleWebsocketMessage(response) {
    // C153176-5082: check response validity
    if (!response || !response.data) {
      return;
    }
    let data = JSON.parse(response.data), index: number = -1;
    if (data.length > 0) {

      let l = data.length;
      for (let i = 0; i < l; i++) {
        if (data[i]._id == this.selectedMail.inquiry.id) {
          index = i;
        }
      }

      if (index !== -1 && this.selectedMail.inquiry.id == data[index]._id) {
        let selectInqAssignedGroupId = [];

        //User should see only conversations for selected  groups only
        if (this.selectedMail.inquiry.workflows) {
          this.selectedMail.inquiry.workflows.forEach(workflow => {
            let assignedGroupId = workflow.assignedGroupId;
            if (this.userDataService.userGroupIdList.indexOf(assignedGroupId) > -1) {
              selectInqAssignedGroupId.push(assignedGroupId);
            }
          });
        }

        let inquiryData = {
          id: this.selectedMail.inquiry.id,
          selectInqAssignedGroupId: selectInqAssignedGroupId
        };
        this.inboxService.getInquiryIdSelected(inquiryData).subscribe(inquiryDetail => {
          if (JSON.stringify(inquiryDetail) !== JSON.stringify(this.selectedMail))
            this.updateInquiryChanges(inquiryDetail);
          // ngOnChanges will be called
        },
          error => {
            console.error('Error while getting response from getInquiryIdSelected()');
          });
      }

    }

  }

  onFullMessageClick(_idx: any) {
    this.displayFullMsg = true;
    this.fullContent = this.readMore[_idx].conversation;
  }

  hideModal() {
    this.displayFullMsg = false;
    this.originalMsg = false;
  }
  toggleParticipant() {
    this.displayParticipant = !this.displayParticipant;
  }
  toggleParticipantSymphony() {
    this.displayParticipantSymphony = !this.displayParticipantSymphony;
  }
  //C153176-4156 this 'to' work same as new message 'to'
  onKeyUp(event: KeyboardEvent, field = 'to') {
    let extEmail: boolean = false;
    if (event.key === "Enter") {
      let tokenInput = event.srcElement as any;
      let email = tokenInput.value.trim();
      // C153176-4930: refactoring the processing of email input to separate method 'handleEmail'
      extEmail = this.isExternalEmailInToCc(email);
      this.handleEmail(tokenInput, email, field);
    }
  }

  /**
   * C153176-4930: refactored method, handling user input on To/Cc/Bacc, shared between onBlur and onKeyUp
   */
  handleEmail(tokenInput, Temail, field) {
    this.filteredToUsersMultiple = [];
    Temail = Temail.split(';');
    let invalid = [], email = [];
    let isNonQmaCitiUser: boolean = false;
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    email = Temail.filter(value => {
      value = value.trim();

      //test and extract email from <foo@bar.com>
      if (/\<([^\>]+)\>/g.test(value)) {
        let tempArr = /\<([^\>]+)\>/g.exec(value);
        value = tempArr[1];
      }

      // C153176-5006 : If User enter soeid who has access to QMA application
      const appUser = this.allToCcDBUser.find(user => user.id === value);
      if (appUser) {
        return true;
      }

      //check for non QMA citi user
      isNonQmaCitiUser = this.validateNonQmaCitiUserSoeId(value);
      if (isNonQmaCitiUser) {
        return true;
      }

      // Push invalid email or soied to display invalid message.
      if (value !== "" && !re.test(value)) {
        invalid.push(value);
        return false;
      } else {
        return true;
      }
    });
    if (invalid.length > 0) {
      tokenInput.value = "";
      this.closeFlagInvalid = true;
      setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
    }

    if (email.length) {
      email.forEach(async element => {
        element = element.trim();
        if (/\<([^\>]+)\>/g.test(element)) {
          let tempArr = /\<([^\>]+)\>/g.exec(element);
          element = tempArr[1];
        }
        if (element !== "") {
          let item = new AllToCcDBUser();

          // C153176-5006 : If User enter soeid who has access to QMA application
          const appUser = this.allToCcDBUser.find(user => user.id === element);
          if (appUser) {
            item = { ...appUser };
          } else if (isNonQmaCitiUser) {
            // check for non QMA and Citi user
            const nonQmaCitiUser = await this.nonQmaCitiUser(element, field);
            if (nonQmaCitiUser) {
              item = { ...nonQmaCitiUser };
            } else {
              tokenInput.value = "";
              this.closeFlagInvalid = true;
              setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
              return;
            }
          } else {
            item = { text: element, value: element, email: element, id: '', active: false };
          }

          switch (field) {
            case "to":
              //if (!this.isDuplicate(item, 'to'))
              this.toUsersList.push(item);
              this.chatForm.get('toUsers').patchValue(this.toUsersList);
              tokenInput.value = "";
              break;
          }
        }
      });
    }
  }

  filterUsers(event) {
    let query = event.query;
    let filtered = [];

    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    this.allActiveGroups.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    // C153176-5496 | External Recipents for suggestion.
    if (this.extRecipients) {
      this.extRecipients.forEach(record => {
        if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
          filtered.push(record);
        }
      });
    }
    this.filteredToUsersMultiple = filtered;
  }

  //   ngOnChanges(changes: SimpleChanges): void {
  checkAttachments(_item: any) {
    if (_item.attachments) {
      return _item.attachment.length > 0;
    }
  }

  onDownloadAttachment(attachments: any) {
    this.inboxService.downloadAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership);
  }

  onDownloadAllAttachment(_inquiryId: number, _conversationId: number) {
    this.inboxService.downloadAllAttachments(_inquiryId, _conversationId);
  }

  sanatize(value: any): any {
    return this.sanatized.bypassSecurityTrustHtml(value);
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  //C153176-4156 this 'to' work same as new message 'to'
  /**
      * Method to determine Internal or External. Add flag to recipient object.
      */
  getModifiedRecipient(recipient: any): Recipient {
    // C153176-4429 : Add check for emailAddr is node in recipent
    let emailId = this.isNull(recipient.emailAddr) ? recipient.displayName : recipient.emailAddr;
    if (emailId.indexOf('<') > 0) {
      let id = emailId.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
      emailId = id ? id[0] : '';
    }
    let isExternal: boolean = this.isExternalEmailInToCc(emailId);
    isExternal = recipient.groupId ? false : isExternal;

    let participantName = AppUtils.extractEmailAddrOrName(USER_NAME, recipient.displayName);
    let rec: Recipient = {
      displayName: recipient.displayName,
      emailAddr: recipient.emailAddr,
      groupId: recipient.groupId,
      toFrom: recipient.toFrom,
      userId: recipient.userId,
      isExternal: isExternal,
      participantName: participantName
    };
    return rec;
  }

  /**
     * Method to check if the entered email is an external.
     * 
     * @param email - Entered Email Id
     */
  isExternalEmailInToCc(email: any): boolean {
    let loggedInUser = this.userDataService.loggedInUserInfo;
    if (!AppUtils.isUndefinedOrNull(email)) {
      let isNonQmaCitiUser = this.validateNonQmaCitiUserSoeId(email);
      if (email && !AppUtils.isCitiDomainEmail(email, loggedInUser.citiDomainList) && !isNonQmaCitiUser)
        return true;
    }
    return false;
  }

  /**
      * Method to get recipients (FROM, TO, CC) description and return to render in html
      *      * 
      * @param type - It might FROM, TO and CC
      * @param isRecent - It is recent Conversation
      */
  getConversationRecipients(conversation: any): any {
    let toList: string[] = [];
    let ccList: string[] = [];
    let bccList: string[] = [];
    let from = '';

    // C153176-5000: make sure displayName is populated if email id is present
    this.preProcessRecipients(conversation);

    // Recipients with external flag
    let fromRecipient: Recipient;
    let toRecipients: Recipient[] = [];
    let ccRecipients: Recipient[] = [];
    let bccRecipients: Recipient[] = [];

    let conversationCopy = { ...conversation };
    conversation.recipients.forEach(recipient => {
      if (recipient.toFrom === QmaConstant.FROM) {
        from = recipient.displayName;
        fromRecipient = this.getModifiedRecipient(recipient);
      }
      else if (recipient.toFrom === QmaConstant.TO) {
        toList.push(recipient.displayName);
        toRecipients.push(this.getModifiedRecipient(recipient));
      }
      else if (recipient.toFrom === QmaConstant.CC) {
        ccList.push(recipient.displayName);
        ccRecipients.push(this.getModifiedRecipient(recipient));
      }
      else if (recipient.toFrom === QmaConstant.BCC) {
        bccList.push(recipient.displayName);
        bccRecipients.push(this.getModifiedRecipient(recipient));
      }
    });

    conversationCopy.from = from;

    conversationCopy.to = toList.join(", ");
    conversationCopy.cc = ccList.join(", ");
    conversationCopy.bcc = bccList.join(", ");

    // Recipients List
    conversationCopy.toRecipients = toRecipients;
    conversationCopy.ccRecipients = ccRecipients;
    conversationCopy.bccRecipients = bccRecipients;
    conversationCopy.fromRecipient = fromRecipient;

    // Add property for expand or collapse message content.
    return conversationCopy;
  }

  /**
     * C153176-5000: make sure displayName is populated if email id is present
    */
  preProcessRecipients(conversation) {
    if (!conversation || !conversation.recipients) {
      return;
    }
    conversation.recipients.forEach(recipient => {
      if (recipient) {
        if (!recipient.displayName && recipient.emailAddr) {
          recipient.displayName = recipient.emailAddr;
        }
      }
    });
  }

  sendInquiry($event) {
    //  C153176-4022 - overflow issue of bubble content
    if (this.editorApp.getData().trim() === "") {
      console.log('can not be blank');
      return;
    }
    // TODO need api to save html currently saving only test
    const chatMsgPlainText = this.extractContent(this.editorApp.getData());
    let reqObj = {
      message: chatMsgPlainText,
      data: '',
      // streamId: this.selectedMailRequest.streamId
    };
    this.symphonyChatServiceService.postMessageToChatRoom(reqObj, this.selectedMailRequest.streamId, this.loginUserInfo.userId, this.loginUserInfo).subscribe((res: any) => {
      console.log(res);
      this.doExpand(true);
    }, (error) => {
      console.log(error);
      this.isSymphonyServiceError = true;
    });
    this.clearEditor();
  }

  /*
  * clearing editor
  * collapsing bottom Bar
  * setting up the very first conversation as default
  */
  cancel($event) {
    this.confirmationService.confirm({
      message: 'Do you want to close ?',
      accept: () => {
        this.clearEditor();
        this.onReplyConversation(QmaConstant.REPLY_ALL, this.selectedMail.conversationList[0]);
        this.doExpand(true);
      }
    });
  }
  // C153176-5146 : Issue-1 : Resolve in Chat view needs the 3 mandatory fields.
  markResolve() {
    if (this.resolve == false) {
      this.resolveFlag.nativeElement.value = "Y";
      this.resolve = true;
      if (this.validateFormFields('Y')) {
        return false;
      }
    }
    else {
      this.resolveFlag.nativeElement.value = "N";
      this.resolve = false;
      if (this.validateFormFields('N')) {
        return false;
      }
    }
  }

  saveDraft($event) {
    let token = AppUtils.getToken();
    let content = this.editorApp.getData();
    let fontStyle = this.editorApp.getSelectedFont();
    let groupName = (this.groupName) ? '' : this.groupName;
    let inquirySource = "";
    let rootCause = (this.isNull(this.chatForm.value.rootCause)) ? '' : this.chatForm.value.rootCause;
    let processingRegion = "";
    let queryCount = "";
    let gfpId = "";
    let gfpName = "";
    let gfcId = (this.selectedMail.inquiry.gfcid) ? '' : this.selectedMail.inquiry.gfcid;
    let gfcName = (this.selectedMail.inquiry.gfcName) ? '' : this.selectedMail.inquiry.gfcName;
    let subject = (this.isNull(this.subject)) ? '' : this.subject;
    let requestType = (this.isNull(this.chatForm.value.requestType)) ? '' : this.chatForm.value.requestType;
    let draftId = this.newMessageService.getDraftId();
    let attachment = (this.isNull(this.chatForm.value.attachments)) ? [] : this.chatForm.value.attachments;
    let tags = (this.isNull(this.chatForm.value.tagList)) ? '' : this.chatForm.value.tagList;

    if (this.tempCCUserList.length)
      this.makeCC();
    else
      this.finalUsersList = this.toUsersList;

    let save = new SaveDraft(draftId, this.finalUsersList, this.ccUsersList, groupName,
      this.selectedMail.inquiry.urgentFlag, subject, requestType, attachment, "NEW DRAFT", false, false, token, true, fontStyle.name, fontStyle.size,
      inquirySource, rootCause, processingRegion, tags, queryCount, gfpId, gfpName, gfcId, gfcName, []);

    console.log("final to cc ----", this.finalUsersList, this.ccUsersList);

    this.newMessageService.saveDraft(save, content).subscribe(result => {
      console.log('result of saving draft:', result);
      this.newMessageService.setInsertedDraftIds(result);
      this.doExpand(true);
      this.clearEditor('y');
      this.clearEditor();
    },
      (error) => {
        console.log('error while saving draft:', error);
      }
    );
  }

  uploadFile() {
    this.uploadFiles.nativeElement.click();
  }

  fileEvent(e) {
    this.files = e.target.files;
    let data = new FormData();
    data.append("filesTobePwdProtected", "");
    data.append("fileInfo", "");
    data.append("filesAlreadySecured", "");
    data.append("attachmentPwdProtectionFlag", this.attachmentPwdProtectionFlag);
    if (this.files) {
      for (let i = 0; i < this.files.length; i++) {
        data.append('files', this.files.item(i), this.files.item(i).name);
      }
    }

    this.newMessageService.uploadMultipleFiles(data).subscribe(result => {
      console.log('result of file upload:', result);
      this.setAttachmentData(result);
    },
      (error) => {
        console.log('error while upload :', error);
      }
    );
  }

  fotmatToggle() {
    this.formatFlg = !this.formatFlg;
  }

  setAttachmentData(attachments) {
    try {
      if (!this.isNull(attachments)) {
        for (let attachment of attachments) {
          this.postDataForAttachmentFile.push({
            "name": attachment.name, "id": attachment.id, "secure": attachment.secure, "fileInfo": attachment.fileInfo, "isSecuredByQMA": attachment.isSecuredByQMA
          })
        }
        this.chatForm.get('attachments').setValue(this.postDataForAttachmentFile);
      }
    } catch (e) {
    }
  }

  checkOverFlow(element) {
    if (element.clientWidth < element.scrollWidth) {
      this.attachmentLength = Math.floor(element.clientWidth / 127);
      return true;
    }
  }

  showRemainingAttchment(event, overlaypanel: OverlayPanel, attachmentList: any): void {
    this.attachmentsOptions = [];
    for (let i = this.attachmentLength; i < attachmentList.length; i++) {
      let attachInfo: attachmentOption = {
        name: attachmentList[i].name,
        id: attachmentList[i].id,
        fileInfo: attachmentList[i]
      };
      this.attachmentsOptions.push(attachInfo);
    }
    overlaypanel.toggle(event);
  }

  // delete attached file when click on delete icon ;
  deleteAttachedFile(id) {
    let elem = document.getElementById(id);
    elem.remove();
    if (this.postDataForAttachmentFile.length === this.attachmentLength + 1) {
      this.isElipsisShow = true;
    }
    for (let i = 0; i < this.postDataForAttachmentFile.length; i++) {
      if (this.postDataForAttachmentFile[i].id === id) {
        this.postDataForAttachmentFile.splice(i, 1);
      }
    }

    this.chatForm.get('attachments').setValue(this.postDataForAttachmentFile);
    console.log('attached files :', this.postDataForAttachmentFile);
  }

  //delete attached file present inside overlay panel
  deleteAttachedFilePanel(id) {


    for (let i = 0; i < this.attachmentsOptions.length; i++) {
      if (this.attachmentsOptions[i].id === id) {
        this.attachmentsOptions.splice(i, 1);
      }
    }
    for (let i = 0; i < this.postDataForAttachmentFile.length; i++) {
      if (this.postDataForAttachmentFile[i].id === id) {
        this.postDataForAttachmentFile.splice(i, 1);
      }
    }
    this.chatForm.get('attachments').setValue(this.postDataForAttachmentFile);
    console.log('attached files :', this.postDataForAttachmentFile);
  }

  downloadAttachment(docId: string, docName: string, convId: number, groupId: number, hasNominatedOwnership: boolean): void {
    this.inboxService.downloadAttachment(docId, docName, convId, groupId, hasNominatedOwnership);
  }


  /**
   * Method to perform action on reply action based on type for a conversation
   * 
   * @param replyType - Reply Type
   * @param conversation - Conversation object
   */
  onReplyConversation(replyType: string, conversation: any, _panel?: any): void {
    if (_panel) { _panel.hide(); this.doExpand(true); } //C153176-4349 when clicked on Reply/Reply All/Reassign
    this.chatForm.get("rootCause").clearValidators();
    this.chatForm.get("rootCause").setErrors(null);
    this.chatForm.get("rootCause").updateValueAndValidity();

    this.chatForm.get("tagList").clearValidators();
    this.chatForm.get("tagList").setErrors(null);
    this.chatForm.get("tagList").updateValueAndValidity();
    //C153176-4156 recipent should populate properly 
    let tabName = "NEW MESSAGE";
    const recipients = conversation.recipients;
    let subject = this.updateSubject(conversation.subject);
    let content = conversation.content;
    if (!content) {
      content = ''; // C153176-4660: set content to empty if it is null or undefind
    }
    let urgentFlag = "N";
    let subPrefix = "RE: ";

    let toRecipient: any = [];
    let ccRecipient: any = [];
    //C153176-4156 this 'to' work same as new message 'to'
    let bccRecipient: any = [];
    let attachments = (this.isNull(conversation.attachments)) ? [] : conversation.attachments;

    // C153176-4459 : Flag to set if reply action is initiated from NLP acceptance.
    let nlpSuggestion: boolean = false;

    // C153176-4594 : Flag to set if reply action is initiated from CLC Suggestion acceptance.
    let clcSuggestion: boolean = false;

    let from: any = this.getRecipients(recipients, "FROM");
    //Array.prototype.push.apply(toRecipient, from);

    let replyContent: string = '';

    switch (replyType) {
      case QmaConstant.REPLY:
        tabName = QmaConstant.REPLY;
        // C153176-4682 : Add condition for 'Sent' folder, 'TO' field should be populated with same TO recipents.
        toRecipient = this.tabNameforChatView !== 'SENT' ? this.getRecipients(recipients, "FROM") : this.getRecipients(recipients, "TO");
        attachments = [];
        break;
      case QmaConstant.REPLY_ALL:
        Array.prototype.push.apply(toRecipient, this.getRecipients(recipients, "FROM"));
        Array.prototype.push.apply(toRecipient, this.getRecipients(recipients, "TO"));
        Array.prototype.push.apply(ccRecipient, this.getRecipients(recipients, "CC"));
        tabName = QmaConstant.REPLY_ALL;
        attachments = [];
        break;
      case QmaConstant.REASSIGN:
        toRecipient = [];
        break;
      default:
        break;
    };
    //C153176-4156 this 'to' work same as new message 'to'
    // Get Unique values
    if (toRecipient.length > 0) {
      toRecipient = _.uniq(toRecipient, 'email');
    }
    if (ccRecipient.length > 0) {
      ccRecipient = _.uniq(ccRecipient, 'email');
    }
    if (bccRecipient.length > 0) {
      bccRecipient = _.uniq(bccRecipient, 'email');
    }

    // C153176-4224 : If group exculed is true,  We should not included assigned group in toRecipient.
    if (this.userDataService.isGroupExcluded(this.assignedGroup)) {
      toRecipient = toRecipient.filter(rec => rec.text !== this.assignedGroup);
    }

    // Message content with last mail header
    content = replyContent + this.getHeader(conversation) + content;

    // Logic to add NLP Suggested content
    if (conversation.suggestedContent) {
      content = conversation.suggestedContent + content;

      // C153176-4459 : Set the flag when it is nlp suggestion action
      nlpSuggestion = true;
    }
    subject = subPrefix + subject;

    /*C153176-4594 - #5 : Add CLC Suggestion Content on reply  */
    // Logic to add CLC Suggested content
    if (conversation.clcSuggestionContent) {
      content = conversation.clcSuggestionContent + content;
      clcSuggestion = true;
    }

    // // Message content with last mail header
    // content = this.getHeader(conversation) + content;
    const templateData = [
      {
        templateContent: content,
        templateSubject: subject,
        shareToGrpList: toRecipient,
        ccList: ccRecipient
      }
    ];

    const inquiry = this.selectedMail.inquiry;
    const workflow = inquiry.workflows[0];
    const notes = inquiry.userNotes;
    const recipientData = {
      from: this.assignedGroup,
      queryCount: workflow.queryCount,
      requestType: workflow.requestType,
      inquirySource: workflow.inquirySource,
      processingRegion: workflow.processingRegion,
      rootCause: workflow.rootCause,
      gfcName: inquiry.gfcName,
      gfcid: inquiry.gfcid,
      gfpName: inquiry.gpName,
      gfpid: inquiry.gpNum,
      tag: workflow.tag,
      fromWhere: 'messages',

    };

    this.replyActionObj = {
      inquiryId: conversation.inquiryId,
      inquiryAction: replyType,
      suggestionConvId: conversation.id,
      userNotes: notes,
      templateData: templateData,
      content: content,
      recipientData: recipientData,
      urgentFlag: urgentFlag,
      attachments: attachments
    }

    console.log('On Reply Action : ', this.replyActionObj);
    this.setRecipentFields(this.replyActionObj);
  }
  //C153176-4156 this 'to' work same as new message 'to'
  /**
     * Method to update the subject while reply or forward.
     */
  updateSubject(conversationSubject: string): string {
    let subject = conversationSubject;
    if (subject.indexOf('RE:') === 0) {
      subject = subject.replace(/RE:/gi, '');
    }
    if (subject.indexOf('FW:') === 0) {
      subject = subject.replace(/FW:/gi, '');
    }
    return subject.trim();
  }

  /**
 * Method to get the header for Reply actions
 */
  getHeader(conversation: any): string {
    let header = "";
    conversation = this.getConversationRecipients(conversation);
    const selectedFromGroup = this.selectedMail.inquiry.origGroupName;
    if (conversation !== null) {
      header += "<br><div id=\"bubleContentSeparator\"><span id=\"bubbleContentSeparatorSpan\"></span></div> "
        + this.getActionSignature() + "<br>"
        + this.getDisclaimerActionInquiry(selectedFromGroup)
        + "<br><hr style='color:rgb(204, 204, 204); border-style:solid;border-width: 0.05em;margin-bottom: 8px;'>";
      let convFrom = AppUtils.extractEmailAddrOrName(USER_NAME, conversation.from);
      let sentDate = this.datePipe.transform(conversation.modDate, 'd/MMM/yyyy h:mm:ss a');
      if (conversation.crtBy) {
        let externalEmailLink = '[mailto:' + conversation.crtBy + ']'
        header += "<strong>From: </strong>" + convFrom + externalEmailLink + '<br>';
      } else {
        header += "<strong>From: </strong>" + convFrom + '<br>';
      }
      header += "<strong>Sent: </strong>" + sentDate + '<br>';
      header += "<strong>To: </strong>" + conversation.to + '<br>';

      if (conversation.cc) {
        header += "<strong>Cc: </strong>" + conversation.cc + '<br>';
      }
      header += "<strong>Subject: </strong>" + conversation.subject + '<br><br>';
    }
    return header;
  }

  /**
   * C153176-5397 : Append signature of the user
   * Method to get the action signature
   */
  getActionSignature(): string {
    const defaultSignature: UserSignature = this.userDataService.getDefaultSignatureforReply();
    return defaultSignature && defaultSignature.content ? defaultSignature.content : "";
  }

  /**
   * C153176-5397 : Append disclaimer of the group
   * Method to get the disclaimer action inquiry from the selected from group
   */
  getDisclaimerActionInquiry(fromGrp: any): string {
    return this.userDataService.getDisclaimerForReply(this.assignedGroup);
  }

  /* Tool Mathods here for frequent actions in EMAIL as chat*/
  clearEditor(emit = "n") {
    if (emit == "n")
      this.editorApp.setData("");
    else
      this.clearChatTextFromInbox.emit("");
  }
  makeCC() {
    this.finalUsersList = [];
    this.ccUsersList = [];
    this.tempCCUserList.map((val) => {
      if (this.findByMatchingProperties(this.toUsersList, val)[0]) {
        this.ccUsersList.push(this.findByMatchingProperties(this.toUsersList, val)[0]);
      }
      this.finalUsersList = this.toUsersList.filter(item => !this.ccUsersList.includes(item))
      // !!! Read below about array.includes(...) support !!!

      console.log(this.toUsersList)
    })

    // this.ccUsersList = this.toUsersList.filter((val) => {
    //   return (val.toFrom == "CC");
    // })
    // this.toUsersList = this.toUsersList.filter((val) => {
    //   return (val.toFrom !== "CC");
    // })
  }
  doExpand(val: boolean) {
    this.bottomBarExpand = val;

  }
  findByMatchingProperties(set, properties) {
    return set.filter(function (entry) {
      return Object.keys(properties).every(function (key) {
        return (entry[key] === properties[key] || entry["value"] === properties["email"]
          || entry["email"] === properties["value"]);
      });
    });
  }
  loadData() {
    if (this.chatText)
      this.editorApp.setData(this.chatText);
    else
      this.clearEditor();
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  mandatoryForResolveParametersForGroupName(grpName) {
    this.mandatoryForResolveFlags = [];
    let isRootCauseMandatory = false;
    let isRootCauseMandatoryWOReply = false;
    let isProcessingRegionMandatory = false;
    let isInqSourceMandatory = false;
    let isGfidMandatory = false;
    var isTagMandatory = false;

    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        loginUserInfo.myGroups.forEach(fromData => {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) && !AppUtils.isUndefinedNullOrBlank(fromData.groupName) && fromData.groupName.toUpperCase() == grpName.toUpperCase()) {
            if (fromData.isRootCauseMandatory) {
              isRootCauseMandatory = true;
              if (fromData.isRootCauseMandatoryWOReply) {
                isRootCauseMandatoryWOReply = true;
              }
            }
            if (fromData.isProcessingRegionMandatory) {
              isProcessingRegionMandatory = true;
            }
            if (fromData.isInquirySourceMandatory) {
              isInqSourceMandatory = true;
            }
            if (fromData.isGfidMandatory) {
              isGfidMandatory = true;
            }
            if (fromData.isTagMandatory) {
              isTagMandatory = true;
            }
          }
        });
      });
    }
    this.mandatoryForResolveFlags.push({
      isRootCauseMandatory: isRootCauseMandatory,
      isProcessingRegionMandatory: isProcessingRegionMandatory,
      isInqSourceMandatory: isInqSourceMandatory,
      isRootCauseMandatoryWOReply: isRootCauseMandatoryWOReply,
      isGfidMandatory: isGfidMandatory,
      isTagMandatory: isTagMandatory
    });
  }

  validateFormFields(resolveFlag): boolean {
    // C153176-5146: reset resolve-mandatory fields
    ['rootCause', 'tagList', 'processingRegion', 'inquirySource', 'gfpId', 'gfcId'].forEach(field => {
      let elem = this.chatForm.get(field);
      if (elem) {
        elem.clearValidators();
        elem.setErrors(null);
        elem.updateValueAndValidity();
      }
    });

    this.mandatoryForResolveParametersForGroupName(this.groupName);
    let valid = false;
    if (resolveFlag === 'Y') {
      // C153176-5146: validate resolve-mandatory fields
      if (this.mandatoryForResolveFlags[0].isProcessingRegionMandatory &&
        (this.chatForm.controls["processingRegion"].untouched || this.chatForm.controls["processingRegion"].pristine) &&
        (this.isNull(this.chatForm.controls["processingRegion"].value))) {
        this.chatForm.controls["processingRegion"].setValidators(Validators.required);
        this.chatForm.controls["processingRegion"].setErrors({ notUnique: true });
        this.chatForm.controls["processingRegion"].updateValueAndValidity();
        console.log('Processing region should be mandatory');
        valid = true;
      }
      else {
        this.chatForm.get("processingRegion").clearValidators();
        this.chatForm.get("processingRegion").setErrors(null);
        this.chatForm.get("processingRegion").updateValueAndValidity();
      }

      if ((this.mandatoryForResolveFlags[0].isRootCauseMandatory || this.mandatoryForResolveFlags[0].isRootCauseMandatoryWOReply) &&
        (this.chatForm.controls["rootCause"].untouched || this.chatForm.controls["rootCause"].pristine) &&
        (this.isNull(this.chatForm.controls["rootCause"].value))) {
        this.chatForm.controls["rootCause"].setValidators(Validators.required);
        this.chatForm.controls["rootCause"].setErrors({ notUnique: true });
        this.chatForm.controls["rootCause"].updateValueAndValidity();
        console.log('Root Cause should be mandatory');
        valid = true;
      }
      else {
        this.chatForm.get("rootCause").clearValidators();
        this.chatForm.get("rootCause").setErrors(null);
        this.chatForm.get("rootCause").updateValueAndValidity();
      }

      if (this.mandatoryForResolveFlags[0].isTagMandatory && (this.chatForm.controls["tagList"].untouched || this.chatForm.controls["tagList"].pristine) &&
        (this.isNull(this.chatForm.controls["tagList"].value))) {
        this.chatForm.controls["tagList"].setValidators(Validators.required);
        this.chatForm.controls["tagList"].setErrors({ notUnique: true });
        this.chatForm.controls["tagList"].updateValueAndValidity();
        console.log('Tags should be mandatory');
        valid = true;
      }
      else {
        this.chatForm.get("tagList").clearValidators();
        this.chatForm.get("tagList").setErrors(null);
        this.chatForm.get("tagList").updateValueAndValidity();
      }
      // C153176-5146: validate resolve-mandatory fields
      if (this.mandatoryForResolveFlags[0].isInqSourceMandatory && (this.chatForm.controls["inquirySource"].untouched || this.chatForm.controls["inquirySource"].pristine)) {
        this.chatForm.controls["inquirySource"].setValidators(Validators.required);
        this.chatForm.controls["inquirySource"].setErrors({ notUnique: true });
        this.chatForm.controls["inquirySource"].updateValueAndValidity();
        console.log('Inquiry Source should be mandatory');
        valid = true;
      }
      else {
        this.chatForm.get("inquirySource").clearValidators();
        this.chatForm.get("inquirySource").setErrors(null);
        this.chatForm.get("inquirySource").updateValueAndValidity();
      }

      // C153176-5146: validate resolve-mandatory fields
      let isGfidMandatory = this.mandatoryForResolveFlags[0].isGfidMandatory;
      if (isGfidMandatory && this.isNull(this.chatForm.value.gfpId) && this.isNull(this.chatForm.value.gfcId)) {
        console.log("GFPID/GFCID is mandatory for resolve");
        this.chatForm.controls["gfpId"].setValidators(Validators.required);
        this.chatForm.controls["gfpId"].setErrors({ notUnique: true });
        this.chatForm.controls["gfpId"].updateValueAndValidity();
        this.chatForm.controls["gfcId"].setValidators(Validators.required);
        this.chatForm.controls["gfcId"].setErrors({ notUnique: true });
        this.chatForm.controls["gfcId"].updateValueAndValidity();
        valid = true;
      }
    }
    //C153176-5146 : Issue 1 : request type validation  issue    
    if (this.chatForm.controls.requestType.errors && (this.chatForm.controls.requestType.pristine || this.chatForm.controls.requestType.untouched))
      valid = true;

    return valid;
  }

  /**
   * Method to get list of attachment of each conversation and populate the attachment list
   */
  getAttachmentList(conversation: any, groupId: number): void {
    if (conversation.attachments) {
      let attachmentList = conversation.attachments;
      let inquiryId = conversation.inquiryId;
      let convId = conversation.id;

      attachmentList.forEach(attachment => {
        let attmnt = attachment;
        attmnt.time = conversation.modDate;
        attmnt.inquiryId = inquiryId;
        attmnt.convId = convId;
        attmnt.groupId = groupId;
      });
      Array.prototype.push.apply(this.attachments, attachmentList);
    }
  }

  /**
   * C153176-4793 : Method to get the selected feed back items
   * @param _evt 
   */
  getSelectedItems(_evt: any): void {
    this.selectedFeedbackItems = [];
    this.selectedFeedbackItems = _evt;
  }

  /**
   * C153176-4793 : Method to capture the user feedback and persist to db.
   * @param conversation 
   * @param overlaypanel 
   */
  onSendReport(conversation: any, overlaypanel: OverlayPanel): void {

    let feedback = this.selectedFeedbackItems.map(feedbackItem => {
      return feedbackItem['label'];
    });

    const feedbackObj = {
      correctionDataItem: feedback.join(),
      inquiryId: conversation.inquiryId,
      convId: conversation.id
    };

    this.inboxService.saveUserAIMLFeedback(feedbackObj).subscribe(result => {
      if (result) {
        if (result.success) {
          this.selectedFeedbackItems = [];
          this.feedbackFlag = true;
          //  Timeout Message after Chat feedback saved successfully
          setTimeout(() => { this.feedbackFlag = false; }, 3000);
        }
        console.log(result.message);
      }
    }, error => {
      throw new Error('Failed to save the user feedback');
    });
    overlaypanel.hide();
  }

  /**
   * C153176-4660: content of all the conversations
   */
  loadAllContent(inquiryData, inquiry) {
    if (!inquiryData) {
      console.log('chatView, skip loading all content');
      return;
    }
    inquiryData.isAllConvContent = true;
    inquiryData.isChatView = true; // C153176-5216 : The request is sent from chat view - All this flag to distinguish normal conversation view or chat view.
    console.debug('chatView, loading all content, request=', inquiryData);
    if (this.parentComponent) {
      //this.parentComponent.showSpinner = true;
      this.showSpinner = true;
      console.debug(":SPINNER:: " + this.parentComponent.showSpinner + " ::symphony-chat-view.loadAllContent");
    }
    this.getChatConversations(inquiryData, inquiry);
    /* this.inboxService.getInquiryIdExtSelected(inquiryData, inquiry).subscribe(inquiryDetail => {
      // set symphony data
      let selectedMailModel =$.extend(true, {},SelectedMailModel);
      selectedMailModel.conversationList[0].bubbleContent = "Hi From Symphony";
      this.selectedMail = selectedMailModel;
      this.selectedMailRequest = inquiryData;
      this.conversationList = [];
      this.getConversationChatView(this.selectedMail);

      // turn off spinner
      if (this.parentComponent) {
        this.parentComponent.showSpinner = false;
      }
      console.debug('chatView, loaded all content, request=', inquiryData);

      // C153176-5216: Update the cache from the chat view that would update the normal conversation view.
      this.requestCacheService.updateInquiryCache(inquiry, inquiryData.isAllConvContent, inquiryDetail);
    },
      error => {
        console.error('chatView, error while getting response from getInquiryIdExtSelected()');
      }); */
  }

  /**
   *  C153176-4660
    * Method to populate the Conversation Inquiry Chat View.
    * @param selectedMail - Selected Inquiry
    */
  getConversationChatView(selectedMail: any): void {
    // changes for C153176-5834
    this.selectedMail.conversationList.map((item) => {
      if (item.bubbleContent) {
        const bubble = item.bubbleContent;
        const bubbleContentProcessed = this.removeBottomSpace(bubble);
        const bubbleContent = bubbleContentProcessed === "" ? bubble : this.removeBottomSpace(bubble);
        item.bubbleContent = bubbleContent;
      }
    })
    Array.prototype.push.apply(this.conversationList, selectedMail.conversationList);
    this.conversationList.reverse();
    this.readMore = this.conversationList.map((item) => {
      let obj: any = {};
      obj.display = false;
      obj.conversation = item.bubbleContent;
      return obj;
    });

    this.hideModal();
    // C153176-5082: do not call ngAfterViewInit() from here.
    //this.ngAfterViewInit();
    // C153176-5082: funtion required after view loaded
    this.viewUpdate();
    this.doExpand(true);

    const totalConversations = this.selectedMail.conversationList.length;
    const groupId = this.selectedMail?.inquiry?.workflows[0]?.assignedGroupId;
    this.selectedMail.conversationList.forEach((conversation, idx) => {
      // Pass attachments to accumulate all the attachments
      this.getAttachmentList(conversation, groupId);
      if (idx === totalConversations - 1) {
        // Pass attachment list to parent
        this.attachmentEvent.emit(this.attachments);
      }
    });
  }
  // changes for C153176-5834
  removeBottomSpace(content: any) {
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(content, 'text/html');
    let viewContent = parsedHtml.querySelector('body').innerHTML;
    let newContent = viewContent.replace(/</gi, "<><");
    let contentArr = newContent.split("<>").filter(v => v != "");
    let len = contentArr.length;
    let start: number = 0;
    for (let i = len - 1; i >= 0; i--) {
      let temp = document.createElement('div');
      temp.innerHTML = contentArr[i];
      let ct = temp.textContent;
      if ((ct.trim().length == 0) || (ct === "")) {
        start = i;
      }
      else {
        let rm = contentArr.splice(start, len - start + 1);
        break;
      }
      temp = null;
    }
    let finalContent: string = "";
    for (let s in contentArr) {
      finalContent += contentArr[s];
    }

    return finalContent;
  }
  ngOnDestroy() {
    // C153176-5082: unsubscribe to ensure no memory leaks
    if (this.webSocketSubscription) {
      this.webSocketSubscription.unsubscribe();
    }
  }

  closeInfo() {
    this.displayInfo = false;
  }

  showOriginalMsg(content: any) {
    this.originalMsg = true;
    this.originalContent = content;
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // C153176-5402 : Auto Population if single data is available - Request Type
  getRequestType(recdata: any): string {
    this.populateRequestType(recdata.from);
    let requestType = this.getValidatedRequestType(recdata.requestType);
    if (this.requestTypes.length == 2 && this.isNull(requestType)) {
      requestType = this.requestTypes[1].value;
    }
    return requestType;
  }

  // C153176-5402 : Auto Population if single data is available - Inquiry Source
  getInquirySrc(recdata: any): string {
    this.populateInquirySource();
    let inquirySrc = recdata.inquirySource || "";
    if (this.inquirySourceList.length == 2 && this.isNull(inquirySrc)) {
      inquirySrc = this.inquirySourceList[1].value;
    }
    return inquirySrc;
  }

  // C153176-5402 : Auto Population if single data is available - Processing Region
  getProcessingRegion(recdata: any): string {
    this.populateProcessingRegion(recdata.from);
    let processRegion = recdata.processingRegion || "";
    if (this.processingRegions.length == 2 && this.isNull(processRegion)) {
      processRegion = this.processingRegions[1].value;
    }
    return processRegion;
  }

  // C153176-5402 : Auto Population if single data is available - Root Cause
  getRootCause(recdata: any): string {
    this.populateRootCause(recdata.from);
    let rootCause = recdata.rootCause || "";
    if (this.rootCauses.length == 2 && this.isNull(rootCause)) {
      rootCause = this.rootCauses[1].value;
    }
    return rootCause;
  }

  getValidatedRequestType(requestType) {
    const reqTypes = this.requestTypes.filter(e => e && e.value === requestType);
    if (reqTypes && reqTypes.length) {
      return requestType;
    } else {
      return '';
    }
  }

  // validate soeid and user name
  validateNonQmaCitiUserSoeId(value: string): boolean {
    return this.userNameAndSoeIdRegEx.test(value);//result;
  }

  //get non QMA Citi user 
  async nonQmaCitiUser(element: string, field: string): Promise<AllToCcDBUser> {
    let citiUser = null;
    try {
      let auId = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.auid;
      let contactInfoEndPoint = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.contactInfoEndPoint;

      const suggestionData = await this.globalDirectoryService.getContactSuggestion(element.toUpperCase(), "BASIC", auId, contactInfoEndPoint).toPromise();

      let jsonObj = this.globalDirectoryService.parseXmlToJson(suggestionData.toString());
      if (jsonObj && jsonObj.people && jsonObj.people.Person && jsonObj.people.Person instanceof Array) {
        //show popup
        console.log('show popup');
        this.displaySuggestion = true;
        this.suggestionField = field;
        this.suggestionRecords = jsonObj['people']['Person'];
      } else if (jsonObj && jsonObj.people && jsonObj.people.Person && jsonObj.people.Person instanceof Object) {
        let personObj = jsonObj['people']['Person'];
        citiUser = new AllToCcDBUser();
        citiUser.value = citiUser.email = personObj['EmailAddress']['_text'];
        if (citiUser.value.length === 0) {
          citiUser = null;
          return citiUser;
        }
        citiUser.id = personObj['SoeId']['_text'];
        citiUser.active = false;
        citiUser.text = personObj['Name']['_text'];
      }
    } catch (error) {
      console.error('Error in nonQmaCitiUser ', error);
      citiUser = null;
    }
    return citiUser;
  }
  addSuggestionToRecipient(citiUser: AllToCcDBUser) {
    if (citiUser && citiUser.value && citiUser.value.length > 0) {
      switch (this.suggestionField) {
        case "to":
          this.toUsersList.push(citiUser);
          this.chatForm.get('toUsers').patchValue(this.toUsersList);
          break;
      }
    }
    this.displaySuggestion = false;
  }
  getChatConversations(inquiryData, inquiry) {
    // to get email id from stphony entitlement
    this.symphonyChatServiceService.getSymphonyEntitlement().subscribe((entitlement: any) => {
      // const streamId = this.selectedGridRow.streamId;
      //const userEmail = this.userDataService.loggedInUserInfo.email;
      const userEmail = entitlement && entitlement.payload && entitlement.payload.email ? entitlement.payload.email : "";
      let reqObj = {
        streamId: inquiryData.streamId,
        userEmail: userEmail,
        limit: 10,
        fromDate: 0
      }
      this.symphonyChatServiceService.getChatConversations(reqObj, this.loginUserInfo).subscribe((chatConv: any[]) => {
        console.log(chatConv);

        // this.prepareConvAttachement(chatConv)
        // set symphony data
        let selectedMailModel = $.extend(true, {}, SelectedMailModel);
        /*   selectedMailModel.conversationList[0].bubbleContent = "Hi From Symphony"; */
        let chatData = { "payload": chatConv };
        this.updateConversationModelBySymphonyResponse(this.selectedMail, chatData);
        /* this.selectedMail = selectedMailModel;
        this.selectedMailRequest = inquiryData; */
        this.conversationList = [];
        this.getConversationChatView(this.selectedMail);

        // turn off spinner
        if (this.parentComponent) {
          // this.parentComponent.showSpinner = false;
          this.showSpinner = false;
          console.debug(":SPINNER:: " + this.parentComponent.showSpinner + " ::symphony-chat-view.getChatConversations");
        }

        console.debug('chatView, loaded all content, request=', inquiryData);
      },
        error => {
          if (!this.isSymphonyServiceError) {
            this.isSymphonyServiceError = true;
          }
          // turn off spinner
          if (this.parentComponent) {
            // this.parentComponent.showSpinner = false;
            this.showSpinner = false;
            console.debug(":SPINNER:: " + this.parentComponent.showSpinner + " ::symphony-chat-view.getChatConversations");
          }
        });
    });
  }

  updateConversationModelBySymphonyResponse(selectedMailModel, chatConv) {
    if (!chatConv || !chatConv.payload) {
      return;
    }
    let conv = $.extend(true, {}, selectedMailModel.conversationList[0]);
    selectedMailModel.conversationList = [];
    chatConv.payload.forEach((payload: any) => {
      let convtoUpdate = $.extend(true, {}, conv);
      convtoUpdate.bubbleContent = payload.message;
      convtoUpdate.fromInitials = payload.user.firstName[0] + payload.user.lastName[0];
      convtoUpdate.displayName = payload.user.displayName;
      convtoUpdate.timestamp = payload.timestamp;
      convtoUpdate.user = payload.user;
      convtoUpdate.symphonyId = payload.user.userId;
      convtoUpdate.soeId = payload.ssoId;
      selectedMailModel.conversationList.push(convtoUpdate);
      // check user internal or external
      /*    const req = {memberSoeId:payload.user.userId,memberType:'SymphonyId'};
         this.symphonyChatServiceService.getUserSymphonyIdByType(req).subscribe((res: any) => {
          
           this.selectedMail.conversationList.filter(ml => ml.user.userId === res.payload.id).forEach((cl: any) => {
             if(!cl.isInternal) {
               cl.isInternal = false;
             }
             cl.isInternal = res.payload.isInternal;
           }
          
             );
         
         }); */
    });
    this.updateAllSymphonyUserPresense(chatConv);
  }

  // TODO need api to save html currently saving only plain text
  extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  updateOpenChatWindowByWs(symphonyNotificationList) {
    console.log(symphonyNotificationList);
    // push latest matching ws message
    symphonyNotificationList.forEach((sn: any) => {
      if (this.selectedMailRequest.streamId === sn.conversationId) {
        let convToPush = $.extend(true, {}, SelectedMailModel.conversationList[0]);
        /* convToPush.bubbleContent = sn.markdownMessage; */
        convToPush.bubbleContent = sn.originalMessage;
        let user = JSON.parse(sn.fromUserProfile);
        convToPush.fromInitials = user.first_name[0] + user.last_name[0];
        convToPush.displayName = user.display_name;
        convToPush.timestamp = sn.sentTimestampUTC.$date;
        convToPush.user = user;
        convToPush.user.displayName = user.display_name
        convToPush.symphonyId = user.symId;
        convToPush.isInternal = !sn.isExternal;
        if (this.userDataService.loggedInUserInfo.userId === user.cvuserId) {
          convToPush.imageUrl = 'assets/symphony/current-loggedin-user.png';
        }
        else if (convToPush.isInternal) {
          convToPush.imageUrl = 'assets/symphony/other-qma-user.png';
        }
        this.conversationList.push(convToPush);
        this.viewUpdateForWs();
      }
    });
  }
  showGroupPopup(event, overlaypanel) {
    overlaypanel.toggle(event);
  }

  viewUpdateForWs() {
    //code to set hight for view more message popup
    setTimeout(() => {
      this.contentHolder.forEach((ele, idx) => {
        let currentHeight = ele.nativeElement.offsetHeight;
        if (currentHeight > this.MAX_HEIGHT) {
          this.readMore[idx].display = true;
        }
      })

      this.chatBody.nativeElement.scrollTop = this.chatBody.nativeElement.scrollHeight; //Scroll to Bottom on Load
    }, 0);
  }

  setCssClassByUserId(conversation) {
    if (conversation.isInternal === false) {
      return "external-user";
    }
    if (!conversation.symphonyId) {
      return "";
    }
    if (this.loginUserInfo.userSymphonyIdToUserMap[conversation.symphonyId]) {
      const soeId = this.loginUserInfo.userSymphonyIdToUserMap[conversation.symphonyId].soeId;
      // chat buuble of logged in user
      if (soeId && soeId === this.loginUserInfo.userId) {
        return "current-logged-in-user";
      } else {
        return "other-QMA-user";
      }

    }

  }

  setCssInitialsByUserId(conversation) {
    if (this.loginUserInfo.userSymphonyIdToUserMap[conversation.symphonyId]) {
      const soeId = this.loginUserInfo.userSymphonyIdToUserMap[conversation.symphonyId].soeId;
      // chat buuble of logged in user
      if (soeId && soeId === this.loginUserInfo.userId) {
        return "current-logged-in-user-initial";
      } else {
        return "other-QMA-user-initial";
      }
    }

  }

  populateGroupMembers(recGrps, recSoeIds) {
    this.allUsers = [];
    if (recGrps) {
      const arrRecGrps = recGrps.split(';');
      arrRecGrps.forEach(grp => {
        if (!grp) {
          return;
        }
        this.allUsers.push(
          {
            displayName: grp,
            emailAddr: grp,
            groupId: grp,
            toFrom: grp,
            userId: grp,
            isExternal: false,
            participantName: grp
          }
        );
      });
    }

    // to populate individual soeids
    if (recSoeIds && recSoeIds.length > 0) {
      // get display name by SOEID
      recSoeIds.forEach(soeId => {
        const participantDisplayName = this.allToCcDBUser.find(au => au.id === soeId).text.split('[')[0];
        this.allUsers.push(
          {
            displayName: participantDisplayName,
            emailAddr: participantDisplayName,
            groupId: participantDisplayName,
            toFrom: participantDisplayName,
            userId: participantDisplayName,
            isExternal: false,
            participantName: participantDisplayName
          }
        );
      });
    }
  }
  loadAllSymphonyChatMmbers() {
    this.allUsersSymphony = [];
    const reqObj = {
      streamId: this.selectedMailRequest.streamId
    };
    this.symphonyChatServiceService.getSymphonyMemberLists(reqObj).subscribe((ml: any) => {
      if (ml && ml.members && ml.members.length > 0) {
        const memberList = ml.members.map((m) => {
          return {
            'isCreator': m.isCreator, 'isOwner': m.isOwner, 'user': m.user
          };
        });
        memberList.forEach(ml => {
          this.allUsersSymphony.push(
            {
              displayName: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              emailAddr: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              groupId: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              toFrom: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              userId: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              isExternal: false,
              participantName: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              isCreator: ml.isCreator,
              isOwner: ml.isOwner,
              symphonyId: ml && ml.user && ml.user.userId ? ml.user.userId : 0
            }
          );
        });
      }

    }, (error) => {
      if (!this.isSymphonyServiceError) {
        this.isSymphonyServiceError = true;
      }

    });
  }
  updateAllSymphonyUserPresense(allChatConv) {
    try {
      // if no conv simply return
      if (!allChatConv || !allChatConv.payload || allChatConv.payload.length === 0) {
        return;
      }
      let convToUpdateList = this.selectedMail.conversationList;
      convToUpdateList.forEach(convToUpdate => {
        let soeIdMap = this.loginUserInfo.userSymphonyIdToUserMap[convToUpdate.symphonyId];
        if (soeIdMap && soeIdMap.soeId && soeIdMap.soeId === this.userDataService.loggedInUserInfo.userId) {
          convToUpdate.imageUrl = 'assets/symphony/current-loggedin-user.png';
        } // internal QMA user
        else if (soeIdMap && soeIdMap.soeId) {
          convToUpdate.imageUrl = 'assets/symphony/other-qma-user.png';
        } else { // external citi user 
          convToUpdate.imageUrl = 'assets/symphony/extenalUser.png';
        }
      });
      /* const sympIds = this.selectedMail.conversationList.map(sl => sl.user).map(user => user.userId);
      // call to get presense of user
      sympIds.forEach(sId => {
        let reqObj = {symphonyId:sId} */
      /*  this.symphonyChatServiceService.getSymphonyUserPresenceBySymphonyId(reqObj).subscribe((presenceRes: any) => { */
      /*  let convToUpdateList = this.selectedMail.conversationList.filter(sm => sm.symphonyId === presenceRes.userId);
       if(!convToUpdateList) {return;}
       convToUpdateList.forEach(convToUpdate => {
         let convQMAUserDetail = this.loginUserInfo.userSymphonyIdToUserMap[presenceRes.userId];
         convToUpdate.userStatus = presenceRes.category
       if(convToUpdate.isInternal && convQMAUserDetail && convQMAUserDetail.soeId ) { 
         if(convQMAUserDetail.soeId === this.userDataService.loggedInUserInfo.userId) {
           if(convToUpdate.userStatus === "OFFLINE") {
             convToUpdate.imageUrl = 'assets/symphony/current-loggedin-user.png';
           } else {
             convToUpdate.imageUrl = 'assets/symphony/current-loggedin-user.png';
           }
         } // internal QMA user
         else {
           if(convToUpdate.userStatus === "OFFLINE") {
             convToUpdate.imageUrl = 'assets/symphony/other-qma-user.png';
           } else {
             convToUpdate.imageUrl = 'assets/symphony/other-qma-user.png';
           }
         }
       }else {
         // if userSymphonyIdToUserMap dont have details mocking status need to change
       convToUpdate.imageUrl = 'assets/symphony/group-3-copy-3@2x.png';
       }
       

       });
       
     
       /* if(convToUpdate.isInternal && ) */
      /*  }); */
      /*  }); */



    } catch (ex) {
      console.log(ex);
    }
    /*   this.selectedMail.conversationList.filter(ml => ml.user.userId === res.payload.id).forEach(cl => {
        if(this.userDataService.loggedInUserInfo.userId === res.payload.ssoId) {
          cl.imageUrl = 'assets/symphony/Outgoing_square icon with oval.png';
        }
        else if (cl.isInternal) {
          cl.imageUrl = 'assets/symphony/Incoming_square icon_with oval_green.png';
        }
      }); */
    /*    this.selectedMail.conversationList.forEach(cl => {
   
         if(this.userDataService.loggedInUserInfo.userId === res.payload.ssoId) {
           cl.imageUrl = 'assets/symphony/Outgoing_square icon with oval.png';
         }
         else if (cl.isInternal) {
           cl.imageUrl = 'assets/symphony/Incoming_square icon_with oval_green.png';
         }
       }); */
    // this.symphonyChatServiceService.getSymphonyUserPresenceBySymphonyId()
  }
  keyupChangeSymphony($event) {
    if ($event === "Enter") {
      if (this.editorApp.getData().trim() !== "") {
        this.sendInquiry(null);
      }
    }
    console.log($event);
  }
  prepareConvAttachementSymphony() {
    this.chatConveration = "";
    let convData = this.conversationList;
    const useSelectedBubble = this.conversationList.filter(c => c.isSelected === true);
    if (useSelectedBubble && useSelectedBubble.length > 0) {
      convData = useSelectedBubble;
    }
    if (convData && convData.length > 0 && convData) {
      // const msg =chatConv.payload[0].message;
      // this.chatConveration = "<div id='signatureDiv'></div><div id='disclaimerDiv'>";
      this.chatConveration = "";
      convData.forEach(element => {
        if (!element.timestamp || !element.user || !element.user.displayName ||
          !element.bubbleContent) {
          return;
        }
        const date = new Date(element.timestamp);
        const month = +date.getMonth() + 1;
        this.chatConveration += "[" + month + "/" +
          date.getDate() + "/" +
          date.getFullYear() + " " + this.formatAMPM(date) + "]" + " " + element.user.displayName + ":"
          + "<br>" +
          element.bubbleContent + "<br>" + "<br>";
      });
      // this.chatConveration += "</div>";
    }
  }
  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  loadNotesAndAudits(changes) {
    try {
      let selGridRow = changes.selectedMailRequest.currentValue.selectedMailboxRow;
      if (!selGridRow) {
        return;
      }
      let hasNominatedOwnership = false;
      let inquiryDataReq = {
        id: selGridRow._id,
        selectInqAssignedGroupId: selGridRow.workflows[0].assignedGroupId,
        isAllConvContent: false, // C153176-4660:
        hasNominatedOwnership: hasNominatedOwnership
      };
      this.symphonyChatServiceService.getInquiryDetails(inquiryDataReq, selGridRow).subscribe(inquiryDetail => {
        console.log(inquiryDetail);
        if (inquiryDetail.userNotes) {
          this.notesEvent.emit(inquiryDetail.userNotes);
        } else {
          this.notesEvent.emit([]);
        }
        if (inquiryDetail.workflowAudit) {
          this.auditsEvent.emit(inquiryDetail.workflowAudit);
        } else {
          this.auditsEvent.emit([]);
        }

      });
    }
    catch (exc) {
      console.log(exc);
    }
  }
  selectChatBubble(item) {
    if (!item.isSelected) {
      item.isSelected = true;
    } else {
      item.isSelected = false;
    }

  }
  getChatBubbleSelection(item) {
    if (!item.isSelected) {
      return "";
    } else {
      return "selectedChatBubble";
    }
  }

  loadAllSymphonyChatMmbersWS() {
    this.symphonyChatServiceService.getwsSymphonyMembers().subscribe((mlUpdate: any) => {
      if (mlUpdate.mlRes && mlUpdate.mlRes && mlUpdate.mlRes.members && mlUpdate.mlRes.members.length > 0) {
        if (mlUpdate.chat.symphonyStreamId !== this.selectedMailRequest.streamId) {
          return;
        }
        this.allUsersSymphony = [];
        let ml = mlUpdate.mlRes;
        const memberList = ml.members.map((m) => {
          return {
            'isCreator': m.isCreator, 'isOwner': m.isOwner, 'user': m.user
          };
        });
        memberList.forEach(ml => {
          this.allUsersSymphony.push(
            {
              displayName: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              emailAddr: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              groupId: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              toFrom: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              userId: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              isExternal: false,
              participantName: ml && ml.user && ml.user.displayName ? ml.user.displayName : "",
              isCreator: ml.isCreator,
              isOwner: ml.isOwner,
              symphonyId: ml && ml.user && ml.user.userId ? ml.user.userId : 0
            }
          );
        });
      }

    }, (error) => {
    });
  }
}
