<nav class="top-bar search-{{isMobileSearchView }} d-flex align-items-center flex-row flex-nowrap">
    <div class="search" [ngClass]="{'search-focused':inputFocused}">
        <div appOuterClick (outsideClick)="resetSearchBox($event)" class="navbar-left">
            <form class="form-horizontal" [formGroup]="searchForm" (keydown.enter)="$event.preventDefault()">
                <div *ngIf="isMobile && !inputFocused" class="sidebaricon">
                    <div class="topnav-icons w3-left w3-bar-item w3-button" id="mobileMenuClick" (click)="showSlide($event)">
                        <img src="assets/mobile/hamburger.svg" alt="">
                    </div>
                </div>
                <div *ngIf="isMobile && inputFocused" (click)="enableBackButton()">
                    <img class="backward-icon" src="assets/Inbox/right_chevron_icon.svg" alt="">
                </div>
                <div class="mobile_header_text flex-row align-items-center justify-content-start"><div class="mobile-label">{{currentTab.header}}</div><div
                        *ngIf="currentTab.count" class="custom-badge">{{displayEllipsis(currentTab.count)}}</div></div>
                <div class="input-group">
                    <div class="input-group-prepend searchBoxLeft" style="display:none">
                        <button [hidden]="!inputFocused" (click)="triggerSearch($event)" class="btn btn-default" name="searchBtn1">
                            <span class="searchsection advance-search-magnifier">
                                <!-- <em class="topnav-icons fa fa-search"></em> -->
                            </span>
                        </button>
                </div>
                <div style="border-radius: 10px;background: #002d72;">
                    <p-dropdown class="drop-down w-100 from-section " [styleClass]="'prime-dd-advance-search'"
                     [options]="searchInFolders" 
                     [style]="{'margin-left': '10px','background': '#002D72','border-bottom-width': '3px !important'}" 
                     (onChange)="onsearchInFolderChange($event)"  formControlName="frmCtrlsearchInFolders"> 
                     <ng-template let-folder pTemplate="selectedItem">
                        <span class="smart-search-dditems">
                            {{folder.label}}
                        </span>
                    </ng-template>
                     <ng-template let-folder pTemplate="item">
                        <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
                            <div class="smart-search-dditems">{{folder.label}}</div>
                        </div>
                    </ng-template>
                    </p-dropdown>
                </div>
                   <!--  <input (keyup.enter)="callSearch($event)" (keyup)="onInputChangeHandler($event)" type="text" class="form-control"
                        [placeholder]="!inputFocused? 'Search...' : 'Search by inquiry id, assigned group, email content and others…'"
                        formControlName="searchText" (focus)="onFocusEventHandler($event)" [hidden]="inputFocused"/> -->
                        <!-- search box with auto populate -->
                        <div class="search-text-container" (click)="toggleSearchInput()" [ngClass]=" inputFocused ? 'search-box-expanded' : 'search-box-collapsed'">
                            <p-autoComplete [suggestions]="savedSeachSingle" 
                            (completeMethod)="filterSearchSingle($event)" field="name" [size]="30"
                            [placeholder]="txtPlaceholder"[minLength]="1"
                            [style]="{'width':'100%','border': 'none','font-size':'14px','font-family':'interstate','color':'#002d72'}"
                             [inputStyle]="{'width':'100%','border': 'none','font-size':'14px','font-family':'interstate','color':'#002d72'}"
                            (onFocus)="onSearchBoxFocus(autocomplete)" (onClear)="clearItem(autocomplete)" 
                            (keypress) = "onAutoCompleteSearch($event)" 
                            (onKeyUp) = "onAutoCompleteKeyUp($event)" 
                            #autocomplete
                            formControlName="searchText" (keyup.enter)="callSearch($event,true,autocomplete)" 
                            styleClass="override override-newmessage-autocomplete mob-mt5" [maxlength]="100"></p-autoComplete>
                            <!-- (onFocus)="!autocomplete.value && autocomplete.handleDropdownClick()" -->
                        </div>
                       <!--  <span class="advance-search-reset" [hidden]="!inputFocused"></span> -->
                       <span class="clear-input" style="cursor: pointer;position: absolute;
                       right: 40px;">
                        <img id='cancel-search-topnav' src="assets/newMessage/cancel.svg" alt="Cancel" [hidden]="!inputFocused" (click)="closeSearchArea()">
                        </span>
                         <div class="input-group-btn searchBox">
                        <button [hidden]="inputFocused" (click)="triggerSearch($event)" class="btn btn-default" name="searchBtn">
                            <span class="searchsection advance-search-magnifier">
                                <!-- <em class="topnav-icons fa fa-search"></em> -->
                            </span>
                        </button>
                        <button [hidden]="!inputFocused" class="btn btn-default adv-search-button" (click)="toggleSearch()"> 
                            <span>
                                <img class="filter-section" src='assets/core/layout/header/filter_active.svg' alt="Filter">
                            </span>
                        </button>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-end align-items-center header-options">
                    <div class="btn mobile-searchicon px-1" *ngIf="showMultiSelection && isMobile" name="selectBtnMobile" (click)="mobileSelectClick($event)">
                        <div [ngClass]="(inboxService.mobileCheckboxSelected)?'isChecked':'isUnChecked'"></div> 
                    </div>
                    <div class="mobile-sort-label px-1" *ngIf="showMultiSelection && isMobile" (click)="mobileSortingClick($event,addToGrp)">
                        {{sortAttrLabel()}}
                    </div>
                    <div class="mobile-sort-icon px-1" *ngIf="showMultiSelection && isMobile" (click)="mobileSortDirection($event,addToGrp)">
                        <!--<img src='assets/mobile/sort.svg' alt="">-->
                        <span [ngClass]="sortDirectionClass()"></span>
                    </div>
                    <div style="position:relative;" class="d-flex flex-row">
                        <p-overlayPanel [styleClass]="'mob-inbox-sorting sorting inbox-sorting'" [appendTo]="'body'" #addToGrp [baseZIndex]="9999" [dismissable]="true">                                        
                            <div class="d-flex flex-row align-items-center justify-content-between">
                                <h6 class="radioMobSort">SORT BY</h6>
                                <div class="close-leftNav" (click)="closeSortingModal($event,addToGrp)">
                                    <img src='assets/mobile/close_sort.svg' alt="Sort">
                                </div>
                             </div>            
                            <form [formGroup]="myForm">
                                <div class="ui-g mobsorting-radio" style="width:250px;margin-top:6px">
                                    <div class="ui-g-12" *ngFor="let attr of sortAttrNames; let i = index">
                                        <p-radioButton class="radioBulkUpload" name="myRadio" value="{{i}}" label="{{attr}}" [(ngModel)]="sortAttrIndex"
                                          formControlName="myRadio" inputId="preopt{{i}}" ></p-radioButton>
                                    </div>
                                </div>
                            </form>
                        </p-overlayPanel>
                    </div>                   
                    <div *ngIf="showMultiSelection && isMobile" class="d-flex flex-row align-items-center mobile-search-btn px-1" (click)="mobileSearchClick()">                       
                        <img class="mobile-search-icon" src='assets/mobile/search.svg' alt="">
                    </div>
                </div>
                <div *ngIf="!firstClick" [hidden]="!advanceSearchVisible" class="advance-holder">
                    <app-adv-criteria (searchToggle)="toggleSearch()"></app-adv-criteria>
                </div>
            </form>
        </div>
        <div *ngIf="showSearchError" class="invalid-feedback">
               
            <div><img src="assets/newMessage/error.svg" class="error" alt="Error"> Please provide minimum of 3 characters</div> 
        </div>
    </div>
    <ul class="d-flex align-items-center flex-row flex-nowrap" [ngClass]="checkIfActive()" id="topmenuhide">
       <li style ="margin-right: 0.6em;"class="notification-icon notification-icon-symphony" [class.notify]="notificationCount>0" 
        *ngIf="symphonyEnabled && userDataService?.loggedInUserInfo?.notificationSettings?.symphony">
            <a style="display: inline-block;position: relative;" (click)="onOptionClick(3)" [class.active]='clickStatusArray[0]'>
                <!-- <i attr.data-count="{{notificationCount}}" class="fa fa-bell-o"></i> -->
                <span class="notify-badge">{{symphonyNotificationCount}}</span>
                <!-- Jira - 4578 - Tooltip has been added -->
                <img [src]="!clickStatusArray[0] ? 'assets/symphony/syphony-2.png':'assets/symphony/syphony-2.png'"
                    style="width: 38px;height: 38px;" pTooltip="Symphony  Notifications" 
                    tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text' alt="">
            </a>
        </li>
         <li style ="margin-right: 0.6em;" class="taskize-login-icon" *ngIf = "isTaskizeEnabledForUser">
            <a style="display: inline-block;position: relative;" (click)="taskizeAuthentication()" [class.active]='clickStatusArray[0]'>
                <img class="inbox-column-chooser-option"  src='assets/Inbox/taskize-icon-anex@2x.png' alt='Login Taskize' >
            </a>
        </li>
        <li class="notification-icon" [class.notify]="notificationCount>0">
            <a style="display: inline-block;position: relative;" (click)="onOptionClick(0)" [class.active]='clickStatusArray[0]'>
                <!-- <i attr.data-count="{{notificationCount}}" class="fa fa-bell-o"></i> -->
                <span class="notify-badge">{{notificationCount}}</span>
                <!-- Jira - 4578 - Tooltip has been added -->
                <img [src]="!clickStatusArray[0] ? 'assets/core/layout/header/notification.svg':'assets/core/layout/header/notification_selected.svg'"
                    style="width: 25px;height: 31px;" pTooltip="Notifications" tooltipPosition="left" 
                    tooltipStyleClass='leftTooltip p-tooltip-text' alt="">
            </a>
        </li>
        <li class="help-icon"><a (click)="onOptionClick(1)" [class.active]='clickStatusArray[1]'>
                <!-- <i class="fa fa-question"></i> -->
                <!-- Jira - 4578 - Tooltip has been added -->
                <img [src]="!clickStatusArray[1] ? 'assets/core/layout/header/help1.svg':'assets/core/layout/header/help1_selected.svg'"
                    alt="" pTooltip="Help" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text' alt="">
            </a>
        </li>
        <li class="profile-icon">
            <a (click)="onOptionClick(2)" [class.active]='clickStatusArray[2]'>
                <img [src]="profilePicSrc" alt="" *ngIf="AuthKey" pTooltip="Profile" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text'>
                <div class="display-individual" *ngIf="!AuthKey">{{userId.substring(0,2)}}</div>
            </a>
        </li>
    </ul>
</nav>
<!-- added for show release note start*/ -->
<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal currentRelease releaseNote'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'44%','height':'60%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title" style="font-weight: bold;">Current Release Notes</h6>
        </div>
    </p-header>

    <iframe id="releaseNotesIframeId" width="100%" height="80%" style="border-color: black;  border-width: 3px"
     [src]="releaseNoteUrl | safeUrl" title="Release Notes"></iframe>

    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn search-btn mr-4" (click)="updateReleaseNotesDocument()">OK</button>
        </div>
    </p-footer>

</p-dialog>
 <!-- added for show release note end */ -->
 <div>
   <!--  <app-symphony-chat-view *ngIf="displayChatPopup" [streamId]="this.streamId" 
    (closeChatView)="closeChatView($event)"></app-symphony-chat-view> -->
 </div>
