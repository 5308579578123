<!-- C153176-4618 Responsive Design-->
 <!-- Jira : C153176-4600 Issue- CMC search -->
 <div *ngIf="display">
 <p-dialog [(visible)]="displayPopup" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal cmsearchsection'"
 [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'1152px', 'width': '100%'}" [resizable]="false">
 <p-header>
   <div class="d-flex flex-row align-items-center justify-content-between">
     <h6 class="gfid-title">CONTACT SEARCH</h6>
     <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text" style="opacity: 1;">
           <img src="assets/Inbox/close_icon_u73.svg" width="22px">
           <h6 class="close-btn-section">CLOSE</h6>
       </button>
   </div>
 </p-header>
 
   <!-- C153176-5259 : Loader when hit the search button -->
   <ng-template #cmcLoading>
     <div class="custom-class-loader">
         <div class="spinner-container">
             <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                 <circle cx="170" cy="170" r="160" stroke="#021e48" />
                 <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                 <circle cx="170" cy="170" r="110" stroke="#021e48" />
                 <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
             </svg>
         </div>
     </div>
   </ng-template> 
   <ngx-loading [show]="showCmcSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="cmcLoading"></ngx-loading>
 
   
 
   <p-tabView [activeIndex]="0" class="pTabContactSearch"> <!-- (onChange)="onTabChange($event)" --> <!-- class="pTabStyle"  -->
     <p-tabPanel header="Advanced Contact Search">
       <ng-template pTemplate="content">
         <!-- <form #gfcForm="ngForm" (ngSubmit)="gfcForm.valid && validateAndSearch(gfcForm)"> -->
         <div class="d-flex flex-row">
           <div class="w-100">
             <form [formGroup]="cmcSearchForm">
                <div class="d-flex flex-row mt-2" style="justify-content: space-between;" *ngIf = "showTCL == 'Y'">
                  <label for="from" class="flex-grow form-section1">Source: </label>
                    <select class="w-50 form-control form-section2" style="height: auto;" #source (change) = "onSourceChange(source.value)">
                      <option value="CMC" selected="selected">CMC</option>
                      <option value="TCL">TCL</option>
                    </select>
                    <label for="from" class="flex-grow form-section1" [style.visibility] ="(showFields == 'TCL') ? 'visible' : 'hidden'">Product Family: </label>
                    <select class="w-50 form-control form-section2" style="height: auto;" formControlName="productFamily" [style.visibility] ="(showFields == 'TCL') ? 'visible' : 'hidden'">
                      <option *ngFor="let productFamilyData of productFamilyData" [selected]="productFamilyData.option == 'Select Product Family'" value="{{ productFamilyData.value }}">{{productFamilyData.option}}</option>
                    </select>
                </div>
                <div class="d-flex flex-row mt-4 mb-2" style="justify-content: space-between;" *ngIf = "showFields == 'CMC'">
                  <label for="from" class="flex-grow form-section1">Contact Email: </label>
                  <input type="text" formControlName="contactEmail" class="w-50 form-control form-section2" />
                  <label for="from" class="flex-grow">
                    <select formControlName="clientIdentifierOption" class="form-section11 dropdowncmc">
                      <option value="" selected="selected">Select Client Identifier</option>
                      <option value="Client Name">Client Name</option>
                      <option value="GP ID">GP ID</option>
                      <option value="Mgmt ID">Mgmt ID</option>
                      <option value="Client Mnemonic">Client Mnemonic</option>
                    </select>
                  </label>
                  <input type="text" formControlName="clientIdentifierValue" class="w-100 form-control form-section2" />
                </div>
                <div class="d-flex flex-row mt-4" style="justify-content: space-between;" *ngIf = "showFields == 'TCL'">
                  <label for="from" class="flex-grow form-section1">Client Email: </label>
                  <input type="text" class="w-50 form-control form-section2 firstname-mobile" formControlName="clientEmail" />
                  <label for="from" class="flex-grow form-section1">Tax ID: </label>
                  <input type="text" class="w-50 form-control form-section2" formControlName="taxId" />
                </div>
              <div class="d-flex flex-row mt-4" style="justify-content: space-between;" *ngIf = "showFields == 'CMC'">
                <label for="from" class="flex-grow form-section1">First Name: </label>
                <input type="text" class="w-50 form-control form-section2 firstname-mobile" formControlName="firstName" />
                <label for="from" class="flex-grow form-section1">Last Name: </label>
                <input type="text" class="w-50 form-control form-section2" formControlName="lastName" />
              </div>
             </form>
             <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 py-2 justify-content-end">
               <button type="submit" class="btn search-btn mr-4" *ngIf = "showFields == 'TCL'" (click)="searchTCL()">Search</button>
               <button type="submit" class="btn search-btn mr-4" *ngIf = "showFields == 'CMC'" (click)="searchCMC()">Search</button>
               <button class="btn clear-btn" (click)="resetCMC(showFields)">Reset</button>
             </div>
             <div class="d-flex flex-row mt-4 inquiry-rule" *ngIf="search">
               <!-- Jira : C153176-4600 Issue- CMC search -->
               <ag-grid-angular #inquiryGrid style="width:100%; height:26vh; " class="ag-theme-balham adminview" id="cmcSearch"
                 [rowData]="routesList" [columnDefs]="routesColumn" [enableColResize]="true"
                 [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" [pagination]="true"
                 [defaultColDef]="defaultColDef" [rowSelection]="'multiple'" [paginationPageSize]="100" (gridReady)="onGridReadyInquiry($event)"
                 suppressPaginationPanel=true (paginationChanged)="onPaginationChanged()" (selectionChanged)="onSelectionRules()"
                 [suppressDragLeaveHidesColumns]="true" [headerHeight]="44" [suppressHorizontalScroll]="false" [modules]="modules">
               </ag-grid-angular>
             </div>
   
             <div style="margin-top: 1%;" class="d-flex justify-content-end qma-mailbox-pagination-container" *ngIf="search">
               <span class="value" id="lbPageSize">{{lbPageSize}}</span>
               <span class="separator"> of </span>
               <span class="value separator" id="lbTotalRecords">{{lbTotalRecords}}</span>
               <span class="separator separator-right"> items </span>
               <span>
                 <img [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtFirst()" id="btFirst"
                   class="pagination-img" src="assets/Inbox/pagination-arrow-first.svg" />
               </span>
               <span class="separator-right">
                 <img id="btPrevious" [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtPrevious()"
                   class="pagination-img" src="assets/Inbox/pagination-arrow-prev.svg" />
               </span>
               <span class="separator-right pagination-text-padding">Page </span>
               <span id="lbCurrentPage" class="separator-right pagination-text-padding">{{lbCurrentPage}}</span>
               <span class="separator-right pagination-text-padding">of</span>
               <span id="lbTotalPages" class="separator-right pagination-text-padding">{{lbTotalPages}}</span>
               <span>
                 <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btNext" (click)="onBtNext()"
                   class="pagination-img" src="assets/Inbox/pagination-arrow-dark.svg" />
               </span>
               <span class="separator-right">
                 <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btLast" (click)="onBtLast()"
                   class="pagination-img" src="assets/Inbox/pagination-arrow-dark1.svg" />
               </span>
             </div>
             <div *ngIf="search" class="search-btn-cls">
               <button class="btn search-btn mr-4 selbtn" (click)="selectContacts()" [disabled]="displayError">Select</button>
             </div>
           </div>
   
         </div>
       </ng-template>
     </p-tabPanel>
     <p-tabPanel header="Corporate Address Book"  [selected]="isCorporateSearch">
       <ng-template pTemplate="content">
        <app-corp-add-book (cmcContactDataCorporate)="selectContactsDL($event)" [toCcBcc]="toCcBcc"(cmcCrptSearchError)="oncmcCrptSearchError($event)"></app-corp-add-book>
       </ng-template>
     </p-tabPanel>
   
   </p-tabView>
 
   <p-footer style="position: absolute; width: 66%; top: 692px; bottom: 0px;">
 
   </p-footer>
 
 
 
 
 </p-dialog>
</div>
 <p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
 [styleClass]="'contextMenuModal failureModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
 [style]="{'width': '30%'}">
 <p-header>
   <div class="d-flex flex-row align-items-center justify-content-between">
       <h6 class="success-title">Information</h6>
       <button type="button" (click)="closeModal()" class="d-flex flex-row close qma-text">
           <img src="assets/Inbox/close_icon_u73.svg">
           <h6>CLOSE</h6>
       </button>
   </div>
 </p-header>
 <div class="success-msg">{{errorMsg}}</div>
 </p-dialog>