<!-- C153176-5259 : Loader when hit the search button -->
<ng-template #clcLoading>
    <div class="custom-class-loader">
        <div class="spinner-container">
            <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                <circle cx="170" cy="170" r="160" stroke="#021e48" />
                <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                <circle cx="170" cy="170" r="110" stroke="#021e48" />
                <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
            </svg>
        </div>
    </div>
</ng-template> 
<ngx-loading [show]="showClcSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="clcLoading"></ngx-loading>

<p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
        <h6 class="gfid-title" style="font-size: 20px">{{clcHeading}}</h6>
        <button type="button" (click)="closeModal('success')" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1">
                <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1">
                <h6>CLOSE</h6>
        </button>
    </div>
</p-header>
<p-accordion (onOpen)="onTabOpen($event)" [activeIndex]="index">
    <p-accordionTab >        
        <p-header>
            <span><img class="qma-text unpinDownSpanImg" src="assets/clc/clc_unpin.svg"> Pinned</span>
        </p-header>
        <div class="pinnedTab">
            <div *ngIf="ValidationFlag" class="d-flex flex-row" style="color: red;">{{ValidMessage}}</div>
            <div class="d-flex flex-row mt-2 unpin">
                <span class="unpinUpSpan">
                        <div class="add-new-btn" (click)="onUnpinnedClcRows()">
                            <span class="unpinDownSpan">
                                <img class="qma-text unpinDownSpanImg" src="assets/clc/clc_unpin.svg">
                            </span>
                <span class="unpinDownSpanImgLabel">UNPIN</span>
            </div>
            </span>
            <span style="padding-right: 10px;">
                        <div class="add-new-btn" (click)="addToReply('pinnedResult')">
                            <span class="unpinDownSpan">
                                <img class="qma-text unpinDownSpanImg" src="assets/clc/clc_add.svg">
                            </span>
            <span class="unpinDownSpanImgLabel">ADD TO REPLY</span>
        </div>
        </span>
        </div>
        <div class="d-flex flex-row mt-2">
            <ag-grid-angular #pinnedGrid class="ag-theme-balham headerPart" id="clcPinnedId" [rowData]="clcPinnedList" [columnDefs]="clcPinnedColumn"
                [defaultColDef]="clcPinnedCheckbox" [rowSelection]="rowSelection" [stopEditingWhenGridLosesFocus]="true"
                [suppressRowClickSelection]="true" (gridReady)="onGridReadyPinned($event)" [suppressDragLeaveHidesColumns]="true"
                [suppressHorizontalScroll]="true" suppressMenu=true [rowHeight]="35" [headerHeight]="35" [modules]="modules">
            </ag-grid-angular>
        </div>
        </div>
    
    </p-accordionTab>
    <p-accordionTab >
        <p-header>
            <span>Search Criteria</span>
        </p-header>

        <div>
            <form [formGroup]="clcForm" class="advance-search" (ngSubmit)="onClcSubmit()">
                <div class="w-100">
                    <div *ngIf="error.isError"  class="d-flex flex-row flex-nowrap pb-10 text-danger error-calender">
                        {{ error.errorMessage }}
        </div>
        </div>
        <div class="searchCriteriaTab">
            <div class="bottom-container mb-3 Col firstColumn">

                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start">
                        <span class="upSpan"><p-dropdown formControlName="tradeId" [styleClass]="'prime-dd-override'" [placeholder]="'Select Trade Id'" class="drop-down w-25 group-details-input" [options]="tradeIdentifierOptions" [appendTo]="'body'" [baseZIndex]="9999"></p-dropdown></span>
                        <span class="downSpan"><input type="text" formControlName="tradeIdInput" placeholder="Trade ID" class="t-box inp-txt-mng group-details-input-mng"></span>
                    </div>
                </div>
                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start ">
                        <span class="upSpan"><p-dropdown formControlName="clientId" [styleClass]="'prime-dd-override'" [placeholder]="'Select Client ID'" class="drop-down w-25 group-details-input" [options]="clientIdOptions" [appendTo]="'body'" [baseZIndex]="9999"></p-dropdown></span>
                        <span class="downSpan"><input type="text"  formControlName="clientIdInput" placeholder="Client Info" class="t-box inp-txt-mng group-details-input-mng"></span>
                    </div>
                </div>

                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start ">
                        <span class="upSpan"><p-dropdown formControlName="sdi" [styleClass]="'prime-dd-override'" [placeholder]="'Select SDI'" class="drop-down w-25 group-details-input" [options]="sdiOptions" [appendTo]="'body'" [baseZIndex]="9999"></p-dropdown></span>
                        <span class="downSpan"><input type="text" formControlName="sdiInput" placeholder="SDI" class="t-box inp-txt-mng group-details-input-mng"></span>
                    </div>
                </div>

                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start">
                        <span class="upSpan"><p-dropdown formControlName="security" [styleClass]="'prime-dd-override'" [placeholder]="'Select security'" class="drop-down w-25 group-details-input" [options]="securityOptions" [appendTo]="'body'" [baseZIndex]="9999"></p-dropdown></span>
                        <span class="downSpan"><input type="text" formControlName="securityInput"  placeholder="Security" class="t-box inp-txt-mng group-details-input-mng"></span>
                    </div>
                </div>
            </div>

            <div class="bottom-container mb-3 Col secondColumn">

                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start ">
                        <span class="upSpanSecond"><label for="from" class="flex-grow">Status</label></span>
                        <span class="downSpanSecond"><p-dropdown formControlName="statusInput"  [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" [options]="statusOptions" [appendTo]="'body'" [baseZIndex]="9999"></p-dropdown></span>
                    </div>
                </div>
                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start ">
                        <span class="upSpanSecond"><label for="from" class="flex-grow">Source</label></span>
                        <span class="downSpanSecond"><p-dropdown formControlName="sourceInput" [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" [options]="sourceOptions" [appendTo]="'body'" [baseZIndex]="9999"></p-dropdown></span>
                    </div>
                </div>

                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start ">
                        <span class="upSpanSecond"><label class="">Trade date</label></span>
                        <span class="downSpanSecondforCal">
                                        <div class="d-flex flex-column justify-content-left">
                                        <p-calendar dateFormat="mm-dd-yy" formControlName="tradeDate"  [showOtherMonths]="true" [hideOnDateTimeSelect]="true" [selectOtherMonths]="false"
                                            [readonlyInput]="false" [monthNavigator]="true" [yearNavigator]="true"  [maxDate]="maxDate" [yearRange]="'2010:2030'"
                                              [styleClass]="'advance-calender2'" [showIcon]="true" [showButtonBar]="true" [appendTo]="'body'" [baseZIndex]="9999"></p-calendar>
                                        </div>
                                    </span>
                    </div>
                </div>
                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start ">
                        <span class="upSpanSecond"><label class="">Settlement date</label></span>
                        <span class="downSpanSecondforCal">
                                        <div class="d-flex flex-column justify-content-left">
                                        <p-calendar dateFormat="mm-dd-yy" formControlName="settlementDate"  [showOtherMonths]="true" [hideOnDateTimeSelect]="true" [selectOtherMonths]="false"
                                            [readonlyInput]="false" [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2010:2030'"
                                              [styleClass]="'advance-calender2'" [showIcon]="true" [showButtonBar]="true" [autoZIndex]="true" [appendTo]="'body'" [baseZIndex]="9999"></p-calendar>
                                        </div>
                                    </span>
                    </div>
                </div>

            </div>

            <div class="bottom-container mb-3 thirdColumn">

                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start ">
                        <span class="upSpanThird"><label for="from" class="flex-grow">Amount</label></span>
                        <span class="downSpanThird"><input type="text" formControlName="amount"  placeholder="Amount" class="t-box inp-txt-mng group-details-input-mng"></span>
                    </div>
                </div>

                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start ">
                        <span class="upSpanThird"><label class="">Quantity</label></span>
                        <span class="downSpanThird"><input formControlName="quantity" type="text" placeholder="Quantity" class="t-box inp-txt-mng group-details-input-mng"></span>
                    </div>
                </div>

                <div class="d-flex flex-row flex-nowrap align-items-end">
                    <div class="d-flex flex-row align-items-start ">
                        <span class="upSpanThird"><label class="">Row Limit</label></span>
                        <span class="downSpanThird"><input formControlName="rowLimit"  type="text" placeholder="Row Limit" class="t-box inp-txt-mng group-details-input-mng"></span>
                    </div>
                </div>

            </div>
        </div>
        <div class="d-flex flex-row flex-wrap bottom-button-holder justify-content-end customButtons">
            <button type="submit" class="btn search-btn mr-5 search">Search</button>
            <button type="reset" class="btn clear-btn clear" (click)='resetCLC()'>Clear</button>
        </div>
        </form>
        </div>
    </p-accordionTab>
</p-accordion>
<div style="float:left; width: 90em;" *ngIf="displayGrid">
    <div class="searchResult">
        <div *ngIf="ValidationSearchFlag" class="d-flex flex-row" style="color: red;">{{ValidSearchMessage}}</div>
        <div class="d-flex flex-row unpinDiv">
            <span class="unpinUpSpanSearch">
                <div class="add-new-btn" (click)="onPinnedClcRows()" *ngIf="(this.clc != undefined && this.clc.inquiryId != null && this.clc.inquiryId != undefined)">
                    <span class="unpinDownSpan">
                        <img class="qma-text unpinDownSpanImg" src="assets/clc/clc_pin.svg">
                    </span>
                    <span class="unpinDownSpanImgLabel">PIN</span>
                </div>
            </span>
            <span style="width:10em">
                <div class="add-new-btn" (click)="addToReply('seachResult')">
                    <span class="unpinDownSpan">
                        <img class="qma-text unpinDownSpanImg" src="assets/clc/clc_add.svg">
                    </span>
                    <span class="unpinDownSpanImgLabel">ADD TO REPLY</span>
                </div>
            </span>
        </div>
        <div class="d-flex flex-row mt-2">
            <ag-grid-angular #clcGrid class="ag-theme-balham headerPart" id="clcId" [rowData]="clcList" [columnDefs]="clcColumn"
                [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" [pagination]="true" [defaultColDef]="clcCheckbox"
                [singleClickEdit]="true" [rowSelection]="rowSelection" [paginationPageSize]="100" suppressPaginationPanel="true" (paginationChanged)="onClcPaginationChanged()"
                (gridReady)="onGridReadyClc($event)" [suppressDragLeaveHidesColumns]="true" suppressMenu=true [rowHeight]="43" [headerHeight]="44" [modules]="modules">
            </ag-grid-angular>
        </div>
        <div class="d-flex justify-content-end qma-mailbox-pagination-container pagin">
            <span class="value" id="lbPageSize">{{lbPageSize}}</span>
            <span class="separator"> of </span>
            <span class="value separator" id="lbTotalRecords">{{lbTotalRecords}}</span>
            <span class="separator separator-right"> CLC </span>
            <span>
                            <img [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtFirst()"
                                id="btFirst" class="pagination-img" src="assets/Inbox/pagination-arrow-first.svg" />
                        </span>
            <span class="separator-right">
                            <img id="btPrevious" [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtPrevious()"
                                class="pagination-img" src="assets/Inbox/pagination-arrow-prev.svg" />
                        </span>
            <span class="separator-right pagination-text-padding">Page </span>
            <span id="lbCurrentPage" class="separator-right pagination-text-padding">{{lbCurrentPage}}</span>
            <span class="separator-right pagination-text-padding">of</span>
            <span id="lbTotalPages" class="separator-right pagination-text-padding">{{lbTotalPages}}</span>
            <span>
                            <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btNext" (click)="onBtNext()"
                                class="pagination-img" src="assets/Inbox/pagination-arrow-dark.svg" />
                        </span>
            <span class="separator-right">
                            <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btLast" (click)="onBtLast()"
                                class="pagination-img" src="assets/Inbox/pagination-arrow-dark1.svg" />
                        </span>
        </div>
    </div>
</div>

<p-dialog [(visible)]="closePinnedSuccessFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal successModal ModelClose'" [closable]="true" [dismissableMask]="true" [baseZIndex]="10000"
    [closeOnEscape]="true" [style]="{width: '30%', 'margin-left':'150px'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Confirmation</h6>           
        </div>
    </p-header>
    <div class="success-msg">{{linkUnlinkMessage}}</div>
</p-dialog>