<div class="row text-center">
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        Previous
      </div>
      <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
        Today
      </div>
      <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="closeOpenMonthViewDay()">
        Next
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
  </div>
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
        Month
      </div>
      <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
        Week
      </div>
      <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
        Day
      </div>
    </div>
  </div>
</div>
<br />
<div [ngSwitch]="view">
  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
    [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-day-view>
</div>

<!-- Everything you see below is just for the demo, you don't need to include it in your app -->

<br /><br /><br />

<h3>
  <button class="btn btn-primary float-right" (click)="addEvent()">
    Add new
  </button>
  <div class="clearfix"></div>
</h3>

<!-- <div class="table-responsive">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th>Title</th>
        <th>Primary color</th>
        <th>Secondary color</th>
        <th>Starts at</th>
        <th>Ends at</th>
        <th>Remove</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let event of events">
        <td>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="event.title"
            (keyup)="refresh.next()"
          />
        </td>
        <td>
          <input
            type="color"
            [(ngModel)]="event.color.primary"
            (change)="refresh.next()"
          />
        </td>
        <td>
          <input
            type="color"
            [(ngModel)]="event.color.secondary"
            (change)="refresh.next()"
          />
        </td>
        <td>
          <input
            class="form-control"
            type="text"
            mwlFlatpickr
            [(ngModel)]="event.start"
            (ngModelChange)="refresh.next()"
            [altInput]="true"
            [convertModelValue]="true"
            [enableTime]="true"
            dateFormat="Y-m-dTH:i"
            altFormat="F j, Y H:i"
            placeholder="Not set"
          />
        </td>
        <td>
          <input
            class="form-control"
            type="text"
            mwlFlatpickr
            [(ngModel)]="event.end"
            (ngModelChange)="refresh.next()"
            [altInput]="true"
            [convertModelValue]="true"
            [enableTime]="true"
            dateFormat="Y-m-dTH:i"
            altFormat="F j, Y H:i"
            placeholder="Not set"
          />
        </td>
        <td>
          <button class="btn btn-danger" (click)="deleteEvent(event)">
            Delete
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div> -->

<ng-template #modalContent let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Event action occurred</h5>
    <button type="button" class="close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div>
      Action:
      <pre>{{ modalData?.action }}</pre>
    </div>
    <div>
      Event:
      <pre>{{ modalData?.event | json }}</pre>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="close()">
      OK
    </button>
  </div>
</ng-template>

<p-dialog [(visible)]="displayCreateAppointmentPopup" [modal]="true" [responsive]="true" [maximizable]="false"
  [appendTo]="'body'" [styleClass]="'symphonyCreateChatRoom'" [closable]="false" [dismissableMask]="true"
  [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'44%','height':'400px'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
      <h6 class="gfid-title" style="font-weight: bold;">Create Appointment</h6>
    </div>
  </p-header>
  <div class="d-flex flex-row align-items-start" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>To</span></label>
    <p-autoComplete [(ngModel)]="userList" [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)"
    [minLength]="1" field="text" [multiple]="true" [styleClass]="'symphonyAutoComplete'"
            ></p-autoComplete> 
  </div>
  <div class="d-flex flex-row align-items-start" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>Subject</span></label>
    <input id="input" type="text" size="30" pInputText [(ngModel)]="inputSubject">
  </div>
  <div class="d-flex flex-row align-items-start" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>Location</span></label>
    <input id="input" type="text" size="30" pInputText [(ngModel)]="inputLocation">
  </div>
  <div class="d-flex flex-row align-items-start" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>Start Time</span></label>
    <p-calendar inputId="startDate" [(ngModel)]="startDate"></p-calendar>
    <p-calendar [(ngModel)]="startTime" [timeOnly]="true" inputId="timeonly"></p-calendar>
  </div>
  <div class="d-flex flex-row align-items-start" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>End Time</span></label>
    <p-calendar inputId="endDate" [(ngModel)]="endDate"></p-calendar>
    <p-calendar [(ngModel)]="endTime" [timeOnly]="true" inputId="endTime"></p-calendar>
  </div>
  <div class="d-flex flex-row align-items-start" style="width: 38.6em">
    <label for="messageBody" class="input pr-4"><span>Message</span></label>
    <textarea [rows]="5" [cols]="30" [(ngModel)]="inputMessageBody" pInputTextarea autoResize="autoResize"></textarea>
  </div>

  <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
      <button type="button" class="btn save-btn mr-4" (click)="createAppointment()">Send Appointment</button>
      <button type="button" class="btn clear-btn" (click)="cancelPopup()">Cancel</button>

    </div>
  </p-footer>

</p-dialog>

<p-dialog [(visible)]="displayCancelAppointmentPopup" [modal]="true" [responsive]="true" [maximizable]="false"
  [appendTo]="'body'" [styleClass]="'symphonyCreateChatRoom'" [closable]="false" [dismissableMask]="true"
  [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'width':'44%','height':'400px'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
      <h6 class="gfid-title" style="font-weight: bold;">Cancel Appointment</h6>
    </div>
  </p-header>
  
  <div class="d-flex flex-row align-items-start" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>Subject</span></label>
    <input id="input" type="text" size="30" pInputText [(ngModel)]="inputSubject">
  </div>
  <div class="d-flex flex-row align-items-start" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>Start Time</span></label>
    <p-calendar inputId="startDate" [(ngModel)]="startDate"></p-calendar>
    <p-calendar [(ngModel)]="startTime" [timeOnly]="true" inputId="timeonly"></p-calendar>
  </div>
  <div class="d-flex flex-row align-items-start" style="width: 38.6em">
    <label for="to" class="input pr-4"><span>End Time</span></label>
    <p-calendar inputId="endDate" [(ngModel)]="endDate"></p-calendar>
    <p-calendar [(ngModel)]="endTime" [timeOnly]="true" inputId="endTime"></p-calendar>
  </div>

  <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
      <button type="button" class="btn save-btn mr-4" (click)="cancelAppointment()">Cancel Appointment</button>
      <button type="button" class="btn clear-btn" (click)="cancelCancelAppointmentPopup()">Cancel</button>

    </div>
  </p-footer>

</p-dialog>