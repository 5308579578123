import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { NotificationService } from "../../common/services/notifications.service";
import { Subscription } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { trigger, transition, style, animate } from "@angular/animations";
import { WebsocketService } from '../../services/websocket/websocket.service';
import { UserDataService } from "src/app/services/user-data.service";
import { DateFormatPipe } from 'src/app/common/pipes/date-format.pipe';
import { InboxService } from '../../services/inbox.service';
import { ContextMenu } from 'src/app/model/Common/ContextMenu';
import { TabDataService } from '../../services/tab-data.service';
import { InquiryCache } from 'src/app/common/cache/inquiry-cache';
import { RightNavigationService } from 'src/app/services/panel/panel.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],

  animations: [
    trigger('items', [
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate('1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
         style({
           transform: 'scale(0.5)', opacity: 0,
           height: '0px', margin: '0px'
         }))
      ])
    ])
  ]
})
export class NotificationComponent implements OnInit, OnDestroy {
  notificationArray: Array<any>;
  clickStatusArray: Array<boolean> = [false, false];
  native: any = 'native';
  private subscription: Subscription;
  public loginUserInfo: any;
  dateFormatPipe: DateFormatPipe;
  guserPrefDateFormat = '';
  protected requestFilter;
  public rowData: any;
  public dataExportToExcel: any;
  public strMailboxView = "";
  selectedMail: any;
  selectedInquiryId: any;
  selectedConvId: number;
  selectedInqId: number;
  componentName = "notification";
  subjectName = ''; 
  tabName: string = ''; 
  @Output() onFilter: EventEmitter<any> = new EventEmitter();
  @Input() showNotificationHtml;
  constructor(private notificationService: NotificationService,
              private wsService: WebsocketService,
              private userDataService: UserDataService,
              private inboxService: InboxService,
              private cdr: ChangeDetectorRef,
              private contextMenu: ContextMenu,
              private tabDataService: TabDataService,
              private inquiryCache: InquiryCache,
              private rightNavigationService:RightNavigationService,
              private httpClient: HttpClient,
              private messageService: MessageService) {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserData => {
      this.loginUserInfo = loginUserData;
      let dateFormat = '';
      loginUserData.preferences.map(function (val) {
        if (val.key === "dateFormatterSelectId") {
          dateFormat = val.value;
        }
      });
      //this.guserPrefDateFormat = dateFormat;
      this.guserPrefDateFormat = "dd/MMM/yy";

      if (this.inquiryCache) {
        this.inquiryCache.init(this.componentName);
      }
      if (this.validateWSconnectionURL(this.loginUserInfo.wsHost)) {
        let CHAT_URL = this.loginUserInfo.wsHost + "/" + this.loginUserInfo.userId;
        // do not commit this this change
       /*  let CHAT_URL = "ws://10.61.161.50:8080/QMA/inquiryActions"+ "/" + this.loginUserInfo.userId; */
        // check if cvStreamingToken enebled
        if (this.loginUserInfo.cvWebsocketConfig.isCVWebSocketUpdateEnabled) {
          let wsUrl: string = this.loginUserInfo.cvWebsocketConfig.qmaWebSocketEndPoint;
          if (wsUrl.indexOf('streaming') > -1) {
            let tokenUrl: string = this.loginUserInfo.cvWebsocketConfig.cvStreamingTokenURL;
            const headers = new HttpHeaders()
              .set("X-Accept", "application/json");
            // on server using cvStreamingTokenURL
            this.httpClient.get(tokenUrl, { headers }).subscribe(data => {
              if (data && data['token']) {
                const token = data['token'];
                CHAT_URL = wsUrl + "/" + this.loginUserInfo.userId + '?&token=' + token;
                CHAT_URL += this.loginUserInfo.cvWebsocketConfig.isWebSocketRedesignEnable ? "&qma2" : "";
                this.connectToWs(CHAT_URL);
              }
            }, error => {
              // On local development environment cvStreamingTokenURL will not work.
              CHAT_URL += this.loginUserInfo.cvWebsocketConfig.isWebSocketRedesignEnable ? "?&qma2" : "";
              this.connectToWs(CHAT_URL);
              console.log(error);
            });
  
          }
        } else {
          // connect to websocket w/o token if 'isCVWebSocketUpdateEnabled' is false
          CHAT_URL += this.loginUserInfo.cvWebsocketConfig.isWebSocketRedesignEnable ? "?&qma2" : "";
          this.connectToWs(CHAT_URL);
        }
      }
    });
    this.dateFormatPipe = new DateFormatPipe();
    //this. getAllNotification();
    //this.subjectName = "Pending Approval"; 
  }
  connectToWs(CHAT_URL) {
    //CHAT_URL = "ws://localhost:8080/QMA/inquiryActions/XXXXX?&qma2"
    this.wsService.connect(CHAT_URL).subscribe(
      (response: MessageEvent): any => {
        console.debug("websocketupdate: recieving ws in notification.connectToWs()");
        // to update websocket records so other components in app can reuse
        let data: any;
        let notificationData : any;
        this.wsService.setwebSocketUpdate(response);
        if (this.loginUserInfo.cvWebsocketConfig.isCVWebSocketUpdateEnabled) {
          data = JSON.parse(response.data).wsInquiryList;
          notificationData = JSON.parse(response.data).wsInquiryList ? JSON.parse(response.data).wsInquiryList[0] : {} ;
        } else {
          data = JSON.parse(response.data);
        }
        if(this.inboxService.commonWsSubscribtion){
          let inqIdArray = JSON.parse(response.data);
          for (let [key, value] of this.inboxService.wsQueue.entries()) {
            value.push(inqIdArray);
            this.inboxService.wsQueue.set(key,value);
            console.debug(`webworker common subscription:  adding ws update to tab:  ${key}`);
          }
          this.inboxService.readWsQueue.next(true);
        }
        

        let action = '';
        let subject = '';
        let notificationSetting = [];
        let approvalFlag = false;
        let RejectFlag = false; 
        let isUserId = false;
        let linkAction = ''
        if (data && data.length > 0) {
          // Get the selected assigned groups in dashboard setting
          let selGroups = JSON.parse(localStorage.getItem('groupNames')) || [];

          data.forEach(inq => {
            //pre-populate action and subject with websocket message's 
            action = inq.action ? inq.action : action;
            subject = inq.subject ? inq.subject : subject;
            let workflows = [...inq.workflows];
            
            // Filter out the workflows according to dash board selected assigned groups.
            if(selGroups && selGroups.length > 0){
              workflows = workflows.filter(wf => selGroups.includes(wf.assignedGroupName));
            }
            
            workflows.reverse();
            workflows.forEach(item => {
              let updated = true;
              if (item.workflowStatus) {
                let approvalTest = item.workflowStatus.split("-");
                approvalFlag = approvalTest.indexOf("Approved ") !== -1;
                let approvalReject = item.workflowStatus.split("-");
                RejectFlag = approvalReject.indexOf("Rejected ") !== -1;
              }
              if (item.nominationRejectionReason) {
                action = "approvals";
                subject = "REJECTED";
              }
              if (subject !== "REJECTED") {
                if (approvalFlag) {
                  action = "approvals";
                  subject = "APPROVED";
                } else if (RejectFlag) {
                  action = "approvals";
                  subject = "REJECT";
                } else if (item.workflowStatus == "Accepted - NOMINATE_OWNERSHIP" && item.direction === "IN" && !item.nominationRejectionReason && item.modBy == this.loginUserInfo.userId) {
                  action = "approvals";
                  subject = "ACCEPTED";
                } else if (item.workflowStatus == "Pending - NOMINATE_OWNERSHIP" && item.direction === "NOMINATE_OWNERSHIP" && !item.nominationRejectionReason && item.modBy == this.loginUserInfo.userId) {
                  action = "approvals";
                  subject = "OWNERSHIP NOMINATION";
                } else if (item.direction !== "IN" && item.direction !== "OUT" && item.direction !== "NOMINATE_OWNERSHIP") {
                  action = "approvals";
                  subject = "PENDING APPROVAL";
                } else if (!this.isNull(item.generalEscalationReason) && item.isConvCountEscalation === "Y") {
                  action = "escalation";
                  subject = "ESCALATION";
                }else if (!this.isNull(item.autoAssigned) && item.autoAssigned === "Y") {
                  action = "autoassign";
                  subject = "AutoAssigned";
                }else if (!this.isNull(item.autoAssigned) && item.autoAssigned === "R") {
                  action = "autoassign";
                  subject = "AutoAssigned Revoked";
                }else if (item.action === "FollowUp" || item.action === "Snooze" || item.action === "UnSnooze") {  
                  action = (item.action == "UnSnooze") ? "snooze" : item.action.toLowerCase();   
                  if (item.action === "Snooze") {
                    subject = "SNOOZE";
                  } else if (item.action === "UnSnooze") {  
                    subject = "UNSNOOZE"; 
                  } else {
                    subject = "FOLLOW UP";
                  }
                } else {
                  updated = false;
                }
              } else {
                updated = false;
              }
              if (!updated) {
                // if action and subject have been updated by this workflow, but action is populated in workflow, take it for both fields.
                action = item.action ? item.action : action;
                subject = item.action ? item.action.toUpperCase() : subject;
              }
              this.subjectName = subject;
            });

            // C170665-455 | User should get the notification based on dashboard setting. 
            // We are not considering empty workflow.
            if (workflows && workflows.length > 0) {
              if (inq.notify && inq.notify.length > 0) {
                isUserId = inq.notify.indexOf(this.loginUserInfo.userId) !== -1;
              }
              for (const key in this.loginUserInfo.notificationSettings) {
                if (this.loginUserInfo.notificationSettings[key]) {
                  notificationSetting.push(key.toLowerCase());
                }
              }
              const isNotify = notificationSetting.indexOf(action) !== -1;
              inq.crtDate.$date = Object(this.dateFormatPipe.transform(inq.crtDate.$date, this.guserPrefDateFormat));
              const indx = this.inquiryCache.findIndex(this.componentName, inq._id);
              if ((isNotify) || (isUserId)) { // Notification should push for every change on inquiry
                data[0].subject = subject;
                this.notificationArray.unshift(inq);
                this.inquiryCache.add(this.componentName, inq, this.notificationArray.length - 1, this.notificationArray.length);
                this.notificationService.setNotificationCount(this.notificationArray);
                this.notificationService.filterCount(this.notificationArray);
              }
            }
          });
        
          // code for update on link/delink for BrazilFX from WS
          if(window.self !== window.top) { // condition added to only show notification in microUi
            console.log('trigger notification only in microUi')
            if (notificationData && notificationData.linkEntities &&  notificationData.linkEntities.length > 0) { // check for linked Entities object
              notificationData.linkEntities.forEach(linkEntitiesData => {
                if (linkEntitiesData.entityAction && linkEntitiesData.entityAction != '') {
                  if (linkEntitiesData.entityAction == 'link') {
                    linkAction = 'link deal'
                  } else if (linkEntitiesData.entityAction == 'link') {
                    linkAction = 'de-link deal'
                  }
                }
                if (linkEntitiesData.entityStatus == 'ACK') {
                  let displayMessage= 'The '+ linkAction +' action on inquiry ' +linkEntitiesData.inquiryId+' and ' +linkEntitiesData.entityType+' ' +linkEntitiesData.entityId+ ' successful.';
                  this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: displayMessage });
                } else if (linkEntitiesData.entityStatus == 'NACK') {
                  let displayMessage= 'The '+ linkAction +' action on inquiry ' +linkEntitiesData.inquiryId+' and ' +linkEntitiesData.entityType+' ' +linkEntitiesData.entityId+ ' failed.';
                  this.messageService.add({ severity: 'error', key: 'qmaToast', summary: '', detail: displayMessage });
                }
              });
            }
          }
        }
      }, error => {
        // Web socket connection failure
        console.error("websocketupdate: connecting to ws url in notification.connectToWs.error()",error);
      }
    );
  }
  getAllNotification(){
    let approvalFlag = false;
    let tabName = '';
    //this.subjectName = "Pending Approval";
    this.notificationService.getNotifyUserInquiry().subscribe(notifyData => {
      notifyData.userNotificationsList.forEach(item => {
        let ind = this.notificationArray.findIndex((ele:any) => ele._id == item.inquiryId);
        item.crtDate = Object(this.dateFormatPipe.transform(item.crtDate, this.guserPrefDateFormat));
        let date = {
          $date: item.crtDate
        }
        if(ind === -1){
          item.workflows.forEach( item =>{
            if(item.workflowStatus){
              let approvalTest = item.workflowStatus.split("-");
              approvalFlag = approvalTest.indexOf("Approved ") !== -1;
            }
            if(approvalFlag){
              tabName = "Inbox";
            } else if( item.direction === "NOMINATE_OWNERSHIP") {
              tabName = "Pending Approval";
            } else if( item.direction !== "IN" &&  item.direction !== "OUT") {
              tabName = "Pending Approval";
            } else if(!this.isNull(item.generalEscalationReason) && item.isConvCountEscalation === "Y") {
              tabName = "Potential Escalations"; 
            }else if (item.action === "FollowUp" || item.action === "Snooze"  || item.action === "UnSnooze") {            
              if(item.action.toLowerCase() === "snooze") {
                tabName = "Snoozed";
              } else if(item.action.toLowerCase() === "unsnooze") {  
                tabName = "Snoozed";
              } else{
                tabName = "Inbox";
              }
            }
          });
          
          let inquiry = {
            subject: tabName,
            action : item.action,
            _id : item.inquiryId,
            crtDate: date,
            type: item.type,
            userId: item.userId,
            id: item.id       
          }
          this.notificationArray.unshift(inquiry); 
          this.inquiryCache.add(this.componentName, inquiry, this.notificationArray.length - 1, this.notificationArray.length);
          this.notificationService.setNotificationCount(this.notificationArray);
          this.notificationService.filterCount(this.notificationArray);         
        }
      }); 
    });
  }
   // Null value check function
   isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }
  onOptionClick(clickedItemIndex: number) {
      for (let i = 0; i < this.clickStatusArray.length; i++) {
        const val = this.clickStatusArray[i];
        this.clickStatusArray[i] = (clickedItemIndex === i) ? !val : false;
      }
  }  
  viewNotification(item: any) {
    this.openInquiryView(item); 
    this.notificationService.filter(item);
  }

  /**
   * get tab name based on notification item
   */
  getTabName(item: any) {
    let approvalFlag = false;
    let rejectFlag = false; 
    let tabName = '';
    item.workflows.forEach( item =>{
      if(item.workflowStatus){
        let approvalTest = item.workflowStatus.split("-");
        approvalFlag = approvalTest.indexOf("Approved " || "Accepted ") !== -1;
        //Notification open in related tab issue 
        let approvalRejectTest = item.workflowStatus.split("-");
        rejectFlag = approvalRejectTest.indexOf("Rejected ") !== -1;
      } else{
        if(item.nominationRejectionReason){
          tabName = "Inbox";
          this.tabName = "Inbox";
        }
      }
      if(this.tabName == ""){
        if(approvalFlag || rejectFlag){
          tabName = "Inbox";
        } else if( item.direction === "NOMINATE_OWNERSHIP") {
          tabName = "Pending Approval";
        } else if( item.direction !== "IN" &&  item.direction !== "OUT") {
          tabName = "Pending Approval";
        } else if(!this.isNull(item.generalEscalationReason) && item.isConvCountEscalation === "Y") {
          tabName = "Potential Escalations"; 
        }else if (item.action === "FollowUp" || item.action === "Snooze" || item.action === "UnSnooze") {           
          if(item.action.toLowerCase() === "snooze") {
            tabName = "Snoozed";
          }else if(item.action === "UnSnooze") {   
            tabName = "Inbox";
          }else{
            tabName = "Inbox";
          }
        }
      }     
    });    
    return tabName;
  }

  /**
   *open inquiry with dedicated "INQUIRY:" tab
   */
  openInquiryView(item:any) {
    let assignedGroupIds = [];
    if (item.workflows) {
      item.workflows.forEach(wf => {
        let assignedGroupId = wf.assignedGroupId;
        if (this.userDataService.userGroupIdList.indexOf(assignedGroupId) > -1) {
          assignedGroupIds.push(assignedGroupId);
        }
      });
    }
    const assignedGroup = item.openGroups;
    let workflow = {
      queryCount: item.queryCount,
      requestType: item.requestTypeStr || null,
      inquirySource: item.inquirySource,
      processingRegion: item.processingRegion,
      rootCause: item.rootCause,
      tag: item.tag
    };
    let curInq = item;
    let hasNominatedOwnership = item.workflows[0].hasNominatedOwnership !== undefined ? item.workflows[0].hasNominatedOwnership : false;
    let inquiryData = {
      id: curInq._id,
      selectInqAssignedGroupId: assignedGroupIds,
      isAllConvContent: false, // do not load all content upon first load of conv view
      hasNominatedOwnership: hasNominatedOwnership
    };
    // Send request to get the all the inquiry conversation for the selected inquiry Id.
    this.inboxService.getInquiryIdExtSelected(inquiryData, curInq).subscribe(inquiryDetail => {
      console.debug('openInquiryView: get the Inquiry details, id = : ' + inquiryData.id);
      // Set the conversation count
      let converationCount = inquiryDetail.conversationList.length;
      // re-trigger tab with the current inquiry
      this.tabDataService.setInquiryData({
        selectedMail: inquiryDetail,
        assignedGroup: assignedGroup,
        selectedConvId: null,
        selectedInqId: inquiryDetail.inquiry ? inquiryDetail.inquiry.id : null,
        inquiryWorkflow: workflow,
        inquirySubject: inquiryDetail.inquiry? inquiryDetail.inquiry.subject: '',
        inquiryReportedConvCount: converationCount, 
        mailContainerHeight: this.inboxService.currentViewHeight,
        inqData: inquiryData, 
        inboxViewMode: 'tab',
        inboxViewName: this.getTabName(item)
      });
      this.tabDataService.sendTabData("INQUIRY:" + item._id, 0);
    });
  }

  validateWSconnectionURL(wsURL: string) {

    //ToDo: To revert to wss for more secured transactions and from the endswith QMA is removed. No url with QMA is created
    return wsURL.startsWith('ws://')  && wsURL.endsWith('/inquiryActions');
  }

  dismissNotification(item: any, event: any) {
    this.notificationService.dismissNotification(item);
    this.notificationService.filterCount(this.notificationArray);
    // this.notificationService.updateNotifyUserInquiry({"_id":item.id,"userId":item.userId}).subscribe(inquiryDetail => {
    // });
    //this.notificationService.updateNotifyUserInquiry({"_id":item.id,"userId":item.userId})
  }

  dismissAll() {
    // this.notificationArray.forEach( element => {
    //   this.notificationService.updateNotifyUserInquiry({"_id":element.id,"userId":element.userId}).subscribe(inquiryDetail => {
    //   });
    // });
    this.notificationService.dismissAllNotifications();
    this.notificationService.filterCount(this.notificationArray);
       
  }

  ngOnInit() {
    this.subscription = this.notificationService.notificationData.subscribe(notifications => {
      this.notificationArray = notifications;
      this.inquiryCache.addAll(this.componentName, this.notificationArray);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

   /** 
   * Method to close Panel  
   */ 
  closePanel(){
    this.rightNavigationService.close();
  }

}