import { enableProdMode, ApplicationRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {LicenseManager} from "@ag-grid-enterprise/core";
import { enableDebugTools } from '@angular/platform-browser';
if (environment.production) {
  enableProdMode();
}

LicenseManager.setLicenseKey("XXXX");

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

/* platformBrowserDynamic().bootstrapModule(AppModule)
.then(moduleRef => {
  const applicationRef = moduleRef.injector.get(ApplicationRef);
  const componentRef = applicationRef.components[0];
  // allows to run `ng.profiler.timeChangeDetection();`
  enableDebugTools(componentRef);
})
.catch(err => console.error(err)); */
