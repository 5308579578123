<!--Responsive Design-->
<p-dialog  [(visible)]="isOpen" [modal]="true" [responsive]="false" [maximizable]="false"
  [appendTo]="'body'" [styleClass]="(inquiryFlag == 7)?'inquiryRedisgn':'contextMenuModal groupModal'" [closable]="false" [dismissableMask]="true"
  [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'height':'730px','max-width':'1334px', 'width': '100%'}" [resizable]="false">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between" style="padding: 20px 27px 0px 24px;">
          <span class="gfid-title" style="font-size: 20px;width: 18em;">ORG ADMINISTRATION  </span>
          <div class="d-flex flex-row align-items-start" style="width: 28.6em">
            <label for="from" style="margin-top: 8px;margin-right: 20px;" class="flex-grow">Organization Name: </label>
            <!-- <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down pull-left w-70" [(ngModel)]="selectedOrganization" (onChange)="orgChangesForInit()" [options]="organizationList" >
            </p-dropdown> -->
            <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down pull-left w-70" optionLabel="name"
                    [(ngModel)]="selectedOrganization" (onChange)="orgChangesForInit()" [options]="organizationList">
            </p-dropdown>
        </div>
          <button type="button" (click)="hideModal()" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1">
                      <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
                      <h6>CLOSE</h6>
                  </button>
      </div>
  </p-header>
  <div class="gfid-body admin-gfid-padding">
      <!-- Loader when hit the save/submit button -->
      <ng-template #customLoadingTemplate>
          <div class="custom-class-loader">
              <div class="spinner-container">
                  <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                      <circle cx="170" cy="170" r="160" stroke="#021e48" />
                      <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                      <circle cx="170" cy="170" r="110" stroke="#021e48" />
                      <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
                  </svg>
              </div>
          </div>
      </ng-template>
      <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
         <div class="d-flex flex-row pr-4">
          <p-tabView class="adminview" #adminTabs  [styleClass]="'admin-tabview'" [scrollable]="false"
              [ngClass]="{'redesign': inquiryFlag== 7}" [style]="{'height':'740px','min-width':'88em','max-width':'100em', 'margin-left': '24px',overflow: 'hidden', 'position': 'relative'}"
              [activeIndex]="inquiryFlag">
  <!--Super Admin meta data-->
  <p-tabPanel header="METADATA" *ngIf="isSuperUser">
    <div style="padding-left:20px">
        <div class="d-flex flex-row mt-4 mrg-left-4em mrg-lef ">
            <div class="w-20 d-flex flex-row align-items-start ">
                <label for="from" class="flex-grow meta-margin">Metadata type: </label>
                <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" [options]="metadataTypeList" [(ngModel)]="selectedMetaDataType"
                    (onChange)="selectOrgMetaDataType($event.value,'onchange')">
                </p-dropdown>
            </div>
        </div>
        <div *ngIf="metaDataType !== 'reqTypeCauseMapping'" class="d-flex flex-row mt-4" style="margin-top: 1em !important; font-family: Interstate">
            <span class="mr-9" style="padding-right: 10px;">
                <div class="add-new-btn" (click)="onBtStartEditing(null, null, 'top')">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                        <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/context/add-button.svg" alt="Add">
                    </span>
            <span>Add New</span>
        </div>
        </span>
        <span [hidden]="metaDeleteFlag">
                <!-- Jira:4703 : Confirmation Dialog before delete --> 
                <div class="add-new-btn" style="margin-left: 0.8em;" (click)="setConfirmation('metaData')">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                        <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/context/delete-button.svg" alt="Delete">
                    </span>
        <span>Delete</span>
    </div>
    </span>
</div>
<div style="margin-top: 1em !important; font-family: Interstate">
<span *ngIf="metaTagValidationFlag" style="color: red;">{{metaTagValidationMsg}} </span>
</div>
<div style="margin-top: 1em !important; font-family: Interstate">
 <span *ngIf="alreadyRaisedRequestRequestTypeStatus" style="color: red;">Request already raised, waiting for approval</span>
 <span *ngIf="alreadyRaisedRequestRootCauseStatus" style="color: red;">Request already raised, waiting for approval </span>
 <span *ngIf="alreadyRaisedRequestTypeRootCauseMappingStatus" style="color: red;">Request already raised, waiting for approval </span>
</div>

<!-- Meta Data Grid-->
<div *ngIf="metaDataType !== 'reqTypeCauseMapping'" class="d-flex flex-row mt-4">
<ag-grid-angular #agGrid style="width: 55.9%; height: 350px" id="metadata" class="ag-theme-balham headerPart" [columnDefs]="metaColumnDefs"
[rowData]="orgMetaRowData" (selectionChanged)="onSelectionCheck()" [defaultColDef]="metaCheckbox" [singleClickEdit]="true"
[stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onMetaDataGridReady($event)"
(cellValueChanged)="onCellValueChanged($event)" [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="true"
(cellKeyDown)="onkeydown($event)">
</ag-grid-angular>
</div>
<!-- Resquest Type and Root Cause Mapping -->
<div *ngIf="metaDataType === 'reqTypeCauseMapping'" class="d-flex flex-column">
   <!-- <div [ngClass]="{'disabled':alreadyRaisedRequestTypeRootCauseMappingStatus}"> comment it to see request type root cause mapping if request type root cause mapping request already raised  -->
<app-reqtype-rootcause [requestTypes]='organizationRequestTypeList' [requestCauses]='organziationRootCauseList' [reqTypeRootCauseList]="orgReqTypeRootCauseMap"
(updateMapping)="onApplyMapping($event)">
</app-reqtype-rootcause>
<!--</div> -->
</div>
</div>
<div><br></div>
<!--disabling org admin over ride-->

<span>
    <p-footer style="position: absolute; width: 100%; bottom : 0; right: 0">
    <div class="d-flex flex-row footer-btn-container"></div>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">   
            <button type="submit" class="btn save-btn mr-4" (click)="saveOrgAuditTrailData()">Submit</button>
            <button class="btn clear-btn" (click)="resetForm()">Reset</button>
     </div>
</p-footer>
</span>
</p-tabPanel>
<!--Sup Admin request-->
<p-tabPanel header="REQUESTS" class="tab-heading">
    <div style="padding-left:20px">
    <div class="d-flex flex-row mt-4 mrg-left-4em mrg-lef">
        <div class="w-20 d-flex flex-row align-items-start ">
            <label for="from" class="flex-grow meta-margin">Org Admin Request: </label>
            <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" [options]="selectedRequestList" [(ngModel)]="selectedRequest"
                 (onChange)="requestChange()">
                </p-dropdown>
        </div>
        <div *ngIf="pendingApprovalRequestDisplay" style="padding-left:19.8em; padding-right: 15px; padding-top: 8px;" class="d-flex flex-row">
            <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showRequestTabPanel($event, info_preferences)"
                (mouseout)="info_preferences.hide()" alt="Info">
            <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_preferences [baseZIndex]="9999">
                <div class="d-flex flex-row mb-0.3">
                    <span>
                        <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                    </span>
                    <span class="info-content">Green color:Newly added metadata<br>Red color: Deleted metadata<br>Blue color:Updated metadata</span>
                </div>
            </p-overlayPanel>
        </div>
    </div>
    <div style="margin-top: 1em !important; font-family: Interstate">
        <span *ngIf="requestApprovedValidationFlag" style="color: red;">{{requestApprovedValidationMsg}} </span>
    </div>
    <div *ngIf="myRequestDisplay" class="d-flex flex-row mt-4 mrg-left-3em mrg-lef">      
        <ag-grid-angular id="myGrid" #agGrid style="width: 100%; height: 300px" id="orgRequest" class="ag-theme-balham headerPart"
        [rowData]="outgoingRequestData" [columnDefs]="columnDefsForOutGoingRequest" [defaultColDef]="defaultColDef"
        [autoGroupColumnDef]="autoGroupColumnDef"  (firstDataRendered)="onFirstDataRendered($event)"
        (selectionChanged)="onRequestSelectionChanged($event)" (gridReady)="onRequestsGridReady($event)" 
        [rowHeight]="43" [headerHeight]="44" 
        [pagination]="true" [paginationAutoPageSize]="true"
        [suppressHorizontalScroll]="true" ></ag-grid-angular>
            
    </div>
    <!--start display my request-->
    <div  class="d-flex flex-row mt-8" style="overflow: auto;margin-top: 8px;">
       <div *ngIf="requestsHighLevelRequestTypeMapping && myRequestDisplay" class="col-6">
        <ag-grid-angular #agGrid style="width: 100%; height: 230px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="orgHighLevelRequestTypeColumn"
       [rowData]="requestsHighLevelRequestTypeRowData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onRequestTypeGridReady($event)"
       (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
        (cellKeyDown)="onkeydown($event)" [modules]="modules" [rowClassRules]="rowClassRules">
       </ag-grid-angular>
       </div>
   <div *ngIf="requestsRootCauseDisplay && myRequestDisplay" class="col-3">
       <ag-grid-angular #agGrid style="width: 100%; height: 230px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="orgRootCauseColumn"
              [rowData]="requestsRootCauseRowData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onRootCauseGridApi($event)"
              (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="34" [headerHeight]="35" [suppressHorizontalScroll]="true"
               (cellKeyDown)="onkeydown($event)" [rowClassRules]="rowClassRules">
           </ag-grid-angular>
   </div>
    <div *ngIf="requestsEnableGroupLevelMetaDataDisplay && myRequestDisplay" class="col-4">
       <ag-grid-angular #agGrid style="width: 100%; height: 150px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="disableMetaDataEditAtGroupLevel"
           [rowData]="requestDisableGroupLevelRowMetaData" (selectionChanged)="onSelectionCheck()" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onDisableGroupGridApi($event)"
           (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
            (cellKeyDown)="onkeydown($event)">
       </ag-grid-angular>
   </div>
   <!--dcc-->
<div *ngIf="enableIngSubStatusOrgLevelMetaDataDisplay && myRequestDisplay" class="col-4">
    <ag-grid-angular #agGrid style="width: 100%; height: 150px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="enableInquirySubStatusColDef"
           [rowData]="enableIngSubStatusOrgLevelRowMetaData" (selectionChanged)="onSelectionCheck()" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onEnableInquirySubStatusGridApi($event)"
           (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
            (cellKeyDown)="onkeydown($event)">
    </ag-grid-angular> 
</div>
   <!--for display request of org metadata disable-->
   <div *ngIf="requestOrgLevelMetaDataDisplay && myRequestDisplay" class="col-3">
    <ag-grid-angular #agGrid style="width: 100%; height: 150px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="enableOrgMetaDataColumn"
        [rowData]="enableOrgLevelMetaDataRowData" (selectionChanged)="onSelectionCheck()" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onDisableOrgMetaData($event)"
        (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
         (cellKeyDown)="onkeydown($event)">
    </ag-grid-angular>
</div>
   <div *ngIf="requestsRequestTypeRootCauseDisplay && myRequestDisplay" class="col-6">
       <ag-grid-angular #agGrid style="width: 100%; height: 230px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="orgRootCauseRequestTypeMapping"
       [rowData]="requestsRequestTypeRootCauseRowData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onRequestTypeRootCause($event)"
       (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
       (cellKeyDown)="onkeydown($event)" [rowClassRules]="rowClassRules">
       </ag-grid-angular>
   </div>
   </div>
    <!--end display my reqeust-->
    <div *ngIf="pendingApprovalRequestDisplay" class="d-flex flex-row mt-4 mrg-left-3em mrg-lef">  
         <ag-grid-angular id="myGrid" #agGrid style="width: 100%; height: 200px" id="orgRequest" class="ag-theme-balham headerPart "
          [rowData]="outgoingRequestData" [columnDefs]="columnDefsForOutGoingRequest" [defaultColDef]="defaultColDef"
          [autoGroupColumnDef]="autoGroupColumnDef"  [detailCellRendererParams]="detailCellRendererParams" [pagination]="true"[paginationAutoPageSize]="true"
          (firstDataRendered)="onFirstDataRendered($event)" (selectionChanged)="onRequestSelectionChanged($event)" [suppressHorizontalScroll]="true"
          (gridReady)="onPendingApprovalGridReady($event)" [rowHeight]="43" [headerHeight]="44"
         ></ag-grid-angular>
    </div>
    <div *ngIf="pendingApprovalRequestDisplay" class="d-flex flex-row mt-8" style="overflow: auto;margin-top: 8px;">
        <!--start of display pending approval grid-->
        <div *ngIf="requestsHighLevelRequestTypeMapping && pendingApprovalRequestDisplay" class="col-6">
            <ag-grid-angular #agGrid style="width: 100%; height: 210px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="orgHighLevelRequestTypeColumn"
           [rowData]="requestsHighLevelRequestTypeRowData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onRequestTypeGridReady($event)"
           (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="34" [headerHeight]="35" [suppressHorizontalScroll]="true"
            (cellKeyDown)="onkeydown($event)" [rowClassRules]="rowClassRules">
           </ag-grid-angular>
           </div>
       <div *ngIf="requestsRootCauseDisplay && pendingApprovalRequestDisplay" class="col-3">
           <ag-grid-angular #agGrid style="width: 100%; height: 210px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="orgRootCauseColumn"
              [rowData]="requestsRootCauseRowData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onRootCauseGridApi($event)"
              (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="34" [headerHeight]="35" [suppressHorizontalScroll]="true"
               (cellKeyDown)="onkeydown($event)" [rowClassRules]="rowClassRules">
           </ag-grid-angular>
       </div>
       <div *ngIf="requestsEnableGroupLevelMetaDataDisplay && pendingApprovalRequestDisplay" class="col-4">
           <ag-grid-angular #agGrid style="width: 100%; height: 150px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="disableMetaDataEditAtGroupLevel"
           [rowData]="requestDisableGroupLevelRowMetaData" (selectionChanged)="onSelectionCheck()" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onDisableGroupGridApi($event)"
           (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
            (cellKeyDown)="onkeydown($event)">
          </ag-grid-angular>
       </div>
       <!--dcc-->
    <div *ngIf="enableIngSubStatusOrgLevelMetaDataDisplay && pendingApprovalRequestDisplay" class="col-4">
    <ag-grid-angular #agGrid style="width: 100%; height: 150px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="enableInquirySubStatusColDef"
           [rowData]="enableIngSubStatusOrgLevelRowMetaData" (selectionChanged)="onSelectionCheck()" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onEnableInquirySubStatusGridApi($event)"
           (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
            (cellKeyDown)="onkeydown($event)">
    </ag-grid-angular> 
</div>
       <!--for display org metadata disable request-->
       <div *ngIf="requestOrgLevelMetaDataDisplay && pendingApprovalRequestDisplay" class="col-3">
        <ag-grid-angular #agGrid style="width: 100%; height: 150px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="enableOrgMetaDataColumn"
            [rowData]="enableOrgLevelMetaDataRowData" (selectionChanged)="onSelectionCheck()" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onDisableOrgMetaData($event)"
            (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
             (cellKeyDown)="onkeydown($event)">
        </ag-grid-angular>
        </div>
       <div *ngIf="requestsRequestTypeRootCauseDisplay && pendingApprovalRequestDisplay" class="col-6">
           <ag-grid-angular #agGrid style="width: 100%; height: 210px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="orgRootCauseRequestTypeMapping"
           [rowData]="requestsRequestTypeRootCauseRowData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onRequestTypeRootCause($event)"
           (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="34" [headerHeight]="35" [suppressHorizontalScroll]="true"
           (cellKeyDown)="onkeydown($event)" [rowClassRules]="rowClassRules">
           </ag-grid-angular>
       </div>
    <!--end of display pending approval grid-->
    </div>
    <div *ngIf="pendingApprovalRequestDisplay" style="margin-top: 15px;" class="d-flex flex-row  mrg-left-4em mrg-lef">
        <label for="from" style="margin-right: 2%;" class="flex-grow meta-margin">Comment: </label>
        <textarea style="width: 100%;" [(ngModel)]="orgComment"  rows="2" cols="30" pInputTextarea required></textarea>
    </div>
    <div *ngIf="pendingApprovalRequestDisplay" class="d-flex flex-row mt-4 mrg-left-3em mrg-lef"> 
        <span>
            <p-footer style="position: absolute; width: 100% ; bottom : 0; right: 0">
            <div class="d-flex flex-row footer-btn-container"></div>
            <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">   
                    <button type="submit" class="btn save-btn mr-4" (click)="approveRequest()">Approve</button>
                    <button class="btn clear-btn" (click)="rejectRequest()">Reject</button>
             </div>
        </p-footer>
        </span>
    </div>
  <div *ngIf="allRequestDisplay" class="d-flex flex-row mt-4 mrg-left-3em mrg-lef">       
     <ag-grid-angular id="myGrid"  #agGrid style="width: 100%; height: 300px" id="orgRequest" class="ag-theme-balham headerPart"
      [rowData]="outgoingRequestData" [columnDefs]="columnDefsForOutGoingRequest" [defaultColDef]="defaultColDef"
      [autoGroupColumnDef]="autoGroupColumnDef" [pagination]="true" [paginationAutoPageSize]="true" [suppressHorizontalScroll]="true"
      (firstDataRendered)="onFirstDataRendered($event)" (selectionChanged)="onRequestSelectionChanged($event)" (gridReady)="allRequestsGridReady($event)" [rowHeight]="43" [headerHeight]="44"
     ></ag-grid-angular>
  </div>
  <div  class="d-flex flex-row mt-8" style="overflow: auto;margin-top: 8px;">
     <!--start of display requests-->
    <div *ngIf="requestsHighLevelRequestTypeMapping && allRequestDisplay" class="col-6">
     <ag-grid-angular #agGrid style="width: 100%; height: 230px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="orgHighLevelRequestTypeColumn"
    [rowData]="requestsHighLevelRequestTypeRowData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onRequestTypeGridReady($event)"
    (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
     (cellKeyDown)="onkeydown($event)" [modules]="modules" [rowClassRules]="rowClassRules">
    </ag-grid-angular>
    </div>
<div *ngIf="requestsRootCauseDisplay && allRequestDisplay" class="col-3">
    <ag-grid-angular #agGrid style="width: 100%; height: 230px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="orgRootCauseColumn"
       [rowData]="requestsRootCauseRowData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onRootCauseGridApi($event)"
       (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
        (cellKeyDown)="onkeydown($event)" [rowClassRules]="rowClassRules">
    </ag-grid-angular>
</div>
<div *ngIf="requestsEnableGroupLevelMetaDataDisplay && allRequestDisplay" class="col-4">
    <ag-grid-angular #agGrid style="width: 100%; height: 150px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="disableMetaDataEditAtGroupLevel"
           [rowData]="requestDisableGroupLevelRowMetaData" (selectionChanged)="onSelectionCheck()" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onDisableGroupGridApi($event)"
           (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
            (cellKeyDown)="onkeydown($event)">
    </ag-grid-angular> 
</div>
<!--dcc-->
<div *ngIf="enableIngSubStatusOrgLevelMetaDataDisplay && allRequestDisplay" class="col-4">
    <ag-grid-angular #agGrid style="width: 100%; height: 150px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="enableInquirySubStatusColDef"
           [rowData]="enableIngSubStatusOrgLevelRowMetaData" (selectionChanged)="onSelectionCheck()" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onEnableInquirySubStatusGridApi($event)"
           (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
            (cellKeyDown)="onkeydown($event)">
    </ag-grid-angular> 
</div>
<!-- for disable org level metadata reqeusts-->
<div *ngIf="requestOrgLevelMetaDataDisplay && allRequestDisplay" class="col-4">
    <ag-grid-angular #agGrid style="width: 100%; height: 150px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="enableOrgMetaDataColumn"
        [rowData]="enableOrgLevelMetaDataRowData" (selectionChanged)="onSelectionCheck()" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onDisableOrgMetaData($event)"
        (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
         (cellKeyDown)="onkeydown($event)">
    </ag-grid-angular>
</div>
<div *ngIf="requestsRequestTypeRootCauseDisplay && allRequestDisplay" class="col-6">
    <ag-grid-angular #agGrid style="width: 100%; height: 230px" id="orgRequest" class="ag-theme-balham headerPart" [columnDefs]="orgRootCauseRequestTypeMapping"
    [rowData]="requestsRequestTypeRootCauseRowData" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true" (gridReady)="onRequestTypeRootCause($event)"
    (cellValueChanged)="onCellValueChanged($event)" [rowHeight]="39" [headerHeight]="40" [suppressHorizontalScroll]="true"
    (cellKeyDown)="onkeydown($event)"[rowClassRules]="rowClassRules">
    </ag-grid-angular>
</div>
<!--end of request display-->
</div>
</div>
</p-tabPanel>
 <!--- Add super admin-->
            <p-tabPanel header="ORG ADMINS" class="tab-heading">
                <div class="d-flex flex-row" style="margin: 30px 0 0 16px">
                    <div class="d-flex flex-row align-items-center input-holder my-2">
                        <div (click)="onEntitlementUserChange(0)" [ngClass]="['mr-3']" [class.isInactive]="!tabArray[0]">
                            <ins>Current User</ins>
                        </div>
                        <div (click)="onEntitlementUserChange(1)" [ngClass]="['mr-3']" [class.isInactive]="!tabArray[1]">
                            <ins>ADD NEW USER</ins>
                        </div>
                    </div>
                </div>
                <div *ngIf="!tabArray[1]" class="firstView" style="margin-left: 28px;">
                    <div *ngIf="entitleDelAndGropFlag" class="d-flex flex-row mt-4">
                        <label for="from" class="flex-grow">USERS</label>
                    </div>
                    <div [hidden]="entitleDelAndGropFlag">
                        <div class="d-flex flex-row mt-4">
                            <div class="d-flex flex-row flex-wrap">
                                <div style="margin-right: 39px">
                                    <label for="from" class="flex-grow">{{entitleSelUser}} USERS SELECTED</label>
                                </div>
                                <div style="margin-right:16px">
                                    <!-- Jira:4703 : Confirmation Dialog before delete -->
                                    <span class="add-new-btn" (click)="setConfirmation('userEntitlement')">
                                        <span style="text-decoration: none; display: inline-block;">
                                            <img style="width:11px;height:11px;" class="qma-text" src="assets/context/delete-button.svg" alt="Delete">
                                        </span>Delete
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-row mt-4">
                        <ag-grid-angular style="width: 54.5%; height: 32.7em;" class="ag-theme-balham" id="userEntitlement" [rowData]="orgSuperAdminsRowData"
                            [columnDefs]="superAdminColumns" [stopEditingWhenGridLosesFocus]="true" [defaultColDef]="entitleColDef"
                            [singleClickEdit]="true" (selectionChanged)="onSelectionChanged($event)" (cellEditingStopped)="onCellEditingStopped($event)"
                            (gridReady)="onEntitleGridReady($event)" [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="true"
                            [modules]="modules">
                        </ag-grid-angular>
                    </div>
                </div>
                <div *ngIf="!tabArray[0]" class="secondView" style="margin-left: 16px; margin-top: 31px;">
                    <div class="d-flex flex-row">
                        <div class="col-8">
                            <div class="d-flex flex-row mt-6">
                                <label for="from" class="flex-grow">SEARCH by SOEID</label>
                            </div>
                            <div class="d-flex flex-row mt-0.5">
                                <input type="text" [(ngModel)]="newUserSoeid" (keydown)="keyDownFunction($event)" placeholder="Search..." class="w-50 search-box"
                                />   
                                <div [hidden]="entitleDelAndGropFlag">
                                <div style="margin-left:20px">
                                    <span class="add-new-btn" (click)="addNewSuperAdmin()">
                                        <span style="text-decoration: none; display: inline-block;">
                                            <img style="width:11px;height:11px;" class="qma-text" src="assets/context/add-button.svg" alt="Add">
                                        </span>Add new super Admin
                                    </span>
                                </div>
                            </div>                            
                            </div>
                            <div style="margin-top: 1em !important; font-family: Interstate"><span *ngIf="userEntitlementFlag" style="color: red;">{{entitlementValidationMsg}} </span></div>
                            <div [hidden]="newUserGridFlag" class="d-flex flex-row mt-8">
                                <ag-grid-angular #agAddNewData style="width: 70.5%; height: 20.7em;" class="ag-theme-balham" id="userEntitlementAddUser" [rowData]="addNewUserData"
                                    [columnDefs]="addNewUserColumns" [stopEditingWhenGridLosesFocus]="true" [defaultColDef]="entitleColDef"
                                    [singleClickEdit]="true" (selectionChanged)="onAddSelectionChanged($event)" (cellEditingStopped)="onCellEditingStopped($event)"
                                    (gridReady)="onAddNewDataGridReady($event)" [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="true"
                                    [modules]="modules">
                                </ag-grid-angular>
                            </div>
                        </div> 
                    </div>
                </div>
            </p-tabPanel> 
            <!--org group ---> 
            <p-tabPanel header="ORG GROUP" class="tab-heading">  
                <div  class="d-flex flex-row mrg-lef mrg-left-4em" style="margin-top: 1em !important;margin-bottom: 1em !important;font-family: Interstate">
                    <span class="mr-9" style="padding-right: 10px;">
                        <div class="add-new-btn" (click)="download_rules()">
                            <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                                <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/Inbox/download_icon_u70.svg" alt="Download">
                            </span>
                    <span>Download</span>
                </div>
                </span>
            </div>                       
                <div class="d-flex flex-row mrg-lef mrg-left-4em">        
                        <ag-grid-angular style="width: 98%; height: 600px"  class="ag-theme-balham headerPart"
                          [defaultColDef]="orgCheckbox" [rowData]="organizationGroupList" [columnDefs]="orgGroupColumn"
                          [modules]="modules" (gridReady)="onGridReady($event)" [rowHeight]="38" [headerHeight]="42" [pagination]="true"
                          [paginationAutoPageSize]="true" [suppressHorizontalScroll]="true">
                        </ag-grid-angular>
                </div> 
            </p-tabPanel>
            <!---End of group-->
            <!--Start org preferences-->
            <p-tabPanel header="ORG PREEFRENCES" class="tab-heading">  
                <!-- [C170665-1719] DCC Requirement: Add Case status field -->
                <div *ngIf="enableInquirySubStatusFlag" class="d-flex flex-row ml-4" style="margin-top: 1em;">
                    <div class="flex-row flex-nowrap align-items-end">
                        <label for="from" class="flex-grow label_font mt-2">Enable Inquiry Sub-Status: </label>
                    </div>
                    <div style="margin-left:2em;" class="flex-row flex-nowrap align-items-end">
                        <p-inputSwitch [(ngModel)]="orgLevelInquirySubStatusEnabled" (onChange)="enableInquirySubStatusOverride()" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                    </div>
                </div>
                <div class="d-flex flex-row ml-4" style="margin-top: 2em;">
                    <div class="flex-row flex-nowrap align-items-end">
                        <label for="from" class="flex-grow label_font mt-2">Enable org level metadata:</label>
                    </div>
                    <div style="margin-left:2em;" class="flex-row flex-nowrap align-items-end">
                        <p-inputSwitch [(ngModel)]="enableOrgMetadata" (onChange)="enableMetaDataOverride()" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                    </div>
                </div>
                <div *ngIf="enableOrgMetadata" class="d-flex flex-row ml-4" style="margin-top: 1em;">
                    <div class="flex-row flex-nowrap align-items-end">
                        <label for="from" class="flex-grow label_font mt-2">Disable Metadata edit at group level :</label>
                    </div>
                    <div style="margin-left:2em;" class="flex-row flex-nowrap align-items-end">
                        <p-inputSwitch [(ngModel)]="groupLevelMetaOverride" (onChange)="enableMetaDataOverride()" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                    </div>
                </div>
                <span>
                    <p-footer style="position: absolute; width: 100%; bottom : 0; right: 0">
                    <div class="d-flex flex-row footer-btn-container"></div>
                    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">   
                            <button type="submit" class="btn save-btn mr-4" (click)="disableGroupLevelMetaData()">Submit</button>
                            <button class="btn clear-btn" (click)="resetDisableGroupEdit()">Reset</button>
                     </div>
                </p-footer>
                </span>
            </p-tabPanel>
  </p-tabView>
  </div>
  </div>
</p-dialog>
<!--Admin Save-->

<p-dialog [(visible)]="closeFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
          <!-- <button type="button" (click)="groupSave.hide()" class="d-flex flex-row close qma-text">
              <img src="assets/Inbox/close_icon_u73.svg">
              <h6>CLOSE</h6>
          </button> -->
      </div>
  </p-header>
  <div class="success-msg">{{grpSaveMsg}}</div>
</p-dialog>

<!-- Sucess msg for add to group user entitlement -->

<p-dialog [(visible)]="closeFlagAddGroup" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%', 'margin-left':'150px'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
          <!-- <button type="button" (click)="closeAddGroup()" class="d-flex flex-row close qma-text">
          <img src="assets/Inbox/close_icon_u73.svg">
          <h6>CLOSE</h6>
      </button> -->
      </div>
  </p-header>
  <div class="success-msg">{{userAddGroupMsg}}</div>
</p-dialog>


<!-- Profile Setting Save -->

<p-dialog [(visible)]="orgAuditTrailSaveStatusSuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> Details Saved Waiting For Approval!</div>
</p-dialog>

<p-dialog [(visible)]="orgAuditTrailSaveStatusFail" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> Error Occured During Save !</div>
</p-dialog>
<!--Confirmation popup window-->
<!-- Jira:4703 : Confirmation Dialog before delete -->
<p-dialog [(visible)]="showPopUpDelete" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal'"
  [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
          <h6 class="reage-title">CONFIRM ACTION</h6>
          <!-- Jira:4703 : close button on dialog for confirmation of delete -->
          <button type="button" (click)="onCancel()" class="d-flex flex-row close qma-text">
          <img src="assets/Inbox/close_icon_u73.svg" alt="Close">            
      </button>
      </div>
  </p-header>
  <div class="reage-body mx-2">
      <div class="d-flex flex-row align-items-center mb-4 pb-4">
          <label>{{confirmMsgText}}</label>
      </div>
  </div>
  <p-footer>
      <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
          <button class="btn search-btn mr-4" (click)="onOk()">Ok</button>
          <button type="reset" class="btn clear-btn" (click)="onCancel()">Cancel</button>
      </div>
  </p-footer>
</p-dialog>
<!--
<p-toast position="center" (onClose)="onReject()" styleClass="custom-toast" key="custom" [modal]="true" [baseZIndex]="5000"></p-toast>
-->

<!--for add super admin success message-->
<p-dialog [(visible)]="superAdminAddFail" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '25%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> {{superAdminAddMessage}}</div>
</p-dialog>
<p-dialog [(visible)]="superAdminAddSuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '25%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> {{superAdminAddMessage}}</div>
</p-dialog>

<!--for add super admin success message-->
<p-dialog [(visible)]="orgAdminDeleteSuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '25%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> {{orgAdminDeleteMessage}}</div>
</p-dialog>
<p-dialog [(visible)]="orgAdminDeleteFail" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '25%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> {{orgAdminDeleteMessage}}</div>
</p-dialog>
<!--pop for display when request approve-->
<p-dialog [(visible)]="approvedRequestSuccessFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '25%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> {{approvedRequestSuccessMsg}}</div>
</p-dialog>
<!--pop for display when request rejcted-->
<p-dialog [(visible)]="apporvedRequestFailureFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '25%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> {{approvedRequestSuccessMsg}}</div>
</p-dialog>
<p-dialog [(visible)]="reqeustRejectedFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '25%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> {{requestRejectedMsg}}</div>
</p-dialog>
<!--display message for already raised request-->
<p-dialog [(visible)]="alreadyRaisedRequestFlag" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> {{alreadyRaisedRequestMessage}}</div>
</p-dialog>
<!--display success mesage for disable org metadata-->

<p-dialog [(visible)]="enableOrgMetadataSuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg"> Org request successfully completed</div>
</p-dialog>
<!--display failure message for disable org metadata-->

<p-dialog [(visible)]="enableOrgMetaDataFail" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
      <div class="d-flex flex-row align-items-center justify-content-between">
          <h6 class="success-title">Success Confirmation</h6>
      </div>
  </p-header>
  <div class="success-msg">An error has been occured</div>
</p-dialog>