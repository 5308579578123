import { Injectable } from '@angular/core';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
// import * as fileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { BehaviorSubject, Observable } from 'rxjs';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
declare const ExcelJS: any;

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  workbook: ExcelJS.Workbook;
  worksheet: any;
  inqRulesReorderInfo = new BehaviorSubject({});
  constructor() { }

  /**
   * Method to return the export excel
   */
  generateExcel(excelObj: ExcelExport): void {

    //Create workbook and worksheet
    this.workbook = new ExcelJS.Workbook();
    this.worksheet = this.workbook.addWorksheet(excelObj.sheetName);

    //Add Header Row
    let headerRow = this.worksheet.addRow(excelObj.header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF00' },
        bgColor: { argb: 'FF0000FF' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.font = { bold: true };
    });

    // Add Data 
    excelObj.data.forEach(d => {
      let dataRow = this.worksheet.addRow(d);
      dataRow.eachCell((cell, number) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'E9E9E2' }
        };
        cell.alignment = { wrapText: true, vertical: 'middle' };
      });
      dataRow.height = excelObj.height;
    });

    // Setting the width of each column
    const columns = excelObj.header;
    for (let i = 1; i < columns.length + 1; i++) {
      this.worksheet.getColumn(i).width = excelObj.width[i - 1];
    }

    //Generate Excel File with given name
    this.workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: EXCEL_TYPE });
      // fileSaver.saveAs(blob, excelObj.fileName);
      console.log("Generated the excel report : " + excelObj.fileName);
    }, error => {
      console.log("Error while generating excel", JSON.stringify(error));
    });
  }

  /**
   * C153176-4445: Method to read the upload excel content.
   * @param arrayBuffer 
   * @param rawValue 
   * @param startRow 
   */
  readExcelContent(arrayBuffer: any, rawValue: boolean = true, startRow: number= 0) {
    let worksheet = this.getExcelWorkSheet(arrayBuffer);

    // If need to get the data from the specific range.
    let range = XLSX.utils.decode_range(worksheet['!ref']);
    range.s.r = startRow || range.s.r;
    range.e.r = range.e.r;
    let new_range  = XLSX.utils.encode_range(range);
  
    if (rawValue) return XLSX.utils.sheet_to_json(worksheet, { raw: true, defval: null });
    else return XLSX.utils.sheet_to_json(worksheet, { defval: '',  range: new_range });
  }

  /**
   * C153176-4445: Method to get the Headers of the uploaded excel.
   * @param arrayBuffer 
   * @param startRow 
   */
  getHeaderRow(arrayBuffer: any, startRow: number) {
    let headers = [];
    let worksheet = this.getExcelWorkSheet(arrayBuffer);

    let range = XLSX.utils.decode_range(worksheet['!ref']);
    let C, R = startRow || range.s.r; /* start in the first row */
    /* walk every column in the range */
    for (C = range.s.c; C <= range.e.c; ++C) {
      let cell = worksheet[XLSX.utils.encode_cell({ c: C, r: R })] /* find the cell in the first row */

      let hdr = "UNKNOWN " + C; //  default header
      if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
      headers.push(hdr);
    }
    return headers;
  }

  /**
   * C153176-4445: Method to retrun the worksheet of the uploaded excel.
   * @param arrayBuffer 
   */
  private getExcelWorkSheet(arrayBuffer: any): any {
    let data = new Uint8Array(arrayBuffer);
    let arr = new Array();
    for (let i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
    let bstr = arr.join("");
    let workbook = XLSX.read(bstr, { type: "binary" });
    let first_sheet_name = workbook.SheetNames[0];
    let worksheet = workbook.Sheets[first_sheet_name];

    return worksheet;
  }

  /**
   *  C153176-4445: Method to download the content as text file.
   * 
   * @param content 
   */
  downloadAsText(content: any[], fileName: string): void {
    let saveAsFile = fileName + '.txt';
    let blob = new Blob(content, { type: "text/plain;charset=utf-8;", });
   //  fileSaver.saveAs(blob, saveAsFile);
  }

  getinqRulesReorderInfo(): Observable<any> {
    return this.inqRulesReorderInfo.asObservable();
    
  }
  setinqRulesReorderInfo(inqRulesReorderInfo: InqRulesReorderInfo) {
    this.inqRulesReorderInfo.next(inqRulesReorderInfo);
  }
}

export interface ExcelExport {
  fileName: string,
  sheetName: string,
  header: string[],
  data: any[],
  width: number[],
  height: number
}

export class InqRulesReorderInfo {
originalOrderNumber: number;
newOrderNumber: number;

}