import { Component, OnInit, Output, EventEmitter, Input, Renderer2, Inject, ViewChild } from '@angular/core';
import { SymphonyChatServiceService } from '../service/symphony-chat-service.service';
import { GroupAdminService } from 'src/app/services/group-admin/group-admin.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { AllToCcDBUser } from 'src/app/model/LoginUserInfo/AllToCcDBUser';
import { DOCUMENT } from '@angular/common';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { AllActiveGroup } from 'src/app/model/LoginUserInfo/AllActiveGroup';
import { forkJoin } from 'rxjs';
import { InboxViewComponent } from 'src/app/inbox/inboxView/inboxView.component';
import { NgForm } from '@angular/forms';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
declare var window: any;

@Component({
  selector: 'app-symphony-create-chat',
  templateUrl: './symphony-create-chat.component.html',
  styleUrls: ['./symphony-create-chat.component.scss']
})
export class SymphonyCreateChatComponent implements OnInit {
  @Output() streamId = new EventEmitter();
  chatRooms: Array<Object> = [];
  filteredChatRooms: Array<Object> = [];
  inputChatRoomName: string = '';
  inputMemberSoeids: string = '';
  memebersList: string='';
  @Input()
  displayChatRoomPopUp: boolean = false;
  selectedGroupList: any[];
  selectedGroup: string = '';
  filteredToUsersMultiple: any[] = [];
  allToCcDBUser: AllToCcDBUser[] = [];
  userList: AllToCcDBUser[] = [];

  contextmenu = false;
  contextmenuX= 0;
  contextmenuY= 0;
  itemId = "";
  memberSelectionType: string = 'group';

  //add or remove member
  displayAddMemberPopUp: boolean = false;
  memberToBeAdded: AllToCcDBUser = null;
  selectedRoomId: string = '';
  selectedRoomName: string = '';
//to show response message
displayResponse: boolean = false;
responseMessage: any = {};
loggedInUser: string;
@Input() listStyle:any ;
groups: [];
toUsersList: AllToCcDBUser[] = [];
citiDomains: any[];
selectedContactId: string = '';
email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
allActiveGroups: AllActiveGroup[] = [];
memebersListArray: Array<string> = new Array<string>();
grpListArray: Array<number> = new Array<number>();
toUserSoeIds : Array<string> = new Array<string>();
externalSymphonyIds: Array<number> = new Array<number>();
public showSpinner: boolean = true;
@Output()
showHideSymphonySpinner: EventEmitter<boolean> =new EventEmitter();
loggedInUserObj : any;
inboxViewComp: InboxViewComponent;
enteredExternalUsers:any;
@ViewChild('newChatRoomForm', { static: true }) createnewChatRoomForm: NgForm;
botName: string = "";
disclaimer = false;
// C170665-1090 Issue- Create Symphony chat room does not work from New message- New chat room menu
@Output()
onSympCrtChatPopupClose: EventEmitter<boolean> = new EventEmitter();
constructor(private symphonyChatServiceService: SymphonyChatServiceService,
    private groupAdminService: GroupAdminService,
    private userDataService: UserDataService,
    private _renderer2: Renderer2, 
    @Inject(DOCUMENT) private _document: Document) { }

  ngOnInit() {
    if(!window['symphony']){
     // this.loadSymphonyScript();
    }
  // this.loadUserSymphonyChatRoom();
    this.loadGroups();
    this.getUserData();
  }

  private loadSymphonyScript() {
    window['onloadCallback'] = function() {
      console.log('symphony');
    }
    let script = this._renderer2.createElement('script');
    script.type = "text/javascript";
    script.src = "XXXXX";
    script.dataset.onload = "onloadCallback";
    script.id = "symphony-ecm-sdk";
    script.async = true;
    script.defer = true;
    this._renderer2.appendChild(this._document.head, script);
  }

  openSymphonyView(streamId: string){
    this.streamId.emit(streamId);
  }

  createChatRoom(newChatRoomForm) {
    // return if validation fails
    if (newChatRoomForm && newChatRoomForm.invalid) {
      return;
    }
    if(this.inboxViewComp) {
      this.inboxViewComp.showSpinner = true;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::symphony-create-chat.createChatRoom");
    }
    console.log('creating chat room'+this.inputChatRoomName);
    if(this.inputChatRoomName && this.inputChatRoomName.length>0){
      this.getRoomMember();
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "New Chat Room", "Create Chat Room", "Click", "Create Chat Room", 0);
  }

  loadUserSymphonyChatRoom() {
    console.log('creating chat room');
    this.symphonyChatServiceService.loadUserSymphonyChatRoom({}).subscribe(
      (data: Array<Object>)=> {
        this.chatRooms = [];
        console.log(data);
        data.forEach((v,i,data)=>{
          console.log(v);
          if(v['streamType']['type']=='ROOM'){
            let name = v['roomAttributes']['name'];
            let id = v['id'];
            let obj = {};
            obj['name']= name;
            obj['id'] = id;
            this.chatRooms.unshift(obj);
          }
        });
        this.filteredChatRooms = Object.assign([], this.chatRooms);
      },
      (error)=>{
        console.log(error);
      }
    );
  }

  displayChatRoomPopup(inboxViewComp?: any){
    this.inboxViewComp = inboxViewComp;
    this.displayChatRoomPopUp = true;
  }

  cancelPopup(){
    this.displayChatRoomPopUp = false;
    // C170665-1090 Issue- Create Symphony chat room does not work from New message- New chat room menu
    this.onSympCrtChatPopupClose.emit(false);
  }

  getRoomMember(): void{
    // notify left nav to show spinner
    this.displayChatRoomPopUp = false;
    this.showHideSymphonySpinner.emit(true);
    this.memebersListArray = [];
    this.toUserSoeIds = [];
    this.grpListArray = [];
    this.externalSymphonyIds = [];
    this.enteredExternalUsers = [];
    let response = [];
    if(this.toUsersList) {
      this.toUsersList.forEach(toUser => {
        // service call only if its group
        if(toUser.id == null) {
          response.push(this.groupAdminService.getGroupDataEntitlement(toUser.text));
          this.grpListArray.push(+toUser.value);
        } else if(toUser.id !== ""){ // internal soeid
          this.toUserSoeIds.push(toUser.id);
          this.memebersListArray.push(toUser.id);
        }else { // external id to check if valid onboarded symphonyid
          this.enteredExternalUsers.push(toUser);
         
        }
        
      });
    }
    if (response.length > 0) {
      // wait till call for all groups complete
      forkJoin(...response).subscribe((soeids: any) => {
        console.log(soeids);
        this.memebersListArray = soeids.flat().map(sid => sid.userId).concat(this.toUsersList.filter(tu => tu.id != null).map(tu => tu.id));
        this.saveChatRoom(this.memebersListArray);
      })
    } else { // no grp involved only soeids
      this.saveChatRoom(this.memebersListArray);
    }

    /* if(this.memberSelectionType === 'soeId' && this.userList.length>0){
      this.userList.forEach((user)=>{
        memebersList.push(user.id);
      });
      this.saveChatRoom(memebersList);
    } else if(this.selectedGroup){
      this.groupAdminService.getGroupDataEntitlement(this.selectedGroup['name'])
      .subscribe((userList: Array<Object>) => {
      userList.forEach((v,i,arr)=>{
        memebersList.push(v['userId']);
      });
      this.saveChatRoom(memebersList);
      },
      err => {
        console.log(err);
      });
    } */
  }

  private saveChatRoom(memebersList: Array<string>) {
    this.memebersList = memebersList.toString();
      let reqParam = {};
      reqParam['name'] = this.inputChatRoomName;
      reqParam['roomMembers'] = this.memebersList === "" ?this.loggedInUserObj.symphonyConfig.symphonyBot:
                                this.memebersList + "," + this.loggedInUserObj.symphonyConfig.symphonyBot;
    this.symphonyChatServiceService.createSymphonyChatRoom( this.loggedInUserObj, this.loggedInUser, reqParam).subscribe((data) => {
      
     
      // this.loadUserSymphonyChatRoom();
    
      // add external members
        this.addExternalMembers(data);
      
    }, (error) => {
      console.log(error);
      this.displayChatRoomPopUp = false;
      this.showHideSymphonySpinner.emit(false);
      if(this.inboxViewComp) {
        this.inboxViewComp.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::symphony-create-chat.saveChatRoom");
      }
      this.showResponseMessage(error);
      
    });
  }

  loadGroups(){
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.citiDomains = loginUserInfo.citiDomainList;
      this.groups = loginUserInfo.myGroups;
      const groups = loginUserInfo.myGroups;
      this.selectedGroupList = [];
      this.loggedInUser = loginUserInfo.userId;
      this.loggedInUserObj = loginUserInfo;
      groups.forEach(element => {
        this.selectedGroupList.push({ 'name': element.groupName });
      });
      this.selectedGroup = this.selectedGroupList[0];
      loginUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
        let individualRecipent: AllToCcDBUser = { ...toCcBccUsers };
        individualRecipent.email = individualRecipent.value;
        this.allToCcDBUser.push(individualRecipent);
      });
    });
  }

  getFilteredToCCUsers(query) {
    let filtered = [];

    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });
    this.allActiveGroups.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });
    return filtered;
  }

  filterUsers(event) {
    let query = event.query;
    this.filteredToUsersMultiple = this.getFilteredToCCUsers(query);
  }

  onRightClick(event, roomId, roomName) {
    this.selectedRoomId = roomId;
    this.selectedRoomName = roomName;
    this.contextmenuX = event.clientX;
    this.contextmenuY = event.clientY;
    this.itemId = event.currentTarget.id;
    this.contextmenu = true;
  }

  disableContextMenu() {
    this.contextmenu = false;
  }

  filterItem(value:string){
    if(!value){
      this.filteredChatRooms = Object.assign([], this.chatRooms);
    }
    this.filteredChatRooms = Object.assign([], this.chatRooms).filter(
       item => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1
    )
  }

  getUserSymphonyId(selectedSoeId: string, callback: Function) {
    console.log('get user symphonu id');
    let reqParam = {};
    reqParam['memberSoeId']=selectedSoeId;
    this.symphonyChatServiceService.getUserSymphonyId(reqParam).subscribe(
      (data: Object)=> {
        console.log(data);
        let userSymphonyId = data['payload']['id'];
        callback.call(this,userSymphonyId);
;      },
      (error)=>{
        console.log(error);
      }
    );
  }

  openAddMemberPopup(){
    this.displayAddMemberPopUp = true;
  }

  actionOnChatRoom(action: string){
    switch(action){
      case 'add':
        this.getUserSymphonyId(this.memberToBeAdded.id, this.addMember);
        break;
      case 'remove':
        this.getUserSymphonyId(this.memberToBeAdded.id, this.removeMember);
        break;
      case 'cancel':
        this.displayAddMemberPopUp = false;
        break;
    }
  }

  addMember(userSymphonyId: string){
    let reqParam = {};
    reqParam['memberSymphonyId'] = userSymphonyId;
    reqParam['streamId'] = this.selectedRoomId;
    this.symphonyChatServiceService.addMember(reqParam).subscribe(
      (data: Object)=> {
        console.log(data);
        this.actionOnChatRoom('cancel');
        this.showResponseMessage(data);
;      },
      (error)=>{
        console.log(error);
      }
    );
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "New Chat Room", "Chat Room - Add member", "Click", "Chat Room - Add member", 0);
  }

  removeMember(userSymphonyId: string){
    let reqParam = {};
    reqParam['memberSymphonyId'] = userSymphonyId;
    reqParam['streamId'] = this.selectedRoomId;
    this.symphonyChatServiceService.removeMember(reqParam).subscribe(
      (data: Object)=> {
        console.log(data);
        this.actionOnChatRoom('cancel');
        this.showResponseMessage(data);
      },
      (error)=>{
        console.log(error);
      }
    );
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "New Chat Room", "Chat Room - Remove member", "Click", "Chat Room - Remove member", 0);
  }

  showResponseMessage(data: any) {
    if(data && data.members && data.members.length > 0) {

     let allMembersAddedInGrp = data.members.map(m=>m.user);
     let allSoeidsEntered= this.toUserSoeIds.concat(this.memebersListArray);
     let soeIdsGotExcluded = [];
     let map = this.loggedInUserObj.userSymphonyIdToUserMap;
     let soeIdSymPdMap = [];
       for (const [key, value] of Object.entries(this.loggedInUserObj.userSymphonyIdToUserMap)) {
             let entry:any = value;
             soeIdSymPdMap.push({soeId:entry.soeId,symphonyId:entry.symphonyId});
       }
       allSoeidsEntered.forEach(soeId => {
         console.log(soeIdSymPdMap);
         let onBoardedQMASoeids:any = soeIdSymPdMap;
         if(!onBoardedQMASoeids.map(m=>m.soeId).includes(soeId)){
          soeIdsGotExcluded.push(soeId); 
         }
       });
         // get display name based on ids
       let allUsersExcluded = this.allToCcDBUser.filter(au => soeIdsGotExcluded.includes(au.id)).map(au => au.text);
       this.responseMessage = {};
       this.responseMessage.header = "Chatroom Created Successfully";
       this.responseMessage.title = `
       We were not able to add below users to the chat:-
       `;
       
       // check for excluded external user
       if(this.enteredExternalUsers.length > 0) {
         let excludedExtrenalMemberEmail = this.enteredExternalUsers.filter(eu=>!data.members.map(u=>u.user).map(u=>u.email).includes(eu.email)).map(eu=>eu.email);
         allUsersExcluded.push(...excludedExtrenalMemberEmail);
       }
       this.responseMessage.excludedMembers = allUsersExcluded;
       this.displayResponse = true;
       this.showHideSymphonySpinner.emit(false);
        if(this.inboxViewComp) {
        this.inboxViewComp.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::symphony-create-chat.showResponseMessage");
      }
    }
    /* if(data && data.payload && data.payload.roomMembers) {
      let AddedSymphonyIds = data.payload.roomMembers.split(',').map(x=>+x);
      let AddedMemberDetailsRes = [];
      AddedSymphonyIds.forEach(sympId => {
        let req = {memberSoeId:sympId,memberType:'SymphonyId'};
        AddedMemberDetailsRes.push(this.symphonyChatServiceService.getUserSymphonyIdByType(req));
      });
      forkJoin(...AddedMemberDetailsRes).subscribe((sympent: any) => {
       let totalMembers = this.memebersList.split(',');
       let ToUserList = this.toUserSoeIds;
       let addedMembers = sympent.map(pl=>pl).map(pl=>pl.payload.ssoId);
       // first chat created with logged in user ID
       addedMembers.push(this.loggedInUser);
       let excludedMembers = totalMembers.filter(tm => !addedMembers.includes(tm));
       // get display name based on ids
       let allUsersExcluded = this.allToCcDBUser.filter(au => excludedMembers.includes(au.id)).map(au => au.text);
       this.externalSymphonyIds;
       this.responseMessage = {};
       this.responseMessage.header = "Chatroom Created Successfully";
       this.responseMessage.title = `
       Following users are excluded:-
       `;
       // excluded external members
       let addedMembersExternalEmail = sympent.map(pl=>pl).map(pl=>pl.payload.email);
       let excludedExtrenalMemberEmail = this.enteredExternalUsers.filter(eu=>!addedMembersExternalEmail.includes(eu.email));
       if(excludedExtrenalMemberEmail) {
        allUsersExcluded.push(excludedExtrenalMemberEmail.map(eu=>eu.email));
       }
       
       this.responseMessage.excludedMembers = allUsersExcluded;
       this.displayResponse = true;
       this.displayChatRoomPopUp = false;
       this.showHideSymphonySpinner.emit(false);
       this.inboxViewComp.showSpinner = false;
      });
     
    } */ else { // null response from symphony error is failure
      this.responseMessage = {};
      this.responseMessage.header = data && data.error && data.error.payload 
                                    ? data.error.payload :"Chatroom not created";
      this.displayResponse = true;
      this.displayChatRoomPopUp = false;
      this.showHideSymphonySpinner.emit(false);
      if(this.inboxViewComp) {
        this.inboxViewComp.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::symphony-create-chat.showResponseMessage");
      }
      
    }
   
  /*   this.displayResponse = true;
    this.responseMessage = data['message']; */
  }

  saveSymphonyDetails(serviceRes) {
    // if no users explicitly add current user id in memberlist
    if(this.toUserSoeIds && this.toUserSoeIds.length === 0) {
      this.toUserSoeIds.push(this.loggedInUser);
    }
    
    // we need this call as external members are added after chatroom creation 
    // and add member dont return symp id added
    const reqObj = {
      streamId:serviceRes.payload.roomId
    };
    this.symphonyChatServiceService.getSymphonyMemberLists(reqObj).subscribe((ml: any) => {
      // let addedMembersSymphonyIds = serviceRes.payload.roomMembers.split(',');
      let allQMAUsers = [];
      let externalUseList = [];
      let addedMembersSymphonyIds = ml.members.map(ml=>ml.user).map(u=>u.userId);
      let symphonyBot = this.userDataService.getSymphonyBot();
      ml.members.forEach(member => {
        let data = this.loggedInUserObj.userSymphonyIdToUserMap[member.user.userId];
        if (data) {
          allQMAUsers.push(data.soeId);
        } else {
          // Exclude symphony bot to external user list.
          if (member.user.email && (member.user.email !== symphonyBot)) {
            externalUseList.push(member.user.email);
          }
        }
        console.log(data);
      });
      const symphonyDetails = {
        'symphonyStreamId': serviceRes.payload.roomId,
        'chatroomName': this.inputChatRoomName,
       /*  'memberList': this.toUserSoeIds, */
        'groupList': this.grpListArray,
        'allQmaUser':allQMAUsers,
        'externalUserList':externalUseList
      };
      this.symphonyChatServiceService.createSymphonyChatRoomInQMA(symphonyDetails).subscribe(
        res=>{
        console.log(res);
      },
      (error)=>{
        console.log(error);
      });
    this.showResponseMessage(ml);
    });
    /* this.symphonyChatServiceService.saveSymphonyDetails(symphonyDetails).subscribe((response: any) => {
      console.log(response);
    }); */
    

  }
  onKeyUp(event: KeyboardEvent, field) {
    let extEmail: boolean = false;
    // this.closeFlagInvalid = false;
    if (event.key === "Enter") {
      let tokenInput = event.srcElement as any;
      let email = tokenInput.value.trim();
      // C153176-4930: refactoring the processing of email input to separate method 'handleEmail'
      extEmail = this.isExternalEmailInToCc(email);
      this.handleEmail(tokenInput, email, field);
    }
    //if (extEmail)
    // this.secure.emit(true);
    // else
    // this.secure.emit(false);
  }
  
  isExternalEmailInToCc(email): boolean {
    let isExternal: boolean = false;
    if (!AppUtils.isUndefinedOrNull(email)) {

      // C153176-5006 : If User enter soeid who has access to QMA application
      let emailList = email.split(';');
      emailList.forEach(emailValue => {
        emailValue = emailValue.trim();
        const appUser = this.allToCcDBUser.find(user => user.id === emailValue);
        if (emailValue && !AppUtils.isCitiDomainEmail(emailValue, this.citiDomains) && !appUser) {
          isExternal = true;
        }
      });
    }
    return isExternal;
  }
  handleEmail(tokenInput, Temail, field) {
    this.filteredToUsersMultiple = [];
    Temail = Temail.split(';');
    let invalid = [], email = [];
    let isNonQmaCitiUser: boolean = false;
    email = Temail.filter(value => {
      value = value.trim();

      //test and extract email from <foo@bar.com>
      if (/\<([^\>]+)\>/g.test(value)) {
        let tempArr = /\<([^\>]+)\>/g.exec(value);
        value = tempArr[1];
      }

      // C153176-5006 : If User enter soeid who has access to QMA application
      const appUser = this.allToCcDBUser.find(user => user.id === value);
      if (appUser) {
        return true;
      }

      //check for non QMA citi user
      /*  isNonQmaCitiUser = this.validateNonQmaCitiUserSoeId(value);
       if (isNonQmaCitiUser) {
         return true;
       } */
      // Push invalid email or soied to display invalid message.
      if (value !== "" && !this.email_regex.test(value)) { // C153176-5262: refactor regex
        invalid.push(value);
        return false;
      } else {
        return true;
      }
    });
    /* if (invalid.length > 0) {
      tokenInput.value = "";
      this.closeFlagInvalid = true;
      setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
    } */

    if (email.length) {
      email.forEach(async element => {
        element = element.trim();
        if (/\<([^\>]+)\>/g.test(element)) {
          let tempArr = /\<([^\>]+)\>/g.exec(element);
          element = tempArr[1];
        }
        if (element !== "") {
          let item = new AllToCcDBUser();

          // C153176-5006 : If User enter soeid who has access to QMA application
          const appUser = this.allToCcDBUser.find(user => user.id === element);
          if (appUser) {
            item = { ...appUser };
          }
          /* else if(isNonQmaCitiUser) { */
          // check for non QMA and Citi user
          // const nonQmaCitiUser = await this.nonQmaCitiUser(element, field);
          /* if(nonQmaCitiUser){
            item = { ...nonQmaCitiUser };
          } else 
          {
            tokenInput.value = "";
            // this.closeFlagInvalid = true;
            setTimeout(() => { 
              // this.closeFlagInvalid = false; 
            }, 3000);
            return;
          }
        }*/
         else {
            item = { text: element, value: element, email: element, id: '', active: false };
          } 
          if(!this.toUsersList){
            this.toUsersList = [];
          }
          switch (field) {
            case "to":
              if (!this.isDuplicate(item, 'to'))
                this.toUsersList.push(item);
              // this.parentForm.get('toUsers').patchValue(this.toUsersList);
              tokenInput.value = "";
              break;
           /*  case "cc":
              if (!this.isDuplicate(item, 'cc'))
                this.ccUsersList.push(item);
              // this.parentForm.get('ccUsers').patchValue(this.ccUsersList);
              tokenInput.value = "";
              break;
            case "bcc":
              if (!this.isDuplicate(item, 'bcc'))
                this.bccUsersList.push(item);
              // this.parentForm.get('bccUsers').patchValue(this.bccUsersList);
              tokenInput.value = "";
              break; */

          }
        }
      });

    }
  }

  isDuplicate(item, toCcBcc) {
    let found = false;
    switch (toCcBcc) {
      case 'to':
        this.toUsersList?.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
      /* case 'cc':
        this.ccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break; */
      /* case 'bcc':
        this.bccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break; */
    }
    return found;
  }
  // display contact info on hover to contact
  displayContactInfo(event, value: any) {
    if (value && value.id) {
      this.selectedContactId = value.id.toUpperCase();
    }
  }
  getUserData() {
    let groupNameTmp = [];
    groupNameTmp.push({ label: 'Select', value: '' });
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      //Get All groups
      loginUserInfo.allActiveGroups.forEach(activeGroup => {
        this.allActiveGroups.push(activeGroup);
      });
      // get boat name
      if (loginUserInfo && loginUserInfo.symphonyConfig && loginUserInfo.symphonyConfig.symphonyBot) {
        if(loginUserInfo.symphonyConfig.symphonyBot.split('.').length && 
        loginUserInfo.symphonyConfig.symphonyBot.split('.').length > 0) {
          this.botName = loginUserInfo.symphonyConfig.symphonyBot.split('.')[0];
        }
      }
     
    });

  }

  addExternalMembers(data) {
    if(data && data.payload) {
      let streamId = data.payload.roomId;
      let extUserSymphonyIdRes = [];
      if(this.enteredExternalUsers && this.enteredExternalUsers.length > 0) {
        this.enteredExternalUsers.forEach(eu => {
          let req = {memberSoeId:eu.email,memberType:'CorpEmail'} // Type=SymphonyId
          extUserSymphonyIdRes.push(this.symphonyChatServiceService.getUserSymphonyIdByType(req));
         
        });
        forkJoin(...[extUserSymphonyIdRes]).subscribe((res: any)=>{
         let addMemberExt =[];
          if (res && res.length>0) {
            let extUsersympIdsArray = res.filter(res=>res.status).map(res=>res.payload).map(res=>res.symphonyId)
            this.externalSymphonyIds = extUsersympIdsArray;
          }
          if(this.externalSymphonyIds.length > 0) {
            this.externalSymphonyIds.forEach(externalid=>{
              let reqParam = {};
              reqParam['memberSymphonyId'] = externalid;
              reqParam['streamId'] = streamId;
              addMemberExt.push(this.symphonyChatServiceService.addMember(reqParam));
            });
          }
          if(addMemberExt.length > 0) {

          forkJoin(...addMemberExt).subscribe((res: any) => {
                this.saveSymphonyDetails(data);
                
              });
          } else { // if external ids entered but all are invalid
                this.saveSymphonyDetails(data);
                
          }
      
      });
    }

    else {// if no external ids
        this.saveSymphonyDetails(data);
       
      } 
    
    } else { // if no response from symphony api its failure
      this.showResponseMessage(data);
    }
   
  }

  resetCreateInqForm() {
    if (this.createnewChatRoomForm) {
      this.createnewChatRoomForm.resetForm();
    }
  }
}
