import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { Observable } from 'rxjs';
import * as Rx from "rxjs";
const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SymphonyChatServiceService {
  symponyEntitlement$ = new Rx.BehaviorSubject({});
  symponyChatToInq$ = new Rx.BehaviorSubject({});
  // when members are added or removed update via ws
  symponyChatUpdatedMembers$ = new Rx.BehaviorSubject({});
  constructor(private http: HttpClient) { }

  checkIfSymphonyAPIUiCallDisabled(loggedInUserObj) {
    if (loggedInUserObj && loggedInUserObj.symphonyConfig
      && loggedInUserObj.symphonyConfig.symphonyApiUiCallEnable === false) {
      return true;
    }
  }

  createSymphonyChatRoom(loggedInUserObj: any, soeId: string, reqParam: Object) {
    if (this.checkIfSymphonyAPIUiCallDisabled(loggedInUserObj)) {
      return this.createSymphonyChatRoomFromController(soeId, reqParam);
    }
    let requestObj = {
      "copyProtected": false,
      "crossPod": false,
      "description": "string",
      "discoverable": false,
      "keywords": [
        {
          "key": "string",
          "value": "string"
        }
      ],
      "membersCanInvite": false,
      "multiLateralRoom": false,
      "name": "QMATest from UI",
      "public": false,
      "readOnly": false,
      // "subType": "EMAIL",
      "viewHistory": false
    };

    requestObj['name'] = reqParam['name'];

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'bot_name': loggedInUserObj.symphonyConfig.symphonyBot
    });
    let httpParams = new HttpParams();
    httpParams = httpParams.append('createUser', soeId);
    httpParams = httpParams.append('createUserType', 'SSO');
    httpParams = httpParams.append('roomMembers', reqParam['roomMembers']);
    httpParams = httpParams.append('memberType', 'SSO');
    // logging added
    this.logApiUrlAndReqObj('createSymphonyChatRoom', QmaConstant.SYMPHONY_BASE_URL + '/v3/obo/room/create', requestObj);

    return this.http.post(QmaConstant.SYMPHONY_BASE_URL + '/v3/obo/room/create',
      requestObj, { headers: headers, params: httpParams });
    /* return this.http.post('http://localhost:3010/cvsym' + '/v3/obo/room/create',
    requestObj,{headers:headers,params:httpParams}); */

  }

  createSymphonyChatRoomFromController(soeId: string, reqParam: Object) {

    let requestObj = {
      "copyProtected": false,
      "crossPod": false,
      "description": "string",
      "discoverable": false,
      "keywords": [
        {
          "key": "string",
          "value": "string"
        }
      ],
      "membersCanInvite": false,
      "multiLateralRoom": false,
      "name": "QMATest from UI",
      "public": false,
      "readOnly": false,
      // "subType": "EMAIL",
      "viewHistory": false
    };

    requestObj['name'] = reqParam['name'];
    requestObj['roomMembers'] = reqParam['roomMembers'];
    return this.http.post(QmaConstant.BASE_URL + '/symphony/createSymphonyChatRoom', requestObj, httpFormPostOptions);
  }
  loadUserSymphonyChatRoom(requestObj: Object) {
    requestObj = {};
    return this.http.get(QmaConstant.BASE_URL + '/symphony/loadUserSymphonyChatRoom', httpFormPostOptions);
  }

  getUserSymphonyId(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/getUserSymphonyId', requestObj, httpFormPostOptions);
  }

  addMember(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/addMember', requestObj, httpFormPostOptions);
  }

  removeMember(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/removeMember', requestObj, httpFormPostOptions);
  }
  checkSymphonyEntitlement() {
    return this.http.get(QmaConstant.BASE_URL + '/symphony/checkSymphonyEntitlement');
  }
  /*  checkSymphonyEntitlement() {
     const headerDict = {
       'Accept':'application/json',
       'bot_name': 'QMASymphony.bot@citi.com',
       'Content-Type': 'application/json; charset=utf-8'
     };
     let inputParams = new HttpParams().set("id","XXXXX").set("idType", "SSO"); //Create new HttpParams
 
     return this.http.get('/symphony/v2/user/check',{headers:headerDict,params:inputParams});
   } */
  // to sahre symphony status across app
  getSymphonyEntitlement() {
    return this.symponyEntitlement$.asObservable();
  }
  setSymphonyEntitlement(entitlement) {
    return this.symponyEntitlement$.next(entitlement);
  }
  getChatConversations(requestObj: any, loggedInUserObj) {
    if (this.checkIfSymphonyAPIUiCallDisabled(loggedInUserObj)) {
      return this.getChatConversationsFromController(requestObj);
    }
    const headerDict = {
      'Accept': 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    }
    let httpParams = new HttpParams();
    const streamId = `streamId:${requestObj.streamId}`;
    httpParams = httpParams.append('query', streamId);
    httpParams = httpParams.append('fromUser', requestObj.userEmail);
    httpParams = httpParams.append('limit', '500');
    httpParams = httpParams.append('skip', '0');
    // logging added
    this.logApiUrlAndReqObj('getChatConversations', QmaConstant.SYMPHONY_BASE_URL + '/v1/obo/message/search', {});
    return this.http.get(QmaConstant.SYMPHONY_BASE_URL + '/v1/obo/message/search', { headers: headerDict, params: httpParams });

  }
  getChatConversationsFromController(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/getSymphonyConversation', requestObj, httpFormPostOptions);
  }
  saveSymphonyDetails(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/saveSymphonyDetails', requestObj, httpFormPostOptions);
  }
  getSymphonyDetails(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/getSymphonyDetails', requestObj, httpFormPostOptions);
  }
  getUserSymphonyIdByType(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/getUserSymphonyIdByType', requestObj, httpFormPostOptions);
  }
  saveUserSymphonyId(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/saveUserSymphonyId', requestObj, httpFormPostOptions);
  }
  /*  postMessageToChatRoom(requestObj: Object){
     return this.http.post(QmaConstant.BASE_URL + '/symphony/postMessageToChatRoom', requestObj,httpFormPostOptions);
   } */
  postMessageToChatRoom(requestObj: any, streamId: string, fromUser, loginUserInfoObj) {
    if (this.checkIfSymphonyAPIUiCallDisabled(loginUserInfoObj)) {
      requestObj.streamId = streamId;
      return this.postMessageToChatRoomFromController(requestObj);
    }
    /* https://xxxx  */
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    let httpParams = new HttpParams().set('fromUser', fromUser).set('fromUserType', 'SSO');
    // logging added
    this.logApiUrlAndReqObj('postMessageToChatRoom', QmaConstant.SYMPHONY_BASE_URL + '/v3/obo/conversation/' + streamId + '/message', requestObj);

    return this.http.post(QmaConstant.SYMPHONY_BASE_URL + '/v3/obo/conversation/' + streamId + '/message',
      requestObj, { headers: headers, params: httpParams });
    // local debug
    /*   return this.http.post('http://localhost:3010/cvsym' + '/v3/obo/conversation/'+ streamId + '/message',
      requestObj,{headers:headers,params:httpParams}); */
  }
  postMessageToChatRoomFromController(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/postMessageToChatRoom', requestObj, httpFormPostOptions);
  }
  getSymphonyMemberLists(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/getMemberLists', requestObj, httpFormPostOptions);
  }
  getSymphonyUserPresenceBySymphonyId(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/getUserPresence', requestObj, httpFormPostOptions);
  }
  createSymphonyChatRoomInQMA(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/createSymphonyChatRoomInQMA', requestObj, httpFormPostOptions);
  }
  addMemberToChatroom(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/addMemberToChatroom', requestObj, httpFormPostOptions);
  }
  removeMemberToChatroom(requestObj: Object) {
    return this.http.post(QmaConstant.BASE_URL + '/symphony/removeMemberToChatroom', requestObj, httpFormPostOptions);
  }
  // to open new message in new tab when creating inq from chat
  setDatasymponyChatToInq(symphonyDetails) {
    return this.symponyChatToInq$.next(symphonyDetails);
  }
  notifysymponyChatToInq() {
    return this.symponyChatToInq$.asObservable();
  }
  // when members are added or removed update via ws
  getwsSymphonyMembers() {
    return this.symponyChatUpdatedMembers$.asObservable();
  }
  setwsSymphonyMembers(symphonyChatMembers) {
    return this.symponyChatUpdatedMembers$.next(symphonyChatMembers);
  }
  getInquiryDetails(requestObj: any, inquiry: any = undefined): Observable<any> {

    return this.http.post(QmaConstant.BASE_URL + '/symphony/getInquiryDetails', requestObj, httpFormPostOptions);
  }
  logApiUrlAndReqObj(uiServiceMethodName, url, reqObj) {
    let stringnifiedObj = '';
    try {
      stringnifiedObj = JSON.stringify(reqObj);
      console.log(`Symphony-> UI service method name-> ${uiServiceMethodName} call to api req url -> ${url} req Obj ->${stringnifiedObj}`);
    } catch (e) {
      console.log(`Exception ocuured while stringnifying req Obj ${reqObj} in method ${uiServiceMethodName}`);
    }

  }
}
