<div>
  <div class="d-flex flex-row mrg-left-4em mrg-lef">
    <div class="w-20 d-flex flex-row align-items-start ">
      <label for="from" class="flex-grow meta-margin">Request type: </label>
      <p-multiSelect [defaultLabel]="'Select Request Type'" [maxSelectedLabels]="2"
      [styleClass]="'rtrc-multiselect'" [style]="{'width':'338px','margin-left':'50px'}"
      [options]="requestTypeList" [(ngModel)]="selectRequestType" (onChange)="onRequestTypeChange($event.value)">
      </p-multiSelect>
    </div>
  </div>
  <div class="p-2">
    <p-pickList [source]="availableRootCauses" [target]="selectedRootCauses" [showSourceControls]="false"
      [showTargetControls]="false" sourceHeader="{{srcHeader}}" targetHeader="{{targetHeader}}"
      [responsive]="true" dragdrop="true"  [sourceStyle]="{'height':'300px'}" [targetStyle]="{'height':'300px'}">
      <ng-template let-item pTemplate="item">
        <div class="ui-helper-clearfix">
          <span>{{item}}</span>
        </div>
      </ng-template>
    </p-pickList>
  </div>
  <div class="d-flex flex-row flex-wrap px-3 pb-2 pt-3 justify-content-end">
    <button type="submit" class="btn save-btn mr-4" (click)="onApply(currentRequestType)">Apply</button>
    <!-- <button class="btn inqclr-btn" (click)="onReset(currentRequestType)">Reset({{currentRequestType}})</button> -->
  </div>
  <div *ngIf="isApplied" class="d-flex justify-content-center">
    <span class="msg">Changes are applied for (<strong>{{currentRequestType}}</strong>)</span>
  </div>
</div>