import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SymphonySideBarComponent } from './symphony-side-bar/symphony-side-bar.component';
import { SymphonyCreateInquiryComponent } from 'src/app/symphony/symphony-create-Inquiry/symphony-create-Inquiry.component';
import {DialogModule} from 'primeng/dialog';
import {InputTextModule} from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DropdownModule} from 'primeng/dropdown';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {RadioButtonModule} from 'primeng/radiobutton';
import { NgScrollbarModule } from 'ngx-scrollbar';
/* import { SymphonyCreateChatComponent } from 'src/app/symphony/symphony-create-chat/symphony-create-chat.component' */
import { SymphonyGridViewComponent } from './symphony-grid-view/symphony-grid-view.component';
import { AngularSplitModule } from 'angular-split';
import { AgGridModule } from '@ag-grid-community/angular';
import { SymphonyMessageRecipentComponent } from './symphony-message-recipent/symphony-message-recipent.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ListboxModule } from 'primeng/listbox';
import { ToastModule } from 'primeng/toast';
import {TooltipModule} from 'primeng/tooltip';
import { SharedModule } from "src/app/common/shared-module";
import { SymphonyChatViewComponent } from 'src/app/symphony/symphony-chat-view/symphony-chat-view.component';
import { SymphonyChatViewComponentOld } from 'src/app/symphony/symphony-chat-view-old/symphony-chat-view.component-old';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
/* import {SymphonyEditMembersComponent} from './symphony-edit-members/symphony-edit-members.component';
 */
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
@NgModule({
  declarations: [SymphonySideBarComponent,
                 /* SymphonyCreateChatComponent, */
                 SymphonyGridViewComponent,
                 SymphonyCreateInquiryComponent, SymphonyMessageRecipentComponent,
                  SymphonyChatViewComponent,
                  SymphonyChatViewComponentOld
                 ],
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    AutoCompleteModule,
    RadioButtonModule,
    NgScrollbarModule,
    AngularSplitModule.forRoot(),
    AgGridModule.withComponents([SymphonyGridViewComponent]),
    OverlayPanelModule,
    ListboxModule,
    ToastModule,
    TooltipModule,
    SharedModule,
    ConfirmDialogModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
        backdropBackgroundColour: 'rgba(0,0,0,0.1)', 
        backdropBorderRadius: '4px',
        primaryColour: '#021e48', 
        secondaryColour: '#021e48', 
        tertiaryColour: '#021e48',
        fullScreenBackdrop:true
    }),
   

  ],
  exports: [SymphonySideBarComponent, 
    /* SymphonyCreateChatComponent, */
     SymphonyGridViewComponent, 
    SymphonyCreateInquiryComponent, SymphonyMessageRecipentComponent, 
    FormsModule, SymphonyChatViewComponent,SymphonyChatViewComponentOld
   
    ]
})
export class SymphonyModule { }
