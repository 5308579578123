import { Component, Input } from '@angular/core';

import * as $ from 'jquery';

@Component({
    selector: 'fading-dialog',
    templateUrl: './fading-dialog.component.html',
    styleUrls: ['./fading-dialog.component.scss']
  })
  export class FadingDialogComponent {

    @Input() chartId: string;

    show(context, interval = 1000) {
        let elem = $('.fading-modal', context);
        if (elem && elem.length) {
            elem.fadeIn(interval);
           /*  setTimeout(() => {
                elem.fadeOut(interval);
            }, 1500); */
        }
    }

    hide(context, interval =0){
        let elem = $('.fading-modal', context);
        if (elem && elem.length) {
            elem.fadeOut(interval);
        }
    }
  }
  