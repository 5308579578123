<div class="gfid-body profile-Setting">
   <!-- Loader when hit the save/submit button -->
    <ng-template #customLoadingTemplate>
      <div class="custom-class-loader">
        <div class="spinner-container">
            <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                <circle cx="170" cy="170" r="160" stroke="#021e48" />
                <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                <circle cx="170" cy="170" r="110" stroke="#021e48" />
                <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
            </svg>
        </div>
    </div>
    </ng-template>        
    <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate"></ngx-loading>
  <form [formGroup]="profileSettingForm">
    <div class="d-flex flex-row">
      <div class="left-panel">
        <div class="profile-icon d-flex align-items-center justify-content-between mt-10">
          <img class="profile-icon-img" [src]="profilePic" *ngIf="profilePic" alt="">
          <div class="display-individual" *ngIf="!profilePic">{{loggedInUser.userId.substring(0,2)}}</div>
        </div>
        <ul class="profile-icon-img-details">
          <li>{{userName}}</li>
          <li>{{email}}</li>
        </ul>
      </div>
<!-- Responsive Design-->
      <p-tabView class="adminview" #profileTabs (onChange)="onTabChange($event)" [styleClass]="'admin-tabview'" [scrollable]="false"
        [style]="{'height':'750px','width':'100%','max-width':'1150px','margin-left': '16px',overflow: 'hidden', 'position': 'relative'}"
        [activeIndex]="actTabIdx">
        <p-tabPanel header="PREFERENCES" class="tab-heading">
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice">Default from group</label>
            </span>
              <span>
              <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" optionLabel="name" formControlName="defaultGroup" [options]="defaultGroupList">
              </p-dropdown>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-4 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice">Conversation view</label>
            </span>
              <span>
              <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" optionLabel="name" formControlName="conversationView" [options]="conversationViewList">
              </p-dropdown>
            </span>
            </div>
          </div>

          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice">Date formatter</label>
            </span>
              <span>
              <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" optionLabel="name" formControlName="dateFormat" [options]="dateFormatList">
              </p-dropdown>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice">Default Editor Font Family</label>
            </span>
              <span>
              <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" optionLabel="name" formControlName="defaulttinyMCEFontFamily" [options]="tinyMCEFontFamilyList">
              </p-dropdown>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-4 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice">Default Editor Font Size</label>
            </span>
              <span>
              <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" optionLabel="name" formControlName="defaulttinyMCEFontSize" [options]="tinyMCEFontSizeList">
              </p-dropdown>
            </span>
            </div>
          </div>
          <!--
          <div class="d-flex flex-row mt-4 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice">Row limit</label>
            </span>
              <span class="w-270">
              <input type="text" formControlName="rowCount" placeholder="Enter Row Count" class="w-92 t-box inp-txt-mng group-details-input-mng"/>
            </span>
              <span>
                <label class="flex-grow outOfOffice">( 1 - 20000 )</label>
            </span>
            </div>
            <div *ngIf="psf.rowCount.errors" class="invalid-feedback">
              <span *ngIf="psf.rowCount.errors.required || psf.rowCount.errors.min">Please enter valid row count!</span>
              <span *ngIf="psf.rowCount.errors.max">The maximum limit should be below 20000</span>
            </div>
          </div> -->
          <div class="d-flex flex-row mt-4 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice pt5">Secure External mails</label>
            </span>
              <span class="radio-preference">
              <p-inputSwitch formControlName="sendSecureEmail" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice pt5">Disable Auto Complete</label>
            </span>
              <span class="radio-preference">
              <p-inputSwitch formControlName="disableAutoComplete" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice pt5">Prompt for Attachment Password</label>
            </span>
              <span class="radio-preference">
              <p-inputSwitch formControlName="attachmentPwdProtectionFlag" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
                <label for="from" class="flex-grow outOfOffice pt5">Disclaimer for New Inquiry</label>
              </span>
              <span class="radio-preference">
                <p-inputSwitch formControlName="disclaimerInq" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
              </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
                  <label for="from" class="flex-grow outOfOffice pt5">Disclaimer for Reply/ReplyAll/Forward</label>
                </span>
              <span class="radio-preference">
                  <p-inputSwitch formControlName="disclaimerRply" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef" *ngIf="isSymphonyEnabled">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
                  <label for="from" class="flex-grow outOfOffice pt5">Enable Symphony</label>
                </span>
              <span class="radio-preference">
                  <p-inputSwitch formControlName="enableSymphony" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef" *ngIf="isTaskizeEnabled">
            <div class="w-75 d-flex flex-row align-items-start ">
              <span class="preference">
                  <label for="from" class="flex-grow outOfOffice pt5">Enable Taskize</label>
                </span>
              <span class="radio-preference">
                  <p-inputSwitch formControlName="enableTaskize" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                </span>
            </div>
          </div>

       
        </p-tabPanel>

        <p-tabPanel header="NOTIFICATIONS" class="tab-heading">
          <div class="d-flex flex-row mt-4 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice pt5">Escalation</label>
            </span>
              <span class="radio-notification">
              <p-inputSwitch formControlName="esclation" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-4 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice pt5">Approve/Reject</label>
            </span>
              <span class="radio-notification">
              <p-inputSwitch formControlName="approval" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-4 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice pt5">Snooze</label>
            </span>
              <span class="radio-notification">
              <p-inputSwitch formControlName="snooze" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-4 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice pt5">FollowUp</label>
            </span>
              <span class="radio-notification">
              <p-inputSwitch formControlName="followUp" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </span>
            </div>
          </div>
          

          <div class="d-flex flex-row mt-4 mrg-lef" *ngIf="isSymphonyEnabled && 
          userDataService.loggedInUserInfo.isSymphonyEnabledForUser == 'Y'">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice pt5">Symphony</label>
            </span>
              <span class="radio-notification">
              <p-inputSwitch formControlName="symphony" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-4 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
              <label for="from" class="flex-grow outOfOffice pt5">AutoAssign</label>
            </span>
              <span class="radio-notification">
              <p-inputSwitch formControlName="autoAssign" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
            </span>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="SIGNATURES">
          <div class="d-flex flex-row mt-2 profile-signature">
            <div class="firstpart">
              <form [formGroup]="signatureForm">
                <div *ngIf="saveSignMsg" class="warning-msg">{{saveSignMsg}}</div>
                <div class="d-flex flex-row mt-4 mrg-lef mb">
                  <div class="w-75 d-flex flex-row align-items-start ">
                    <span>
                  <label for="from" class="flex-grow outOfOffice">Signature Name</label>
                </span>
                    <span class="pl5" *ngIf="showIconsCheckBox">
                  <p-dropdown [styleClass]="'prime-dd-override signature-color'" class="drop-down w-25 group-details-input" optionLabel="name" formControlName="defaultSignature"
                    (onChange)="onSignatureChange()" [options]="signatures">
                  </p-dropdown>
                </span>
                    <span class="pl5" *ngIf="showIcons">
                  <input type="text" formControlName="newSignature" placeholder="Enter signature" class="w-92 t-box inp-txt-mng group-details-input-mng"/>
                </span>
                  </div>
                  <div *ngIf="sf.newSignature.errors && saveSign" class="invalid-feedback">
                    <span *ngIf="sf.newSignature.errors.required">Please enter a valid and unique signature name</span>
                    <span *ngIf="sf.newSignature.errors.duplicate">The signature name is already used. Please enter a different name!</span>
                  </div>
                </div>
                <div class="d-flex flex-row mt-2 outOfOffice">
                  <editor-app [elementId]="'signature'" [toolbarOptions]="2" [height]="300"></editor-app>
                </div>
              </form>
              <div *ngIf="showIconsCheckBox" class="d-flex flex-row mt-4 mrg-lef">
                <span>
                <label for="from" class="flex-grow outOfOffice pt5 w15">Include in new inquiry</label>
              </span>
                <span class="pl5">
                <p-inputSwitch formControlName="inInquiry" (onChange)="onChangeInquiry($event)" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
              </span>
              </div>
              <div *ngIf="showIconsCheckBox" class="d-flex flex-row mt-2 mrg-lef">
                <span>
                <label for="from" class="flex-grow outOfOffice pt5 w16">Reply/Reply All/Forward</label>
              </span>
                <span class="pl4">
                <p-inputSwitch formControlName="inReply" (onChange)="onChangeReplyOrFwd($event)" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
              </span>
              </div>
              <div *ngIf="showSignatureMsg" class="warning-msg">
                <span>{{signatureMsg}}</span>
              </div>
            </div>
            <div class="mt-2 mrg-lef secondpart">
              <div class="pt2 pb3" (click)="onCreateOrUpdate('create')">
                <span>
                <img class="qma-text right-side-signature-icon" src="assets/context/add-button.svg" alt="Create">
              </span>
                <span class="right-side-signature">Create new</span>
              </div>
              <div class="pb3" *ngIf="showIconsCheckBox" (click)="onCreateOrUpdate('modify')">
                <span>
                <img   class="qma-text right-side-signature-icon" src="assets/context/edit-button.svg" alt="Modify">
              </span>
                <span class="right-side-signature">Modify</span>
              </div>
              <div class="pb3" *ngIf="showIconsCheckBox" (click)="onDeleteSignature()">
                <span>
                <img  class="qma-text right-side-signature-icon" src="assets/context/delete-button.svg" alt="Delete">
              </span>
                <span class="right-side-signature">Delete</span>
              </div>
              <div class="pb3" *ngIf="showIcons" (click)="onSaveSignature()">
                <span>
                <img class="qma-text right-side-signature-icon" src="assets/newMessage/save.svg" alt="Save">
              </span>
                <span class="right-side-signature">Submit</span>
              </div>
              <div class="pb3" *ngIf="showIcons" (click)="resetSignature()">
                <span>
                <img class="qma-text right-side-signature-icon" src="assets/Inbox/close_icon_u73.svg" alt="Cancel">
              </span>
                <span class="right-side-signature">Cancel</span>
              </div>
            </div>

          </div>
        </p-tabPanel>
        <p-tabPanel header="TEMPLATES">
          <div class="profile-template">
            <form [formGroup]="templateForm">
              <div *ngIf="saveTmptMsg" class="warning-msg">{{saveTmptMsg}}</div>
              <div *ngIf="tf.newTemplate.errors && saveTemplate" class="invalid-feedback">
                <span *ngIf="tf.newTemplate.errors.required">Please enter valid details for the name & content!</span>                
                <span *ngIf="tf.newTemplate.errors.maxlength">The template name should not contain more than 50 characters!</span>
                <span *ngIf="tf.newTemplate.errors.duplicate">The template name is already used. Please enter a different name!</span>
              </div>
              <div *ngIf="reqTmpltConent && saveTemplate" class="invalid-feedback">
                <span>Please enter valid details for the name & content!</span>
              </div>
              <div class="firstpart">
                <div class="w-75 d-flex flex-row align-items-start mb3 ">
                  <span>
                <label for="from" class="flex-grow outOfOffice">Template</label>
              </span>
                  <span class="pl5" *ngIf="showTempIcons">
                <input type="text" formControlName="newTemplate" placeholder="Enter template" class="t-box inp-txt-mng group-details-input-mng profile-template-input"/>
              </span>
                  <span class="pl5" *ngIf="showTempIconsCheckBox">
                <p-dropdown [styleClass]="'prime-dd-override template-color'" class="drop-down w-25 " optionLabel="name" formControlName="defaultTemplate"
                  (onChange)="onTemplateChange()" [options]="userTemplateList">
                </p-dropdown>
              </span>
                </div>
              </div>

              <div class="mt-2 mrg-lef secondpart">
                <div class="pb3" (click)="onCreateOrUpdateTemplate('create')">
                  <span>
                <img class="qma-text right-side-signature-icon" src="assets/context/add-button.svg" alt="Create">
              </span>
                  <span class="right-side-signature">Create new</span>
                </div>
                <div class="pb3" *ngIf="showTempIconsCheckBox" (click)="onCreateOrUpdateTemplate('modify')">
                  <span>
                <img class="qma-text right-side-signature-icon" src="assets/context/edit-button.svg" alt="Modify">
              </span>
                  <span class="right-side-signature">Modify</span>
                </div>
                <div class="pb3" *ngIf="showTempIconsCheckBox" (click)="onDeleteTemplate()">
                  <span>
                <img class="qma-text right-side-signature-icon" src="assets/context/delete-button.svg" alt="Delete">
              </span>
                  <span class="right-side-signature">Delete</span>
                </div>
                <div class="pb3" *ngIf="showTempIcons" (click)="onSaveTemplate()">
                  <span>
                <img class="qma-text right-side-signature-icon" src="assets/newMessage/save.svg" alt="Save">
              </span>
                  <span class="right-side-signature">Submit</span>
                </div>
                <div class="pb3" *ngIf="showTempIcons" (click)="resetTemplate()">
                  <span>
                <img class="qma-text right-side-signature-icon" src="assets/Inbox/close_icon_u73.svg" alt="Cancel">
              </span>
                  <span class="right-side-signature">Cancel</span>
                </div>
              </div>


              <div class="thirdpart">
                <div class="d-flex flex-row align-items-start  control-group">
                  <label for="to" class="min-width pr5 bg-color">
                    <span>To:</span>
                  </label>

                  <p-autoComplete formControlName="toList" 
                  [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)"
                   [minLength]="1"
                    field="text" [multiple]="true" styleClass="override" class="w-100 bn inp-txt-mng group-details-input-mng"
                    [(ngModel)]="toUsersList" (onKeyUp)="onKeyUp($event, 'to')" (onBlur)="onBlur($event, 'to')"  
                   >
                    <ng-template let-value pTemplate="selectedItem">
                      <span [ngClass]="contactTextStyle(value.value)" class="p-autocomplete-token-label new-message-token">{{value.text}}</span>
                    </ng-template>
                  </p-autoComplete>
                </div>
                <!-- merge recipient checkbox -->
                <div class="d-flex flex-row align-items-center" style="margin-left: 5%;">
                  <span class="pl3" style="width: 27%">
                    <!--                         <p-inputSwitch formControlName="mergeRecipients" (onChange)="onChangeMergerecipients($event)" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                 -->
                    <input formControlName="mergeRecipients" type="checkbox" class="" 
                       style="margin-right: 1%">
                    <label for="from" class="flex-grow outOfOffice pt5">Merge recipients in reply</label>
                
                  </span>
                  <div class="">
                    <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" 
                    (mouseenter)="onShowInfo($event, meregerecipientsTooltip)" (mouseleave)="onShowInfo($event, meregerecipientsTooltip)"
                    alt="Info">
                    <p-overlayPanel [styleClass]="'icon-info-meregerecipientsTooltip'" [appendTo]="'body'"
                     #meregerecipientsTooltip [baseZIndex]="9999">
                      <div class="d-flex flex-row mb-0.3">
                        <span class="info-content">Uncheck to <span style="color: #0033cc;
                          font-style: italic;
                          font-family: 'Interstate';">replace</span> recipients in reply</span>
                      </div>
                    </p-overlayPanel>
                  </div>
                
                </div>
                
                <div class="d-flex flex-row align-items-start control-group">
                  <label for="cc" class="min-width pr5 bg-color">
                    <span>Cc:</span>
                  </label>
                    
                  <p-autoComplete formControlName="ccList" [suggestions]="filteredToUsersMultiple" (completeMethod)="filterUsers($event)" [minLength]="1"
                    field="text" [multiple]="true" styleClass="override" class="w-100 bn  inp-txt-mng group-details-input-mng"
                    (onKeyUp)="onKeyUp($event, 'cc')" (onBlur)="onBlur($event, 'cc')" [(ngModel)]="ccUsersList">
                    <ng-template let-value pTemplate="selectedItem" >
                      <span [ngClass]="contactTextStyle(value.value)" class="p-autocomplete-token-label new-message-token">{{value.text}}</span>
                    </ng-template>
                  </p-autoComplete>
                </div>
                <div class="d-flex flex-row align-items-start control-group">
                  <input type="text" formControlName="subject" placeholder="Subject" class="w-100 override t-box inp-txt-mng group-details-input-mng"
                  />
                </div>
                <div class="d-flex flex-row mt-2">
                  <label for="from" class="flex-grow outOfOffice">Share to group</label>
                </div>
                <div class="d-flex flex-row mt-1 brd">

                  <p-autoComplete formControlName="shareToGrpList" [suggestions]="filteredGroups" (completeMethod)="filterGroups($event)" [minLength]="1"
                    field="text" [multiple]="true" [styleClass]="'ownership-dropdown contact-pac'" placeholder="Start typing..."
                    class="w-100" [dropdown]="true">
                    <ng-template let-grp pTemplate="item">
                      <div class="ui-helper-clearfix">
                        <div class="search-text">{{grp.text}}</div>
                      </div>
                    </ng-template>
                  </p-autoComplete>
                </div>
                <div class="d-flex flex-row mt-2 outOfOffice">
                  <editor-app [elementId]="'template'" [height]="300"></editor-app>
                </div>
                
              </div>
            </form>

            <div class="w-75 d-flex flex-row mt-4 fourthpart" *ngIf="!showTempIcons">
              <div class="d-flex flex-row">
                <span> 
                  <label for="from" class="flex-grow outOfOffice pt5">Save as quick reply template</label> 
                </span>
                <span class="pl3">
                  <p-inputSwitch formControlName="quickReply" (onChange)="onChangeQuickReply($event)" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                </span>
              </div>
              
              <div class="ml-auto mt-2">
                <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (click)="onShowInfo($event, quickRplyInfo)" alt="Info">
                <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #quickRplyInfo [baseZIndex]="9999">
                  <div class="d-flex flex-row mb-0.3">
                    <span> <img class="default-color icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info"></span>
                    <span class="info-content">{{quickReplyMsg}}</span>
                    <span> <img [src]="'assets/profile/quickReplyOptions.jpg'" alt="Reply"></span>
                  </div>
                </p-overlayPanel>
              </div>
            </div>
            
            <div *ngIf="showTemplateMsg" class="warning-msg">
              <span>{{templateMsg}}</span>
            </div>
          </div>
        </p-tabPanel>
        <p-tabPanel header="ENTITLEMENTS">
          <div class="client_category d-flex flex-row mt-4">
            <ag-grid-angular id="myEntitlement" style="height: 311px" class="ag-theme-balham headerPart agGridEntitlement" [suppressHorizontalScroll] ="true" [rowHeight] ="43" [headerHeight] ="44" 
            [columnDefs]="entitlementColumnDefs" [rowData]="groupRoles" [modules]="modules">
            </ag-grid-angular>
          </div>
        </p-tabPanel>
        <p-tabPanel header="OUT OF OFFICE">
          <div class="profile-out-of-office">
            <div class="w-75 d-flex flex-row mt-4">
              <span class="ooo">
                  <label for="from" class="flex-grow outOfOffice">Out Of Office</label>
                </span>
              <span class="pl2">
                  <p-inputSwitch formControlName="outOfOffice" (onChange)="onOutOfOffice($event)" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
                </span>
            </div>
            <div class="w-20 d-flex flex-row mt-4 ">
              <div class="d-flex flex-row flex-nowrap align-items-end w-50 pr-2">
                <label class="outOfOffice">From date:</label>
                <div class="w-50 pl-2 mb10">
                  <p-calendar dateFormat="mm/dd/yy" formControlName="startDt" [showOtherMonths]="true" [hideOnDateTimeSelect]="true" [selectOtherMonths]="false"
                    [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2010:2030'" [styleClass]="'advance-calender schedule-cal tab-calender-fix'"
                    [readonlyInput]="false" [showIcon]="true" [minDate]="minimumDate" [disabledDays]="[0,6]" [disabled]="isOOO">
                  </p-calendar>
                </div>
              </div>
              <div *ngIf="psf.startDt.errors && isSubmitted" class="invalid-feedback">
                  <span *ngIf="psf.startDt.errors.required">From Date is required.</span>
                  <span *ngIf="psf.startDt.errors.prevDate">From date can't be less than today.</span>
                  <span *ngIf="psf.startDt.errors.nextDate">From date can't be greater than To date.</span>
                </div>

              <div class="d-flex flex-row flex-nowrap align-items-end w-50 pr-2">
                <label class="outOfOffice">To date:</label>
                <div class="w-50 pl-2 mb10">
                  <p-calendar dateFormat="mm/dd/yy" formControlName="endDt" [showOtherMonths]="true" [hideOnDateTimeSelect]="true" [selectOtherMonths]="false"
                    [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2010:2030'" [styleClass]="'advance-calender schedule-cal tab-calender-fix'"
                    [readonlyInput]="false" [showIcon]="true" [minDate]="minimumDate" [disabledDays]="[0,6]" [disabled]="isOOO">
                  </p-calendar>
                </div>
              </div>
              <div *ngIf="psf.endDt.errors && isSubmitted" class="invalid-feedback">
                  <span *ngIf="psf.endDt.errors.required">To Date is required.</span>
                  <span *ngIf="psf.endDt.errors.prevDate">To date can't be less than From date or today.</span>
                  <span *ngIf="psf.endDt.errors.nextDate"></span>
                </div>
            </div>

            <div class="d-flex flex-row mt-4">
              <label for="from" class="flex-grow outOfOffice">Out of Office message</label>
            </div>

            <div class="d-flex flex-row mt-2 editor">
              <editor-app [elementId]="'out-of-office'" [height]="300"></editor-app>
            </div>

            <div class="w-75 d-flex flex-row mt-4">
              <span class="ooo">
              <label for="from" class="flex-grow outOfOffice">Notify Manager</label>
            </span>
              <span class="pl2">
              <p-inputSwitch formControlName="notifyManager" [styleClass]="'advance-inputSwitch'" [disabled]="isOOO"></p-inputSwitch>
            </span>
            </div>

          </div>
        </p-tabPanel>

        <!-- Personal Information - Relates to Personal Calender and Personal Mailbox-->
        <p-tabPanel header="PERSONAL" class="tab-heading" *ngIf="isPersonalEnable">
          <span class="sub-title-info">This information is used for QMA Personal Beta Testing only.</span>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
                <label for="from" class="flex-grow outOfOffice pt5">Enable Mail</label>
              </span>
              <span class="radio-notification">
                <p-inputSwitch formControlName="personalEmailSubscribed" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
              </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
                <label for="from" class="flex-grow outOfOffice pt5">Enable Calendar</label>
              </span>
              <span class="radio-notification">
                <p-inputSwitch formControlName="calendarSubscribed" [styleClass]="'advance-inputSwitch'"></p-inputSwitch>
              </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
              <div class="w-75 d-flex flex-row align-items-start ">
                <span class="preference">
                  <label for="from" class="flex-grow outOfOffice">Exchange Region</label>
                </span>
                <span>
                  <p-dropdown [styleClass]="'prime-dd-override'" class="drop-down w-25 group-details-input" optionLabel="name"
                    formControlName="exchDomain" [options]="exchangeRegionList">
                  </p-dropdown>
                </span>
              </div>
            </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
                <label for="from" class="flex-grow outOfOffice pt5">Exchange Account Host</label>
              </span>
              <span class="personal-input-span">
                <input type="text" formControlName="exchUrl" placeholder="<Exchange host>/ews/exchange.asmx" class="t-box personal-input"/>
                <span class="sub-title">Exchange host is available on your outlook account page.</span><br/>
                <span class="sub-title">Example: <strong>XXXXX</strong></span>
              </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
                <label for="from" class="flex-grow outOfOffice pt5">Password</label>
              </span>
              <span class="personal-input-span">
                <input type="password" formControlName="exchPassword" placeholder="Enter Password..." class="t-box personal-input"/>
              </span>
            </div>
          </div>
          <div class="d-flex flex-row mt-3 mrg-lef">
            <div class="w-75 d-flex flex-row align-items-start">
              <span class="preference">
                <label for="from" class="flex-grow outOfOffice pt5">Retype Password</label>
              </span>
              <span class="personal-input-span">
                <input type="password" formControlName="exchPasswordIV" placeholder="Re-Enter Password..." class="t-box personal-input"/>
                <span class="sub-title">Type the password configured for your exchange account.</span>
              </span>
            </div>
            <div *ngIf="psf.exchPasswordIV.errors && isSubmitted" class="invalid-feedback">
                <span *ngIf="psf.exchPasswordIV.errors.mustMatch">Passwords must match.</span>
            </div>
          </div>
          <!-- Not Required as of now-->
          <!-- <div class="d-flex flex-row mt-3 add-btn">
            <div class="add-new-btn" (click)="addSubjectFlagwords()">
              <span class="btn-text">
                <img class="qma-text btn-img" [src]="'assets/context/add-button.svg'">
              </span>
              <span>Add New</span>
            </div>
            <span [hidden]="subDeleteFlag">
              <div class="add-new-btn btn-space" (click)="deleteSubjectFlagwords()">
                <span class="btn-text">
                  <img class="qma-text btn-img" [src]="'assets/context/delete-button.svg'">
                </span>
                <span>Delete</span>
              </div>
            </span>
          </div> -->
          <div class="d-flex flex-row mt-3">
              <ag-grid-angular id="subjectFlagwords" style="height: 180px" class="ag-theme-balham profile-setting-headerPart agGridSubjectFlagwords" 
              [columnDefs]="subjectWordColumnDefs" 
              [rowData]="subjectFlagwords"
              [suppressHorizontalScroll] ="true"
              [modules]="modules"
              [rowHeight] ="30" 
              [headerHeight] ="28" 
              (gridReady)="onGridReady($event)">
              </ag-grid-angular>
          </div>
        </p-tabPanel>

              <!-- [stopEditingWhenGridLosesFocus]="true" 
							[suppressRowClickSelection] = "true" 
              [defaultColDef]="subjectWordCheckbox"
              (selectionChanged)="onSubjectCheck()"
              (cellValueChanged)="onSubjectValueChanged($event)" -->

        <p-footer class="footer-profile">
          <div class="d-flex flex-row footer-btn-container"></div>
          <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn search-btn mr-4" (click)="onSaveUserProfile()">Submit</button>
            <button class="btn clear-btn" (click)="resetChanges()">Reset</button>
          </div>
        </p-footer>
      </p-tabView>
    </div>
  </form>
</div>
<!--Error pop up -->
<p-dialog [(visible)]="hasErrors" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
          [styleClass]="'contextMenuModal errorModal ModelClose'" [closable]="true" [dismissableMask]="true"
          [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}" (hide)="hasErrors=false">
          <p-header>
            <div class="d-flex flex-row align-items-center justify-content-between">
              <h6 class="success-title">Error Message</h6>
            </div>
          </p-header>
          <div class="success-msg">{{errorMessage}}</div>
</p-dialog>

<!--Confirmation popup window-->
<p-dialog [(visible)]="showPopUp" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal'"
  [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">

  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
      <h6 class="reage-title">CONFIRM ACTION</h6>
      <button type="button" (click)="onCancel(actionFieldName)" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="reage-body mx-2">
    <form>
      <div class="d-flex flex-row align-items-center input-holder mb-4">
        <div class="d-flex flex-row align-items-center mb-4 pb-4">
          <label>{{confirmMsgText}}</label>
        </div>
      </div>
    </form>
  </div>
  <p-footer>
    <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
      <button class="btn search-btn mr-4" (click)="onConfirm(actionFieldName)">Ok</button>
      <button type="reset" class="btn clear-btn" (click)="onCancel(actionFieldName)">Cancel</button>
    </div>
  </p-footer>
</p-dialog>