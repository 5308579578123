import { Injectable, ViewChild, EventEmitter, Output, Directive } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import * as Rx from "rxjs";
import { TabView } from 'primeng/tabview';
import * as $ from 'jquery';
import { TabsComponent } from '../core/tabs/tabs.component';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class TabDataService {
  @ViewChild('tabs') tabs: TabView;
  private subject = new Subject<any>();
  private subjectDraftOnTabClose = new Subject<any>();
  private clickedTab = new Subject<any>();
  // C153176-5107: subscription on unread count update
  private unReadCount = new Subject<any>();
  public savedTabData: string;
  public currentInquiry: any;
  clickedIcon: any;    
  tabName: any;
  clcData = new Map();
  private listenerMobile = new Subject<any>();
  //Begin This service is to send clicked icon info and tabName
  clickedIconSubject$ = new Rx.BehaviorSubject([]);
  @Output() actionSubject = new EventEmitter<any>();  
 tabComponentReady$ = new Subject();
  //C153176-4565: tracking base tab header row height
  baseTabHeaderRowHeight: number = 0;
  subjectOnTabClose$ = new Subject<string>();
  //C153176-3684 refactor mobile action footer - specify the footer at service level thus shared by multiple components
  //C153176-5202 : Order of the icons in mobile
  public footerMailboxArray:Array<any> = [
    {
      name: 'Inbox',
      label:'Inbox',
      image:'assets/mobile/inbox.svg',
      disableImage:'assets/mobile/inbox_disable.svg',
      isActiveTab:true,
      count:0
    }, 
    {
      name: 'Assigned To Me',
      label: 'Assigned To Me',
      image:'assets/mobile/assignedToMe.svg',
      disableImage:'assets/mobile/assignedToMe_disable.svg',
      isActiveTab:false,
      count:0
    },   
    {
      name: 'Pending Approval',
      label: 'Pending Approval',
      image:'assets/mobile/pending_approval.svg',
      disableImage:'assets/mobile/pending_approval_disable.svg',
      isActiveTab:false,
      count:0
    },
    {
      name: 'Escalation',
      label:'Potential Escalations',
      image:'assets/mobile/escalation.svg',
      disableImage:'assets/mobile/escalation_disable.svg',
      isActiveTab:false,
      count:0
    },   
    {
      name: 'Dashboard',
      label: 'Dashboard',
      image:'assets/mobile/dashboard.svg',
      disableImage:'assets/mobile/dashboard_disable.svg',
      isActiveTab:false,
      count:0
    },
    {
      name: 'New Message',
      label: 'New Message',
      image:'assets/mobile/new-message.svg',
      disableImage:'assets/mobile/new-message_disable.svg',
      isActiveTab:false,
      count:0
    }
  ];
 
  setClickedIcon(selectedConv: any): void {
    this.clickedIconSubject$.next(selectedConv);
  }

  getClickedIcon(): Observable<any> {
    return this.clickedIconSubject$.asObservable();
  }

  clearClickedIcon(): void {
    this.clickedIconSubject$.next([]);
  }

  triggerActionEvent(event) {
    this.actionSubject.emit(event);
  }

  getActionSubject(): EventEmitter<any> {
    return this.actionSubject;
  }

 //End This service is to send clicked icon info and tabName  
  sendTabData(tabData: string, count : number, triggeredFrom?:string,_symphonyData?:any) {
    this.savedTabData = tabData;
    this.subject.next({header : tabData, count : count, triggeredFrom:triggeredFrom, symphonyData:_symphonyData});
  }

  setInquiryData(inquiry) {
    this.currentInquiry = inquiry;
  }

  clearTabData() {
    this.subject.next();
  }

  getTabData(): Observable<any> {
    return this.subject.asObservable();
  } 

  getSaveDraftOnTabClose(): Observable<any> {
    return this.subjectDraftOnTabClose.asObservable();
  }

  saveDraftOnTabClose(draftObj: any) {
    this.subjectDraftOnTabClose.next(draftObj);
  }

  onTabChange(): Observable<any> {
    return this.clickedTab.asObservable();
  }

  setTabChange(tabName: any) {
    this.clickedTab.next(tabName);
  }
  // C153176-5107: subscribe on unread count
  onUnReadCount(): Observable<any> {
    return this.unReadCount.asObservable();
  }
  // C153176-5107: notify of unread count
  setUnReadCount(name: any, count: any) {
    this.unReadCount.next({name: name, count: count});
  }

  listenMobile(): Observable<any> {
    return this.listenerMobile.asObservable();
  }

  setMobile(mobileView: any) {
    this.listenerMobile.next(mobileView);
  }

  markTabComponentReady(){
    this.tabComponentReady$.next();
  }

  onTabComponentReady(): Observable<any>{
    return this.tabComponentReady$.asObservable();
  }

  
  constructor() { }

  /**
   * C153176-4565: tracking base tab header row height
   * Return number of rows of tab header.
   */
  checkTabHeaderRows() {
    const tabview_nav = $(".p-tabview-nav");
    if (tabview_nav && tabview_nav.length) {
      if (this.baseTabHeaderRowHeight === 0) {
        // first tab view in addition to dashboard, setting the base row height
        this.baseTabHeaderRowHeight = tabview_nav[0].clientHeight;
      }
      return Math.round(tabview_nav[0].clientHeight / this.baseTabHeaderRowHeight);
    } else {
      // console.log('cannot find tabview header bar!');
    }
    return 1;
  }

  /**
   *  C153176-5849 | Email as chat feature adoption audit information.
   */
  setChatViewUsageStatistics(viewtype: string): void {
    let chatUsageObj = [{ viewtype: viewtype, startAt: new Date() }];
    sessionStorage.setItem("chat_stats", JSON.stringify(chatUsageObj));
  }

  /**
   * Method to get the duration for the closed chat view and update session storage.
   * @param viewtype 
   */
  updateChatViewUsageStatistics(viewtype: string): void {
    let chatUsageObj = JSON.parse(sessionStorage.getItem("chat_stats"));
    if(!chatUsageObj) {
      return;
    }
    let idx = chatUsageObj.findIndex(i => i.viewtype === viewtype);
    if(idx > -1 ){
      let obj = chatUsageObj[idx];
      let endAt = new Date();
      let startAt = new Date(obj.startAt);
      let duration = (endAt.getTime() - startAt.getTime()) / 60000;
      console.log(duration + " Minutes");
  
      // Remove the view from session storage.
      let updatedchatUsageObj = chatUsageObj.filter(i => i.viewtype !== viewtype);
      console.log("Updated : " + JSON.stringify(updatedchatUsageObj));
      sessionStorage.setItem("chat_stats", JSON.stringify(updatedchatUsageObj));
    }
  }

  getsubjectOnTabClose(): Observable<string> {
    return this.subjectOnTabClose$.asObservable();
  }
  
  setsubjectOnTabClose(tabToclose) {
    return this.subjectOnTabClose$.next(tabToclose);
  }
}
