import { Component, AfterViewInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import 'tinymce/tinymce.min'; //import tinymce.min instead of tinymce to enable build-optimizer
import 'tinymce/icons/default'; // Load tinyMCE format option icons
import * as Rx from "rxjs";
import { AppUtils } from '../../../common/utility/appUtil';
import { UserDataService } from 'src/app/services/user-data.service';
import { QmaConstant } from '../../../constant/qma-constant';
import { ElementRef } from "@angular/core";
import { Renderer2 } from "@angular/core";
import { NewMessageService } from 'src/app/services/newMessage/new-message.service';
declare var tinymce: any;
import * as $ from 'jquery';

@Component({
  selector: 'editor-app',
  styleUrls: ['./exp-tiny.component.css'],
  template: `<textarea id="{{elementId}}" style="height:{{height}}px;" *ngIf="loadEditor"></textarea>`
})


export class ExpTinyComponent implements AfterViewInit, OnDestroy {
  @Input() toolbarOptions: number = 0;
  @Input() elementId: String;
  @Input() template: any;
  @Input() height: any = 200;
  @Input() skip_focus: boolean = false; //skip_focus arg for displaying placeholder text
  @Output() onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Input() disabled: boolean = false; // true for disabling editor
  @Output() inlineImage = new EventEmitter();
  @Output() keyupChange = new EventEmitter();
  @Output() keyupChangeSymphony = new EventEmitter();
  fontStyle:any;
  fontSizeList;
  fontStyleList = "";
  isMobile:boolean = false;

  TINYMCE_URL: string;
  PLACEHOLDER_ID: string = 'qmatinymceplaceholder';
  editor;
  id: String;
  toolBarShow: String = "";
  toolBarLocation: String = "";

  LoadFlag = new Rx.BehaviorSubject(false);
  @Output() sendEvent = new EventEmitter();
  messageInstance; // current message instance

  //TODO:Performance :: Calculate the time.
  startTime:any;

  isDefaultFontApplied = false;
  forPdfViewerReInitialise = false;
  loadEditor = true;
  contentBeforeEditorReload = "";
  originalContentFromNlpSuggestion: any;
  contentEdited: string = 'Not Edited';
  constructor(private userDataService: UserDataService, private element: ElementRef, private renderer: Renderer2, private newMessageService: NewMessageService) {
    //TODO:Performance :: Start Time. 
    this.startTime = performance.now();

    this.TINYMCE_URL = this.userDataService.getTinyMceUrl();
    this.fontStyle = this.userDataService.getDefaultFontDetail();
    this.userDataService.getEditorFontStyle().map((val) => {
      this.fontStyleList += val +"="+val+";";
    });
    this.fontSizeList = this.userDataService.getEditorFontSize().join(" ");

    if(window.innerWidth <= QmaConstant.MOBILE_WIDTH){
      this.isMobile = true;
    }
    else{
      this.isMobile = false;
    }
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message Editor Constructor processing took : ${Math.ceil(endTime - this.startTime)} miliseconds`);
  }

  isToolBarOption(options: number) {
    switch (options) {
      case 1:
        //for other small areas like template and signature
        this.toolBarShow = 'bold italic underline alignleft aligncenter alignright casechange';
        break;
      case 2:
        //for Profile setting - template, signature and out of office
        this.toolBarShow = 'bold italic underline strikethrough | alignleft aligncenter alignright justifyfull | link | ' +
          'bullist numlist outdent indent | anchor | undo redo | forecolor backcolor | fontselect | fontsizeselect |  removeformat  formatpainter  powerpaste  table casechange';
        break;
      case 3:
        //for Mobile
        this.toolBarShow = 'bold italic underline strikethrough | alignleft aligncenter alignright justifyfull | bullist';
        // this.toolBarLocation  = 'bottom'
        break;
      default:
        this.toolBarShow = 'bold italic underline strikethrough | alignleft aligncenter alignright justifyfull | link | ' +
          'bullist numlist outdent indent | anchor | print | undo redo | forecolor backcolor | fontselect fontsizeselect |  removeformat  formatpainter  powerpaste  table casechange';
    }
  }

  ngAfterViewInit() {
   // this.updateEditorByPdfViewer();
    setTimeout(() => {
      let startTime = performance.now();
      let readOnly = this.disabled ? 1 : 0;

      if(this.isMobile){
        this.isToolBarOption(3); //For Mobile options
      }
      else{
        this.isToolBarOption(this.toolbarOptions);
      }
      
      tinymce.baseURL = "assets/tinyMCE";// trailing slash important
      tinymce.init({
        selector: '#' + this.elementId,
        // Add any unwanted symbol in the protect array, the tinymce editor will not consider it as text.
        // POC: http://fiddle.tinymce.com/pAfaab/6
        // https://stackoverflow.com/questions/40234340/stripping-out-if-mso-by-displaying-in-tinymce
        // https://www.tiny.cloud/docs/configure/content-filtering/#protect
        // Arbitrary junk values are populating on message body
        protect: [
          /\<!\[if !mso\]\>/g,             // Protect <![if !mso]>
          /\<!\-\-\[if gte mso 10\]\>/g,   // Protect <!--[if gte mso 10]>
          /\<!\[if !vml\]\>/g,             // Protect <![if !vml]>
          /\<!\[endif\]\>/g,               // Protect <![endif]>
          /<\?php[\s\S]*?\?>/g             // Protect <?php ?> code
        ],
        mobile: {
          theme: 'silver'
        },
        readonly: readOnly,
        height: this.height,
        plugins: 'code autolink link image  lists print table formatpainter powerpaste casechange',

        // Update the tinyMCE url
        external_plugins: {
          'formatpainter': this.TINYMCE_URL + '/tinymce/plugins/formatpainter/plugin.js',
          'powerpaste': this.TINYMCE_URL + '/tinymce/plugins/powerpaste/plugin.js',
          'casechange' : this.TINYMCE_URL + '/tinymce/plugins/casechange/plugin.js',
        },
        //formatting button added
        formats: {
          formatpainter_removeformat: [
            {
              selector: 'b,strong,em,i,font,u,strike,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins',
              remove: 'all', split: true, expand: false, block_expand: true, deep: true
            },
            { selector: 'span', attributes: ['style', 'class'], remove: 'empty', split: true, expand: false, deep: true },
            { selector: '*:not(tr,td,th,table)', attributes: ['style', 'class'], split: false, expand: false, deep: true }
          ]
        },
        powerpaste_block_drop: true,
        paste_postprocess: (editor, fragment) => {
          console.log('fragment:', fragment);
          //fragment.node.appendChild(textnode);
          let element = fragment.node.innerHTML;

          let inlineImagePresent = false;

          if (!AppUtils.isUndefinedOrNull(element)) {
            let text = element;
            if (!AppUtils.isUndefinedOrNull(text) && text.toString().indexOf('<img src="blob:') !== -1) {
              inlineImagePresent = true;
            }
          }
          this.inlineImage.emit(inlineImagePresent);
        },
        fontsize_formats: this.fontSizeList,
        font_formats : this.fontStyleList,
        toolbar: this.toolBarShow,
        // toolbar_location : this.toolBarLocation,
        menubar: false,
        browser_spellcheck: true,
        powerpaste_html_import: true,
        cleanup: true,
        //additional space in outlook
        force_br_newlines: true,
        force_p_newlines: false, //This will add new line to <p></p> then formatting can be applied.
       
       forced_root_block: 'p', //Spacing Issue.
        forced_root_block_attrs: {
          //Navigation : Additional user level preference option
          // to set font from profile settings
          // 'style': 'margin: 0px 0px;' + `font-family:${this.fontStyle.fontName};` // + `font-size:${this.fontStyle.fontSize}`
        },
        // remove_linebreaks: true,
        //convert_newlines_to_brs: true,
        // inline_styles: false,
        // entity_encoding: 'name',
        // entities: '160,38,amp,nbsp,60,lt,62,gt',
         // entity_encoding: 'raw', // performace refactor change
         // verify_html : false, // performace refactor change
        statusbar: false,
        contextmenu: false,
        extended_valid_elements: '+div[*]',//to remove blank div that is taking space while adding nlp suggestion 
        init_instance_callback: () => {
            this.enableFlag(); 
            if (!this.forPdfViewerReInitialise) {
              this.onLoad.emit("Loaded"); 
            }else {
              // reset flag
              this.forPdfViewerReInitialise = false;
              this.setData(this.contentBeforeEditorReload);
              this.contentBeforeEditorReload = "";
            }
            
            this.fontSet(this.skip_focus); //pass in skip_focus arg
          }, // Listen to TinyMce changes
          setup: (e) => { 
                /* e.on('keydown', (event) => {
                  if (!this.isDefaultFontApplied) { */
                    //Editor: Latest selected font-family and font-size should be applied automatically while new-message and reply.
                   /*   this.fontSet(this.skip_focus); // maintain flag
                     this.isDefaultFontApplied = true;
                  } 
                }); */
                e.on('keydown', (event) => {
                  this.fontSet(this.skip_focus);
                }),
                e.on('keyup', (event) => {
                 //  this.fontSet(this.skip_focus);
                  let editableContent = e.getContent({ format: "html" });
                  let editableDivContent = this.extractContentFromDiv(editableContent,'convSuggestionsId')
                  if (this.originalContentFromNlpSuggestion != editableDivContent) {
                    this.contentEdited = 'Edited'
                  } else {
                    this.contentEdited = 'Not Edited'
                  }
                  this.keyupChange.emit("");
                  if(event && event.keyCode && event.keyCode === 13 && event.shiftKey === true) {
                    this.keyupChangeSymphony.emit("Shift + Enter");
                  }
                  else if(event && event.keyCode && event.keyCode === 13) {
                    this.keyupChangeSymphony.emit("Enter");
                    
                  }    
                         
                });
                //remove the placeholder (if any) when focus is on
                e.on('focus', () => {
                  /*- Disable the placeholder message
                  let elem = e.dom.select('#' + this.PLACEHOLDER_ID); 
                  if (elem) {
                    e.dom.remove(elem);
                  }*/
                  // notify of editor focus
                  this.newMessageService.setEditorFocus(true);
                  if (!this.isDefaultFontApplied) {
                  // this.fontSet(this.skip_focus);
                  this.isDefaultFontApplied = true;
                  }
                });
              }
      });
      let endTime = performance.now();
      console.log(`QMA2.0 Performance :: Editor setup init loading : ${Math.ceil(endTime - startTime)} miliseconds`);

    }, 0);

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: Editor Component loading : ${Math.ceil(endTime - this.startTime)} miliseconds`);
  
  }

  // add skip_focus arg so that user can see the placeholder in new message editor
  fontSet(skip_focus = false){
    setTimeout(() => {
   /*  tinymce.get(this.elementId).execCommand("fontName", false, this.fontStyle.fontName, {skip_focus: true});
    tinymce.get(this.elementId).execCommand("fontSize", false, this.fontStyle.fontSize, {skip_focus: true});
    */ 
   let countId = this.newMessageService.getIndex() - 1 ;
   // for reply screen
   if($('#my-editor' + countId + '_ifr').contents().find('hr').length > 0) {
    $('#my-editor' + countId + '_ifr').contents().find('hr').first('hr').prevAll().not('.custom-template').css( 'font-size', this.fontStyle.fontSize );
    $('#my-editor' + countId + '_ifr').contents().find('hr').first('hr').prevAll().not('.custom-template').css( 'font-family',  this.fontStyle.fontName );
    $('#my-editor' + countId + '_ifr').contents().find('hr').first('hr').prevAll().not('.custom-template').css( 'margin', '0px' );

   } else { // new msg scenario
       // apply default font on loading and after adding sign/template/disclaimer
    $('#my-editor' + countId + '_ifr').contents().find('#signatureDiv').first().prevAll().not('.custom-template').css( 'font-size', this.fontStyle.fontSize );
    $('#my-editor' + countId + '_ifr').contents().find('#signatureDiv').first().prevAll().not('.custom-template').css( 'font-family', this.fontStyle.fontName);
    $('#my-editor' + countId + '_ifr').contents().find('#signatureDiv').first().prevAll().not('.custom-template').css( 'margin', '0px');

    $('#my-editor' + countId + '_ifr').contents().find('#signatureReplyMsgDiv').first().prevAll().not('.custom-template').css( 'font-size', this.fontStyle.fontSize );
    $('#my-editor' + countId + '_ifr').contents().find('#signatureReplyMsgDiv').first().prevAll().not('.custom-template').css( 'font-family', this.fontStyle.fontName);
    $('#my-editor' + countId + '_ifr').contents().find('#signatureReplyMsgDiv').first().prevAll().not('.custom-template').css( 'margin', '0px');
    $('#my-editor' + countId + '_ifr').contents().find('#disclaimerReplyMsgDiv').first().next().not('.custom-template').css( 'font-size', this.fontStyle.fontSize );
    $('#my-editor' + countId + '_ifr').contents().find('#disclaimerReplyMsgDiv').first().next().not('.custom-template').css( 'font-family', this.fontStyle.fontName );
    $('#my-editor' + countId + '_ifr').contents().find('#disclaimerReplyMsgDiv').first().next().not('.custom-template').css( 'margin', '0px' );
   }
    // when user clicks backspace or select all and delete
    if($('#my-editor' + countId + '_ifr').contents().find('p').length === 1) {
      $('#my-editor' + countId + '_ifr').contents().find('p').css( 'font-size', this.fontStyle.fontSize );
      $('#my-editor' + countId + '_ifr').contents().find('p').css( 'font-family', this.fontStyle.fontName); 
      $('#my-editor' + countId + '_ifr').contents().find('p').css( 'margin', '0px');
    }
    // for template or signature
    if($('#template_ifr').contents().find('p').length === 1) {
      $('#template_ifr').contents().find('p').css( 'font-size', this.fontStyle.fontSize );
      $('#template_ifr').contents().find('p').css( 'font-family', this.fontStyle.fontName); 
      $('#template_ifr').contents().find('p').css( 'margin', '0px');
    }
    if($('#signature_ifr').contents().find('p').length === 1) {
      $('#signature_ifr').contents().find('p').css( 'font-size', this.fontStyle.fontSize );
      $('#signature_ifr').contents().find('p').css( 'font-family', this.fontStyle.fontName); 
      $('#signature_ifr').contents().find('p').css( 'margin', '0px');
    }
    // for email as a chat
    this.applyDefaultFontTOEmailAsaChat();


    }, 0);
  }
 // Editor: Latest selected font-family and font-size should be applied automatically while new-message and reply.
  setSelectedfont(skip_focus = false){
    const font = this.getSelectedFont();
    tinymce.get(this.elementId).execCommand("fontName", false, font.name, {skip_focus: true});
    tinymce.get(this.elementId).execCommand("fontSize", false, font.size, {skip_focus: true});
  }
  // Catch the exception, so that it doesn't break the flow. User is not to switch the inquiry
  getSelectedFont() {
    let font = { name: "", size: "" };
    try {
      if (tinymce.get(this.elementId)) {
        font.name = tinymce.get(this.elementId).queryCommandValue("FontName") === "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif" 
                    ? this.fontStyle.fontName:tinymce.get(this.elementId).queryCommandValue("FontName");
        font.size = tinymce.get(this.elementId).queryCommandValue("FontSize") === ""?this.fontStyle.fontSize:tinymce.get(this.elementId).queryCommandValue("FontSize");
      }
    } catch (e) {
      console.log("Exception while getting the font details : ", e)
    }
    return font;
  }

  enableFlag() {
    this.LoadFlag.next(true);
    this.originalContentFromNlpSuggestion = this.extractContentFromDiv(tinymce.get(this.elementId).getContent({ format: "html" }),'convSuggestionsId')
    if (tinymce.editors && tinymce.editors.length) {
      const lastIndex = tinymce.editors.length -1;
      tinymce.editors[lastIndex].shortcuts.add('alt+s', 'Send', () => {
        if (this.messageInstance) {
          this.sendEvent.emit({messageInstance: this.messageInstance, action: 'Send'});
        }
      });
      tinymce.editors[lastIndex].on('dragover', (e) => {
        if (e && e.target) {
          e.target.focus();
        }
      });
      tinymce.editors[lastIndex].on('drop', (e) => {
        const data = e.dataTransfer.getData('text');
        if (data && data.startsWith(QmaConstant.QMA_DND_HEADER)) {
          this.sendEvent.emit({messageInstance: this.messageInstance, data: data, action: 'drop'});
          e.preventDefault();
          e.stopPropagation();
        }
      });
    }
  }
  // add placeholder
  getPlaceholder() {
    // [C170665-1284] - Disable the placeholder message
    return "";
  }

  getData() {
    if (!this.isNull(tinymce.get(this.elementId))) {
      return (tinymce.get(this.elementId).getContent());
    }
  }
  setData(content: String) {
    // handling content upon its loading to T editor (initial loading case which is not covered by paste plugin)
    if (content) {
      // return the second group in regex if matched
      content = content.replace(/(<!\[if !supportLists\]>)([^!]*)(<!\[endif\]>)/g, (match, p1, p2, p3, offset, string) => {
        return p2;
      });
      // additional filtering of MS HTML metatags
      content = content.replace(/(<!\[if !vml\]>)([^!]*)(<!\[endif\]>)/g, (match, p1, p2, p3, offset, string) => {
        return p2;
      });
      // Auto generated email with comments corrupting html contents
      // already removing comments in maildetailview replaceWildCharacters
      // content = content.replace(/(<!\[endif\]-->)/g, '');
      
    }
    if (!this.LoadFlag.getValue()) {

      //subscribing for flag to look for Tiny is  loaded or not--

      this.LoadFlag.subscribe(val => {

        // console.log("subscribed -- " + val);


        if (tinymce.get(this.elementId) != null) {
          // console.log("in setData before load flag subscribe---- " + this.LoadFlag.getValue(), content);

          if (val) {
            // console.log("in Subscribe", content, val);
            if (content) {
              tinymce.get(this.elementId).setContent(content);
            } else {
              console.log('exp-tiny, content empty', content);
            }

          } else {
            console.log("erroe loading in data")
          }
        }
      })
    }
    else {
      tinymce.get(this.elementId).setContent(content);
    }



  }//end of set

  clearEditorForTaskize() {
    tinymce.get(this.elementId).setContent('');
  } 

  // Format Method
  appnedData(content: String, elemID) {
    let cnt = tinymce.get(this.elementId).getContent();
    let arr: string[] = cnt.split("\n")
    let num: number = arr.length;
    let i: number;
    let editorContent: string = "";
    let indexSignNewMsg: number = 0;
    let disclaimerNewMsg: number = 0;
    let indexSignReplyMsg: number = 0;
    let indexDisclaimReplyMsg: number = 0;
    for (i = 0; i < num; i++) {
      var str = arr[i];
      if (str.indexOf('signatureDiv') != -1) { indexSignNewMsg = i; }
      if (str.indexOf('disclaimerDiv') != -1) { disclaimerNewMsg = i; }
      if (str.indexOf('signatureReplyMsgDiv') != -1) { indexSignReplyMsg = i; }
      if (str.indexOf('disclaimerReplyMsgDiv') != -1) { indexDisclaimReplyMsg = i; break;}
    }
    if ((indexSignReplyMsg != 0) && (indexDisclaimReplyMsg != 0)) {
      let rm = arr.splice(indexSignReplyMsg + 1, (indexDisclaimReplyMsg - indexSignReplyMsg) - 1, "<p>" + content.toString() + "</p><br>");
    } else if  (indexSignReplyMsg != 0 || indexDisclaimReplyMsg != 0) { 
      let rm = arr.splice(indexDisclaimReplyMsg , 0, "<div id='signatureReplyMsgDiv' contenteditable = 'false' style='cursor: text;outline: none;'></div><p>" + content.toString() + "</p><br>");
    }
    else {
      let rm = arr.splice(indexSignNewMsg + 1, (disclaimerNewMsg - indexSignNewMsg) - 1, "<p>" + content.toString() + "</p><br>");
    }
    arr.forEach(x => {
      editorContent += x;
    });
    tinymce.get(this.elementId).setContent(editorContent);
    this.fontSet(true);
  }

  ngOnDestroy() {
    if (tinymce.get(this.elementId)) {
      tinymce.get(this.elementId).execCommand('mceRemoveControl', true, '#' + this.elementId);
    }
    tinymce.remove('#' + this.elementId);
    this.LoadFlag.unsubscribe();
  }

  getId() {
    return this.elementId;
  }

  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }
  applyDefaultFontTOEmailAsaChat() {
    if($("#" + this.elementId + '_ifr').contents().find('p').length === 1) {
      $("#" + this.elementId + '_ifr').contents().find('p').css( 'font-size', this.fontStyle.fontSize );
      $("#" + this.elementId + '_ifr').contents().find('p').css( 'font-family', this.fontStyle.fontName); 
      $("#" + this.elementId + '_ifr').contents().find('p').css( 'margin', '0px');
    }
    
  }
  
  reInitialiseTinyMCE() {
    this.forPdfViewerReInitialise = true;
    this.contentBeforeEditorReload = this.getData();
    tinymce.remove('#' + this.elementId);
    this.loadEditor = false;
    setTimeout(() => {
    this.loadEditor = true;
      this.ngAfterViewInit();
    }, 2000);
  }

  setNlpEditedFlag(data) {
    this.contentEdited = data
  }

  extractContentFromDiv(nlpContent,divId) {
    let div = document.createElement('div');
    div.innerHTML = nlpContent;
    let divData = div.querySelector('#' + divId)
    return divData ? divData.textContent : null;
  }
}
