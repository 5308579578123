import { map, filter } from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, OnChanges, ChangeDetectorRef, SimpleChanges, ChangeDetectionStrategy,ElementRef,Inject,ViewChild, AfterViewInit } from '@angular/core';
import { InboxService } from "src/app/services/inbox.service";
import { NotificationService } from "../../common/services/notifications.service"
import { TabDataService } from '../../services/tab-data.service';
import { UserDataService } from "src/app/services/user-data.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { QmaConstant } from 'src/app/constant/qma-constant';
import { LeftNavigationService } from '../leftnav/leftnav.service';
import { OverlayPanel } from "primeng//overlaypanel";
import { RadioButtonModule } from 'primeng/radiobutton';
import { GroupAdminService } from "src/app/services/group-admin/group-admin.service";
import { DOCUMENT } from "@angular/common";
import { RightNavigationService } from 'src/app/services/panel/panel.service';
import { SymphonyNotificationService } from 'src/app/common/services/Symphony-notifications.service ';
import { SymphonyChatServiceService } from 'src/app/symphony/service/symphony-chat-service.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { TaskizeAuthService } from 'src/app/services/taskize-auth.service';

import * as $ from 'jquery';
import { ROUTER_CONFIGURATION } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopnavComponent implements OnInit, OnChanges,AfterViewInit {
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
  myForm;

  @Input() clickStatusArray: Array<boolean>;
 // @Output() optionClicked = new EventEmitter();
  @Output() advanceSearchToggle = new EventEmitter();
  @Input() advanceSearchVisible: boolean = false;
  @Input() clearCriteria: boolean = true;
  showMultiSelection: boolean = false;
  inputFocused: boolean = false;
  isMobileSearchView : boolean = false;
  notificationCount: number;
  symphonyNotificationCount: number;
  advCriteriaToggle: any = true;
  firstClick: boolean = true;
  val2: string = 'DATE';
  currentTab: any = {
    header: 'Assigned To Me',
    count: 0
  };
  profilePic: any;
  showClearIcon: boolean = false;
  userId: string = "";
  isMobile: boolean;
  tabs: any = {};

  relaseDoc: any;
  display: boolean = false;

  searchForm: FormGroup = this.builder.group({
    searchText: new FormControl('', Validators.required),
    frmCtrlsearchInFolders:new FormControl('All')
  });
  public showSearchError: boolean = false;
  protected cachedTabs = {};
  sortAttrNames = ["Date", "Subject", "From"];
  sortAttrIndex: any = 0;
  sortDirection: boolean = false;
  sortDirectionLabel: string = "By Date";

  CSI: string;
  AUID: string;
  AuthKey: string;
  profilePicSrc: string;
  displayChatPopup: boolean = false;
  isScriptLoaded: boolean = false;

  //symphony stream id
  @Input() streamId: String = '';
  @Output() closeChatViewEmit = new EventEmitter();
  symphonyEnabled : boolean = false;
  support_for_ukraine_url = QmaConstant.SUPPORT_FOR_UKRAINE_URL;
  loginUserInfoData: any;
  isTaskizeEnabledForUser: any;
  windowHandle: Window;
  searchInFolders = [{label:'All', value:'All'},
                     {label:'Inbox', value:'Inbox'},
                     {label:'Pending Approval', value:'Pending Approval'},
                     {label:'Resolved', value:'Resolved'},
                     {label:'Sent', value:'Sent'},
                     ];
  selectedFolder:string = 'All';

  searchText: any;
  // arrSavedSearch :any = [{name:'TestSeach'},{name:'TestSeach2'}];
  arrSavedSearch :any= [];
  savedSeachSingle: any[];
  txtPlaceholder = 'Search'
  smartSearchTopInitialkeywordsCount = 5;
  searchTextLength = 0;
  smartSearchCharactersLimit = 0;
  releaseNoteUrl: string;
  constructor(private inboxService: InboxService,
    private notificationService: NotificationService,
    private tabDataService: TabDataService,
    public userDataService: UserDataService,
    private sanitizer: DomSanitizer,
    private builder: FormBuilder,
    private leftNavigationService: LeftNavigationService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private groupAdminService: GroupAdminService,
    private rightNavigationService: RightNavigationService,
    @Inject(DOCUMENT) private document: any,
    private symphonyNotificationService: SymphonyNotificationService,
    private symphonyChatService: SymphonyChatServiceService,
    private dashboardService: DashboardService,
    private taskizeService : TaskizeAuthService
  ) {
    // this.cdr.detach();
    this.isMobile = (window.innerWidth <= QmaConstant.MOBILE_WIDTH);

    this.tabDataService.getTabData().subscribe(tab => {
      // skip setting 'tab.header' to SEARCH in mobile version (which is not displayed)
      if (tab.header !== 'SEARCH') {
        this.currentTab = tab;
        this.cdr.detectChanges();
      }
      if (this.isMobile && tab.header) {
        // cache the tab in a separate field, so that it may be restored if needed.
        this.tabs[tab.header] = tab;
      }
      if ((tab.header as String).includes('NEW MESSAGE') || (tab.header as String).includes(QmaConstant.QMA_NEW_BUBBLE) || tab.header == 'DASHBOARD') {
        this.showMultiSelection = false;
      }
      else {
        this.showMultiSelection = true;
      }
    });
    this.tabDataService.listenMobile().subscribe(mob => {

    //  this.isMobile = mob;
    })
    this.myForm = this.fb.group({
      myRadio: ['DATE', []]
    })
  
// check symphony entitlement
    this.symphonyChatService.getSymphonyEntitlement().subscribe((entitlement: any) => {
      if (entitlement && entitlement.status && this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.symphonyConfig
        && this.userDataService.loggedInUserInfo.symphonyConfig.symphonyEnabled && this.userDataService.loggedInUserInfo.isSymphonyEnabledForUser == 'Y') {
        this.symphonyEnabled = true;
      }
      else {
        this.symphonyEnabled = false;
      }
    });

  // check taskize Entitlement
  if (this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.taskizeConfig && this.userDataService.loggedInUserInfo.taskizeConfig.taskizeEnabled && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser?.toUpperCase() == 'Y') {
      this.isTaskizeEnabledForUser = true;
    } else {
      this.isTaskizeEnabledForUser = false;
  }
 
// update unread count for mobile
    if (this.isMobile) {
       this.updateUnreadCount();
    }
    this.smartSearchCharactersLimit = this.userDataService && this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.qmaUI2Config &&
                                      this.userDataService.loggedInUserInfo.qmaUI2Config.smartSearchCharactersLimit?this.userDataService.loggedInUserInfo.qmaUI2Config.smartSearchCharactersLimit:QmaConstant.SMARTSEARCHChARACTERSLimit;
  }

  expandSearchBox(event) {
    if (event.currentTarget.name && event.currentTarget.name === 'searchBtn') {
      this.inputFocused = true;
      this.showClearIcon = false;
    }
  }

  resetSearchBox(event: any) {
    // if (!this.isMobile) {
    //   if (!this.advanceSearchVisible && (!event.srcElement || event.srcElement.id !== 'cancel-search-topnav')) {
    //     this.inputFocused = false;
    //     this.txtPlaceholder = this.inputFocused? 'Search by inquiry id, assigned group, email content and others…':'Search...';
    //   }
    // } else {
    //   // TBD: to be finalized what are the cases when outside clicks shall hide the search box
    // }
    // // this.cdr.detectChanges();
    // this.cdr.reattach();
  }

  enableBackButton() {
    this.inputFocused = false;
    this.isMobileSearchView  = false;
    this.inboxService.disableMobileSearch();
    // clear search at component level - retriggering original request
    this.clearSearch();
  }

  toggleSearch() {
    this.reattchach();
    this.firstClick = false;
    this.advanceSearchVisible = !this.advanceSearchVisible;
    if (this.advanceSearchVisible == false) {
      //reset inputFocus only for web version. The mobile version keep it on until user hits back button
      if (!this.isMobile) {
        this.inputFocused = false;
      }
    }
    this.advanceSearchToggle.emit(this.advanceSearchVisible);
    if(this.advanceSearchVisible == true) {
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search", "Click", "Advanced Search", 0);
    }
  }

  reattchach() {
    this.cdr.reattach();
  }
  mobileSearchClick() {
    this.isMobileSearchView  = true;
    this.onFocusEventHandler("");
    this.inboxService.enableMobileSearch();
  }

  checkIfActive() {
    if (this.clickStatusArray.includes(true)) {
      return 'isActive';
    }
  }

  onOptionClick(clickedItemIndex: number) {
    //this.optionClicked.emit(clickedItemIndex);
    this.rightNavigationService.open(clickedItemIndex)
  }

  chatViewPopup() {
    if(this.streamId){
      this.displayChatPopup = true;
    } else {
      this.displayChatPopup = false;
    }
    
  }

  onClickAdvCriteria() {
    this.advCriteriaToggle = !this.advCriteriaToggle;
  }
  // Global search on texxt enter in top nav search box
  onInputChangeHandler(event: any) {
    let searchText = event.target.value;
    if (searchText.length > 0)
      this.showClearIcon = true;
    else if (searchText.length === 0)
      this.showClearIcon = false;
    else
      this.showClearIcon = false;
    // reset search error if condition is met
    if (searchText.length > 3 && this.showSearchError) {
      this.showSearchError = false;
    }
    this.searchForm.get('searchText').setValue(searchText);
    if (this.isMobile) {
      this.inboxService.onKeySearchText({ text: searchText, forced: false });
    }
  }

  onFocusEventHandler(event: any) {
    this.inputFocused = true;
    let text = this.searchForm.get('searchText').value;
    if (text && text.length > 0) {
      this.showClearIcon = true;
    } else {
      this.showClearIcon = false;
    }
    this.cdr.detectChanges();
  }

  callSearch(event: any, isSolrSearchText:boolean,autocomplete) {
    const searchForm = {...this.searchForm}
    const searchText = searchForm.value.searchText.name?searchForm.value.searchText.name:searchForm.value.searchText;
    // this.searchForm.get('searchText').setValue(searchText);
    if (event.keyCode === 13) {
      // if (event.target.value.length > 2) { // Global Search - To have minimum of 3 characters as search criteria
      if (searchText.length > 2) {  
        console.log('Text :', event.target.value);
        this.showSearchError = false;
        // if (!this.isMobile && ( !this.searchForm.value.frmCtrlsearchInFolders)) {
        //   // let reqObj = { viewName: "Search", solrSearchText: event.target.value, from: 'search' };
        //   let reqObj = { viewName: "Search", solrSearchText: searchText, from: 'search' };
        //   this.inboxService.requestObj = reqObj;
        //   //reset request since it is a new search
        //   this.inboxService.resetRequest = true;
        //   //clear searchBoxes at inbox service
        //   this.inboxService.searchBoxes = undefined;
        //   this.tabDataService.sendTabData("SEARCH", 0);
        // } else 
        if (!this.isMobile) { 
          let reqObj = isSolrSearchText ? { "viewName": "Search", "solrSearchText": searchText, from: 'search' } : { "viewName": "Search", "advanceSearchData": {content:searchText} };
                  
          this.inboxService.requestObj = reqObj;
          this.inboxService.resetRequest = true;
          this.inboxService.searchBoxes = (searchForm.value.frmCtrlsearchInFolders =='Sent') ? 'Outbox' : searchForm.value.frmCtrlsearchInFolders;
          this.inboxService.triggerMobileSearch('adv-search');
          this.tabDataService.sendTabData("SEARCH", 0);
         // this.loadFormDataToService();
        }
        if(isSolrSearchText && searchForm.value && searchForm.value.searchText && searchForm.value.searchText.id) {
          this.inboxService.requestObj['searchKeyId'] = searchForm.value.searchText.id;
        } else if(isSolrSearchText && searchForm.value && searchForm.value.searchText) {
          this.userDataService.saveSmartSearchFeedback(this.inboxService.requestObj).subscribe((res:any)=>{
            if(res.data && res.id)
            this.arrSavedSearch.unshift({name:this.inboxService.requestObj.solrSearchText,id:res.id});
            this.inboxService.requestObj.searchKeyId = res.id;
          });
        }
          else if(this.isMobile) {
          this.inboxService.triggerMobileSearch('global');
        }
        setTimeout(()=>{
          $('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').css('display','none');
        },0)
      }
      else {
        this.showSearchError = true;
      }
      autocomplete.handleDropdownClick();
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Quick Search - "+this.currentTab.header, "Type&Search", "Quick Search - "+this.currentTab.header, 0);
  }

  triggerSearch(event: any) {
    const text = this.searchForm.get('searchText').value;
    console.log('triggerSearch: text=', text);
    if (text && text.length > 3) {
      this.showSearchError = false;
      if (!this.isMobile) {
        const reqObj = { viewName: "Search", solrSearchText: text, from: 'search' };
        this.inboxService.requestObj = reqObj;
        //reset request since it is a new search
        this.inboxService.resetRequest = true;
        // clear searchBoxes at inbox service
        this.inboxService.searchBoxes = undefined;
        this.tabDataService.sendTabData("SEARCH", 0);
      } else {
        this.inboxService.triggerMobileSearch('global');
      }
    } else {
      this.showSearchError = true;
    }
  }

  ngOnInit() {

    this.notificationService.getNotificationCount().subscribe(data => {
      this.notificationCount = data.length
      this.cdr.detectChanges();
    });
    
    this.symphonyNotificationService.getNotificationCount().subscribe(data =>{
      this.symphonyNotificationCount = data.length
      this.cdr.detectChanges();
    }
    );
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.loginUserInfoData = loginUserInfo;
      let userPic = loginUserInfo.profilePic;
      this.userId = loginUserInfo.userId;
      this.relaseDoc = loginUserInfo.currentReleaseDocumentName;
      if (userPic) {
        this.profilePic = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + userPic);
      }
      if (loginUserInfo && loginUserInfo.showReleaseDocument) {
        this.showCurrentReleaseNotes();
      }
      //[C153176-3427] -added below code get profile picture from Global directory
      let globalDirectoryConfig = loginUserInfo.globalDirectoryConfig;
      let userAuthKey = loginUserInfo.userAuthKey;
      if(globalDirectoryConfig){
        this.CSI = globalDirectoryConfig.csi;
        this.AUID = globalDirectoryConfig.profilePictureConfig.auid;
        this.AuthKey = userAuthKey;
        let picSrc = globalDirectoryConfig.profilePictureConfig.profilePicEndPoint;
        
        this.profilePicSrc = picSrc+"?authkey="+this.AuthKey+"&CSI="+this.CSI+"&AUID="+this.AUID;
        
        console.log('making url '+ this.profilePicSrc);
      }
      if(loginUserInfo.qmaUI2Config && loginUserInfo.smartSearchTopInitialkeywordsCount) {
            this.smartSearchTopInitialkeywordsCount = loginUserInfo.smartSearchTopInitialkeywordsCount;
      }
    });
    this.notificationService.listenCount().subscribe((count: any) => {
      this.notificationCount = count.length;
      this.cdr.detectChanges();
    });
    this.symphonyNotificationService.listenCount().subscribe((count: any) => {
      this.symphonyNotificationCount = count.length;
      this.cdr.detectChanges();
      console.log(count);
    });
    this.inboxService.resetSearch.subscribe((event: any) => {
      this.clearSearchText();
    });
    this.inboxService.searchTabEvent.subscribe((event: any) => {
      if (!event || !event.action) {
        return;
      }
      if (event.action === 'addUpdate') {
        if (event.tab) {
          this.cachedTabs[event.tab.toUpperCase()] = this.searchForm.get('searchText').value;
        }
      } else if (event.action === 'close') {
        if (event.tab) {
          if (this.cachedTabs[event.tab.toUpperCase()]) {
            this.clearSearchText();
            this.cachedTabs[event.tab.toUpperCase()] = undefined;
          }
        }
        /*C153176-4795 Mobile change */
        if (this.isMobile) {
          // check current tab from sessionStorage and restore on topnav for mobile version
          let currentTab = sessionStorage.getItem('currentTab');
          if (currentTab) {
            let tab = this.tabs[currentTab];
            if (tab) {
              this.currentTab = tab;
              this.cdr.detectChanges();
            }
          }
        }

      } else if (event.action === 'clear') {
        this.clearSearchText();
      }
    });
    this.userDataService.httpgetSearchKeyWords().subscribe((data:any)=>{
    Object.keys(data.data).forEach((k)=> {
      if(data.data[k]) {
        this.arrSavedSearch.push({name:data.data[k],id:k});
      }
    }
    );
    this.addSearchLimitText(this.arrSavedSearch);
    // this.arrSavedSearch.reverse();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.clearCriteria && changes.clearCriteria.currentValue) {
      this.clearSearchText();
    }
    if(changes.streamId && changes.streamId.previousValue !== changes.streamId.currentValue && this.streamId != ''){
      console.log('top nav streamId : '+this.streamId);
      this.chatViewPopup();
    }
    if(!this.streamId){
      this.chatViewPopup();
    }
  }

  clearSearchText() {
    // this.searchForm.get('searchText').setValue('');
    this.showSearchError = false;
    this.showClearIcon = false;
  }

  clearSearch() {
    // C153176-5043: align mobile with web version
    this.clearSearchText();
    const reqObj = { viewName: "Search", rollback: 'search'};
    this.inboxService.requestObj = reqObj;
    // C153176-4992: reset request since it is a new search object (rollback of last search)
    this.inboxService.resetRequest = true;
    // C153176-4540: clear searchBoxes at inbox service
    this.inboxService.searchBoxes = undefined;
    // trigger any 'eligible' view to update
    // 'eligible' views: Inbox, Sent, Resolve
   // this.tabDataService.sendTabData("SEARCH", 0, 'updateEligibleViewOnly');
    this.tabDataService.sendTabData("SEARCH", 0, 'resetSearch');
    if (this.isMobile) {
      this.inboxService.onKeySearchText({ text: '', forced: false });
    }
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Clear Search", "Click", "Clear Search", 0);
  }

  mobileSelectClick(event?: any) {
    this.inboxService.mobileCheckboxSelected = !this.inboxService.mobileCheckboxSelected;
    this.inboxService.notifyMobileCheckboxSelection();
  }

  mobileSortingClick(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }
  closeSortingModal(event, overlaypanel) {
    overlaypanel.hide();

  }
  
  showCurrentReleaseNotes() {
    this.display = true;
    const folderUrl = "/QMA/releaseNotesDocuments/";
    const origin = this.document.location.origin;

    let qmaUrl = "XXXXX"; // Point to Production URL.

    // if the origin contains port number. It is local or dev server according to current configuration.
    if (origin.indexOf(':') > -1 || origin.indexOf('qa') > -1 || origin.indexOf('uat') > -1) {
      qmaUrl = origin;
    }
    this.releaseNoteUrl = qmaUrl + folderUrl + this.relaseDoc;
  }

  updateReleaseNotesDocument() {
    this.groupAdminService.saveReleaseData().subscribe(result => {
      this.display = false;

    },
      error => {
        this.display = false;
        console.error('Fail To Save User Data Please Contact support ', error);
      });

  }

  mobileSortDirection(event): void {
    this.sortDirection = !this.sortDirection;
    this.cdr.detectChanges();
  }

  displayEllipsis(_count: number) {
    if (_count >= 1000) {
      return ((_count / 1000).toFixed(1) + "k");
    }
    else {
      return _count;
    }
  }

  showSlide(event: Event) {
    this.leftNavigationService.toggle();
    event.stopPropagation();
  }

  sortDirectionClass() {
    return this.sortDirection ? "fa fa-sort-amount-asc" : "fa fa-sort-amount-desc";
  }

  sortAttrLabel() {
    let index = this.sortAttrIndex;
    if (typeof this.sortAttrIndex === 'string') {
      index = parseInt(this.sortAttrIndex);
    }
    this.inboxService.triggerMobileSort({ attr: this.sortAttrNames[index], direction: this.sortDirection });
    return "By " + this.sortAttrNames[index];
  }

  closeChatView(event){
    console.log("closing chat view ");
    this.displayChatPopup = !this.displayChatPopup;
    this.streamId = '';
    this.closeChatViewEmit.emit(true);
  }
  supportForUkraineClick() {
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId,
                                        "Top Nav", "Ukraine Support Link", "Click", "Ukraine Support Link Click", 0);
  }
  updateUnreadCount() {
    this.dashboardService.getUpdatedUnreadCount().subscribe(data=> {
      try {
        if(this.isObjetEmpty(data)) {
          return;
        }
        console.log(this.currentTab);
        this.currentTab && this.currentTab.header && data && data.tabName && 
        data.updatedUnread && this.currentTab.header.toUpperCase() === data.tabName.toUpperCase()
        ? this.currentTab.count = data.updatedUnread : this.currentTab.count = this.currentTab.count;
        this.cdr.detectChanges();
      } catch(e) {
        console.error(e);
      }
    });
  }

  isObjetEmpty(obj) {
    return Object.keys(obj).length === 0 ? true : false;

  }
  onsearchInFolderChange(folderName) {
  console.log(folderName);
  }
 
    filterSearchSingle(event) {
      let query = event.query;
      this.savedSeachSingle = this.filterSearch(query, this.arrSavedSearch);
  }
 
  filterSearch(query, savedSearch: any[]):any[] {
    let filtered : any[] = [];
    // in case of blank query show top N results
     if(query === '') {
      filtered = savedSearch.slice(0,this.smartSearchTopInitialkeywordsCount);
    }
   else {
for(let i = 0; i < savedSearch.length; i++) {
        let search = savedSearch[i];
        if(search.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(search);
        }
    }
   }
   this.arrSavedSearch.filter(arr=>arr.id === -1);
   filtered.push(this.arrSavedSearch.filter(arr=>arr.id === -1)[0]);
    return filtered;
}

onSearchBoxFocus(autocomplete) {
  // !autocomplete.value && autocomplete.handleDropdownClick();
  if($('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').length > 0) {
    $('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').css('display','inherit');
    return;
  }
  autocomplete.handleDropdownClick();
  setTimeout(() => {
    $('.search-text-container .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item')
    .last().css({"pointer-events": "none","display":"flex","justify-content":"right","opacity":"80%","color":"gray"});
  }, 0);
}
toggleSearchInput() {
  if (!this.isMobile) {
    // let searchedText = this.searchForm.value.searchText && this.searchForm.value.searchText.name?this.searchForm.value.searchText.name:this.searchForm.value.searchText;
    // if(searchedText) {
    //   this.inputFocused = true;
    //    }
    this.inputFocused = true;
    //    else {
    //   this.inputFocused = !this.inputFocused;
    // }
    this.txtPlaceholder = this.inputFocused? `Character Limit 0/${this.smartSearchCharactersLimit}`:`Search...`;
    $('.search-text-container .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item')
    .last().text(`Character Limit ${this.searchTextLength}/${this.smartSearchCharactersLimit}`);
    if($('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').length > 0) {
      $('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').css('display','inherit');
      $('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').css('left','0px');
      return;
    }
  }
}
clearItem(autocomplete: any) {
  autocomplete.value = '';  
  autocomplete.handleDropdownClick();  
}
closeSearchArea() {
  this.inputFocused = false;
  this.smartSearchCharactersLimit
  this.txtPlaceholder = this.inputFocused? 'Search (0/100 characters)':'Search...';
  this.searchTextLength = 0;
  this.searchForm.reset();
  const reqObj = { viewName: "Search", rollback: 'search'};
    this.inboxService.requestObj = reqObj;
    // C153176-4992: reset request since it is a new search object (rollback of last search)
    this.inboxService.resetRequest = true;
    // C153176-4540: clear searchBoxes at inbox service
    this.inboxService.searchBoxes = undefined;
  this.tabDataService.setsubjectOnTabClose("SEARCH");
  this.tabDataService.sendTabData("SEARCH", 0, 'resetSearch');
}
onAutoCompleteSearch(event:any) {
  let searchedData = event.target.value + event.key;
  if(searchedData.length > this.smartSearchCharactersLimit) {
    event.target.value = searchedData.substring(0,this.smartSearchCharactersLimit);
    searchedData = event.target.value;
    

  }
  this.searchTextLength = searchedData.length;
  // setTimeout(() => {
    $('.search-text-container .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item')
    .last().css({"pointer-events": "none","display":"flex","justify-content":"right","color":"gray"});
  $('.search-text-container .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item')
    .last().text(`Character Limit ${this.searchTextLength}/${this.smartSearchCharactersLimit}`);
  // }, 0);
  
  if($('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').length > 0) {
    $('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').css('display','inherit');
    $('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').css('left','0px');
    return;
  }
}
onAutoCompleteKeyUp(event:any) {
  // if(event.target.value.length > this.smartSearchCharactersLimit) {
  //   event.target.value = event.target.value.substring(0,this.smartSearchCharactersLimit);
  // }
  this.searchTextLength = event.target.value.length;
  setTimeout(() => {
    $('.search-text-container .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item')
    .last().css({"pointer-events": "none","display":"flex","justify-content":"right","color":"gray"});
  $('.search-text-container .p-autocomplete-panel .p-autocomplete-items .p-autocomplete-list-item')
    .last().text(`Character Limit ${this.searchTextLength}/${this.smartSearchCharactersLimit}`);
  }, 0);
  
  if($('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').length > 0) {
    $('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').css('display','inherit');
    $('.override-newmessage-autocomplete .ng-trigger-overlayAnimation').css('left','0px');
    return;
  }
}


  taskizeAuthentication() {
    this.windowHandle = this.taskizeService.createOauthWindow(this.loginUserInfoData);
    this.taskizeService.closeOauthWindow(this.windowHandle)
  }
  addSearchLimitText(arrSavedSearch) {
    let isSearchTextLimitExists = arrSavedSearch.some(data=>data.id === -1);
    if(isSearchTextLimitExists) {
      return;
    }
    arrSavedSearch.push({name:`Character Limit (${this.searchTextLength}/100)`,id:-1})
  }

}

