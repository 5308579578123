import { Component, OnInit, ViewChild, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { OverlayPanel } from "primeng//overlaypanel";
import { CheckboxListComponent } from '../../common/component/checkbox-list/checkbox-list.component';
import { CheckboxListInputComponent } from '../../common/component/checkbox-list-input/checkbox-list-input.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserDataService } from '../../services/user-data.service';
import { MessageService } from 'primeng/api';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';


@Component({
  selector: 'app-dashboard-setting',
  templateUrl: './dashboard-setting.component.html',
  styleUrls: ['./dashboard-setting.component.scss'],
  providers: [MessageService]
})
export class DashboardSettingComponent implements OnInit {
  groupNames: any[] = [];
  attachmentsOptions: any = [];
  display: boolean = true;
  requestTypeMap;
  requestTypes: any[] = [];
  assignMenu: any;
  saveSuccess: boolean = false;
  errMsgFlag: boolean = false;
  errMsg: String = '';
  @ViewChild('requestTypes', { static: true }) checkboxlistcomp: CheckboxListComponent;
  @ViewChild('ownerTypes', { static: true }) checkboxlistowner: CheckboxListComponent;
  @ViewChild('heatmap', { static: true }) checkboxheatmap: CheckboxListComponent;
  @ViewChild('messages', { static: true }) checkboxmessage: CheckboxListComponent;
  @ViewChild('myViews', { static: true }) checkboxmyview: CheckboxListComponent;
  @Output() onCloseModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloseDash: EventEmitter<any> = new EventEmitter<any>();

  messagesGroups: any = [
    {
      name: "Inbox",
      value: "Inbox",
      label: "Inbox",
      binary: false
    },
    {
      name: "Resolved",
      value: "Resolved",
      label: "Resolved",
      binary: false
    },
    {
      name: "Outbox",
      value: "Sent",
      label: "Sent",
      binary: false
    },
    {
      name: "Pending Approval",
      value: "Pending Approval",
      label: "Pending Approval",
      binary: false
    },
    {
      name: "Potential Escalation",
      value: "Potential Escalation",
      label: "Potential Escalation",
      binary: false
    },
    {
      name: "Snoozed",
      value: "Snoozed",
      label: "Snoozed",
      binary: false
    },
    {
      name: "Assigned To Me",
      value: "Assigned To Me",
      label: "Assigned To Me",
      binary: false
    },
    {
      name: "Unassigned",
      value: "Unassigned",
      label: "Unassigned",
      binary: false
    },    
    {
      name: "Non Inquiries",
      value: "Non Inquiries",
      label: "Non Inquiries",
      binary: false
    },
    {
      name: "Auto Assign FYI",
      value: "Auto Assign FYI",
      label: "Auto Assign FYI",
      binary: false
    }
  ]
  heatMapJson: any = [
    {
      name: "Platinum",
      value: "Platinum",
      label: "Platinum",
      categoryType: "system",
      binary: true,
      checkedStatus: true
    },
    {
      name: "Priority",
      value: "Priority",
      label: "Priority",
      categoryType: "system",
      binary: true,
      checkedStatus: true
    },
    {
      name: "Client",
      value: "Client",
      label: "Client",
      categoryType: "system",
      binary: false,
      checkedStatus: true

    }
  ]
  myViewJson: any = [];
  tempArray: any = [];
  assignedOwner: any = [];
  selectedId: any = 0;
  selBoxes: any = [];
  selectedGroup: any = [];
  selectedGroupName: any = [];
  selectedMsg: any = [];
  selRequest: any = [];
  selViews: any = [];
  groupMain: any = [];
  currentGroup: String;
  selectedOwner: any = [];
  requestTypeData: any = [];
  heatmapData: any = [];
  assignedOwnerData: any = [];
  checked = new Set();
  tempOwnerlist: any = [];
  tempMessage: any = [];
  tagLimits: any = [];
  counter: any = 0;
  counterFlag: boolean = false
  currentRequest: any = [];
  currentHeat: any = [];
  currentOwner: any = [];
  checkselection: any;
  latestGroupName: any;
  requestChecked = new Set();
  requestGrpChecked = new Set(); /*C153176-4740  issue1*/
  chekedOwner = new Set();
  chekedGrpOwner = new Set(); /*C153176-4740  issue1*/
  loginUserInfo: any;
  setDefaultGroup: boolean = false;
  viewinfo: any;
  selectedGrp: any;
  heatCounter: any = 0;
  heatFlag: boolean = false;
  selectedHeat: any = [];
  @ViewChildren(CheckboxListComponent) checkboxListComponents: QueryList<CheckboxListComponent>
  @ViewChildren(CheckboxListInputComponent) CheckboxListInputComponent: QueryList<CheckboxListInputComponent>
  error: any = { isError: false, errorMessage: '' };
  public advSearchForm: FormGroup;

  constructor(private dashboardService: DashboardService, private formBuilder: FormBuilder, private userDataService: UserDataService, private messageService: MessageService) {
    this.advSearchForm = formBuilder.group({
      messages: this.buildMessages(),
      myViews: this.buildMyViews()
    });
    let groupNameTmp = [{ label: "Select All", value: "Select All" }];
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {      
      this.getUserGroups(loginUserInfo);
      loginUserInfo.myGroups.forEach(myGroup => {
        groupNameTmp.push({ label: myGroup.groupName, value: myGroup.groupName });
      });
    });
    this.groupNames = groupNameTmp;    
  }

  ngOnInit() {   
    //calling http request once only     
    this.getGroupData();
  }

  getGroupData() {
    let selDrop: any = [];
    let tempMessage = [];
    let heatMapData = [];

    this.dashboardService.getDashBoardSettingData().subscribe(viewInfo => {
      if (viewInfo != undefined) {
        if (viewInfo.assignedGroups != null) {
          this.requestTypeData = viewInfo.assignedGroups;
          this.assignedOwnerData = viewInfo.assignedGroups;
        }        
        this.heatmapData = viewInfo.heatMap;
        if (viewInfo.heatMap !== null) {
          this.heatCounter = this.heatCounter + viewInfo.heatMap.length;
          viewInfo.heatMap.forEach(heatmap => {
            this.tagLimits.push(heatmap.tagLimit);
            this.selectedHeat.push({ name: heatmap.clientCategoryName, categoryType: heatmap.categoryType,
              tagLimit: heatmap.tagLimit, groupName: heatmap.groupName, checkedStatus: true, binary: true });
            this.heatChecked.add(heatmap.clientCategoryName);
          });
        }
        //C153176-4587 for newly onboarded user doesn't have any mailbox selected
        //C153176-4903 condition added if user has only view selected then also no need to load default mail box stats
        try {
          if (viewInfo.mailBoxStats && viewInfo.mailBoxStats.messages.length === 0 && viewInfo.mailBoxStats.myViews.length === 0) {
            viewInfo.mailBoxStats.messages = ["Inbox", "Resolved", "Pending Approval", "Potential Escalation"];
          }
        } catch (e) {
          console.error('QMA 2.0 exception while getGroupData() ', e);
          viewInfo.mailBoxStats.messages = ["Inbox", "Resolved", "Pending Approval", "Potential Escalation"];
        }
       
        if (viewInfo.mailBoxStats && viewInfo.mailBoxStats.messages) {                    
          for (var j = 0; j < viewInfo.mailBoxStats.messages.length; j++) {
            this.counter = this.counter + 1;
            let index = this.messagesGroups.findIndex((item:any) => item.name == viewInfo.mailBoxStats.messages[j]);           
            this.messagesGroups[index].binary = true;
            this.messagesGroups[index].checkedStatus = true;
          }
        }        
        if (viewInfo.mailBoxStats && viewInfo.mailBoxStats.myViews) {          
          viewInfo.mailBoxStats.myViews.forEach(myGroup => {
            for (var i = 0; i < this.myViewJson.length; i++) {
              if (this.myViewJson[i].value == myGroup) {
                /* C153176-4587 - When created view deleted from MyView then still dashboardsetting has that view in , so counter 
                code move inside the condition so it will check view is present or not in loginuserinfo */
                this.counter = this.counter + 1;
                this.myViewJson[i].binary = true;
                this.myViewJson[i].checkedStatus = true;
              }
            }
          });
        }
        if (viewInfo.assignedGroups != null && viewInfo.assignedGroups.length > 0) {
          if (viewInfo.assignedGroups.length === this.groupNames.length - 1) {
            this.groupNames[0].binary = true;
            this.groupNames[0].checkedStatus = true;
          }
          viewInfo.assignedGroups.forEach(myGroup => {
            for (var i = 0; i < this.groupNames.length; i++) {
              if (this.groupNames[i].value == myGroup.groupName) {
                this.groupNames[i].binary = true;
                this.groupNames[i].checkedStatus = true;
                selDrop.push({
                  label: myGroup.groupName,
                  value: myGroup.groupName
                })
              }
            }
          });
          this.selectedGroup = selDrop;
        } else {
          selDrop.push({ label: "Select Group From Assigned Groups", value: "Select Group From Assigned Groups" });
          this.selectedGroup = selDrop;
          this.selectedGrp = this.selectedGroup[0].value;
        }

        if (this.selectedGroup.length > 0 && this.selectedGroup[0].value != "Select Group From Assigned Groups") {
          this.displayRequestType({ value: this.selectedGroup[0].value });
          this.displayHeatMap(this.selectedGroup[0]);
          this.requestTypes = this.getRequestTypes(this.groupNames[0].value);
        }
        this.defaultSelectedHeat(viewInfo.heatMap);        
      }      
    });
  }
  getSelectedId(groupName, loginuserInfo): any {
    loginuserInfo.myGroups.forEach(myGroup => {
      if (myGroup.requestTypes && myGroup.groupName === groupName) {
        this.selectedId = myGroup.id;
      }
    });
    return this.selectedId;
  }
  displayHeatMap(selectedGroup) {
    this.heatMapJson = [];
    let totalHeat: any = [];
    let currentHeat;
    totalHeat.push({
      categoryName: "Platinum", "categoryType": "system"
    },
    { categoryName: "Priority", "categoryType": "system" },
    { categoryName: "Client", "categoryType": "system"
  });
    currentHeat = this.getHeatMaps(selectedGroup.value);
    currentHeat.forEach(ele => {
      totalHeat.push({ categoryName: ele.categoryName });
    })
    totalHeat.forEach(categoryName => {
      this.heatMapJson.push({
        name: categoryName.categoryName,
        label: categoryName.categoryName,
        value: categoryName.categoryName,
        binary: categoryName.categoryType == "system" ? true : false,
        checkedStatus: categoryName.categoryType == "system" ? true : false,
        groupName: categoryName.categoryType == "system" ? null : selectedGroup.value,
        categoryType: categoryName.categoryType == "system" ? "system" : "custom"
      });
    });
    this.selectedHeat.forEach(item => {
      if (item.groupName == selectedGroup.value) {
        let index = currentHeat.map(function (x) { return x.categoryName; }).indexOf(item.name);
        if (index != -1) {
          index = index + 3;
          this.heatMapJson[index].binary = true;
          this.heatMapJson[index].checkedStatus = true;
          this.heatMapJson[index].tagLimit = item.tagLimit;
        }
      } else {
        if (item.categoryType === "system") {
          let ind = this.selectedHeat.map(function (item) { return item.name }).indexOf(item.name);
          this.heatMapJson[ind].tagLimit = item.tagLimit;
        }
      }
    })
    this.checkboxheatmap.items = this.heatMapJson;
    this.checkboxheatmap.assignCopy();
  }
  defaultSelectedHeat(viewInfo) {
    if (viewInfo && viewInfo.length > 0) {
      viewInfo.forEach(heatmap => {
        for (var i = 0; i < this.heatMapJson.length; i++) {
          if (this.heatMapJson[i].value == heatmap.clientCategoryName) {
            this.heatMapJson[i].binary = true;
            this.heatMapJson[i].checkedStatus = true;
            this.heatMapJson[i].tagLimit = heatmap.tagLimit;
          }
        }
      });
      this.checkboxheatmap.items = this.heatMapJson;
      this.checkboxheatmap.assignCopy();
    }
  }
  getHeatMaps(groupName): any[] {
    let heatMaps = [];
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      loginUserInfo.myGroups.forEach(myGroup => {
        if (myGroup.groupName === groupName && myGroup.customClientCategory != null) {
          heatMaps = myGroup.customClientCategory;
        }
      });
    });
    return heatMaps;
  }
  getSelectedHeatMap(groupName: any) {
    let heatMaps = [];
    this.heatmapData.forEach(item => {
      if (item.groupName === groupName) {
        heatMaps.push(item.clientCategoryName);
      }
    })
    return heatMaps;
  }
  heatChecked = new Set();
  getSelectedHeat(selectedHeat: any) {    
    if (selectedHeat.checkedStatus && this.heatCounter < 6) {
      this.heatCounter = this.heatCounter + 1;      
      const inserted = this.heatChecked.has(selectedHeat.name);
      this.heatChecked.add(selectedHeat.name);
      if (!inserted && this.heatCounter < 6) {
        this.selectedHeat.push({ name: selectedHeat.name, categoryType: "custom", tagLimit: selectedHeat.tagLimit, groupName: selectedHeat.groupName, binary: true, checkedStatus: true })
        this.currentHeat.push({ name: selectedHeat.name, categoryType: "custom", groupName: selectedHeat.groupName });
      }
    } else {
      if(selectedHeat.name !== "Platinum" && selectedHeat.name !== "Priority" && selectedHeat.name !== "Client") {
        this.heatCounter = this.heatCounter - 1;
        let ind = this.currentHeat.map(function (x) { return x.name; }).indexOf(selectedHeat.name);
        if (ind !== -1) this.currentHeat.splice(ind, 1);
        let indx = this.selectedHeat.map(function (x) { return x.name; }).indexOf(selectedHeat.name);
        this.selectedHeat.splice(indx, 1);
        this.heatChecked.delete(selectedHeat.name);
      }else{
        this.checkboxheatmap.resetCheckBox(selectedHeat);
      }
    }
    if (this.heatCounter > 5) {
      this.checkselection = selectedHeat
      selectedHeat.checkedStatus = false;
      selectedHeat.binary = false;
      this.heatFlag = true;
      this.checkCountHeatMap();
    }
  }
  checkCountHeatMap() {
    this.checkboxheatmap.resetCheckBox(this.checkselection);
    this.heatCounter = this.heatCounter - 1;
    setTimeout(() => {
      this.heatFlag = false;
    }, 4000);
  }
  displayRequestType(selectedGroup) {
    this.requestTypes = [];
    this.assignedOwner = [];
    this.currentGroup = "";
    let totalreq = [];
    this.tempMessage = [];
    this.tempOwnerlist = [];
    this.tagLimits = [];
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.loginUserInfo = loginUserInfo;
      loginUserInfo.myGroups.forEach(myGroup => {
        if (selectedGroup != null && myGroup.requestTypes && myGroup.groupName === selectedGroup.value) {
          this.selectedId = myGroup.id;
          this.currentGroup = selectedGroup.value
          for (let i = 0; i < myGroup.requestTypes.length; i++) {
            this.requestTypes.push({
              name: myGroup.requestTypes[i],
              value: myGroup.requestTypes[i],
              label: myGroup.requestTypes[i],
              binary: false,
              groupName: myGroup.groupName
            });
          }
        }
      });
      for (var idx = 0; idx < loginUserInfo.myGroups.length; idx++) {
        if (this.selectedId == loginUserInfo.context[idx].groupId) {
          for (var ind = 0; ind < loginUserInfo.context[idx].assignOwnerList.length; ind++) {
            this.assignedOwner.push({
              name: loginUserInfo.context[idx].assignOwnerList[ind].text,
              value: loginUserInfo.context[idx].assignOwnerList[ind].text,
              label: loginUserInfo.context[idx].assignOwnerList[ind].text,
              binary: false,
              groupName: this.currentGroup
            });
          }
        }
      }
      this.tempOwnerlist = this.getSelectedOwnerList(this.currentGroup);
      /* C153176-4740 to intact selected owner*/
      if(this.currentOwner.length > 0){
        for (i = 0; i < this.currentOwner.length; i++) {
          if(this.currentOwner[i].groupName == this.currentGroup){
            let indx = this.assignedOwner.map(function (x) { return x.name; }).indexOf(this.currentOwner[i].name);
            if (indx !== -1) this.assignedOwner[indx].binary = true; 
          }          
        }
      }
      if(this.tempOwnerlist.length > 0){
        for (var i = 0; i < this.tempOwnerlist.length; i++) {
          let indx = this.assignedOwner.map(function (x) { return x.name; }).indexOf(this.tempOwnerlist[i]);
          if (indx !== -1){
            this.assignedOwner[indx].binary = true;
            this.selectedOwner.push({ name: this.assignedOwner[indx].name, label: this.assignedOwner[indx].label, value: this.assignedOwner[indx].value, groupName:this.currentGroup, checkedStatus: true });
          } 
        }
      }
      /* C153176-4740 to intact selected owner*/
      this.tempMessage = this.getSelectedRequest(this.currentGroup);
      totalreq = this.getRequestTypes(this.currentGroup);
      if(this.currentRequest.length > 0){
        for(i=0; i<this.currentRequest.length; i++){
          if(this.currentRequest[i].groupName == this.currentGroup){
            let indx = totalreq.indexOf(this.currentRequest[i].name);
            if(indx != -1) this.requestTypes[indx].binary = true; 
          }                   
        }
      }

      if(this.tempMessage.length > 0){
        for(i=0; i<this.tempMessage.length; i++){
          let indx = totalreq.indexOf(this.tempMessage[i]);
          if(indx != -1) this.requestTypes[indx].binary = true; 
          this.selRequest.push({ name: this.requestTypes[indx].name, label: this.requestTypes[indx].label, value: this.requestTypes[indx].value, groupName:this.currentGroup, checkedStatus: true });         
        }
      }      
    });   
    this.checkboxlistcomp.items = this.requestTypes;
    this.checkboxlistcomp.assignCopy();
    this.checkboxlistowner.items = this.assignedOwner;
    this.checkboxlistowner.assignCopy();    
  }

  getRequestTypes(groupName): any[] {
    let requestTypes = [];
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      loginUserInfo.myGroups.forEach(myGroup => {
        if (myGroup.groupName === groupName)
          requestTypes = myGroup.requestTypes;
      });
    });
    return requestTypes;
  }
  getSelectedRequest(groupName): any[] {
    let requestTypes = [];
    this.requestTypeData.forEach(myGroup => {
      if (myGroup.groupName === groupName)
        requestTypes = myGroup.requestTypes;
    });
    return requestTypes;
  }
  getSelectedOwnerList(groupName): any[] {
    let requestTypes = [];
    this.assignedOwnerData.forEach(myGroup => {
      if (myGroup.groupName === groupName)
        requestTypes = myGroup.assignedOwner;
    });
    return requestTypes;
  }

  getSelectedMessage(selectedMsg: any) {
    if (selectedMsg.checkedStatus) {
      this.counter = this.counter + 1;
    } else {
      this.counter = this.counter - 1;
    }
    if (this.counter > 4) {
      this.checkselection = selectedMsg
      this.counterFlag = true;
      selectedMsg.checkedStatus = false;
      selectedMsg.binary = false;
      this.checkCountMailbox();
    } else {
      this.selectedMsg.push({ name: selectedMsg.name, label: selectedMsg.label, value: selectedMsg.value, binary: true, checkedStatus: true, group: 'Message' });
    }
  }

  getMyViews(selectedView) {
    if (selectedView.checkedStatus) {
      this.counter = this.counter + 1;
    } else {
      this.counter = this.counter - 1;
    }
    if (this.counter > 4) {
      this.checkselection = selectedView
      selectedView.checkedStatus = false;
      selectedView.binary = false;
      this.counterFlag = true;
      this.checkCountMailbox();
    }
    this.selViews.push({ name: selectedView.name, label: selectedView.label, value: selectedView.value, binary: true, checkedStatus: true, group: 'Views' });
  }

  getSelectedGroup(selectedBox: any) {
    if (this.selectedGroup.length > 0 && this.selectedGroup[0].value == "Select Group From Assigned Groups") {
      this.selectedGroup.splice(0, 1);
    }
    if (selectedBox.checkedStatus) {
      const inserted = this.checked.has(selectedBox.value);
      this.checked.add(selectedBox.value);      
      if (selectedBox.value == "Select All") {        
        for (var j = 0; j < this.selectedGroup.length; j++) {                
          this.checked.delete(this.selectedGroup[j].value);
        }
        this.selectedGroup = [];
        for (var i = 0; i < this.groupNames.length; i++) {
          const inserted = this.checked.has(this.groupNames[i].value);
          this.checked.add(this.groupNames[i].value);
          this.groupNames[i].binary = true;
          this.groupNames[i].checkedStatus = true;  /* C153176-4740 Select All issue fixed*/       
          if (!inserted) {
            if (this.groupNames[i].value != "Select All")
              this.selectedGroup.push({ label: this.groupNames[i].label, value: this.groupNames[i].value, binary: true, checkedStatus: true });
          }
        }
      }      
      else if (!inserted && selectedBox.value != "Select All") {
        /* C153176-4740 Select All issue fixed*/
        let index = this.groupNames.findIndex((item:any) => item.value == selectedBox.value);
        this.groupNames[index].binary = true;
        this.selectedGroup.push({ label: selectedBox.label, value: selectedBox.value, binary: true, checkedStatus: true });
      }
      if (this.setDefaultGroup) {
        if (this.selectedGroup.length > 0) {
          this.setDefaultGroup = false;
          this.displayRequestType({ value: this.selectedGroup[0].value });
        }
      }
      //this.getGroupsHeatMaps(selectedBox.value);
      /* C153176-4740 Select All issue fixed*/
      if ((this.groupNames.length - 1) === this.selectedGroup.length) {
        this.groupNames[0].binary = true;
        this.groupNames[0].checkedStatus = true;
      }
    } else {
      if (selectedBox.value == "Select All") {
        this.selectedGroup = [];
        this.selectedGroup.push({ value: "Select Group From Assigned Groups", label: "Select Group From Assigned Groups" })
        this.deselectAll(this.groupNames, this.checked);
      } else {
        /* C153176-4740 Select All issue fixed*/
        let index = this.groupNames.findIndex((item:any) => item.value == selectedBox.value);
        this.groupNames[index].binary = false;
        const ind = this.selectedGroup.map(function (x) { return x.value; }).indexOf(selectedBox.value);
        this.selectedGroup.splice(ind, 1);
        this.checked.delete(selectedBox.value);        
        if (this.selectedGroup.length === 0) {
          this.setDefaultGroup = true;
          this.selectedGroup = [];
          this.selectedGroup.push({ value: "Select Group From Assigned Groups", label: "Select Group From Assigned Groups" })
          this.requestTypes = [];
          this.assignedOwner = [];
          this.checkboxlistcomp.items = this.requestTypes;
          this.checkboxlistcomp.assignCopy();
          this.checkboxlistowner.items = this.assignedOwner;
          this.checkboxlistowner.assignCopy();          
        }        
      }
      if (this.groupNames.length !== this.selectedGroup.length) {
        this.checked.delete("Select All"); /* C153176-4740 Select All issue fixed*/
        this.groupNames[0].binary = false;
        this.groupNames[0].checkedStatus = false; /* C153176-4740 Select All issue fixed*/
      }
    }
    if (this.selectedGroup.length === 1) {
      this.selectedGrp = this.selectedGroup[0].value;
      this.displayRequestType({ value: this.selectedGroup[0].value });
    }
  }  
  getSelectedRequestType(selectedBox: any){    
    if(selectedBox.checkedStatus){
    const inserted = this.requestChecked.has(selectedBox.value);
    this.requestChecked.add(selectedBox.value);
    /*C153176-4740  issue1*/
    const insertedGroup = this.requestGrpChecked.has(selectedBox.groupName);
    this.requestGrpChecked.add(selectedBox.groupName);
    if (!inserted || (inserted && !insertedGroup)) {
      this.selRequest.push({ name: selectedBox.name, label: selectedBox.label, value: selectedBox.value, groupName:selectedBox.groupName, binary: true, checkedStatus: true });
      this.currentRequest.push({name: selectedBox.name, groupName: selectedBox.groupName});
      /* C153176-4740 to intact selected request type*/
      // this.selRequest.forEach(item => {
      //   this.tempMessage.push(item.name);        
      // });
    }
    }else {        
      let ind = this.selRequest.map(function (x) { return x.value; }).indexOf(selectedBox.value);
      let indx = this.tempMessage.indexOf(selectedBox.value)
      //let index = this.currentRequest.indexOf(selectedBox.value)
      let index = this.currentRequest.findIndex((item:any) => (item.groupName == selectedBox.groupName && item.name == selectedBox.value));
      this.selRequest.splice(ind, 1);
      if(indx != -1) this.tempMessage.splice(indx, 1); /* C153176-4740 to intact selected request type*/
      if(index != -1) this.currentRequest.splice(index, 1); /* C153176-4740 to intact selected request type*/
      this.requestChecked.delete(selectedBox.value);
      this.requestChecked.delete(selectedBox.groupName); /*C153176-4740  issue1*/
    } 
  }  
  getSelectedOwner(selectedOwner: any) {
    if(selectedOwner.checkedStatus){
      const inserted = this.chekedOwner.has(selectedOwner.value);
      this.chekedOwner.add(selectedOwner.value);
      /*C153176-4740  issue1*/
      const insertedGrp = this.chekedGrpOwner.has(selectedOwner.groupName);
      this.chekedGrpOwner.add(selectedOwner.groupName); 
      if (!inserted || (inserted && !insertedGrp)) {
        this.selectedOwner.push({ name: selectedOwner.name, label: selectedOwner.label, value: selectedOwner.value, groupName:selectedOwner.groupName, binary: true, checkedStatus: true });
        this.currentOwner.push({name: selectedOwner.name, groupName: selectedOwner.groupName});
        /* C153176-4740 to intact selected request type*/
        // this.selectedOwner.forEach(item => {
        //   this.tempOwnerlist.push(item.name);          
        // });
      }
    }else {        
      let ind = this.selectedOwner.map(function (x) { return x.value; }).indexOf(selectedOwner.value);
      let indx = this.tempOwnerlist.indexOf(selectedOwner.value);
      //let index = this.currentOwner.indexOf(selectedOwner.value);
      /* C153176-4740 to intact selected request type*/
      let index = this.currentOwner.findIndex((item:any) => (item.groupName == selectedOwner.groupName && item.name == selectedOwner.value));
      this.selectedOwner.splice(ind, 1);
      if(indx != -1) this.tempOwnerlist.splice(indx, 1); /* C153176-4740 to intact selected request type*/
      if(index != -1) this.currentOwner.splice(index, 1); /* C153176-4740 to intact selected request type*/
      this.chekedOwner.delete(selectedOwner.value);
      this.chekedGrpOwner.delete(selectedOwner.groupName); /*C153176-4740  issue1*/
    }
  }
  getAssignOwner(loginUserInfo, selectedId, groupName): any {
    let assignedOwner = [];
    for (var idx = 0; idx < loginUserInfo.myGroups.length; idx++) {
      if (selectedId == loginUserInfo.context[idx].groupId) {
        for (var ind = 0; ind < loginUserInfo.context[idx].assignOwnerList.length; ind++) {
          assignedOwner.push({
            name: loginUserInfo.context[idx].assignOwnerList[ind].text,
            value: loginUserInfo.context[idx].assignOwnerList[ind].text,
            label: loginUserInfo.context[idx].assignOwnerList[ind].text,
            binary: false,
            groupName: groupName
          });
        }
      }
    }
    return assignedOwner
  }
  selectAll(selectedOwner, chekedOwner, assignedOwner, tempOwnerlist, currentOwner) {
    for (var j = 0; j < selectedOwner.length; j++) {
      chekedOwner.delete(selectedOwner[j].value);
    }
    selectedOwner = [];
    for (var i = 0; i < assignedOwner.length; i++) {
      const inserted = chekedOwner.has(assignedOwner[i].value);
      chekedOwner.add(assignedOwner[i].value);
      assignedOwner[i].binary = true;
      if (!inserted) {
        if (assignedOwner[i].value != "Select All")
          selectedOwner.push({ name: assignedOwner[i].name, label: assignedOwner[i].label, value: assignedOwner[i].value, groupName: assignedOwner[i].groupName, binary: true, checkedStatus: true });
        currentOwner.push({ name: assignedOwner[i].name, groupName: assignedOwner[i].groupName });
        selectedOwner.forEach(item => {
          tempOwnerlist.push(item.name);
        });
      }
    }
  }
  deselectAll(selectList, checkedData) {
    for (var i = 0; i < selectList.length; i++) {
      selectList[i + 1].binary = false;
      checkedData.delete(selectList[i].value);
    }
  }
  checkCountMailbox() {
    if (this.checkselection.group === "View") {
      this.checkboxmyview.resetCheckBox(this.checkselection);
    } else {
      this.checkboxmessage.resetCheckBox(this.checkselection);
    }
    this.counter = this.counter - 1;
    setTimeout(() => {
      this.counterFlag = false;
    }, 4000);
  }
  buildMessages() {
    const arr = this.messagesGroups.map(messageItem => {
      return this.formBuilder.control(messageItem.binary);
    });
    return this.formBuilder.array(arr);
  }

  buildMyViews() {
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      if (loginUserInfo.userViews !== null) {
        loginUserInfo.userViews.forEach(myViews => {
          this.myViewJson.push({
            name: myViews.name,
            value: myViews.name,
            label: myViews.name,
            binary: false,
            group: 'View'
          });
        })
      }
    })
    const arr = this.myViewJson.map(myviewItem => {
      return this.formBuilder.control(myviewItem.binary);
    });
    return this.formBuilder.array(arr);
  };

  showDashBoardSetting(event, overlaypanel: OverlayPanel): void {
    this.attachmentsOptions = [];
    this.attachmentsOptions.push({ name: "Dashboard Setting" }, { name: "Set as Default" });
    overlaypanel.toggle(event);
  }
  /* For Dashboard Popup by OM */
  showDashBoardPanel(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }

  openAdminDialog() {
    this.display = true;
  }
  hideModal() {
    this.display = false;
    setTimeout(() => {
      this.onCloseModal.emit(this.display);
    }, 3100);

  }
  closeModal() {
    this.counterFlag = false;
    this.counter = this.counter - 1;
    if (this.checkselection.group === "View") {
      this.checkboxmyview.resetCheckBox(this.checkselection);
    } else {
      this.checkboxmessage.resetCheckBox(this.checkselection);
    }
  }
  closeErrModal() {
    this.errMsgFlag = false;
  }
  clearSearch() {
    this.checkboxListComponents.forEach((comp) => {
      comp.resetCheckList();
    });
    this.error.isError = false;
  }
  resetMailbox() {
    this.counter = 0;
    this.checkboxListComponents.forEach((comp) => {
      comp.resetCheckList();
    });
    this.myViewJson.forEach(view => {
      view.binary = false;
      view.checkedStatus = false;
    });
    this.messagesGroups.forEach(myGroup => {
      myGroup.binary = false;
      myGroup.checkedStatus = false;
    });
    this.groupNames.forEach(group => {
      if (this.checked.has(group.value)) {
        this.checked.delete(group.value);
      }
      group.binary = false;
      group.checkedStatus = false;
    });
    this.requestTypes.forEach(req => {
      if (this.requestChecked.has(req)) {
        this.requestChecked.delete(req);
      }
      //req.binary = false;
      //req.checkedStatus = false;
    });
    this.assignedOwner.forEach(owner => {
      if (this.chekedOwner.has(owner.value)) {
        this.chekedOwner.delete(owner.value);
      }
      owner.binary = false;
      owner.checkedStatus = false;
    });
    this.assignedOwner = [];
    this.requestTypes = [];
    this.currentRequest = [];
    this.currentOwner = [];
    this.selectedHeat = [];
    this.checkboxlistcomp.items = this.requestTypes;
    this.checkboxlistcomp.assignCopy();
    this.checkboxlistowner.items = this.assignedOwner;
    this.checkboxlistowner.assignCopy();
    this.tempOwnerlist = [];
    this.selectedGrp = this.selectedGroup[0].value;
    this.heatMapJson.forEach(heat => {
      heat.binary = false;
      heat.checkedStatus = false;
    });
    this.tagLimits = [];
  }
  resetModal() {
    this.resetMailbox();
    this.getGroupData();
  }
  getUserGroups(_loginUserInfo: any) {
    this.assignMenu = [];
    for (var i = 0; i < _loginUserInfo.context.length; i++) {
      var groupOwnerList = [], uniqueData = [], assignMenuAvailable = [];
      for (var item = 0; item < _loginUserInfo.context[i].assignOwnerList.length; item++) {
        if (_loginUserInfo.context[i].assignOwnerList[item].active) {
          groupOwnerList.push(_loginUserInfo.context[i].assignOwnerList[item]);
        }
      }

      for (var idx = 0; idx < _loginUserInfo.myGroups.length; idx++) {
        if (_loginUserInfo.myGroups[idx].id == _loginUserInfo.context[i].groupId) {
          this.assignMenu.push({
            groupOwner: groupOwnerList,
            groupName: _loginUserInfo.myGroups[idx].groupName,
            id: _loginUserInfo.myGroups[idx].id
          })
        }
      }
    }
  }

  finalReq(groupmain) {
    const seen = new Set();
    const seenReq = new Set();
    let requestArr = [];
    groupmain.forEach(item => {
      const duplicate = seen.has(item.groupName);
      seen.add(item.groupName);
      if (item.groupName == this.latestGroupName) {
        const duplicateReq = seenReq.has(item.requestTypes);
        seenReq.add(item.requestTypes);
        if (!duplicate) {
          if (!duplicateReq) {
            requestArr = [item.requestTypes]
          }
        } else {
          if (!duplicateReq) {
            requestArr.push(item.requestTypes);
          }
        }
      }
    })
    return requestArr;
  }

  finalOwner(ownerlist) {
    const seen = new Set();
    const seenOwn = new Set();
    let ownerArr = [];
    ownerlist.forEach(item => {
      const duplicate = seen.has(item.groupName);
      seen.add(item.groupName);
      if (item.groupName == this.latestGroupName) {
        const duplicateOwn = seenOwn.has(item.assignOwnerList);
        seenOwn.add(item.assignOwnerList);
        if (!duplicate) {
          if (!duplicateOwn) {
            ownerArr = [item.assignOwnerList]
          }
        } else {
          if (!duplicateOwn) {
            ownerArr.push(item.assignOwnerList);
          }
        }
      }
    })
    return ownerArr;
  }
  changeInputs($event) {
    this.tagLimits = $event;
    if (this.selectedHeat.length === 0) {
      this.selectedHeat.push(
        { name: "Platinum", "categoryType": "system", checkedStatus: true, binary: true },
        { name: "Priority", "categoryType": "system", checkedStatus: true, binary: true  },
        { name: "Client", "categoryType": "system", checkedStatus: true, binary: true });
    }    
    const index = this.heatMapJson.findIndex((item: any) => item.name === this.tagLimits.tagName);
    const ind = this.selectedHeat.findIndex((item: any) => item.name === this.tagLimits.tagName);
    this.heatMapJson[index].tagLimit = this.tagLimits.tagLimit;
    this.selectedHeat[ind].tagLimit = this.tagLimits.tagLimit;

  }
  refreshAllCharts() {
    this.dashboardService.getOpenInquiryGroupData();
    this.dashboardService.getInquiriesTrendCnts();
    this.dashboardService.getDashboardCountsService();
    this.dashboardService.getIntensityHeatMapCnts();
    this.dashboardService.getHomePageChartsData();
    this.dashboardService.getmailBoxStats();
  }

  postDashboarSettingData() {
    let postMessage = [];
    let tempView = [];
    let ownerArr = [];
    let ownerlist = [];
    let tempOwnerArr = [];
    let heatmap = [];
    let requestArr = [];
    this.tempMessage = [];
    this.requestTypes = [];
    const seenOwner = new Set();
    let totalreq = [];
    let reqGroupLenth = [];
    const groupSeen = new Set();
    this.selRequest.forEach(item => {
      const duplicateGroup = groupSeen.has(item.groupName);
      groupSeen.add(item.groupName);
      if (!duplicateGroup) {
        reqGroupLenth.push(item.groupName);
      }
    })
    if (this.selectedGroup.length > 0 && this.selectedGroup[0].value == "Select Group From Assigned Groups") {
      this.selectedGroup.splice(0, 1);
    }
    if (this.selectedGroup.length !== reqGroupLenth.length) {
      this.selectedGroup.forEach(group => {
        let index = this.selRequest.findIndex((item: any) => item.groupName == group.value);
        if (index === -1) {
          this.tempMessage = this.getSelectedRequest(group.value);
          totalreq = this.getRequestTypes(group.value);
          if (this.tempMessage.length > 0) {
            for (var i = 0; i < this.tempMessage.length; i++) {
              let indx = totalreq.indexOf(this.tempMessage[i]);
              this.requestTypes = this.getRequestTypes(group.value);
              if (indx != -1) {
                this.selRequest.push({ name: this.requestTypes[indx], label: this.requestTypes[indx], value: this.requestTypes[indx], groupName: group.value, checkedStatus: true });
              }
            }
          }
        }
        let ind = this.selectedOwner.findIndex((item: any) => item.groupName == group.value);
        this.tempOwnerlist = this.getSelectedOwnerList(group.value);
        if (ind === -1 && this.tempOwnerlist.length > 0) {
          let selectedId = this.getSelectedId(group.value, this.loginUserInfo);
          if (this.tempOwnerlist.length > 0) {
            this.assignedOwner = this.getAssignOwner(this.loginUserInfo, selectedId, group.value);
            for (var i = 0; i < this.tempOwnerlist.length; i++) {
              let indx = this.assignedOwner.map(function (x) { return x.name; }).indexOf(this.tempOwnerlist[i]);
              if (indx !== -1) {
                this.selectedOwner.push({ name: this.assignedOwner[indx].name, label: this.assignedOwner[indx].label, value: this.assignedOwner[indx].value, groupName: group.value, checkedStatus: true });
              }
            }
          }
        }

      })
    }
    this.messagesGroups.forEach(ele => {
      if ((ele.checkedStatus && ele.binary) || ele.checkedStatus) {
        postMessage.push(ele.name)
      }
    });
    this.myViewJson.forEach(ele => {
      if ((ele.checkedStatus && ele.binary) || ele.checkedStatus) {
        tempView.push(ele.name)
      }
    });
    this.heatMapJson = [];
    this.selectedHeat.forEach(item => {
      const isSelected = this.selectedGroup.findIndex((element: any) => element.value === item.groupName) !== -1;
      if (item.categoryType === "system") {
        this.heatMapJson.push({
          clientCategoryName: item.name,
          tagLimit: item.tagLimit,
          categoryType: item.categoryType,
          groupName: item.groupName,
          binary: item.binary,
          checkedStatus: item.checkedStatus
        });
      } else if (item.categoryType === "custom" && isSelected) {
        this.heatMapJson.push({
          clientCategoryName: item.name,
          tagLimit: item.tagLimit,
          categoryType: item.categoryType,
          groupName: item.groupName,
          binary: item.binary,
          checkedStatus: item.checkedStatus
        });
      }
    });
    this.heatMapJson.forEach(ele => {
      if ((ele.checkedStatus && ele.binary && ele.categoryType !== undefined) || (ele.checkedStatus && ele.categoryType != undefined)) {
        heatmap.push({ clientCategoryName: ele.clientCategoryName, tagLimit: ele.tagLimit,
                    categoryType: ele.categoryType, groupName: ele.groupName });
      }
    });
    this.selRequest.forEach(item => {
      this.groupMain.push({
        groupName: item.groupName,
        requestTypes: item.name
      });
    });
    this.selectedOwner.forEach(item => {
      ownerlist.push({
        groupName: item.groupName,
        assignOwnerList: item.name
      })
    });

    this.selectedGroup.forEach(group => {
      this.latestGroupName = group.value;
      let reqArr = this.finalReq(this.groupMain);
      let ownArr = this.finalOwner(ownerlist);
      tempOwnerArr.push({
        groupName: group.value,
        assignedOwner: ownArr.length > 0 ? ownArr : [],
        requestTypes: reqArr.length > 0 ? reqArr : []
      })
    })
    //}
    if(tempOwnerArr.length > 0){
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Dashboard Settings", "Dashboard Settings - Request type and owner", "Click", "Dashboard Settings - Request type and owner", 0);
    }
    if(heatmap.length > 0){
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Dashboard Settings", "Dashboard Settings - Heatmap", "Click", "Dashboard Settings - Heatmap", 0);
    }
    let postDataForDashboardSetting = {
      dashboardSettings: {
        mailBoxStats: {
          messages: postMessage,
          myViews: tempView,
        },
        assignedGroups: tempOwnerArr,
        heatMap: heatmap
      }
    }
    this.dashboardService.postDashboardSettingData(postDataForDashboardSetting).subscribe(response => {
      this.saveSuccess = true;
      // Update the localstorage with updates mailBoxStats
      if (postDataForDashboardSetting.dashboardSettings['mailBoxStats']['messages'].length > 0
        || postDataForDashboardSetting.dashboardSettings['mailBoxStats']['myViews'].length > 0) {
        console.log('Mail Box Stats from service: ', postDataForDashboardSetting.dashboardSettings['mailBoxStats']);
        localStorage.setItem('mailBoxStats', JSON.stringify(postDataForDashboardSetting.dashboardSettings['mailBoxStats']));
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Dashboard Settings", "Dashboard Settings - Mailbox statistic", "Click", "Dashboard Settings - Mailbox statistic selected", 0);
      } else {
        console.log('Mail Box Stats from constants: ', QmaConstant.MAIL_BOX_STATS);
        localStorage.setItem('mailBoxStats', JSON.stringify(QmaConstant.MAIL_BOX_STATS));
      }

      // Store the group names to local storage to show and hide group tags
      let assignedGroups = postDataForDashboardSetting.dashboardSettings['assignedGroups'];
      if (assignedGroups && assignedGroups.length > 0) {
        let groupNames = assignedGroups.map(assignGroup => assignGroup.groupName);
        localStorage.setItem("groupNames", JSON.stringify(groupNames));
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Dashboard Settings", "Dashboard Settings - Assigned groups", "Click", "Dashboard Settings - assigned groups", 0);
      } else {
        localStorage.removeItem("groupNames");
      }

      this.refreshAllCharts();
      this.messageService.add({ key: 'custom', summary: 'Success Confirmation', detail: 'Dashboard setting saved successfully !' });
      this.hideModal();
      this.dashboardService.passSaveFlag(this.saveSuccess);
      console.log("Result : ", response);
    },
      error => {
        this.errMsgFlag = true;
        this.errMsg = error.message
        console.log("error : ", error);
      }
    );
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Dashboard Settings", "Dashboard Settings - Submit", "Click", "Dashboard Settings - Submit", 0);
  }
  onReject() {
    this.messageService.clear('c');
  }
}