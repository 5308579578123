import { MobileInboxViewComponent } from '../mobile-inbox-view/mobile-inbox-view.component';

export interface ColumnApi {

}

export interface DetailGridInfo {
    id: string;
    api: MobileViewAPI | null | undefined;
    columnApi: ColumnApi | null | undefined;
}

export class MobileViewAPI {

    public viewComponent: MobileInboxViewComponent;

    constructor() {

    }

    public forEachDetailGridInfo(callback: (gridInfo: DetailGridInfo, index: number) => void) {
        // TBD
        console.log('MobileViewAPI.forEachDetailGridInfo:');
    }

    public setRowData(rowData: any[]) {
        // C153176-5031: clear old row data, set new row data
        console.log('MobileViewAPI.setRowData:');
        // remove existing data
        this.viewComponent.rowData = [];
        this.viewComponent.nodes = [];
        this.viewComponent.nodeCount = 0;
        this.viewComponent.rowData = rowData;
        this.handleAddNodes(rowData, 0);
    }

    public setColumnDefs(colDefs: any[], source: any = "api") {
        // TBD - InboxView
        console.log('MobileViewAPI.setColumnDefs:');
    }

    public expireValueCache(): void {
        console.log('MobileViewAPI.expireValueCache:');
    }

    public setAlwaysShowVerticalScroll(show: boolean) {
        console.log('MobileViewAPI.setAlwaysShowVerticalScroll:');
    }

    public refreshCells(params: any = {}): void {
        if (Array.isArray(params)) {
            return;
        }
        // C153176-4796, 4925: force change detect
        if (params && params.rowNodes && params.rowNodes.length) {
            params.rowNodes.forEach(rowNode => {
                if (rowNode && rowNode.cdr) {
                    // C153176-5031: catch err
                    try {
                      rowNode.cdr.detectChanges();
                    } catch(err) {
                        console.error("force change detection fail error", err)
                    }
                }
            });
        }
    }

    public redrawRows(params: any = {}): void {
        // C153176-4796, 4925: force change detect
        if (params && params.rowNodes && params.rowNodes.length) {
            params.rowNodes.forEach(rowNode => {
                if (rowNode && rowNode.cdr) {
                    rowNode.cdr.detectChanges();
                }
            });
        }
    }

    public refreshHeader() {
        console.debug('MobileViewAPI.refreshHeader:');
    }

    public isAnyFilterPresent(): boolean {
        console.log('MobileViewAPI.isAnyFilterPresent:');
        return false;
    }

    public isAdvancedFilterPresent(): boolean {
        return this.isColumnFilterPresent();
    }

    public isColumnFilterPresent(): boolean {
        console.log('MobileViewAPI.isColumnFilterPresent:');
        return false;
    }

    public isQuickFilterPresent(): boolean {
        console.log('MobileViewAPI.isQuickFilterPresent:');
        return false;
    }

    public getModel(): any {
        // TBD
        console.log('MobileViewAPI.getModel:');
        return undefined;
    }

    public onGroupExpandedOrCollapsed(deprecated_refreshFromIndex?: any) {
        // TBD
        console.log('MobileViewAPI.onGroupExpandedOrCollapsed:');
    }

    public refreshInMemoryRowModel(step?: string): any {
        this.refreshClientSideRowModel(step);
    }

    public refreshClientSideRowModel(step?: string): any {
        console.log('MobileViewAPI.refreshClientSideRowModel:');
    }

    public getRowNode(id: string): any {
        // TBD - InboxView
        console.log('MobileViewAPI.getRowNode:');
    }

    public expandAll() {
        console.log('MobileViewAPI.expandAll:');
    }

    public collapseAll() {
        console.log('MobileViewAPI.collapseAll:');
    }

    public setQuickFilter(newFilter:any): void {
        // TBD - InboxView
        console.log('MobileViewAPI.setQuickFilter:');
    }

    public selectAll() {
        // TBD - InboxView
        console.log('MobileViewAPI.selectAll:');
    }

    public deselectAll() {
        // TBD - InboxView
        console.log('MobileViewAPI.deselectAll:');
    }

    public selectAllFiltered() {
        // TBD - InboxView
        console.log('MobileViewAPI.selectAllFiltered:');
    }

    public deselectAllFiltered() {
        // TBD - InboxView
        console.log('MobileViewAPI.deselectAllFiltered:');
    }

    public recomputeAggregates(): void {
        console.log('MobileViewAPI.recomputeAggregates:');
    }

    public sizeColumnsToFit() {
        // TBD - InboxView
        console.log('MobileViewAPI.sizeColumnsToFit:');
    }

    public hideOverlay(): void {
        // TBD - gfid search
        console.log('MobileViewAPI.hideOverlay:');
    }

    public isNodeSelected(node:any) {
        // TBD
        console.log('MobileViewAPI.isNodeSelected:');
    }

    public getSelectedNodes(): any[] {
        // TBD - InboxView
        console.log('MobileViewAPI.getSelectedNodes:');
        return [];
    }

    public getSelectedRows(): any[] {
        // TBD - InboxView
        console.log('MobileViewAPI.getSelectedRows:');
        return [];
    }

    public forEachNode(callback: (rowNode: any, index: number) => void) {
        if (this.viewComponent && this.viewComponent.nodes) {
            this.viewComponent.nodes.forEach(callback);
        } else {
            console.log('MobileViewAPI.forEachNode: invalid nodes!');
        }
    }

    public onFilterChanged() {
        // TBD - InboxView
        console.log('MobileViewAPI.onFilterChanged:');
    }

    public setHeaderHeight(headerHeight: number) {
        // TBD - InboxView
        console.log('MobileViewAPI.setHeaderHeight:');
    }

    public resetRowHeights() {
        // TBD - InboxView
        console.log('MobileViewAPI.resetRowHeights:');
    }

    private handleAddNodes(itemsToAdd, addIndex) {
        let i = 0;
        // C153176-4926: ordering fix
        itemsToAdd = itemsToAdd.reverse();
        // C153176-4577: row node mockup: add 'selected' and 'setSelected'
        const nodesToAdd = itemsToAdd.map(item => {
            return { data: item, rowIndex: addIndex + i++, selected: false, setSelected: (flag) => {
              }};
        });
        const spliceArgs = [addIndex, 0].concat(nodesToAdd);
        this.viewComponent.nodes.splice.apply(this.viewComponent.nodes, spliceArgs);
        this.viewComponent.nodeCount += itemsToAdd.length;
        this.updateRowIndex();
    }

    private updateRowIndex() {
        // update rowIndex
        this.viewComponent.nodes.forEach((node, index) => {
            if (node) {
                node.rowIndex = index;
                node.data.rowIndex = index;
            }
        });
        this.viewComponent.nodeCount = this.viewComponent.nodes.length;
    }

    private handleRemoveItem(item) {
        if (!item) {
            return;
        }
        let rowIndex = item.rowIndex;
        if (rowIndex >= 0 && rowIndex < this.viewComponent.rowData.length) {
            this.viewComponent.rowData.splice(rowIndex, 1);
            this.viewComponent.nodes.splice(rowIndex, 1);
        } else if (rowIndex === undefined) {
            console.log('handleRemoveItem, invalid rowIndex');
        }
        this.updateRowIndex();
    }


    public updateRowData(rowDataTransaction: any): any {
        console.log('updateRowData:', rowDataTransaction);
        if (!rowDataTransaction) {
            return;
        }
        let itemsToDelete = rowDataTransaction.remove;
        if (itemsToDelete) {
            if (Array.isArray(itemsToDelete)) {
                itemsToDelete.forEach(n => {
                    this.handleRemoveItem(n);
                });
            } else {
                this.handleRemoveItem(itemsToDelete);
            }
        }
        let itemsToAdd = rowDataTransaction.add;
        let addIndex = rowDataTransaction.addIndex;
        if (!addIndex) {
            addIndex = 0;
        }
        if (itemsToAdd) {
            if (Array.isArray(itemsToAdd) && itemsToAdd.length) {
                this.handleAddNodes(itemsToAdd, addIndex);
            } else {
                this.handleAddNodes([itemsToAdd], addIndex);
            }
        }

        /*
        if (this.clientSideRowModel) {
            res = this.clientSideRowModel.updateRowData(rowDataTransaction);
        } else if (this.infinitePageRowModel) {
            this.infinitePageRowModel.updateRowData(rowDataTransaction);
        } else {
            console.error('ag-Grid: updateRowData() only works with ClientSideRowModel and InfiniteRowModel.');
        }

        // need to force updating of full width rows - note this wouldn't be necessary the full width cell comp listened
        // to the data change event on the row node and refreshed itself.
        this.rowRenderer.refreshFullWidthRows();

        // do change detection for all present cells
        if (!this.gridOptionsWrapper.isSuppressChangeDetection()) {
            this.rowRenderer.refreshCells();
        }
        */
    }

    public getFirstDisplayedRow(): number {
        return this.getDisplayedRowAtIndex(0);
    }

    public getDisplayedRowAtIndex(index: number): any {
        if (this.viewComponent && this.viewComponent.nodes && index >= 0 && index < this.viewComponent.nodes.length) {
            return this.viewComponent.nodes[index];
        } else {
            console.log('MobileViewAPI.getDisplayedRowAtIndex, invalid nodes, index=' + index);
            return undefined;
        }
    }

    public getDisplayedRowCount(): number {
        if (this.viewComponent && this.viewComponent.nodes) {
            return this.viewComponent.nodes.length;
        } else {
            console.log('MobileViewAPI.getDisplayedRowCount, invalid nodes');
            return 0;
        }
    }

    public ensureIndexVisible(index, position) {
        // TBD
    }

    // C153176-5011 add API method
    public hidePopupMenu() {
    }
}
