<div *ngIf="loginUserInfo">
	<app-leftnav (getSelectedMenu)="selectedMenuItem($event)" (streamId)="openSymphonyChatView($event)"></app-leftnav>
	<div class="wrapper">
		<app-topnav [clickStatusArray]='clickStatusArray' [advanceSearchVisible]="displayOverlay" (advanceSearchToggle)="toggleOverlay($event)" [clearCriteria]="clrSearchCriteria" [streamId]="this.streamId" (closeChatViewEmit)="closeChatView($event)"></app-topnav>
		<!-- <app-adv-criteria appOuterClick [hidden]="advCriteriaToggle"></app-adv-criteria> -->
		<app-tabs (searchClr)="clrSearchOnTabClose($event)"></app-tabs>
		<!-- Right Panel Components[Open on top Nav buttons click] -->
		<p-sidebar 
					[position]="'right'"
					[modal]="true"
					[showCloseIcon]="false"
					[styleClass] ="sideBarStyle"
					[baseZIndex]="1001"
					[(visible)]="clickStatusArray[0]"
					>
				<app-notification [showNotificationHtml] = "true"></app-notification>
		</p-sidebar>
		<p-sidebar 
					[(visible)]="clickStatusArray[1]"
					[position]="'right'"
					[modal]="true"
					[showCloseIcon]="false"
					[styleClass]="sideBarStyle"
					[baseZIndex]="1001">
				<app-help></app-help>
		</p-sidebar>
		<p-sidebar 
		 			[(visible)]="clickStatusArray[2]"
					[position]="'right'"
					[modal]="true"
					[showCloseIcon]="false"
					[styleClass] ="sideBarStyle"
					[baseZIndex]="1001">
				<app-user></app-user>
		</p-sidebar> 
		<div *ngIf="symphonyEnabled">
			<p-sidebar  [(visible)]="clickStatusArray[3]"
						[position]="'right'"
						[modal]="true"
						[showCloseIcon]="false"
						[styleClass] ="sideBarStyle"
						[baseZIndex]="1001"			
						>
				<app-notification-symphony></app-notification-symphony>
			</p-sidebar>
		</div>
		<!-- END of Right Panel Components -->	
	</div>
	<!-- <div [hidden]="!menuStatusArray[1]" class="dashboardWrapper">
		<app-dashboard-view></app-dashboard-view>
	</div> -->
</div>
<div class="qma-overlay" *ngIf="displayOverlay" (click)="hideOverlay()"></div>

<!--Confirmation popup window-->
<p-dialog [(visible)]="showPopUp" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal'"
 [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">

	<p-header>
		<div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
			<h6 class="reage-title">CONFIRM ACTION</h6>
			<button type="button" (click)="onCancel()" class="d-flex flex-row close qma-text">
	  <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
	  <h6>CLOSE</h6>
	</button>
		</div>
	</p-header>
	<div class="reage-body mx-2">
		<form>
			<div class="d-flex flex-row align-items-center input-holder mb-4">
				<div class="d-flex flex-row align-items-center mb-4 pb-4">
					<label>Currently, out of office is turned on, please click Ok to disable your out of office.</label>
				</div>
			</div>
		</form>
	</div>
	<p-footer>
		<div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
			<button class="btn search-btn mr-4" (click)="onConfirm()">Ok</button>
			<button type="reset" class="btn clear-btn" (click)="onCancel()">Cancel</button>
		</div>
	</p-footer>
</p-dialog>


<!--Confirmation popup window for no Role Group-->
<p-dialog [(visible)]="showNoGroupPopup" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal'"
[closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">

 <p-header>
	 <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
		 <h6 class="reage-title">MESSAGE</h6>
		 <button type="button" (click)="onCloseGroupPopup()" class="d-flex flex-row close qma-text">
	 <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
	 <h6>CLOSE</h6>
 </button>
	 </div>
 </p-header>
 <div class="reage-body mx-2">
	 <form>
		 <div class="d-flex flex-row align-items-center input-holder mb-4">
			 <div class="d-flex flex-row align-items-center mb-4 pb-4">
				 <label>You currently do not have access to any groups! <br>Please contact Group Admin!</label>
			 </div>
		 </div>
	 </form>
 </div>
 <p-footer>
	 <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
		 <button class="btn search-btn mr-4" (click)="onCloseGroupPopup()">Ok</button>		 
	 </div>
 </p-footer>
</p-dialog>

<!--Popup for showing error messages-->
<p-dialog [(visible)]="showError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal'"
[closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">

 <p-header>
	 <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
		 <h6 class="reage-title">MESSAGE</h6>
		 <button type="button" (click)="onCloseGroupPopup()" class="d-flex flex-row close qma-text">
	 <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
	 <h6>CLOSE</h6>
 </button>
	 </div>
 </p-header>
 <div class="reage-body mx-2">
	 <form>
		 <div class="d-flex flex-row align-items-center input-holder mb-4">
			 <div class="d-flex flex-row align-items-center mb-4 pb-4">
				 <label>Technical issue has occurred! Please contact Support!</label><br/><br/>
				 <em class="fa fa-envelope-o" style="font-size: 18px;margin: -1px 6px 0px 0px;"></em>
				 <h6 style="font-family: Interstatelight;color: #002D72;font-size: 14px;text-decoration: underline;"
					 (click)="mailSupport()">@Support</h6>
			 </div>
		 </div>
	 </form>
 </div>
 <p-footer>
	 <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
		 <button class="btn search-btn mr-4" (click)="onCloseGroupPopup()">Ok</button>		 
	 </div>
 </p-footer>
</p-dialog>