<div class="row pr-3" [hidden]=false>
  <div class="col-sm-12 mb-3 mobile-dashboard-view" style="border-bottom: 2px solid #ccc;padding-bottom:4px;">
    <span class="dashboardRefreshLabel">Last Refreshed:</span>
    <span class="dashboardRefreshDate" [textContent]="lastRefreshDate"></span>
    <a class="d-flex flex-row flex-nowrap" style="justify-content:flex-end; float:right;" (click)="showDashBoardSetting($event, attachmentOptions)">      
            <img src="assets/dashboard/contextmenu.png" class="p-1" style="height: 2em; width: 2em; cursor:pointer;" pTooltip="Please click here to access dashboard personalization" tooltipPosition="left" tooltipStyleClass='leftTooltip p-tooltip-text' alt="">
      </a>     
    <span class="dashboardPanelDisplay" (click)="showDashBoardPanel($event, dropdownvalues)">
        <span style=" padding: 2px; float: right;" class="fa fa-chevron-down"></span>
        <span style="margin-left:3px;"> View Charts: All </span>
    </span>  
  </div> 
<p-toast position="center" (onClose)="onReject()" styleClass="custom-toast" key="custom" [baseZIndex]="5000"></p-toast>   
  <p-overlayPanel [style]="{'margin-top': '-28px', 'border': '1px solid dodgerblue','z-index': '1006','top': '101px','left': '1655px',
  'transform': 'translateY(0px)', 'opacity': '1','width':'182px','height':'220px'}" #dropdownvalues appendTo="body" [styleClass]="'dashboard_loadchart'" >
   <div class="dashboardchart" style="font-size:10px;font-weight:bold;">
      <div class="d-flex flex-row mb-0.3" >
            <span class="flex-row flex-nowrap align-items-end w-75">
                <label for="from" class="flex-grow" style="width:82px; color:#007BC2;font-size:12px;font-weight:bold;">Select Charts</label>
            </span>
            <span class="flex-row flex-nowrap align-items-end w-30 ml-60">
                <button type="button" (click)="dropdownvalues.hide()" class="d-flex flex-row close qma-text">
                    <span style="font-size: 12px; color: #007BC2;padding-top: 3px;margin-left:7px;" class="fa fa-chevron-up"></span>            
                </button>
            </span>
        </div>
        <div class="d-flex flex-row mb-0.3" >
              <span class="flex-row flex-nowrap align-items-end w-75">
                  <label for="from" class="flex-grow">All</label>
              </span>
              <span class="flex-row flex-nowrap align-items-end w-30 ml-60">
                  <p-inputSwitch name="isAllChart" [(ngModel)]="isAllChart" #chartNameAll (onChange)="handleChange($event,chartNameAll)"></p-inputSwitch>
              </span>
        </div>
        <div class="d-flex flex-row mb-0.3">
            <span class="flex-row flex-nowrap align-items-end w-75">
                <label for="from" class="flex-grow">Owner chart</label>
            </span>
            <span class="flex-row flex-nowrap align-items-end w-30 ml-60">
                <p-inputSwitch name="isOwnerchart" [(ngModel)]="isOwnerChart" #chartNameIsOwnerchart (onChange)="handleChange($event,chartNameIsOwnerchart)" ></p-inputSwitch>
            </span>
        </div>
        <div class="d-flex flex-row mb-0.3" >
            <span class="flex-row flex-nowrap align-items-end w-75">
                <label for="from" class="flex-grow">Heatmap chart</label>
            </span>
            <span class="flex-row flex-nowrap align-items-end w-30 ml-60">
                <p-inputSwitch name="isHeatmapchart" [(ngModel)]="isHeatmapChart" #chartNameIsHeatmapchart (onChange)="handleChange($event,chartNameIsHeatmapchart)" ></p-inputSwitch>
            </span>
        </div>         
        <div class="d-flex flex-row mb-0.3">
            <span class="flex-row flex-nowrap align-items-end w-75">
                <label for="from" class="flex-grow ">Request type chart </label>
            </span>
            <span class="flex-row flex-nowrap align-items-end w-30 ml-60">
                <p-inputSwitch name="isRequesttypechart" [(ngModel)]="isRequesttypeChart" #chartNameIsRequesttypechart (onChange)="handleChange($event,chartNameIsRequesttypechart)"></p-inputSwitch>
            </span>
        </div>
         <!--below commented because section will always true -->
        <!-- <div class="d-flex flex-row mb-0.3" >
            <span class="flex-row flex-nowrap align-items-end w-75">
                <label for="from" class="flex-grow">Group chart</label>
            </span>
            <span class="flex-row flex-nowrap align-items-end w-30 ml-60">
                <p-inputSwitch name="isGroupchart" [readonly]="true"  [(ngModel)]="isGroupChart" #chartNameIsGroupchart (onChange)="handleChange($event,chartNameIsGroupchart)" ></p-inputSwitch>
            </span>
        </div> -->
        <div class="d-flex flex-row mb-0.3">
            <span class="flex-row flex-nowrap align-items-end w-75">
                <label for="from" class="flex-grow">Trend chart</label>
            </span>
            <span class="flex-row flex-nowrap align-items-end w-30 ml-60">
                <p-inputSwitch name="isTrendChart" [(ngModel)]="isTrendChart" #chartNameIsTrendChart (onChange)="handleChange($event,chartNameIsTrendChart)" ></p-inputSwitch>
            </span>
        </div>
        <!--below commented because section not implemented yet -->
        <!-- <div class="d-flex flex-row mb-0.3" >
            <span class="flex-row flex-nowrap align-items-end w-75">
                <label for="from" class="flex-grow">Community widget</label>
            </span>
            <span class="flex-row flex-nowrap align-items-end w-30 ml-60">
                <p-inputSwitch name="isCommunitywidget" [(ngModel)]="isCommunitywidgetChart" #chartNameIsCommunitywidget (onChange)="handleChange($event,chartNameIsCommunitywidget)" ></p-inputSwitch>
            </span>
        </div> -->
        <div class="d-flex flex-row mb-0.3" >
            <span class="flex-row flex-nowrap align-items-end w-75">
                <label for="from" class="flex-grow">Mailbox statistics</label>
            </span>
            <span class="flex-row flex-nowrap align-items-end w-30 ml-60">
                <p-inputSwitch name="isMailbox" [(ngModel)]="isMailboxChart" #chartNameIsMailbox (onChange)="handleChange($event,chartNameIsMailbox)" ></p-inputSwitch>
            </span>
        </div>
        
        <div class="d-flex flex-row mb-0.3" >
              <span class="flex-row flex-nowrap align-items-end w-135">
                  <button type="button" (click)="[onDoLoadChart(),dropdownvalues.hide()]" class="btn search-btn loadChart">Load Charts</button>
              </span>              
        </div>
    </div>
  </p-overlayPanel>
 

    <div id="assigned-group-box" class="col-sm-6 pr-0 mb-3" *ngIf="isAll || isGroup">
            <div class="card"  style="min-width: 355px; min-height:260px;">
            <app-inquiries-by-assigned-group [category]="'main'"></app-inquiries-by-assigned-group>
        </div>
    </div>

    <div id="trend-chart-box" class="col-sm-6 mb-3" *ngIf="isAll || isTrend">
        <div class="card"  style="min-width: 355px; min-height:260px;">
            <app-inquiries-by-trend></app-inquiries-by-trend>
        </div>
    </div>
    
    <div id="mailbox-statistics-chart-box" class="col-sm-12 mb-3" *ngIf="isAll || isMailbox" >
        <div class="card" style="min-height: 255px;height: 100%;background:#FCFCFC;overflow-y:inherit;max-height:1200px;">
            <app-mailbox-statistics></app-mailbox-statistics>
        </div>
    </div>
    
    <div id="request-type-chart-box" class="col-sm-6 pr-0 mb-3" *ngIf="isAll || isRequesttype">
        <div class="card" style="min-height: 260px;">
            <app-inquiries-by-request-type></app-inquiries-by-request-type>
        </div>
    </div>

    <div id="intensity-heatmap-chart-box" class="col-sm-6 mb-3" *ngIf="isAll || isHeatmap">
        <div class="card" style="min-height: 260px;">
            <app-intensity-heatmap></app-intensity-heatmap>
        </div>
    </div>
  
    <div id="assigned-owner-box" class="col-sm-12 mb-3" *ngIf="isAll || isOwner">
        <div class="card mobile-card-height" style="min-height: 360px;">
            <app-inquiries-by-assigned-owner></app-inquiries-by-assigned-owner>
        </div>
    </div>  
  
</div>
 
<p-overlayPanel  class ="dashboardsetting" #attachmentOptions appendTo="body" [styleClass]="'dashboarsetting'" [dismissable]="true">
    <p-listbox [listStyle]="{'max-height':'200px', 'overflow-x': 'hidden'}" [options]="attachmentsOptions" class="dashboardsettinglist" [styleClass]="'dashboarsettingListStyle'">  <!-- C153176-5641 -->
      <ng-template let-attachOpt pTemplate="item">
        <a *ngIf="attachOpt.name=='Dashboard Settings'" (click)="openAdminDialog();" title="{{attachOpt.name}}" class="attachment-font"> {{attachOpt.name}}</a>
        <a *ngIf="attachOpt.name=='Set as Default'" (click)="setAsDefault();" title="{{attachOpt.name}}" class="attachment-font"> {{attachOpt.name}}</a>
    </ng-template>
  </p-listbox>
</p-overlayPanel>
<app-dashboard-setting (onCloseModal)="changeFlag($event)" *ngIf="display"></app-dashboard-setting>