import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
declare let symphony ;
import * as $ from 'jquery';
import { UserDataService } from 'src/app/services/user-data.service';
@Component({
  selector: 'app-symphony-chat-view-old',
  templateUrl: './symphony-chat-view.component-old.html',
  styleUrls: ['./symphony-chat-view.component-old.scss']
})
export class SymphonyChatViewComponentOld implements OnInit {

  selectedGridRow: any = null;
  
  @Output() closeChatView = new EventEmitter();
  constructor(private userDataService: UserDataService) {}

  ngOnInit() {
   /*  console.log('init of symphony'+this.selectedGridRow.streamId);
    if(this.selectedGridRow.streamId && this.selectedGridRow.streamId != ''){
      this.renderSymphonyChatView();
    } */
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    /* if(changes.streamId.previousValue !== changes.streamId.currentValue && this.streamId != '' && this.streamId){
      console.log('loading symphony chat streamId: '+ this.streamId);
    } */
  }
  
  renderSymphonyChatView(selectedGridRow: any) {
   if(!selectedGridRow) {
     return;
   }
   $( ".my-class" ).empty();
    this.selectedGridRow = null;
    this.selectedGridRow = selectedGridRow;
    console.log('symphony' + symphony);
    //this.streamId = 'j3FHXDJtsAMssPRdXDDD53///o7U/5TSdA==';
    let obj = {
      'module': 'room',
      'streamId': '',
      //'streamId': this.streamId,
      //'module': 'im',
      //'userIds': [343734822692362],
      'theme':'blue',
      'mode': 'light',
      'contrast': true,
      'condensed': true,
      'showTitle': true,
      'showInfo': true,
      'showXPod': false,
      'showEmoji': true,
      'showAttach': true,
      'showDisableInput': false,
      'showCompose': true,
      'showSystemMessages': false,
      'urlIntercept': (url)=> {
        console.log(url);
        return this.userDataService.loggedInUserInfo.symphonyConfig.urlIntercept+encodeURIComponent(url);
        },
      'style': {
    general: {
      color: "red",
      background: "light blue",
      font: 'InterstateLight'
    },
    header: {
      background: "#f7fafd",
      font: "Bold 1em InterstateLight;",
      color: "#002d72"
    },
    input: {
      outline: "5px dotted blue",
      background: "pink",
      font: "InterstateLight",
      color: "#002d72"
    },
    sendUsername: {
      font: "Bold 1em InterstateLight;",
      color: "#002d72"
    },
    sendText: {
      font: "InterstateLight",
      color: "#002d72"
    },
    receiveUsername: {
      font: "InterstateLight;",
      color: "#002d72"
    },
    receiveText: {
      font: "InterstateLight",
      color: "#002d72"
    },
    systemMessages: {
      font: "InterstateLight",
      color: "#002d72",
    }
  },
    };
    obj['streamId'] = this.selectedGridRow.streamId;
  setTimeout(function() { symphony.render('my-class', obj); 
}, 0);
 }

openSymphonyView(streamId: string){
  this.closeChatView.emit(streamId);
}

onCreateInquiry(){
  alert("Create new inquiry");
}

}
