import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, BehaviorSubject, from, forkJoin, of} from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GridViewDataModel } from '../model/GridViewDataModel/GridViewDataModel';
import { InquiryDataList } from '../model/GridViewDataModel/InquiryDataList';
import { DefaultColumnDef } from '../model/LoginUserInfo/DefaultColumnDef';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { RequestCacheService } from 'src/app/services/cache/request-cache.service';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import * as $ from 'jquery';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};

@Directive()
@Injectable({
  providedIn: 'root'
})
export class InboxService {
  gridViewDataModel: GridViewDataModel;
  public columnDefs: DefaultColumnDef[] = [];
  inquiryDataList: InquiryDataList[];
  requestObj: any = {};
  customizedColumns: any = [];
  baseURL: string;
  nlpURL: string;
  extInqUrl: string;
  attachBinary : any;
  isDefault : any;
  genericUrl: string;
  // to save preferences
  baseUrlUser: string;
  @Output() dataChange: EventEmitter<any> = new EventEmitter();
  @Output() viewRequest: EventEmitter<any> = new EventEmitter();
  @Output() httpResponse: EventEmitter<string> = new EventEmitter();
  @Output() resetSearch: EventEmitter<string> = new EventEmitter();
  @Output() searchTabEvent: EventEmitter<string> = new EventEmitter();
  @Output() rowDragDropEvent: EventEmitter<string> = new EventEmitter();
  @Output() ageDataEvent: EventEmitter<string> = new EventEmitter();
  @Output() inquiryDragEvent: EventEmitter<string> = new EventEmitter();
  @Output() onKeySearchTextEvent: EventEmitter<any> = new EventEmitter();
  @Output() onMobileSearchChangedEvent: EventEmitter<any> = new EventEmitter();
  @Output() mobileSearchTriggerEvent: EventEmitter<any> = new EventEmitter();
  @Output() mobileGridDataReadyEvent: EventEmitter<any> = new EventEmitter();
  // C153176-4696: mobile sorting event
  @Output() mobileSortTriggerEvent: EventEmitter<any> = new EventEmitter();
  // C153176-4925: data update event
  @Output() mobileGridDataUpdateEvent: EventEmitter<any> = new EventEmitter();

  layoutOption = new BehaviorSubject("");
  private requestCache: any = {};
  // C153176-4540: caching user selected 'Search In' boxes
  public searchBoxes: any;
  public mobileCheckboxSelected = false;
  @Output() mobileSelectionEnabledEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() selectAllEvent: EventEmitter<boolean> = new EventEmitter();
  public selectAll = false;
  public mobileSearchEnabled = false;
  @Output() mobileSearchEnabledEvent: EventEmitter<boolean> = new EventEmitter();
  public showSpinner = false;
  public resetRequest = false;
  //C153176-4796 reset check-before-load flag 
  public checkBeforeLoad = false;
  // C153176-5011: myview list
  public myViewItems: any;
  // C153176-5295: caching current view height
  public currentViewHeight;
  // C153176-5343; file drag event
  @Output() fileDragEvent: EventEmitter<any> = new EventEmitter();
  private fileDragState: boolean = false;
  private fileDragStateTimer: any;
  private fileDragIntervalCounter = 0;
  wsQueue: Map<string,Array<object>> = new Map();
  commonWsSubscribtion: boolean = false;
  readWsQueue = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient, private requestCacheService: RequestCacheService) {
    this.baseURL = QmaConstant.BASE_URL + '/inquiry';
    this.nlpURL = QmaConstant.BASE_URL + '/qmanlp';
    this.extInqUrl = QmaConstant.BASE_URL + '/extInquiry';
    this.baseUrlUser = QmaConstant.BASE_URL + '/users';
    this.genericUrl = QmaConstant.BASE_URL + '/generic';
  }

  setLayoutOption(layOutSelected: string) {
    this.layoutOption.next(layOutSelected);
  }

  getLayoutOption() {
    return this.layoutOption;
  }
  /**
   * Method to get the group level inquiry data for view based on the view name in request object.
   * 
   * @param requestObj - Request JSON object.
   */
  getGroupLevelInquiryDataForView(requestObj: any): Observable<any> {
    return this.http.post(this.baseURL + '/getGroupLevelInquiryDataForView', requestObj, httpOptions);
  }

  getGridViewDataDefault(requestObj: any): Observable<GridViewDataModel> {
    return this.http.post<GridViewDataModel>(this.baseURL + '/getGridViewData', requestObj, httpOptions);
  }

  getGridViewTotalCount(requestObj: any): Observable<GridViewDataModel> {
    return this.http.post<GridViewDataModel>(this.extInqUrl + '/getGridViewTotalCount', requestObj, httpOptions);
  }

  getGridViewTotalUnreadCount(requestObj: any): Observable<GridViewDataModel> {
    return this.http.post<GridViewDataModel>(this.extInqUrl + '/getGridViewTotalUnreadCount', requestObj, httpOptions);
  }

  /**
   * C153176-4540: For advance search, if user has specified 'SEARCH IN' boxes, assign them to 'viewName' in request object.
   * @param requestObj original request object
   * @return potentially updated request object. In most cases, return the same specified 'requestObj'.
   */
  updateRequest(requestObj) {
    if (!requestObj || (!requestObj.advanceSearchData && !requestObj.solrSearchText) || requestObj.viewName !== 'Search'
        || !this.searchBoxes || !this.searchBoxes.length ) {
      return requestObj;
    }
    const newReqObj = Object.assign({}, requestObj);
    newReqObj.viewName = this.searchBoxes;
    return newReqObj;
  }

  /**
   * Method to get all the inquiry conversation details based on inquiry id and its assigned group.
   * 
   * @param requestObj - Request JSON object.
   */
  getInquiryIdSelected(requestObj: any, inquiry: any = undefined): Observable<any> {
    //C153176-4456: cache lookup first, and request service if cache miss
    let cachedResp = this.requestCacheService.lookupCache(inquiry, requestObj.isAllConvContent);
    if (cachedResp) {
      return from([cachedResp]);
    }
    return this.http.post(this.extInqUrl + '/getAllInquiryConversations', requestObj, httpOptions);
  }

  /**
   * Method to get all the inquiry draft details based on inquiry id and its assigned group.
   * 
   * @param requestObj - Request JSON object.
   */
  getDraftById(requestObj: any): Observable<any> {
    return this.http.post(this.baseURL + '/getDraftById', requestObj, httpOptions);
  }

  /**
   * Method to get the inquiry conversation details based on conversation id.
   *
   * @param requestObj - Request JSON object.
   */
  getInquiryConversationById(requestObj: any): Observable<any> {
    return this.http.post(this.extInqUrl + '/getInquiryConversationById', requestObj, httpOptions);
  }

  /**
  * Method to add notes to the inquiry.
  *
  * @param notesDetails - Request JSON object.
  */
  addNoteToInquiry(notesDetails: any): Observable<any> {
    return this.http.post(this.baseURL + '/addNoteToInquiry', JSON.stringify(notesDetails), httpOptions);
  }

  /**
   * Method to download conversation as email.
   * 
   * @param conversationId - Conversation Id
   * @param docName - Document name
   */
  downloadAsEmail(conversationId: number, docName: string, groupId:number, hasNominatedOwnership:boolean,isDraft:boolean=false): void {
    const fileUrl = QmaConstant.BASE_URL + '/file/fileSave?bt=' + conversationId + '&groupId=' + groupId + '&hasNominatedOwnership='+ hasNominatedOwnership+ '&draftId=' + conversationId + '&isDraft=' + isDraft;
    this.http.get(fileUrl, { responseType: "blob" }).subscribe(result => {

      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      var newBlob = new Blob([result], { type: "application/octet-stream" });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers: 
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);

      let link = document.createElement('a');
      link.href = data;
      // C153176-4706: call helper function 'getSubject' to retrive subject of inquiry
      if (docName === null || docName === undefined || docName === 'null') {
        docName = QmaConstant.NO_SUBJECT;
      }
      link.download = docName + ".eml";
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }, error => {
      console.log('error :', error);
    });
  }

  
  /**
   * Method to download conversation as email.
   * 
   * @param conversationId - Conversation Id
   * @param docName - Document name
   */
  downloadAsEmailAndReturnFile(conversationId, docName, groupId:number, hasNominatedOwnership:boolean, isDraft:boolean=false) {
    const fileUrl = QmaConstant.BASE_URL + '/file/fileSave?bt=' + conversationId + "&isDraft" + isDraft;
    
    return this.http.get(fileUrl, { responseType: "blob" });
  }

  /**
   * Method to download the attachments based on docId.
   * @param docId - Document unique Id
   * @param docName - Document name with document type
   */
  downloadAttachment(docId: string, docName: string, convId:number, groupId: number, hasNominatedOwnership:boolean, isDraft:boolean = false): void {
    const attachementUrl = QmaConstant.BASE_URL + '/file/fileDownload?docId=' + docId + '&convId=' + convId + '&groupId=' + groupId + '&hasNominatedOwnership=' + hasNominatedOwnership + '&isDraft=' + isDraft;

    this.http.get(attachementUrl, { responseType: "blob" }).subscribe(result => {
      var newBlob = new Blob([result], { type: "application/octet-stream" });
      const nav = (window.navigator as any);
      if (nav &&nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download = docName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }, error => {
      console.log('error :', error);
    });
  }

   downloadAttachmentForReupload(sAttachDet){
    var result =  this.http.get(sAttachDet.split(':')[0], { responseType: "blob" });
    return result;
    }

  
  private callback(bas64,obj) {
    obj.attachBinary = obj._base64ToArrayBuffer(bas64);
  }
   private _base64ToArrayBuffer(base64) {
    const binary_string = window.atob(base64);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  private blobToBase64(blob, callback,obj) : any {
    var reader = new FileReader();
    reader.onload = function() {
        var dataUrl = ''+reader.result;
        var base64 = dataUrl.slice(dataUrl.indexOf(',')+1);
        
        return callback(base64,obj);
    };
    reader.readAsDataURL(blob);
  };
  
  public  viewAttachment(docId: string, docName: string,  convId: string, groupId:number, hasNominatedOwnership:boolean, isDraft:boolean=false): any {
    
    return QmaConstant.BASE_URL + '/file/fileDownload?docId=' + docId + '&convId=' + convId + '&groupId=' + groupId + '&hasNominatedOwnership=' + hasNominatedOwnership + '&isDraft=' + isDraft;
    
  }
    
  /**
   * Method to per form print, pdfdownload and new window open on Conversation view expanded view.
   * @param converstion view -
   * @param options used are - print,pdf,newwindow -
   */
  print(options): void {
    const currTabName = AppUtils.getCurrentTab();
    const tabName = currTabName.toUpperCase();

    let selector = 'mail-detail-view > div[data-view-id=\'' + tabName + '\'';

    let clone: any = document.querySelector('head').cloneNode(true);
    let printContents, popupWin;
    printContents = document.querySelector(selector).innerHTML;
    popupWin = window.open('', '_blank');
    popupWin.blur();
    window.focus();
    popupWin.document.write(`
    <!doctype html>
      <html>
      ${clone.innerHTML} 
    <body>
    <link rel="stylesheet" href="assets/css/bootstrap.min.css" crossorigin="anonymous">
    <style>
   
    html, body{
        height: auto;
        width: auto;
        zoom:85%;
    }
        .print{display: inline-block !important;}
        .noPrint{display: none !important;}
        .conversation-container {
            height: auto !important;
            width: auto !important;
            overflow: visible !important;
            position: relative !important;
          }
          .conv-info img[alt="NLP"],.conv-info .coll-expd, .conv-info .ellipsis-icon  {display: none; }
    </style>
    ${printContents}
    </body>
    </html>`
    );

    if (options === 'print') {
      setTimeout(() => {
        popupWin.print();
        //popupWin.close();
      }, 1000); //C153176-5352:Increased time to load complete content before print
    } else  if (options === 'pdf'){
      let _this = this;
     
      setTimeout(() => {
       // pdfMake.vfs = pdfFonts.pdfMake.vfs;
       //C153176-5352:Download PDF issue fix to print image in multipage
        html2canvas(popupWin.document.body).then(function (canvas) {
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;
        /* var doc = new jsPDF('p', 'mm','A4'); */ // C153176-6107 : Reduce PDF genrated size.
        // var doc = new jsPDF('p', 'mm',[canvas.width,canvas.height]); // C153176-6107 : Reduce PDF genrated size.
        let doc = new jspdf('p', 'mm',[canvas.width,canvas.height]);
       /*  var options = {
          pagesplit: true
        };
        var source = popupWin.document.body;
        doc.fromHTML(source, 10, 10, 
        {pagesplit: true}
        , function () {
          doc.save("test.pdf");
        }); */

        
        var position = 0;
        var imgData = canvas.toDataURL('image/jpeg', 1.0 );
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST'); // C153176-6107 : Speedup pdf generation.
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST'); // C153176-6107 : Speedup pdf generation.
        heightLeft -= pageHeight;
        }
           // doc.save('Conversation_history.pdf');
            
           // window.print();
           // C170665-375 PDF formatting issue post download
           popupWin.print();
           popupWin.close();
        });
      }, 200)  //C153176-5352:Increased time to load complete content before print
    }

  }

  /**
   * Emit a data change event
   */
  public triggerDataChange(option:any): void {
    this.dataChange.emit(option);
  }

  /**
   * Emit a Http Success event
   */
  public triggerHttpSuccess(flag): void {
    this.httpResponse.emit('' + flag);
  }

  /**
   * Emit a Reset Search event
   */
  public triggerResetSearch(event): void {
    this.resetSearch.emit(event);
  }

  /**
   * Emit a Reset Search event
   */
  public triggerSearchTabEvent(event): void {
    this.searchTabEvent.emit(event);
  }

  /**
   * Emit a Row Drag Drop event
   * @param event row drag drop event
   */
  public triggerRowDragDrop(event): void {
    this.rowDragDropEvent.emit(event);
  }

  /**
   * Emit an Age Data event
   */
  public triggerAgeData(event): void {
    this.ageDataEvent.emit(event);
  }

  /**
   * Emit an Inquiry Drag event
   */
  public triggerInquiryDrag(event): void {
    this.inquiryDragEvent.emit(event);
  }

  fetchConvSuggestions(data): Observable<any> {
    return this.http.post(this.nlpURL + '/fetchConvSuggestions', JSON.stringify(data), httpOptions);
  }

  refreshSuggestions(data): Observable<any> {
    return this.http.post(this.nlpURL + '/refreshSuggestion', JSON.stringify(data), httpOptions);
  }

  declineSuggestions(data): Observable<any> {
    return this.http.post(this.nlpURL + '/declineSuggestions', JSON.stringify(data), httpOptions);
  }

  getAutoRoutedWorkflows(data): Observable<any> {
    return this.http.post(this.nlpURL + '/getAutoRoutedWorkflows', JSON.stringify(data), httpOptions);
  }

  reassignAutoAssignedWorkflows(data): Observable<any> {
    return this.http.post(this.nlpURL + '/reassignAutoAssignedWorkflows', JSON.stringify(data), httpOptions);
  }

  submitIntentSuggestionFeedback(data): Observable<any> {
    return this.http.post(this.nlpURL + '/intentSuggestionFeedback', JSON.stringify(data), httpOptions);
  }

  /**
   * Method to download all the attachments for specific inquiry or conversation.
   * @param inquiryId - Inquiry Id
   * @param convId - Conversation Id to download conversation specific attachments
   */
  downloadAllAttachments(inquiryId: number, convId?: number, groupId?:number): void {
    const attachementUrl = this.baseURL + '/downloadAllAttachments';

    let request: AttachmentRequest = { inquiryId: inquiryId, convId: convId, groupId : groupId};

    let fileName = inquiryId + '.zip';
    if (convId) {
      fileName = convId + '.zip';
    }

    const body = JSON.stringify(request);
    this.http.post(attachementUrl, body, { responseType: "arraybuffer" }).subscribe(result => {
      var newBlob = new Blob([result], { type: "application/octet-stream" });
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download = fileName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }, error => {
      console.log('error :', error);
    });
  }

  /**
   * Request opening a message
   */
  public requestView(options): void {
    this.viewRequest.emit(options);
  }

  /**
   * Start view loading
   */
  public startViewLoading(options): void {
  }

  /**
   * Stop view loading
   */
  public stopViewLoading(): void {
    this.showSpinner = false;
    console.debug(":SPINNER:: "+ this.showSpinner+" ::inbox-service.stopViewLoading");
    this.viewRequest.emit({ loading: 'stop' });
  }

   /**
   * Method to get all the inquiry conversation details based on inquiry id and its assigned group.
   * 
   * @param requestObj - Request JSON object.
   */
  getInquiryIdExtSelected(requestObj: any, inquiry: any = undefined): Observable<any> {
    // C170665-524 Performance Issue - While switching an inquiry in the grid
    // removal of cache to avoid caching of large contents on UI
    //C153176-4456: cache lookup first, and request service if cache miss
    /* let cachedResp = this.requestCacheService.lookupCache(inquiry, requestObj.isAllConvContent);
    let isChatView = requestObj.isChatView ? requestObj.isChatView : false;
    if (cachedResp && !isChatView) { // C153176-5216 : Don't get data from cache if request comes from Chat view
      return from([cachedResp]);
    } */
    return this.http.post(this.extInqUrl + '/getAllInquiryConversations', requestObj, httpOptions);
  }

  getAllInquiryConversationsForServerSidePaging(requestObj: any, inquiry: any = undefined): Observable<any> {
    return this.http.post(this.extInqUrl + '/getAllInquiryConversationsWithPaging', requestObj, httpOptions);
  }

  saveColumnPreferences(requestObj: any): any{
    return this.http.post(this.baseUrlUser + '/saveCustomColumnsForDefaultViews', requestObj, httpOptions)
  }

  saveMyViewColumnPreferences(requestObj: any):any{
    console.log('request is:'+requestObj);
    return this.http.post(this.baseUrlUser + '/saveUserDefaultBoxColumns', requestObj, httpOptions)
  }
  setDefautScreen(isDefault: any): void {
    this.isDefault = isDefault;
  }
  getDefautScreen(): Observable<any> {
    return this.isDefault;
  }

  /**
   * C153176-4795 Cache request to support cancellation of global search
   * @param viewName view name
   * @param requestObj current request object
   */
  cacheOrUpdateRequest(viewName: string, tabName: string, requestObj) {
    if (!viewName || !requestObj) {
      return requestObj;
    }
    let key = viewName;
    let forceUpdate: boolean = false;
    if (tabName === 'DASHBOARD') {
      key = tabName;
    }
    if (viewName === QmaConstant.QMA_PERSONAL) {
      key = tabName;
      return this.requestObj;                                                                                         
    }
    if (!this.requestCache[key] || (!requestObj.rollback && !requestObj.from)) {
      // if the view's request was not cached, or this is NOT a global search/cancel request, cache the request
      this.requestCache[key] = requestObj;
      return requestObj;
    }
    if (requestObj.rollback) {
      const prevRequest = this.requestCache[key];
      if (prevRequest) {
        // return the previously cached request
        return prevRequest;
      }
    } else if (requestObj.from !== 'search') {
      this.requestCache[key] = requestObj;
    }
    // if all other cases, return current requestObj
    return requestObj;
  }

  /**
   * C153176-4895:
   * Return cached request per specified viewName if it is cached and not a rollback request.
   * This is necessary as the inboxService.requestObj is shared by multiple views (folders), only the requestCache
   * maintains them at per view basis.
   * If the view doesn't have request cached, or it is a rollback request, return the one from current service.
   */
  getRequestObj(viewName, tabName) {
    let key = viewName;
    let forceUpdate = this.resetRequest; // C153176-4700: take resetRequest flag
    if (tabName === 'DASHBOARD') {
      key = tabName;
      if (this.requestObj && this.requestObj.isRequestFromMailBoxStates && this.requestObj.viewName !== viewName) {
        forceUpdate = true;
      }
    }
    if (viewName === QmaConstant.QMA_PERSONAL) {
      key = tabName;
      return this.requestObj;                                                                                         
    }
    if (forceUpdate || !key || !this.requestCache[key] || this.requestCache[key].rollback) {
      return this.requestObj;
    }
    return this.requestCache[key];
  }

  notifyMobileCheckboxSelection() {
    this.mobileSelectionEnabledEvent.emit(this.mobileCheckboxSelected);
  }

  onSelectAll(val:boolean){
    this.selectAllEvent.emit(val);
  }

  enableMobileSearch() {
    this.mobileSearchEnabled = true;
    this.mobileSearchEnabledEvent.emit(this.mobileSearchEnabled);
  }

  disableMobileSearch() {
    this.mobileSearchEnabled = false;
    this.mobileSearchEnabledEvent.emit(this.mobileSearchEnabled);
  }

  onKeySearchText(text) {
    this.onKeySearchTextEvent.emit(text);
  }

  notifyMobileSearchChanged(ids) {
    this.onMobileSearchChangedEvent.emit(ids);
  }
  // C153176-4676: mobie search: global or adv-search
  triggerMobileSearch(type) {
    this.mobileSearchTriggerEvent.emit({search: type});
  }

  triggerMobileSort(sortSpec) {
    this.mobileSortTriggerEvent.emit(sortSpec);
  }

  // C153176-5019: introduce updateOnly false, optional, default to false
  notifyGridDataReady(tabName, updateOnly = false) {
    this.mobileGridDataReadyEvent.emit({tabName: tabName, updateOnly: updateOnly});
  }

  // C153167-4925: notify mobile mailbox of data update
  notifyGridDataUpdate(tabName) {
    this.mobileGridDataUpdateEvent.emit({tabName: tabName});
  }

  /**
   * Method to save the user feedback from cvhat view bubble content.
   * 
   * @param requestObj - Request JSON object.
   */
  saveUserAIMLFeedback(requestObj: any): Observable<any> {
    return this.http.post(QmaConstant.BASE_URL + '/generic/saveUserAIMLFeedback', requestObj, httpOptions);
  }

  /**
   * C153176-5296 | Download Excel should contain all the records in the folder
   * Method to download the grid rows.
   * 
   * @param requestObj - Request JSON object.
   */
  exportInquiriesToFile(requestObj: any): void {
    this.http.post(QmaConstant.BASE_URL + '/inquiry/exportInquiriesToFile', requestObj, httpOptions).subscribe(details => {
      if (details) {
        console.log('Exporting inquiries is in process, requestObj=', requestObj); // C153176-5296, log request obj
        this.downloadExportedFile(details['filename'], true);
      }
    }, error => {
      throw new Error("Internal Address Data server call failed with the message:" + error);
    });
  }

  /**
   * C153176-5296 | Download Excel should contain all the records in the folder
   * Method to download the requested file when avaialble.
   * 
   * @param fileName - File name with extension
   * @param isDefaultFileNameUsed - true or false
   */
  downloadExportedFile(fileName: string, isDefaultFileNameUsed: boolean): void {
    const attachementUrl = QmaConstant.BASE_URL + '/inquiry/downloadExportedFile?filename=' + fileName + '&isDefaultFileNameUsed=' + isDefaultFileNameUsed;
    this.http.get(attachementUrl, { responseType: "blob" }).subscribe(result => {
      var newBlob = new Blob([result], { type: "application/octet-stream" });
      const nav = (window.navigator as any);
      if (nav && nav.msSaveOrOpenBlob) {
        nav.msSaveOrOpenBlob(newBlob);
        return;
      }
      const data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download = "Export.csv";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    }, error => {
      console.log('error :', error);
    });
  }

  /**
   * C153176-5343: Set file drag state
   */
  setFileDragState(val) {
    if (this.fileDragState !== val) {
      this.fileDragState = val;
      if (val && !this.fileDragStateTimer) {
        this.fileDragStateTimer = setInterval(() => {
          this.checkAndClearDragState();
        }, 8000); // C153176-5343: set internal of 8 seconds per iteration
      } else if (!val) {
        this.checkAndClearDragState(true);
      }
      console.debug('setFileDragState', val)
      this.fileDragEvent.emit({qma: true, active: this.fileDragState});
    } else if (!val) {
      // both val and internal 'this.fileDragState' is false, stop the timer
      this.checkAndClearDragState(true);
    }
  }

  /**
   * C153176-5343: Clear/reset file drag state as well as internal up to 2 iteration
   */
  checkAndClearDragState(clearTimer = false) {
    if (this.fileDragStateTimer) {
      if (clearTimer || this.fileDragIntervalCounter > 1) {
        clearInterval(this.fileDragStateTimer);
        this.fileDragStateTimer = null;
        this.fileDragIntervalCounter = 0;
        if (this.fileDragState) {
          this.fileDragState = false;
          // notify th file drag end
          console.debug('checkAndClearDragState, 4', this.fileDragState)
          this.fileDragEvent.emit({qma: true, active: this.fileDragState});
        }
      } else {
        this.fileDragIntervalCounter++;
      }
    }
  }

  /**
   * Method to update the user activity.
   * 
   * @param requestObj 
   */
  userActivity(requestObj: any): Observable<any> {
    return this.http.post(this.genericUrl + '/useractivity', requestObj, httpOptions);
  }
  
  /**
   * Method to save the console log to server.
   * 
   * @param allLogAsString - Log.
   */
  saveUILog(allLogAsString: any): Observable<any> {
    let requestObj = { consoleLogArray: allLogAsString };
    return this.http.post(QmaConstant.BASE_URL + '/generic/saveUIConsoleLog', JSON.stringify(requestObj), httpOptions);
  }
}

export interface AttachmentRequest {
  inquiryId: number;
  convId?: number;
  groupId?: number;
}