import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, ViewChild, ChangeDetectorRef, HostListener } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { TabDataService } from '../../services/tab-data.service';
import { TabViewModule, TabView } from 'primeng/tabview';
import { TextValueLabel } from '../../model/Common/TextValueLabel';
import { InboxService } from '../../services/inbox.service';
import * as Rx from "rxjs";
import { ConfirmationService } from 'primeng/api';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { LeftNavigationService } from 'src/app/core/leftnav/leftnav.service';
import { environment } from 'src/environments/environment';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { GridOperationService } from "src/app/services/mailbox/grid-operation.service"; 
import { PersonalEmailService } from 'src/app/personal-email/personal-email.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  providers: [ConfirmationService],
  /* host: {
    '(document:keydown)': 'onKeyDown($event)'
  }, */
})
export class TabsComponent {
  static readonly SEARCH_ELIGIBLE_TABS = ["Inbox", "Sent", "Resolved"];
  static readonly NEW_MESSAGE_COMPONENTS = ["NEW MESSAGE", QmaConstant.QMA_NEW_BUBBLE, "Reply", "Forward", "ReplyAll", "ReplyResolve", "ReplyAllResolve", "DRAFT"];
  static readonly TAB_TEXT = ["NEW MESSAGE",QmaConstant.QMA_NEW_BUBBLE, "Reply", "Forward", "ReplyAll", "ReplyResolve", "ReplyAllResolve", "SEARCH", "DASHBOARD","Sent", "INQUIRY:","CHAT:"];
  static readonly DRAGDROP_ENABLED = ["NEW MESSAGE",QmaConstant.QMA_NEW_BUBBLE, "Reply", "Forward", "ReplyAll", "ReplyResolve", "ReplyAllResolve", "INQUIRY:", "CHAT:"];
  selectedTabIndex: number;
  public items: TextValueLabel[];
  subscription: Subscription;
  unreadSubscription: Subscription; 
  MAX_CHAR: number = 3;
  @ViewChild('tabs', { static: true }) tabs: TabView;
  triggeredFromMessage: boolean = false;
  tabName: any;
  headerStyle: any = "header-class";
  selectedHeaderName: string;
  lastSelectedHeaderName: string;
  indexMatch = {
    NONE: 0,
    EXACT: 1,
    ELIGIBLE: 2,
    REPLACE: 3
  };
  mailBoxView: string;
  @Output() searchClr = new EventEmitter<boolean>();
  currentInquiries: any = [];
  innerWidth: number;
  innerHeight: number;
  borderHighlight = false;
  isMobile:boolean = false;

  public inMobileSearch: boolean = false;
  dashboardCountSubs$: Subscription;
  tabNameforChatViewSymphony : string = "";
  constructor(
    private userDataService: UserDataService,
    public tabDataService: TabDataService, private inboxService: InboxService, private confirmationService: ConfirmationService, 
    private dashboardService: DashboardService, private leftNavigationService: LeftNavigationService,
    private cdr: ChangeDetectorRef, private gridOprService: GridOperationService, private personalEmailService: PersonalEmailService) { //C153176-5107, //C153176-4796: refactoring from mobile inbox view and enable change detection
    this.isMobile = window.innerWidth <= QmaConstant.MOBILE_WIDTH;
    this.items =  this.isMobile? []:[{ text: 'DASHBOARD', value: ''}];
   
    this.subscription = this.tabDataService.getTabData().subscribe(tabData => {
      //reset tab header if it is Mobile version so as to use the same tab
      if (this.isMobile && tabData.header === "SEARCH" && this.selectedTabIndex != -1 && this.selectedHeaderName) {
        tabData.header = this.selectedHeaderName;
      }
      //refactor mobile action footer here 
      this.tabDataService.footerMailboxArray.forEach(val =>{
        val.isActiveTab = (val.label.toUpperCase() == tabData.header.toUpperCase());
      })
      this.triggeredFromMessage = (tabData.triggeredFrom === 'leftNav');
      const updateEligibleViewOnly = (tabData.triggeredFrom === 'updateEligibleViewOnly');
      this.tabName = tabData.header;
      if (this.tabName.startsWith('INQUIRY:') || this.tabName.startsWith('CHAT:')) {
        // capture the current inquiry from tabDataService
        //cache multiple double-click-opened inquiries
        this.currentInquiries[tabData.header] = this.tabDataService.currentInquiry;
        this.currentInquiries[tabData.header].mailContainerHeight = Math.max(200, this.innerHeight - 30);
        // reset the tabDataService's current inquiry
        delete this.tabDataService.currentInquiry;
      }
      
      sessionStorage.setItem("currentTab", tabData.header);
      const matchResult = this.checkSearchEligiblePanel(tabData.header);
      if (matchResult === this.indexMatch.NONE) {
        if (updateEligibleViewOnly) {
          //skip opening new tab, no eligible view to update, nor to open any new one.
          return;
        }

        //TO fix initial load issue
        if(this.tabs.tabs){
          this.tabs.tabs.forEach(tab => {
            tab.selected = false;
          });
        }
        this.items.push(new TextValueLabel(tabData.header, tabData.count,tabData.symphonyData));
        this.dashboardCountSubs$ = this.dashboardService.getDashboardCountsServiceSubject().subscribe(dashSnapshotCnts => {
          if (dashSnapshotCnts) {
            if(dashSnapshotCnts.boxCounts){
              dashSnapshotCnts.boxCounts.forEach((val:any) =>{
                let t = this.items.find(item => item.text.toUpperCase() == Object.keys(val)[0].toUpperCase());
                if(t){
                  t.value = val[Object.keys(val)[0]].unReadCount.$numberLong;
                }
              })
            }
          }
        })    
        this.selectedTabIndex = this.getIndex(this.items, tabData.header);
        //  save current selected header to lastSelectedHeaderName
        this.lastSelectedHeaderName = this.selectedHeaderName;//
        this.selectedHeaderName = tabData.header;//
      } else {
        this.tabs.tabs.forEach(tab => {
          tab.selected = false;
        });
        if (matchResult == this.indexMatch.EXACT) {
          // exact match
          this.selectedTabIndex = this.getIndex(this.items, tabData.header);
          // save current selected header to lastSelectedHeaderName
          this.lastSelectedHeaderName = this.selectedHeaderName;
          this.selectedHeaderName = this.items[this.selectedTabIndex].text;
        } else if (matchResult == this.indexMatch.ELIGIBLE) {
          // search eligible panels
          this.selectedTabIndex = this.getSearchEligibleIndex(this.items, TabsComponent.SEARCH_ELIGIBLE_TABS, this.selectedTabIndex);
          //update 'currentTab' with the eligible tab's header, so that it points to the right tab
          sessionStorage.setItem("currentTab", this.items[this.selectedTabIndex].text);
          //save current selected header to lastSelectedHeaderName
          this.lastSelectedHeaderName = this.selectedHeaderName;
          this.selectedHeaderName = this.items[this.selectedTabIndex].text;
        } else if (matchResult == this.indexMatch.REPLACE) {
          this.selectedTabIndex = this.getReplacedIndex(tabData.header);
          //save current selected header to lastSelectedHeaderName
          this.lastSelectedHeaderName = this.selectedHeaderName;
          this.items[this.selectedTabIndex].text = tabData.header;
          this.selectedHeaderName = tabData.header;
        }
        this.tabs.tabs[this.selectedTabIndex].selected = true;
        if (!updateEligibleViewOnly || matchResult === this.indexMatch.ELIGIBLE) {
          // if updateEligibleViewOnly is true, update only if the match result is ELIGIBLE;
          // in all other cases, proceed update (trigger data change).
          if (updateEligibleViewOnly || matchResult === this.indexMatch.ELIGIBLE) {
            // start loading spin here as this is the only place where it is certain there is a reload to be triggered
            this.inboxService.startViewLoading({color: '#020e38'});
          }

          // This code should execute only when user perform global search and grid open from dashboard.
          if (this.tabName === 'SEARCH' || this.tabName === QmaConstant.QMA_OPEN_INQ_BYASSIGNEDGROUP ||
            this.tabName === QmaConstant.QMA_OPEN_INQ_BYREQUESTTYPE || this.tabName === QmaConstant.QMA_OPEN_INQ_BY_OWNER
            || this.tabName === QmaConstant.QMA_PERSONAL) {
            this.inboxService.triggerDataChange({ tabName: this.tabs.tabs[this.selectedTabIndex].header, dashboardInlineView: false });
          }
          // trigger search for mobile 
          if (this.isMobile) {
            if (tabData.triggeredFrom == 'leftNav') {
              this.inboxService.triggerDataChange({ tabName: this.tabs.tabs[this.selectedTabIndex].header, dashboardInlineView: false });
            } else {
             /*  if ((this.tabName === 'Inbox')) { */
                this.inboxService.triggerDataChange({ tabName: this.tabs.tabs[this.selectedTabIndex].header, dashboardInlineView: false });
              /* } */
            }
          }
        }
      }
      //this.tabDataService.tabs = this.tabs; // PERFORAMCE IMPROVEMENT - Why is the need of this we are updating the tab-panels
      if (inboxService.requestObj && inboxService.requestObj.from) {
        this.inboxService.triggerSearchTabEvent({index: this.selectedTabIndex, tab: this.selectedHeaderName, action: 'addUpdate'});
      } else {
        this.inboxService.triggerSearchTabEvent({index: this.selectedTabIndex, tab: this.selectedHeaderName, action: 'clear'});
      }
    });
    // Layout option fromm UserProfile
    this.inboxService.getLayoutOption().subscribe(lo => {
      this.mailBoxView = lo.toLocaleLowerCase();
    });
    // inquiry drag highlight handling
    this.inboxService.inquiryDragEvent.subscribe((evt :any)  => {
      if(evt && evt.qma) {
        this.borderHighlight = evt.active;
      }
    });
    this.inboxService.mobileSearchEnabledEvent.subscribe(flag => {
      // entering / leaving search mode
      this.inMobileSearch = flag;
      this.cdr.detectChanges();
    });
    this.unreadSubscription = this.tabDataService.onUnReadCount().subscribe(tuple => {
      let name = tuple.name;
      let count = tuple.count;
      if (this.items && name && (count !== undefined)) {
        let index = this.items.findIndex(item => item.text && item.text.toUpperCase() === name.toUpperCase());
        if (index > -1) {
          this.items[index].value = count;
          this.cdr.detectChanges();
        }
      }
    });
  }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;
    this.tabDataService.markTabComponentReady();
    this.dashboardService.getUpdatedUnreadCount().subscribe(data=> {
      if(this.isObjetEmpty(data)) {
        return;
      }
      this.items.filter(i=>i.text.toUpperCase() === data.tabName)[0].value = data.updatedUnread;
    });
    // close search tab on click of close icon of serch input
    this.tabDataService.getsubjectOnTabClose().subscribe((tabHeader:string)=>{
     const index = this.tabs.tabs.findIndex(t=> t.header === tabHeader)
      if(index > -1 ) {
        let event = {index:index,close:function(){}};
        this.performTabOperation(event,tabHeader);
      }

    });
  }

  /**
   * Method to change the view type of conversation
   */
  onImgClick(clickedIcon: string, tabName: string) {

    if (tabName === 'chatview' && this.mailBoxView != tabName) {
      let requestObj = {
        action: "SWITCH TO CHAT VIEW",
        description: "Switch to chat view in " + clickedIcon
      };

      this.inboxService.userActivity(requestObj).subscribe(result => {
        if (result) {
          console.log('User activity is saved.', result);

          // Add the start time
          this.tabDataService.setChatViewUsageStatistics(clickedIcon);
        }
      }, error => {
        console.error('Failed to add user activity', error);
        // Add the start time
        this.tabDataService.setChatViewUsageStatistics(clickedIcon);
      });
    } else {
      // Update the time.
      this.tabDataService.updateChatViewUsageStatistics(clickedIcon);
    }

    this.tabDataService.setClickedIcon(tabName);
    this.mailBoxView = tabName;
    this.tabDataService.tabName = tabName;
  }
  
  /**
   * Check whether the specified tabName matches any of the existing tab header.
   * @param tabName tab header
   * @return:
   *  NONE (0): no match
   *  EXACT (1): exact match in header string
   *  ELIGIBLE (2): If the tabName is SEARCH, and one of the selected tabs is Inbox, Sent or Resolved.
   */
  checkSearchEligiblePanel(tabName: string): number {
    if (this.items.some(item => item.text === tabName)) {
      // exact match
      return this.indexMatch.EXACT;
    }
    if (tabName && tabName.toUpperCase() === "SEARCH"
      && TabsComponent.SEARCH_ELIGIBLE_TABS.some((val) => this.items.some(item => item.text === val))
      && !this.isMultiBoxMatched()
      && this.getSearchEligibleIndex(this.items, TabsComponent.SEARCH_ELIGIBLE_TABS, this.selectedTabIndex) != -1) {
      // in case of SEARCH, at least one of the tables is Inbox, Sent, Resolved.
      return this.indexMatch.ELIGIBLE;
    } else if (tabName && tabName.toUpperCase().startsWith('REPLY') &&
      this.items.some(item => item.text && item.text.toUpperCase().replace('ALL', '') === tabName.toUpperCase().replace('ALL', ''))) {
      return this.indexMatch.REPLACE;
    }
    // other cases (non-match)
    return this.indexMatch.NONE;
  }
  getIndex(data, value, upperCase?) {
    let index = -1;
    data.forEach((item, i) => {
      if (item.text === value || (upperCase && item.text.toUpperCase() === value.toUpperCase())) {
        index = i;
      }
    });
    return index;
  }
  /***
   * Search and return any eligible index.
   * Eligibility is defined as when the one of the items in 'data' appears in the 'values' collection, and it is the selected index,
   * and also, it is in the adv-search Search In boxes if it is present (selected by user) 
   * @param data
   * @param values
   * @return the eligible index. If no such index is found, return -1.
   */
  getSearchEligibleIndex(data, values, selectedIndex) {
    let index = -1;
    data.forEach((item, i) => {
      if (values.includes(item.text) && selectedIndex === i && 
        (!this.inboxService.searchBoxes || !this.inboxService.searchBoxes.length ||
          this.inboxService.searchBoxes.includes(item.text))) {
        index = i;
      }
    });
    return index;
  }
  getReplacedIndex(tabName) {
    let index = -1;
    const normalizedName = tabName.toUpperCase().replace('ALL', '');
    this.items.forEach((item, i) => {
      if (item.text && item.text.toUpperCase().replace('ALL', '') === normalizedName) {
        index = i;
      }
    });
    return index;
  }
  handleChange(event: any) {
    this.gridOprService.clearConversationRequestData();
    // make the existing selected tab's property selected = false
    this.tabs.tabs.forEach(tab => {
      tab.selected = false;
    });
    this.selectedTabIndex = event.index;
    this.tabs.tabs[this.selectedTabIndex].selected = true;
    this.lastSelectedHeaderName = this.selectedHeaderName;
    this.selectedHeaderName = this.tabs.tabs[this.selectedTabIndex].header;//
    this.tabDataService.setTabChange(this.selectedHeaderName)
    sessionStorage.setItem("currentTab", this.selectedHeaderName);
    this.userDataService.userUsageStats("Dashboard", this.selectedHeaderName).subscribe(result => console.log("StartTime:"+new Date()));
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Tab", this.selectedHeaderName, "Click",this.selectedHeaderName,0)
  }
  handleClose(event: any) {
    const closedHeaderName = this.tabs.tabs[event.index].header;
    if (closedHeaderName.includes("NEW MESSAGE") && event.originalEvent.isTrusted) {

      this.confirmationService.confirm({
        message: 'Do you want to save changes?',
        accept: () => {
          this.userDataService.userUsageStats("New Message", "Save Draft").subscribe(result => console.log("StartTime:"+new Date()));
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Menu", "New message - Save Draft", "Click", "New message -Save Draft", 0);
          this.performTabOperation(event, closedHeaderName);
          const closeTab = {
            draftFlag: true,
            id:closedHeaderName
          };
          this.tabDataService.saveDraftOnTabClose(closeTab);
        },
        reject: () => {
          this.performTabOperation(event, closedHeaderName);
          this.userDataService.userUsageStats("Dashboard", "Dashboard").subscribe(result => console.log("StartTime:"+new Date()));
        }
      });
    } else {
      this.performTabOperation(event, closedHeaderName);
      this.userDataService.userUsageStats("Dashboard", "Dashboard").subscribe(result => console.log("StartTime:"+new Date()));
    }
  }
  performTabOperation(event, closedHeaderName) {
    event.close();
    this.items.splice(event.index, 1);
    // if last tab is closed without selecting any other tab 
    if (this.selectedHeaderName == this.lastSelectedHeaderName &&
      this.selectedHeaderName == closedHeaderName) {
      this.selectedHeaderName = this.tabs.tabs[event.index > 0 ? event.index - 1 : 0].header;

      this.lastSelectedHeaderName = this.selectedHeaderName;
      this.tabs.tabs[0].selected = false;
    }
    // if closed tab name = selected tab, keep the last selected tab as selected
    else if (closedHeaderName == this.selectedHeaderName) {
      this.selectedHeaderName = this.lastSelectedHeaderName;
      if (!this.selectedHeaderName) {
        this.selectedHeaderName = this.tabs.tabs[event.index > 0 ? event.index - 1 : 0].header;
      }
      this.tabs.tabs[0].selected = false;
      // default selected tab, then close any other tab 
    } else {
      if (this.selectedTabIndex !== -1 && this.tabs.tabs[this.selectedTabIndex]) {
        const header = this.tabs.tabs[this.selectedTabIndex].header;
        this.lastSelectedHeaderName = this.selectedHeaderName;
        this.selectedHeaderName = this.tabs.tabs[this.selectedTabIndex].header;
      }
      // reset selected state
      this.tabs.tabs[0].selected = false;
    }
    
    this.selectedTabIndex = this.getIndex(this.items, this.selectedHeaderName);

    if (this.selectedTabIndex !== -1) {
      //Find the index of selected header name and selected that specific tab as on close tabs object still remain same until removed.
      let tabIndex = this.tabs.tabs.findIndex(tab => tab.header == this.selectedHeaderName);
      this.tabs.tabs[tabIndex].selected = true;
    }
    // Clear the search criteria on tab close except new message
    if(this.userDataService.loggedInUserInfo.taskizeConfig && this.userDataService.loggedInUserInfo.taskizeConfig.taskizeEnabled && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser?.toUpperCase() == 'Y') {
      if (!closedHeaderName.includes("NEW MESSAGE") || !closedHeaderName.includes(QmaConstant.QMA_NEW_BUBBLE)) {
        this.searchClr.emit(true);
      }
    } else {
      if (!closedHeaderName.includes("NEW MESSAGE")) {
        this.searchClr.emit(true);
      }
    }
    
    
    if (this.selectedTabIndex !== -1 && this.isMobile) {
      this.tabDataService.sendTabData(this.items[this.selectedTabIndex].text, this.items[this.selectedTabIndex].value, 'leftnav');
    } else {
      sessionStorage.setItem("currentTab", this.selectedHeaderName); // on close of a tab, got to update session storage
      this.inboxService.triggerSearchTabEvent({action: 'close', tab: closedHeaderName});
    }
    let inqId: number;
    const currIdArr = closedHeaderName.match(/\d+/g);
    if (currIdArr) {
      inqId = currIdArr[0];
      let map = this.tabDataService.clcData;
      map.forEach((v, k, map) => {
        if (k === inqId) map.delete(k);
      });
    }

    // If the close tab is personal mail box, we need to clear the selected item.
    if(closedHeaderName.includes(QmaConstant.QMA_PERSONAL)){
      this.personalEmailService.setSelectedFolder(null);
    }
  }

  setHeader() {
    if (this.inMobileSearch) {
      return 'mobileSearchTabHeader';
    } else if (this.items.length == 1) {
      return 'hide-tab-header';
    } else {
      return 'tabHeader';
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unreadSubscription.unsubscribe();

    if (this.dashboardCountSubs$) {
      this.dashboardCountSubs$.unsubscribe();
    }
  }
  /**
   * Method to decide tab should contain Inbox or New Message Component.
   */
  isInboxOrNewMessage(tabText: string): boolean {
    let isAllowed: boolean = false;
    const tabDetail = TabsComponent.NEW_MESSAGE_COMPONENTS.find(tabName => tabText.includes(tabName));
    const matchingItem = this.items.find(i => i.text === tabText);
    const triggeredFromTags = matchingItem && matchingItem.inputData && matchingItem.inputData === "triggeredFromTag" ? true : false;
    isAllowed = tabDetail && !triggeredFromTags ? true : false;
    return isAllowed;
  }

  /**
   * Method to decide to show count or not
   */
  showCount(tabText: string): boolean {
    let isAllowed: boolean = false;
    let tabDetail = TabsComponent.TAB_TEXT.find(tabName => tabText.includes(tabName));
    isAllowed = tabDetail ? true : false;
    return isAllowed;
  }
  displayEllipsis(_count: number) {
    if (_count >= 1000) {
      return ((_count / 1000).toFixed(1) + "k");
    }
    else {
      return _count;
    }
  }
  /**
   * Handle keyboard events
   */
  @HostListener('document:keydown.alt.s', ['$event'])
  onKeyDown(event) {
    event.stopPropagation();
    event.preventDefault();
    if (event && event.altKey && !event.ctrlKey && !event.shiftKey && !event.metaKey) {
      if (event.key === 's') {
        this.tabDataService.triggerActionEvent({ action: 'Send', tabName: this.selectedHeaderName });
        // do not propagate further
        return false;
      }
    }
    return true;
  }

  /**
   * Handle row-drag dragover event
   */
  handleRowDragOver(event) {
    if (event && event.innerText) {
      const header = event.innerText;
      const currentIndex = this.getIndex(this.items, header, true);
      if (currentIndex !== -1 && currentIndex !== this.selectedTabIndex) {
        this.tabs.tabs.forEach(tab => {
          tab.selected = false;
        });
        this.selectedTabIndex = currentIndex;
        this.tabs.tabs[this.selectedTabIndex].selected = true;
        this.lastSelectedHeaderName = this.selectedHeaderName;
        this.selectedHeaderName = header;
      }
    }
  }

  /**
   * Handle row-drag drag drop event
   */
  handleRowDragDrop(event: any) {
    if (event && event.innerText && event.data) {
      const header = event.innerText;
      const currentIndex = this.getIndex(this.items, header, true);
      if (currentIndex !== -1 && currentIndex !== this.selectedTabIndex) {
        this.tabs.tabs.forEach(tab => {
          tab.selected = false;
        });
        this.selectedTabIndex = currentIndex;
        this.tabs.tabs[this.selectedTabIndex].selected = true;
        this.lastSelectedHeaderName = this.selectedHeaderName;
        this.selectedHeaderName = header;
      }
      this.inboxService.triggerRowDragDrop({header: header, data: event.data});
      this.inboxService.triggerInquiryDrag({qma: true, active: false});
    }
  }

  /**
   * Return whether this is an Inquiry Tab
   */
  isInquiry(tabText: string): boolean {
    return tabText && tabText.startsWith('INQUIRY:');
  }

  /**
   * Return whether the tabshould be highlighted for drag/drop
   */
  isDragDropHighlight(tabText: string): boolean {
    if (!this.borderHighlight || !tabText) {
      return false;
    }
    return TabsComponent.DRAGDROP_ENABLED.findIndex(tab => tabText.toUpperCase().indexOf(tab.toUpperCase()) !== -1) !== -1;
  }

  /**
   * C153176-4540: check whether a search covers more than one targeted boxes, in which case the SEARCH tab should be
   * used to display search result instead of one of the eligible tabs.
   */
  isMultiBoxMatched() {
    if (!this.inboxService.searchBoxes || !this.inboxService.searchBoxes.length) {
      return false;
    }
    return false; //C153176-5220:Design changed to select single Mailbox, so will always return false.
    /* 
    const filteredBoxes = this.inboxService.searchBoxes.filter(v => TabsComponent.SEARCH_ELIGIBLE_TABS.includes(v));
    return filteredBoxes && (filteredBoxes.length > 1
      || (filteredBoxes.length === 1 && this.inboxService.searchBoxes.length > 1));*/
  }

  
  switchTab(item:any){
    if(item.label == "New Message"){
      this.tabDataService.sendTabData('NEW MESSAGE', 0, 'leftNav');
    }
    else if(item.label == "Dashboard"){
      this.tabDataService.sendTabData(item.label.toUpperCase(), 0);
    }else{
      this.inboxService.checkBeforeLoad = true;
      this.tabDataService.sendTabData(item.label, item.count, 'leftNav');
      this.leftNavigationService.formRequestObjectOfGrid(item.label);
    }
  }

  isObjetEmpty(obj) {
    return Object.keys(obj).length === 0 ? true : false;

  }

   /**
   * Return whether this is an Inquiry Tab
   */
  isSymphonyChat(tabText: string): boolean {
    return tabText && tabText.startsWith('CHAT:');
  }
}