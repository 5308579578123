import {
  Component, OnInit, Input, Inject, AfterViewInit, ElementRef, SimpleChanges, SimpleChange, OnChanges,
  ViewChildren, QueryList, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { MyGroup } from '../../model/LoginUserInfo/MyGroup';
import { GetContactsService } from '../../services/newMessage/get-contacts.service';
import { AllToCcDBUser } from '../../model/LoginUserInfo/AllToCcDBUser';
import { AllActiveGroup } from '../../model/LoginUserInfo/AllActiveGroup';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OverlayPanel } from "primeng//overlaypanel";
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { isNull } from 'util';
import { GfidSearchComponent } from "src/app/common/component/gfid-search/gfid-search.component";
import { ViewChild, Output, EventEmitter } from "@angular/core";
import { NewMessageService } from '../../services/newMessage/new-message.service';
import { QmaConstant } from '../../constant/qma-constant';
import { NewMessageComponent } from "src/app/new-message/new-message.component";
import { Subscription } from 'rxjs';
import { InboxService } from '../../services/inbox.service';
import { AppUtils } from '../../common/utility/appUtil';
import { MessageService } from 'primeng/api';
import { CmcsearchComponent } from '../cmcsearch/cmcsearch.component';
import { MyFavoriteContact, GroupMemberContact } from '../../model/LoginUserInfo/MyFavoriteContact';
import { CheckboxListComponent } from '../../common/component/checkbox-list/checkbox-list.component';
import { ClcService } from '../../services/clc/clc.service';
import { UserSignature } from "src/app/model/LoginUserInfo/UserSignature";
import { environment } from 'src/environments/environment';
import { GlobalDirectoryService } from 'src/app/services/global-directory/global-directory.service';
import { TopContacts } from 'src/app/model/LoginUserInfo/TopContacts';
import { debounceTime, distinctUntilChanged, ignoreElements } from 'rxjs/operators';
import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
import  * as _ from 'underscore';
import { AttachmentViewerDialogComponent } from 'src/app/common/component/attachment-viewer/attachmentviewer.dialog.component';
import { DateFormatPipe } from 'src/app/common/pipes/date-format.pipe';
import { TaskizeAuthService } from 'src/app/services/taskize-auth.service';
import { AutoComplete } from 'primeng/autocomplete';
interface ConversationOption {
  name: string,
  code: string
}

interface attachmentOption {
  name: string,
  id: string,
  fileInfo: any
}



@Component({
  selector: 'new-message-recipent',
  templateUrl: './new-message-recipent.component.html',
  styleUrls: ['./new-message-recipent.component.scss'],
  /* providers: [MessageService] */
})
export class NewMessageRecipentComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren(CheckboxListComponent) checkboxListComponents: QueryList<CheckboxListComponent>;
  @Input()
  inboxView: any;

  existingConvAttachements: any;
  extensionImgIconMapping: any = [
    { extension: 'xlsx', imgUrl: 'assets/Inbox/icon-excel.svg' },
    { extension: 'xls', imgUrl: 'assets/Inbox/icon-excel.svg' },
    { extension: 'svg', imgUrl: 'assets/Inbox/icon-img.svg' },
    { extension: 'pdf', imgUrl: 'assets/Inbox/icon-pdf.svg' },
    { extension: 'pptx', imgUrl: 'assets/Inbox/icon-power-point.svg' },
    { extension: 'ppt', imgUrl: 'assets/Inbox/icon-power-point.svg' },
    { extension: 'svg', imgUrl: 'assets/Inbox/icon-txt.svg' },
    { extension: 'docx', imgUrl: 'assets/Inbox/icon-word.svg' },
    { extension: 'doc', imgUrl: 'assets/Inbox/icon-word.svg' },
    { extension: 'zip', imgUrl: 'assets/Inbox/icon-zip.svg' },
    { extension: 'unkown', imgUrl: 'assets/Inbox/icon_unknown_file.svg' },
    { extension: 'eml', imgUrl: 'assets/Inbox/icon-email.svg' },
    { extension: 'html', imgUrl: 'assets/Inbox/icon-html.svg' },

    { extension: 'gif', imgUrl: 'assets/Inbox/GIF_icon.svg' },
    { extension: 'jpg', imgUrl: 'assets/Inbox/JPG_icon.svg' },
    { extension: 'jpeg', imgUrl: 'assets/Inbox/JPG_icon.svg' },
    { extension: 'png', imgUrl: 'assets/Inbox/PNG_icon.svg' },
    { extension: 'jfif', imgUrl: 'assets/Inbox/GIF_icon.svg' },
    { extension: 'tiff', imgUrl: 'assets/Inbox/TIFF_icon.svg' },
    { extension: 'txt', imgUrl: 'assets/Inbox/icon_text_attachment.svg' },

  ];
  @ViewChild('attachvwr') attachViewer: AttachmentViewerDialogComponent;
  menuIconImageConfig = {
    copyIconmouseOut: 'assets/Inbox/copy-iconn-darkblue.svg',
    copyIconmouseOn: 'assets/Inbox/copy-iconn-lightblue.svg',
    copyIconSrc: 'assets/Inbox/copy-iconn-darkblue.svg',
    viewIconmouseOut: 'assets/Inbox/eye-icon-darkblue.svg',
    viewIconmouseOn: 'assets/Inbox/eye-icon-lightblue.svg',
    viewIconSrc: 'assets/Inbox/eye-icon-darkblue.svg',
    downloadIconmouseOut: 'assets/Inbox/download-icon_darkblue.svg',
    downloadIconmouseOn: 'assets/Inbox/download-icon_lightblue.svg',
    downloadIconSrc: 'assets/Inbox/download-icon_darkblue.svg',
    copyAllIconmouseOut: 'assets/Inbox/copy-iconn-darkblue.svg',
    copyAllIconmouseOn: 'assets/Inbox/copy-iconn-lightblue.svg',
    copyAllIconSrc: 'assets/Inbox/copy-iconn-darkblue.svg',
    downloadAllIconmouseOut: 'assets/Inbox/download-icon_darkblue.svg',
    downloadAllIconmouseOn: 'assets/Inbox/download-icon_lightblue.svg',
    downloadAllIconSrc: 'assets/Inbox/download-icon_darkblue.svg',

  }

  sAttachViewUrl: string;
  remainingAttachementCount = 0;
  showAttchementPopup = false;
  selectAllAttach = false;
  guserPrefDateFormat: string = '';
  enableInquirySubStatus: boolean = false;
  inquirySubStatusList: any[];
  /*  attachmentsOptions: attachmentOption[] = []; */
  hasPrevAttach: false;
  enableInquirySubStatusFlag: any;
  displayErrorForBlockedChars = false;
  Qma_blocked_file_name_chars = "";
  showFieldOnlyForTaskize: boolean = false;
  taskizeEnabled: boolean;
  isTaskizeInquiry: boolean;
  taskizeInquirySubjectReadonly: boolean;
  @ViewChild('toUsersData') toUsersData: AutoComplete;
  @ViewChild('ccUsersData') ccUsersData: AutoComplete;
  @ViewChild('bccUsersData') bccUsersData: AutoComplete;
  constructor(private userDataService: UserDataService, private getContactsService: GetContactsService, private builder: FormBuilder, private newMessageService: NewMessageService,
    public parentComponent: NewMessageComponent, private inboxService: InboxService, private messageService: MessageService,
    private globalDirectoryService: GlobalDirectoryService, private dateFormatPipe: DateFormatPipe, private taskizeService : TaskizeAuthService) {

    //TODO:Performance :: Start Time. 
    this.startTime = performance.now();
    this.guserPrefDateFormat = this.userDataService.getDefaultDateFormate();
  }

  groupNames: any[] = []; // C153176-5197
  selectedGroupName: any; // C153176-5197
  filteredToUsersMultiple: any[] = [];
  allToCcDBUser: AllToCcDBUser[] = [];
  allActiveGroups: AllActiveGroup[] = [];
  toUsersList: AllToCcDBUser[] = [];
  ccUsersList: AllToCcDBUser[] = [];
  bccUsersList: AllToCcDBUser[] = [];
  inquirySourceList = [];
  requestTypes = [];
  processingRegions = [];
  rootCauses = [];
  attachmentsOptions: any = [];
  // requestTypeMap = new Map<string, string[]>();
  // processingRegionMap = new Map<string, string[]>();
  // rootCauseMap = new Map<string, string[]>();
  // tagsMap = new Map<string, string[]>();
  query: any;
  note: string = '';
  attachmentLength: any = 0;
  isElipsisShow: boolean = false;
  @Input() parentForm: FormGroup;
  @ViewChild(GfidSearchComponent, { static: true }) GfidSearchComponent: any;
  postDataForAttachmentFile: Array<any> = [];
  @Input() postDataForAttachment: any;
  @Input() template: any;
  @Input() messageInstance: any;
  asyncLoad = new BehaviorSubject("");
  isFirstInstance: boolean = true;
  fromwhere: string = '';
  subManager = new Subscription();

  showContact: boolean = false;
  closeFlagInvalid: boolean = false;
  isClosed: boolean = true;
  isChevronUp: boolean = false; // Jira : C153176-5194 this flag should stay false initially (for web version) 
  //C153176-4703 : Auto select dropdown values in case of single value in new message
  countCheck: number = 2; //Jira : C153176-4703 : The selected Processing region gets unselect after Submit and Refresh
  toggleccbcc: boolean = false; // Jira : C153176-5194 Mobile -To/CC/ BCC changes
  selectedContactId: string = '';
  // Emitter for Notes
  @Output() notesEvent = new EventEmitter();
  @Output() messageContent = new EventEmitter();
  @Output() conversationObj = new EventEmitter();
  @Output() resizeMailBody = new EventEmitter();
  @Input() fromNewMessage: boolean = false;
  @Input() toCcBccData: any;
  @Input() isInlineReply: boolean = false;

  // For disclaimer change event
  @Output() disclaimerEvent = new EventEmitter();
  @Output() expandEvent = new EventEmitter();


  // Notes list to keep the entered notes;
  notesList: any[] = [];

  //userName: string = '';
  citiDomains: any[];
  // @ViewChild('gfpidgfcid') gfpidgfcid: ElementRef;
  gfpidgfcid = '';
  mandatoryForResolveFlags = [];
  queryCountMsg: string = '';
  loggedInUser: string = '';
  @ViewChild(CmcsearchComponent) cmcsearchComponent: any;
  @Output() secure = new EventEmitter();
  @Output() group = new EventEmitter();

  // Property to for disable AutoComplete
  isAutoCompleteDisabled: boolean = false;

  // name of the tab in which this component is used
  tabName: string = undefined;
  @Input() resolve: any;
  /** Defined the variable for Add to Top contacts and show member list */
  addToTopFlag: boolean = false;
  addOptions: any = [];
  setData: any;
  groupMembers: any = [];
  displaySuccess: boolean = false;
  groupMemberList: any = [];
  displayError: boolean = false;
  noMember: boolean = false;
  @ViewChild('groupMember') checkboxlistcomp: CheckboxListComponent;
  /**Top Contacts variable definition ended */
  replyActionObj: any = {};
  @Input() editorData: any;
  tags = [];
  borderDragDropHighlight = false;
  @Output() newMessageData = new EventEmitter();
  isMobile: boolean = false;
  @Input() pushContact: any; // C153176-4229 : Push Contact as recipients
  currentField: string; //C153176-4229 for cuurent field to add member  
  selectedMember: any = []; //C153176-4229 to store selected member

  //below is added to show /hide contact info functionlity
  GDIR_DISPLAY_INFO: boolean;

  // recipientsMap = new Map<string, any[]>();
  extRecipients = [];

  displayCMCSearchPopup = false;

  //display suggestion popup
  displaySuggestion: boolean = false;
  suggestionRecords: Array<object> = [];
  suggestionRecordCount: number = 0;
  suggestionField: string = '';
  //assignedGroups: MyGroup[] = [];

  // C153176-5746 : Top contact save or error dialog flag
  contactSuccess = false;
  contactError = false;
  @Output() topContactAddEvent = new EventEmitter();
  @Output() recipientUpdateEvent = new EventEmitter(); // C153176-5896 | On Remove Recipient
  isRecUpdated: boolean = false;

  // C170665-10 | Request Type and Root Cause Linking
  // reqTypeRootCauseMap = new Map<string, any>(); 

  //spinner
  showSpinner: boolean = false;

  //TODO:Performance :: Calculate the time.
  startTime: any;
  @Output() updatePrevAttach = new EventEmitter();
  // Jira C170665-1265 revamp reply screen start
  isRootCauseMandatoryab = false;
  isRootCauseMandatoryWOReplyab = false;
  isProcessingRegionMandatoryab = false;
  isInqSourceMandatoryab = false;
  isGfidMandatoryab = false;
  isTagMandatoryab = false;
  isrevamped = false;
  grpName: any;
  gname: any;
  loginUserInfo;
  errMsgRestrcitedCharsInFilename = "";
  public qmaConstant = QmaConstant;
  ngOnInit() { }
  ngOnInitTrigeredByNewMsgComp() {
    let startTimengOnInitTrigeredByNewMsgComp = performance.now();
    //Check whether application is opened in mobile
    this.isMobile = AppUtils.isMobileView();
    // C153176-5194: set isChevronUp to true if it is mobile version
    if (this.isMobile) {
      this.isChevronUp = true;
    }
    let startTime = performance.now();
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.enableInquirySubStatusFlag = loginUserInfo.enableInquirySubStatusFlag;
      this.loginUserInfo = loginUserInfo;
      this.Qma_blocked_file_name_chars = loginUserInfo.blockedFileNameCharacters;
    });
    if (this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.taskizeConfig && this.userDataService.loggedInUserInfo.taskizeConfig.taskizeEnabled && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser && this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser?.toUpperCase() == 'Y') {
      this.taskizeEnabled = true
      this.checkForTaskize()
    } else {
      this.taskizeEnabled = false
    }
    this.getUserData();

    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message receipient getUserData processing took : ${Math.ceil(endTime - startTime)} miliseconds`);

    // this.getContactsService.getContact().subscribe((item) => {
    //   let pushContact = new AllToCcDBUser();
    //   if (item[0]) {
    //     pushContact.text = item[0].name;
    //     pushContact.value = item[0].email;
    //     pushContact.email = item[0].email;
    //     this.pushToTo(pushContact, item[1]);
    //   }
    // });

    // this.subscribingRecipientData();
    this.subscribingToCcBccFor();

    // C153176-4202 : Get the auto complete user preference
    this.isAutoCompleteDisabled = this.userDataService.loggedInUserInfo.disableAutoComplete;

    const globalDirectoryConfig = this.userDataService.loggedInUserInfo.globalDirectoryConfig;
    this.GDIR_DISPLAY_INFO = globalDirectoryConfig.contactInfoConfig.gdirDisplayInfo;

    // XXXXX - Why we need it here
    // this.newMessageService.getInquiryReplyData().subscribe(result => {
    //   if (result) {
    //     console.log('result :', result);
    //     this.replyActionObj = result;
    //   }
    // })

    // inquiry drag highlight handling
    this.inboxService.inquiryDragEvent.subscribe((evt :any) => {
      if (evt && evt.qma) {
        this.borderDragDropHighlight = evt.active;
      }
    });
    this.onValueChanges();
    let OnInitTrigeredByNewMsgCompEnd = performance.now();
    console.log(`QMA2.0 Performance :: New Message receipient OnInitTrigeredByNewMsgComp processing took : ${Math.ceil(OnInitTrigeredByNewMsgCompEnd - startTimengOnInitTrigeredByNewMsgComp)} miliseconds`);
  }

  clearToCcBcc(val) {
    switch (val) {
      case 'to':
        this.toUsersList = [];
        this.parentForm.get('toUsers').setValue([]);
        break;
      case 'cc':
        this.ccUsersList = [];
        this.parentForm.get('ccUsers').setValue([]);
        break;
      case 'bcc':
        this.bccUsersList = [];
        this.parentForm.get('bccUsers').setValue([]);
        break;
    }
    // C153176-5896 | let the other components knows that recipients are removed.
    this.isRecUpdated = !this.isRecUpdated;
    this.recipientUpdateEvent.emit(this.isRecUpdated);
  }
  subscribingRecipientData(data) {
    if (!data) {
      console.log('new-message-recipient: empty recipientData', data);
      return;
    }
    //TODO:Performance :: start time
    let strtTime = performance.now();

    let recdata = data.recipientData;
    this.fromwhere = recdata.fromWhere;
    if (!this.isNull(this.fromwhere)) {
      try {
        if (!this.isNull(recdata) && recdata.fromWhere === "messages") {
          // C170665-578 | Issue in sending mails; FROM field is not get populated
          console.log("Recipient Information while reply operation : ", JSON.stringify(recdata));
          console.log("Mail would be sent FROM: ", recdata.from);

          this.parentForm.get('groupName').setValue(recdata.from);
          // C153176-2907 Subscribing for groupName for top contact
          this.getContactsService.setGroupContact(recdata.from);
          this.parentForm.get('queryCount').setValue(recdata.queryCount);

          // C153176-5302 : Auto Population if single data is available
          this.parentForm.get('requestType').setValue(this.getRequestType(recdata));
          this.parentForm.get('inquirySource').setValue(this.getInquirySrc(recdata));
          if(this.enableInquirySubStatusFlag){
            this.parentForm.get('inquirySubStatus').setValue(this.getInquirySubStatus(recdata));
          }
         
          this.parentForm.get('processingRegion').setValue(this.getProcessingRegion(recdata));
          this.parentForm.get('rootCause').setValue(this.getRootCause(recdata));

          this.populateTags(recdata.from);
          this.parentForm.get('tags').setValue(recdata.tag);

          this.parentForm.get('gfcName').setValue(recdata.gfcName);
          this.parentForm.get('gfcId').setValue(recdata.gfcid);
          this.parentForm.get('gfpName').setValue(recdata.gfpName);
          this.parentForm.get('gfpId').setValue(recdata.gfpid);

          // C170665-5 | Add Account and Branch
          this.parentForm.get('skAccountNo').setValue(recdata.skAccountNo);
          this.parentForm.get('branch').setValue(recdata.branch);

          // C153176-6041 | Show GFPID or GFC ID as per QMA 1
          if (!this.isNull(recdata.gfpid) || !this.isNull(recdata.gfcid)) {
            let gfpId = recdata.gfpid || "";
            let gfcId = recdata.gfcid || "";
            let gfcDetails = gfpId + " / " + gfcId;

            if (recdata.skAccountNo) {
              gfcDetails = gfcDetails + " / " + recdata.skAccountNo + " / " + recdata.branch;
            }
            this.gfpidgfcid = gfcDetails;
          }
          this.postDataForAttachmentFile = data.attachments;
          this.parentForm.get("attachments").setValue(this.postDataForAttachmentFile);

          // C153176-5496 | Populate the recipients for the group, that will availbale as suggestion.
          this.populateRecipients(recdata.from);
          this.gname = recdata.from;
          if (!AppUtils.isUndefinedNullOrBlank(this.gname)) {
            this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
              loginUserInfo.myGroups.forEach(fromData => {
                if (!AppUtils.isUndefinedNullOrBlank(fromData) && !AppUtils.isUndefinedNullOrBlank(fromData.groupName) && fromData.groupName.toUpperCase() == this.gname.toUpperCase()) {
                  if (fromData.isRootCauseMandatory) {
                    this.isRootCauseMandatoryab = true;
                    if (fromData.isRootCauseMandatoryWOReply) {
                      this.isRootCauseMandatoryWOReplyab = true;
                    }
                  }
                  if (fromData.isProcessingRegionMandatory) {
                    this.isProcessingRegionMandatoryab = true;
                  }
                  if (fromData.isInquirySourceMandatory) {
                    this.isInqSourceMandatoryab = true;
                  }
                  if (fromData.isGfidMandatory) {
                    this.isGfidMandatoryab = true;
                  }
                  if (fromData.isTagMandatory) {
                    this.isTagMandatoryab = true;
                  }
                  if (this.enableInquirySubStatusFlag && (fromData.enableInquirySubStatus || loginUserInfo.isInquirySubStatusEnabledAtOrg)) {
                    this.enableInquirySubStatus = true;
                  }
                }
              });
            });
          }

          //TODO:Performance :: End Time. 
          let endTime = performance.now();
          console.log(`QMA2.0 Performance :: New Message Recipient - subscribingRecipientData : ${Math.ceil(endTime - strtTime)} miliseconds`);
        }
      } catch (e) {
        console.log('Error while populatting the new-message recipients in subscribingRecipientData()', e);
      }
    }
    //});

  }
  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  subscribingToCcBccFor() {
    this.newMessageService.getInquiryReplyData().subscribe(data => {
      let strtTime = performance.now();
      console.log("QMA2.0 Performance :: Calling subscribingToCcBccFor() subscription");
      if (Object.entries(data).length === 0 && data.constructor === Object) {
        return;
      }
      let inquiryId = data.inquiryId;
      let inquiryAction = data.inquiryAction; // Tab name
      let templateData = data.templateData[0];
       if (this.messageInstance.includes(inquiryId) &&
        (inquiryAction.startsWith(QmaConstant.REASSIGN) === this.messageInstance.startsWith(QmaConstant.REASSIGN))) {
             console.log("QMA2.0 Performance :: Inside QmaConstant.REASSIGN, InquiryAction :  " + inquiryAction + ", this.messageInstance : " + this.messageInstance);
        this.setRecipentFields(templateData);
        this.subscribingRecipientData(data);
        this.messageContent.emit(templateData);
        this.conversationObj.emit(data);
        // in case of select all attch
        const action = this.messageInstance && this.messageInstance.split(':') && this.messageInstance.split(':')[0] ?
          this.messageInstance.split(':')[0] : '';
        if (action && action.toLowerCase() === "forward") {
          this.selectAllAttach = true;
        }
        /*  if (action && action.toLowerCase() !== "forward") { */
        this.existingConvAttachements = data.existingConvAttachements;
        if (this.existingConvAttachements && this.existingConvAttachements.length && this.existingConvAttachements.length > 0) {
          this.existingConvAttachements.forEach((att: any) => {
            att.showTitle = true;
            att.showMenu = false;
            if (att.time) {
              att.time = this.dateFormatPipe.transform(att.time, this.guserPrefDateFormat);
            }
            // in case of forward existing attachement is selected by default so mark it selected
            if (data && data.attachments && Array.isArray(data.attachments) && data.attachments.length > 0) {
              const newAttachemnt = data.attachments.find(attc => attc.id === att.id);
              if (newAttachemnt) {
                att.isSselected = true;
              }
            }
          });
        }
        /* } */
        // make new attachement visible in all scenarios
        if (data && data.attachments && Array.isArray(data.attachments) && data.attachments.length > 0) {
          data.attachments.forEach((att: any) => {
            att.showTitleNew = true;
            att.showMenuNew = false;
            // att.isSselected = false;
          });
        }
        // C170665-1969 Issue- Maker checker not triggered when attachment from previous email attached during reply.
        // when user selects prev attachement notify new-messge-actions component so maker checker can be verified on attachement
        this.updatePrevAttachementStatus(data.existingConvAttachements);
        // adjust editor screen after adding attachement panel
        setTimeout(() => {
          this.resizeMailBody.emit({ event: 'attachementPanel' });
        }, 300);
      }
     
      //TODO:Performance :: End Time. 
      let endTime = performance.now();
      console.log(`QMA2.0 Performance :: New Message Recipient - subscribingToCcBccFor() subscription : ${Math.ceil(endTime - strtTime)} miliseconds`);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    let startTime = performance.now();
    // C153176-5766 | Clicking on Resolve duplicates the attachment.
    if (changes && changes.postDataForAttachment) {
      if (!this.postDataForAttachmentFile) {
        this.postDataForAttachmentFile = [];
      }
      if (!this.existingConvAttachements) {
        this.existingConvAttachements = [];
      }
      if (this.postDataForAttachment && this.postDataForAttachment.length > 0) {
        // C153176-4707: copy only new attachment(s) to postDataForAttachmentFile list
        this.postDataForAttachment.forEach(attch => {

          // C153176-5616 | Multiple Files not attaching with same names.
          //&& this.postDataForAttachmentFile.findIndex(elem => elem && elem.name === attch.name) === -1
          if (attch) {
            attch.showMenuNew = false;
            attch.showTitleNew = true;
            this.postDataForAttachmentFile.push(Object.assign({}, attch));
            let attachInfo: attachmentOption = {
              name: attch.name,
              id: attch.id,
              fileInfo: attch,
            };
            this.attachmentsOptions.push(attachInfo);
            // add new attachement to existing attachement list
            const newAttach = Object.assign({}, attch);
            newAttach.showMenu = false;
            newAttach.showTitle = true;
            newAttach.isSselected = true;
            this.existingConvAttachements.unshift(newAttach);
            // update attachement more link
            this.processAttachementMoreLink();
          }
        });
        this.getAttachmentData();
      }
    }
    this.getResolveEnquiry();

    //C153176-5002 - the deleted email is reappearing in to/cc/bcc
    try {
      if (changes && changes.template) {
        this.setTemplateData();
      }
    } catch (e) { }

    // C153176-4229 : Push Contact as recipients
    if (changes.pushContact && changes.pushContact.currentValue) {
      const singleContact = changes.pushContact.currentValue;
      this.pushToTo(singleContact.contact, singleContact.field);

      // C153176-5951 | Call Update Recipient when new contact is pushed.
      this.onUpdateRecipient(singleContact.contact);
    }

    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message receipients onchanges processing took : ${Math.ceil(endTime - startTime)} miliseconds`);
    // adjust editor screen after adding attachement panel
    setTimeout(() => {
      this.resizeMailBody.emit({ event: 'attachementPanel' });
    }, 300);
    // if all attachements are selected mark select all checked
    const selectedAttach = this.existingConvAttachements.filter(ea => ea.isSselected);
    this.selectAllAttach = selectedAttach && selectedAttach.length && selectedAttach.length === this.existingConvAttachements.length;
  }

  setTemplateData() {
    let template = this.template;
    try {
      if (!isNull(template)) {
        //template[0].templateContent;
        this.setRecipentFields(template);
        // t.unsubscribe();
      }
    } catch (e) {
      //console.log(e)
    }
  }
  getAttachmentData() {
    this.parentForm.get('attachments').setValue(this.postDataForAttachmentFile);
    let attachFields = {
      fileldFlag: this.postDataForAttachmentFile.length > 0,
      fieldName: "attachment"
    }
    this.resizeMailBody.emit(attachFields);
    // C170665-1969 Issue- Maker checker not triggered when attachment from previous email attached during reply.
    // when user selects prev attachement notify new-messge-actions component so maker checker can be verified on attachement
    if (this.postDataForAttachmentFile && Array.isArray(this.postDataForAttachmentFile) && this.postDataForAttachmentFile.length > 0) {
      this.updatePrevAttach.emit(true);
      this.parentForm.get("hasPrevAttach").setValue(true);
    } else {
      this.updatePrevAttach.emit(false);
      this.parentForm.get("hasPrevAttach").setValue(false);
    }
  }
  checkOverFlow(element) {
    if (element.clientWidth < element.scrollWidth) {
      this.attachmentLength = Math.floor(element.clientWidth / 127);
      return true;
    }
  }
  showRemainingAttchment(event, overlaypanel: OverlayPanel, attachmentList: any): void {
    this.attachmentsOptions = [];
    for (let i = this.attachmentLength; i < attachmentList.length; i++) {
      let attachInfo: attachmentOption = {
        name: attachmentList[i].name,
        id: attachmentList[i].id,
        fileInfo: attachmentList[i]
      };
      this.attachmentsOptions.push(attachInfo);
    }
    overlaypanel.toggle(event);
  }
  // delete attached file when click on delete icon ;
  deleteAttachedFile(id) {
    let elem = document.getElementById(id);
    elem.remove();
    // mark removed  from existing attachement list if exists
    if (this.existingConvAttachements && Array.isArray(this.existingConvAttachements)) {
      this.existingConvAttachements.forEach(atta => {
        if (atta.id === id) {
          atta.isSselected = false;
        }
      });
    }
    // adjust editor screen after adding attachement panel
    setTimeout(() => {
      this.resizeMailBody.emit({ event: 'attachementPanel' });
    }, 300);
    if (this.postDataForAttachmentFile.length === this.attachmentLength + 1) {
      this.isElipsisShow = true;
    }
    for (let i = 0; i < this.postDataForAttachmentFile.length; i++) {
      if (this.postDataForAttachmentFile[i].id === id) {
        this.postDataForAttachmentFile.splice(i, 1);
      }
    }
    for (let i = 0; i < this.postDataForAttachment.length; i++) {
      if (this.postDataForAttachment[i].id === id) {
        this.postDataForAttachment.splice(i, 1);
      }
    }

    this.parentForm.get('attachments').setValue(this.postDataForAttachmentFile);
    console.log('attached files :', this.postDataForAttachmentFile);
    let attachFields = {
      fileldFlag: this.postDataForAttachmentFile.length > 0,
      fieldName: "attachment"
    }
    this.resizeMailBody.emit(attachFields);



  }

  //delete attached file present inside overlay panel
  deleteAttachedFilePanel(id) {


    for (let i = 0; i < this.attachmentsOptions.length; i++) {
      if (this.attachmentsOptions[i].id === id) {
        this.attachmentsOptions.splice(i, 1);
      }
    }
    for (let i = 0; i < this.postDataForAttachmentFile.length; i++) {
      if (this.postDataForAttachmentFile[i].id === id) {
        this.postDataForAttachmentFile.splice(i, 1);
      }
    }



    this.parentForm.get('attachments').setValue(this.postDataForAttachmentFile);
    console.log('attached files :', this.postDataForAttachmentFile);
  }

  setRecipentFields(inquiryData: any) {

    //TODO:Performance :: start time
    let strtTime = performance.now();
    // C170665-2777 Template Issue : Recipient not merging on replying to an email by using Template.
    // in case of null values of receipients add empty array to avoid null value issues
    if(inquiryData && inquiryData.type && inquiryData.type === "addTemplate") { 
    if(!inquiryData.toList) {
      inquiryData['toList']=[];
      }
      if(!inquiryData.ccList) {
        inquiryData['ccList'] = [];
      }
    }

   // C170665-3201 On template switch - Subject has stale data of template 1.
   if(inquiryData.type !== "addTemplate") { // in case of reply/reply all forward dont replace orignal subject with tepmplate sub
    this.parentForm.patchValue({
      "subject": inquiryData.templateSubject
    });    
  } else if(inquiryData.type === "addTemplate") {
    this.applyTemplateSubject(inquiryData.templateSubject,this.parentForm);
  }

    if (this.check(inquiryData.toList)) {
       // C170665-3128 Template Issue
      // in case of add template update receipients
      if(inquiryData.type === "addTemplate") {
        this.toUsersList  = this.processTemplateReceipient(inquiryData.toList,this.parentForm.get('toUsers')?.value,inquiryData.mergeRecipients);
      }
      // old implimentation
      /* this.toUsersList = inquiryData.toList;*/
      // this.parentForm.get('toUsers').patchValue(this.toUsersList); 
    }
    else
      this.toUsersList = [];

    if (this.check(inquiryData.ccList)) {
       // C170665-3128 Template Issue
      // in case of add template update receipients
      if(inquiryData.type === "addTemplate") {
        this.ccUsersList = this.processTemplateReceipient(inquiryData.ccList,this.parentForm.get('ccUsers')?.value,inquiryData.mergeRecipients);
        }
        // old implimentation
      /* this.parentForm.controls["ccUsers"].markAsTouched(); // Performance change code refactor
      this.parentForm.controls["ccUsers"].markAsDirty(); // Performance change code refactor
      this.parentForm.controls["ccUsers"].updateValueAndValidity(); */ // Performance change code refactor
      // this.parentForm.get('ccUsers').patchValue(this.ccUsersList);
    }
    else
      this.ccUsersList = [];

    if (this.check(inquiryData.bccList)) {
       // C170665-3128 Template Issue
    // BCC was getting wiped off dont have bcc in template
      // this.bccUsersList = inquiryData.bccList;
      /* this.parentForm.controls["bccUsers"].markAsTouched(); // Performance change code refactor
      this.parentForm.controls["bccUsers"].markAsDirty(); // Performance change code refactor
      this.parentForm.controls["bccUsers"].updateValueAndValidity(); */ // Performance change code refactor
     // this.parentForm.get('bccUsers').patchValue(this.bccUsersList);
    }
 /*    else
      this.bccUsersList = []; */

    // C153176-5950 | When external recipient added from template.
    // let toExtRecipients = this.getExternalRecipients(this.toUsersList);
    // let ccExtRecipients = this.getExternalRecipients(this.ccUsersList);
    // let bccExtRecipients = this.getExternalRecipients(this.bccUsersList);

    let externalRecipient = this.getExternalRecipients([...this.toUsersList, ...this.ccUsersList, ...this.bccUsersList]);
    let endTimeexternalRecipient = performance.now();
    console.log(`QMA2.0 Performance :: New Message Recipient - getExternalRecipients(): ${Math.ceil(endTimeexternalRecipient - strtTime)} miliseconds`);

    // if there is any external recipient.
    // if (toExtRecipients.length > 0 || ccExtRecipients.length > 0 || bccExtRecipients.length > 0) {
    //   this.isRecUpdated = !this.isRecUpdated;
    //   this.recipientUpdateEvent.emit(this.isRecUpdated);
    // }

    if (externalRecipient.length > 0) {
      this.isRecUpdated = !this.isRecUpdated;
      this.recipientUpdateEvent.emit(this.isRecUpdated);
    }

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message Recipient - setRecipentFields(): ${Math.ceil(endTime - strtTime)} miliseconds`);
  }

  // C153176-5950 | When external recipient added from template.
  // Get the list of recipients if there is any external recipient.
  getExternalRecipients(recipients: any[]): any[] {

    //TODO:Performance :: start time
    let strtTime = performance.now();

    let extRecipient = [];
    if (recipients.length > 0) {
      extRecipient = recipients.filter(recipient => this.isExternalEmailInToCc(recipient.value) == true);
    }
    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message Recipient - getExternalRecipients(): ${Math.ceil(endTime - strtTime)} miliseconds`);
    return extRecipient;
  }

  check(x) {
    let startTime = performance.now();
    if (x == null) {
      return false;
    }

    if (x === null) {
      return false;
    }

    if (typeof x === 'undefined') {
      return false;
    }
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message receipients check() processing took : ${Math.ceil(endTime - startTime)} miliseconds`);

    return true;
  }

  splitMultipleEmailIds(item, field) {

    let emailPattern = /\<([^\>]+)\>/g;
    let match = "";
    let newItem = new AllToCcDBUser();
    let result = item.value.split(';');
    for (let str of result) {
      str = str.trim();

      //check for patter => '"Name one" <foo@domain.com>
      //let temp = emailPattern.exec(str);
      //str = temp[1];


      newItem = {
        text: str,
        value: str,
        email: str,
        id: '',
        active: false
      };
      if (field === 'to' && !this.isDuplicate(newItem, 'to'))
        this.toUsersList.push(newItem);
      else if (field === 'cc' && !this.isDuplicate(newItem, 'cc'))
        this.ccUsersList.push(newItem);
      else if (field === 'bcc' && !this.isDuplicate(newItem, 'bcc'))
        this.bccUsersList.push(newItem);
    }
  }

  pushToTo(item: AllToCcDBUser, field: string) {

    // C153176-5262: validate email id
    if (item && item.value && item.value.trim()) { // C153176-5288: check whether value is empty
      let emails = item.value.split(';');
      for (let email of emails) {
        email = email.trim();

        // C153176-5719 | Drag Drop of Group
        // Consider group emails - for group value have id i.e. number
        if (NaN != Number(email) && Number(email) > 0) {
          let grpdIdx = this.userDataService.getAssignedGroups().findIndex(grp => grp.id == Number(email));

          // Check if the group tried to push from contact list but not in assigned group
          if (grpdIdx > -1) {
            email = this.userDataService.getAssignedGroups()[grpdIdx].groupEmail;
          } else {
            email = item.email;
          }
        }

        if (email && !this.newMessageService.email_regex.test(email)) {
          this.closeFlagInvalid = true;
          setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
          return;
        }
      }
    } else {
      // C153176-5288: prevent empty email id from breaking into new-message address fields
      this.closeFlagInvalid = true;
      setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
      return;
    }

    switch (field) {
      case 'to':
        if (!this.isDuplicate(item, 'to')) {
          if (item.value.indexOf(';') > -1) {
            this.splitMultipleEmailIds(item, 'to');
          }
          else {
            this.toUsersList.push(item);
            setTimeout(() => { this.toUsersData.focusInput(); }, 100);
          }
          this.parentForm.controls["toUsers"].markAsTouched();
          this.parentForm.controls["toUsers"].markAsDirty();
          this.parentForm.controls["toUsers"].updateValueAndValidity();
        }
        break;
      case 'cc':
        if (!this.isDuplicate(item, 'cc')) {
          if (item.value.indexOf(';') > -1) {
            this.splitMultipleEmailIds(item, 'cc');
          }
          else {
            this.ccUsersList.push(item);
            setTimeout(() => { this.ccUsersData.focusInput(); }, 100);
          }
          this.parentForm.controls["ccUsers"].markAsTouched();
          this.parentForm.controls["ccUsers"].markAsDirty();
          this.parentForm.controls["ccUsers"].updateValueAndValidity();
        }
        break;
      case 'bcc':
        if (!this.isDuplicate(item, 'bcc')) {
          if (item.value.indexOf(';') > -1) {
            this.splitMultipleEmailIds(item, 'bcc');
          }
          else {
            this.bccUsersList.push(item);
            setTimeout(() => { this.bccUsersData.focusInput(); }, 100);
          }
          this.parentForm.controls["bccUsers"].markAsTouched();
          this.parentForm.controls["bccUsers"].markAsDirty();
          this.parentForm.controls["bccUsers"].updateValueAndValidity();
        }
        break;
    }
  }

  isDuplicate(item, toCcBcc) {
    let found = false;
    switch (toCcBcc) {
      case 'to':
        this.toUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
      case 'cc':
        this.ccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
      case 'bcc':
        this.bccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
    }
    return found;
  }

  onGroupNameChange(groupName) {
   
    console.log('QMA2.0 Performance :: Calling onGroupNameChange()');
    this.populateMandatoryFields(groupName.value)
    this.getContactsService.setGroupContact(groupName.value);
    this.populateRequestType(groupName.value);
    this.populateProcessingRegion(groupName.value);
    this.populateRootCause(groupName.value);
    this.populateTags(groupName.value);

    // C153176-5496 | Populate the recipients for the group, that will availbale as suggestion.
    this.populateRecipients(groupName.value);

    // Update disclaimer on change of group name
    const disclaimer = this.getDisclaimerByGroup(groupName.value);
    this.disclaimerEvent.emit(disclaimer);
    this.group.emit(groupName.value);
  }

  getValidatedRequestType(requestType) {
    const reqTypes = this.requestTypes.filter(e => e && e.value === requestType);
    return reqTypes && reqTypes.length ? requestType : '';
  }

  populateRequestType(groupName) {
    this.requestTypes = [];
    this.requestTypes.push({ label: 'Select', value: '' });

    // this.requestTypeMap.forEach((value: string[], key: string) => {
    //   if (!isNull(value)) {
    //     if (key === groupName) {
    //       for (let i = 0; i < value.length; i++) {
    //         this.requestTypes.push({ label: value[i], value: value[i] });
    //       }
    //     }
    //   }
    // });

    let groupRequestTypes: any[] = this.userDataService.getRequestType(groupName).map(reqType => ({ label: reqType, value: reqType }));
    if (groupRequestTypes && groupRequestTypes.length > 0) {
      this.requestTypes = [...this.requestTypes, ...groupRequestTypes];
    }


    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.requestTypes.length === this.countCheck) {
      this.parentForm.get('requestType').setValue(this.requestTypes[1].value);
    } else if (this.showFieldOnlyForTaskize) {
        this.parentForm.get("requestType").clearValidators();
        this.parentForm.get("requestType").setErrors(null);
        this.parentForm.get("requestType").updateValueAndValidity();
        this.parentForm.get('requestType').setValue('');
    } else {
      this.parentForm.get('requestType').setValue(''); // C153176-5737 | If no record is available.
    }

  }

  populateProcessingRegion(groupName) {
    this.processingRegions = [];
    this.processingRegions.push({ label: 'Select', value: '' });

    // this.processingRegionMap.forEach((value: string[], key: string) => {
    //   if (!isNull(value)) {
    //     if (key === groupName) {
    //       for (let i = 0; i < value.length; i++) {
    //         this.processingRegions.push({ label: value[i], value: value[i] });
    //       }
    //     }
    //   }
    // });

    let groupProcesingRegions: any[] = this.userDataService.getProcessingRegion(groupName).map(pr => ({ label: pr, value: pr }));
    if (groupProcesingRegions && groupProcesingRegions.length > 0) {
      this.processingRegions = [...this.processingRegions, ...groupProcesingRegions];
    }

    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.processingRegions.length === this.countCheck) {
      this.parentForm.get('processingRegion').setValue(this.processingRegions[1].value);
    } else {
      this.parentForm.get('processingRegion').setValue(''); // C153176-5737 | If no record is available.
    }
  }

  populateRootCause(groupName: string, requestType?: string) {
    this.rootCauses = [];
    this.rootCauses.push({ label: 'Select', value: '' });

    // C170665-10 | Check whether requestTypeRootCauseFilter is enabled and there is request type - root cause mapping exist,
    // then populate the root cause with selected request type.
    let requestTypeRootCauseFilter = this.userDataService.reqTypeRootCauseMap.has(groupName);
    if (requestTypeRootCauseFilter && null != this.userDataService.reqTypeRootCauseMap.get(groupName)) {
      let selRequestType = this.parentForm.get('requestType').value;
      if (!this.isNull(selRequestType) && this.isNull(requestType)) {
        requestType = selRequestType;
      }
      let rootcauses = this.getRootCauseFromMapping(groupName, requestType);
      // C170665-10 | If there is no request type - root cause mapping then load default.
      if (rootcauses.length === 0 && !this.isNull(requestType)) {
        rootcauses = this.getDefaultRootCause(groupName);
      }
      for (let i = 0; i < rootcauses.length; i++) {
        this.rootCauses.push({ label: rootcauses[i], value: rootcauses[i] });
      }
    }
    else {
      let rootcauses = this.getDefaultRootCause(groupName);
      for (let i = 0; i < rootcauses.length; i++) {
        this.rootCauses.push({ label: rootcauses[i], value: rootcauses[i] });
      }
    }
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.rootCauses.length === this.countCheck) {
      	setTimeout(()=>{
		this.parentForm.get('rootCause').setValue(this.rootCauses[1].value);
	},0)
    } else {
      this.parentForm.get('rootCause').setValue(''); // C153176-5737 | If no record is available.
    }
  }

  populateInquirySource() {
    this.inquirySourceList = [];
    this.inquirySourceList.push({ label: 'Select', value: '' });
    // this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
    //   //Get Inquiry Source
    //   loginUserInfo.inquirySourceList.forEach(inquirySource => {
    //     this.inquirySourceList.push({ label: inquirySource, value: inquirySource });
    //   });
    // });

    let inquirySources: any[] = this.userDataService.loggedInUserInfo.inquirySourceList.map(inqSrc => ({ label: inqSrc, value: inqSrc }));
    if (inquirySources && inquirySources.length > 0) {
      this.inquirySourceList = [...this.inquirySourceList, ...inquirySources];
    }

    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.inquirySourceList.length === this.countCheck) {
      this.parentForm.get('inquirySource').setValue(this.inquirySourceList[1].value);
    } else if (this.showFieldOnlyForTaskize) {
      this.parentForm.get('inquirySource').setValue('Taskize');
    } else {
      this.parentForm.get('inquirySource').setValue(''); // C153176-5737 | If no record is available.
    }
  }

  populateTags(groupName) {
    this.tags = [];
    this.tags.push({ label: 'Select', value: '' });

    // this.tagsMap.forEach((value: string[], key: string) => {
    //   if (!this.isNull(value)) {
    //     if (key === groupName) {
    //       for (let i = 0; i < value.length; i++) {
    //         this.tags.push({ label: value[i], value: value[i] });
    //       }
    //     }
    //   }
    // });

    let groupTags: any[] = this.userDataService.getTags(groupName).map(tag => ({ label: tag, value: tag }));
    if (groupTags && groupTags.length > 0) {
      this.tags = [...this.tags, ...groupTags];
    }

    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.tags.length === this.countCheck) {
      this.parentForm.get('tags').setValue(this.tags[1].value);
    } else {
      this.parentForm.get('tags').setValue(''); // C153176-5737 | If no record is available.
    }
  }

  // C153176-5496 : Populate the extRecipients for the selected group.
  populateRecipients(groupName): void {
    this.extRecipients = [];
    // this.extRecipients = this.userDataService.getGroupRecipients(groupName) || []; // Performance refactor change
  }

  getUserData() {
    let strtTime = performance.now();
    let groupNameTmp = [];
    groupNameTmp.push({ label: 'Select', value: '' });
    //this.inquirySourceList.push({ label: 'Select', value: '' });

    //TODO:Performance :: This called every time new message is opened from left nevigation bar.
    // Population of below details woould be in shared service.
    // this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
    //   //this.citiDomains = loginUserInfo.citiDomainList;
    //   //this.loggedInUser = loginUserInfo.userId;
    //   //this.userName = loginUserInfo.userName;
    //   //this.assignedGroups =  loginUserInfo.myGroups;

    //   // loginUserInfo.myGroups.forEach(myGroup => {
    //   //   groupNameTmp.push({ label: myGroup.groupName, value: myGroup.groupName });
    //   //   this.requestTypeMap.set(myGroup.groupName, myGroup.requestTypes);
    //   //   this.processingRegionMap.set(myGroup.groupName, myGroup.processingRegionList);
    //   //   this.rootCauseMap.set(myGroup.groupName, myGroup.rootCauseList);
    //   //   this.tagsMap.set(myGroup.groupName, myGroup.tags);

    //   //   // C170665-10 | Populate the map of group with request type and root cause mapping.
    //   //   if (myGroup.requestTypeRootCauseFilter) {
    //   //     this.reqTypeRootCauseMap.set(myGroup.groupName, myGroup.requestTypeRootCauseMapping);
    //   //   }

    //   //   // C153176-5496 | Get the recipients of the group.
    //   //   let recipents = null;
    //   //   // Populate the recipients only when predictiveRecipients flag is true for the group.
    //   //   if (myGroup.predictiveRecipients && myGroup.recipients) {
    //   //     recipents = myGroup.recipients.map(rec => ({ text: rec, value: rec, email: rec }));
    //   //     this.recipientsMap.set(myGroup.groupName, recipents);
    //   //   }
    //   // });

    //   // //Get To,CC,BCC users list
    //   // loginUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
    //   //   let individualRecipent: AllToCcDBUser = { ...toCcBccUsers };
    //   //   individualRecipent.email = individualRecipent.value;
    //   //   this.allToCcDBUser.push(individualRecipent);
    //   // });

    //   // //Get All groups
    //   // loginUserInfo.allActiveGroups.forEach(activeGroup => {
    //   //   this.allActiveGroups.push(activeGroup);
    //   // });

    //   // this.populateInquirySource();

    //   // // Once all the drop down is loaded successfully. Populate the dropdown as per default group
    //   // let groupName = this.parentForm.get('groupName').value;
    //   // if (groupName) {
    //   //   this.populateRequestType(groupName);
    //   //   this.populateProcessingRegion(groupName);
    //   //   this.populateRootCause(groupName);
    //   //   this.populateTags(groupName);

    //   //   // C153176-5496 | Populate the recipients for the group, that will availbale as suggestion.
    //   //   this.populateRecipients(groupName);
    //   //   this.group.emit(groupName);
    //   // }
    // });


    let userAssignedGroups = this.userDataService.getUserAssignedGroup().map(grp => ({ label: grp, value: grp }));
    let taskizeAssignedGroups = this.userDataService.getTaskizeAssignedGroup().map(grp => ({ label: grp, value: grp }));    
    if (this.showFieldOnlyForTaskize) {
      if (taskizeAssignedGroups && taskizeAssignedGroups.length > 0) {
        groupNameTmp = [...groupNameTmp, ...taskizeAssignedGroups];
        this.groupNames = groupNameTmp;
      }
    } else {
      if (userAssignedGroups && userAssignedGroups.length > 0) {
        groupNameTmp = [...groupNameTmp, ...userAssignedGroups];
      }
      this.groupNames = groupNameTmp;
    }

    // this.allToCcDBUser = this.userDataService.getAllToCcDBUsers(); // Performance refactor change
    // this.allActiveGroups = this.userDataService.getAllActiveGroups(); // Performance refactor change
    this.populateInquirySource();
    this.populateInquirySubStatusList();
    // // C153176-5197: auto select first group if there is one
    // if (!this.selectedGroupName && (this.groupNames.length > 1)) {
    //   // C153176-5197: select the first group
    //   let defaultGroup: any = this.groupNames[1];
    //   this.selectedGroupName = defaultGroup.value;
    //   this.onGroupNameChange(this.groupNames[1]);
    // }

    let groupName = this.parentForm.get('groupName').value;
    if (groupName) {
      if (this.messageInstance.startsWith('NEW')) {
        this.populateMandatoryFields(groupName);
      }
      this.populateRequestType(groupName);
      this.populateProcessingRegion(groupName);
      this.populateRootCause(groupName);
      this.populateTags(groupName);

      // C153176-5496 | Populate the recipients for the group, that will availbale as suggestion.
      this.populateRecipients(groupName);
      this.group.emit(groupName);
    }

    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message Recipient - Get User Data : ${Math.ceil(endTime - strtTime)} miliseconds`);
  }

  // C153176-4930: refactored method, used by multiple callers
  //TODO:Performance :: Filter should be done after few characters and some few milliseconds delay.
  getFilteredToCCUsers(query) {
    let filtered = [];
    // performance refactor
    if (this.allToCcDBUser.length === 0) {
      this.allToCcDBUser = this.userDataService.getAllToCcDBUsers();
    }
    if (this.allActiveGroups.length === 0) {
      this.allActiveGroups = this.userDataService.getAllActiveGroups();
    }
    if (this.extRecipients.length === 0) {
      let groupName = this.parentForm.get('groupName').value;
      this.extRecipients = this.userDataService.getGroupRecipients(groupName) || [];
    }

    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    this.allActiveGroups.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    //C153176-5496 | External Recipents for suggestion.
    if (this.extRecipients) {
      this.extRecipients.forEach(record => {
        if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
          filtered.push(record);
        }
      });
    }
    return filtered;
  }

  filterUsers(event) {
    let query = event.query;
    // C153176-4930: refactored method
    this.filteredToUsersMultiple = this.getFilteredToCCUsers(query);
  }

  popup = "";

  showPopup(event, field, overlaypanel) {
    this.popup = field;
    if (!this.showFieldOnlyForTaskize) {
      overlaypanel.toggle(event);
    }
    this.showContact = true;
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "New Message - Contact Search", "Click", "New Message - Contact Search", 0);
  }

  onBlur(event: any, field) {
    // C153176-2906, C153176-4930: upon leaving the field, handle case of external email without breaking suggesstion handling
    let tokenInput = event.srcElement as any;
    let email = tokenInput.value.trim();
    // first check whether the current input match any suggestions
    let suggestions = this.getFilteredToCCUsers(email);
    if (!suggestions || !suggestions.length) {
      // C153176-4930: does NOT have any suggestions, check whether it's a valid external email. If yes, take it.
      // C153176-4930: check / accept internal email too
      this.closeFlagInvalid = false;
      let extEmail = false;
      if (email) {
        extEmail = this.newMessageService.handleEmail(tokenInput, email, field, this);
        if (!this.closeFlagInvalid) {
          // C153176-4930: if the closeFlagInvalid is false, the input is valid email id(s)
          if (extEmail) {
            this.secure.emit(true);
          } else {
            this.secure.emit(false);
          }
          // !important to return now so as to keep the input field
          return;
        }
      }
      // C153176-4930: in other cases
      event.srcElement.value = "";
    } else {
      // C153176-4930: has suggestions, clear the field
      event.srcElement.value = "";
      this.resizeMailBody.emit(true);
    }
  }

  onKeyUp(event: KeyboardEvent, field) {
    let extEmail: boolean = false;
    this.closeFlagInvalid = false;
    if (event.key === "Enter") {
      let tokenInput = event.srcElement as any;
      let email = tokenInput.value.trim();
      // C153176-4930: refactoring the processing of email input to separate method 'handleEmail'
      //extEmail = AppUtils.isExternalEmailInToCc(email, this.userDataService.loggedInUserInfo.citiDomainList);
      //extEmail = this.handleEmail(tokenInput, email, field);
      extEmail = this.newMessageService.handleEmail(tokenInput, email, field, this);
    } else if (event && event.ctrlKey && (event.key === "k" || event.key == "K")) {
      let tokenInput = event.srcElement as any;
      let email = tokenInput.value.trim();
      extEmail = AppUtils.isExternalEmailInToCc(email, this.userDataService.loggedInUserInfo.citiDomainList);
      this.newMessageService.resolveFullDisplayName(field, this);
    }
    if (extEmail) {
      this.secure.emit(true);
    } else {
      this.secure.emit(false);
    }
  }


  onHideContactPopUp(e: any) {
    this.query = '';
    this.isClosed = true;
  }
  toggleAdd: boolean = false;
  toggleMoreFields: boolean = false;
  showHideAddNote() {
    this.toggleAdd = !this.toggleAdd;
    let addFields = {
      fileldFlag: this.toggleAdd,
      fieldName: "addNote"
    }
    this.resizeMailBody.emit(addFields);
  }
  showHideMoreFields() {
    this.toggleMoreFields = !this.toggleMoreFields;
    let moreFields = {
      fileldFlag: this.toggleMoreFields,
      fieldName: "moreField"
    }
    this.resizeMailBody.emit(moreFields);
  }
  // Jira : C153176-5194 Mobile -To/CC/ BCC changes
  showccbcc() {
    this.toggleccbcc = !this.toggleccbcc;
  }

  cancelAddNote() {
    this.toggleAdd = false;
    this.note = '';
    this.parentForm.get('note').setValue('');
    let addFields = {
      fileldFlag: this.toggleAdd,
      fieldName: "addNote"
    }
    this.resizeMailBody.emit(addFields);
  }

  callGFIDWindow() {
    let gfcId = this.parentForm.get('gfcId').value;
    let skAccountNo = this.parentForm.get('skAccountNo').value;
    let branch = this.parentForm.get('branch').value;
 
    let defaultVal = {
      'gfcId': gfcId,
      'skAccountNo': skAccountNo,
      'branch': branch
    };
    this.GfidSearchComponent.defaultVal = defaultVal;
    this.GfidSearchComponent.groupName = this.parentForm.get('groupName').value;
    this.GfidSearchComponent.triggerFalseClick();
  }

  getGFID(val: any) {
    let gfcDetails = val.gfpId + " / " + val.gfcId;

    // Show Account and Branch details if preset to UI.
    if (val.skAccountNo && val.branch) {
      let accountDetails = val.skAccountNo + " / " + val.branch;
      gfcDetails = gfcDetails + " / " + accountDetails;
    }
    this.gfpidgfcid = gfcDetails;

    this.parentForm.get('gfcId').setValue(val.gfcId);
    this.parentForm.get('gfcName').setValue(val.gfcName);
    this.parentForm.get('gfpId').setValue(val.gfpId);
    this.parentForm.get('gfpName').setValue(val.gfpName);

    // Add Account Number and Branch
    this.parentForm.get('skAccountNo').setValue(val.skAccountNo);
    this.parentForm.get('branch').setValue(val.branch);

    this.messageService.add({ key: 'custom', summary: 'Success Confirmation', detail: 'Record added successfully !' });
  }

  onReject() {
    this.messageService.clear('c');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getQueryCountValidation();
      this.processAttachementMoreLink();
    }, 200);
    //this.getAttachmentData();
    setTimeout(() => {
      this.processAttachementMoreLink();
    }, 500);
    //TODO:Performance :: End Time. 
    let endTime = performance.now();
    console.log(`QMA2.0 Performance :: New Message Recipient ngAfterViewInit loading : ${Math.ceil(endTime - this.startTime)} miliseconds`);


  }

  /**
   * Method to add note in new message.
   */
  addNote(): void {
    this.toggleAdd = false;
    const addedNote = this.parentForm.get('note').value;
    console.log("Entered note : ", addedNote);

    let currentDate = new Date();
    // C153176-6039 | Add note model change for date while WS re-design
    let addNote = {
      commentDate: { $date: currentDate.getTime() },
      userName: this.userDataService.getLoggedInUserName(),
      comments: addedNote,
      origin: 'messages'
    };

    this.parentForm.get('note').setValue('');
    if (addedNote) // Jira : 4634 - blank note will not be added.
      this.notesList.push(addNote);
    this.notesEvent.emit(this.notesList);
    let addFields = {
      fileldFlag: this.toggleAdd,
      fieldName: "addNote"
    }
    this.resizeMailBody.emit(addFields);
    this.userDataService.userUsageStats("New Message", "Add note").subscribe(result => console.log("StartTime:" + new Date()));
    PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Menu", "New message - Add Note", "Click", "New message - Add note", 0);
  }

  downloadAttachment(docId: string, docName: string, convId: number, groupId: number, hasNominatedOwnership: boolean): void {
    this.inboxService.downloadAttachment(docId, docName, convId, groupId, hasNominatedOwnership);
  }

  isExternalEmailInToCc(email): boolean {
    let isExternal: boolean = false;
    if (!AppUtils.isUndefinedOrNull(email)) {

      // C153176-5006 : If User enter soeid who has access to QMA application
      let emailList = email.split(';');
      emailList.forEach(emailValue => {
        emailValue = emailValue.trim();
        const appUser = this.allToCcDBUser.find(user => user.id === emailValue);
        let isNonQmaCitiUser = AppUtils.validateNonQmaCitiUserSoeId(emailValue);
        if (emailValue && !AppUtils.isCitiDomainEmail(emailValue, this.userDataService.loggedInUserInfo.citiDomainList) && !appUser && !isNonQmaCitiUser) {
          isExternal = true;
        }
      });
    }
    return isExternal;
  }

  getContactStatus(value) {
    //TODO it is getting fired infinite times to check if input in TO/CC/BCC field has any external email. please check and improve
    if (!isNaN(value)) {
      return "bold";
    } else if (this.isExternalEmailInToCc(value)) {
      return "external";
    } else {
      return "";
    }
  }

  onShowContactPopUp(e: any) {
    this.query = '';
    this.isClosed = false;
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  allowDrop(event) {
    event.preventDefault();
  }

  drag(event, emailField, data) {
    let jsonObject = JSON.stringify(data);
    event.dataTransfer.setData("data", jsonObject);
    event.dataTransfer.setData("emailField", emailField);
  }

  drop(event, emailField) {
    event.preventDefault();
    if (this.isInquiryDrop(event, emailField)) {
      return this.handleInquiryDrop(event);
    }
    if (emailField !== "parent") {
      // C153176-5343 - null check on data from drop dataTransfer object
      let data = event.dataTransfer.getData("data");
      let obj = data ? JSON.parse(data) : undefined;
      if (obj) {
        let setEmailField = event.dataTransfer.getData("emailField");
        this.popEmailAddress(obj, setEmailField);
        this.pushToTo(obj, emailField);
      } else {
        let setEmailField = event.dataTransfer.getData("emailField");
        obj = event.dataTransfer.items;
        // C153176-5343: support multiple items in dataTransfer obj
        for (let i = 0; i < obj.length; i++) {
          let item = obj[i].getAsString((s) => {
            if (s) {
              this.popEmailAddress(s, setEmailField);
              this.pushToTo(s, emailField);
            }
          });
        }
      }
    }
  }

  popEmailAddress(emailObject, dragEmail) {

    let emailIndex = -1;
    switch (dragEmail) {
      case 'to':
        emailIndex = this.toUsersList.findIndex(x => x.value === emailObject.value);
        if (emailIndex > -1) {
          this.toUsersList.splice(emailIndex, 1);
        }
      case 'cc':
        emailIndex = this.ccUsersList.findIndex(x => x.value === emailObject.value);
        if (emailIndex > -1) {
          this.ccUsersList.splice(emailIndex, 1);
        }
      case 'bcc':
        emailIndex = this.bccUsersList.findIndex(x => x.value === emailObject.value);
        if (emailIndex > -1) {
          this.bccUsersList.splice(emailIndex, 1);
        }
    }

  }

  mandatoryForResolveParametersForGroupName(grpName) {
    this.mandatoryForResolveFlags = [];
    let isRootCauseMandatory = false;
    let isRootCauseMandatoryWOReply = false;
    let isProcessingRegionMandatory = false;
    let isInqSourceMandatory = false;
    let isGfidMandatory = false;
    var isTagMandatory = false;
    let enableInquirySubStatus = false;
    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        loginUserInfo.myGroups.forEach(fromData => {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) && !AppUtils.isUndefinedNullOrBlank(fromData.groupName) && fromData.groupName.toUpperCase() == grpName.toUpperCase()) {
            if (fromData.isRootCauseMandatory) {
              isRootCauseMandatory = true;
              if (fromData.isRootCauseMandatoryWOReply) {
                isRootCauseMandatoryWOReply = true;
              }
            }
            if (fromData.isProcessingRegionMandatory) {
              isProcessingRegionMandatory = true;
            }
            if (fromData.isInquirySourceMandatory) {
              isInqSourceMandatory = true;
            }
            if (fromData.isGfidMandatory) {
              isGfidMandatory = true;
            }
            if (fromData.isTagMandatory) {
              isTagMandatory = true;
            }
            if (fromData.enableInquirySubStatus || loginUserInfo.isInquirySubStatusEnabledAtOrg) {
              enableInquirySubStatus = true;
            }
          }
        });
      });
    }
    this.mandatoryForResolveFlags.push({
      isRootCauseMandatory: isRootCauseMandatory,
      isProcessingRegionMandatory: isProcessingRegionMandatory,
      isInqSourceMandatory: isInqSourceMandatory,
      isRootCauseMandatoryWOReply: isRootCauseMandatoryWOReply,
      isGfidMandatory: isGfidMandatory,
      isTagMandatory: isTagMandatory,
      enableInquirySubStatus: enableInquirySubStatus
    });
  }

  validateFormFields(resolveFlag): boolean { 
    this.mandatoryForResolveParametersForGroupName(this.parentForm.value.groupName);
    let valid = false;

    if (this.mandatoryForResolveFlags[0].enableInquirySubStatus && (this.parentForm.controls["inquirySubStatus"].untouched || this.parentForm.controls["inquirySubStatus"].pristine
    || this.parentForm.controls["inquirySubStatus"].value === 'Select' )) {
      this.parentForm.controls["inquirySubStatus"].setValidators(Validators.required);
      this.parentForm.controls["inquirySubStatus"].setErrors({ notUnique: true });
      this.parentForm.controls["inquirySubStatus"].updateValueAndValidity();
      console.log('Inquiry Sub-Status should be mandatory');
      valid = true;
    }
    else {
      this.parentForm.get("inquirySubStatus").clearValidators();
      this.parentForm.get("inquirySubStatus").setErrors(null);
      this.parentForm.get("inquirySubStatus").updateValueAndValidity();
    }

    if (resolveFlag === "Y") {
      if (this.mandatoryForResolveFlags[0].isProcessingRegionMandatory &&
        (this.parentForm.controls["processingRegion"].untouched || this.parentForm.controls["processingRegion"].pristine) &&
        (this.isNull(this.parentForm.controls["processingRegion"].value))) {
        this.parentForm.controls["processingRegion"].setValidators(Validators.required);
        this.parentForm.controls["processingRegion"].setErrors({ notUnique: true });
        this.parentForm.controls["processingRegion"].updateValueAndValidity();
        console.log('Processing region should be mandatory');
        valid = true;
      }
      else {
        this.parentForm.get("processingRegion").clearValidators();
        this.parentForm.get("processingRegion").setErrors(null);
        this.parentForm.get("processingRegion").updateValueAndValidity();
      }

      if ((this.mandatoryForResolveFlags[0].isRootCauseMandatory || this.mandatoryForResolveFlags[0].isRootCauseMandatoryWOReply) &&
        (this.parentForm.controls["rootCause"].untouched || this.parentForm.controls["rootCause"].pristine) &&
        (this.isNull(this.parentForm.controls["rootCause"].value))) {
        this.parentForm.controls["rootCause"].setValidators(Validators.required);
        this.parentForm.controls["rootCause"].setErrors({ notUnique: true });
        this.parentForm.controls["rootCause"].updateValueAndValidity();
        console.log('Root Cause should be mandatory');
        valid = true;
      }
      else {
        this.parentForm.get("rootCause").clearValidators();
        this.parentForm.get("rootCause").setErrors(null);
        this.parentForm.get("rootCause").updateValueAndValidity();
      }

      if (this.mandatoryForResolveFlags[0].isInqSourceMandatory && (this.parentForm.controls["inquirySource"].untouched || this.parentForm.controls["inquirySource"].pristine)) {
        this.parentForm.controls["inquirySource"].setValidators(Validators.required);
        this.parentForm.controls["inquirySource"].setErrors({ notUnique: true });
        this.parentForm.controls["inquirySource"].updateValueAndValidity();
        console.log('Inquiry Source should be mandatory');
        valid = true;
      }
      else {
        this.parentForm.get("inquirySource").clearValidators();
        this.parentForm.get("inquirySource").setErrors(null);
        this.parentForm.get("inquirySource").updateValueAndValidity();
      }

      // C153176-6041 | Issue2- Tag is mandatory
      if (this.mandatoryForResolveFlags[0].isTagMandatory &&
        (this.parentForm.controls["tags"].untouched || this.parentForm.controls["tags"].pristine)
        && (this.isNull(this.parentForm.controls["tags"].value))) {
        console.log('Tags should be mandatory');
        this.parentForm.controls["tags"].setValidators(Validators.required);
        this.parentForm.controls["tags"].setErrors({ notUnique: true });
        this.parentForm.controls["tags"].updateValueAndValidity();
        // this.toggleMoreFields = false;
        // this.showHideMoreFields();
        valid = true;
      }
      else {
        this.parentForm.get("tags").clearValidators();
        this.parentForm.get("tags").setErrors(null);
        this.parentForm.get("tags").updateValueAndValidity();
      }

      let isGfidMandatory = this.mandatoryForResolveFlags[0].isGfidMandatory;
      // C153176-6041 | Issue1- GFCID is not prompted for entry on reply resolve action
      if (isGfidMandatory && (this.isNull(this.parentForm.value.gfpId) || this.isNull(this.parentForm.value.gfcId))) {
        console.log("GFPID/GFCID is mandatory for resolve");
        this.parentForm.controls["gfpId"].setValidators(Validators.required);
        this.parentForm.controls["gfpId"].setErrors({ notUnique: true });
        this.parentForm.controls["gfpId"].updateValueAndValidity();
        this.parentForm.controls["gfcId"].setValidators(Validators.required);
        this.parentForm.controls["gfcId"].setErrors({ notUnique: true });
        this.parentForm.controls["gfcId"].updateValueAndValidity();
        // this.toggleMoreFields = false;
        // this.showHideMoreFields();
        valid = true;
      }
      else {
        this.parentForm.get("gfpId").clearValidators();
        this.parentForm.get("gfpId").setErrors(null);
        this.parentForm.get("gfpId").updateValueAndValidity();

        this.parentForm.get("gfcId").clearValidators();
        this.parentForm.get("gfcId").setErrors(null);
        this.parentForm.get("gfcId").updateValueAndValidity();
      }
    }
    else if (resolveFlag === "N") {
      this.parentForm.get("processingRegion").clearValidators();
      this.parentForm.get("processingRegion").setErrors(null);
      this.parentForm.get("processingRegion").updateValueAndValidity();

      this.parentForm.get("rootCause").clearValidators();
      this.parentForm.get("rootCause").setErrors(null);
      this.parentForm.get("rootCause").updateValueAndValidity();

      this.parentForm.get("inquirySource").clearValidators();
      this.parentForm.get("inquirySource").setErrors(null);
      this.parentForm.get("inquirySource").updateValueAndValidity();

      // C153176-6041 | Issue2- Tag is mandatory
      this.parentForm.get("tags").clearValidators();
      this.parentForm.get("tags").setErrors(null);
      this.parentForm.get("tags").updateValueAndValidity();

      this.parentForm.get("gfpId").clearValidators();
      this.parentForm.get("gfpId").setErrors(null);
      this.parentForm.get("gfpId").updateValueAndValidity();

      this.parentForm.get("gfcId").clearValidators();
      this.parentForm.get("gfcId").setErrors(null);
      this.parentForm.get("gfcId").updateValueAndValidity();

      // this.toggleMoreFields = true;
      // this.showHideMoreFields();
    }
    if (this.parentForm.controls.requestType.errors && (this.parentForm.controls.requestType.pristine || this.parentForm.controls.requestType.untouched))
      valid = true;
    return valid;
  }

  getResolveEnquiry() {
    try {
      if (this.resolve && !this.isNull(this.resolve))
        this.validateFormFields(this.resolve);
    } catch (e) {
    }
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getQueryCountValidation() {
    this.newMessageService.getQueryCountValidation().subscribe(message => {
      try {
        if (message !== "test") {
          this.queryCountMsg = message;
        }
        else {
          this.queryCountMsg = '';
        }
      } catch (e) {
      }
    });
  }

  callCmcSearchWindow() {
    this.cmcsearchComponent.loadCMCSearch(this.popup, this.userDataService.getLoggedInUser());
    /* this.displayCMCSearchPopup = true; */
    /*  this.cmcsearchComponent.display = true; */
  }

  getCmcContactData(cmcData) {
    cmcData.selectedContacts.forEach(data => {
      let item = {
        id: '',
        active: false,
        text: data.text,
        value: data.value,
        email: data.value
      }
      if (this.isExternalEmailInToCc(data.value))
        this.secure.emit(true);
      this.pushToTo(item, cmcData.toCcBcc);
    });
  }

  onClear(event) {
    console.log('event :', event);
  }

  /**Right Click method for open panel */
  //C153176-4229 - to get current field  
  addToTop(event, overlaypanel: OverlayPanel, value: any, field): void {
    this.addOptions = [];
    this.addOptions.push({ name: "Add To Top Contacts" }, { name: "Show Member List" });
    let jsonObject = value;
    this.setData = jsonObject;
    this.currentField = field; //C153176-4229 - to get current field
    //event.dataTransfer.setData("data", jsonObject);   
    overlaypanel.toggle(event);
  }

  // C153176-5746 : Unable to add external contact using right click add to top contact
  /** To add the contacts in Top contacts */
  addTopContacts(event) {
    // C153176-5746 : Unable to add external contact using right click add to top contact
    let selectedGroup = this.parentForm.get('groupName').value;
    let groupIdList = this.userDataService.getAssignedGroupIdList();
    let topContacts = this.userDataService.getTopContacts();
    let nxtIdx = topContacts.length;

    // New top contact object.
    let items = {
      name: this.setData.text,
      email: this.setData.email == null ? this.setData.value : this.setData.email,
      mapToGroupsName: selectedGroup ? selectedGroup : "",
      index: nxtIdx,
      sharedToGroupsName: "",
      createdBy: this.userDataService.getLoggedInUser()
    };

    let updatedTopContacts = topContacts.concat([items]);
    let topContact = new TopContacts(updatedTopContacts, groupIdList);

    // Service call to add contact as top contact.
    this.newMessageService.saveTopContact(topContact).subscribe(result => {
      if (result.processingstatus === "true") {
        // Update top Contacts to log in user.
        this.userDataService.updateFavoriteContacts(updatedTopContacts);
        console.log('Contact is saved as top contact successfully : ' + JSON.stringify(items));
        this.contactSuccess = true;
        this.topContactAddEvent.emit(items);
      }
    }, (error) => {
      console.error('Failed to save contact as top contact', error);
      this.contactError = true;
    });
  }

  closeModal(_str: any) {
    if (_str == "success") { this.contactSuccess = false; }
    if (_str == "error") { this.contactError = false; }
  }


  showMemberList(groupDetail: AllActiveGroup): void {
    let groupMemberList: GroupMemberContact[] = [];

    let groupData = {
      'groupName': this.setData.text
    };

    this.userDataService.getGroupMemberList(groupData).subscribe(groupMemberDetails => {
      if (groupMemberDetails && groupMemberDetails !== null) {
        this.displaySuccess = true;
        groupMemberDetails.forEach(memberContact => {
          groupMemberList.push({ name: memberContact.userName, email: memberContact.email });
        });
        groupMemberList.forEach(item => {
          this.groupMemberList.push({ label: item.name, name: item.name, value: item.name, email: item.email })
        })
      } else if (groupMemberDetails === null) {
        this.displaySuccess = true;
        this.noMember = true;
      }
      this.groupMembers = [];
      //this.groupMemberList = groupMemberList; 
      this.checkboxlistcomp.items = this.groupMemberList;
      this.checkboxlistcomp.assignCopy();
    }, error => {
      //this.errors = error;
      this.displayError = true;
    });
    groupDetail.toggle = !groupDetail.toggle;
  }
  cancelTopContact() {
    this.displaySuccess = false;
    this.groupMemberList = [];

    // When there is no member in the list, so checkbox would not be available.
    if (this.checkboxlistcomp) {
      this.checkboxlistcomp.items = this.groupMemberList;
      this.checkboxlistcomp.assignCopy();
    }
  }

  addToTopContact() {
    //C153176-4229 - add the show member list contacts
    this.selectedMember.forEach(member => {
      let contact = new AllToCcDBUser();
      contact.text = member['name'];
      contact.value = member['email'];
      contact.email = member['email'];
      this.pushToTo(contact, this.currentField);
    });
    let index = this.toUsersList.findIndex((item: any) => item.text == this.setData.text);
    this.toUsersList.splice(index, 1);
    this.displaySuccess = false;
    this.groupMemberList = [];

    // When there is no member in the list, so checkbox would not be available.
    if (this.checkboxlistcomp) {
      this.checkboxlistcomp.items = this.groupMemberList;
      this.checkboxlistcomp.assignCopy();
    }
    this.selectedMember = []; //C153176-4229 - remove selected member
  }

  getSelectedMember(selectedMember) {
    if (selectedMember.checkedStatus) {
      this.selectedMember.push({ name: selectedMember.value, email: selectedMember.email });
    } else {
      let index = this.selectedMember.map(function (member) { return member.value }).indexOf(selectedMember.value);
      this.selectedMember.splice(index, 1);
    }
  }
  OkToClose() {
    this.displayError = false;
  }

  /**
   * Method to get the disclaimer as per group name
   */
  getDisclaimerByGroup(groupName: string): string {
    const disclaimer = this.userDataService.getDisclaimerForNewInquiry(groupName);
    return '<div id="disclaimerDiv"> ' + disclaimer + '</div>';;
  }


  expand() {
    const templateData = [
      {
        templateContent: this.editorData.getData(),
        clcContent: null,
        templateSubject: this.parentForm.value.subject,
        toList: this.parentForm.value.toUsers,
        ccList: this.parentForm.value.ccUsers,
        bccList: this.parentForm.value.bccUsers,
        nlpContent: null // C153176-5420: Placeholder should not be there if NLP content.
        // inquiryId:conversation.inquiryId
      }
    ];

    const recipientData = {
      from: this.parentForm.value.groupName,
      queryCount: this.parentForm.value.queryCount,
      requestType: this.parentForm.value.requestType,
      inquirySource: this.parentForm.value.inquirySource,
      inquirySubStatus: this.parentForm.value.inquirySubStatus,
      processingRegion: this.parentForm.value.processingRegion,
      rootCause: this.parentForm.value.rootCause,
      tag: this.parentForm.value.tags,
      gfcName: this.parentForm.value.gfcName,
      gfcid: this.parentForm.value.gfcId,
      gfpName: this.parentForm.value.gfpName,
      gfpid: this.parentForm.value.gfpId,
      skAccountNo: this.parentForm.value.skAccountNo,
      branch: this.parentForm.value.branch,
      fromWhere: 'messages'
    };

    const expReplyObject = {
      templateData: templateData,
      content: this.editorData.getData(),
      recipientData: recipientData,
      attachments: this.parentForm.value.attachments,
      existingConvAttachements: this.existingConvAttachements
    };

    this.expandEvent.emit(expReplyObject);

  }

  isInquiryDrop(e, emailField) {
    // TBD will use 'emailField' if needed
    const data = e.dataTransfer.getData('text');
    return data && data.startsWith(QmaConstant.QMA_DND_HEADER);
  }

  handleInquiryDrop(e) {
    const data = e.dataTransfer.getData('text');
    this.editorData.sendEvent.emit({ data: data, action: 'drop' });
    this.inboxService.triggerInquiryDrag({ qma: true, active: false });
    this.parentComponent.dropInProcessing = true;
    e.stopPropagation();
  }
  hideCcBcc() {
    this.isChevronUp = !this.isChevronUp;
    this.resizeMailBody.emit('mobile');   //c-4475 -newmessage mobile tinymce height&width
  }

  // Listen to Form changes 
  onValueChanges(): void {
    // Performance Improvement - Listen to form changes after 1s, so that we can control multiple requests
    this.parentForm.valueChanges.pipe(debounceTime(1000)).subscribe(val => {
      console.log('QMA2.0 Performance :: onValueChanges() : Value Changed');
      this.newMessageData.emit(val);
    })
  }

  // display contact info on hover to contact
  async displayContactInfo(event, opContactInfo: OverlayPanel, value: any) {
    try {
      if (opContactInfo && opContactInfo.overlayVisible) {
        opContactInfo.hide();
        return false;
      }
      if (this.GDIR_DISPLAY_INFO) {
        //for soeId 
        if (value && value.id && typeof value.id === "string") {
          this.selectedContactId = value.id.toUpperCase();
          opContactInfo.toggle(event);
        } else if (value && AppUtils.isUndefinedNullOrBlank(value.id) && AppUtils.isCitiDomainEmail(value.email, this.userDataService.loggedInUserInfo.citiDomainList)) {
          //get soeId from email id
          let soeId = value.email.split('@')[0];
          //validate if it is soeId
          let regEx = /^(\w{2}\d{5})$/;
          if (regEx.test(soeId)) {
            this.selectedContactId = soeId.toUpperCase();
            opContactInfo.toggle(event);
          } else {
            //get soeId from email
            let auId = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.auid;
            let contactInfoEndPoint = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.contactInfoEndPoint;
            const suggestionData = await this.globalDirectoryService.getContactSuggestion(value.email.toUpperCase(), "BASIC", auId, contactInfoEndPoint).toPromise();
            let jsonObj = this.globalDirectoryService.parseXmlToJson(suggestionData.toString());
            console.log(jsonObj);
            if (jsonObj && jsonObj.people && jsonObj.people.Person && jsonObj.people.Person.SoeId && jsonObj.people.Person.SoeId._text) {
              let soeId = jsonObj.people.Person.SoeId._text;
              this.selectedContactId = soeId.toUpperCase();
              opContactInfo.toggle(event);
            }
          }
        }
      }
    } catch (e) {
      console.error('error in newMessageComponent#displayContactInfo', e);
    }
  }

  // C153176-5302 : Auto Population if single data is available - Request Type
  getRequestType(recdata: any): string {
    this.populateRequestType(recdata.from);
    let requestType = this.getValidatedRequestType(recdata.requestType);
    if (this.requestTypes.length === this.countCheck && this.isNull(requestType)) {
      requestType = this.requestTypes[1].value;
    }
    return requestType;
  }

  // C153176-5302 : Auto Population if single data is available - Inquiry Source
  getInquirySrc(recdata: any): string {
    this.populateInquirySource();
    let inquirySrc = recdata.inquirySource || "";
    if (this.inquirySourceList.length === this.countCheck && this.isNull(inquirySrc)) {
      inquirySrc = this.inquirySourceList[1].value;
    }
    return inquirySrc;
  }

  getInquirySubStatus(data: any): string {
   
      this.populateInquirySubStatusList();
      let subStatus = "";
      subStatus = data.inquirySubStatus || "";
      if(subStatus === 'Message to review')
          subStatus = data.prevInqSubStatus || "";
      if (this.inquirySubStatusList.length === this.countCheck && this.isNull(subStatus)) {
        subStatus = this.inquirySubStatusList[1].value;
      }
    return subStatus;
  }

  populateInquirySubStatusList() {
    this.inquirySubStatusList = [];
    this.inquirySubStatusList.push({ label: 'Select', value: '' });

    if (this.userDataService.loggedInUserInfo.inquirySubStatusList != null) {
      //let inquirySubStatus: any[] = this.userDataService.loggedInUserInfo.inquirySubStatusList.map(inqSrc => ({ label: inqSrc, value: inqSrc }));
      let inquirySubStatus: any[] = this.userDataService.loggedInUserInfo.inquirySubStatusList;
      if (inquirySubStatus && inquirySubStatus.length > 0) {
        for(let i=0; i<=inquirySubStatus.length; i++){
          if(inquirySubStatus[i] === 'Completed' 
          || inquirySubStatus[i] === 'Reopen - To be reviewed'
          || inquirySubStatus[i] === 'Message to review'
          || inquirySubStatus[i] === 'New'){
          this.inquirySubStatusList.push({label:inquirySubStatus[i], value: inquirySubStatus[i], disabled: true});
          }else{
            this.inquirySubStatusList.push({label:inquirySubStatus[i], value: inquirySubStatus[i]});
          }
        }
        //this.inquirySubStatusList = [...this.inquirySubStatusList, ...inquirySubStatus];
      }
    } else {
      console.log('Inquiey SubStatus list is null');
    }
  }

  // C153176-5302 : Auto Population if single data is available - Processing Region
  getProcessingRegion(recdata: any): string {
    this.populateProcessingRegion(recdata.from);
    let processRegion = recdata.processingRegion || "";
    if (this.processingRegions.length === this.countCheck && this.isNull(processRegion)) {
      processRegion = this.processingRegions[1].value;
    }
    return processRegion;
  }

  // C153176-5302 : Auto Population if single data is available - Root Cause
  getRootCause(recdata: any): string {
    this.populateRootCause(recdata.from);
    let rootCause = recdata.rootCause || "";

    /**
     * C170665-10 | Request Type and Root Cause Linking
     * 
     * Check if the rootcause is exists in the rootcauses list.
     * If not - Get the root cause list from the root cause map and check if the required root cause is exist.
     * If exists - Append the root cause to the list.
     * 
     * This is required for existing inquiry that have request type and root cause but don't have any mapping now.
     */
    if (!this.isNull(rootCause)) {
      let idx = this.rootCauses.findIndex(rc => rc.value == rootCause);
      let rootCauseList = this.userDataService.getRootCause(recdata.from);
      if (rootCauseList && rootCauseList.includes(rootCause) && idx == -1) {
        this.rootCauses.push({ label: rootCause, value: rootCause });
      }
    }

    if (this.rootCauses.length === this.countCheck && this.isNull(rootCause)) {
      rootCause = this.rootCauses[1].value;
    }
    return rootCause;
  }

  addSuggestionToRecipient(citiUser: AllToCcDBUser) {
    if (citiUser && citiUser.value && citiUser.value.length > 0) {
      switch (this.suggestionField) {
        case "to":
          if (!this.isDuplicate(citiUser, 'to'))
            this.toUsersList.push(citiUser);
          this.parentForm.get('toUsers').patchValue(this.toUsersList);
          break;
        case "cc":
          if (!this.isDuplicate(citiUser, 'cc'))
            this.ccUsersList.push(citiUser);
          this.parentForm.get('ccUsers').patchValue(this.ccUsersList);
          break;
        case "bcc":
          if (!this.isDuplicate(citiUser, 'bcc'))
            this.bccUsersList.push(citiUser);
          this.parentForm.get('bccUsers').patchValue(this.bccUsersList);
          break;
      }
      this.secure.emit(citiUser.isExternal);
    }

    this.displaySuggestion = false;
  }

  /**
   * C153176-5896 | On Remove Recipient.
   * 
   * Method to call on unselect recipient from the recipient.
   */
  onRemoveRecipient(recipient: any): void {
    console.log("Remove Recipent : ", recipient);
    this.onUpdateRecipient(recipient);

    if (recipient && recipient.isExternal) {
      let recipientList = this.toUsersList.concat(this.ccUsersList).concat(this.bccUsersList);
      let externalEmail = recipientList.filter(item => {
        if (item.isExternal === true) {
          return true;
        }
      });
      if (externalEmail && externalEmail.length > 0) {
        this.secure.emit(true);
      } else {
        this.secure.emit(false);
      }
    }

  }

  /**
   * Method to select the recipient from suggestion list.
   * 
   * @param recipient 
   */
  onSelectRecipient(recipient: any): void {
    console.log("Select Recipent : ", recipient);
    if (recipient) {
      let isExtrnalEmail = !AppUtils.isCitiDomainEmail(recipient.email, this.userDataService.loggedInUserInfo.citiDomainList);
      if (isExtrnalEmail) {
        recipient.isExternal = true;
        this.secure.emit(true);
      }
    }

    this.onUpdateRecipient(recipient);
  }

  /**
   * Method to update recipient.
   * 
   * @param recipient 
   */
  onUpdateRecipient(recipient: any): void {
    try {
      let rec = recipient.email || recipient.value;
      let extEmail = this.isExternalEmailInToCc(rec);
      if (extEmail) {
        this.isRecUpdated = !this.isRecUpdated;
        this.recipientUpdateEvent.emit(this.isRecUpdated);
      }
    } catch (e) {
      console.error("Fail to fetch email : ", e);
      this.isRecUpdated = !this.isRecUpdated;
      this.recipientUpdateEvent.emit(this.isRecUpdated);
    }
  }

  /**
   * Method to update the root cause based on selected request type.
   * @param requestType 
   */
  onRequestTypeChange(requestType: any): void {
    let groupName = this.parentForm.get('groupName').value;
    let requestTypeRootCauseFilter = this.userDataService.reqTypeRootCauseMap.has(groupName);
    if (requestTypeRootCauseFilter) {
      this.populateRootCause(groupName, requestType.value);
    }
  }

  /**
   * Method to get the list of root cause form the selected request type and the group 
   * from request type and root cause mapping.
   * @param groupName 
   * @param requestType 
   */
  getRootCauseFromMapping(groupName: string, requestType: string): string[] {
    let rootCauses = [];
    if (!isNull(requestType)) {
      let reqTypeRootCauseMap = this.userDataService.getReqTypeRootCauseMapping(groupName);
      if (reqTypeRootCauseMap) {
        for (const [key, value] of Object.entries(reqTypeRootCauseMap)) {
          if (key === requestType) {
            if (Array.isArray(value)) {
              rootCauses = [...value];
            }
            break;
          }
        }
      }
    }
    return rootCauses;
  }

  /**
   * Method to get the default root cause for the specific group.
   * 
   * @param groupName 
   */
  getDefaultRootCause(groupName: string): string[] {
    let rootCauses = [];

    let groupRootCauses: any[] = this.userDataService.getRootCause(groupName);
    if (groupRootCauses && groupRootCauses.length > 0) {
      rootCauses = [...rootCauses, ...groupRootCauses];
    }
    return rootCauses;
  }

  getAttachementImageByExtension(attachement) {
    let extension = attachement && attachement.name ? attachement.name.split('.').pop() : 'unkown';
    let configObj = this.extensionImgIconMapping.find(i => i.extension === extension.toLowerCase());
    const imgUrl = configObj && configObj.imgUrl ? configObj.imgUrl : "assets/Inbox/icon_unknown_file.svg";
    return imgUrl;
  }
  onExistingAttachementSelected(attachement) {
    attachement.isSselected = !attachement.isSselected;
    // Add to current attachment list if existing is selected
    if (attachement && attachement.isSselected) {
      this.postDataForAttachmentFile.push(Object.assign({}, attachement));
    } else {
      // remove from current attachment list if existing is selected
      this.postDataForAttachmentFile = _.without(this.postDataForAttachmentFile, _.findWhere(this.postDataForAttachmentFile, {
        id: attachement.id
      }));
    }
    // if all attachements are selected mark select all checked
    const selectedAttach = this.existingConvAttachements.filter(ea => ea.isSselected);
    this.selectAllAttach = selectedAttach && selectedAttach.length && selectedAttach.length === this.existingConvAttachements.length;
    // update parent form
    this.parentForm.get('attachments').setValue(this.postDataForAttachmentFile);
    // make new attachement visible
    if (this.postDataForAttachmentFile && Array.isArray(this.postDataForAttachmentFile)) {
      setTimeout(() => {
        this.postDataForAttachmentFile.forEach(att => {
          att.showTitleNew = true;
          att.showMenuNew = false;

        });
      }, 200);
    }

    // adjust editor screen after adding attachement panel
    setTimeout(() => {
      this.resizeMailBody.emit({ event: 'attachementPanel' });
    }, 300);
    // C170665-1969 Issue- Maker checker not triggered when attachment from previous email attached during reply.
    // when user selects prev attachement notify new-messge-actions component so maker checker can be verified on attachement
    if (this.postDataForAttachmentFile && Array.isArray(this.postDataForAttachmentFile) && this.postDataForAttachmentFile.length > 0) {
      this.updatePrevAttach.emit(true);
      this.parentForm.get("hasPrevAttach").setValue(true);
    } else {
      this.updatePrevAttach.emit(false);
      this.parentForm.get("hasPrevAttach").setValue(false);
    }
    
  }
  onMouseEnterAttTitle(attachment) {
    this.existingConvAttachements.forEach((att) => {
      if (attachment.id === att.id) {
        attachment.showMenu = true;
        attachment.showTitle = false;
      } else {
        att.showMenu = false;
        att.showTitle = true;
      }
    });
  }
  onMouseLeaveAttTitle(attachment) {
    this.existingConvAttachements.forEach((att) => {
      if (attachment.id === att.id) {
        attachment.showMenu = false;
        attachment.showTitle = true;
      } else {
        att.showMenu = false;
        att.showTitle = true;
      }
    });
  }
  onMouseEnterAttMenu(attachment) {
    this.existingConvAttachements.forEach((att) => {
      if (attachment.id === att.id) {
        att.showMenu = true;
        att.showTitle = false;
      } else {
        att.showMenu = false;
        att.showTitle = true;
      }
    });
  }
  onMouseLeaveAttMenu(attachment) {
    this.existingConvAttachements.forEach((att) => {
      att.showMenu = false;
      att.showTitle = true;
    });
  }
  onViewctionRedesigned(attachement) {
    let event = { target: { name: 'view', title: attachement.name } };
    this.onDownloadAction(event, null);
  }
  getAttachementCopyIconRedesigned(attachement) {

    return "assets/Inbox/" + this.attachmentsOptions.find(ao => ao.id === attachement.id).imageName;
  }
  onDownloadAction(event, overlaypanel: OverlayPanel): void {
    const selectedAttachment = this.attachmentsOptions.find(option => option.name === event.target.title);

    if (event.target.name == "copy") {

      // C153176-5490 | One last item shoud be available for attachment for attahment. 
      // We need to clear the session storage on new attachment request.
      console.log("Only last item should available to paste for attachment");
      sessionStorage.removeItem("copiedattch");
      // this.copyAllImage = "copy_icon.svg";

      let bAddNewAttach: boolean = false;// C153176-4708	display toaster message on click 
      let attachments = selectedAttachment.fileInfo;

      this.attachmentsOptions.forEach(attachment => {
        if (attachment.name != attachments.name) {
          attachment.imageName = 'copy_icon.svg';
        }
      });

      if (Array.isArray(attachments)) {
        if (attachments.length === 1) {
          console.log('Only one attachment : ', attachments[0].name);
          let curVal = sessionStorage.getItem('copiedattch');
          if (curVal == null) {
            curVal = '';
            bAddNewAttach = true;
          } else {
            if (curVal.indexOf(attachments[0].name) == -1) {
              bAddNewAttach = true;
            }
          }
          if (bAddNewAttach == true) {
            sessionStorage.setItem('copiedattch', curVal + '~' + this.inboxService.viewAttachment(attachments[0].id, attachments[0].name, attachments[0].convId, attachments[0].groupId, attachments[0].hasNominatedOwnership) + ':' + attachments[0].name);

            // C153176-4648 - Show 'Copied' message on copy of an attachment.
            this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: 'Copied' });
          }
        }
      }
      else {
        let curVal = sessionStorage.getItem('copiedattch');
        if (curVal == null) {
          curVal = '';
          bAddNewAttach = true;// C153176-4648 - Show 'Copied' message on copy of an attachment.
        } else {
          if (curVal.indexOf(attachments.name) == -1) {
            bAddNewAttach = true;
          }
        }
        if (bAddNewAttach == true) {
          sessionStorage.setItem('copiedattch', curVal + '~' + this.inboxService.viewAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership) + ':' + attachments.name);

          // C153176-4648 - Show 'Copied' message on copy of an attachment.
          this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: 'Copied' });
        }
      }
    } else {
      if (overlaypanel) {
        overlaypanel.hide();
      }

      //  this.attachByteArray = this.inboxService.attachBinary;******
      var filename = "";
      if (!Array.isArray(selectedAttachment.fileInfo)) {
        filename = selectedAttachment.fileInfo.name;
      }
      else
        filename = selectedAttachment.fileInfo[0].name;
      var extension = filename.split('.').pop().toLowerCase();

      var isViewed = false;

      console.log('attach file extension ' + extension);

      if ((event && event.target && event.target.classList && event.target.classList.contains('attach-name-opt') === true)
        || event.target.name === "view") {
        this.setAttachmentUrl(selectedAttachment.fileInfo);
        if (extension !== 'doc' &&
          extension !== "xls" &&
          extension !== 'ppt' &&
          extension !== 'pptx' &&
          extension !== 'zip' &&
          extension !== 'rar' &&
          extension !== 'wpd' &&
          extension !== 'msg' &&
          extension !== '7z') {
          isViewed = this.attachViewer.displayDialog();
        } else {
          alert('The selected file type is not supported for inline preview');
        }
      }

      if (isViewed === true) {   //view attachment
        var attchViewer = this.attachViewer;
        setTimeout(() => {    //<<<---    using ()=> syntax
          this.attachViewer.showDialog(this.sAttachViewUrl, extension);
        }, 200);
      } else { //downlad attachment
        this.onDownloadAttachment(selectedAttachment.fileInfo);
      }
    }

  }

  setAttachmentUrl(attachments: any): any {
    if (Array.isArray(attachments)) {

      console.log('Only one attachment : ', attachments[0].name);
      this.sAttachViewUrl = this.inboxService.viewAttachment(attachments[0].id, attachments[0].name, attachments[0].convId, attachments[0].groupId, attachments[0].hasNominatedOwnership);
    }
    else {
      console.log('Only one attachment : ', attachments.name);
      this.sAttachViewUrl = this.inboxService.viewAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership);
    }

  }
  onDownloadAttachment(attachments: any): any {
    if (Array.isArray(attachments)) {
      if (attachments.length === 1) {
        console.log('Only one attachment : ', attachments[0].name);
        this.inboxService.downloadAttachment(attachments[0].id, attachments[0].name, attachments[0].convId, attachments[0].groupId, attachments[0].hasNominatedOwnership);
        this.sAttachViewUrl = this.inboxService.viewAttachment(attachments[0].id, attachments[0].name, attachments[0].convId, attachments[0].groupId, attachments[0].hasNominatedOwnership);
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download attachment - mail detailed view", "Click", "Download attachment - mail detailed view", 0);
      }
      else if (attachments.length > 1) {
        console.log('More than one attachment : ', attachments.length);
        const inquiryId = attachments[0].inquiryId;
        const convId = attachments[0].convId;
        this.inboxService.downloadAllAttachments(inquiryId, convId);
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download all attachments - mail detailed view", "Click", "Download all attachments - mail detailed view", 0);
      }
    }
    else {
      console.log('Only one attachment : ', attachments.name);
      this.inboxService.downloadAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership);
      this.sAttachViewUrl = this.inboxService.viewAttachment(attachments.id, attachments.name, attachments.convId, attachments.groupId, attachments.hasNominatedOwnership);
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled, this.userDataService.loggedInUserInfo.userId, "Mailboxes ", "Download attachment - mail detailed view", "Click", "Download attachment - mail detailed view", 0);
    }

  }

  showAttachments(event, overlaypanel: OverlayPanel, attachmentList: any): void {

    this.attachmentsOptions = [];
    // this.copyAllImage = "copy_icon.svg";

    let curVal = sessionStorage.getItem('copiedattch');// C153176-4708	display toaster message on click 
    let copiedAttachments = curVal !== null ? curVal.split('~') : null;

    // C153176-5494 | For copied all - To distunguish single is copied or copy all
    // copiedAtmntCount is used. If count is matched with original attachments then its a copy all.
    let copiedAtmntCount = 0;
    if (copiedAttachments) {
      let attachmentNames = [];
      let matchedAttachments = [];
      copiedAttachments.forEach(attachment => {
        if (attachment) {
          attachmentNames.push(attachment.substring(attachment.indexOf(':') + 1, attachment.length).trim());
        }
      });
      matchedAttachments = attachmentList.filter(attachment => attachmentNames.includes(attachment.name));
      copiedAtmntCount = attachmentNames.length === matchedAttachments.length ? attachmentNames.length : 0;
    }

    attachmentList.forEach(attachment => {
      let attachInfo: attachmentOption = {
        name: attachment.name,
        id: attachment.id,
        fileInfo: attachment,
        // imageName: "copy_icon.svg"// C153176-4708	display toaster message on click 
      };
      // If selected for copy as an attachment. // C153176-4708	display toaster message on click 
      if (curVal !== null && curVal.indexOf(attachInfo.name) > -1 && copiedAtmntCount == 0) {
        // attachInfo.imageName = "copy_icon_blue.svg";
      }
      this.attachmentsOptions.push(attachInfo);
    });

    /*  if (copiedAtmntCount > 1) {
         this.copyAllImage = "copy_icon_blue.svg";
     } */
    if (overlaypanel && event) {
      overlaypanel.toggle(event);
    }

  }

  onCopyActionRedesigned(attachement) {
    let event = { target: { name: 'copy', title: attachement.name } };
    this.onDownloadAction(event, null);
  }

  onMouseEnterNewAttTitle(attachment) {
    this.postDataForAttachmentFile.forEach((att) => {
      if (attachment.id === att.id) {
        attachment.showMenuNew = true;
        attachment.showTitleNew = false;
      } else {
        att.showMenuNew = false;
        att.showTitleNew = true;
      }
    });
  }
  onMouseLeaveNewAttTitle(attachment) {
    this.postDataForAttachmentFile.forEach((att) => {
      if (attachment.id === att.id) {
        attachment.showMenuNew = false;
        attachment.showTitleNew = true;
      } else {
        att.showMenuNew = false;
        att.showTitleNew = true;
      }
    });
  }

  onMouseEnterNewAttMenu(attachment) {
    this.postDataForAttachmentFile.forEach((att) => {
      if (attachment.id === att.id) {
        att.showMenuNew = true;
        att.showTitleNew = false;
      } else {
        att.showMenuNew = false;
        att.showTitleNew = true;
      }
    });
  }
  onMouseLeaveNewAttMenu(attachment) {
    this.postDataForAttachmentFile.forEach((att) => {
      att.showMenuNew = false;
      att.showTitleNew = true;
    });
  }

  getAttachementGridColumnsCount() {
    if (!Array.isArray(this.existingConvAttachements)) {
      return;
    }
    if (!document.getElementById("existingAttachementGrid")) {
      return;
    }
    const gridComputedStyle = window.getComputedStyle(document.getElementById("existingAttachementGrid"));
    const columnCount = gridComputedStyle && gridComputedStyle.getPropertyValue("grid-template-columns")
      && gridComputedStyle.getPropertyValue("grid-template-columns").split(" ").length ?
      gridComputedStyle.getPropertyValue("grid-template-columns").split(" ").length : -1;
    return columnCount;
  }

  processAttachementMoreLink() {
    if (Array.isArray(this.existingConvAttachements)) {
      const columnCount = this.getAttachementGridColumnsCount();
      this.remainingAttachementCount = this.existingConvAttachements.length - columnCount;
      return "+" + this.remainingAttachementCount;
    }
  }

  hideModal() {
    this.showAttchementPopup = false;
  }

  showAttchPopup() {
    this.showAttchementPopup = true;
    this.showAttachments(null, null, this.postDataForAttachmentFile);
  }
  selectDeselectAllAttachements() {
    // reset postDataForAttachmentFile
    this.postDataForAttachment = [];
    this.postDataForAttachmentFile = [];
    this.attachmentsOptions = [];
    // select deselect all attachements 
    this.selectAllAttach = !this.selectAllAttach;
    if (this.existingConvAttachements && this.existingConvAttachements.length && this.existingConvAttachements.length > 0) {
      this.existingConvAttachements.forEach((att: any) => {
        att.isSselected = this.selectAllAttach;
        // if select all scenario add it to postDataForAttachmentFile
        if (att.isSselected) {

          this.postDataForAttachmentFile.push(Object.assign({}, att));
          const attachInfo: attachmentOption = {
            name: att.name,
            id: att.id,
            fileInfo: att,
          };
          this.attachmentsOptions.push(attachInfo);
        }
      });
    }
    // in case deselect remove all attacehements
    if (!this.selectAllAttach) {
      this.postDataForAttachmentFile = [];
    }
    this.parentForm.get("attachments").setValue(this.postDataForAttachmentFile);

    if (this.postDataForAttachmentFile && Array.isArray(this.postDataForAttachmentFile) && this.postDataForAttachmentFile.length > 0) {
      this.updatePrevAttach.emit(true);
      this.parentForm.get("hasPrevAttach").setValue(true);
    } else {
      this.updatePrevAttach.emit(false);
      this.parentForm.get("hasPrevAttach").setValue(false);
    }
  
  }
  btnAttachClick() {
    this.hideModal();
  }
  btnAttachCancelClick() {
    this.postDataForAttachment = [];
    this.postDataForAttachmentFile = [];
    this.attachmentsOptions = [];
    this.hideModal();
    // unselect all attach
    if (this.existingConvAttachements && this.existingConvAttachements.length && this.existingConvAttachements.length > 0) {
      this.existingConvAttachements.forEach((att: any) => {
        att.isSselected = false;
      });
    }
  }
  getViewIconImage(attachement) {
    return attachement && attachement.viewIconUrl ? attachement.viewIconUrl : this.menuIconImageConfig.viewIconSrc;
  }
  onMouseOverViewIcon(attachement) {
    if (attachement) {
      attachement.viewIconUrl = this.menuIconImageConfig.viewIconmouseOn;
    }
  }
  onMouseOutViewIcon(attachement) {
    if (attachement) {
      attachement.viewIconUrl = this.menuIconImageConfig.viewIconmouseOut;
    }
  }
  getDownloadIconImage(attachement) {
    return attachement && attachement.downloadIconUrl ? attachement.downloadIconUrl : this.menuIconImageConfig.downloadIconSrc;
  }
  onMouseOverDownloadIcon(attachement) {
    if (attachement) {
      attachement.downloadIconUrl = this.menuIconImageConfig.downloadIconmouseOn;
    }
  }
  onMouseOutDownloadIcon(attachement) {
    if (attachement) {
      attachement.downloadIconUrl = this.menuIconImageConfig.downloadIconmouseOut;
    }
  }
  // C170665-1969 Issue- Maker checker not triggered when attachment from previous email attached during reply.
  // when user selects prev attachement notify new-messge-actions component so maker checker can be verified on attachement
  updatePrevAttachementStatus(existingConvAttachements) {
    if (existingConvAttachements && Array.isArray(existingConvAttachements)
      && existingConvAttachements.some(att => att.isSselected === true)) {
      this.parentForm.get("hasPrevAttach").setValue(true);
    } else {
      this.parentForm.get("hasPrevAttach").setValue(false);
    }
  }

  populateMandatoryFields(groupName) {
    this.isRootCauseMandatoryab = false;
    this.isRootCauseMandatoryWOReplyab = false;
    this.isProcessingRegionMandatoryab = false;
    this.isInqSourceMandatoryab = false;
    this.isGfidMandatoryab = false;
    this.isTagMandatoryab = false;
    this.enableInquirySubStatus = false;
    if (!AppUtils.isUndefinedNullOrBlank(groupName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        loginUserInfo.myGroups.forEach(fromData => {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) && !AppUtils.isUndefinedNullOrBlank(fromData.groupName) && fromData.groupName.toUpperCase() == groupName.toUpperCase()) {
            if (fromData.isRootCauseMandatory) {
              this.isRootCauseMandatoryab = true;
              if (fromData.isRootCauseMandatoryWOReply) {
                this.isRootCauseMandatoryWOReplyab = true;
              }
            }
            if (fromData.isProcessingRegionMandatory) {
              this.isProcessingRegionMandatoryab = true;
            }
            if (fromData.isInquirySourceMandatory) {
              this.isInqSourceMandatoryab = true;
            }
            if (fromData.isGfidMandatory) {
              this.isGfidMandatoryab = true;
            }
            if (fromData.isTagMandatory) {
              this.isTagMandatoryab = true;
            }
            if (this.enableInquirySubStatusFlag && (fromData.enableInquirySubStatus || loginUserInfo.isInquirySubStatusEnabledAtOrg)) {
              if(this.fromNewMessage){
                this.parentForm.get('inquirySubStatus').setValue("New");
              }
              this.enableInquirySubStatus = true;
            }else{
              if(this.fromNewMessage){
                this.parentForm.get('inquirySubStatus').setValue("");
              }
            }
          }
        });
      });
    }
  }
  onPdfViewerOpen($event) {
    this.parentComponent.child.reInitialiseTinyMCE();
    setTimeout(() => {
      let attachFields = {
        fileldFlag: this.postDataForAttachmentFile.length > 0,
        fieldName: "attachment"
      }
      this.resizeMailBody.emit(attachFields);
    }, 3000);
  }

  showUploadFilesPanel(event, overlaypanel: OverlayPanel): void {
    overlaypanel.toggle(event);
  }
  closeModalBlockedChars(_str: any) {
    if (_str == "error") {
      this.displayErrorForBlockedChars = false;
      this.errMsgRestrcitedCharsInFilename = '';
    }
  }
// C170665-3128 Template Issue
processTemplateReceipient(templateReceipients,existingRecipients,mergeRecipients) {
  let processedReceipients = null;
  try {
    if(mergeRecipients === false) {
      processedReceipients = templateReceipients;
    } else {
      // remove prev added rec via template
      existingRecipients = existingRecipients.filter(er=>!er.isAddedFromTemplate);
      // add recent template receipients
      existingRecipients = existingRecipients.concat(templateReceipients);
      // remove duplicates exist in both template and existing
      existingRecipients = _.uniq(existingRecipients, 'text');
      processedReceipients = existingRecipients;
    }
  }
 catch(ex) {
  console.log(ex);
 }
  return processedReceipients;
}
//C170665-3201 On template switch - Subject has stale data of template 1.
// update sub in newmessage when template is empty or not entered by user
applyTemplateSubject(templateSubject,parentForm) {
  // other than new message dont process subject template
  const action = this.messageInstance && this.messageInstance.split(':') && this.messageInstance.split(':')[0] ?
            this.messageInstance.split(':')[0] : '';
  if (action &&  action.toLowerCase() === "forward" || action.toLowerCase() === "reply" || action.toLowerCase() === "replyall") {
    return;
  }
  if(!parentForm.controls.subject.dirty || parentForm.controls.subject.value === "") {
    this.parentForm.patchValue({
      "subject": templateSubject
    });
  } 
  }

  checkForTaskize() {
    this.isTaskizeInquiry = this.taskizeService.checkIsTaskizeInquiry();
    if (this.taskizeEnabled && ((this.messageInstance && this.messageInstance.startsWith(this.qmaConstant.QMA_NEW_BUBBLE)) || ((this.messageInstance && this.messageInstance.startsWith(this.qmaConstant.REPLY_ALL) && this.isTaskizeInquiry)))) {
      this.showFieldOnlyForTaskize = true
    } else {
      this.isTaskizeInquiry = false
      this.showFieldOnlyForTaskize = false
    }

    if (this.taskizeEnabled && this.messageInstance && this.messageInstance.startsWith('ReplyAll') && this.isTaskizeInquiry) {
      this.taskizeInquirySubjectReadonly = true
    } else {
      this.taskizeInquirySubjectReadonly = false
    }
  }
 }
