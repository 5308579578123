import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { MessageService } from 'primeng/api';
import { serviceUrl } from 'src/app/utility/serviceUrl';
import { AdminSetupService } from 'src/app/services/admin-setup/admin-setup.service';
import { NewMessageService } from 'src/app/services/newMessage/new-message.service';
import { GroupSetupService } from '../../services/group-setup/group-setup.service';
import { ReleaseNoteService } from 'src/app/services/release-note/release-note.service';
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { GridOptions, Module } from "@ag-grid-community/core";
import { AppUtils } from "src/app/common/utility/appUtil";
@Component({
  selector: 'app-admin-setup',
  templateUrl: './admin-setup.component.html',
  styleUrls: ['./admin-setup.component.scss'],
  providers: [GroupSetupService]
})
export class AdminSetupComponent implements OnInit {
  isUploadSuccess: boolean = false;
  errAndSuccess: string;
  errorMessageForGroupAdmin: any;
  failGrpMsg: any = [];
  displayGrpTable: boolean = false;
  displayGrpMsg: boolean = false;
  grpMsg: any;
  validationGfcidMsg: any = [];
  failGfcidMsg: any = [];
  displayGfcidTable: boolean = false;
  displatRtmTable: boolean = false;
  validationMsg: any;
  failRtmMsg: any = [];
  displayGfcid: boolean = false;
  gfcidMsg: any;
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() isOpen: boolean = false;
  rtmMessage: any;
  displayRtmMsg: boolean = false;
  showSpinner: boolean = false;
  showGroupName: boolean = false;
  uploadPartiallyValidGFCIDs: boolean = false;
  uploadPartiallyValidRTMs: boolean = false;
  uploadedGroupFiles: any[] = [];
  uploadedGFCIDFiles: any[] = [];
  uploadedRTMFiles: any[] = [];
  uploadPartiallyValidGroups: boolean = false;
  serviceUrl = serviceUrl;
  @ViewChild('uploadFiles') uploadFiles: ElementRef;
  files: FileList;
  uploadedFiles: any[] = [];
  groupSetAllGroupData: any;
  successResolveGroup: boolean = false;// for success message
  errorResolveGroup: boolean = false;// for success message
  errorResolveGroupMessage: string;
  successResolveGroupMessage: string;
  successMISExclusion: boolean = false;// for success message
  errorMISExclusion: boolean = false;// for success message
  errorMISExclusionMessage: string;
  successMISExclusionMessage: string;
  rowData: any;
  rowSelection;
  gridColumnApi;
  idArray: any[] = [];
  deactivateStatus: boolean = false;
  public disablePaginationNextLast: boolean = false;
  public disablePaginationPrevFirst: boolean = false;
  public lbTotalRecords: number = 0;
  public lbPageSize: number = 0;
  public lbCurrentPage: number = 0;
  public lbTotalPages: number = 0;
  constructor(private groupSetupService: GroupSetupService, private newMessageService: NewMessageService, private messageService: MessageService, private adminSetupService: AdminSetupService, private releaseNoteService: ReleaseNoteService) {
    this.rowSelection = 'multiple';
  }
  minDate = new Date();
  exclusionReasonsList: any = [];
  reportNamesList: any = [];
  groupsList: any = [];
  selectedMISExclusionGroup: any;
  selectedBulkResolveGroup: any;
  selectedExclusionReason: any;
  selectedReportName: any;
  IsMISExclusionError: boolean = false;
  IsMISExclusionErrorMsg: any = "";
  IsBulkGroupResolveError: boolean = false;
  exclusionEndDate: Date;
  misExclusionComments: string = '';
  bulkResolveComments: string = null;
  validationError: string = null;
  selectedRowId = [];
  releaseNote: File | null = null;
  postReleaseNoteUpload: boolean = false;
  successOrErrorMsg: any;
  releaseNoteUploadedSuccessfully: boolean = false;
  /*
  columnDefs = [
    { field: 'make' },
    { field: 'model' },
    { field: 'price' }
];

rowData = [
    { make: 'Toyota', model: 'Celica', price: 35000 },
    { make: 'Ford', model: 'Mondeo', price: 32000 },
    { make: 'Porsche', model: 'Boxter', price: 72000 }
];
*/
  defaultColDef = {
    resizable: false,
    filter: true, suppressMovable: true,
    suppressMenu: true,
    cellStyle: { 'background-color': 'white' },
    headerComponentParams: {
      menuIcon: 'fa-bars',
      template:
      '<div class="ag-cell-label-container" role="presentation">' +
      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      '    <span ref="eText" class="ag-header-cell-text mailbox-header" role="columnheader" ></span>' +
      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
      '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
      '  </div>' +
      '</div>'
    },
    suppressColumnVirtualisation: true
  }
  columnDefs = [
    {
      width: 30,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
    },
    { headerName: 'Group Name', field: 'groupName', width: 200 },
    { headerName: 'ReportName', field: 'misExclusionReportName', width: 150 },
    { headerName: 'Start Date', field: 'misExclusionStartDate', width: 120, valueGetter: (params) => this.setDate(params.data.misExclusionStartDate) },
    { headerName: 'End Date', field: 'misExclusionEndDate', width: 120, valueGetter: (params) => this.setDate(params.data.misExclusionEndDate) },
    { headerName: 'Exclusive Reason', field: 'misExclusionReason', width: 150 },
    { headerName: 'Active', field: 'active', width: 100 },
    { headerName: 'Comments', field: 'comments', width: 100 }
  ];
  rowStyle = {
    color: "rgb(0,45,114)"
  }
  gridApi: any;
  actTabIdx: number = 0;
  ngOnInit() {
    this.populateUserGroup();
    this.populateExclusiveReason();
    this.populateReportName();
  }

  handleFileInput(e) {
    let file = e.files[0];
    let data = new FormData();
    data.append('releaseNoteFile', file, file.name);
    data.append('fileName', file.name );
    this.releaseNoteService.uploadReleaseNotes(data).subscribe(result => {
      if (result) {
        this.postReleaseNoteUpload = true;
        this.releaseNoteUploadedSuccessfully = true;
        this.successOrErrorMsg = "Successfully Uploaded Release Notes!";
      }
    },
      (error) => {
        this.postReleaseNoteUpload = true;
        this.releaseNoteUploadedSuccessfully = false;
        this.successOrErrorMsg = "Issue Uploading Release Notes. Please reach out to support.";
        console.log('Error while uploadinng release notes :', error);
      }
    );
  }

  setDate(date) {
    if (!AppUtils.isUndefinedOrNull(date)) {
      let tempDate = new Date(date);
      return (tempDate.getMonth() + 1) + '/' + tempDate.getDate() + '/' + tempDate.getFullYear();
    }

  }

  closeModal() {
    // this.isOpen = false;
    this.modalClosed.emit();
  }

  downloadTemplate(templateType: string) {
    this.adminSetupService.downloadTemplate(templateType);
  }

  downloadGroupTemplate() {
    this.downloadTemplate('group');
  }

  downloadGFCIDTemplate() {
    this.downloadTemplate('gfpcid');
  }

  downloadRTMTemplate() {
    this.downloadTemplate('rtm');
  }

  onGroupFilesUpload(e) {
    let file = e.files[0];
    let status = this.uploadPartiallyValidGroups ? 'true' : 'false';
    let groupData = 'groupData';
    let data = new FormData();
    data.append('fileDetails', file.name);
    data.append('partialUpload', status);
    data.append('type', groupData);
    data.append('grpSetupFile', file, file.name);
    this.showSpinner = true;
    console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onGroupFilesUpload");
    this.adminSetupService.processGrpBulkUpload(data).subscribe(result => {
      if (result) {
        this.processOnSuccessResult(result);
      }
    },
      (error) => {
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onGroupFilesUpload");
        console.log('error while feedback file upload :', error);
      }
    );
  }
  processOnSuccessResult(e) {
    if (!AppUtils.isUndefinedOrNull(e) && !AppUtils.isUndefinedOrNull(e.success) && e.success) {
      this.onSuccessGrpsUpload(e);
    } else {

      if (!AppUtils.isUndefinedOrNull(e.success)) {
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.processOnSuccessResult");
        this.displayGrpMsg = true;
        this.grpMsg = e.message;
      }
    }
  }
  onSuccessGrpsUpload(response) {
    if (!AppUtils.isUndefinedOrNull(response.validGrps) && response.validGrps.length > 0) {
      let resString = "Validation of " + response.validGrps.length + " groups successfull !";
      if (!AppUtils.isUndefinedOrNull(response.onboardingResult) && !AppUtils.isUndefinedOrNull(response.onboardingResult.onboarded)
        && response.onboardingResult.onboarded.length > 0) {
        resString += "<br/>" + response.onboardingResult.onboarded.length + " groups Onboarded successfully !";
      }
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessGrpsUpload");
      this.displayGrpMsg = true;
      this.grpMsg = resString;
    } else {
      this.displayGrpMsg = false;
      this.grpMsg = '';
    }

    if (!AppUtils.isUndefinedOrNull(response.invalidGrps) && response.invalidGrps.length > 0) {
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessGrpsUpload");
      this.displayGrpMsg = true;
      this.grpMsg = " Validation of following " + response.invalidGrps.length + " groups failed !!";

      this.displayFailedGroups(response.invalidGrps);
    } else {
      this.displayGrpMsg = false;
      this.isUploadSuccess = true;
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessGrpsUpload");
      this.errAndSuccess = "Success Confirmation";
      this.errorMessageForGroupAdmin = response.message;
      setTimeout(() => { this.isUploadSuccess = false; }, 3000);
    }
  }
  displayFailedGroups(failedGrps) {
    this.displayGrpTable = true;
    for (let i = 0; i < failedGrps.length; i++) {
      const groupName = failedGrps[i].groupName;
      const validationFailure = failedGrps[i].validationFailure;
      if (!AppUtils.isUndefinedOrNull(groupName)) {
        if (!AppUtils.isUndefinedOrNull(validationFailure) && validationFailure.length > 0) {
          for (let i = 0; i < validationFailure.length; i++) {
            this.validationGfcidMsg.push(validationFailure[i]);
          }
        }
        this.failGrpMsg.push({ 'row': i + 1, 'lineNo': failedGrps[i].groupName, 'validation': this.validationGfcidMsg });
        console.log(this.failGrpMsg);
      }
    }
  }

  onGFCIDFilesUpload(e) {
    let file = e.files[0];
    let status = this.uploadPartiallyValidGFCIDs ? 'true' : 'false';
    let groupData = 'gfcidData';
    let data = new FormData();
    data.append('fileDetails', file.name);
    data.append('partialUpload', status);
    data.append('type', groupData);
    data.append('gfcidSetupFile', file, file.name);
    // this.files
    this.showSpinner = true;
    console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onGFCIDFilesUpload");
    this.adminSetupService.processGfpcBulkUpload(data).subscribe(result => {
      if (result) {
        this.processOnSuccessResultGfcid(result);
      }
    },
      (error) => {
        console.log('error while  file upload :', error);
      }
    );
    //this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
  }
  processOnSuccessResultGfcid(e) {
    if (!AppUtils.isUndefinedOrNull(e) && !AppUtils.isUndefinedOrNull(e.success) && e.success) {
      this.onSuccessGfcidUpload(e);
    } else {
      if (!AppUtils.isUndefinedOrNull(e.success)) {
        this.gfcidMsg = e.message;
        this.displayGfcid = true;
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.processOnSuccessResultGfcid");
      }
    }
  }

  onSuccessGfcidUpload(response) {
    if (!AppUtils.isUndefinedOrNull(response.validGfcid) && response.validGfcid.length > 0) {
      let resString = "Valid entries in the file : " + response.validGfcid.length;
      if (!AppUtils.isUndefinedOrNull(response.onboardingResult)
        && !AppUtils.isUndefinedOrNull(response.onboardingResult.newInsertedRecords)
        && !AppUtils.isUndefinedOrNull(response.onboardingResult.recordsAlreadyExists)) {

        resString += "<br/>" +
          response.onboardingResult.newInsertedRecords + " new records inserted into database." +
          "<br/>" +
          response.onboardingResult.recordsAlreadyExists + " records already exists into database."
      }
      this.displayGfcid = true;
      this.gfcidMsg = resString;
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessGfcidUpload");
    } else {
      this.displayGfcid = false;
      this.gfcidMsg = '';
    }

    if (!AppUtils.isUndefinedOrNull(response.invalidGfcid) && response.invalidGfcid.length > 0) {
      this.displayGfcid = true;
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessGfcidUpload");
      this.gfcidMsg = " Validation of following " + response.invalidGfcid.length + " entries failed !!";
      this.displayFailedGfcid(response.invalidGfcid);
    } else {
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessGfcidUpload");
      this.displayGfcid = false;
      this.gfcidMsg = "";
      this.isUploadSuccess = true;
      this.errAndSuccess = "Success Confirmation";
      this.errorMessageForGroupAdmin = response.message;
      setTimeout(() => { this.isUploadSuccess = false; }, 3000);
    }

  }
  displayFailedGfcid(failedGfcid) {
    this.failGfcidMsg = [];
    this.displayGfcidTable = true;
    for (let i = 0; i < failedGfcid.length; i++) {
      const lineNo = failedGfcid[i].index;
      const validationFailure = failedGfcid[i].validationFailure;
      if (!AppUtils.isUndefinedOrNull(lineNo) && !AppUtils.isUndefinedOrNull(validationFailure) && validationFailure.length > 0) {
        for (let i = 0; i < validationFailure.length; i++) {
          this.validationGfcidMsg = validationFailure[i];
        }
      }
      this.failGfcidMsg.push({ 'row': i + 1, 'lineNo': failedGfcid[i].index, 'validation': this.validationGfcidMsg });
    }
  }

  fileSelect() {
    this.rtmMessage = '';
    this.displatRtmTable = false;
    this.displayRtmMsg = false;

  }
  fileSelectGfcid() {
    this.displayGfcidTable = false;
    this.displayGfcid = false;
    this.gfcidMsg = '';
  }
  fileSelectGroup() {
    this.displayGrpMsg = false;
    this.displayGrpTable = false;
    this.grpMsg = '';
  }

  onRTMFilesUpload(e) {
    let file = e.files[0];
    let status = this.uploadPartiallyValidRTMs ? 'true' : 'false';
    let groupData = 'rtmData';
    let data = new FormData();
    data.append('fileDetails', file.name);
    data.append('partialUpload', status);
    data.append('type', groupData);
    data.append('rtmSetupFile', file, file.name);
    this.showSpinner = true;
    console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onRTMFilesUpload");
    this.adminSetupService.processRtmBulkUpload(data).subscribe(result => {
      if (result) {
        this.processOnSuccessResultRtm(result);
      }
    },
      (error) => {
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onRTMFilesUpload");
        console.log('error while file upload :', error);
      }
    );
    // this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
  }
  processOnSuccessResultRtm(result) {
    if (!AppUtils.isUndefinedOrNull(result) && !AppUtils.isUndefinedOrNull(result.message) && !AppUtils.isUndefinedOrNull(result.success) && result.success) {
      this.onSuccessRtmUpload(result);
    } else {
      if (!AppUtils.isUndefinedOrNull(result.success)) {
        this.displayRtmMsg = true;
        this.rtmMessage = result.message;
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.processOnSuccessResultRtm");
      }
    }

  }
  onSuccessRtmUpload(response) {
    if (!AppUtils.isUndefinedOrNull(response.validRtm) && response.validRtm.length > 0) {
      let resString = "Valid entries in the file : " + response.validRtm.length;
      if (!AppUtils.isUndefinedOrNull(response.onboardingResult)
        && !AppUtils.isUndefinedOrNull(response.onboardingResult.newInsertedRecords)
        && !AppUtils.isUndefinedOrNull(response.onboardingResult.recordsAlreadyExists)) {

        resString += "<br/>" +
          response.onboardingResult.newInsertedRecords + " new records inserted into database." +
          "<br/>" +
          response.onboardingResult.recordsAlreadyExists + " records already exists into database.";
      }
      this.rtmMessage = resString;
      this.displayRtmMsg = true;
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessRtmUpload");
    } else {
      this.displayRtmMsg = false;
      this.rtmMessage = '';
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessRtmUpload");
    }

    if (!AppUtils.isUndefinedOrNull(response.invalidRtm) && response.invalidRtm.length > 0) {
      this.displayRtmMsg = true;
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessRtmUpload");
      this.rtmMessage = " Validation of following " + response.invalidRtm.length + " entries failed !!";
      this.displayFailedRtm(response.invalidRtm);
    } else {
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.onSuccessRtmUpload");
      this.displayRtmMsg = false;
      this.rtmMessage = '';
      this.isUploadSuccess = true;
      this.errAndSuccess = "Success Confirmation";
      this.errorMessageForGroupAdmin = response.message;
      setTimeout(() => { this.isUploadSuccess = false; }, 3000);
    }
  }
  displayFailedRtm(failedRtm) {
    this.failRtmMsg = [];
    this.displatRtmTable = true;
    for (let i = 0; i < failedRtm.length; i++) {
      const row = i + 1;
      const lineNo = failedRtm[i].index;
      const validationFailure = failedRtm[i].validationFailure;

      if (!AppUtils.isUndefinedOrNull(lineNo) && !AppUtils.isUndefinedOrNull(validationFailure) && validationFailure.length > 0) {
        for (let i = 0; i < validationFailure.length; i++) {
          this.validationMsg = validationFailure[i];
        }
      }
      this.failRtmMsg.push({ 'row': i + 1, 'lineNo': failedRtm[i].index, 'validation': this.validationMsg });
    }

  }
  onFilesUploadError(event) {
    console.log('error while uploading file' + JSON.stringify(event));
    console.log('event is:' + event);
    //this.messageService.add({ severity: 'error', summary: 'An error occured while uploading file', detail: event.error });
  }
  //Initialize data
  populateUserGroup() {
    this.groupsList = [];
    this.groupsList.push({
      label: 'Select Group Name',
      value: 'Select Group Name'
    });
    this.groupSetupService.getGroupsList().subscribe((data: any[]) => {
      this.groupSetAllGroupData = data;
      // first group selected on load
      this.selectedBulkResolveGroup = this.groupsList && this.groupsList[0] && this.groupsList[0].value ? this.groupsList[0].value : "";
      data.forEach((item) => {
        this.groupsList.push({
          label: item.text,
          value: item.value
        });
      });
    });
  }
  validateFields() {
    this.IsMISExclusionError = false;
    this.IsMISExclusionErrorMsg = "";
    if ((AppUtils.isUndefinedNullOrBlank(this.selectedMISExclusionGroup) || this.selectedMISExclusionGroup === "Select Group Name") || (AppUtils.isUndefinedNullOrBlank(this.selectedReportName) || this.selectedReportName === "Select Report Name") || (AppUtils.isUndefinedNullOrBlank(this.selectedExclusionReason) || this.selectedExclusionReason === "Select Reason") || AppUtils.isUndefinedNullOrBlank(this.exclusionEndDate) || AppUtils.isUndefinedNullOrBlank(this.misExclusionComments)) {
      this.IsMISExclusionError = true;
      this.IsMISExclusionErrorMsg = "All fields are mandatory";
      return false;
    }
    if ((AppUtils.isUndefinedNullOrBlank(this.selectedMISExclusionGroup) || this.selectedMISExclusionGroup === "Select Group Name")) {
      this.IsMISExclusionError = true;
      this.IsMISExclusionErrorMsg = "Group Id doesnot exist for the group";
      return false;
    }
    return true;
  }

  saveMISExclusionChanges() {
    if (this.validateFields()) {
      let selectedGroupObj = this.groupSetAllGroupData.find((val) => val.value === this.selectedMISExclusionGroup);
      let exclusionDate = new Date(this.exclusionEndDate);
      let date = exclusionDate.getMonth() + 1 + '-' + exclusionDate.getDate() + '-' + exclusionDate.getFullYear();
      let data = {
        "groupName": selectedGroupObj.text,
        "groupId": this.selectedMISExclusionGroup,
        "misExclusionReportName": this.selectedReportName,
        "misExclusionReason": this.selectedExclusionReason,
        "misExclusionEndDate": date,
        "comments": this.misExclusionComments
      }
      this.adminSetupService.saveExclusionReportData(data).subscribe(result => {
        if (result.success) {
          this.successMISExclusionMessage = result.message;
          this.successMISExclusion = result.success;
          setTimeout(() => { this.successMISExclusion = false; }, 3000);
        } else {
          this.errorMISExclusionMessage = result.message;
          this.errorMISExclusion = true;
          setTimeout(() => { this.errorMISExclusion = false; }, 3000);
        }
      },
        (error) => {
          this.errorMISExclusionMessage = error;
          this.errorMISExclusion = true;
          setTimeout(() => { this.errorMISExclusion = false; }, 3000);
        }
      );
    }
  }
  validateFieldsForBulkGroupResolve() {
    this.IsBulkGroupResolveError = false;
    this.validationError = '';

    if ((AppUtils.isUndefinedNullOrBlank(this.selectedBulkResolveGroup) || this.selectedBulkResolveGroup === "Select Group Name") && AppUtils.isUndefinedNullOrBlank(this.bulkResolveComments)) {
      this.IsBulkGroupResolveError = true;
      this.validationError = "Group name and comments are mandatory";
      return false;
    }
    if (AppUtils.isUndefinedNullOrBlank(this.selectedBulkResolveGroup) || this.selectedBulkResolveGroup === "Select Group Name") {
      this.IsBulkGroupResolveError = true;
      this.validationError = "Group Id doesnot exist for the group";
      return false;
    }
    if (AppUtils.isUndefinedNullOrBlank(this.bulkResolveComments)) {
      this.IsBulkGroupResolveError = true;
      this.validationError = "Group name and comments are mandatory";
      return false;
    }
    return true;


  }
  saveBulkResolveChanges() {
    if (this.validateFieldsForBulkGroupResolve()) {
      let selectedGroupObj = this.groupSetAllGroupData.find((val) => val.value === this.selectedBulkResolveGroup);
      let startDate = "10/10/2011";
      let data = {
        "groupName": selectedGroupObj.text,
        "groupId": this.selectedBulkResolveGroup,
        "startDate": startDate,
        "comments": this.bulkResolveComments
      }
      this.adminSetupService.saveBulkGroupResolve(data).subscribe(result => {
        if (result.message) {
          this.successResolveGroupMessage = result.message;
          this.successResolveGroup = result.success;
          setTimeout(() => { this.successResolveGroup = false; }, 3000);
        } else {
          this.errorResolveGroupMessage = result.message;
          this.errorResolveGroup = true;
          setTimeout(() => { this.errorResolveGroup = false; }, 3000);
        }
      },
        (error) => {
          this.errorResolveGroupMessage = `An error occured while saving changes`;
          this.errorResolveGroup = true;
          setTimeout(() => { this.errorResolveGroup = false; }, 3000);
        }
      );
    }
  }
  resetForm() {

  }
  populateReportName() {
    this.reportNamesList.push({
      label: 'Select Report Name',
      value: 'Select Group Name'
    });
    this.reportNamesList.push({
      label: 'QMA Daily Snapshot 1',
      value: 'QMA Daily Snapshot 1'
    });
    this.reportNamesList.push({
      label: 'QMA Daily Snapshot 2',
      value: 'QMA Daily Snapshot 2'
    });
  }
  populateExclusiveReason() {
    this.exclusionReasonsList.push({
      label: 'Select Reason',
      value: 'Select Reason'
    });
    this.exclusionReasonsList.push({
      label: 'Awaiting enhencement',
      value: 'Awaiting enhencement'
    });
    this.exclusionReasonsList.push({
      label: 'Extended onboarding',
      value: 'Awaiting enhencement'
    });
  }
  loadMISExlusionReports() {
    this.IsMISExclusionError = false;
    this.IsMISExclusionErrorMsg = '';
    if ((AppUtils.isUndefinedNullOrBlank(this.selectedMISExclusionGroup) || this.selectedMISExclusionGroup === "Select Group Name")) {
      this.IsMISExclusionError = true;
      this.IsMISExclusionErrorMsg = "Select Group Name";
      return false;
    }
    this.showSpinner = true;
    console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.loadMISExlusionReports");
    const selectedGroupObj = this.groupSetAllGroupData.find((val) => val.value === this.selectedMISExclusionGroup);
    const data = {
      "groupName": selectedGroupObj.text,
      "misExclusionReportName": ''
    };
    this.adminSetupService.getMISExclusionReportRecords(data).subscribe(result => {
      this.rowData = result;
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.loadMISExlusionReports");
    },
      (error) => {
        console.log('error while getting MISE Exclusion report record :', error);
      }
    );
  }
  onSelectionChanged() {
    const selectedRows = this.gridApi.getSelectedRows();
    const arr = [];
    selectedRows.forEach(function (selectedRow, index) {
      arr.push(selectedRow.id);
    });
    this.selectedRowId = arr;
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.rowData = this.rowData;
  }
  deactivateMISExclusion() {
    this.showSpinner = true;
    console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.deactivateMISExclusion");
    const data = {
      "idArray": this.selectedRowId
    };
    this.adminSetupService.deactivateExclusionReportData(data).subscribe(result => {
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::admin-setup.deactivateMISExclusion");
      this.deactivateStatus = result.success;
      setTimeout(() => { this.deactivateStatus = false; }, 3000);
    },
      (error) => {
        console.log('error while getting MISE Exclusion report record :', error);
      }
    );
  }
  onPaginationChanged() {
    if (this.gridApi) {
      this.lbTotalRecords = this.gridApi.paginationGetRowCount();
      this.lbPageSize = this.gridApi.paginationGetPageSize();
      this.lbCurrentPage = this.gridApi.paginationGetCurrentPage() + 1;
      this.lbTotalPages = this.gridApi.paginationGetTotalPages();

      let currentPage = this.gridApi.paginationGetCurrentPage() + 1;
      let lastPage = this.gridApi.paginationGetTotalPages();
      if (currentPage == lastPage) {

        this.disablePaginationNextLast = true;

      } else {

        this.disablePaginationNextLast = false;
      }

      if (currentPage == 1) {

        this.disablePaginationPrevFirst = true;
      } else {
        this.disablePaginationPrevFirst = false;
      }

      if (this.lbPageSize > this.lbTotalRecords) {
        this.lbPageSize = this.lbTotalRecords;
      } else {
        this.lbPageSize = this.gridApi.paginationGetPageSize();
      }
    }
  }

  setText(selector, text) {
    document.querySelector(selector).innerHTML = text;
  }
  setLastButtonDisabled(disabled) {
  }
  onBtNext() {
    this.gridApi.paginationGoToNextPage();
  }
  onBtPrevious() {
    this.gridApi.paginationGoToPreviousPage();
  }
  onBtLast() {
    this.gridApi.paginationGoToLastPage();
  }
  onBtFirst() {
    this.gridApi.paginationGoToFirstPage();
  }
}