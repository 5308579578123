import { Injectable } from '@angular/core';
import { HttpClientService } from '../../utility/http-client.service';
import { HttpClient } from '@angular/common/http';
import { AdminModule } from '../../admin/admin.module';
import { Observable } from 'rxjs';
import { serviceUrl } from 'src/app/utility/serviceUrl';

@Injectable({
  providedIn: 'root'
})

export class UserAdminService extends HttpClientService{
  userServiceURL: string;
  constructor(http: HttpClient) {
    super(http);
  }

  getUserDataById(requestObj: any): Observable<any> {
    return this.post(serviceUrl.getUserDataById, requestObj);
  }

  saveUserAdminData(requestObj: any) {
    let req = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.post(serviceUrl.saveUserAdminData,req);
  }
}
