<ng-template #customLoadingTemplate>
    <div class="custom-class-loader">
        <div class="spinner-container">
            <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                <circle cx="170" cy="170" r="160" stroke="#021e48" />
                <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
                <circle cx="170" cy="170" r="110" stroke="#021e48" />
                <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
            </svg>
        </div>
    </div>
</ng-template>

<ngx-loading *ngIf="showSpinner" [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" 
[template]="customLoadingTemplate">
</ngx-loading>
<div *ngIf ="showMicroUi && loginUserInfo">
    <app-microfrontend-inbox [folderName]="folderName" [accessType] = "accessType" [entityTypeParam] ="entityTypeParam" [entityDetails] =  "entityDetails"  [productFamilyParam] = "productFamilyParam" [dealStatusParam] = "dealStatusParam" [inquiryIdParam] ="inquiryIdParam"></app-microfrontend-inbox>
</div>
<div *ngIf = "notShowMicroUi && !loginUserInfo">
    <app-core></app-core>
</div>
<!-- <app-core *ngIf="isAllowed"></app-core>
<h1 style="color:black"  *ngIf="!isAllowed">Error Page</h1> -->
<p-toast key="qmaToast"></p-toast>