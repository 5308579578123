<!--Responsive Design-->
<!-- inquiry rule redesign start -->
<p-dialog [(visible)]="display" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'inquiryRedisgn createModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'height':'830px','max-width':'1770px', 'width': '100%'}"
    [resizable]="false">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="gfid-title">GROUP NAME : {{selectedGroupName}}</h6>
            <button type="button" (click)="closeBtn()" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <div style="padding-left:20px">

        <div style="width: 99%;display: inline-block;text-align: right;margin-top: 1.6em;">
            <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_inquiry_rules)" (mouseout)="info_inquiry_rules.hide()" alt="Info">
            <p-overlayPanel [styleClass]="'icon-info inquiry_rules_info'" [appendTo]="'body'" #info_inquiry_rules [baseZIndex]="9999">
                <div class="d-flex flex-row mb-0.3">
                    <span><img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info"></span>
                    <span class="info-content">{{infoContent}}</span>
                </div>
            </p-overlayPanel>
        </div>

        <div class="d-flex align-items-center w-100 py-2">
            <span class="inbox-options-item inbox-search-option " [ngClass]="{'hide':filterIconHide}">
                <img class="inbox-search-option" src='assets/Inbox/search_icon_selected_u42.svg' alt='Kiwi standing on oval' (click)="filterIconClick()" alt="Search">
            </span>

            <div class="inbox-input" [ngClass]="{'hide':!filterIconHide}">
                <input class="inbox-filter-txtbox" type="text" placeholder="Filter..." (keyup)="onKey($event)" [(ngModel)]="filterText" />
                <img src="assets/Inbox/close_icon_u73.svg" width=25 (click)="filterCloseClick()" class="mail-box-filter-close-img" alt="Close"/>
            </div>

            <span class="mr-9" style="width:8%;">
                <div class="add-new-btn" (click)="addRules(null, null, 'top')">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                        <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/context/add-button.svg" alt="Add">
                    </span>
                    <span>Add new rule</span>
                </div>
            </span>

            <span style="width:6%">
                <div class="add-new-btn" (click)="setConfirmation('inquiryRules')">
                    <span style="text-decoration: none; display: inline-block;">
                        <img style="width:11px;height:11px;" class="qma-text" src="assets/context/delete-button.svg" alt="Delete">
                    </span>
                    <span>Delete</span>
                </div>
            </span>

            <span class="mr-9" style="width:8%;">
                <div class="add-new-btn" (click)="copyInquiryRecords()">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                        <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/Inbox/copy_icon.svg" alt="Copy">
                    </span>
                    <span>Make a copy</span>
                </div>
            </span>

            <span class="mr-9" style="width:10%;">
                <div class="add-new-btn" (click)="checkDuplicate($event, duplicate)">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                        <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/context/add-button.svg" alt="Check">
                    </span>
                    <span>Check duplicates</span>
                </div>
            </span>

            <!--temperory hide will be enable after service change -->
            <span class="mr-9" style="width:40%;">
                <div *ngIf ="restoreHideFlag" class="add-new-btn" (click)="restoreRulesShowPopUp($event, restoreRules)">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                        <img class="qma-text qma-hide-restore-rules" style="height:13px;width:13px;opacity:2;" src="assets/context/restore-rules.svg" alt="Restore">
                    </span>
                    <span class="qma-hide-restore-rules">Restore rules</span>
                </div>
            </span>

            <span class="mr-9" style="width:6%;">
                <div class="add-new-btn" (click)="uploadInquiry()">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">                                                               
                        <img class="qma-text" style="height:13px;width:13px;opacity:2;transform: rotate(180deg);" src="assets/Inbox/download_icon_u70.svg" alt="Upload">
                    </span>
                    <span>Upload</span>
                </div>
            </span>
            <input name="files" id="files" type="file" multiple="false" style="display:none" #uploadFilesForInquiry (change)="fileEventForInquiry($event)" accept=".xlsx" value="" />

            <span class="mr-9" style="width:6%;">
                <div class="add-new-btn" (click)="download_rules()">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                        <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/Inbox/download_icon_u70.svg" alt="Download">
                    </span>
                    <span>Download</span>
                </div>
            </span>

            <span class="mr-9" *ngIf="invalidRowMessage.length > 0">
                <div class="add-new-btn" (click)="onDownloadLogs()">
                    <span style="text-decoration: none; display: inline-block;padding-right:3px;">
                        <img class="qma-text" style="height:13px;width:13px;opacity:2;" src="assets/Inbox/download_icon_u70.svg" alt="Download">
                    </span>
                    <span>Download failed rules</span>
                </div>
            </span>
        </div>

        <div *ngIf="!validationCheck">
            <span class="errorMsg">{{validationRules}}</span>
        </div>
    </div>

    <div class="d-flex flex-row inquiry-rule">
        <ag-grid-angular style="width: 100%; height: 450px; " class="ag-theme-balham adminview" id="inquiryRules" [rowData]="routesList"
            [columnDefs]="routesColumn" [stopEditingWhenGridLosesFocus]="true" [suppressRowClickSelection]="true"
            [pagination]="true" [defaultColDef]="inquiryRulesChecbox" [singleClickEdit]="true" [rowSelection]="rowSelection"
            [paginationPageSize]="100" (gridReady)="onGridReadyInquiry($event)" suppressPaginationPanel=true (paginationChanged)="onPaginationChanged()"
            (selectionChanged)="onSelectionRules()" [suppressDragLeaveHidesColumns]="true" suppressMenu=true [rowHeight]="43"
            [headerHeight]="44" [modules]="modules">
        </ag-grid-angular>
    </div>

    <!--check duplicate -->
    <p-overlayPanel [styleClass]="'duplicate_panel'" [appendTo]="'body'" [dismissable]="false" #duplicate [baseZIndex]="9999">
        <p-header>
            <div class="d-flex flex-row align-items-center justify-content-between">
                <h6 class="new-rule">NEW RULES</h6>
                <h6 class="duplicate-rule"> DUPLICATE</h6>
                <button type="button" (click)="hideDuplicateModal(duplicate)" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1">
                          <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
                          <h6>CLOSE</h6>
                      </button>
            </div>
        </p-header>
        <div style="width: 100%; height :1px; border: 1px solid #002d72"></div>
        <div style="background-color: rgba(0, 189, 242, 0.06);">
            <div class="d-flex flex-row align-items-center justify-content-between" style="width: 100%; height: 43px;">
                <span class="gfid-title" style="font-size: 14px;margin-left: 7em;">FROM  </span>
                <span class="gfid-title" style="font-size: 14px;width: 3em;">SUBJECT  </span>
                <span class="gfid-title" style="font-size: 14px;width: 26em;">TO  </span>
            </div>
            <div style="width: 100%; height :1px;"></div>
        </div>
        <div class="d-flex flex-row inquiry-rule">
            <ag-grid-angular #inquiryGridDuplicate style="width: 100%; height: 441px; " class="ag-theme-balham admindrp" id="inquiryRulesGridDuplicate"
                [rowData]="duplicateList" [columnDefs]="duplicateColumn" [stopEditingWhenGridLosesFocus]="true"
                [suppressRowClickSelection]="true" [pagination]="true" [defaultColDef]="duplicateCheckBox" [singleClickEdit]="true"
                [rowSelection]="rowSelection" [paginationPageSize]="100" (gridReady)="onGridDuplicate($event)" suppressPaginationPanel=true
                (paginationChanged)="onPaginationChanged()" (selectionChanged)="onSelectionRules()" [suppressDragLeaveHidesColumns]="true"
                suppressMenu=true [rowHeight]="43" [headerHeight]="44" [suppressHorizontalScroll]="true" (rowDataChanged)="dupGridRowDataChanged($event)">
                
            </ag-grid-angular>
        </div>
    </p-overlayPanel>
    <p-overlayPanel [styleClass]="'inq-rules-restore-overlay'" [appendTo]="'body'" [dismissable]="false" #restoreRules [baseZIndex]="9999">
        <p-header>
            <div class="inq-rules-restore-header-container">
                <span class="qma-inq-rule-popup-header">RESTORE INQUIRY RULES</span>
                <span class="qma-inq-rules-reorder-close" (click)="closeRestorePopup($event, restoreRules)">
                        <img [src]="'assets/Inbox/close_secondary_columns.svg'" class="qma-secondary-close-icon" alt="Close">
                        <span>
                            CLOSE
                        </span>
                </span>

            </div>
        </p-header>
        <div class="inq-restore-content">
            <div>
                The inquiry rules will be restored to the version saved last on
            </div>
            <div class="inq-restore-calender">
                <em class="pi pi-calendar"></em> <span class="inq-retore-date">03 JAN 2020 </span>
            </div>
            <div>
                Are you sure you want to go head with the restore ?
            </div>
        </div>
        <!-- <p-footer>
            <div class="d-flex flex-row footer-btn-container"></div>
            <div class="d-flex flex-row flex-wrap bottom-button-holder pt-2 justify-content-end">

                <button type="submit" class="btn save-btn mr-4 btn-sm restore-button" (click)="saveGroup()">Restore</button>
                <button class="btn clear-btn" (click)="resetForm()">Cancel</button>
            </div>
        </p-footer> -->
    </p-overlayPanel>

    <div style="margin-top: 1%;height:20px !important" class="d-flex justify-content-end qma-mailbox-pagination-container">
        <span class="value" id="lbPageSize">{{lbPageSize}}</span>
        <span class="separator"> of </span>
        <span class="value separator" id="lbTotalRecords">{{lbTotalRecords}}</span>
        <span class="separator separator-right"> Rules </span>
        <span>
            <img [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtFirst()"
                id="btFirst" class="pagination-img" src="assets/Inbox/pagination-arrow-first.svg" alt="First"/>
        </span>
        <span class="separator-right">
            <img id="btPrevious" [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtPrevious()"
                class="pagination-img" src="assets/Inbox/pagination-arrow-prev.svg" alt="Previous" />
        </span>
        <span class="separator-right pagination-text-padding">Page </span>
        <span id="lbCurrentPage" class="separator-right pagination-text-padding">{{lbCurrentPage}}</span>
        <span class="separator-right pagination-text-padding">of</span>
        <span id="lbTotalPages" class="separator-right pagination-text-padding">{{lbTotalPages}}</span>
        <span>
            <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btNext" (click)="onBtNext()"
                class="pagination-img" src="assets/Inbox/pagination-arrow-dark.svg" alt="Next"/>
        </span>
        <span class="separator-right">
            <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btLast" (click)="onBtLast()"
                class="pagination-img" src="assets/Inbox/pagination-arrow-dark1.svg" alt="Last"/>
        </span>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn search-btn mr-4" (click)="saveInquiryRules()">Save Rules</button>
            <button type="reset" class="btn clear-btn" (click)="hideModal()">Reset</button>
        </div>
    </p-footer>
</p-dialog>

<!-- inquiry rule redesign end -->
<!-- for sucess and error -->
<p-dialog [(visible)]="displaySuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Success Confirmation</h6>
            <button type="button" (click)="closeModal('success')" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
            <h6>CLOSE</h6>
        </button>
        </div>
    </p-header>
    <div class="success-msg"> Inquiry rule has been saved successully</div>
</p-dialog>
<p-dialog [(visible)]="displayError" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal failureModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Error Message</h6>
            <button type="button" (click)="closeModal('error')" class="d-flex flex-row close qma-text">
                <img src="assets/Inbox/close_icon_u73.svg" alt="Close">
                <h6>CLOSE</h6>
            </button>
        </div>
    </p-header>
    <div class="success-msg">Error while saving Inquiry rule !</div>
</p-dialog>
<!-- Not access for creating rule -->
<p-dialog [(visible)]="isNotAuthorize" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal errorModal ModelClose'"
    [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">Error Message</h6>
        </div>
    </p-header>
    <div class="success-msg">{{errorMessage}}</div>
</p-dialog>
<!--Confirmation popup window-->
<!-- Jira:4703 : Confirmation Dialog before delete -->
<p-dialog [(visible)]="showPopUpDelete" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal'"
    [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'max-width':'500px','width':'50%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="reage-title">CONFIRM ACTION</h6>
            <!-- Jira:4703 : close button on dialog for confirmation of delete -->
            <button type="button" (click)="onCancel()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">           
        </button>
        </div>
    </p-header>
    <div class="reage-body mx-2">
        <div class="d-flex flex-row align-items-center mb-4 pb-4">
            <label>{{confirmMsgText}}</label>
        </div>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button class="btn search-btn mr-4" (click)="onOk()">Ok</button>
            <button type="reset" class="btn clear-btn cancel-btn" (click)="onCancel()">Cancel</button>
        </div>
    </p-footer>
</p-dialog>

<!--Inquiry Rules Upload Message -->
<p-dialog [(visible)]="inquiryRulesUpload" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
    [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '20%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between">
            <h6 class="success-title">{{inquiryRulesMsgTitle}}</h6>
        </div>
    </p-header>
    <div class="success-msg">{{inquiryRulesMsg}}</div>
</p-dialog>

<!--Inquiry Rules Upload Message - Confirmation box -->
<p-dialog [(visible)]="inquiryRulesConfirm" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{'max-width':'500px','width':'50%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="reage-title">CONFIRM ACTION</h6>
            <button type="button" (click)="onInquiryRulesCancel()" class="d-flex flex-row close qma-text">
        <img src="assets/Inbox/close_icon_u73.svg" alt="close">            
    </button>
        </div>
    </p-header>
    <div class="reage-body mx-2">
        <div class="d-flex flex-row align-items-center mb-4 pb-4">
            <label>{{inquiryRulesMsg}}</label>
        </div>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button class="btn search-btn mr-4" (click)="onInquiryRulesConfirm()">Ok</button>
            <button type="reset" class="btn clear-btn" (click)="onInquiryRulesCancel()">Cancel</button>
        </div>
    </p-footer>
</p-dialog>

<!-- Inquiry Rules Upload - Reject Row Message -->
<p-dialog [(visible)]="inquiryRulesRejected" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'"
    [styleClass]="'contextMenuModal'" [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true"
    [style]="{'max-width':'550px','width':'100%'}">
    <p-header>
        <div class="d-flex flex-row align-items-center justify-content-between mx-2 mt-1">
            <h6 class="reage-title">WARNING</h6>
            <button type="button" (click)="onInquiryRulesRejectedConfirm()" class="d-flex flex-row close qma-text">
            <img src="assets/Inbox/close_icon_u73.svg" alt="Close">            
        </button>
        </div>
    </p-header>
    <div class="reage-body mx-2">
        <div class="d-flex flex-column reject-list">
            <label *ngFor="let rowMessage of invalidRowMessage">{{rowMessage}}</label>
        </div>
    </div>
    <p-footer>
        <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button class="btn search-btn mr-4" (click)="onInquiryRulesRejectedConfirm()">Ok</button>
        </div>
    </p-footer>
</p-dialog>