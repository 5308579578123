import { Component, OnInit, ViewChildren, Inject, Input, Output, EventEmitter } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { GroupAdminService } from "src/app/services/group-admin/group-admin.service";
import { MessageService } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { ExcelService} from "src/app/services/common/excel.service";
import { CheckboxListComponent } from 'src/app/common/component/checkbox-list/checkbox-list.component';
import { ClientCategory } from "src/app/model/LoginUserInfo/clientCategory";
import { Module } from "@ag-grid-community/core";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { RightNavigationService } from 'src/app/services/panel/panel.service';
import { SuperAdminSetupService } from 'src/app/services/super-admin-setup/super-admin-setup.service';
import { GroupSetupService } from '../../services/group-setup/group-setup.service';
import { Organization } from 'src/app/model/organization/Organization';
import { OrganizationAuditTrail } from 'src/app/model/organization/OrganizationAuditTrail';
import * as moment from 'moment';
import { SuperAdminCheckboxComponent } from '../SuperAdminCheckboxComponent';
import { OverlayPanel } from "primeng//overlaypanel";
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-super-admin-setup',
  templateUrl: './super-admin-setup.component.html',
  styleUrls: ['./super-admin-setup.component.scss'],
  providers: [MessageService, GroupSetupService]
})
export class SuperAdminSetupComponent implements OnInit {
  metadataBackup: any = [];
  modules: Module[] = AllModules;
  //Loader
  showSpinner: boolean = false;
  showGroupName: boolean = false;
  gridRoleApi: any;
  errAndSuccess: string = "";
  //closeFlagBulkUpload: boolean;
  errorUpload: boolean = false;
  inqprocessingRegionList: any = [];
  inqTagsList: any = [];
  inqRequestTypeList: any = [];
  requestTypes = [];
  @Input() isOpen: boolean = false;
  @Input() ReqtypeRootcauseComponent: any;
  inquirySourceList = [];
  metadataTypeList: any = [];
  selectedGroupList = [];
  public metaRowData: any;
  public orgMetaRowData: any=[];
  public addNewUserData: any = [];
  public addNewUserColumns;
  public newUserSoeid: string = '';
  public addNewUsercolumnDefs: any;
  newUserGridFlag: boolean = true;
  public entitleData: any;
  public superAdminColumns;
  public metaColumnDefs;
  public pinnedTopRowData;
  public pinnedBottomRowData;
  requestTypeList = [];
  rootCauseList = [];
  tagsList = [];
  originalTagList = [];
  processingRegionList = [];
  inquirySource=[];
  metaDataType = 'RequestTypes';
  isProcessingRegionMandatory: boolean = false;
  isTagMandatory: boolean = false;
  isRootCauseMandatory: boolean = false;
  isRootCauseMandatoryWOReply: boolean = false;
  isRootCauseMandatoryAlways: boolean = true;
  dropdownChangeRequest: any = [];
  reqList: any = [];
  metaDeleteFlag: boolean = true;
  domainDeleteFlag: boolean = true;
  metadataRecords: number = 0;
  tabArray: Array<boolean> = [true, false];
  successFlag: boolean = false;
  closeFlag: boolean = false;
  closeFlagAddGroup: boolean = false;
  isExist: boolean = false;
  //isGroupAdmin: boolean = false;
  metaTagValidationFlag: boolean = false;
  selectedCustomClientList: any = [];
  grpSaveMsg: string = "";
  showPopUpDelete: boolean = false;
  confirmMsgText: string;
  groupTab: string;
  public groupManager: string = '';
  public infoMetadata: string = 'Displays the current Group\'s inquiry related details.Request types, Root Cause Tags and Processing Region can be added/edited in this section.';
  public errorMessageForMetadata: string = "";
  public userAddGroupMsg: string = "";
  public concurrentUpdateMsg: string = "Your group's preferences have been updated by another user. \nPlease refresh your screen to get latest updates and make your changes again. \nThe changes that you’ve just made will not be saved.";
  public userSavedSuccess: string = 'Group Details saved successfully!';

  //heirarchyData: HeirarchyData;
  version: number;
  gfcid: any = [];
  subjectEscalationList: any = [];

  //public clientCategoryDataGridApi;
  //public clientCategoryDataGridColumnApi;

  public metaDataGridApi;
  public metaDataGridColumnApi;
  public gridApi;
  public gridColumnApi;
  public addNewUserGridApi;
  public addNewUserGridColumnApi;
  public disablePaginationNextLast: boolean = false;
  public disablePaginationPrevFirst: boolean = false;
  selectedGroup:string ='';
  selectedAdminGroupName: string = "";
  //@ViewChild('adminTabs') adminTabs: TabView;
  @ViewChildren(CheckboxListComponent) checkboxListComponent: any;
  //@ViewChild('uploadFilesForInquiry') uploadFilesForInquiry: ElementRef;
  public entitleGridApi;
  public entitleGridColumnApi;
  public entitleColDef;
  public metaCheckbox;
  public orgCheckbox;
  public domainCheckbox;
  rowSelection;
  entitleSelUser: number = 0;
  entitleDelAndGropFlag: boolean = true;
  public lbTotalPages: number = 0;
  //  Preferences
  groupLevelMetaOverride: boolean = false;
  attachmentBasedMakerChecker: boolean = false;
  predictiveRecipients: boolean = false;
  isEmailSharingBlocked: boolean = false;
  isInquirySourceMandatory: boolean = false;
  isGfidMandatory: boolean = false;
  isInqReferenceIdReq: boolean = false;
  excludeMyGroupWhileReplyAll: boolean = false;
  //  Enable/disable client contact list visibility for group members
  hideClientList: boolean = false;
  //  Enable/disable flag for Re-age need supervisor approval
  supervisiorApprovalForReage: boolean = false;
  //  Request Type and Root Cause Linking
  requestTypeRootCauseFilter: boolean = false;
  holidayAndShiftDetails: boolean = false;
  enableWorkShiftDiv: boolean = true;
  enablePeerReviewDiv: boolean = true;
  peerRevDomain: string = 'All';
  selectedEntGrpName: any = [];
  entGrpName: any = [];
  selectedWeekelyOff: any = [];
  weekelyOff: any[];
  countryList: any[] = [];
  timeZones: any[] = [];
  selectedCountry: object = {};
  selectedTimeZone: object = {};
  selectedStartTime: object = {};
  selectedEndTime: object = {};
  //  end Preferences
  //  email disc
  emailDisc: string = '';
  //  auto response
  autoResponse: string = '';
  //  Escl criteria
  converThreshold: number;
  resTimeThreHr: number;
  resTimeThreMin: number;
  penApprThreHr: number;
  penApprThreMin: number;

  public columnDefs;
  public defaultColDef;

  public loginInfoResponse: any;
   public inquiryFlag = 0;
   public validationCheck: boolean = true;
  public lastLogin: any;
  displaySuccess: boolean = false;
  displayError: boolean = false;
  errorMessage: boolean = false;
  private frameworkComponents;
  metaTagValidationMsg: string = "";

  hasGroupRole: boolean = true;

  isQMAAdmin: boolean = false;
  parser: any;
  clientCategories: ClientCategory[] = [];

  groupRequestTypes = [];
  groupProcessingRegion = [];
  groupTags = [];
  groupUsers = [];
  invalidRowMessage = [];
  uploadedValidInqRules = [];
  //  Message attributes
  inquiryRulesUpload: boolean = false;
  inquiryRulesMsgTitle: string = '';
  inquiryRulesMsg: string = '';
  inquiryRulesConfirm: boolean = false;
  inquiryRulesRejected: boolean = false;
  duplicateColumn: any = [];
  duplicateList: any = [];
  duplicateCheckBox: any;

  // BCC Group Inquiry Rules
  isBccGrpSelected = false;
  inqAssignedGroups: any = [];
  groupSetupBoolean: boolean = false;

  //  Meta data type value.
  selectedMetaDataType: any;
  start_typing_placehoder: string = " start typing..";

  // Restore button implemention for the rules screen
  restoreInquiryDate: Date;
  restoreStatus: boolean = false;
  activeMenuArray: Array<boolean> = [false, true, false, false, false, false, false, false];
  hideSubMenu: boolean = false;
  newMsgIndexes: number[] = [];

  displayDatabaseAdmin: boolean = false;

  //  A pop-up blocks user to create request types
  workshiftErrMsg: string = "";
  //  Variables for Request type and Root Cause mapping
  reqTypeRootCauseMap: any[] = [];
  updatedReqTypeCauseMap: any[] = [];
  groupRootCauses: any[] = [];
  isSuperUser: boolean = true;
  @Output() modalClosed = new EventEmitter<boolean>();

  // for suepr admin request
  selectedRequest: any;
  selectedRequestList: any[] = [];
  // get all group data
  groupSetAllGroupData: any;
  groupsList: any = [];
  // for request
  incomingRequestData: any = [];
  outgoingRequestData: any = [];
  incomingRequestFlag: boolean = false;
  outgoingRequestFlag: boolean = false;
  actTabIdx: number = 0;
  // data for incoming request
  srNo: number;
  requestType: any;
  highLevelRequestTypeMapping: any;
  processingRegion: any;
  workflowStatus: any;
  maker: any;
  createDate: Date;
  comment: any;
  adminTabs: any;
 // org name
  selectedOrganization:any;
  orgName:any;
  orgManager:any;
  orgRequestTypeList = [];
  orgRootCauseList = [];
  orgProcessingRegionList = [];
  orgsuperAdmins:any=[];
  orgHighLevelRequestTypeMapping:any=[];    
  orgEnableGroupLevelOverride:any;
  orgWorkflowStatus:any;
  orgMaker:any;
  orgInquirySource:any;
  organizationList:any[] = [];
  orgWorkflowRemarks:any;
  orgModBy:any;
  orgCrtDate:Date;
  orgCrtBy:any;
  orgAuditTrailSaveStatusSuccess:boolean=false;
  orgAuditTrailSaveStatusFail:boolean=false;
  // for request type root cause mapping
  organizationRequestTypeList=[];
  organziationRootCauseList=[];
  organizationProcessingRegionList=[];
  organizationInquirySource=[];
  orgReqTypeRootCauseMap: any[] = [];
  updatedOrgReqTypeCauseMap: any[] = [];
  organizationGroupList:any=[];
  getAllOrgGroup:any;
  groupHeirarchy;
  secondLevelHeirarchy;
  columnDefsForOutGoingRequest=[];
  detailCellRendererParams;
  autoGroupColumnDef;
  groupDefaultExpanded;
  getDataPath;
  currentUser:any;
  allRequestDisplay:boolean;
  approvedRequestDisplay:boolean;
  pendingApprovalRequestDisplay:boolean=false;
  myRequestDisplay:boolean=true;
  orgId:any;
  // for add new org admin
  entitlementValidationMsg: string = "";
  userEntitlementFlag: boolean = false;
  orgMetaDataChecker:any=[];
  selectedSuperAdmin:any=[];
  orgData:any=[];
  newSuperAdmin:any=[];
  superAdminAddMessage:string;
  superAdminAddSuccess:boolean;
  superAdminAddFail:boolean;
  deleteSuperAdmin:any=[];
  orgAdminDeleteSuccess:boolean=false;
  orgAdminDeleteFail:boolean=false;
  orgAdminDeleteMessage:string;
  orgComment:any;
  selectedAuditTrailId:any;
  selectedRow:any=[];
  requestApprovedValidationFlag:boolean=false;
  requestApprovedValidationMsg:any;
  approvedRequestSuccessMsg:any;
  approvedRequestSuccessFlag:boolean;
  apporvedRequestFailureFlag:boolean;
  requestRejectedMsg:any;
  reqeustRejectedFlag:boolean;
  // for super admin if org does not have data
  superAdmin:any=[];
  alreadyRaisedRequestMessage:any;
  alreadyRaisedRequestFlag:boolean;
  requestColumn:any=[];
  viewDetailRequest:any=[];
  role:string;
  // for request tab
  requestsRequestType:any=[];
  requestsProcessingRegion:any=[];
  requestsInquirySource:any=[];
  requestsRootCause:any=[];
  requestsHighLevelRequestType:any=[];
  requestsRequestTypeDisplay:boolean=false;
  requestsProcessingRegionDisplay:boolean=false;
  requestsRootCauseDisplay:boolean=false;
  requestsEnableGroupLevelMetaDataDisplay:boolean=false;
  requestsHighLevelRequestTypeMapping:boolean=false;
  requestsRootCauseRowData:any=[];
  requestsRequestTypeRowData:any=[];
  requestsProcessingRegionRowData:any=[];
  requestsHighLevelRequestTypeRowData:any=[];
  requestInquirySourceRowData:any=[];
  requestsRequestTypeRootCauseMapping:any=[];
  requestsRequestTypeRootCauseDisplay:boolean=false;
  requestsRequestTypeRootCauseRowData:any=[];
  //for organization group tab
  orgGroupColumn= [
    { headerName: 'Sr.No.', field: 'id', width: 180},
    { headerName: 'Group Name', field: 'groupName', width: 560 },
  ];
  // for reqeusts tab
  orgRequestColumn= [
    { headerName: 'Request type', field: 'requestType', width: 200 }
  ];
  orgHighLevelRequestTypeColumn= [
    { headerName: 'Request type', field: 'requestType', width: 180 },
    { headerName: 'High level request type', field: 'highLevelRequestType', width: 210 }
  ];
  orgRootCauseColumn=[
    { headerName: 'Root Cause', field: 'rootCause', width: 200 }, 
  ];
  disableMetaDataEditAtGroupLevel=[
    { headerName: 'Disable metadata edit at group level', field: 'disableGroupLeveMetaData', width: 320 }, 
  ];
  enableInquirySubStatusColDef=[
    { headerName: 'Enable Inquiry Sub-Status', field: 'orgLevelInquirySubStatusEnabled', width: 320 }, 
  ];
  enableOrgMetaDataColumn=[
    { headerName: 'Enable org metadata', field: 'enableOrgLevelMetaData', width: 200 }, 
  ]
  orgProcessingRegion=[
    { headerName: 'Processing Region', field: 'processingRegion', width: 200 }, 
  ];
  orgRootCauseRequestTypeMapping=[
    { headerName: 'Request type', field: 'requestType', width: 200 },
    { headerName: 'Root Cause', field: 'rootCause', width: 200 } 
  ];
  requestTypeRootCauseStatus:boolean=false;
  requestGridApi:any;
  requestGridColumnApi:any;
  pendingApprovalGridApi:any;
  pendingApprovalColumnApi:any;
  allRequestGridApi:any;
  allRequestColumnApi:any;
  requestTypeGridApi:any;
  requestTypeGridColumnApi:any;
  rootCauseTypeGridApi:any;
  rootCauseTypeGridColumnApi:any;
  processingRegionGridApi:any;
  processingRegionColumnGridApi:any;
  disableGroupLevelEditGridApi:any;
  disableGroupLevelEditGridColumnApi:any;
  requestTypeRootCauseGridApi:any;
  requestTypeRootCauseGridColumnApi:any;
  orgSuperAdminsRowData:any=[];
  requestDisableGroupLevelMetaData:any=[];
  groupData:any;
  backupDisableGroupMetaData:boolean;
  alreadyRaisedRequestRequestType:boolean=false;
  alreadyRaisedRequestRootCause:boolean=false;
  alreadyRaisedRequestRequestTypeStatus=false;
  alreadyRaisedRequestRootCauseStatus=false;
  alreadyRaisedRequestTypeRootCauseMapping=false;
  alreadyRaisedRequestTypeRootCauseMappingStatus=false;
  requestDisableGroupLevelRowMetaData: any;
  backupOrgRequestType:any=[];
  backupOrgRootCause:any=[];
  backupOrgRequestTypeRootCauseMapping:any=[];
  isDisabled:boolean=false;
  orgAuditTrailHighLRequestTypes:any=[];
  orgAuditTrailRequestTypes:any=[];
  orgAuditTrailRootCause:any=[];
  orgAuditTrailRequestTypeRootCauseMapping:any=[];
  orgAuditHighLevelRequestType:any=[];
  orgAuditRootCause:any=[];
  orgAuditRequestType:any=[];
  orgAuditRequestTypeRootCauseMapping:any=[];
  rowClassRules:any;
  enableOrgMetadata:boolean=true;
  enableOrgMetadataSuccess:boolean;
  enableOrgMetaDataFail:boolean;
  backupEnableOrgMetaData:boolean;
  enableOrgLevelMetaDataRowData:any=[];
  requestEnableOrgLevelMetaData:any=[];
  requestOrgLevelMetaDataDisplay:boolean;
  enableOrgLevelMetaDataGridApi:any;
  enableOrgLevelMetaDataColumnApi:any;
  orgLevelInquirySubStatusEnabled: boolean;
  enableIngSubStatusOrgLevelMetaDataDisplay: boolean=false;
  enableIngSubStatusOrgLevelMetaData:any=[];
  enableIngSubStatusOrgLevelRowMetaData: any;
  enableInquirySubStatusFlag: any;
  constructor(private superAdminSetupSerivce:SuperAdminSetupService,private userDataService: UserDataService, private groupAdminService: GroupAdminService, private messageService: MessageService, private rightNavigationService: RightNavigationService, private sanatized: DomSanitizer, @Inject(DOCUMENT) private document: any, private excelService: ExcelService, private groupSetupService: GroupSetupService) {      
    this.rowClassRules = {
      'org-metadata-added': function (params) {
        var statusIndicator = params.data.statusIndicator;
        return statusIndicator ==='A';
          },
      'org-metadata-updated':  function (params) {
          var statusIndicator = params.data.statusIndicator;
          return statusIndicator ==='U';
        },
      'org-metadata-deleted':  function (params) {
          var statusIndicator = params.data.statusIndicator;
          return statusIndicator ==='D';
        }
      };
    this.columnDefsForOutGoingRequest = [
      { 
         headerName: 'Sr.No.', field: 'id',
         checkboxSelection: true,
         width:77,
      },
      { 
        headerName: 'Organization Name',
        field: 'orgName',
        width:180,
      },
      { 
        headerName: 'Workflow status',
        field: 'workflowStatus',
        width:150,
      },
      { 
        headerName: 'Maker',
        field: 'maker',
        width:85,
       },
      { 
        headerName: 'Create date', 
        field: 'createDate', 
        cellRenderer: (data) => {
          return moment(data.value).format('MM/DD/YYYY')
      },
      width:130,
      },
      { 
        headerName: 'Comment', 
        field: 'comment', 
        width:130,
      },
      { 
        headerName: 'Selected Requests',
        field: 'details',
        cellEditor: "agSelectCellEditor",
        width:190,
        cellRendererFramework: SuperAdminCheckboxComponent
       },
    ];
    
    this.defaultColDef = { 
      width: 80,
      checkboxSelection: this.isFirstColumn,
      resizable: true,
      editable: false,
      cellStyle: { 'background-color': 'white' }
    };
    this.detailCellRendererParams = {
      getDetailRowData: function (params) {
        //params.successCallback(params.data.metaData);
      },
    };
  }
      
  showInitial() {
   try{
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.currentUser=loginUserInfo.userId;
      let orgDetails=loginUserInfo.orgAdminRole[0];
      this.selectedOrganization=orgDetails.orgName;
        this.reqList = [];
          if (loginUserInfo.highlevelRequestTypeList) {
            loginUserInfo.highlevelRequestTypeList.forEach(highlevelRequestType => {
              this.reqList.push(highlevelRequestType);
            });
              this.reqList.unshift("");
          }
          if(loginUserInfo.orgAdminRole){
             loginUserInfo.orgAdminRole.forEach(element=>{
              this.organizationList.push({'name': element.orgName});
             })
          }
    })
   }catch(error){
    console.log("Exceptin has occured in SuperAdminSetupComponent.showInitial"+this.displayErrorMessage(error.message));
   }
  }
  gridColumnApiDuplicateInquiry;

  onSelectionCheck() {
    let arr = this.metaDataGridApi.getSelectedRows();
    this.metadataRecords = arr.length;
    if (this.metadataRecords == 0) {
      this.metaDeleteFlag = true;
    } else {
      this.metaDeleteFlag = false;
    }
  }
  isFirstColumn(params) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onEntitlementUserChange(_index: number) {
    this.tabArray.fill(false);
    this.tabArray[_index] = true;
  }
  
  deleteMetaRecords() {
  try{
    this.showPopUpDelete = false; //  Confirmation Dialog before delete 
    this.errorMessageForMetadata = "";
    let selectedMetaData = this.metaDataGridApi.getSelectedRows();
    if (this.metaDataType === 'Root Cause' || this.metaDataType === 'RequestTypes' || !this.isExist) {
      this.metaDataGridApi.updateRowData({ remove: selectedMetaData });
      this.orgMetaRowData.forEach(el => {
        selectedMetaData.forEach(element => {
          let key = Object.keys(element)[0];
          let ind = this.orgMetaRowData.map(function (x) { return x[key] }).indexOf(element[key]);
          if (ind != -1) this.orgMetaRowData.splice(ind, 1);
        });
      });
      //Update the request type and root cause mapping
      this.updateRequestTypeRootCauseMapping(selectedMetaData, this.metaDataType);
    }
  }catch(error){
    console.log("Exceptin has occured in SuperAdminSetupComponent.deleteMetaRecords"+this.displayErrorMessage(error.message));
  }
  }

  resetMetadata() {
    this.metaDataType = 'RequestTypes';
    this.selectedMetaDataType = 'RequestTypes';
    this.metaRowData = [];
    this.metaRowData = JSON.parse(JSON.stringify(this.metadataBackup));
    this.metaDataGridApi.setRowData(this.metadataBackup);
  }
  
  callAssignGroupService(userList, selectedEntGrpName) {
    this.groupAdminService.getBulkAssignUsersToGroups({
      "addToGroup": {
        "groupList": selectedEntGrpName,
        "userList": userList
      }
    }
    ).subscribe(response => {
      if (response !== null) {
        this.userAddGroupMsg = response.message;
      }
    });
  }


  hideModal() {
    this.modalClosed.emit();
  }
  resetForm() {
    this.showInitial();
    this.ngOnInit();
    this.resetMetadata();
    this.checkboxListComponent.forEach((comp) => {
      comp.resetCheckList();
    });
    this.selectedEntGrpName = [];
    this.newUserGridFlag = true;
    this.addNewUserData = [];
  }
  onBtStartEditing(key, char, pinned) {
    this.metaTagValidationFlag = false;
    this.metaTagValidationMsg = '';
    let focussedCell = '';
    if (this.metaDataType === 'RequestTypes') {
      if(this.alreadyRaisedRequestRequestType){
        this.alreadyRaisedRequestRequestTypeStatus=true;
      }else{
        this.alreadyRaisedRequestRequestTypeStatus=false;
        if (this.orgMetaRowData.length === 0 || this.orgMetaRowData[0].requestType !== this.start_typing_placehoder || this.orgMetaRowData[0].requestType !== "") {
          focussedCell = 'requestType';
          this.orgMetaRowData.splice(0, 0, {
            requestID: "0",
            requestType: this.start_typing_placehoder,
            highlevelRequestType: "select request type"
          });
        }
      }
    } else if (this.metaDataType === 'Root Cause') {
       if(this.alreadyRaisedRequestRootCause){
         this.alreadyRaisedRequestRootCauseStatus=true;
       }else{
         this.alreadyRaisedRequestRootCauseStatus=false;
        if (this.orgMetaRowData.length === 0 || this.orgMetaRowData[0].rootCause !== this.start_typing_placehoder || this.orgMetaRowData[0].rootCause !== "") {
          focussedCell = 'rootCause';
          this.orgMetaRowData.splice(0, 0, {
            rootCause: this.start_typing_placehoder,
            rootID: "0"
          });
        }
       }
    }
    this.metaDataGridApi.setRowData(this.orgMetaRowData)
    this.metaDataGridApi.setFocusedCell(0, focussedCell, pinned);
    this.metaDataGridApi.startEditingCell({
      rowIndex: 0,
      colKey: focussedCell,
      rowPinned: pinned
    });
    this.metaDataGridApi.setFocusedCell(0, focussedCell, pinned);
  }  
  ngOnInit() {
    this.showInitial();
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.enableInquirySubStatusFlag = loginUserInfo.enableInquirySubStatusFlag;
    });

    this.selectedRequest='my request';
    let data={
      "maker":this.currentUser,
      "orgName":this.selectedOrganization,
      "requestType":this.selectedRequest
    }
    this.getOrgData();
    this.populateOrgGroupData();
    this.getRequests(data);
    let formData={
      "orgName":this.selectedOrganization
    }
    this.getAlreadyRaisedRequest(formData);
    this.loadColumnDef();
    this.loadRequestDropDownData();
  }
  isMetaTagValid(charStr): boolean {
    try{
      const letterNumber = /^[0-9a-zA-Z\.\-\/&\s]+$/;
    const regex = /[↑,←,→,↔,↕,↨,↓,▲,▼,►,◄]/g;
    if ((charStr.match(regex))) {
      return false;
    }
    if (charStr == "") {
      return false;
    }
    if ((charStr.match(letterNumber))) {
      return true;
    }
    // Check for non english character
    else {
      const nonEnglish = /[^\u0000-\u007F]+/;
      if ((charStr.match(nonEnglish))) {
        return true;
      }
    }
    return false;
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.isMetaTagValid"+this.displayErrorMessage(error.message));
    }
  }
  isRequestTypeValid(charStr): boolean {
    const regex = /[↑,←,→,↔,↕,↨,↓,▲,▼,►,◄]/g;
    if ((charStr.match(regex))) {
      return false;
    } else {
      return true;
    }
  }
  // tag esc issue 
  onkeydown(e) {
    try{
      if (e.event.keyCode == 27 && e.data.tagID !== "0") {
        e.node.setDataValue("tags", e.data.tagID);
        this.metaDataGridApi.stopEditing();
      } else if (e.event.keyCode == 27 && e.data.tagID == "0") {
        e.node.setDataValue("tags", null);
        this.metaDataGridApi.startEditingCell({
          rowIndex: e.rowIndex,
          colKey: "tags"
        });
      }
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.onkeydown"+this.displayErrorMessage(error.message));
    }
  }

  onCellValueChanged(params) {
    // Reset the Meta Tag Vaildation flag and Message before start validation again.
    try{
      this.metaTagValidationFlag = false;
    this.metaTagValidationMsg = ""
    var colId = params.column.getId();
    if (colId === "requestType") {
      const selectedReqType = params.data.requestType;
      const request = this.requestTypeList.filter(reqType => reqType.requestType !== selectedReqType);
      const requestTypeExist = request.map((request, index) => { if (index !== params.rowIndex) return request.requestType }).indexOf(selectedReqType) !== -1;
      const validTag = this.isRequestTypeValid(selectedReqType);
      if (!validTag) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Invalid name! Only ./&- special characters are allowed.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "requestType"
        });
        return false;
      }
      if (requestTypeExist) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Request type already exist.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "requestType"
        });
      }
      else if (selectedReqType == "" || selectedReqType == this.start_typing_placehoder) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Request type value cannot be blank.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "requestType"
        });
      } else if (params.data.highlevelRequestType == "select request type" || params.data.highlevelRequestType == "") {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Please select matching generic request type in order to add a new request type';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "requestType"
        });
      } else {
        this.updateRequestTypeRootCauseOnAdd("requestType", [...this.metaRowData]);
      }
    }
    else if (colId === "rootCause") {
      const selectedRootCause = params.data.rootCause;
      const root = this.rootCauseList.filter(rc => rc.rootcause !== selectedRootCause); // Update logic for already exist
      const rootCausExist = root.map((rc, index) => { if (index !== params.rowIndex) return rc.rootCause }).indexOf(selectedRootCause) !== -1;
      if (rootCausExist) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Root Cause already exist.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "rootCause"
        });
      }
      else if (selectedRootCause == "" || selectedRootCause == this.start_typing_placehoder) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Root Cause value can not be blank.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "rootCause"
        });
      } else {
        this.updateRequestTypeRootCauseOnAdd("rootCause", [...this.metaRowData]);
      }
    }
    else if (colId === "inquirySource") { // for inquiry source
      const selectedInquirySource = params.data.inquirySource;
      if (selectedInquirySource)
        this.metaTagValidationFlag = false;
      const inquirySource = this.inquirySourceList.filter(is => is.inquirySource !== selectedInquirySource); // Update logic for already exist
      const tagsExit = inquirySource.map((is, index) => { if (index !== params.rowIndex) return is.inquirySource }).indexOf(selectedInquirySource) !== -1;
      const validInquirySource = this.isMetaTagValid(selectedInquirySource);
      if (tagsExit) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Tag already exist.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "inquirySource"
        });
      }
      else if (selectedInquirySource == "" || selectedInquirySource == this.start_typing_placehoder) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Inquiry Source value can not be blank.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "inquirySource"
        });
      }

      else if (!validInquirySource) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Invalid name! Only ./&- special characters are allowed.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "inquirySource"
        });
      }
    }
    else if (colId === "processingRegion") {
      const selectedProcessingRegion = params.data.processingRegion;
      const process = this.processingRegionList.filter(pr => pr.processingRegion !== selectedProcessingRegion);; // Update logic for already exist
      const prRegionExit = process.map((pr, index) => { if (index !== params.rowIndex) return pr.processingRegion }).indexOf(selectedProcessingRegion) !== -1;
      if (prRegionExit) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Processing Region already exist.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "processingRegion"
        });
      }
      else if (selectedProcessingRegion == "" || selectedProcessingRegion == this.start_typing_placehoder) {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'Processing Region value can not be blank.';
        this.metaDataGridApi.startEditingCell({
          rowIndex: params.rowIndex,
          colKey: "processingRegion"
        });
      }
    }
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.onCellValueChanged"+this.displayErrorMessage(error.message));
    }
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;    
  }
  onRequestsGridReady(params){
    this.requestGridApi=params.api;
    this.requestGridColumnApi=params.columnApi;  
  }
  onPendingApprovalGridReady(params){
    this.pendingApprovalGridApi=params.api;
    this.pendingApprovalColumnApi=params.columnApi;
  }
  allRequestsGridReady(params){
    this.allRequestGridApi=params.api;
    this.allRequestColumnApi=params.columnApi;
  }
  onRequestTypeGridReady(params){
    this.requestTypeGridApi=params.api;
    this.requestTypeGridColumnApi=params.columnApi;
    this.requestTypeGridApi.setRowData(this.requestsHighLevelRequestTypeRowData);
  }
  onRootCauseGridApi(params){
   this.rootCauseTypeGridApi=params.api;
   this.rootCauseTypeGridColumnApi=params.columnApi;
   this.rootCauseTypeGridApi.setRowData(this.requestsRootCauseRowData);
  }
  onProcessingRegionGridApi(params){
    this.processingRegionGridApi=params.api;
    this.processingRegionColumnGridApi=params.columnApi;
    this.processingRegionGridApi.setRowData(this.requestsProcessingRegionRowData);
  }
  onDisableGroupGridApi(params){
    this.disableGroupLevelEditGridApi=params.api;
    this.disableGroupLevelEditGridColumnApi=params.columnApi;
    this.disableGroupLevelEditGridApi.setRowData(this.requestDisableGroupLevelRowMetaData);
  }

  onEnableInquirySubStatusGridApi(params){
    this.disableGroupLevelEditGridApi=params.api;
    this.disableGroupLevelEditGridColumnApi=params.columnApi;
    this.disableGroupLevelEditGridApi.setRowData(this.enableIngSubStatusOrgLevelRowMetaData);
  }
  onDisableOrgMetaData(params){
    this.enableOrgLevelMetaDataGridApi=params.api;
    this.enableOrgLevelMetaDataColumnApi=params.columnApi;
    this.enableOrgLevelMetaDataGridApi.setRowData(this.enableOrgLevelMetaDataRowData);
  }
  onRequestTypeRootCause(params){
   this.requestTypeRootCauseGridApi=params.api;
   this.requestTypeRootCauseGridColumnApi=params.columnApi;
   this.requestTypeRootCauseGridApi.setRowData(this.requestsRequestTypeRootCauseRowData);
  }
  onMetaDataGridReady(params) {
    this.metaDataGridApi = params.api;
    this.metaDataGridColumnApi = params.columnApi;
  }
  onEntitleGridReady(params) {
    this.entitleGridApi = params.api;
    this.entitleGridColumnApi = params.columnApi;
   this.entitleGridApi.setRowData(this.orgSuperAdminsRowData);
  }

  setIsRootCauseMandatoryWOReply(val) {
    this.isRootCauseMandatoryWOReply = val;
  }
  /* for super admin request */
  onRequestGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeauto;
  }

  /**
   * Method to save the admin group details.
   */
  // inquiry rules
  onRowDataChanged(event) {
    event.api.forEachNode(function (rowNode, index) {
      console.log(rowNode + '' + index);
    });
  }

  onCellEditingStopped() {
    // this.onColumnResized();
  }
  /*  To close success message for Admin */
  closeSuccess() {
    this.closeFlag = false;
  }
  closeAddGroup() {
    this.closeFlagAddGroup = false;
  }
  setConfirmation(tab): void {
    try{
      this.metaTagValidationFlag = false;
    this.metaTagValidationMsg = '';
    if (tab == 'metaData' && this.orgMetaRowData.length == 1 && this.metaDataType === 'RequestTypes') {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'At least one request type is required.';
      this.inquiryFlag = 2;
      return;
    }
    else if (tab == 'metaData' && this.orgMetaRowData.length == 1 && this.metaDataType === 'Root Cause') {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'At least one root cause is required.';
      this.inquiryFlag = 2;
      return;
    }
    else if (tab == 'metaData' && this.orgMetaRowData.length == 1 && this.metaDataType === 'Processing Region') {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'At least one processing region is required.';
      this.inquiryFlag = 2;
      return;
    }
    else if (tab == 'metaData' && this.orgMetaRowData.length == 1 && this.metaDataType === 'Tags') {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'At least one tags is required.';
      this.inquiryFlag = 2;
      return;
    }
    this.showPopUpDelete = true;
    this.confirmMsgText = "Are you sure want to delete the selected items ?";
    this.groupTab = tab;
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.setConfirmation"+this.displayErrorMessage(error.message));
    }
  }
  onCancel() {
    this.showPopUpDelete = false;
  }
  onOk() {
    if (this.groupTab == 'metaData') {
      this.deleteMetaRecords();
    }
    if (this.groupTab == 'userEntitlement') {
      this.deleteOrgAdmin();
    }
  }

  /**
   *  Method to update the request type and root cause mapping on delete operation.
   * 
   * @param deleteItem 
   * @param metaDataType 
   */
  updateRequestTypeRootCauseMapping(deleteItem: any, metaDataType: string): void {
     try{
      let rtrcMap = [...this.reqTypeRootCauseMap];
      let updatedMap = [];
      let rootCausesList = [];
      let requestTypeList = []
      if (metaDataType === 'Root Cause') {
        rootCausesList = this.orgMetaRowData.map(data => data.rootCause);
        let deletedItem = deleteItem[0].rootCause;
        rtrcMap.forEach(item => {
          let rootCause = item.rootCause.filter(rc => rc !== deletedItem);
          if (rootCause && rootCause.length > 0) {
            updatedMap.push({ 'requestType': item.requestType, 'rootCause': rootCause });
          }
        });
      }
      else if (metaDataType === 'RequestTypes') {
        requestTypeList = this.orgMetaRowData.map(data => data.requestType);
        let deletedItem = deleteItem[0].requestType;
        rtrcMap.forEach(item => {
          if (item.requestType !== deletedItem) {
            updatedMap.push({ 'requestType': item.requestType, 'rootCause': item.rootCause });
          }
        });
      }
      // Update the Request Type and Root cause mapping.
      if (requestTypeList.length > 0) {
        this.groupRequestTypes = [...requestTypeList].sort();
      }
      if (rootCausesList.length > 0) {
        this.groupRootCauses = [...rootCausesList].sort();
      }
      this.reqTypeRootCauseMap = [...updatedMap];  
     } catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.checkMetaData"+this.displayErrorMessage(error.message));
     }
  }

  /**
   * Method to update the request type and root cause on adding new.
   * 
   * @param metaDataType 
   * @param metaData 
   */
  updateRequestTypeRootCauseOnAdd(metaDataType: string, metaData: any[]): void {
   try{
    let rootCausesList = [];
    let requestTypeList = []
    if (metaDataType === "requestType") {
      requestTypeList = metaData.map(data => data.requestType);
      this.orgRequestTypeList = [...requestTypeList].sort();
    }
    else if (metaDataType === "rootCause") {
      rootCausesList = metaData.map(data => data.rootCause);
      this.orgRootCauseList = [...rootCausesList].sort();
    }
   }catch(error){
    console.log("Exceptin has occured in SuperAdminSetupComponent.updateRequestTypeRootCauseOnAdd"+this.displayErrorMessage(error.message));
   }
  }
  /**
   * Method to handle the request when request type and root cause mapping is applied.
   * @param event 
   */
  onApplyMapping(event: any): void {
    this.updatedReqTypeCauseMap = [...event];
  }

  populateOrgGroupData()
  {
    let orgName= typeof this.selectedOrganization=="object"?this.selectedOrganization.name:this.selectedOrganization;
    console.log("org name in populate org group data"+orgName);
    try{
      let request={
      "orgName": orgName
    }
    let orgGroupData:any=[]; 
    this.organizationGroupList=[];
    this.showSpinner = true;
      this.superAdminSetupSerivce.getOrgGroup(request).subscribe((data: any[]) => {
        orgGroupData=data;
         for(let i=0;i<orgGroupData.length;i++){
           let group=orgGroupData[i];
           let obj: any = {};
             obj.id= i+ 1; 
            obj.groupName= group.groupName;
            this.organizationGroupList.push(obj);
         }
         let groupName=orgGroupData[0];
        this.groupHeirarchy=groupName.heirarchyData;
        this.secondLevelHeirarchy=this.groupHeirarchy.secondLevelHeirarchy;
        this.orgManager=this.secondLevelHeirarchy[0].userId;            
        this.superAdmin.push({'user':this.secondLevelHeirarchy[0].userName,'soeid':this.secondLevelHeirarchy[0].userId});
        this.showSpinner=false;
        if(this.orgSuperAdminsRowData.length==0){
          this.entitleGridApi.setRowData(this.superAdmin);
        }
        this.gridApi.setRowData(this.organizationGroupList);
   });
  }catch(error){
    console.log("Exceptin has occured in SuperAdminSetupComponent.populateOrgGroupData"+this.displayErrorMessage(error.message));  
  }
}
  /**
   * Method to validate and get the request type and root cause mapping list.
   * 
   * @param requestTypes 
   * @param rootCauses 
   */
  getReqTypeRootCauseMappingList(requestTypes: string[], rootCauses: any): any {
    try{
      let requestTypeRootCauseMapping = {};
    let mapping = [];

    if (this.updatedReqTypeCauseMap.length > 0) {
      mapping = [...this.updatedReqTypeCauseMap];
    }
    else {
      mapping = [...this.reqTypeRootCauseMap];
    }
    mapping.forEach(item => {
      if (requestTypes.includes(item.requestType)) {
        let rootCauseList: [] = item['rootCause'];
        let existingRootCauseList = rootCauseList.filter(e => rootCauses.includes(e));
        if (existingRootCauseList.length > 0) {
          requestTypeRootCauseMapping[item.requestType] = existingRootCauseList;
        }
      }
    });
     return requestTypeRootCauseMapping;
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.checkMetaData"+this.displayErrorMessage(error.message))
    }
  }
  requestChange() {
     let orgName= typeof this.selectedOrganization=="object"?this.selectedOrganization.name:this.selectedOrganization;
  try{
    if(this.selectedRequest=='my request'){
      this.requestApprovedValidationFlag=false;
      this.myRequestDisplay=true;
      this.pendingApprovalRequestDisplay=false;
      this.approvedRequestDisplay=false;
      this.allRequestDisplay=false;
      let data={
        "maker":this.currentUser,
        "orgName":orgName,
        "requestType":this.selectedRequest
      }
      this.getRequests(data);
  }else if(this.selectedRequest=='request for approval'){
    this.myRequestDisplay=false;
      this.pendingApprovalRequestDisplay=true;
      this.approvedRequestDisplay=false;
      this.allRequestDisplay=false;
      let data={
        "maker":this.currentUser,
        "orgName":orgName,
        "requestType":this.selectedRequest
      }
      this.getRequests(data);
  }else if(this.selectedRequest=='All'){
    this.myRequestDisplay=false;
    this.requestApprovedValidationFlag=false;
    this.pendingApprovalRequestDisplay=false;
    this.approvedRequestDisplay=false;
    this.allRequestDisplay=true;
    let data={
      "maker":this.currentUser,
      "orgName":orgName,
      "requestType":this.selectedRequest
    }
    this.getRequests(data);
  }
  }catch(error){ 
    console.log("Exceptin has occured in SuperAdminSetupComponent.requestChange"+this.displayErrorMessage(error.message))
  }
  }
  // data for incoming request  
  isUndefinedNullOrBlank(val) {
    return val === undefined || val === null || val === '';
  }
  getRequests(formData){
   try{
    let orgGroupData:any=[];
    let outRequest:any=[];
    let outGoingData:any=[];
    let details=['Request Type'];
    let selectedRequest='';
    let firstRequest
      this.superAdminSetupSerivce.getRequests(formData).subscribe((data: any[]) => {
        orgGroupData = data;
        firstRequest=orgGroupData[0];
        orgGroupData.forEach((element,index) => {
          if(element.highLevelRequestTypeMapping){
            selectedRequest='highLevelRequestTypeMapping';
          }
          if(element.rootCause){
            selectedRequest=selectedRequest.length>0?selectedRequest+',rootCause':"rootCause";
          }         
         if(element.preferences===null||element.preferences.enableGroupLevelOverride===null){
           console.log("rg preferences is null");
         }else if(element.preferences && element.preferences.enableGroupLevelOverride && (element.preferences!==null||element.preferences.enableGroupLevelOverride!==null)){
            selectedRequest=selectedRequest.length>0?selectedRequest+',disableGroupLeveMetaData':"disableGroupLeveMetaData";    
          }
          if(element.requestTypeRootCauseMapping){
            selectedRequest=selectedRequest.length>0?selectedRequest+',requestTypeRootCauseMapping':"requestTypeRootCauseMapping";   
          }
          if(element.enableOrgLevelMetaData){
            selectedRequest=selectedRequest.length>0?selectedRequest+',enableOrgLevelMetaData':"enableOrgLevelMetaData";
          }

          if(element.preferences===null||element.preferences.enableInquirySubStatus===null){
            console.log("rg preferences is null");
          }else if(element.preferences && element.preferences.enableInquirySubStatus && (element.preferences!==null||element.preferences.enableInquirySubStatus!==null)){
             selectedRequest=selectedRequest.length>0?selectedRequest+',orgLevelInquirySubStatusEnabled':"orgLevelInquirySubStatusEnabled";    
           }
          outRequest={
            'id':index+1,
            'orgId':element.id,
            'orgName':element.orgName,
            'workflowStatus':element.workflowStatus,
            'maker':element.maker,
            'createDate':element.crtDate,
            'comment':element.comment,
            'details':selectedRequest
          }
          selectedRequest='';
          outGoingData.push(outRequest);
        });
        this.outgoingRequestData=outGoingData;
        if(firstRequest){
          let formData={
            'id':firstRequest.id,
            'orgName':firstRequest.orgName
           } 
         this.loadRequestData(formData);
        }else{
          this.requestsHighLevelRequestTypeMapping=false;
          this.requestsRootCauseDisplay=false;
          this.requestsEnableGroupLevelMetaDataDisplay=false;
          this.requestsRequestTypeRootCauseDisplay=false;
          this.requestOrgLevelMetaDataDisplay=false;
          this.enableIngSubStatusOrgLevelMetaDataDisplay=false;
        }
      }); 
   }catch(error){
    console.log("Exceptin has occured in SuperAdminSetupComponent.getRequests"+this.displayErrorMessage(error.message))
   } 
  }
  // for get org data for org name
  getOrgData(){
    let orgName= typeof this.selectedOrganization=="object"?this.selectedOrganization.name:this.selectedOrganization;
     console.log("org name in getOrgData"+orgName);
    try{
      let data={
        "orgName":orgName
      }
        this.superAdminSetupSerivce.getOrgData(data).subscribe((data: any[]) => {
          this.orgData = data;
          this.populateOrgData(this.orgData);
        });
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.getOrgData"+this.displayErrorMessage(error.message));
    }
  }
  populateOrgData(element){
    try{
      if(element){
        this.orgName=element.orgName;
        this.orgManager=element.orgManager;
        this.orgRequestTypeList=element
        this.orgRequestTypeList=[];
        this.orgProcessingRegionList=[];
        this.orgRootCauseList=[];
        this.orgInquirySource=[];
        this.orgHighLevelRequestTypeMapping=[];  
        this.requestTypeList=[];
        this.rootCauseList=[];
        let orgMetaDataAdmin:any=[];
        if(element.requestTypes && element.highLevelRequestTypeMapping){
           this.requestTypeList=element.requestTypes;
           this.getHighLevelRequestTypeBackup(element.highLevelRequestTypeMapping);
           element.highLevelRequestTypeMapping.forEach(req=>{  
           this.orgRequestTypeList.push({'requestType':req.requestType,'highlevelRequestType':req.highlevelRequestType,'requestID':req.requestType});
           })
        }else{
          this.backupOrgRequestType=[];
          this.orgRequestTypeList=[];
        }
        if(element.rootCause){
          this.rootCauseList=element.rootCause;
          this.getRootCauseBackup(element.rootCause);
          element.rootCause.forEach(rootCause=>{
           this.orgRootCauseList.push({ 'rootCause': rootCause, 'rootID': rootCause });
          })
        }else{
          this.backupOrgRootCause=[];
          this.orgRootCauseList=[];
        }
        if(element.highLevelRequestTypeMapping){
          element.highLevelRequestTypeMapping.forEach(mapping=>{
            this.orgHighLevelRequestTypeMapping.push({'requestType':mapping.requestType,'highlevelRequestType':mapping.highlevelRequestType});
          });
         }else{
           this.orgHighLevelRequestTypeMapping=[];
         }
        this.orgReqTypeRootCauseMap = [];
         this.updatedOrgReqTypeCauseMap = []; // Clear this on group change
         let orgReqTypeRootCauseMap = element.requestTypeRootCauseMapping;
         this.backupOrgRequestTypeRootCauseMapping=element.requestTypeRootCauseMapping;
         if (orgReqTypeRootCauseMap) {
           for (const [key, value] of Object.entries(orgReqTypeRootCauseMap)) {
             if (Array.isArray(value)) {
               this.orgReqTypeRootCauseMap.push({ 'requestType': key, 'rootCause': value });
             }
           }
         }else{
           this.orgReqTypeRootCauseMap=[];
           this.backupOrgRequestTypeRootCauseMapping=[];
         }  
        if(element.workflowStatus)
          {
           this.orgWorkflowStatus=element.workflowStatus;
          }
          if(element.workflowRemarks){
             this.orgWorkflowRemarks=element.workflowRemarks;
          }
          if(element.modBy){
            this.orgModBy=element.modBy;
          }
          if(element.crtDate){
           this.orgCrtDate=element.crtDate;
          }
          if(element.superAdmins){
            let noOfSuperAdmins=element.superAdmins.length;
            for(let i=0;i<noOfSuperAdmins;i++){
              let superAdmin=element.superAdmins[i];
              orgMetaDataAdmin.push({'user':element.superAdmins[i].user,'soeid':element.superAdmins[i].soeid});
            }
           this.orgSuperAdminsRowData=orgMetaDataAdmin;
           this.orgsuperAdmins=orgMetaDataAdmin;
           orgMetaDataAdmin=[];
          }
          //[C170665-1719] DCC Requirement: Add Case status field
          if(element.orgPreferences && element.orgPreferences.enableInquirySubStatus){
              this.orgLevelInquirySubStatusEnabled = element.orgPreferences.enableInquirySubStatus=="true"?true:false; 
          }
          if(element.orgPreferences && element.orgPreferences.enableGroupLevelOverride){
            let enableStatus=element.orgPreferences.enableGroupLevelOverride=="true"?true:false;    
            this.groupLevelMetaOverride=enableStatus;
            this.backupDisableGroupMetaData=enableStatus;
          }else{ // if disable group level metadata is not present in db
            this.backupDisableGroupMetaData=false;
          }
          let enableOrgMetaDataStatus=element.enableOrgLevelMetaData;
          if(enableOrgMetaDataStatus!=null && enableOrgMetaDataStatus!=undefined){
           this.enableOrgMetadata=enableOrgMetaDataStatus;
           this.backupEnableOrgMetaData=enableOrgMetaDataStatus;
          }else{ // if disable org level metadata is not present in db
           this.backupEnableOrgMetaData=false;
          }
         
         this.selectOrgMetaDataType(this.metaDataType, 'onload');
         this.organizationRequestTypeList=element.requestTypes ? [...element.requestTypes].sort() : [];
         this.organizationProcessingRegionList=element.processingRegionList ? [...element.processingRegionList].sort() : [];
         this.organziationRootCauseList=element.rootCause?[...element.rootCause].sort():[];
         this.organizationInquirySource=element.inquirySource ?[...element.inquirySource].sort():[];
      }else{
        this.orgRequestTypeList=[];
        this.orgProcessingRegionList=[];
        this.orgRootCauseList=[];
        this.orgInquirySource=[];
        this.orgHighLevelRequestTypeMapping=[];  
        this.requestTypeList=[];
        this.rootCauseList=[];
        this.orgMetaRowData=[];
        this.orgReqTypeRootCauseMap=[];
        this.backupOrgRequestTypeRootCauseMapping=[];
        this.orgSuperAdminsRowData=[];
        this.orgsuperAdmins=[];
      }
    }catch(error){ 
      console.log("Exceptin has occured in SuperAdminSetupComponent.populateOrgData"+this.displayErrorMessage(error.message))
    }
    }
  // populate or meta data
  selectOrgMetaDataType(metaDataTypeVar, mode?: string) {
    //Metadata Validation for request type
    try{
      this.metaTagValidationFlag = false;
      this.metaTagValidationMsg = '';
      this.metaDeleteFlag = true; //Metadata delete button should come when we select any record
      if (mode != 'onload') {
        if (metaDataTypeVar !== 'reqTypeCauseMapping') {
          if (this.updatedReqTypeCauseMap.length > 0) {
            this.reqTypeRootCauseMap = [...this.updatedReqTypeCauseMap];
            this.updatedReqTypeCauseMap = [];
          }
        }
      }
      this.metaDataType = metaDataTypeVar;
      if (this.metaDataType === 'RequestTypes') {
        this.orgMetaRowData = this.orgRequestTypeList;
        this.metaColumnDefs = [
          {
            colId: 'requestType',
            field: "requestType",
            headerName: 'Request type',
            width: 200,
            cellStyle: { 'display': 'inline', 'width': '250px' },          // css alignment issue
            editable: true,
            sort: 'asc',
            comparator: function (a, b) {
              if (typeof a === 'string') {
                return a.localeCompare(b);
              } else {
                return (a > b ? 1 : (a < b ? -1 : 0));
              }
            }
          },
          {
            colId: 'highlevelRequestType',
            field: "highlevelRequestType",
            headerName: 'Generic request type',
            width: 200,
            editable: true,
            cellEditor: "agSelectCellEditor",
            cellStyle: { 'left': '235px !important', 'width': '257px' },// css alignment issue
            cellEditorParams: { values: this.reqList }
          },
          {
            colId: '1',
            field: "requestID",
            hide: true,
            headerName: ''
          }
        ];
  
      } else if (this.metaDataType === 'Root Cause') {
        this.orgMetaRowData = this.orgRootCauseList;
        this.metaColumnDefs = [
          {
            field: "rootCause",
            width: 493,
            editable:true,
            sort: 'asc', //  Sorting in Alphabetical order.
            comparator: function (a, b) {
              if (typeof a === 'string') {
                return a.localeCompare(b);
              } else {
                return (a > b ? 1 : (a < b ? -1 : 0));
              }
            }
          },
          {
            colId: '1',
            field: "rootID",
            hide: true,
            headerName: ''
          }
        ];
       }
      this.alreadyRaisedRequestRequestTypeStatus=false;
      this.alreadyRaisedRequestRootCauseStatus=false;
      if(this.metaDataType==="reqTypeCauseMapping" && this.alreadyRaisedRequestTypeRootCauseMapping){
        this.alreadyRaisedRequestTypeRootCauseMappingStatus=true;
      }else{
        this.alreadyRaisedRequestTypeRootCauseMappingStatus=false;
      }
      this.metaCheckbox = {
        width: 100,
        checkboxSelection: this.isFirstColumn,
        resizable: true,
        suppressMenu: true,
        suppressMovable: true,
        editable: true,
        cellStyle: { 'height': '41px' }
      };
      this.orgCheckbox = {
        width: 100,
        checkboxSelection: false,
        resizable: true,
        suppressMenu: true,
        suppressMovable: true,
        editable: true,
        cellStyle: { 'background-color': 'white'}
      };
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.selectOrgMetaDataType"+this.displayErrorMessage(error.message));
    }
  }
  saveOrgAuditTrailData(){
    try{
      let orgAuditTrailData:OrganizationAuditTrail=new OrganizationAuditTrail();
      let orgName= typeof this.selectedOrganization=="object"?this.selectedOrganization.name:this.selectedOrganization;
      orgAuditTrailData.orgName=orgName; 
     if (this.orgMetaRowData) {
      if (this.metaDataType === 'RequestTypes') {
        this.orgRequestTypeList = this.orgMetaRowData;
      } else if (this.metaDataType === 'Root Cause') {
        this.orgRootCauseList = this.orgMetaRowData;
      }
    }
    if (((this.orgMetaRowData).length > 0) && (this.orgMetaRowData[0].requestType == this.start_typing_placehoder ||
      this.orgMetaRowData[0].highlevelRequestType == this.start_typing_placehoder || this.orgMetaRowData[0].tags == this.start_typing_placehoder ||
      this.orgMetaRowData[0].rootCause == this.start_typing_placehoder || this.orgMetaRowData[0].processingRegion == this.start_typing_placehoder)) {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'Field value can not be blank.';
      this.inquiryFlag = 2;
      return;
    }
    else {
      this.metaTagValidationFlag = false;
      this.metaTagValidationMsg = "";
    };   
    orgAuditTrailData.rootCause=[];
    if(this.orgRootCauseList && this.orgRootCauseList.length>0){
      this.getRaisedRootCause(this.orgRootCauseList,this.backupOrgRootCause)
      orgAuditTrailData.rootCause=this.orgAuditTrailRootCause;
    }else{
      orgAuditTrailData.rootCause=[];
    }
    if(this.orgRequestTypeList && this.orgRequestTypeList.length>0){
      this.getRaisedRequestType(this.orgRequestTypeList,this.backupOrgRequestType);
      orgAuditTrailData.highLevelRequestTypeMapping=this.orgAuditTrailHighLRequestTypes;
      orgAuditTrailData.requestTypes=this.orgAuditTrailRequestTypes;
    }else{
      orgAuditTrailData.highLevelRequestTypeMapping=[];
      orgAuditTrailData.requestTypes=[];
    }
    orgAuditTrailData.crtBy=this.currentUser;
    orgAuditTrailData.maker=this.currentUser;
    orgAuditTrailData.checker=this.orgMetaDataChecker;
    orgAuditTrailData.workflowStatus=this.workflowStatus;
    orgAuditTrailData.comment="new request";
    orgAuditTrailData.workflowStatus='Pending for approval';   
    if (orgAuditTrailData.requestTypes.length == 0 || orgAuditTrailData.highLevelRequestTypeMapping.length == 0) {
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'At least one request type is required.'; 
      this.inquiryFlag = 2;
      return;
    }
    let nonGenericRequestType = orgAuditTrailData.highLevelRequestTypeMapping.filter(reqType => reqType.highlevelRequestType == "");
    if (nonGenericRequestType && nonGenericRequestType.length > 0) {
      // Pre-select the meta-data type as Request type and populates the value
      this.selectedMetaDataType = 'RequestTypes';
      this.metaTagValidationFlag = true;
      this.metaTagValidationMsg = 'Request type should be mapped with generic request type.';
      this.inquiryFlag = 2;
      return;
    }
    if(this.orgAuditRequestType.length>0 && this.orgAuditRootCause.length>0){
    orgAuditTrailData.requestTypeRootCauseMapping = this.getReqTypeRootCauseMappingList(this.orgAuditRequestType, this.orgAuditRootCause);
    this.getRaisedRequestTypeRootCauseMapping(orgAuditTrailData.requestTypeRootCauseMapping,this.backupOrgRequestTypeRootCauseMapping)
    orgAuditTrailData.requestTypeRootCauseMapping=this.orgAuditTrailRequestTypeRootCauseMapping;
    }else{
      orgAuditTrailData.requestTypeRootCauseMapping=[];
    }
    if(this.checkMetaData(this.rootCauseList,this.orgAuditRootCause)){
       orgAuditTrailData.rootCause=[];
    }
    if(this.checkMetaData(this.orgHighLevelRequestTypeMapping,this.orgAuditHighLevelRequestType))
    {
        orgAuditTrailData.requestTypes=[];
        orgAuditTrailData.highLevelRequestTypeMapping=[];
    }  
    if(orgAuditTrailData.rootCause.length==0 && orgAuditTrailData.highLevelRequestTypeMapping.length==0
       && orgAuditTrailData.requestTypes.length==0 && !(Object.keys(orgAuditTrailData.requestTypeRootCauseMapping).length>0))
       {
        this.metaTagValidationFlag = true;
        this.metaTagValidationMsg = 'At least one meta data change is required.'; 
        return;
       }
    // checker
    if(this.orgsuperAdmins.length>0){
      this.orgsuperAdmins.forEach(element=>{
        if(element.soeid!=this.currentUser){
          this.orgMetaDataChecker.push(element);
        }
      });
    }else{
      this.orgMetaDataChecker.push(this.orgManager);
    }
    orgAuditTrailData.checker=this.orgMetaDataChecker;
    this.showSpinner = true;
    this.superAdminSetupSerivce.saveOrgUnApprovedRequest(orgAuditTrailData).subscribe(result => {
     if(result){
      this.showSpinner = false;
      this.orgAuditTrailSaveStatusSuccess=true;
      setTimeout(() => { this.orgAuditTrailSaveStatusSuccess = false; this.hideModal() }, 3000);
     }else{
      this.showSpinner = false;
      this.alreadyRaisedRequestMessage="Request already raised pending for approval";
      this.alreadyRaisedRequestFlag=true;
      console.log("org save audit trail data"+this.alreadyRaisedRequestFlag);
      setTimeout(() => { this.alreadyRaisedRequestFlag = false}, 3000);
     }
    },
      error => {
        this.showSpinner = false;
        this.orgAuditTrailSaveStatusFail=true;
        setTimeout(() => { this.orgAuditTrailSaveStatusFail = false;}, 3000);
        console.error('Error while saving org audit trail  details in SaveOrgAuditTrailData()');
      });
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.saveOrgAuditTrailData"+this.displayErrorMessage(error.message))
    }
  }
  download_rules() {
    let columnList = this.gridApi.columnModel.displayedColumns.filter(col => col.colId !== "order").map(column => column.colId);
    let params = {
      columnKeys: columnList,
      fileName: "Organization Group",
      sheetName: "Organization Group"
    };    
    this.gridApi.exportDataAsExcel(params);
  }
  onFirstDataRendered(params) {
    setTimeout(function () {
     // params.api.getDisplayedRowAtIndex(1).setExpanded(true);
    }, 0);
  }
  onRequestSelectionChanged(params) {
   try{
    this.selectedRow=params.api.getSelectedRows();
    if(this.selectedRow && this.selectedRow.length>0){
      this.orgName=this.selectedRow[0].orgName;
    this.selectedAuditTrailId=this.selectedRow[0].orgId;
    let formData={
      'id':this.selectedAuditTrailId,
      'orgName':this.orgName
     }
    this.loadRequestData(formData);
    }else{
      this.requestsRequestTypeRootCauseDisplay=false;
	    this.requestsHighLevelRequestTypeMapping=false;
	    this.requestsEnableGroupLevelMetaDataDisplay=false;
	    this.requestsProcessingRegionDisplay=false;
	    this.requestsRootCauseDisplay=false;
      this.requestsRequestTypeDisplay=false;
      this.requestOrgLevelMetaDataDisplay=false;
      this.enableIngSubStatusOrgLevelMetaDataDisplay=false;
    }
   }catch(error){
    console.log("Exceptin has occured in SuperAdminSetupComponent.onRequestSelectionChanged"+this.displayErrorMessage(error.message))
   }
  }
  onBtnClick1(e) {
    console.log('button is clicked');
  }
  keyDownFunction(event) {
    try{
      if (event.keyCode == 13) {
        console.log(this.addNewUserData.some(item => item.soeid === this.newUserSoeid))
        this.groupAdminService.getUserDataById({ 'userId': this.newUserSoeid }).subscribe(userDetails => {
          if (userDetails !== null) {
            this.entitlementValidationMsg = '';
            this.addNewUserData.splice(0, 0, { 'user': userDetails.userName, 'soeid': userDetails.userId, 'role': 'Standard User', 'status': 'Valid' });
            this.addNewUserData.length == 0 ? this.newUserGridFlag = true : this.newUserGridFlag = false;
            this.addNewUserGridApi.setRowData(this.addNewUserData);
            this.addNewUserGridApi.setFocusedCell(0, "role");
            this.addNewUserGridApi.startEditingCell({
              rowIndex: 0,
              colKey: "ROLE"
            });
          } else if (userDetails == null) {               
            this.userEntitlementFlag = true;
            this.entitlementValidationMsg = 'User Id not found!';
          }
          this.newUserSoeid = '';
        });
      }
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.checkMetaData"+this.keyDownFunction(error.message));
    }
  }
  // add new super admin
  onAddNewDataGridReady(params) {
    this.addNewUserGridApi = params.api;
    this.addNewUserGridColumnApi = params.columnApi; 
  }
  onAddSelectionChanged() {
   try{
    this.selectedSuperAdmin = this.addNewUserGridApi.getSelectedRows();
    this.entitleSelUser = this.selectedSuperAdmin.length;
    if (this.entitleSelUser == 0) {
      this.entitleDelAndGropFlag = true;
    } else {
      this.entitleDelAndGropFlag = false;
    }
   }catch(error){
    console.log("Exceptin has occured in SuperAdminSetupComponent.onAddSelectionChanged"+this.displayErrorMessage(error.message))
   }
  }
  addNewSuperAdmin()
  {
     try{
      let successStatus;
      let status=true;
      this.showSpinner=true;
      let superAdminLength=this.selectedSuperAdmin.length;
       this.newSuperAdmin=this.orgData.superAdmins;
       let orgAdmin=[];
       let addNewOrgAdmin:Organization=new Organization();
       addNewOrgAdmin.orgName=this.orgData.orgName;
       addNewOrgAdmin.orgManager=this.orgManager;
       // changes regarding a user can be part of multiple org
       let orgName= typeof this.selectedOrganization=="object"?this.selectedOrganization.name:this.selectedOrganization;
       if(this.newSuperAdmin && this.selectedSuperAdmin){
       // this.showSpinner=false;
            let userId=this.selectedSuperAdmin[0].soeid;
            let alreadyExit=this.newSuperAdmin.findIndex(l=>l.soeid==userId);
            console.log("org user status "+alreadyExit);
            if(alreadyExit>-1){
              successStatus=false;
           }else{
            this.newSuperAdmin.push({'user':this.selectedSuperAdmin[0].user,'soeid':this.selectedSuperAdmin[0].soeid,});
            let formData={
              'userId':this.selectedSuperAdmin[0].soeid,
              'orgName':orgName,
              'crtBy':this.currentUser
            }
            this.superAdminSetupSerivce.saveOrgAdminData(formData).subscribe((res)=>{
              console.log('update status'+res);
            })
            successStatus=true;
          // this.orgData.superAdmins=this.newSuperAdmin;
            addNewOrgAdmin.superAdmins=this.newSuperAdmin;
           }
       }else{
          orgAdmin.push({'user':this.selectedSuperAdmin[0].user,'soeid':this.selectedSuperAdmin[0].soeid});
          let formData={
            'userId':this.selectedSuperAdmin[0].soeid,
            'orgName':orgName,
            'crtBy':this.currentUser
          }
          this.superAdminSetupSerivce.saveOrgAdminData(formData).subscribe((res)=>{
            console.log('update status'+res);
          })
       // this.orgData.superAdmins=orgAdmin;
       addNewOrgAdmin.superAdmins=orgAdmin;
        successStatus=true;
       }
      if(successStatus){     
        this.superAdminSetupSerivce.saveOrgData(addNewOrgAdmin).subscribe((data: any[]) => {
        successStatus = data;
        this.superAdminAddMessage="User addded as an organization admin";
        this.superAdminAddSuccess=true;
        this.superAdminAddFail=false;
        setTimeout(() => { this.superAdminAddSuccess = false; this.hideModal() }, 3000);
         });
      }else{
        this.superAdminAddSuccess=false;
        this.superAdminAddFail=true;
        this.superAdminAddMessage="User already addded as an organization admin";
        setTimeout(() => { this.superAdminAddFail = true; this.hideModal() }, 3000);
      }   
     }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.addNewSuperAdmin"+this.displayErrorMessage(error.message))
     }
  }
  onSelectionChanged() {
    try{
      this.deleteSuperAdmin = this.entitleGridApi.getSelectedRows();
    this.entitleSelUser = this.deleteSuperAdmin.length;
    if (this.entitleSelUser == 0) {
      this.entitleDelAndGropFlag = true;
    } else {
      this.entitleDelAndGropFlag = false;
    }
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.onSelectionChanged"+this.displayErrorMessage(error.message));
    }
  }
  deleteOrgAdmin(){
    try{
    let successStatus;
    this.showPopUpDelete = false;
    let users=this.orgData.superAdmins;
    let length=this.deleteSuperAdmin.length;
    let deletedOrgAdmin:Organization=new Organization();
    deletedOrgAdmin.orgName=this.orgData.orgName;
    deletedOrgAdmin.orgManager=this.orgData.orgManager;
     if(length>0){
       for(let i=0;i<length;i++){
        let userId=this.deleteSuperAdmin[i].soeid;
        let alreadyExit=users.findIndex(i=>i.soeid==userId);
        if(alreadyExit!=-1){
          users.splice(alreadyExit,1);
        }
        let formData={
        'userId':userId,
        'crtBy':this.currentUser,
        'orgName':this.orgData.orgName
        }
        this.superAdminSetupSerivce.deleteOrgAdminData(formData).subscribe((res)=>{
          console.log('update status'+res);
        })
      }
      //this.orgData.superAdmins=users;
      deletedOrgAdmin.superAdmins=users;
        this.superAdminSetupSerivce.saveOrgData(deletedOrgAdmin).subscribe((data: any[]) => {
          successStatus = data;
          if(successStatus){
            this.orgAdminDeleteMessage='Organization admin deleted sucessfully';
            this.orgAdminDeleteSuccess=true;
            this.orgAdminDeleteFail=false;
            setTimeout(() => { this.orgAdminDeleteFail = false; this.hideModal() }, 3000);
          }else{
            this.orgAdminDeleteMessage='Error occured while deleting organization admin';
            this.orgAdminDeleteSuccess=false;
            this.orgAdminDeleteFail=true;
            setTimeout(() => { this.orgAdminDeleteFail = true; this.hideModal() }, 3000);
          }
        }); 
     }  
    }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.deleteOrgAdmin"+this.displayErrorMessage(error.message));
    }    
  }

rejectRequest(){
  try{
  let selectedOrgAuditTrailData;
  let rejectedRequestStatus;
  let orgName= typeof this.selectedOrganization=="object"?this.selectedOrganization.name:this.selectedOrganization;
  if(this.selectedRow.length>0){
    if(this.orgComment){
      let fromData={
        'id':this.selectedAuditTrailId,
        'orgName':orgName
      }
      this.requestApprovedValidationFlag=false;
      this.showSpinner=true;
      this.superAdminSetupSerivce.getRequestById(fromData).subscribe((data) => {
        selectedOrgAuditTrailData = data;
        if(selectedOrgAuditTrailData){
          selectedOrgAuditTrailData.workflowStatus="rejected"
          selectedOrgAuditTrailData.comment=this.orgComment;
          this.superAdminSetupSerivce.saveOrgUnApprovedRequest(selectedOrgAuditTrailData).subscribe((data) => {
            rejectedRequestStatus = data;
            this.showSpinner=false;
            this.requestRejectedMsg="Request is rejected";
            this.reqeustRejectedFlag=true;
            setTimeout(() => { this.reqeustRejectedFlag = false; this.hideModal() }, 3000);
          },error=>{
             console.log('error occured during audit trail update');
          });
        }
        });
    }else{
      this.requestApprovedValidationMsg="Please provide a comment";
      this.requestApprovedValidationFlag=true;
    }
  }else{
    this.requestApprovedValidationMsg="Please select a row to approve/reject";
    this.requestApprovedValidationFlag=true;
   }
  }catch(error){
   console.log("Exceptin has occured in SuperAdminSetupComponent.rejectRequest"+this.displayErrorMessage(error.message));
  }
}
approveRequest(){
  try{
  let orgAuditSaveStatus;
  let orgName= typeof this.selectedOrganization=="object"?this.selectedOrganization.name:this.selectedOrganization;
   if(this.selectedRow.length>0){
    let selectedOrgAuditTrailData;
    let fromData={
      'id':this.selectedAuditTrailId,
      'orgName':orgName
      }
        this.showSpinner = true;
        this.superAdminSetupSerivce.getRequestById(fromData).subscribe((data) => {
        selectedOrgAuditTrailData = data;
        if(selectedOrgAuditTrailData){
          this.showSpinner=false;
          if(this.orgData){
             this.orgData.requestTypes=selectedOrgAuditTrailData.requestTypes;
             this.orgData.highLevelRequestTypeMapping=selectedOrgAuditTrailData.highLevelRequestTypeMapping;
             this.orgData.rootCause=selectedOrgAuditTrailData.rootCause;
             this.orgData.requestTypeRootCauseMapping=selectedOrgAuditTrailData.requestTypeRootCauseMapping;
             this.orgData.modBy=selectedOrgAuditTrailData.crtBy;
             this.orgData.workflowRemarks=this.orgComment;
            if(selectedOrgAuditTrailData.preferences===null||selectedOrgAuditTrailData.preferences.enableGroupLevelOverride===null){
              console.log("org preferences is null");
            }else if(selectedOrgAuditTrailData.preferences && selectedOrgAuditTrailData.preferences.enableGroupLevelOverride && (selectedOrgAuditTrailData.preferences!==null||selectedOrgAuditTrailData.preferences.enableGroupLevelOverride!==null)){
              this.orgData.enableGroupLevelOverride=selectedOrgAuditTrailData.preferences.enableGroupLevelOverride;
             }

             if(selectedOrgAuditTrailData.preferences===null||selectedOrgAuditTrailData.preferences.enableInquirySubStatus===null){
              console.log("org preferences is null");
             }else if(selectedOrgAuditTrailData.preferences && selectedOrgAuditTrailData.preferences.enableInquirySubStatus){
              this.orgData.orgLevelInquirySubStatusEnabled = selectedOrgAuditTrailData.preferences.enableInquirySubStatus;
            }
             
             this.orgData.enableOrgLevelMetaData=selectedOrgAuditTrailData.enableOrgLevelMetaData;
            this.saveOrgData(this.orgData);
        }else{
          let orgData=new Organization();
            orgData.orgName=orgName;
            orgData.orgManager=this.orgManager;
            orgData.superAdmins=this.superAdmin;
            orgData.requestTypes=selectedOrgAuditTrailData.requestTypes;
            orgData.highLevelRequestTypeMapping=selectedOrgAuditTrailData.highLevelRequestTypeMapping;
            orgData.rootCause=selectedOrgAuditTrailData.rootCause;
            orgData.requestTypeRootCauseMapping=selectedOrgAuditTrailData.requestTypeRootCauseMapping;
            if(selectedOrgAuditTrailData.preferences===null||selectedOrgAuditTrailData.preferences.enableGroupLevelOverride===null){
              console.log("org preferences is null");
            }else if(selectedOrgAuditTrailData.preferences && selectedOrgAuditTrailData.preferences.enableGroupLevelOverride && (selectedOrgAuditTrailData.preferences!==null||selectedOrgAuditTrailData.preferences.enableGroupLevelOverride!==null)){
              this.orgData.enableGroupLevelOverride=selectedOrgAuditTrailData.preferences.enableGroupLevelOverride;
             }
             if(selectedOrgAuditTrailData.preferences===null||selectedOrgAuditTrailData.preferences.enableInquirySubStatus===null){
              console.log("org preferences is null");
             }else if(selectedOrgAuditTrailData.preferences && selectedOrgAuditTrailData.preferences.enableInquirySubStatus){
              this.orgData.orgLevelInquirySubStatusEnabled = selectedOrgAuditTrailData.preferences.enableInquirySubStatus;
            }
            orgData.enableOrgLevelMetaData=selectedOrgAuditTrailData.enableOrgLevelMetaData;
            orgData.crtBy=selectedOrgAuditTrailData.crtBy;
            this.saveOrgData(orgData);
      }
      selectedOrgAuditTrailData.workflowStatus="Approved";
      selectedOrgAuditTrailData.comment=this.orgComment;
    } 
    this.requestApprovedValidationFlag=false;
    this.superAdminSetupSerivce.saveOrgUnApprovedRequest(selectedOrgAuditTrailData).subscribe((data) => {
      orgAuditSaveStatus = data;
    },error=>{
       console.log('error occured during audit trail update');
    });
  });
   }else{
    this.requestApprovedValidationMsg="Please select a row to approve/reject";
    this.requestApprovedValidationFlag=true;
   }
  }
  catch(error){
    console.log("Exceptin has occured in SuperAdminSetupComponent.approveRequest"+this.displayErrorMessage(error.message));
  }
}    
   checkMetaData(orgMetaData,orgAuditTrailMetaData)
   {
     try{
      if(JSON.stringify(orgMetaData)==JSON.stringify(orgAuditTrailMetaData)){
        return true;
      }else{   
        return false;
      }
     }catch(error){
       console.log("Exceptin has occured in SuperAdminSetupComponent.checkMetaData"+this.displayErrorMessage(error.message))
     }
   }
   displayErrorMessage(str){
      return str;
   }
   saveOrgData(orgData){
    let orgSaveStatus;
    try{
      this.superAdminSetupSerivce.saveOrgData(orgData).subscribe((data) => {
        orgSaveStatus = data;  
        this.showSpinner = false;
        this.approvedRequestSuccessMsg='Request successfully approved'
        this.approvedRequestSuccessFlag=true;
        setTimeout(() => { this.approvedRequestSuccessFlag = false; this.hideModal() }, 3000);
      },
        error => {
          this.showSpinner = false;
          this.approvedRequestSuccessMsg='Error has been occured during approved'
          this.apporvedRequestFailureFlag=true;
          setTimeout(() => { this.apporvedRequestFailureFlag = false; this.hideModal() }, 3000);
        });
    }catch(error){
      console.log("error has occurred in saveOrgData"+error.message);
    }
   }
   loadRequestData(formData){
     let requestData;   
      try{
        this.superAdminSetupSerivce.getRequestById(formData).subscribe((data: any[])=>{
          requestData=data;
          if(requestData)
          {
            if(requestData.highLevelRequestTypeMapping){
              requestData.highLevelRequestTypeMapping.forEach(element=>{
                if(element.statusIndicator!="NA"){
                  let data={
                    'requestType':element.requestType,
                    'highLevelRequestType':element.highlevelRequestType,
                    'statusIndicator':element.statusIndicator
                 }
                  this.requestsHighLevelRequestType.push(data);
                 }
              })
              // checking the size of high level reqeust type to display reqeust in grid
            this.requestsHighLevelRequestTypeMapping=this.requestsHighLevelRequestType.length>0?true:false;
            this.requestsHighLevelRequestTypeRowData=this.requestsHighLevelRequestType;
            this.requestsHighLevelRequestType=[];            
          }else{
              this.requestsHighLevelRequestTypeMapping=false;
          }
            if(requestData.rootCause){
              requestData.rootCause.forEach(element=>{
                if(element.statusIndicator!="NA"){
                  this.requestsRootCause.push({'rootCause':element.rootCause,'statusIndicator':element.statusIndicator});
                }
              })              
              this.requestsRootCauseRowData=this.requestsRootCause;
              // checking the size of requestRootCause to display reqeust in grid
              this.requestsRootCauseDisplay=this.requestsRootCause.length>0?true:false;
              this.requestsRootCause=[];
            }else{
              this.requestsRootCauseDisplay=false;
            }
            if(requestData.preferences){
              let request=requestData.preferences.enableGroupLevelOverride;
              if(request){ 
                if(this.requestDisableGroupLevelMetaData.length<1){ 
                this.requestDisableGroupLevelMetaData.push({'disableGroupLeveMetaData':requestData.preferences.enableGroupLevelOverride});
                this.requestDisableGroupLevelRowMetaData=this.requestDisableGroupLevelMetaData;
                this.requestsEnableGroupLevelMetaDataDisplay=true;
                }else{
                  this.requestDisableGroupLevelMetaData=[];
                  this.requestDisableGroupLevelMetaData.push({'disableGroupLeveMetaData':requestData.preferences.enableGroupLevelOverride});
                  this.requestDisableGroupLevelRowMetaData=this.requestDisableGroupLevelMetaData;
                  
                  this.requestsEnableGroupLevelMetaDataDisplay=true; 
                }
              }else{
                this.requestDisableGroupLevelMetaData=[];
                this.requestsEnableGroupLevelMetaDataDisplay=false;
              }
            }else{
              this.requestDisableGroupLevelMetaData=[];
              this.requestsEnableGroupLevelMetaDataDisplay=false;
            }
            //dcc
            if(requestData.preferences && this.selectedRow[0].details === 'orgLevelInquirySubStatusEnabled'){ //&& this.selectedRow[0].details === 'orgLevelInquirySubStatusEnabled'
              let request=requestData.preferences.enableInquirySubStatus;
              if(request){
                if(this.enableIngSubStatusOrgLevelMetaData.length<1){ 
                this.enableIngSubStatusOrgLevelMetaData.push({'orgLevelInquirySubStatusEnabled':requestData.preferences.enableInquirySubStatus});
                this.enableIngSubStatusOrgLevelRowMetaData=this.enableIngSubStatusOrgLevelMetaData;
                this.enableIngSubStatusOrgLevelMetaDataDisplay=true;
                }else{
                  this.enableIngSubStatusOrgLevelMetaData=[];
                  this.enableIngSubStatusOrgLevelMetaData.push({'orgLevelInquirySubStatusEnabled':requestData.preferences.enableInquirySubStatus});
                  this.enableIngSubStatusOrgLevelRowMetaData=this.enableIngSubStatusOrgLevelMetaData;
                  
                  this.enableIngSubStatusOrgLevelMetaDataDisplay=true; 
                }
              }else{
                this.enableIngSubStatusOrgLevelMetaData=[];
                this.enableIngSubStatusOrgLevelMetaDataDisplay=false;
              }
            }else{
              this.enableIngSubStatusOrgLevelMetaData=[];
              this.enableIngSubStatusOrgLevelMetaDataDisplay=false;
            }
          if(requestData.requestTypeRootCauseMapping){       
            requestData.requestTypeRootCauseMapping.forEach((element)=>{
               if(element.statusIndicator!="NA")
                {
                  this.requestsRequestTypeRootCauseMapping.push({'requestType':element.requestType,'rootCause':element.rootCause,'statusIndicator':element.statusIndicator});
                }
            })
            // check the length of request type root cause mapping to display request in request tab
            this.requestsRequestTypeRootCauseDisplay=this.requestsRequestTypeRootCauseMapping.length>0?true:false;
            this.requestsRequestTypeRootCauseRowData=this.requestsRequestTypeRootCauseMapping;
           this.requestsRequestTypeRootCauseMapping=[];
          }else{
            this.requestsRequestTypeRootCauseDisplay=false;
          }
          if(requestData.enableOrgLevelMetaData){ 
            this.requestEnableOrgLevelMetaData.push({'enableOrgLevelMetaData':requestData.enableOrgLevelMetaData});
            this.enableOrgLevelMetaDataRowData=this.requestEnableOrgLevelMetaData;
            this.requestOrgLevelMetaDataDisplay=true;
            this.requestEnableOrgLevelMetaData=[];
          }else{
            this.requestOrgLevelMetaDataDisplay=false;
            this.requestEnableOrgLevelMetaData=[];
          }
        }else{
          this.requestsHighLevelRequestTypeMapping=false;
          this.requestsRootCauseDisplay=false;
          this.requestsEnableGroupLevelMetaDataDisplay=false;
          this.requestsRequestTypeRootCauseDisplay=false;
          this.requestOrgLevelMetaDataDisplay=false;
          this.enableIngSubStatusOrgLevelMetaDataDisplay=false;
        }
        });
      }catch(message){
        console.log("exeption has been occured in loadRequestData"+message.error);
      }
   }
   enableMetaDataOverride() {
    if(!this.groupLevelMetaOverride){
      this.groupLevelMetaOverride=false;
    } else{
      this.groupLevelMetaOverride=true;
    }
    }

    enableInquirySubStatusOverride(){
      if(!this.orgLevelInquirySubStatusEnabled){
        this.orgLevelInquirySubStatusEnabled=false;
      }else{
        this.orgLevelInquirySubStatusEnabled=true;
      }
    }
   disableGroupLevelMetaData(){
    try{
      let orgAuditTrailData:OrganizationAuditTrail=new OrganizationAuditTrail();
      let orgName= typeof this.selectedOrganization=="object"?this.selectedOrganization.name:this.selectedOrganization;
    orgAuditTrailData.orgName=orgName;
    if(this.orgsuperAdmins.length>0){
      this.orgsuperAdmins.forEach(element=>{
        if(element.soeid!=this.currentUser){
          this.orgMetaDataChecker.push(element);
        }
      });
    }else{
      this.orgMetaDataChecker.push(this.orgManager);
    }
    let enableGroupLevelMetaDataStatus=Number(this.groupLevelMetaOverride)-Number(this.backupDisableGroupMetaData);
    if(enableGroupLevelMetaDataStatus!=0){
      orgAuditTrailData.enableGroupLevelOverride=this.groupLevelMetaOverride;
    }
    let disableOrgLevelMetaDataStatus=Number(this.enableOrgMetadata)-Number(this.backupEnableOrgMetaData);
    if(disableOrgLevelMetaDataStatus!=0){
      orgAuditTrailData.enableOrgLevelMetaData=this.enableOrgMetadata;
    }
    orgAuditTrailData.checker=this.orgMetaDataChecker;
    orgAuditTrailData.orgLevelInquirySubStatusEnabled = this.orgLevelInquirySubStatusEnabled;
    orgAuditTrailData.crtBy=this.currentUser;
    orgAuditTrailData.maker=this.currentUser;
    orgAuditTrailData.comment='new request';
    orgAuditTrailData.workflowStatus='Pending for approval';  
    this.showSpinner = true;
    this.superAdminSetupSerivce.saveOrgUnApprovedRequest(orgAuditTrailData).subscribe(result => {
     if(result){
      this.showSpinner = false;
      this.orgAuditTrailSaveStatusSuccess=true;
      setTimeout(() => { this.orgAuditTrailSaveStatusSuccess = false; this.hideModal() }, 3000);
     }else{
      this.showSpinner = false;
      this.alreadyRaisedRequestMessage="Request already raised pending for approval";
      this.alreadyRaisedRequestFlag=true;
      setTimeout(() => { this.alreadyRaisedRequestFlag = false, this.hideModal()}, 3000);
     }
    },
      error => {
        this.showSpinner = false;
        this.orgAuditTrailSaveStatusFail=true;
        setTimeout(() => { this.orgAuditTrailSaveStatusFail = false;}, 3000);
        console.error('Error while saving org audit trail  details in SaveOrgAuditTrailData()');
      });
    }catch(error){
      console.log("Exception has been occured in disableGroupLevelMetaData()"+error.message);
    }
   }
   resetDisableGroupEdit(){
    this.groupLevelMetaOverride=this.backupDisableGroupMetaData;
    this.enableOrgMetadata=this.backupEnableOrgMetaData;
   }
   getAlreadyRaisedRequest(formData){
     let data;
     try{
      this.superAdminSetupSerivce.getAlreadyRaisedRequest(formData).subscribe(result=>{
        data=result;
        this.alreadyRaisedRequestRequestType=data.requestTypes;
        this.alreadyRaisedRequestRootCause=data.rootCause;
        this.alreadyRaisedRequestTypeRootCauseMapping=data.requestTypeRootCauseMapping;
        if(this.alreadyRaisedRequestTypeRootCauseMapping && this.metaDataType=="reqTypeCauseMapping"){
          this.alreadyRaisedRequestTypeRootCauseMappingStatus=this.alreadyRaisedRequestTypeRootCauseMapping;
        }else {
          this.alreadyRaisedRequestTypeRootCauseMappingStatus=false;
        }
       });
     }catch(error){
        console.log("error has been occured in getAlreadyRaisedRequest()"+error.message);
     }
   }
   getHighLevelRequestTypeBackup(requestTypeMapping){
     this.backupOrgRequestType=[];
    if(requestTypeMapping){
      requestTypeMapping.forEach(req=>{  
        this.backupOrgRequestType.push({'requestType':req.requestType,'highlevelRequestType':req.highlevelRequestType,'requestID':req.requestType});
        });
    }
   }
   getRootCauseBackup(backupRootCause){
    this.backupOrgRootCause=[];
   if(backupRootCause){
    backupRootCause.forEach(rootCause=>{
      this.backupOrgRootCause.push({ 'rootCause': rootCause, 'rootID': rootCause });
     })
   }
  }
  getRaisedRequestTypeRootCauseMapping(current,backup){
    this.orgAuditRequestTypeRootCauseMapping=new Map();
    let currentMap=new Map(Object.entries(current));
    let backupMap=new Map(Object.entries(backup));
    if(currentMap.size!=0&& backup.size!=0){
      currentMap.forEach((value,key)=>{
        let  requestTypeRootCauseValue=backupMap.get(key);
        if(requestTypeRootCauseValue){
          if(JSON.stringify(value)==JSON.stringify(requestTypeRootCauseValue))
          {
            this.orgAuditTrailRequestTypeRootCauseMapping.push({'requestType':key,'rootCause':value,'statusIndicator':'NA'});
          }else{
            this.orgAuditTrailRequestTypeRootCauseMapping.push({'requestType':key,'rootCause':value,'statusIndicator':'U'});
          }
        }else{
          this.orgAuditTrailRequestTypeRootCauseMapping.push({'requestType':key,'rootCause':value,'statusIndicator':'A'});
        }
      })
      backupMap.forEach((value,key)=>{
        let deletedRequestTypeRootCause=currentMap.get(key);
        if(deletedRequestTypeRootCause){}else {
          this.orgAuditTrailRequestTypeRootCauseMapping.push({'requestType':key,'rootCause':value,'statusIndicator':'D'});
        }
      })
    }else if(backupMap.size!=0 && currentMap.size==0){
      this.orgAuditTrailRequestTypeRootCauseMapping=[];
    }else {
       currentMap.forEach((value,key)=>{
       this.orgAuditTrailRequestTypeRootCauseMapping.push({'requestType':key,'rootCause':value,'statusIndicator':'A'});
      })
    }
  }
  getRaisedRequestType(currentRequestType,backupRequestType){
    function comparer(otherArray:any){
      return function(current:any){
        return otherArray.filter(function(other:any){
          return other.requestType == current.requestType && other.highlevelRequestType == current.highlevelRequestType
          && other.requestId == current.requestId   }).length == 0;
      }
    }
    var onlyInCurrent = currentRequestType.filter(comparer(backupRequestType));
    var onlyInBackup = backupRequestType.filter(comparer(currentRequestType));
    onlyInCurrent.forEach(element=>{
        let requestId=element.requestID;
        let index=onlyInBackup.findIndex(element=>element.requestID==requestId);
        if(index>-1){
          onlyInBackup.splice(index,1);  
          this.orgAuditRequestType.push(element.requestType); 
          this.orgAuditHighLevelRequestType.push({'requestType':element.requestType,'highlevelRequestType':element.highlevelRequestType}); 
          this.orgAuditTrailRequestTypes.push({'requestType':element.requestType,'statusIndicator':'U'});
          this.orgAuditTrailHighLRequestTypes.push({'requestType':element.requestType,'highlevelRequestType':element.highlevelRequestType,'statusIndicator':'U'}); 
        } else{
          this.orgAuditRequestType.push(element.requestType);
          this.orgAuditHighLevelRequestType.push({'requestType':element.requestType,'highlevelRequestType':element.highlevelRequestType}); 
          this.orgAuditTrailRequestTypes.push({'requestType':element.requestType,'statusIndicator':'A'}); 
          this.orgAuditTrailHighLRequestTypes.push({'requestType':element.requestType,'highlevelRequestType':element.highlevelRequestType,'statusIndicator':'A'});
        }  
    })
    // common element is
    let  requestTypeNotChange = currentRequestType.filter(o => backupRequestType.some(({requestType,highlevelRequestType,requestId}) => o.requestType === requestType && o.highlevelRequestType === highlevelRequestType &&
                         o.requestId==requestId));
    requestTypeNotChange.forEach(element=>{
      this.orgAuditRequestType.push(element.requestType);
      this.orgAuditHighLevelRequestType.push({'requestType':element.requestType,'highlevelRequestType':element.highlevelRequestType});
      this.orgAuditTrailRequestTypes.push({'requestType':element.requestType,'statusIndicator':'NA'});  
      this.orgAuditTrailHighLRequestTypes.push({'requestType':element.requestType,'highlevelRequestType':element.highlevelRequestType,'statusIndicator':'NA'});
    })
    // for deleted request type
    onlyInBackup.forEach(element=>{
      this.orgAuditRequestType.push(element.requestType);
      this.orgAuditHighLevelRequestType.push({'requestType':element.requestType,'highlevelRequestType':element.highlevelRequestType}); 
      this.orgAuditTrailRequestTypes.push({'requestType':element.requestType,'statusIndicator':'D'}); 
      this.orgAuditTrailHighLRequestTypes.push({'requestType':element.requestType,'highlevelRequestType':element.highlevelRequestType,'statusIndicator':'D'});
    })
  }
  getRaisedRootCause(currentRootCause,backupRootCause){
    function comparer(otherArray:any){
      return function(current:any){
        return otherArray.filter(function(other:any){
          return other.rootCause == current.rootCause && other.rootID == current.rootID}).length == 0;
      }
    }
    var onlyInCurrentRootCause = currentRootCause.filter(comparer(backupRootCause));
    var onlyInBackupRootCause = backupRootCause.filter(comparer(currentRootCause));
    onlyInCurrentRootCause.forEach(element=>{
        let rootID=element.rootID;
        let index=onlyInBackupRootCause.findIndex(element=>element.rootID==rootID);
        if(index>-1){
          onlyInBackupRootCause.splice(index,1);  
          this.orgAuditRootCause.push(element.rootCause);  
          this.orgAuditTrailRootCause.push({'rootCause':element.rootCause,'statusIndicator':'U'}); 
        } else{
          this.orgAuditRootCause.push(element.rootCause);  
          this.orgAuditTrailRootCause.push({'rootCause':element.rootCause,'statusIndicator':'A'});
        }  
    })
    // common element is
    let  rootCauseNotMadeAnyChange = currentRootCause.filter(o => backupRootCause.some(({rootCause,rootID}) => o.rootCause === rootCause && o.rootID === rootID));
    rootCauseNotMadeAnyChange.forEach(element=>{
      this.orgAuditRootCause.push(element.rootCause);  
      this.orgAuditTrailRootCause.push({'rootCause':element.rootCause,'statusIndicator':'NA'});
    })
    onlyInBackupRootCause.forEach(element=>{
      this.orgAuditRootCause.push(element.rootCause); 
     this.orgAuditTrailRootCause.push({'rootCause':element.rootCause,'statusIndicator':'D'});
    })
  }
 // for display pop up in request tab
 showRequestTabPanel(event, overlaypanel: OverlayPanel): void {
   overlaypanel.toggle(event);
  }
  disableOrgLevelMetaData(){
    if(this.enableOrgMetadata){
      this.enableOrgMetadata=true;
    }else{
      this.enableOrgMetadata=false;
    }
  }
  orgChangesForInit(){
    this.selectedRequest='my request';
    let data={
      "maker":this.currentUser,
      "orgName":this.selectedOrganization.name,
      "requestType":this.selectedRequest
    }
    this.requestsHighLevelRequestTypeRowData=[];
    this.requestsRootCauseRowData=[];
    this.requestDisableGroupLevelMetaData=[];
    this.enableOrgLevelMetaDataRowData=[];
    this.requestsRequestTypeRootCauseRowData=[];
    this.enableIngSubStatusOrgLevelMetaData=[];
    this.getOrgData();
    this.populateOrgGroupData();
    this.getRequests(data);
    let formData={
      "orgName":this.selectedOrganization.name
    }
    this.getAlreadyRaisedRequest(formData);
    this.loadColumnDef();
    this.loadRequestDropDownData();
  }
  loadColumnDef(){
    this.metadataTypeList = [
      { label: 'Request Types', value: 'RequestTypes' }, 
      { label: 'Root Cause', value: 'Root Cause' },
      { label: 'Request Type/ Root Cause Mapping', value: 'reqTypeCauseMapping' }
    ];
    this.selectedRequestList=[
      { label: 'My Request', value: 'my request' }, 
      { label: 'Request waiting for my approval', value: 'request for approval' }, 
      { label: 'All Requests', value: 'All' }
    ];
    // this.organizationList=[
    //     {label:this.selectedOrganization, value:this.selectedOrganization}
    // ];
 
    this.superAdminColumns = [
      {
        field: "user",
        headerName: 'USER',
        width: 185,
        editable: false,
          cellStyle: { 'width': '185x !important' }
      },
      {
        field: "soeid",
        headerName: 'SOEID',
        width: 120,
        editable: false
      }
    ];
    this.entitleColDef = {
      width: 100,
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
      resizable: true,
      editable: false,
      cellStyle: { 'background-color': 'white' }
    };
    this.rowSelection = "single";
     this.addNewUserColumns = [
       {
         field: "user",
         headerName: 'USER',
         width: 150,
         editable: false,
       },
       {
         field: "soeid",
         headerName: 'SOEID',
         width: 100,
         editable: false
       },
     ];
     this.domainCheckbox = {
       width: 100,
       checkboxSelection: this.isFirstColumn,
       resizable: true,
       suppressMenu: true,
       suppressMovable: true,
       editable: true,
       cellStyle: { 'background-color': 'white' }
     };
  }
  loadRequestDropDownData(){
    try{
      this.superAdminSetupSerivce.currentMessage.subscribe((data)=>{
        this.viewDetailRequest=data;
        this.role=this.viewDetailRequest.role;
        this.orgName=this.viewDetailRequest.orgName;
        this.orgId=this.viewDetailRequest.orgId;
        let requestData;
        if(this.role){
          let st=JSON.stringify(this.role);
          let formData={
            'id':this.orgId,
            'orgName':this.orgName
           }
          this.superAdminSetupSerivce.getRequestById(formData).subscribe((data: any[])=>{
            requestData=data;
            if(requestData)
            {
              if(requestData.rootCause && st.indexOf("rootCause")>-1){
                requestData.rootCause.forEach(element=>{
                  if(element.statusIndicator!="NA"){
                    this.requestsRootCause.push({'rootCause':element.rootCause,'statusIndicator':element.statusIndicator});
                   }
                });
                this.requestsRootCauseDisplay=true;
                this.requestsRootCauseRowData=this.requestsRootCause;
                this.requestsRootCause=[];
              }else{
                this.requestsRootCauseDisplay=false;
              }
              if(requestData.preferences===null||requestData.preferences.enableGroupLevelOverride===null){
                this.requestsEnableGroupLevelMetaDataDisplay=false;
              }else if(requestData.preferences && requestData.preferences.enableGroupLevelOverride && (requestData.preferences!==null||requestData.preferences.enableGroupLevelOverride!==null)){
                if(st.indexOf("disableGroupLeveMetaData")>-1){
                  this.requestDisableGroupLevelMetaData.push({'disableGroupLeveMetaData':requestData.preferences.enableGroupLevelOverride});
                  this.requestDisableGroupLevelRowMetaData=this.requestDisableGroupLevelMetaData;
                  this.requestsEnableGroupLevelMetaDataDisplay=true;
                  this.requestDisableGroupLevelMetaData=[];
                }else{
                  this.requestsEnableGroupLevelMetaDataDisplay=false;
                }   
               }else {
                this.requestsEnableGroupLevelMetaDataDisplay=false;
               }
              if(requestData.highLevelRequestTypeMapping && st.indexOf("highLevelRequestTypeMapping")>-1){
                 requestData.highLevelRequestTypeMapping.forEach(element=>{
                  if(element.statusIndicator!="NA"){
                    let data={
                      'requestType':element.requestType,
                      'highLevelRequestType':element.highlevelRequestType,
                      'statusIndicator':element.statusIndicator
                   }
                    this.requestsHighLevelRequestType.push(data);
                  }
                 }) 
                this.requestsHighLevelRequestTypeMapping=true;
                this.requestsHighLevelRequestTypeRowData=this.requestsHighLevelRequestType;
                this.requestsHighLevelRequestType=[];            
              }else{
              this.requestsHighLevelRequestTypeMapping=false;
            }
            if(requestData.requestTypeRootCauseMapping && st.indexOf("requestTypeRootCauseMapping")>-1){       
              requestData.requestTypeRootCauseMapping.forEach(element=>{
                if(element.statusIndicator!="NA")
                {
                  this.requestsRequestTypeRootCauseMapping.push({'requestType':element.requestType,'rootCause':element.rootCause,'statusIndicator':element.statusIndicator});
                }
              })
              this.requestsRequestTypeRootCauseDisplay=true;
              this.requestsRequestTypeRootCauseRowData=this.requestsRequestTypeRootCauseMapping;
              this.requestsRequestTypeRootCauseMapping=[];
            }else{
              this.requestsRequestTypeRootCauseDisplay=false;
            }
            if(requestData.disableOrgLevelMetaData && st.indexOf("enableOrgLevelMetaData")>-1){
              if(requestData.disableOrgLevelMetaData){
                this.requestEnableOrgLevelMetaData.push({'enableOrgLevelMetaData':requestData.disableOrgLevelMetaData});
                this.enableOrgLevelMetaDataRowData=this.requestEnableOrgLevelMetaData;
                this.requestOrgLevelMetaDataDisplay=true;
                this.requestEnableOrgLevelMetaData=[];
              }else{
                this.requestOrgLevelMetaDataDisplay=false;
                this.requestEnableOrgLevelMetaData=[];  
              }
            }else{
              this.requestOrgLevelMetaDataDisplay=false;
            }
            if(requestData.preferences && requestData.preferences.enableInquirySubStatus && (requestData.preferences!==null||requestData.preferences.enableInquirySubStatus!==null)){
              if(st.indexOf("orgLevelInquirySubStatusEnabled")>-1){
                this.enableIngSubStatusOrgLevelMetaData.push({'orgLevelInquirySubStatusEnabled':requestData.preferences.enableInquirySubStatus});
                this.enableIngSubStatusOrgLevelRowMetaData=this.enableIngSubStatusOrgLevelMetaData;
                this.enableIngSubStatusOrgLevelMetaDataDisplay=true;
                this.enableIngSubStatusOrgLevelMetaData=[];
              }else{
                this.enableIngSubStatusOrgLevelMetaDataDisplay=false;
              }   
            }

          }
          });
        }
     });  
     }catch(error){
      console.log("Exceptin has occured in SuperAdminSetupComponent.currentMessage"+this.displayErrorMessage(error.message));
     }
  }
}