import { QmaConstant } from '../../constant/qma-constant';
import { AppUtils } from 'src/app/common/utility/appUtil';
import { InboxService } from 'src/app/services/inbox.service';
import { UserDataService } from "src/app/services/user-data.service";
import { Observable, forkJoin, of } from 'rxjs';
import { GridViewDataModel } from 'src/app/model/GridViewDataModel/GridViewDataModel';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridDataCacheService implements OnDestroy {

  // configuration paramters from QmaConstant
  defaultPageNo;
  defaultPageSize;
  maxPagesInCache;
  public rowData: any = [];
  latestRequestedRowIndex: number;
  // loaded starting page, indexed from 1
  startPage: number;
  // page num latest request sent to backend
  requestPage: number;
  // loaded ending page, indexed from 1
  endPage: number;
  // cached request object
  requestObj: any;
  // C153176-4755: cached request filter
  requestFilters: any = {};
  // maximum fetched records
  maxFetchedRecords: number;
  // C153176-4885:
  fullCacheLoad: boolean = false;
  // C153176-4408: request subscription to be tracked for unsubscribe
  requestSub: any;
  // C153176-4994:
  noMoreData: boolean = false;
  // C170665-1005 Introduce control over scrolling the the Grid
  // default to true for initial api call
  isGetGridViewDataAPICallComplete = true;
  constructor(private inboxService: InboxService, private viewComponent, private userDataService: UserDataService) { // C153176-5144
    this.defaultPageNo = QmaConstant.inboxGridViewType.defaultPageNo;
    this.defaultPageSize = QmaConstant.inboxGridViewType.defaultPageSize;
    // C153176-5144: fetch page size per loggedInUserInfo
    this.setPageSize(this.userDataService.loggedInUserInfo, this.viewComponent.isMobile);
    this.maxPagesInCache = QmaConstant.inboxGridViewType.maxPagesInCache;
    this.resetParams();
  }

  resetParams(keepRowData = false) { // C153176-5076
    this.latestRequestedRowIndex = -1;
    this.startPage = -1;
    this.requestPage = -1;
    this.endPage = -1;
    this.maxFetchedRecords = 0; // C153176-4700: fix initial value
    if (!keepRowData) {
      if (this.rowData && this.rowData.length) {
        this.rowData.splice(0, this.rowData.length);
      }
    }
  }

  setViewComponent(viewComponent: any) {
    this.viewComponent = viewComponent;
  }

  getPageParams(nextPage = true) {
    let columnUpdateFlag = false;
    if (nextPage) {
      // moving downward
      if (this.requestPage === -1) {
        this.requestPage = this.defaultPageNo;
        if (this.requestPage && typeof this.requestPage === 'string') {
          this.requestPage = parseInt(this.requestPage);
        }
        columnUpdateFlag = true;
      } else {
        this.requestPage++;
        if (this.endPage >= this.requestPage) {
          // C153176-4700: jump to request the following page from endPage
          this.requestPage = this.endPage + 1;
        }
      }
    } else {
      // moving upward
      if (this.requestPage > 1) {
        this.requestPage--;
        if (this.requestPage >= this.startPage) {
          this.requestPage = this.startPage - 1;
        }
        this.requestPage = Math.max(1, this.requestPage);
      } else if (this.requestPage === -1) {
        this.requestPage = this.defaultPageNo;
        if (this.requestPage && typeof this.requestPage === 'string') {
          this.requestPage = parseInt(this.requestPage);
        }
        columnUpdateFlag = true;
      }
    }
    return { pageNum: this.requestPage, pageSize: this.defaultPageSize, columnUpdateFlag: columnUpdateFlag };
  }

  /**
   *  C153176-5076 - return boolean indicating whether the new data is requested
   *  @return true if request was truly sent, otherwise false
   */
  requestPrevPage() {
    if (!this.startPage || this.startPage <= 1) {
      return false;
    }
    let requestIndex = (this.startPage - 1) * this.defaultPageSize + 1;
    if (this.latestRequestedRowIndex === requestIndex && this.latestRequestedRowIndex !== -1) {
      // same request, already made, skip now
      return false;
    }
    this.syncRowData();
    this.latestRequestedRowIndex = requestIndex;
    return this.requestGridData(false); // C153176-5076 
  }

  syncRowData() {
    let myLength = this.rowData ? this.rowData.length : -1;
    let viewLength = this.viewComponent.rowData ? this.viewComponent.rowData.length : -1;
    if (viewLength > myLength) {
      this.maxFetchedRecords += (viewLength - myLength);
    }
    if (myLength !== viewLength) {
      // reference-copy only
      this.rowData = this.viewComponent.rowData;
    }
  }

  /**
   *  C153176-5076 - return boolean indicating whether the new data is requested
   *  @return true if request was truly sent, otherwise false
   */
  requestNextPage(lastRowIndex) {
    if (!lastRowIndex || lastRowIndex < 0) {
      return false;
    }
    // C153176-4994: if noMoreData flag is set, skip fetching next page
    if (this.noMoreData) {
      console.debug("Skipping loading next page due to no more data");
      return false;
    }
    if (this.latestRequestedRowIndex === lastRowIndex && this.latestRequestedRowIndex !== -1) {
      // same request, already made, skip now
      return false;
    }
    this.syncRowData();
    if (((lastRowIndex + 1) < this.rowData.length && this.rowData.length > 0) &&
      (this.endPage > 0 && lastRowIndex < ((this.endPage - this.startPage + 1) * this.defaultPageSize - 3))) {
      return false;
    }
    this.latestRequestedRowIndex = lastRowIndex;
    return this.requestGridData(); // C153176-5076
  }

  /**
   * C153176-4577: get request key
   */
  getRequestKey(requestObj) {
    if (!this.isValidRequest(requestObj)) { // C153176-5011
      return undefined;
    }
    let key = '';
    Object.keys(requestObj).forEach(k => {
      if (k && k !== 'filter' && k !== 'pageNum' && k !== 'pageSize') {
        key += ('' + k + '-' + requestObj[k]);
      }
    });
    return key;
  }

  /**
   * C153176-4755: build and cache request filter
   */
  buildRequestFilter() {
    if (!this.requestObj || !this.requestObj.filter) {
      // no filter, do nothgin
      return;
    }
    const key: string = this.getRequestKey(this.requestObj);
    this.requestFilters[key] = this.requestObj.filter;
  }

  /**
   * C153173-5011: check request object validity
   */
  isValidRequest(req) {
    return req && req.viewName;
  }

  /**
   * C153176-4700: server-side pagination request method
   * @param nextPage
   * @return true if the request is sent, otherwise false // C153176-5076
   */
  requestGridData(nextPage = true, isMobileRefresh = false): boolean {
    let requestObj = this.requestObj;
    if (!this.isValidRequest(requestObj)) { // C153176-5011
      requestObj = this.inboxService.getRequestObj(this.viewComponent.strMailboxView, this.viewComponent.tabNameforChartView);
      // save the request object from inboxService
      this.requestObj = requestObj;
      // C153176-4577: check / cache request filter
      this.buildRequestFilter();
    } else {
      const svcRequestObj = this.inboxService.requestObj;
      if (svcRequestObj && this.inboxService.resetRequest) {
        requestObj = svcRequestObj;
        // save the request object from inboxService
        this.requestObj = requestObj;
        this.resetParams();
        // C153176-4577: check / cache request filter
        this.buildRequestFilter();
        // C153176-4700: reset row data if request was reset
        this.resetRowData();
      }
    }
    if (!this.isValidRequest(requestObj)) { // C153176-5011
      return false; // C153176-5076
    }
    // reset inbox service request flag
    this.inboxService.resetRequest = false;

    let pageParams = this.getPageParams(nextPage);
    // C153176-4895: get request from inboxService.requestObj directly as it was just assigned
    requestObj.pageNum = pageParams.pageNum;
    requestObj.pageSize = pageParams.pageSize;
    // C153176-5011: update view type of the component if request has a different type
    if (this.viewComponent.viewType !== requestObj.viewType && requestObj !== undefined) {
      this.viewComponent.viewType = requestObj.viewType;
    }
    if (this.isPaginationRequired(requestObj)) {
      // C153176-4152, 4577, 4700: if pagination is required, make sure the pagination attrs are set on request
      if (!requestObj.pageNum) {
        requestObj.pageNum = 1;
      }
      requestObj.pageSize = this.getPageSize(requestObj);
    }
    if (!requestObj.pageSize) {
      requestObj.pageSize = pageParams.pageSize;
    }

    requestObj = this.inboxService.cacheOrUpdateRequest(this.viewComponent.strMailboxView,
      this.viewComponent.tabNameForChartView, requestObj);

    if (requestObj && requestObj.filter) {
      delete this.inboxService.requestObj.filter;
    }
    // performance improvement change 
    // if prev req is not yet complete discard subsequent request
    if (this.viewComponent.showSpinner && this.requestObj && this.requestObj.pageNum && this.requestObj.pageNum !== 1) {
      return;
    }
    // do not show viewComponent's spinner if the caller has introduced its from inboxService
    if (this.inboxService.showSpinner) {
      this.viewComponent.showSpinner = false;
      console.debug(":SPINNER:: " + this.viewComponent.showSpinner + " ::grid-data-cache-service.requestGridData");
    } else {
      this.viewComponent.showSpinner = true;
      console.debug(":SPINNER:: " + this.viewComponent.showSpinner + " ::grid-data-cache-service.requestGridData");
    }
    // C153176-5047: alter request object in case a solr search is done at
    let _requestObj = requestObj;
    if (_requestObj.viewName == 'Search' && _requestObj.solrSearchText) {
      if (!this.viewComponent.isMobile && // C153176-5043: for Mobile version, do not alter viewName, since solrSearch for mobile is always on ALL mailboxes
        (this.viewComponent.strMailboxView === 'INBOX' || this.viewComponent.strMailboxView === 'SENT' || this.viewComponent.strMailboxView === 'RESOLVED')) {
        _requestObj = Object.assign({}, requestObj);
        _requestObj.viewName = this.getViewNameFromTabName(this.viewComponent.strMailboxView);
      }
    }
    if (this.requestSub) {
      this.requestSub.unsubscribe();
    }
    // C170665-1005 Introduce control over scrolling the the Grid
    this.isGetGridViewDataAPICallComplete = false;
    this.requestSub = this.getGridViewData(_requestObj).subscribe(gridViewData => {
      // C170665-1005 Introduce control over scrolling the the Grid
      this.isGetGridViewDataAPICallComplete = true;
      let gridViewDataModel = null;
      let defaultData = gridViewData[0];
      if (requestObj.pageNum > 1) {
        gridViewDataModel = { ...defaultData };
      } else {
        let totalCount = gridViewData[1];
        //let unreadCount = gridViewData[2];
        gridViewDataModel = { ...defaultData, ...totalCount };
      }

      let inquiryList = this.viewComponent.retrieveGetGridViewResponse(gridViewDataModel,
        requestObj, pageParams.columnUpdateFlag).inquiryList;

      
      this.populateClientCategory(inquiryList);


      this.populateCitiReplyCount(inquiryList);


      if ((!inquiryList || !inquiryList.length) && nextPage) {
        this.noMoreData = true;
      } else {
        this.noMoreData = false;
      }
      let initialDataSize = this.rowData.length;

      if (this.viewComponent.gridApi) {
        this.updatePageParams(inquiryList, requestObj, nextPage);
        this.addRowData(inquiryList, nextPage, isMobileRefresh);
        this.viewComponent.showSpinner = false;
        console.debug(":SPINNER:: " + this.viewComponent.showSpinner + " ::grid-data-cache-service.requestGridData");
      } else {
        // C153176-4964: if gridApi is not ready, update row data a bit later
        const me = this;
        setTimeout(() => {
          me.updatePageParams(inquiryList, requestObj, nextPage);
          me.addRowData(inquiryList, nextPage);
          me.viewComponent.showSpinner = false;
          console.debug(":SPINNER:: " + this.viewComponent.showSpinner + " ::grid-data-cache-service.requestGridData");
        }, 100);
      }
      // C153176-5113: for dashboard inline view, make sure the first row is selected and conv view shown
      // C153176-5113: if initially data was empty, make the first row selected as this is a new page
      if (!initialDataSize || this.viewComponent.isDashboardInlineView && (_requestObj.pageNum === 1)) {
        this.viewComponent.inboxStateChanged(null);
      }
    }, error => {
      this.inboxService.stopViewLoading();
      // C153176-4577: stop spinner if an error occurred in service request
      this.viewComponent.showSpinner = false;
      console.debug(":SPINNER:: " + this.viewComponent.showSpinner + " ::grid-data-cache-service.requestGridData");
    });
    return true; // C153176-5076
  }

  updatePageParams(newData, request, nextPage) {
    if (!newData || !newData.length || !request) {
      return;
    }
    if (request && request.pageNum && request.pageSize) {
      let pageNum = typeof request.pageNum == 'string' ? parseInt(request.pageNum) : request.pageNum;
      if (this.startPage === -1 || pageNum < this.startPage) {
        this.startPage = Math.max(1, pageNum);
      }
      if (this.endPage === -1 || request.pageNum > this.endPage) {
        this.endPage = pageNum;
      }
      this.pruneRowData(nextPage);
    }
  }

  pruneRowData(nextPage) {
    let pageCount = this.endPage - this.startPage + 1;
    if (pageCount <= this.maxPagesInCache) {
      return;
    }
    console.debug("pruneRowData, before: startPage, endPage=", this.startPage, this.endPage);
    let totalRemovalPages = this.endPage - this.startPage + 1 - this.maxPagesInCache;
    let removalCount = totalRemovalPages * this.defaultPageSize;
    if (removalCount) {
      this.rowData = this.viewComponent.rowData;
      if (this.rowData && removalCount < this.rowData.length) {
        let removedRecords;
        if (nextPage) {
          // prune the top records
          removedRecords = this.rowData.splice(0, removalCount);
        } else {
          // prune the tail records
          removedRecords = this.rowData.splice(this.rowData.length - removalCount, removalCount);
        }
        if (this.viewComponent.gridApi && removedRecords && removedRecords.length === removalCount) {
          // update start/end pages
          this.viewComponent.gridApi.updateRowData({ remove: removedRecords });
          if (nextPage) {
            this.startPage += totalRemovalPages;
            this.startPage = Math.min(this.endPage, this.startPage);
          } else {
            this.endPage -= totalRemovalPages;
            this.endPage = Math.max(this.startPage, this.endPage);
            // C153176-4994: end page trimmed, set 'noMoreData' to false
            this.noMoreData = false;
          }
        }
      }
    }
    console.debug("pruneRowData, after: startPage, endPage=", this.startPage, this.endPage);
  }

  addRowData(rowData: any, nextPage: boolean, isMobileRefresh = false) {
    let update = false;
    if (rowData && this.viewComponent.gridApi) {
      if (nextPage) {
        if (this.rowData.length === 0) {
          this.viewComponent.gridApi.updateRowData({
            // C153176-4926: reversely adding the array to the top
            add: rowData.reverse(),
            addIndex: this.rowData ? this.rowData.length : 0,
          });
        } else {

          if (!isMobileRefresh) {
            this.viewComponent.gridApi.updateRowData({
              add: rowData
              // DO not specify 'addIndex' so to append rowData to the end
            });
          } else {
            this.rowData = rowData;
            update = true;
          }
        }

        if (!isMobileRefresh) {
          this.rowData.push.apply(this.rowData, rowData);
        }
      } else {
        this.viewComponent.gridApi.updateRowData({
          add: rowData.reverse(),
          addIndex: 0,
        });
        this.rowData.unshift.apply(this.rowData, rowData);
      }
      console.debug('addRowData, after, length=', this.rowData.length, 'start, end, request pages=',
        this.startPage, this.endPage, this.requestPage, 'latest requested index =', this.latestRequestedRowIndex);
      this.viewComponent.rowData = [];
      this.viewComponent.rowData = this.rowData;
      // C153176-4700: augment maxFetchedRecords only fetching next page when scrolling down. (Do not augment when fetching a prev page)
      if (nextPage) {
        this.maxFetchedRecords += rowData.length;
      }
      this.viewComponent.updateCountLabels();
    }
    this.viewComponent.notifyMobileDataReady();
    // set grid page
    if (this.latestRequestedRowIndex > 0 && this.latestRequestedRowIndex > this.rowData.length - 10) {
      if (nextPage) {
        this.latestRequestedRowIndex -= this.defaultPageSize;
        this.latestRequestedRowIndex = Math.max(this.defaultPageSize, this.latestRequestedRowIndex);
      }
    }
    console.debug('addRowData, adjusted latest rowIndex =', this.latestRequestedRowIndex);
    if (this.viewComponent.gridApi && this.latestRequestedRowIndex > 0) {
      setTimeout(() => {
        // reassign back from view component
        this.rowData = this.viewComponent.rowData;
        if (!this.rowData) {
          this.rowData = [];
        }
        this.viewComponent.gridApi.ensureIndexVisible(this.latestRequestedRowIndex, 'middle');
      }, 100);
    }
  }

  /**
   * C153176-4152, 4577: check whether pagination is needed
   */
  isPaginationRequired(requestObj) {
    if (!this.isValidRequest(requestObj)) { // C153176-5011
      return true;
    }
    // C153176-5011: myView and search require pagination
    if (typeof requestObj.viewName !== 'string') {
      return false;
    } else {
      return true;
    }
  }

  /**
   * C153176-4152, 4577: return page size
   */
  getPageSize(requestObj) {
    if (this.viewComponent.viewType === -1) {
      return undefined;
    } else {
      // C153176-4885: For all the folders except my views, return default page. This includes searches.
      return this.defaultPageSize;
    }
  }

  /**
   * C153176-4885: check whether the view has its own filter
   */
  hasViewFilter() {
    return this.requestObj && this.requestObj.viewName === 'Search' && this.viewComponent.assignedGroups && this.viewComponent.assignedGroups.length;
  }

  
  getRequestFilter(requestObj) {
    if (!this.isValidRequest(requestObj)) { // C153176-5011
      return undefined;
    }
    const key: string = this.getRequestKey(requestObj);
    const filter = this.requestFilters[key];
    return filter;
  }

  /**
   * C153176-4700: reset row data when request has changed
   */
  resetRowData() {
    this.rowData = [];
    this.viewComponent.rowData = [];
    this.viewComponent.rowData = this.rowData;
    // C153176-4408: reset ag-grid
    if (this.viewComponent.gridApi) {
      this.viewComponent.gridApi.setRowData(this.viewComponent.rowData);
    }
    if (this.requestObj && this.requestObj.isPersonal == "Y") {
      setTimeout(() => {
        this.viewComponent.showSpinner = true;
        console.debug(":SPINNER:: " + this.viewComponent.showSpinner + " ::grid-data-cache-service.resetRowData");
      }, 300);
    }
  }

  /**
   * C153176-5047: get view name from tab name
   */
  getViewNameFromTabName(tabName) {
    if (tabName === 'SENT') {
      return "Outbox";
    } else {
      return tabName.substring(0, 1).toUpperCase() + tabName.substring(1).toLowerCase();
    }
  }

  setPageSize(loginUserInfo, isMobile) {
    if (!loginUserInfo || !loginUserInfo.qmaUI2Config) {
      return;
    }
    if (isMobile && loginUserInfo.qmaUI2Config.noOfRecordsOnPage_device) {
      this.defaultPageSize = loginUserInfo.qmaUI2Config.noOfRecordsOnPage_device;
    } else if (loginUserInfo.qmaUI2Config.noOfRecordsOnPage_web) {
      this.defaultPageSize = loginUserInfo.qmaUI2Config.noOfRecordsOnPage_web;
    }
  }

  /**
  * Method to get the group level inquiry data for view based on the view name in request object.
  * @param requestObj - Request JSON object.
  */
  getGridViewData(requestObj: any): Observable<any> {
    const updatedRequestObj = this.inboxService.updateRequest(requestObj);
    try {
      const default$ = this.inboxService.getGridViewDataDefault(updatedRequestObj);
      if (requestObj.pageNum > 1) {
        return forkJoin([default$]);
      } else {
        const totalCount$ = this.inboxService.getGridViewTotalCount(updatedRequestObj);
        //const unreadCount$ = this.inboxService.getGridViewTotalUnreadCount(updatedRequestObj);
        return forkJoin([default$, totalCount$]);
      }

    } catch (e) {
      console.error('Error while getting response from getGridViewData()', e);
    }
  }

  /**
   * Method to set the client category to the inquiry.
   * 
   * @param inquiryList 
   */
  populateClientCategory(inquiryList) {
    inquiryList.map(inq => {
      let category = this.getClientCategory(inq);
      inq.clntCstmCategory = category ? category.categoryName : "";
    });
  }

  /**
    * C170665-445 : Method to get the client Category with color code and name.
    * @param params 
    */
  getClientCategory(inquiry) {
    // C153176-4566 : Show System Defualt or Custom client category. System client category have precedence over custom client.
    // System Client Category
    let clientCategory = null;
    if (inquiry.workflows) {
      const clntCtgryWf = inquiry.workflows.find(x => x.clientCategory);
      if (clntCtgryWf && clntCtgryWf.clientCategory) {
        clientCategory = clntCtgryWf.clientCategory;
      }
    }

    // Default Client Category
    let customClientCategory = null;
    if (inquiry.workflows) {
      const cstmCtgryWf = inquiry.workflows.find(x => x.customClientCategory);
      if (cstmCtgryWf && cstmCtgryWf.customClientCategory) {
        customClientCategory = cstmCtgryWf.customClientCategory[0];
      }
    }
    let finalClientCategory = clientCategory ? clientCategory : customClientCategory;
    return finalClientCategory;
  }

  /**
   * C170665-85 | Populate the Reply Count from workflow to inquiry. 
   * 
   * @param inquiryList 
   */
  populateCitiReplyCount(inquiryList) {
    inquiryList.map(inq => {
      let replyCountWf = this.getReplyCount(inq);
      inq.citiReplyCountFromQMA = replyCountWf ? replyCountWf.citiReplyCountFromQMA : "";
    });
  }

  /**
   * Method to get the CITI Reply Count.
   * 
   * @param inquiry 
   */
  getReplyCount(inquiry): any {
    let replyCount = null;
    if (inquiry.workflows) {
      const citiReplyCount = inquiry.workflows.find(x => x.citiReplyCountFromQMA);
      if (citiReplyCount) {
        replyCount = citiReplyCount;
      }
    }
    return replyCount;
  }

  ngOnDestroy(): void {
    if (this.requestSub) {
      this.requestSub.unsubscribe();
    }
  }

}