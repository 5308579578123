<div class="search-list">
  <div class="input-group">
    <input type="text" #myInput placeholder='{{placeholder}}' (input)="filterItem(myInput.value)">
    <div class="input-group-btn searchBox">
      <button class="btn btn-default" type="submit">
        <img src="assets/core/layout/header/search_white.svg" style="width:18px;height:18px;" alt="Search">
      </button>
    </div>
  </div>
  <ng-scrollbar class="collapse-scrollbar" 
    [invertY]="true"
    [trackX]="false"
    [compact]="false" 
    [shown]="native"
    [autoUpdate]="true"
    [ngStyle]="listStyle">  
    <div class="panel-group" id="accordion">
      <div *ngFor="let item of filteredItems;let i = index" class="panel mb-1 panel-default">
        <div class="panel-heading">
          <div class="d-flex flex-row justify-content-between flex-unwrap panel-title p-2">
            <a class="accordion-toggle d-flex align-items-center justify-content-between w-100" 
              (click)="toggleItem(item)">
              <div class="pr-2">{{item.name}}</div>
              <span class="pull-right">
                <img src="assets/core/layout/nav/group_tag_chevron.svg" class="tag-chevron" 
                [ngClass]="{'chevron-tranform': item.toggle }" alt="Group">
              </span>
            </a>           
          </div>
        </div>
        <div [hidden]="item.toggle" class="panel-collapse pl-1">
          <div class="panel-body p-2">
              <ul class="list-disp pl-0">
                <li class="mb-1 py-1 d-flex flex-row justify-content-between align-items-center" *ngFor="let child of item.childList | search: 'name': myInput.value">
                  <a (click)="itemSelected(item, child)">{{child.name}}</a><span *ngIf="child.count>0" class="custom-badge pull-right">{{child.count}}</span>
                </li>
              </ul>
          </div>
        </div>
      </div>
    </div>
  </ng-scrollbar>
</div>