import { Component, ElementRef, AfterViewInit, ViewChild, Input, ViewChildren, QueryList, HostListener, OnInit, OnDestroy, EventEmitter, Output, ChangeDetectorRef, Renderer2 } from "@angular/core";
  import { timer as observableTimer, Subscription } from 'rxjs';
  import { DatePipe } from '@angular/common';
  import { MailBoxGridColdefs } from "../inbox/inboxView/inboxGridColumnDefs"
  
  import { AllModules } from "@ag-grid-enterprise/all-modules";
  import { GridOptions, Module } from "@ag-grid-community/core";
  import { DetailCellRenderer } from "../inbox/inboxView/detail-cell-renderer.component";
  import { InboxService } from '../services/inbox.service';
  import { UserDataService } from "src/app/services/user-data.service";
  import { QmaConstant } from '../constant/qma-constant';
  import { ContextMenu } from 'src/app/model/Common/ContextMenu';
  import { ContextMenuService } from "../services/contextMenu/context-menu.service";
  import { GridMenuItems } from "src/app/common/utility/gridMenuItems";
  import { ColumnDefsMobile } from 'src/app/inbox/inboxView/mailbox-mobile-view/mailbox-mobile-gridcoldefs'
  import { AppUtils } from 'src/app/common/utility/appUtil';
  import { InboxViewHelper } from '../inbox/inboxView/inboxViewHelper';
  import { InboxViewFilters } from '../inbox/inboxView/inboxViewFilters';
  import { InboxGroupByHelper } from '../inbox/inboxView/inboxGroupBy';
  import { MailBoxColumnChooser } from 'src/app/model/MailBox/mailboxColumnChooser';
  import { CheckboxListComponent } from 'src/app/common/component/checkbox-list/checkbox-list.component';
  import { MailboxColumnChooserHelper } from '../inbox/inboxView/mailBoxColumnChooser';
  import { ColDefsService } from 'src/app/services/mailbox/get-ColDefsService.service';
  import { GfidSearchComponent } from 'src/app/common/component/gfid-search/gfid-search.component';
  import { LinkExceptionComponent } from 'src/app/common/component/link-exception/link-exception.component';
  import { NewMessageService } from 'src/app/services/newMessage/new-message.service';
  import { GroupDetailsComponent } from 'src/app/common/component/group-details/group-details.component';
  import { ReAgeComponent } from 'src/app/common/component/re-age/re-age.component';
  import { InquiryDataList } from '../model/GridViewDataModel/InquiryDataList';
  import { UpdateMemoComponent } from 'src/app/common/component/update-memo/update-memo.component';
  import { OwnershipComponent } from 'src/app/common/component/ownership/ownership.component';
  import { ActionPopupComponent } from 'src/app/common/component/action-popup/action-popup.component';
  import { GroupRowInnerRenderer } from "../inbox/inboxView/group-row-inner-renderer.component";
  import { DragGripCellRenderer } from "../inbox/inboxView/drag-grip-cell-renderer.component";
  import { GridUpdateService } from 'src/app/services/mailbox/mailbox-grid-update.service';
  import { ResolveInquiryComponent } from 'src/app/common/component/resolve-inquiry/resolve-inquiry.component';
  import { MailBoxHelper } from '../inbox/inboxView/mailBoxHelper';
  import { RejectInquiryComponent } from 'src/app/common/component/reject-inquiry/reject-inquiry.component';
  import { NotifyComponent } from 'src/app/common/component/notify/notify.component';
  import { GridOperationService } from "src/app/services/mailbox/grid-operation.service";
  import { CreateRuleComponent } from 'src/app/common/component/create-rule/create-rule.component';
  import { MailBoxSecColumnChooser } from '../inbox/inboxView/mailBoxSecColumnChooser';
  import { SelectItem } from '../model/LoginUserInfo/SelectItem';
  import { NameCount } from 'src/app/model/Common/NameCount';  
  import { DateFormatPipe } from 'src/app/common/pipes/date-format.pipe';
  import { SnoozeInquiryComponent } from 'src/app/common/component/snooze/snooze-inquiry.component';
  import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
  import { NotificationService } from "../common/services/notifications.service";
  import { ConfirmationService,MessageService } from 'primeng/api';
  import { Message } from 'primeng/api';
  import { RequestCacheService } from 'src/app/services/cache/request-cache.service';
  import { GridViewDataModel } from 'src/app/model/GridViewDataModel/GridViewDataModel';
  import { GridDataCacheService } from 'src/app/services/cache/grid-data-cache.service';
  import * as $ from 'jquery';
  import  * as _ from 'underscore';
  import { MailDetailViewComponent } from '../inbox/mailDetailView/mailDetailView.component';
  import { SymphonyChatServiceService } from 'src/app/symphony/service/symphony-chat-service.service';
  import { SymphonyCreateChatComponent } from 'src/app/symphony/symphony-create-chat/symphony-create-chat.component';
  import { SelectedMailModel } from 'src/app/symphony/model/selected-mail.model';
  import { SelectedMailRequest } from 'src/app/symphony/model/selected-mailRequest-model';
  import { SymphonyGridViewComponent } from 'src/app/symphony/symphony-grid-view/symphony-grid-view.component';
  import { SymphonyChatViewComponent } from 'src/app/symphony/symphony-chat-view/symphony-chat-view.component';
  import { MyViewsService } from 'src/app/services/myViews/my-views.service';
  import { InlineWorker } from 'src/app/InlineWorker';
  import { WebSocketUtil } from 'src/app/webSocketUtil';
  import { PiwikProUtils } from 'src/app/common/utility/piwikProUtils';
  import { TabDataService } from "../services/tab-data.service";
  import { TextValue } from 'src/app/model/Common/TextValue';
  import { ListCount } from '../model/Common/NameCount';
  import { MicrofrontendInboxService } from "./microfrontend-inbox.service";
  export class ClsGridStateBeforeWsUpdate {
    selectedInqs: any;
    filterModel: any;
    ColumnGroupState: any;
    sortModel: any;
  }

@Component({
  selector: 'app-microfrontend-inbox',
  templateUrl: './microfrontend-inbox.component.html',
  styleUrls: ['./microfrontend-inbox.component.scss']
})
export class MicrofrontendInboxComponent implements OnInit {

    // C170665-524 Performance Issue - While switching an inquiry in the grid
    // Fix for ws insert focus change
    inqAddedByWs = false;
    // C170665-524 Performance Issue - While switching an inquiry in the grid
    convSelectionStarted: boolean = false;
    public modules: Module[] = AllModules;
    // fix for JIRA C153176-3135
    public objAppUtils = new AppUtils();
    //Loader
    showSpinner: boolean = true;
    saveedColumnstoDBFlag: boolean = false;
    @ViewChild('element') theElement;
    inquiryFiltered: InquiryDataList;
    rowDataInquiryDataList: InquiryDataList[];
    inquiryIndex: number;
    public gridApi: any;
    public gridApiMobile: any;
    public gridColumnApi: any;
    public columnDefs: any;
    public detailRowHeight: any;
    public detailCellRenderer: any;
    public frameworkComponents: any;
    public rowData: any;
    public dataExportToExcel: any;
    public filterIconHide: boolean = false;
    public filterIconHideHorizontal: boolean = false;
    public disableGrpBy: boolean = false;
    public gridOptions: any;
    public rowSelection: any;
    public rowClassRules: any;
    selectedMail: any;
    selectedInquirySubject: string;
    inquiryReportedConvCount: number; // C153176-5257: field tracking the conversation count reported by selected inquiry
    dragSourceMail: any;
    public inboxView: string;
    public isUserDataServiceSuccess: boolean = false;
    public loginUserInfo: any;
    public contextMenuItems: any;
    public hideGrpByMenu: boolean = false;
    public lstmailBoxGrpByChooser: MailBoxColumnChooser[] = new Array();
    public lstmailBoxColumnChooser: MailBoxColumnChooser[] = new Array();
    public lstmailBoxSecondaryColumnChooser: MailBoxColumnChooser[] = new Array();
    public lstmailBoxColumnSelected = new Array();
    public isGroupedBy: Boolean = false;
    public filterText = "";
    public disablePaginationNextLast: boolean = false;
    public disablePaginationPrevFirst: boolean = false;
    public maxSelectionColumnChooser = 50;
    private assignMenu: any;
    private isChatView = false;
    private isFilterFromApply = false;
    public inqData: any;
    public isMobile: boolean = false;
    public columnDefsMobile: any;
    selectedMailMobile: any;
    @Input() public isDashboardInlineView: boolean = false;
    // C153176-4558
    // C153176-4673
    @Input() initialCount: any;
    unreadCount: any;
    @Input() folderName : any
    @Input() accessType : any
    @Input() entityDetails : any [] = []
    @Input() productFamilyParam :any;
    @Input() dealStatusParam :any;
    @Input() inquiryIdParam: any [] = [];
    @Input() entityTypeParam: any;
  
    @Output() updatedUnReadCount = new EventEmitter();
    //secondary clmn  list
    public lstsecondaryCols: any;
    private origColumnDefs: any;
    private customizedColumnDefs: any;
    public customViewFlag = false;
    public chatText = "";
    public selectedRowData: any;
  
    public detailCellRendererParams: any;
    public childInqueryarr = new Array();
    public tabNameforChatView = "";
    public tabNameforChatViewSymphony = "";
    public currentInquirySelection = "";
    // to check if default columns
    public isDefaultColumns: boolean = false;
    // Mail send from assigned group
    // fix for C153176-4558
    public storedColdefs: boolean = false;
    // C153176-5040
    private pendingStoredColDefs: any;
    public columnConfig: any;
    assignedGroup: string = '';
    inquiryWorkflow: any = {};
    isDragged: boolean = false;
    // filter AppUtils
    inboxViewFilters = new InboxViewFilters();
    // C153176-4447: tracking whether the view originated from dashboard chart
    isChartView: boolean = false;
  
    @ViewChildren(CheckboxListComponent) checkboxListComponent: any;
    @ViewChild('primaryColumns', { static: true }) primaryColumns: CheckboxListComponent;
    @ViewChild('owner', { static: true }) owner: CheckboxListComponent;
    @ViewChild('tag', { static: true }) tag: CheckboxListComponent;
    @ViewChild('assignedG', { static: true }) assignedG: CheckboxListComponent;
    @ViewChild('customCategoryfltr', { static: true }) customCategoryfltr: CheckboxListComponent;
    @ViewChild('request', { static: true }) request: CheckboxListComponent;
    @ViewChild(GfidSearchComponent, { static: true }) gfidSearchComponent: any;
    @ViewChild(LinkExceptionComponent, { static: true }) linkExceptionComponent: any;
    @ViewChild(GroupDetailsComponent, { static: true }) groupDetailsComponent: any;
    @ViewChild(ReAgeComponent, { static: true }) reAgeComponent: any;
    @ViewChild(UpdateMemoComponent) updateMemoComponent: any;
    @ViewChild(OwnershipComponent, { static: true }) ownershipComponent: any;
    @ViewChild(ResolveInquiryComponent, { static: true }) resolveInquiryComponent: any;
    @ViewChild(ActionPopupComponent, { static: true }) actionPopupComponent: any;
    @ViewChild(RejectInquiryComponent, { static: true }) rejInqComponent: any;
    @ViewChild(NotifyComponent, { static: true }) notifyComponent: any;
    @ViewChild(CreateRuleComponent, { static: true }) createRuleComponent: any;
    @ViewChild('asSplitAreaMailbox') asSplitAreaMailboxEl: ElementRef;
    @ViewChild('asSplitAreaDetailView') asSplitAreaDetailViewEl: ElementRef;
    // to set grid cols widt based on container width
    @ViewChild('mailBoxDesktopGridContainer') mailBoxDesktopGridContainer: ElementRef;
    // to maintain wf direction and  mailboxView
    public wfDirectionMailboxViewMap: Map<string, string>;
    // JIRA C153176-4596 Start
    // Inquiry direction enum
    inquiryDirectionEnum: any = {
      // This is a dummy view used for tab configuration.
      IN: "IN",
      OUT: "OUT",
      PENDINGAPPROVAL: "PENDINGAPPROVAL",
      PND_REAGE: 'PND_REAGE'
    }
    // JIRA C153176-4596 End
    inlineReply: boolean = false;
    newMessaagedata: any;
    showConversationView = false;
    groupNameFrom = "";
  
    emailGroups: any = [
      {
        name: "Followup",
        value: "FollowUp",
        label: "Follow up"
        //binary: false
      },
      {
        name: "filterUnassigned",
        value: "Unassigned",
        label: "Unassigned"
        //binary: false
      },
      {
        name: "filterAssigned",
        value: "Assigned",
        label: "Assigned"
        //binary: false
      },
      {
        name: "filterRead",
        value: "Read",
        label: "Read"
        //binary: false
      },
      {
        name: "filterUnread",
        value: "Unread",
        label: "Unread"
        //binary: false
      }
      ,
      {
        name: "filterAttachement",
        value: "Attachement",
        label: "Attachement"
        //binary: false
      }
    ];
    ageJson: any = [
      {
        name: "aboveThirty",
        value: "30",
        label: "30+ days",
        //binary: false
      },
      {
        name: "aboveFiften",
        value: "15",
        label: "15+ days",
        //binary: false
      },
      {
        name: "aboveFive",
        value: "5",
        label: "5+ days",
        //binary: false
      },
      {
        name: "aboveSingle",
        value: "1",
        label: "1+ day",
        //binary: false
      }
    ];
  
    hoursJson: any = [
      {
        name: "aboveFourty",
        value: "48",
        label: "48+ hrs",
        //binary: false
      },
      {
        name: "aboveTwenty",
        value: "24",
        label: "24+ hrs",
        //binary: false
      },
      {
        name: "aboveTwelve",
        value: "12",
        label: "12+ hrs",
        //binary: false
      },
      {
        name: "aboveSix",
        value: "6",
        label: "6+ hrs",
        //binary: false
      }
  
    ]
  
  
    @ViewChild(SnoozeInquiryComponent, { static: true }) snoozeInqComponent: any;
    public selectedColumn = "";
    public columnChooserSelectedColumns: Array<any> = new Array();
    // secondary column selected values
    public SecondarycolumnChooserSelectedColumns: Array<object> = new Array();
    public rowGroupPanelShow;
    public lbTotalRecords: string = '0';
    public lbPageSize: number = 0;
    public lbCurrentPage: number = 0;
    public lbTotalPages: number = 0;
  
    // Input to the contact sidebar 
    attachmentsList: any = [];
    notesList: any = [];
    auditsList: any = [];
    clc: any = {};
    selectedInquiryId: any;
  
    // For Draft Conversation
    selectedDraftMail: any;
  
    // for dynamic height/width of grid row
    private currentHeight: any;
    private currentWidth: any; // C1531760-4399
  
    dateFormatPipe: DateFormatPipe;
    guserPrefDateFormat: string = '';
  
    conversationViewId: string;
    // selected node
    selectedNodes: any;
    wsWorker: InlineWorker;
    isWebWorkerEnable: boolean = false;
    delayWorkerResponseExecutionInMs: number = 0;
    wsQueue: Array<object> = [];
    isWebWorkerBusy: boolean = false;
    userDataSubscription: Subscription;
    enableInquirySubStatusFlag: any;
    groupRowInnerRenderer: string;
    popupParent: HTMLBodyElement;
    private animateRows = true
    private getRowHeight;
    showFilterFlag: boolean = false;
    @Output() advanceSearchToggle = new EventEmitter();
    @Input() advanceSearchVisible: boolean = false;
    showMicrofrontendinbox: any;
    productFamilyFromLoginUser: any;
    hideLinkToDeal: boolean;
  
    @HostListener('window:resize', ['$event'])
    onResize(event) {
      event.target.innerWidth;
      /*  this.resizeGrid(); */
    }
  
    defaultColDef = {
      sortable: true,
      resizable: false,
      filter: true,
      width: 100,
      headerComponentParams: {
        menuIcon: 'fa-bars',
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
  
          '    <span ref="eText" class="ag-header-cell-text mailbox-header" role="columnheader" ></span>' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          '  </div>' +
          '</div>'
      },
      suppressColumnVirtualisation: true
  
    }
    public isDraftsView: boolean = false;
    // symphony chaanges
    public isSymphonyView: boolean = false;
    public strMailboxView: string = "";
    public WS_PROCESSING_FAILURE_MSG: any;
    public currentTabName: string = "";
    //TO customize row group 
    private groupRowRendererParams;
    private subscription: Subscription;
    private gridOprSubscription: Subscription;
    private tabClickedSubscription: Subscription;
    private inlineSusbscription: Subscription;
    selectedConvId: number;
    selectedInqId: number;
    doubleClickedInqId: number;
    // C153176-4577: refactor 'requestFilter' to 'dataCache'
    viewNotificationData: any = null;
    // inquiry filter
    selectedAssignedGroups: string;
    selectedRequestTypes: string;
    selectedTags: string;
    assignedGroups: NameCount[] = [];
    customCategories: SelectItem[] = [];;
    requestTypes: SelectItem[] = [];
    requestTempTypes = [];
    tags: SelectItem[] = [];
    selReqTypes: any = [];
    selAssignedGrps: any = [];
    customCategory: any = [];
    selAssignedOwner: any;
    selTags: any = [];
    selEmail: any = [];
    selDays: any = [];
    selHours: any = [];
    selOwner: any = [];
  
    counter: any = 0;
    counterFlag: boolean = false;
    display: boolean = true;
    checkselection: any;
    groupOwnerList = [];
    groupTempOwnerList = [];
    dashboardInlineTab: boolean = false;
  
    //resize panel Horizontal
    splitdirection: string = ''
    // C153176-5191: grid 60%, conversation view: 40%
    splitAreaMailboxDefaultSize = 60;
    splitAreaDetailDefaultSize = 40;
    sizes = {
      percent: {
        area1: 30,
        area2: 70,
      },
      pixel: {
        area1: 120,
        area2: '*',
        area3: 160,
      },
    }
  
    // loader
    private annexFields = ['URGENT', 'ATTACHMENT', 'NOTES', 'FOLLOW UP', 'ESCALATION'];
    private annexAddedToCustom = false;
    // to restrict 
    // to check weather preferences are there in DB
    private isColsPreferencesinDb: boolean = false;
  
    // Initial load read flag needs no change
    private isGridInitialLoad: boolean = true;
  
    // Selected CLC trade from the Right Navigation bar
    trades: any;
    msgsGrowl: Message[] = [];
    inlineReplySendData: boolean;
    // C153176-5546: inquiry with open Conversation History
    private openedConvHistInq: any;
    // C153176-5546: reference count of the opened Conversation History
    private openedConvHistInqRefCount: number = 0;
  
    pushContact: any = null; // C153176-4229 : Push Contact as recipients
    dataCache: GridDataCacheService;
    viewType: any;
    myViewCriteria: any;
    finalCriteria: any;
    myViewDefaultCriteria: any;
    finalUICriteria: any; // C153176-5081: finalUICriteria - to be used for websocket upadates filtering
    acceptedByMyView: boolean = false;   // C153176-4798: filter myView criteria, // C153176-5081: rename the attribute so as not to be confused with other folder's criteria
    searchView: boolean = false;
  
    // C153176-4903: Pass Selected Assigned Group Name to Contact Side bar for exact grid selecteion
    assignedGroupName: any = null;
  
    // C153176-4910: loading conversation in-progress flag
    loadingConversationInProgress: boolean = false;
    // C153176-4910: the latest clicked inquiry id/group
    clickedInquiryId: any;
    clickedInquiryGroups: any;
    // C153176-4660:
    thisComponent: any;
    // C153175-4700: main grid viewbody
    gridViewPort: any;
  
    // C153175-5040: Show confirmation popup for save columns for TAG.
    saveColumnConfirm: boolean = false;
    public inqUnreadStatusMap = new Map();
  
    gridDetailsObject = {
      'criteria': "",
      'name': "",
      'selInq': [],
      'viewType': 0
    };
    userGroupIdList: any;
    oneDay = 24 * 60 * 60 * 1000;
    oneHour = 1000 * 60 * 60;
    objGridStateBeforeWsUpdate = new ClsGridStateBeforeWsUpdate();
    isFirstWsUpdate: boolean = true;
    isWebSocketRedesignEnable: boolean = true;
    symphonyEnabled: boolean = false;
    
    @ViewChild('sympcrtChatComp') sympcrtChatComp: SymphonyCreateChatComponent;
    @ViewChild('symphonyGridView', { static: true }) symphonyGridView: SymphonyGridViewComponent;
    @ViewChild('symphonyChatViewComponant') sympChatViewComp: SymphonyChatViewComponent;
    @ViewChild('mailDetailViewComp') mailDetailViewComp: MailDetailViewComponent; // C170665-148 Reading pane Focus moving itself
    myViewColumns: NameCount[] = []; //C170665-363 for save column in my view
    userFilterColumns;
  
    // C170665-673 | Input protery for mail detail view.
    selInquiryDetails: any;
    showWebSocketProcessingFailureMsg = false;
    isWsConnectionEstablished = false;
    renderFilterBody = false;
    viewBeforeSymphonyRowSelection = {viewName:''};
    groupIdFromProductFamily: any[];
    enableManualLinkEntity : string
    constructor(private inboxService: InboxService, private userDataService: UserDataService,
      private tabDataService: TabDataService,
      private renderer: Renderer2,
      private contextMenu: ContextMenu, private colDefsService: ColDefsService, private newMessageService: NewMessageService, private gridUpdateService: GridUpdateService, private gridOprService: GridOperationService,
      private cdr: ChangeDetectorRef, private dashboardService: DashboardService, private notificationService: NotificationService,
      private contextMenuService: ContextMenuService, private requestCacheService: RequestCacheService,
       private symphonyChatService: SymphonyChatServiceService,
      private myViewService: MyViewsService,
      private micrfrontendInboxService : MicrofrontendInboxService,
      private messageService: MessageService

    ) {
  
      this.isMobile = AppUtils.isMobileView();
      // C153176-4660: assign 'thisComponent'
      this.thisComponent = this;
      // C153176-4673: server pagination

       this.dataCache = new GridDataCacheService(this.inboxService, this, this.userDataService); // C153176-5144

      this.wfDirectionMailboxViewMap = new Map<string, string>();
      this.wfDirectionMailboxViewMap.set('PENDING APPROVAL', 'PENDINGAPPROVAL');
      this.wfDirectionMailboxViewMap.set('INBOX', 'IN');
      this.wfDirectionMailboxViewMap.set('SENT', 'OUT');
  
      this.getConvData();
      this.convChange();
      this.gridOptions = <GridOptions>{
        context: {
          componentParent: this
        },
        rowBuffer: 0,
        columnDefs: this.columnDefs,
        rowGroupPanelShow: 'always',
        animateRows: true,
        debug: true,
        showToolPanel: true,
        suppressAggFuncInHeader: true,
        purgeClosedRowNodes: true,
      };
  
      // let tabName = AppUtils.getCurrentTab();
      let tabName = this.folderName
      if (tabName) {
        this.strMailboxView = tabName.toUpperCase();
        this.currentTabName = tabName.toUpperCase();
        this.WS_PROCESSING_FAILURE_MSG = `Messages in the ${this.strMailboxView} are not up to date. Please close and re-open the tab`;
      }
      this.notificationService.listen().subscribe((m: any) => {
        this.viewNotificationData = m;
      })
      this.rowGroupPanelShow = "always";
      this.rowSelection = "multiple";
      this.inboxView = "vertical";
      let timeCellClassStart = performance.now();
      this.rowClassRules = {
  
        "qma-inbox-row-read": (params) => {
          var isReadby = false;
          //Removing read / unread , Jira 4572
          if (params && params.data && params.data.readBy && this.strMailboxView !== 'SENT') {
            for (let i = 0; i < params.data.readBy.length; i++) {
              if (this.loginUserInfo.userId == params.data.readBy[i]) {
                isReadby = true;
                //console.log(this.unreadCount);
                /* this.dashboardService.getDashboardCountsServiceSubject().subscribe(dashSnapshotCnts=>{
                  if(dashSnapshotCnts && dashSnapshotCnts.boxCounts) {
                    dashSnapshotCnts.boxCounts.forEach((val:any) =>{
                      // let t =  this.strMailboxView == Object.keys(val)[0].toUpperCase();
                      if(this.strMailboxView === Object.keys(val)[0].toUpperCase()) {
                      let previousUnreadCount = +val[Object.keys(val)[0]].unReadCount.$numberLong;
                      val[Object.keys(val)[0]].unReadCount.$numberLong = previousUnreadCount-1;
                      this.dashboardService.setDashboardCountsAtUISubject(dashSnapshotCnts);       
                      }
                       
                     })
                  }
                 
                }); */
  
              }
            }
            if (isReadby) {
              return isReadby
            }
          } else if (this.strMailboxView === 'SENT') {
            return true;
          }
        },
        "qma-inbox-row": (params) => {
          var isUnread = true;
          if (params && params.data && params.data.readBy && this.strMailboxView !== 'SENT') {
            for (let i = 0; i < params.data.readBy.length; i++) {
              if (this.loginUserInfo.userId == params.data.readBy[i]) {
                isUnread = false;
              }
            }
  
          }
          if (isUnread && this.strMailboxView !== 'SENT') {
            return isUnread;
          } else if (this.strMailboxView === 'SENT') {
            return false;
          }
        }/* ,
        "qma-grid-row-escalated": (params) => {
          var isEscalated = false;
          if (params &&  params.node && !params.node.detail && params.data && params.data.isEscalated == "Y" ) {
            isEscalated=true;
          }
          return isEscalated;
        } */
  
      };
      let timeCellClassEnd = performance.now();
      console.log("time taken to render grid css " + (timeCellClassEnd - timeCellClassStart) + " milliseconds.");
      this.getConvData();
      this.gridOptions = <GridOptions>{
        context: {
          componentParent: this
        },
        navigateToNextCell: (params) => {
          let suggestedNextCell = params.nextCellPosition;
          let KEY_UP : any = 38;
          let KEY_DOWN : any = 40;
          switch (params.key) {
            case KEY_DOWN: {
              let previousCell = params.previousCellPosition;
              // set selected cell on current cell + 1
              this.gridApi.forEachNode(function (node) {
                if (previousCell.rowIndex + 1 === node.rowIndex) {
                  node.setSelected(true);
                } else {
                  node.setSelected(false);
                }
              });
              break;
            }
            case KEY_UP: {
              let previousCell = params.previousCellPosition;
              // set selected cell on current cell - 1
              if (params.previousCellPosition.rowIndex !== 0) {
                this.gridApi.forEachNode(function (node) {
                  if (previousCell.rowIndex - 1 === node.rowIndex) {
                    node.setSelected(true);
                  } else {
                    node.setSelected(false);
                  }
                });
              }
              break;
            }
          }
          return suggestedNextCell;
        }
      };
      tabName = AppUtils.getCurrentTab();
      if (tabName) {
        this.strMailboxView = tabName.toUpperCase();
      }
      this.notificationService.listen().subscribe((m: any) => {
        this.viewNotificationData = m;
      })
      this.rowGroupPanelShow = "always";
      this.rowSelection = "multiple";
      this.inboxView = "vertical";
  
  
      // if (tabName.toUpperCase() == "DRAFTS") {
      //   this.isDraftsView = true;
      //   //this.createViewForDrafts();
      //   this.maxSelectionColumnChooser = 6;
      //   // this.columnDefs = new DraftGridColumnDefs().draftsGridDefs();
      //   this.removeDeletedDraftsById();
      //   this.updateMailboxForDrafts();
      //   this.removeSentMailFromDrafts();
      //   this.colDefsService.setcolDefs(this.strMailboxView, this.columnDefs);
      // }
      if (!this.isDraftsView) {
  
        let objMailBoxGridColdefs = new MailBoxGridColdefs();
        //request Payload to save columns preferences
        /* {"viewName":"Inbox","columnsToShow":[{"attributeName":"followUp","displayOrder":0,"displayName":"Follow Up","dataType":"String","defaultDisplay":"Y","width":20,"disableFilter":"Y","displayType":"icon"}]}  */
        //get state from DB
        /* let storedColdefs=JSON.parse(sessionStorage.getItem("MAILBOX_STATE"));
        if(storedColdefs){
          this.columnDefs = storedColdefs;//Restore 
          
        }else{
          this.columnDefs = objMailBoxGridColdefs.getMailboxColDefs();
        } */
  
        this.columnDefs = objMailBoxGridColdefs.getMailboxColDefs();
  
        if (this.strMailboxView === "SEARCH" && (!this.inboxService.customizedColumns || !this.inboxService.customizedColumns.length)) {
          /* C153176-4570 State column push on second last position so Annex column will be last for avoid the overlapping issue*/
          this.columnDefs.splice(this.columnDefs.length - 2, 0, MailBoxGridColdefs.getSearchStateColumnDef());
          //this.columnDefs.push(MailBoxGridColdefs.getSearchStateColumnDef())
        }
        // this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        //   this.enableInquirySubStatusFlag = loginUserInfo.enableInquirySubStatusFlag;
        // });
  
        this.columnDefs.forEach(col => {
          if (col.headerName != "ANNEX") {
            col.enableRowGroup = true;
            col.suppressMovable = false;
  
          }
          //dcc
          if(col.field === "inquirySubStatus" && !this.enableInquirySubStatusFlag){
            const index = this.columnDefs.indexOf(col, 0);
            if (index > -1) {
              this.columnDefs.splice(index, 1);
            }
          }
        });
  
        this.detailRowHeight = 260;
        this.detailCellRenderer = "myDetailCellRenderer";
        this.detailCellRendererParams = {
          refreshStrategy: 'rows',
        }
        //this.frameworkComponents = { myDetailCellRenderer: DetailCellRenderer };
        this.frameworkComponents = {
          myDetailCellRenderer: DetailCellRenderer,
          groupRowInnerRenderer: GroupRowInnerRenderer,
          dragGripCellRenderer: DragGripCellRenderer,
          myDetailCellRendererParams: this.detailCellRendererParams
        };
        this.groupRowInnerRenderer = "groupRowInnerRenderer";
        this.columnDefs[0].cellRenderer = "agGroupCellRenderer";
        this.colDefsService.setcolDefs(this.strMailboxView, this.columnDefs);
  
        //reset cols visibility on load
        this.resetMailboxColumnsVisibility();
        let objinboxGroupByHelper = new InboxGroupByHelper();
        let objinboxColumnChooserHelper = new MailboxColumnChooserHelper();
        this.lstmailBoxGrpByChooser = objinboxGroupByHelper.createGroupByList(this.columnDefs);
        /* this.lstmailBoxColumnChooser = objinboxColumnChooserHelper.createColumnChooserList(this.columnDefs); */
        this.lstmailBoxColumnChooser = this.createColumnChooserList(this.columnDefs);
        //Selected columns count
        this.lstmailBoxColumnChooser.forEach(column => {
          if (column.binary) {
            this.columnChooserSelectedColumns.push(column);
          }
        });
  
        this.groupRowRendererParams = {
          flagCodes: {
  
          }
        };
  
        this.updateMailboxForUnread();
        this.updateMailboxForRead();
  
        //to have middle aligned rows
        this.columnDefs.forEach(col => {
          if (col.headerName == "ANNEX") {
            /*C153176-4570 class added for annex column when advance search*/
            if (this.strMailboxView == "SEARCH") {
              col.cellClass = "qma-mailbox-header";
            } else {
              // col.cellClass = "qma-mailbox-cell";        // annex column resize issue -C153176-4904
            }
          }
  
        });
        // backup initial 'columnDefs' and tentatively apply customized columns
        this.backupColumnDefs();
        this.applyCustomColumns(false, true);
      }
      if (this.isMobile) {
        this.columnDefsMobile = ColumnDefsMobile;
      }
      // load grid data for the first time on this component
      // C153176-4673: server-side pagination
      this.loadGridData();
      if (this.isMatchingViewName("intensityHeatMap")) {
        /* C153176-4570 State column push on second last position so Annex column will be last for avoid the overlapping issue*/
        this.columnDefs.splice(this.columnDefs.length - 2, 0, MailBoxGridColdefs.getSearchStateColumnDef());
      }
        this.userDataService.getServiceStatus().subscribe(status => {
        this.isUserDataServiceSuccess = status;
          });
      let value = '';
    this.userDataSubscription =  this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserData => {

      //  this.isWebSocketRedesignEnable = loginUserData.cvWebsocketConfig.isWebSocketRedesignEnable;
        // this.isWebWorkerEnable = loginUserData.cvWebsocketConfig.isWebWorkerEnable;
      //  this.inboxService.commonWsSubscribtion = loginUserData.cvWebsocketConfig.commonWsSubscribtion;
       this.delayWorkerResponseExecutionInMs = loginUserData.cvWebsocketConfig.delayWorkerResponseExecutionInMs;
   
      this.loginUserInfo = loginUserData;
      this.enableInquirySubStatusFlag = loginUserData.enableInquirySubStatusFlag;
      this.showMicrofrontendinbox = loginUserData.enableBrazilFxIntegration ? loginUserData.enableBrazilFxIntegration : 'N'
      this.productFamilyFromLoginUser = loginUserData.productFamilyGroupIdMap ? loginUserData.productFamilyGroupIdMap : '';
      this.enableManualLinkEntity = loginUserData.enableManualLinkEntity ? loginUserData.enableManualLinkEntity : 'N'
      this.getAssignedGroupRequestTypeTags(loginUserData)
        this.notificationService.setLoginUserId(loginUserData.userId);
        this.getUserGroups(loginUserData);
        this.removeExistingItem(QmaConstant.inboxConfiguration.contextMenu);
        this.addNewItem(QmaConstant.inboxConfiguration.contextMenu, this.loginUserInfo);
        this.dataCache.setPageSize(loginUserData, this.isMobile);
        
        
        
  
        //for inquiry filter
        for (var i = 0; i < loginUserData.context.length; i++) {
          //var groupOwnerList = [];
          for (var item = 0; item < loginUserData.context[i].assignOwnerList.length; item++) {
            if (loginUserData.context[i].assignOwnerList[item].active) {
              this.groupTempOwnerList.push({
                name: loginUserData.context[i].assignOwnerList[item].text,
                value: loginUserData.context[i].assignOwnerList[item].text,
                label: loginUserData.context[i].assignOwnerList[item].text,
                binary: false,
                userID: loginUserData.context[i].assignOwnerList[item].value,
                status: loginUserData.context[i].assignOwnerList[item].active,
                country: loginUserData.context[i].assignOwnerList[item].country,
                id: loginUserData.context[i].assignOwnerList[item].id,
                email: loginUserData.context[i].assignOwnerList[item].email,
                time: loginUserData.context[i].assignOwnerList[item].timeZone,
  
  
              });
            }
          }
        }
        //console.log(this.groupTempOwnerList);
  
        let findWithinArray = [];
        this.groupTempOwnerList.forEach(element => {
          findWithinArray.some(item => item.userID == element.userID) ? null : findWithinArray.push({
            name: element.name,
            value: element.value,
            label: element.label + " " + '(' + element.userID + ')',
            binary: false,
            userID: element.userID,
            status: element.status,
            country: element.country,
            id: element.id,
            email: element.email,
            time: element.time,
          });
        });
        // console.log(finAddAnaArr);
        this.groupOwnerList = findWithinArray;
        // console.log(this.groupOwnerList);
        let convView = '';
        if (null != loginUserData.preferences) {
          loginUserData.preferences.map(function (val) {
            if (val.key === "conversationViewId") {
              convView = val.value;
            }
            if (val.key === "dateFormatterSelectId") {
              value = val.value;
            }
          })
        }
        convView = 'Horizontal'
        this.conversationViewId = convView !== "" ? convView : "Vertical";
        this.guserPrefDateFormat = value;
        // will be used in Grid
        AppUtils.setDateFormarInSession(this.guserPrefDateFormat);
        this.inboxService.setLayoutOption(this.conversationViewId);
        this.tabDataService.setClickedIcon(this.conversationViewId.toLowerCase());
      });
      // update content from websocket
     
      this.popupParent = document.querySelector("body");
      //dynamic detail row height
      this.getRowHeight = function (params) {
        //console.log("COUNT="+params.data.convCount);
        if (params.node && params.node.detail) {
          //JIRA : 4656 :  extra line coming after grid
          let detailViewHeight = 0;
          let convHeight = 0;
          let convCount = params.data.convCount;
          if (convCount == 0) convCount = 1;
          if (convCount < 5) {
            //less than 5 inquiries
            convHeight = convCount * QmaConstant.inboxConfiguration.detailRowHeightForNRows;  //C153176-5191 row-height: 33 -> 28
            detailViewHeight = QmaConstant.inboxConfiguration.detailRowHeightForOneRow + convHeight;
          } else if (convCount == 5) {
            convHeight = convCount * QmaConstant.inboxConfiguration.detailRowHeightForNRows;
            detailViewHeight = QmaConstant.inboxConfiguration.detailRowHeightForNRows - 1 + convHeight;
          }
          else {
            //more than 5 inquiries         
            // C170665-49 Unable to view and download the 1st conversation of an inquiry
            convHeight = QmaConstant.inboxConfiguration.detailRowHeightForNRows * 7; // scroll padding factor increased from 5 to 7 and it will go hand in hand with detail row
            detailViewHeight = QmaConstant.inboxConfiguration.detailRowHeight + convHeight;
          }
          return detailViewHeight;
        } else {
          return QmaConstant.inboxConfiguration.gridRowHeight;
        }
      };
      this.dateFormatPipe = new DateFormatPipe();
  
      this.animateRows = true;
      this.renderer.listen('window', 'click', (e: Event) => {
        if (this.mailBoxDesktopGridContainer && !this.mailBoxDesktopGridContainer.nativeElement.contains(event.target)) {
          if (this.gridApi) {
            // C153176-4705: outer-click observed, reset focused cell
            this.gridApi.clearFocusedCell();
          }
        }
      });
      this.userGroupIdList = this.getUserGroupIds();
      this.getMyviewColumn();//my view save column
    }
  
    ngAfterViewInit() {
  
      /* $("#jQuryTest").click(function () {
        alert("Handler for .click() called.");
      }); */
      /* const element = this.getElement();
            // element.resizable({ handles: "all" });
      if (element) {
        $(element).resizable({ maxHeight: 1000,
        maxWidth: 1000,
        minHeight: 100,
        minWidth: 100 });
      }
             $(element).resizable({ maxHeight: 1000,
              maxWidth: 1000,
              minHeight: 100,ver
              minWidth: 100 }); */
    }
    ngOnInit() {
      // C153176-4660: clear conversation request data
      if(this.accessType == 'Advance Search') {
        this.toggleSearch(false);
      }
      this.groupIdFromProductFamily = this.micrfrontendInboxService.getAssignedGroupId(this.productFamilyParam,this.productFamilyFromLoginUser);
      if (this.dealStatusParam == QmaConstant.preMatchedStatusEnum.REVIEWED  || this.dealStatusParam ==  QmaConstant.preMatchedStatusEnum.APPROVED  || this.dealStatusParam ==  QmaConstant.preMatchedStatusEnum.CANCELLED ) {
        this.hideLinkToDeal = true;
      }
      if(this.inboxService.commonWsSubscribtion){
        //this.subscribeToWsQueue();
        console.debug('worker common subscribtion is on');
        this.inboxService.wsQueue.set(this.currentTabName,[]);
        this.subscribeToTimer();
      } else{
        // this.subscribeToWebSocketUpdate();
      }
      if(this.isWebWorkerEnable){
        this.initializeWebWorker();
        //this.consumeWsUpdateQueue();
      }
      this.gridOprService.clearConversationRequestData();
      // for inquiry filter
    //  this.tabNameforChatView = AppUtils.getCurrentTab().toUpperCase();
     // this.tabNameforChatViewSymphony = AppUtils.getCurrentTab().toUpperCase() + "_symphony";
      this.assignedGroups = this.userDataService.assignedGroups;
  
      // this.requestTempTypes ;
      this.dashboardInlineTab = this.dashboardService.getDashboardInline();
      let requestArray: SelectItem[] = [];
      this.userDataService.requestTypes.forEach(element => {
        requestArray.some(val => val.value == element.value) ? null : requestArray.push({ 'text': element.text, 'label': element.label, 'value': element.value, 'listItem': element.listItem });
      });
      this.requestTypes = requestArray;
  
      let tagsArray: SelectItem[] = [];
      this.userDataService.tags.forEach(element => {
        tagsArray.some(tag => tag.value == element.value) ? null : tagsArray.push({ 'text': element.text, 'label': element.label, 'value': element.value, 'listItem': element.listItem });
      });
      this.tags = tagsArray;
  
  
      this.bindCategoryFilterOptions();
      AppUtils.dashboardInlineView = this.isDashboardInlineView;
      // this.dashboardService.getDashboardCountsServiceSubject().subscribe(dashSnapshotCnts=>{
      //   if (Object.keys(dashSnapshotCnts).length != 0) {
      //     dashSnapshotCnts.boxCounts.forEach((val:any) =>{
      //       if(this.folderName == Object.keys(val)[0].toUpperCase()) { 
      //         this.unreadCount = val[Object.keys(val)[0]].unReadCount;
      //       }
      //     });
      //   }
      // });

      // re-assign this.strMailboxView <!-- Jira - 4458 - Download excel correction done -->
      // let tabName = AppUtils.getCurrentTab();
      let tabName = this.folderName;
      if (tabName) {
        this.strMailboxView = tabName.toUpperCase();
      }
  
      // C153176-5398 | For saving columns for dashboard inline inbox view
      if (!this.folderName && this.isDashboardInlineView) {
        this.folderName = tabName;
      }
  
      //was creating multiple issues thats why commented
      this.subscription = this.inboxService.dataChange.subscribe(option => {
        const tabName = option.tabName;
        const isDashboardInlineView = option.dashboardInlineView ? true : false;
  
        // C153176-5398 | For saving columns for dashboard inline inbox view
        if (this.isDashboardInlineView) {
          this.folderName = tabName;
        }
  
        // C153176-4577: matching non-dashboard and dashboard views. The dashboard views match with 'isDashboardInlineView' only,
        // since there is only one dashboard inline view at most.
        if ((tabName && tabName.toUpperCase() === this.strMailboxView && isDashboardInlineView == this.isDashboardInlineView)
          || (isDashboardInlineView === true && this.isDashboardInlineView === true)) {
          if (this.customViewFlag) {
            // if it is customView (MyViews), re-apply original and customized columns
            this.applyCustomColumns(true, false);
          }
          //C153176-4796: Do NOT show Save Column icon on MyViews
         if (this.inboxService.checkBeforeLoad && this.rowData && this.rowData.length) {
            // C153176-4796: this view already has data, and was instructed not to load more, return do nothing
            this.notifyMobileDataReady();
            return;
          }
          this.loadGridData();
        }
      });
  
      // Subscription for Grid Operations
      this.gridOprSubscription = this.gridOprService.getConversation().subscribe(selectedInqConv => {
        if (this.isMobile) { // added for mobile performance
          return undefined;
        }
        return this.onConversationHistorySelected(selectedInqConv);
      });
      this.handleSplitter("vertical");

      // this.onHorizontalImgClick()
      // this.tabClickedSubscription = this.tabDataService.getClickedIcon().subscribe(clickedIcon => {
      //   if (this.isMobile) { // added for mobile performance
      //     return;
      //   }
      //   // C170665-1616 email as a chat loader handling in exception and api failure
      //   this.loadingConversationInProgress = false;
      //   // in case of inbox or chat view folder for chat row dont change view
      //   /*  if((this.strMailboxView == "INBOX" || this.strMailboxView == "CHAT") && this.symphonyEnabled
      //    &&  this.inqData &&  this.inqData.streamId
      //    ) {
      //        return;
      //    } */
      //   this.clickedIcon = clickedIcon;
      //   //changing split direction for horizontal view of mailbox
  
      //   if (clickedIcon == 'horizontal' || this.clickedIcon == "Horizontal") {
      //     //resize panel view Start
      //     this.handleSplitter("vertical");
      //     //resize panel view End
      //     this.onHorizontalImgClick();
      //   }
      //   if (clickedIcon == 'vertical' || this.clickedIcon == "Vertical") {
      //     //resize panel view
  
      //     this.handleSplitter("horizontal");
      //     this.onVerticleImgClick();
  
      //   }
      //   // C170665-1766 provide Email as a Chat view in profile settings so user can save it to default.
      //   if (clickedIcon && typeof clickedIcon === 'string' && clickedIcon.toUpperCase() === 'CHATVIEW') {
      //     //this.onVerticleImgClick();
      //     this.handleSplitter("horizontal");
      //     this.onChatImgClick();
      //   }
      // });
  
      this.setUserConversationView();
      // check symphony entitlement
      this.symphonyChatService.getSymphonyEntitlement().subscribe((entitlement: any) => {
        if (entitlement && entitlement.status && this.userDataService.loggedInUserInfo && this.userDataService.loggedInUserInfo.symphonyConfig
          && this.userDataService.loggedInUserInfo.symphonyConfig.symphonyEnabled && this.userDataService.loggedInUserInfo.isSymphonyEnabledForUser == 'Y') {
          this.symphonyEnabled = true;
        }
        else {
          this.symphonyEnabled = false;
        }
      });
      // check ws Connection status
      // this.subscribeToWSConnectionStatus();
    }
  
    // inquiry filter
    getRequestGroups(selReqTypes: any) {
      this.counter = this.counter + (selReqTypes.length - this.selReqTypes.length);
      this.selReqTypes = selReqTypes.slice(0);
    }
  
    getOwnerList(selOwner: any) {
      this.counter = this.counter + (selOwner.length - this.selOwner.length);
      this.selOwner = selOwner.slice(0);
  
    }
  
    getAssignedGroups(selAssignedGrps: any) {
      this.counter = this.counter + (selAssignedGrps.length - this.selAssignedGrps.length);
      this.selAssignedGrps = selAssignedGrps.slice(0);
  
    }
  
    getcustomCategory(customCategory: any) {
      this.counter = this.counter + (customCategory.length - this.customCategory.length);
      this.customCategory = customCategory.slice(0);
  
    }
  
    getTags(selTags: any) {
      this.counter = this.counter + (selTags.length - this.selTags.length);
      this.selTags = selTags.slice(0);
    }
  
    getEmail(selEmail: any) {
      this.counter = this.counter + (selEmail.length - this.selEmail.length);
      this.selEmail = selEmail.slice(0);
    }
  
    getAgeDays(selDays: any) {
      this.counter = this.counter + (selDays.length - this.selDays.length);
      this.selDays = selDays.slice(0);
    }
    getAgeHours(selHours: any) {
      this.counter = this.counter + (selHours.length - this.selHours.length);
      this.selHours = selHours.slice(0);
    }
  
    getUserGroups(_loginUserInfo: any) {
      this.assignMenu = [];
      for (var i = 0; i < _loginUserInfo.context.length; i++) {
        var groupOwnerList = [], uniqueData = [], assignMenuAvailable = [];
        for (var item = 0; item < _loginUserInfo.context[i].assignOwnerList.length; item++) {
          if (_loginUserInfo.context[i].assignOwnerList[item].active) {
            // if(uniqueData.indexOf(_loginUserInfo.context[i].assignOwnerList[item].value) != "-1"){
            groupOwnerList.push(_loginUserInfo.context[i].assignOwnerList[item]);
            //  }
          }
          /* if (uniqueData.indexOf(_loginUserInfo.context[i].assignOwnerList[item].text) == -1) {
              assignMenuAvailable.push(_loginUserInfo.context[i].assignOwnerList[item]);
              uniqueData.push(_loginUserInfo.context[i].assignOwnerList[item].text);
          } */
        }
  
        for (var idx = 0; idx < _loginUserInfo.myGroups.length; idx++) {
          if (_loginUserInfo.myGroups[idx].id == _loginUserInfo.context[i].groupId) {
            this.assignMenu.push({
              groupOwner: groupOwnerList,
              groupName: _loginUserInfo.myGroups[idx].groupName,
              id: _loginUserInfo.myGroups[idx].id
            })
          }
  
        }
  
      }
    }
  
    /**
     * Backup 'columnDefs' to 'origiColumnDefs'
     */
    backupColumnDefs() {
      this.origColumnDefs = Object.assign([], this.columnDefs);
    }
  
    hasAnnexField(customColDefs) {
      return customColDefs.some(c_def => {
        return c_def.label && this.annexFields.includes(c_def.label.toUpperCase());
      })
    }
  
    /**
     * Apply customized view columns from InboxService.customized columns
     * @param restoreColumnDefs  to restore columnDefs if true
     * @param updateCustomViewFlag to update customViewFlag if true
     */
    applyCustomColumns(restoreColumnDefs, updateCustomViewFlag) {
      if (restoreColumnDefs && this.customViewFlag) {
        // restore columnDefs if this is a customized view and restore flag is set.
        this.columnDefs = Object.assign([], this.origColumnDefs);
      }
  
      let customViewFlag = false;
      if (this.inboxService.customizedColumns && this.inboxService.customizedColumns.columns
        && this.inboxService.customizedColumns.columns.length) {
        customViewFlag = true;
        // copy customized columns from inboxService
        this.customizedColumnDefs = this.inboxService.customizedColumns.columns;
        const hasAnnex = this.hasAnnexField(this.customizedColumnDefs);
        if (hasAnnex && !this.annexAddedToCustom) {
          this.customizedColumnDefs.push({ label: 'ANNEX' });
          this.annexAddedToCustom = true; // C153176-5165
        }
        // C170665-94 -Annex column should be part of all views in QMA 2.0
        if (!hasAnnex && !this.annexAddedToCustom && this.inboxService.requestObj.viewName) {
          this.customizedColumnDefs.push({ label: 'ANNEX' });
          this.annexAddedToCustom = true;
        }
        // always add checkbox and cheverons to customized views
        this.customizedColumnDefs.push({ label: 'checkbox'.toUpperCase() });
        this.customizedColumnDefs.push({ label: 'cheveron'.toUpperCase() });
        this.customizedColumnDefs.push({ label: 'cheveron_1'.toUpperCase() });
        this.customizedColumnDefs.push({ label: 'expander'.toUpperCase() });
        this.customizedColumnDefs.push({ label: 'draggrip'.toUpperCase() });
  
        // set the customized columns at inboxService
        this.inboxService.customizedColumns = undefined;
        // apply hide
        this.columnDefs.forEach(def => {
          let d_headerName = def.headerName.toUpperCase();
          if (!d_headerName && def.field) {
            // if headerName is empty, take def.field if it is present
            d_headerName = def.field.toUpperCase();
          }
          if (d_headerName === 'ANNEX') {
            def.hide = !hasAnnex;
          } else {
            const index = this.customizedColumnDefs.findIndex(c_def => c_def.label.toUpperCase() === d_headerName);
            if (index >= 0) {
              def.hide = false;
            } else {
              def.hide = true;
            }
          }
        });
        if (this.gridApi) {
          this.gridApi.setColumnDefs(this.columnDefs);
        }
      }
      if (updateCustomViewFlag) {
        this.customViewFlag = customViewFlag;
      }
    }
  
    /**
     * Check whether a column is part of the customized columns
     * @param column column def object
     */
    isCustomizedColumn(column) {
      if (!column || !this.customViewFlag || !this.customizedColumnDefs) {
        return false;
      }
      let v = column.headerName;
      if (!v && column.field) {
        // if headerName is empty but field name is not, take the latter
        v = column.field.toUpperCase();
      }
      let index = this.customizedColumnDefs.findIndex(c_def => v && c_def.label.toUpperCase() === v);
      if (index >= 0) {
        return true;
      }
      return this.isColumnSelectedByChooser(column);
    }
  
  
  
    /**
     * Check whether a column is part of chooser-selected columns
     */
    isColumnSelectedByChooser(column) {
      if (!column || !this.columnChooserSelectedColumns) {
        return false;
      }
      let v = column.headerName;
      if (!v && column.field) {
        // if headerName is empty but field name is not, take the latter
        v = column.field.toUpperCase();
      }
      let index = this.columnChooserSelectedColumns.findIndex(c_def => v && c_def.label.toUpperCase() === v);
      return index >= 0;
    }
  
    loadGridData() {
      if (!this.inboxService) {
        return;
      }
      if (this.inboxService.showSpinner) {
        this.showSpinner = false;
        console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.loadGridData");
      }
      let objMailBoxHelper = new MailBoxHelper();
      //C153176-4796 reset check-before-load flag as it has been consumed by the caller of this method.
      this.inboxService.checkBeforeLoad = false;
      this.dataCache.requestGridData();
    }
  
    executeGridFilterQuery(query) {
      var hardcodedFilter = query;
      //Filter JSON Example 
      //{
      // openUsers: ["Ireland"],
      // age: {
      //   type: "lessThan",
      //   filter: "30"
      // },
      //  age: {
      //   type: "greaterThan",
      //   filter: "25"
      // },
      //};
      this.gridApi.setFilterModel(hardcodedFilter);
      this.gridApi.onFilterChanged();
    }
  
    removeDeletedDraftsById() {
      this.gridUpdateService.getdeletedDraftIds().subscribe(delDraftIds => {
        try {
          if (delDraftIds && delDraftIds.length > 0 && delDraftIds[0] != 0) {
            let deletedDrafts = new Array();
            delDraftIds.forEach(delDraftId => {
              this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === delDraftId);
              if (this.inquiryIndex > -1) {
                deletedDrafts.push(this.rowData[this.inquiryIndex]);
  
                this.rowData.splice(this.inquiryIndex, 1);
                this.gridApi.setRowData(this.rowData);
              }
            });
            //this.gridApi.updateRowData({ remove: this.rowData[this.inquiryIndex] });
  
          }
        }
        catch (exec) {
          console.log(exec);
        }
      });
  
    }
  
    // Function used to mark inquiry as unread(bold)
    updateMailboxForUnread() {
      this.gridUpdateService.getunreadInqIds().subscribe(inq => {
        try {
          if (inq && inq.length > 0 && inq[0].inqId != 0) {
            inq.forEach(inq => {
              const rows = [];
              this.gridApi.forEachNode(function (node) {
                if (node.data._id === inq.inqId && node.data.workflows[0].assignedGroupId === inq.agrpId) {
                  rows.push(node);
                }
              });
              let readById = this.loginUserInfo.userId;
              rows.forEach((rData, key) => {
                if (rData.data._id === inq.inqId) {
                  rData.data.readBy = [];
                }
                this.IncrementUnReadCount(inq);
              });
              if (this.gridApi) {
                if (rows) {
                  this.gridApi.redrawRows({ rowNodes: rows });
                }
              }
            });
          }
        } catch (exec) {
          console.log(exec)
        }
      })
    }
  
    /**
     * C153176-4660: handle single conversation history case
     */
    getSingleConvData(inq, convRowData, tabName) {
      if (!this.rowData || !convRowData || convRowData.length !== 1) {
        // skip non-single row data cases
        return '';
      }
      let singleRow = convRowData[0];
      let previousConv = this.colDefsService.getPrevConv();
      if (previousConv.length === 0 || singleRow.id !== previousConv[0]['id']) {
        singleRow.convRowIndex = 0;
        singleRow.tabName = tabName;
        let currentConvData = {
          'conv': singleRow,
          'data': this.rowData,
          'parentGridData': inq
        }
        return currentConvData;
      }
      return '';
    }
  
    handleOpenConv(inq, convRowData, tabName) {
      if (inq && this.gridApi) {
        this.gridApi.forEachNode(gridRow => {
          if (inq._id !== gridRow.data._id ||
            inq.workflows[0].assignedGroupId !== gridRow.data.workflows[0].assignedGroupId ||
            inq.openGroups !== gridRow.data.openGroups) {
            gridRow.setExpanded(false);
            if (gridRow.isSelected()) {
              gridRow.setSelected(false);
            }
          } else {
            if (!gridRow.isSelected()) {
              this.selectOpenConvInquiryNode(gridRow, true);
            }
            let convData = this.getSingleConvData(inq, convRowData, tabName);
            this.readPrevConv(convData, true);
          }
        });
      }
    }
  
    getConvData() {
      if (this.isMobile) { // added for mobile performance
        return;
      }
      this.colDefsService.getOpenConvData().subscribe(data => {
        try {
          this.handleOpenConv(data.inquiry, data.convRowData, data.tabName);
        } catch (exec) {
          console.log("Failed in handling open conv"); // do not print exception
          setTimeout(() => {
            console.log("Retrying to handling open conv")
            this.handleOpenConv(data.inquiry, data.convRowData, data.tabName);
          }, 0);
        }
      });
      this.colDefsService.getCloseConvData().subscribe(conv => {
        try {
          if (conv && this.gridApi) {
            this.gridApi.forEachNode(gridRow => {
              if (conv._id === gridRow.data._id && conv.workflows[0].assignedGroupId === gridRow.data.workflows[0].assignedGroupId) {
                console.log('agGrid: set expanded to false on ' + conv._id + "_" + conv.workflows[0].assignedGroupId);
                gridRow.setExpanded(false);
                if (gridRow.isSelected()) {
                  gridRow.setSelected(false);
                }
              }
            });
          }
        } catch (exc) {
          console.log(exc)
        }
      });
    }
  
    // Function used to mark inquiry as read(unbold)
    updateMailboxForRead() {
      this.gridUpdateService.getreadInqIds().subscribe(inq => {
        try {
  
          if (inq && inq.length > 0 && inq[0].inqId != 0) {
            inq.forEach(inq => {
              let openGroups = this.loginUserInfo.myGroups.find(mg => mg.id === inq.agrpId).groupName;
              this.DecrementUnReadCount({ id: inq.inqId, openGroups: openGroups })
              const rows = [];
              if (this.gridApi) {
                this.gridApi.forEachNode(function (node) {
                  if (node.data._id === inq.inqId && node.data.workflows[0].assignedGroupId === inq.agrpId) {
                    rows.push(node);
                  }
                });
              }
              if (rows && rows.length > 0) {
                rows.forEach((rData, key) => {
                  // null check
                  if (!rData.data) {
                    return;
                  }
                  if (rData.data.readBy) {
                    rData.data.readBy.push(this.loginUserInfo.userId);
                  } else {
                    const readBy = new Array();
                    readBy.push(this.loginUserInfo.userId);
                    rData.data.readBy = readBy;
                  }
                });
              }
              if (this.gridApi) {
                if (rows) {
                  setTimeout(() => {
                    try {
                      let prevfocusedCell = this.gridApi.getFocusedCell();
                      this.gridApi.redrawRows({ rowNodes: rows });
                      this.setFocusOnFirstCellOfSelectedRow(prevfocusedCell);
                    } catch (err) { // C153176-4614: handle refresh exception with retry
                      setTimeout(() => {
                        this.gridApi.redrawRows({ rowNodes: rows });
                      }, 10);
                    }
                  }, 10);
                }
              }
            });
          }
  
        }
        catch (exec) {
          console.log(exec)
        }
      })
    }
  
    updateMailboxForDrafts() {
      if (this.strMailboxView == "DRAFTS") {
        this.newMessageService.getInsertedDraftIds().subscribe(inq => {
          try {
            if (this.strMailboxView == "DRAFTS") {
              if (inq && inq.draft && inq.draft.id >= 0) {
                let index = this.rowData.findIndex(row => row._id === inq.draft.id);
                if (index >= 0) {
                  this.updateRowDataForDraft(index, inq.draft);
                } else {
                  this.rowData.unshift(this.convertNewDraft(inq.draft));
                }
                // C153176-5031: null check
                if (this.gridApi) {
                  this.gridApi.setRowData(this.rowData);
                }
              }
              // begin below code is for schd for later msg save id drafts
              //Jira - 4458 - Download excel correction done 
              if (inq && inq.entity && inq.entity.draft && inq.entity.draft.id >= 0) {
                // C153176-5031: add null check
                let index = this.rowData ? this.rowData.findIndex(row => row._id === inq.entity.draft.id) : -1;
                if (index >= 0) {
                  this.updateRowDataForDraft(index, inq.entity.draft);
                } else if (this.rowData) { // C153176-5031 null check
                  this.rowData.unshift(this.convertNewDraft(inq.entity.draft));
                }
                // C153176-5031: null check
                if (this.gridApi) {
                  this.gridApi.setRowData(this.rowData);
                }
              }
              // end 
            }
          }
          catch (exec) {
            console.log(exec)
          }
        })
      }
    }
  
    removeSentMailFromDrafts() {
      if (this.strMailboxView == "DRAFTS") {
        this.newMessageService.getsentDraftId().subscribe(sentDraftId => {
          if (sentDraftId && sentDraftId > 0) {
            const draftIndex = this.rowData.findIndex(irowData => irowData._id === sentDraftId);
            this.rowData.splice(draftIndex, 1);
            this.gridApi.setRowData(this.rowData);
          }
  
        })
      }
    }
  
    updateMailBoxFromWebSocket(socketData: any) {
      try {
        if (!this.rowData) {
          // the grid data is not ready, do nothing and return
          return;
        }
        // C153176-4798: reset acceptedByMyView
        this.acceptedByMyView = false;
  
        let redrawRows = new Array();
        socketData.forEach(inq => {
          // this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == inq.workflows[0].assignedGroupId);
          //TODO unniqueness is failing when multiple inquiries with same ID. assignedGroupId is comiing different on workflows[0] index
  
          //Optimosation purpose search in first100 inqs and then in remaining
          let firstHundredInq = this.rowData.slice(0, 100)
          this.inquiryIndex = firstHundredInq.findIndex(irowData => irowData._id === inq._id);
          //Serch in remaining inquiries
          if (this.inquiryIndex == -1) {
            this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id);
          }
          if ((inq.action === "Reopen" && this.strMailboxView === "INBOX" &&
            inq.workflows.filter(wf => wf.action === "Reopen" && wf.direction === "IN").length) ||
              /* Fix for JIRA C153176-4813*/ inq.action === "Resolve" && this.strMailboxView === "RESOLVED") {
            // C153176-5081: no changes here, valid to set inquiryIndex in case of RESOLVED
            this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && inq.openGroups === irowData.openGroups);
            // this.webSocketInsertNewInquiryToOpenGrid(this.strMailboxView, inq);
            let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
            // to update opengroups of reopened inqs
            let openedGrps = inq.workflows.filter(wf => wf.action === "Reopen" && wf.direction === "IN");
            // remove existing Open grps of inqs
            if (openedGrps && openedGrps.length > 0) {
              inq.openGroups = "";
              openedGrps.forEach(og => {
                if (inq.openGroups.length > 0) {
                  inq.openGroups = inq.openGroups + ";" + og.assignedGroupName;
                } else {
                  inq.openGroups = inq.openGroups + og.assignedGroupName;
                }
  
              })
            }
          }
  
  
  
          if (this.inquiryIndex == -1) {
            if (this.strMailboxView !== "DRAFTS") {
              // C153176-4673: if the inquiry index is not found but page 1 is not present (purged), skip the websocket
              if (this.dataCache && this.dataCache.startPage > 1) {
                console.log('Skip handling websocket inquiry, it will be picked up when user scrolls to first page, inq._id=', inq._id);
                return;
              }
              // C153176-4798: filter myView criteria if applicable
              let filterResult = this.filterByCriteria(inq); // C153176-5309 - filter protocol change
              if (filterResult && filterResult.result) {
                this.webSocketInsertNewInquiryToOpenGrid(this.strMailboxView, filterResult.inq);
              }
              // 4466 - Schedule for later - remove msg from drafts grid after msg is scheduled. 
              // Then selection should also change from scheduled msg.  
            } else if (this.strMailboxView === "DRAFTS") {
              if (inq.draftId) {
                let draftIndex = this.rowData.findIndex(irowData => irowData._id === inq.draftId);
                if (draftIndex > -1) {
                  this.rowData.splice(draftIndex, 1);
                  this.gridApi.setRowData(this.rowData);
                }
              }
              this.inboxStateChanged(null);
              this.selectedMail = null;
              this.inqData = null; // C153176-4660 reset request
              this.assignedGroup = null;
              this.selectedInqId = null;
              this.selectedConvId = null;
              this.inquiryWorkflow = null;
            }
  
          } else {
            //performance change search in first 100
            this.inquiryIndex = firstHundredInq.findIndex(irowData => irowData._id === inq._id);
            //If inq id is same but assignedGroupId is diff add another row with same ID
            if (this.inquiryIndex == -1) {
              this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == inq.workflows[0].assignedGroupId);
            }
            if (this.inquiryIndex == -1) {
              this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id);
            }
            if (this.inquiryIndex == -1) {
              // C153176-4673: if the inquiry index is not found but page 1 is not present (purged), skip the websocket
              if (this.dataCache && this.dataCache.startPage > 1) {
                console.log('Skip handling websocket inquiry, it will be picked up when user scrolls to first page, inq._id=', inq._id);
                return;
              }
              // C153176-4798: filter myView criteria if applicable
              let filterResult = this.filterByCriteria(inq); // C153176-5309 - filter protocol change
              if (filterResult && filterResult.result) {
                //Format Date as per profile
                this.tranformDateWithProfile(filterResult.inq);
                this.addItemsAtTop(filterResult.inq);
              }
            }
  
            //update Existinf Inquiry
  
            else {
  
  
              // C153176-4798: filter myView criteria if applicable
              let filterResult = this.filterByCriteria(inq); // C153176-5309 - filter protocol change
              if (filterResult && filterResult.result) {
                this.updateExistingInq(this.inquiryIndex, filterResult.inq, firstHundredInq);
  
              }
              //For Reject and Approve and deescalte Scenario remove only from pending approval Grid
              this.webSocketRemoveInquiryFromOpenGrid(this.strMailboxView, inq);
              // snoozed multigroup insert Inq
              this.insertUnsnoozedInq(inq);
              this.processReplyAllResolve(this.strMailboxView, inq);
            }
            // Performance improvement change
            //this.gridApi.redrawRows();
            //this.refreshGridLeftToRight();
          }
  
  
        });
        // C153176-4925: notify mobile mailbox of rowdata update
        if (this.isMobile) {
          this.inboxService.notifyGridDataUpdate(this.strMailboxView);
        }
      }
      catch (exec) {
        console.log(exec);
      }
    }
    redrawUpdatedRows(updatedRowIndex) {
      var rows = [];
      var row = this.gridApi.getDisplayedRowAtIndex(updatedRowIndex);
      rows.push(row);
      this.gridApi.redrawRows({ rowNodes: rows });
    }
    webSocketRemoveInquiryFromOpenGrid(_openedTab, inq) {
      let openedTab = this.normalizeTabName(_openedTab);
      this.processNonInqWsUpdate(inq);
      switch (openedTab) {
  
        case "PENDING APPROVAL": {
          if (!inq.direction) {
            let removeFromGrid = inq.workflows.findIndex(wf => wf.direction == "PENDINGAPPROVAL" || wf.direction == "PND_REAGE" || wf.direction == "NOMINATE_OWNERSHIP");
            if (removeFromGrid == -1) {
              this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id);
              if (this.inquiryIndex > -1) {
                this.rowData.splice(this.inquiryIndex, 1);
                this.gridApi.setRowData(this.rowData);
  
                // C153176-4482 | Reset the conversation view once the inquiry is approved
                this.resetConversationView();
              }
            }
          }
          break;
        }
  
        case "INBOX": {
          if (!inq.direction) {
            let removeFromGrid = inq.workflows.findIndex(wf => wf.direction == "IN");
  
            if (removeFromGrid == -1) {
  
              this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id);
              if (this.inquiryIndex > -1) {
                /*  this.rowData.splice(this.inquiryIndex, 1);
                 this.gridApi.setRowData(this.rowData); */
                let nodeToDelete = [];
                this.gridApi.forEachNode(function (node) {
                  if (inq._id === node.data._id) {
                    nodeToDelete.push(node.data)
                  }
                });
                if (nodeToDelete.length > 0) {
                  // C153176-5567 Inquiry should be remove from escalation :client chaser
                  this.removeInqFromAgGrid(nodeToDelete);
                }
                // C153176-5567 Inquiry should be remove from escalation :client chaser
                this.inlineReplySendData = true;
                return;
  
              }
            }
            // remove resolved inq from inbox in case of take ownership
            const isInqResolved = inq.workflows.filter(wf => (wf.action === "Resolve By Other Group Ownership" && wf.direction === "IN"));
  
  
            // remove resolved Inq from Inbox 
            // JIRA C153176-4596 Start
            if ((inq && inq.action && inq.action == "Resolve") ||
            /* Fix for JIRA C153176-4629 Start*/(inq && inq.action && inq.action == "Reply Resolve") ||
              (inq && inq.action && inq.action == "ReplyAll Resolve") /* Fix for JIRA C153176-4629 End*/ || isInqResolved.length > 0) {
              // for multi group inquiry
              const multigrpInqWf = inq.workflows.filter(wf => (wf.action === "Resolve" && wf.direction === "IN")
                || /* Fix for JIRA C153176-4629 Start*/ (wf.action === "Reply Resolve" && wf.direction === "IN") ||
                (wf.action === "ReplyAll Resolve" && wf.direction === "IN") /* Fix for JIRA C153176-4629 End*/
                || (wf.action === "Resolve By Other Group Ownership" && wf.direction === "IN")
              );
              let nodeToDelete = [];
              multigrpInqWf.forEach(matchinqWf => {
                this.gridApi.forEachNode(function (node) {
                  let isMatchingDirExists = node.data.workflows.filter(wf => wf.direction === "IN");
                  if (isMatchingDirExists) {
                    if (inq._id === node.data._id && node.data.openGroups === matchinqWf.assignedGroupName) {
                      nodeToDelete.push(node.data)
  
                    }
                  }
                });
              });
              // remove resolved inqs from inbox
              if (nodeToDelete.length > 0) {
                let res = this.gridApi.updateRowData({ remove: nodeToDelete });
                // Remove the conversation if Selected Inquiry Id is resolved              
                nodeToDelete.forEach(deletedInquiry => {
                  if (deletedInquiry._id === this.selectedInqId && deletedInquiry.openGroups === this.assignedGroup) {
                    this.resetConversationView();
                  }
                });
              }
              // after removing Inq from grid remove it from row data.
              if (nodeToDelete.length > 0) {
                this.removeInqFromRowData(nodeToDelete);
              }
              // JIRA C153176-4596 End
              // C153176-5681 insert reassigned inq in INBOX inquiry is not updating in inbox if user resolve by reply/reply All
              let insertInGrid = inq.workflows.filter(wf => wf.direction == "IN" && wf.status === "Open");
              if (insertInGrid) {
                let insertedinqs = 0;
                insertInGrid.forEach(wf => {
                  let isInqExists = this.rowData.findIndex(irowData => irowData._id === inq._id &&
                    irowData.openGroups === wf.assignedGroupName);
                  if (isInqExists === -1) {
                    insertedinqs++;
                    const inqToInsert = Object.assign({}, inq);
                    inqToInsert.openGroups = wf.assignedGroupName;
                    this.updateInqByWf(inqToInsert);
                    this.addItemsAtTop(inqToInsert);
                  }
  
                });
                // add mod by logic here to avoid change selection for other user in group
                if (insertedinqs > 0 && inq.latestUserId && this.loginUserInfo.userId === inq.latestUserId) {
  
                  this.inboxStateChanged(null);
                }
              }
            }
  
  
            /* this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id);
            if (this.inquiryIndex > -1) {
              this.rowData.splice(this.inquiryIndex, 1);
              this.gridApi.setRowData(this.rowData);
            } */
            // Remove Snoozed inquiries from inbox 
            if (inq) {
  
              let nodeToDelete = [];
              const multigrpInqWfSnoozed = inq.workflows.filter(wf => (wf.action === "Snooze" && wf.direction === "IN"));
              multigrpInqWfSnoozed.forEach(matchinqWf => {
                this.gridApi.forEachNode(function (node) {
                  if (inq._id === node.data._id && node.data.openGroups === matchinqWf.assignedGroupName) {
                    nodeToDelete.push(node.data);
  
                  }
  
                });
              });
              // remove unsnoozed inqs from inbox folder
              if (nodeToDelete.length > 0) {
                let res = this.gridApi.updateRowData({ remove: nodeToDelete });
              }
              // Remove the conversation if Selected Inquiry Id is resolved
              nodeToDelete.forEach(deletedInquiry => {
                // remove from rowdata  
                let inquiryIndextoDelete = this.rowData.findIndex(irowData => irowData._id === deletedInquiry._id &&
                  irowData.openGroups === deletedInquiry.openGroups);
                this.rowData.splice(inquiryIndextoDelete, 1);
  
                // to reset conversation view  
                if (deletedInquiry._id === this.selectedInqId && deletedInquiry.openGroups === this.assignedGroup) {
                  this.resetConversationView();
                }
              });
            }
  
  
          }
          // Fix for JIRA C153176-4480 Start
          // Remove Snoozed inquiries from inbox 
          if (inq) {
  
            let nodeToDelete = [];
            const multigrpInqWfSnoozed = inq.workflows.filter(wf => (wf.action === "Snooze" && wf.direction === "IN"));
            multigrpInqWfSnoozed.forEach(matchinqWf => {
              this.gridApi.forEachNode(function (node) {
                if (inq._id === node.data._id && node.data.openGroups === matchinqWf.assignedGroupName) {
                  nodeToDelete.push(node.data);
  
                }
  
              });
            });
            // remove unsnoozed inqs from inbox folder
            let res = this.gridApi.updateRowData({ remove: nodeToDelete });
  
            // Remove the conversation if Selected Inquiry Id is resolved
            nodeToDelete.forEach(deletedInquiry => {
              // remove from rowdata  
              let inquiryIndextoDelete = this.rowData.findIndex(irowData => irowData._id === deletedInquiry._id &&
                irowData.openGroups === deletedInquiry.openGroups);
              this.rowData.splice(inquiryIndextoDelete, 1);
  
              // to reset conversation view  
              if (deletedInquiry._id === this.selectedInqId && deletedInquiry.openGroups === this.assignedGroup) {
                this.resetConversationView();
              }
            });
          }
          // Fix for JIRA C153176-4480 End
  
  
          break;
        }
        case "SNOOZED": {
  
  
          // Remove UnSnoozed inquiries 
          if (inq) {
            // C153176-5651  Snooze- Inquiry not moving from snooze folder if user replied on it
            this.updateOpenSnoozTab(inq);
            let nodeToDelete = [];
            const multigrpInqWfSnoozed = inq.workflows.filter(wf => (wf.action === "UnSnooze" && wf.direction === "IN") ||
              (wf.action === "Un-Snooze" && wf.direction === "IN"));
            multigrpInqWfSnoozed.forEach(matchinqWf => {
              this.gridApi.forEachNode(function (node) {
                if (inq._id === node.data._id && node.data.openGroups === matchinqWf.assignedGroupName) {
                  nodeToDelete.push(node.data)
                }
  
              });
            });
            // remove unsnoozed inqs from snooz folder
            if (nodeToDelete.length > 0) {
              // fix for (C153176-4480) Snooze- inquiry Notification not coming after Snooze
              const selectedGridRow = this.gridApi.getSelectedRows();
              let res = this.gridApi.updateRowData({ remove: nodeToDelete });
              nodeToDelete.forEach(deletedInquiry => {
                // remove from rowdata  
                let inquiryIndextoDelete = this.rowData.findIndex(irowData => irowData._id === deletedInquiry._id &&
                  irowData.openGroups === deletedInquiry.openGroups);
                this.rowData.splice(inquiryIndextoDelete, 1);
  
                // to reset conversation view  
                if (deletedInquiry._id === this.selectedInqId && deletedInquiry.openGroups === this.assignedGroup) {
                  this.resetConversationView();
                }
              });
              // fix for (C153176-4480) Snooze- inquiry Notification not coming after Snooze
              if (selectedGridRow && selectedGridRow.length) {
                const deletedRow = selectedGridRow.filter(row => nodeToDelete.some(node => node && node._id === row._id));
                if (deletedRow && deletedRow.length === selectedGridRow.length) {
                  // select row 0
                  let rowNode = this.gridApi.getDisplayedRowAtIndex(0);
                  if (rowNode) {
                    rowNode.setSelected(true);
                  }
                }
              }
  
            }
  
  
  
          }
          break;
        }
        case "RESOLVED": {
          if (inq) {
            // C153176-5081: no changes on removal logic. finalUILogic not applicable immediately here
            let nodeToDelete = [];
            const multigrpInqWfSnoozed = inq.workflows.filter(wf => (wf.status === "Open"));
            multigrpInqWfSnoozed.forEach(matchinqWf => {
              this.gridApi.forEachNode(function (node) {
                if (inq._id === node.data._id && node.data.openGroups === matchinqWf.assignedGroupName) {
                  nodeToDelete.push(node.data);
                }
  
              });
            });
  
            if (nodeToDelete.length > 0) {
              const selectedGridRow = this.gridApi.getSelectedRows();
              let res = this.gridApi.updateRowData({ remove: nodeToDelete });
              nodeToDelete.forEach(deletedInquiry => {
                // remove from rowdata  
                let inquiryIndextoDelete = this.rowData.findIndex(irowData => irowData._id === deletedInquiry._id &&
                  irowData.openGroups === deletedInquiry.openGroups);
                this.rowData.splice(inquiryIndextoDelete, 1);
  
                // to reset conversation view  
                if (deletedInquiry._id === this.selectedInqId && deletedInquiry.openGroups === this.assignedGroup) {
                  this.resetConversationView();
                }
              });
              // C153176-4639: check whether any row deleted is currently selected. If yes, select row 0 since this one will be removed.
              if (selectedGridRow && selectedGridRow.length) {
                const deletedRow = selectedGridRow.filter(row => nodeToDelete.some(node => node && node._id === row._id));
                if (deletedRow && deletedRow.length === selectedGridRow.length) {
                  // select row 0
                  let rowNode = this.gridApi.getDisplayedRowAtIndex(0);
                  if (rowNode) {
                    rowNode.setSelected(true);
                  }
                }
              }
            }
          }
          break;
        }
        case "UNASSIGNED": {
          if (inq) {
            // remove assigned inquiries from unassigned folder Fix for C153176-4921
            let assignedinqs = inq.workflows.filter(wf => wf.assignedUserName && wf.direction === "IN");
            let nodeToDelete = [];
            assignedinqs.forEach(matchinqWf => {
              this.gridApi.forEachNode(function (node) {
                if (inq._id === node.data._id && node.data.openGroups === matchinqWf.assignedGroupName) {
                  nodeToDelete.push(node.data);
                }
  
              });
            });
            // remove unassigned inqs from ag grid and rowdata Fix for C153176-4921
            this.removeInqFromAgGrid(nodeToDelete);
          }
          break;
        }
  
        case "ASSIGNED TO ME": {
          // remove notAssignedToMeinqs inquiries from assigned to me folder Fix for C153176-4921
          let notAssignedToMeinqs = inq.workflows.filter(wf => wf.assignedUserId !== this.loginUserInfo.userId && wf.direction === "IN");
          let nodeToDelete = [];
          notAssignedToMeinqs.forEach(matchinqWf => {
            this.gridApi.forEachNode(function (node) {
              if (inq._id === node.data._id && node.data.openGroups === matchinqWf.assignedGroupName) {
                nodeToDelete.push(node.data);
              }
            });
          });
          // remove notAssignedToMeinqs from ag grid and rowdata Fix for C153176-4921
          this.removeInqFromAgGrid(nodeToDelete);
          // remove snoozed Inq
          this.removeSnoozedInq(inq);
          break;
        }
  
        default: {
          // C153176-5567 Inquiry should be remove from escalation :client chaser
          this.updateEscalationFolder(inq);
          this.processNonInqWsUpdate(inq);
          break;
        }
      }
      // C153176-4952: all escalation types
      if (AppUtils.isEscalationType(openedTab)) {
        if (!inq.direction) {
          var isDeescalated = inq.workflows.findIndex(wf => wf.action == "De-escalate");
          if (isDeescalated > -1) {
            this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id);
            if (this.inquiryIndex > -1) {
              this.rowData.splice(this.inquiryIndex, 1);
              this.gridApi.setRowData(this.rowData);
              this.resetConversationView();
            }
          }
        }
      }
    }
  
    getWorkflowStatus(inq: any) {
      let wfStatus = {
        isPendingApprovalReage: false
      }
  
      inq.workflows.forEach(wsWf => {
        if (wsWf.workflowStatus && wsWf.workflowStatus == "Pending Approval - Re-age Inquiry") {
          wfStatus.isPendingApprovalReage = true;
        }
      })
      return wfStatus;
    }
  
    webSocketInsertNewInquiryToOpenGrid(_openedTab, inq) {
      //to add cheveron as first column for webSocket Inq
      inq.cheveron = "";
      let inqAddedAtTop = false;
      inq.checkbox = "";
      inq.cheveron_1 = "";
      // fix for C153176-5406 Standardize external email "From" so it always shows client name first
      inq.latestUserName = this.formatDisplayName(inq.latestUserName, inq.latestEmail);
      this.setClientCategoryForWorkflows(inq);
      //To Format date as per Profile Settings
      /* if (!this.isNull(inq.crtDate))
        inq.crtDate.$date = Object(this.dateFormatPipe.transform(inq.crtDate.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.modDate))
        inq.modDate.$date = Object(this.dateFormatPipe.transform(inq.modDate.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.reAgeDate))
        inq.reAgeDate.$date = Object(this.dateFormatPipe.transform(inq.reAgeDate.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.lockedDate))
        inq.lockedDate.$date = Object(this.dateFormatPipe.transform(inq.lockedDate.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.lastActionTime))
        inq.lastActionTime.$date = Object(this.dateFormatPipe.transform(inq.lastActionTime.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.reOpenDate))
        inq.reOpenDate.$date = Object(this.dateFormatPipe.transform(inq.reOpenDate.$date, this.guserPrefDateFormat)); */
      let openedTab = this.normalizeTabName(_openedTab);
      switch (openedTab) {
        case "INBOX": {
          this.updateWfForNonInq(inq)
          if (this.isCurrentTabOpenInqByGrp()) {
            this.processMatchedGroup(inq);
          }
          if (this.isCurrentTabOpenMacthCriterian(QmaConstant.QMA_OPEN_INQ_BYREQUESTTYPE)) {
            this.processRequestType(inq)
          }
  
  
          if (!inq.direction) {
            let isResolvedByTO = inq.workflows.findIndex(wf => wf.direction === "IN" && wf.status === "Resolved");
            if (isResolvedByTO > -1) {
              this.addItemsAtTopINBOXForNominateOwnership(inq);
              inqAddedAtTop = true;
              return;
            }
  
  
            let insertInGrid = inq.workflows.findIndex(wf => wf.direction === "IN" && wf.status === "Open");
            var BreakException = {};
            try {
              // add null check
              if (inq.openGroups && inq.openGroups.includes(";")) {
                let assignedGrp = inq.openGroups.split(";");
                for (let i = 0; i < assignedGrp.length; i++) {
                  const inqToInsert = Object.assign({}, inq);
  
                  inqToInsert.openGroups = assignedGrp[i];
                  // update Assigned to by Wf
                  inqToInsert.openUsers = this.updateAssignedTo(inqToInsert);
                  //Hanging down grid Need alternate
                  /* this.rowData.unshift(inqToInsert);
                  this.gridApi.setRowData(this.rowData); */
                  // fix for JIRA C153176-4568
                  // check to restrict insertion of inquiry if logged in user is not part of new inq assigned group
                  if (this.isLoggedInUserConsistAssignedGrp(inqToInsert)) {
                    this.updateInqByWf(inqToInsert);
                    if (this.updateInqWorkflow(inqToInsert)) {
                      // C153176-5309: only if workflow has been set will the inquiry be added to mailbox
                      this.addItemsAtTop(inqToInsert);
                    }
                    inqAddedAtTop = true;
                  }
                }
              }
              else {
                if (insertInGrid > -1) {
                  //Hanging down grid  Need alternate
                  /*  this.rowData.unshift(inq);
                   this.gridApi.setRowData(this.rowData); */
                  // fix for JIRA C153176-4568   
                  // check to restrict insertion of inquiry if logged in user is not part of new inq assigned group
                  if (this.isLoggedInUserConsistAssignedGrp(inq)) {
                    this.updateInqByWf(inq);
                    inq.openUsers = this.updateAssignedTo(inq);
                    this.addItemsAtTop(inq);
                    inqAddedAtTop = true;
                  }
                }
              }
            }
            catch (e) {
              if (e !== BreakException) throw e;
            }
          }
          this.processNonInqWsUpdate(inq);
          break;
        }
        case "SENT": {
          if (!inq.direction) {
            let insertInGrid = inq.workflows.filter(wf => wf.direction == "OUT");
            if (insertInGrid) {
              insertInGrid.forEach(wf => {
                const inqToInsert = Object.assign({}, inq);
                inqToInsert.openGroups = wf.assignedGroupName;
                this.updateInqByWf(inqToInsert);
                this.addItemsAtTop(inqToInsert);
              });
            }
          }
          break;
        }
        case "PENDING APPROVAL": {
          if (!inq.direction) {
            let insertInGrid = inq.workflows.findIndex(wf => wf.direction == "PENDINGAPPROVAL" || wf.direction == "PND_REAGE");
            if (insertInGrid > -1) {
              /* this.rowData.unshift(inq);
              this.gridApi.setRowData(this.rowData); */
              // fix for C153176-5247 Webbsocket issue for Non Inquiry rules Start
              let matchedWf = inq.workflows.filter(wf => wf.direction == "PENDINGAPPROVAL" || wf.direction == "PND_REAGE");
              // C153176-5665 Re-Age-inquiry is not updating in pending approval if reaged from inbox
              inq.openGroups = "";
              matchedWf.forEach((wf, index) => {
                inq.openGroups += (matchedWf.length > 1 && index < matchedWf.length - 1) ? wf.assignedGroupName + ";" : wf.assignedGroupName;
                // C153176-5665 Re-Age-inquiry is not updating in pending approval if reaged from inbox
                // set mod date of Inq from related workflow
                if (wf && wf.modDate && wf.modDate.$date) {
                  inq.modDate.$date = new Date(wf.modDate.$date).toISOString();
                }
              });
              inq.workflows = [];
              inq.workflows = matchedWf;
              // fix for C153176-5247 Webbsocket issue for Non Inquiry rules End
              this.addItemsAtTop(inq);
              inqAddedAtTop = true;
            } else {
              let insertInGridNominateOwnership = inq.workflows.findIndex(wf => wf.direction == "NOMINATE_OWNERSHIP");
              if (insertInGridNominateOwnership > -1) {
                /* this.rowData.unshift(inq);
                this.gridApi.setRowData(this.rowData); */
                this.addItemsAtTopForNominateOwnership(inq);
                inqAddedAtTop = true;
              }
  
            }
          }
          break;
        }
        case "RESOLVED": {
          // Add resolved inq into resolved folder in case of take ownership
          // fix for C153176-5247 Webbsocket issue for Non Inquiry rules
          const isInqResolved = inq.workflows.filter(wf => ((wf.action === "Resolve By Other Group Ownership" || wf.status === "Resolved") && wf.direction === "IN"));
          if (isInqResolved && isInqResolved.length > 0) {
            inq.openGroups = isInqResolved[0].assignedGroupName;
            inq.openUsers = "";
            // fix for C153176-5390 UI issues
            this.updateInqByWf(inq, isInqResolved[0], "IN", isInqResolved[0]);
          }
          // C153176-5705 Web-socket issue- On Resolve all my Group, all the workflows are not displayed on the Resolved grid via websocket.
          isInqResolved.forEach(wf => {
            let isInqExists = this.rowData.findIndex(irowData => irowData._id === inq._id &&
              irowData.openGroups === wf.assignedGroupName);
            if (isInqExists === -1) {
              const inqToInsert = Object.assign({}, inq);
              inqToInsert.openGroups = wf.assignedGroupName;
              this.updateInqByWf(inqToInsert, null, "IN", wf);
              this.addItemsAtTop(inqToInsert);
            }
            inq.openGroups = wf.assignedGroupName;
          })
  
          // C153176-5081: the inquiry has passed the finalUICriteria filter, no more filtering needed
          // this.addItemsAtTop(inq);
          /* C153176-5081: putting the rest of handling in comment
        if (!inq.direction && inq.action && ((inq.action == "Resolve")
          || (inq.action == "Reply Resolve")
          || (inq.action == "ReplyAll Resolve") ||(isInqResolved.length > 0))) {
          if (inq.action == "Resolve") {// In case of resolve for all groups we have to insert inq for multiple grps based on workflow.
            const multigrpInqWf = inq.workflows.filter(wf => wf.action === "Resolve" && wf.direction === "IN");
            multigrpInqWf.forEach(matchinqWf => {
              inq.assignedGroupName = matchinqWf.assignedGroupName;
              inq.openGroups = matchinqWf.assignedGroupName;
              // Fix for JIRA C153176-4813
              inq.status = matchinqWf.status;
                // to prevent duplicate insertionstatus
                let isInqExists = this.rowData.findIndex(irowData => irowData._id === inq._id && inq.openGroups === irowData.openGroups);
                if (isInqExists === -1) {
              this.addItemsAtTop(inq);
                }
            })
  
          } else {
            this.addItemsAtTop(inq);
          }
  
          inqAddedAtTop = true;
        } else{
        // Fix for JIRA C153176-4714 once other grp accept ownership move inq
        // to resolved folder of orignal grp
        let ResolvedWf = inq.workflows.filter(wf => wf.status === "Resolved");
        if (ResolvedWf.length > 0) {
          inq.assignedGroupName = ResolvedWf[0].assignedGroupName;
          inq.openGroups = ResolvedWf[0].assignedGroupName;
          this.addItemsAtTop(inq)
        }
      }
      */
          break;
        }
        case "POTENTIAL ESCALATIONS": {
          if (!inq.direction && inq.action) {
            //if(inq.action && inq.action != 'New Inquiry') {
            let escalationFlag: boolean = false;
            inq.workflows.forEach(wf => {
              if (wf.direction && wf.direction == 'IN' && this.checkEscalationFlags(wf)) {
                inq.escalationReason = this.calcEsclationreason(wf);
                inq.isEscalated = "Y";
                this.addItemsAtTop(inq);
                inqAddedAtTop = true;
              }
            });
            break;
          }
          break;
        }
        case "SNOOZED": {
          if (!inq.direction && inq.action) {
            inq.workflows.forEach(wf => {
              if (wf.direction && wf.direction == 'IN' && wf.action == 'Snooze') {
                inq.openGroups = wf.assignedGroupName;
                // JIRA  C153176-4878 Snooze - phase 2
                inq.snoozedFromWs = true;
                this.addItemsAtTop(inq);
                inqAddedAtTop = true;
              }
            });
            break;
          }
          break;
        }
        // fix for JIRA C153176-4991 31 Jan Prod Issue - Web socket issue Unassigned and Assigned queues are not updated
        case "ASSIGNED TO ME": {
          inq.workflows.forEach(wf => {
            if (wf.direction && wf.direction == 'IN' && wf.assignedUserId === this.loginUserInfo.userId &&
              wf.status !== "Resolved" /* fix for C153176-5405 An inquiry after resolve was delayed to show up in Assigned to me folder */) {
              const inqToInsert = Object.assign({}, inq);
              inqToInsert.workflows = [];
              inqToInsert.workflows.push(wf);
              this.updateInqByWf(inqToInsert, wf, "IN");
              inqToInsert.openGroups = wf.assignedGroupName;
              this.addItemsAtTop(inqToInsert);
              inqAddedAtTop = true;
            }
          });
          break;
        }
        // fix for JIRA C153176-4991 31 Jan Prod Issue - Web socket issue Unassigned and Assigned queues are not updated
        case "UNASSIGNED": {
  
          inq.workflows.forEach(wf => {
            if (wf.direction && wf.direction == 'IN' && !wf.assignedUserId) {
              if (this.isLoggedInUserConsistAssignedGroup(wf.assignedGroupName)) {
                const inqToInsert = Object.assign({}, inq);
                delete inqToInsert.openUsers;
                inqToInsert.workflows = [];
                /* inqToInsert.workflows=wf; */
                inqToInsert.workflows.push(wf);
                // C153176-5444 [BUG]-Websocket - Assigned to me and Unassigned folder auto refresh not working
                this.updateInqByWf(inqToInsert, wf, "IN", wf);
                // this.updateInqWorkflow(inqToInsert);
                this.addItemsAtTop(inqToInsert);
                inqAddedAtTop = true;
              }
  
            }
          });
  
  
          break;
        }
        // fix for C153176-5247 Webbsocket issue for Non Inquiry rules
        case "NON INQUIRIES": {
          inq.workflows.forEach(wf => {
            let inqIndex = this.rowData.findIndex(rdata => inq._id === rdata._id && rdata.openGroups === wf.assignedGroupName);
            if (inqIndex === -1) {
              if (wf.direction === "IN" && wf.rulesFlag && wf.rulesFlag.markAsNonInquiry) {
                const inqToInsert = Object.assign({}, inq);
                inqToInsert.openGroups = wf.assignedGroupName;
                this.updateInqByWf(inqToInsert);
                this.addItemsAtTop(inqToInsert);
              }
            }
  
          });
          break;
        }
  
        default: {
          this.updateOpenTagsTab(inq);
          this.updateEscalationFolder(inq);
          this.updateCategoryFolder(inq);
  
          break;
        }
        // to keep first inquiry as selected
        /* if (inqAddedAtTop) {
        this.inboxStateChanged(null);
      } */
        // this.inboxStateChanged();
      }
    }
    checkEscalationFlags(wf: any) {
      if ((wf.isConvCountEscalation == 'Y')
        || (wf.isClientChaseEscalation == 'Y')
        || (wf.responseTimeEscalationFlag == 'Y')
        || (wf.isSubjectEscalation == 'Y')
        || (wf.ispendingApprovalEscalation == 'Y')
        || (wf.isManualEscalation == 'Y')
      ) {
        return true;
      }
      return false;
    }
    // old ws
    calcEsclationreason(wf: any) {
      let escalationReason: string = "";
      if (!this.isNull(wf.generalEscalationReason)) {
        escalationReason = wf.generalEscalationReason;
      }
      if (!this.isNull(wf.responseTimeEscalationReason)) {
        escalationReason += wf.responseTimeEscalationReason;
      }
      if (!this.isNull(wf.manualEscalationReason)) {
        escalationReason += wf.manualEscalationReason;
      }
      return escalationReason;
    }
    updateExistingInq(inqIndex: number, inq: any, firstHundredInq: any) {
  
      inq.workflows.forEach(wsWf => {
  
        try {
          //performance change search in first 100
          this.inquiryIndex = firstHundredInq.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
          if (this.inquiryIndex == -1) {
            this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
          }
          if (this.inquiryIndex == -1) {
            this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id);
          }
  
          if (this.inquiryIndex === -1) {
            return;
          }
          //to update Last Action
          let wfForAction = inq.workflows.find(x => x.action)
  
          if (!this.isNull(wfForAction) && !this.isNull(this.rowData[this.inquiryIndex])) { // C153176-5190 null check
            this.rowData[this.inquiryIndex].action = wfForAction.action;
          }
  
          //to update last action date
  
          if (wfForAction && wfForAction.lastActionTime && wfForAction.lastActionTime.$date) {
            let date = Object(this.dateFormatPipe.transform(wfForAction.lastActionTime.$date, this.guserPrefDateFormat));
            this.rowData[this.inquiryIndex].lastActionTime = { "$date": date };
          }
  
  
          //Update for Potential Escalation
          if ((!this.isNull(wsWf.isClientChaseEscalation) && wsWf.isClientChaseEscalation == "Y")) {
            this.rowData[this.inquiryIndex].escalationReason = wsWf.generalEscalationReason;
            this.rowData[this.inquiryIndex].isEscalated = "Y";
          }
          //check if link id exists
          let isLinkIdExists = inq.workflows.findIndex(x => x.linkId > 0)
          //update link id if exists
          if (isLinkIdExists > -1) {
            let linkId = -1;
            inq.workflows.forEach(wsWfInner => {
              if ((!this.isNull(wsWfInner.linkId))) {
                linkId = wsWfInner.linkId;
                this.rowData[this.inquiryIndex].linkId = linkId;
                this.rowData[this.inquiryIndex].workflows[0].linkId = linkId;
              }
            })//remove link id if not exists
          } else {
            this.rowData[this.inquiryIndex].linkId = "";
            this.rowData[this.inquiryIndex].workflows[0].linkId = "";
          }
  
          if ((!this.isNull(wsWf.isConvCountEscalation) && wsWf.isConvCountEscalation == "Y")) {
            this.rowData[this.inquiryIndex].escalationReason = wsWf.generalEscalationReason;
            this.rowData[this.inquiryIndex].isEscalated = "Y";
          }
          if ((!this.isNull(wsWf.responseTimeEscalationFlag) && wsWf.responseTimeEscalationFlag == "Y")) {
            // fix for C153176-4958 Escalation icon tool tip
            this.rowData[this.inquiryIndex].escalationReason = wsWf.responseTimeEscalationReason ? wsWf.responseTimeEscalationReason :
              wsWf.generalEscalationReason;
            this.rowData[this.inquiryIndex].isEscalated = "Y";
          }
          if ((!this.isNull(wsWf.isSubjectEscalation) && wsWf.isSubjectEscalation == "Y")) {
            // fix for C153176-4958 Escalation icon tool tip
            this.rowData[this.inquiryIndex].escalationReason = wsWf.manualEscalationReason ? wsWf.manualEscalationReason
              : wsWf.generalEscalationReason;
            this.rowData[this.inquiryIndex].isEscalated = "Y";
          }
          if ((!this.isNull(wsWf.ispendingApprovalEscalation) && wsWf.ispendingApprovalEscalation == "Y")) {
            this.rowData[this.inquiryIndex].escalationReason = wsWf.responseTimeEscalationReason;
            this.rowData[this.inquiryIndex].isEscalated = "Y";
          }
          if ((!this.isNull(wsWf.isManualEscalation) && wsWf.isManualEscalation == "Y")) {
            this.rowData[this.inquiryIndex].escalationReason = wsWf.responseTimeEscalationReason;
            this.rowData[this.inquiryIndex].isEscalated = "Y";
          }
  
          // Update suggetion indicator NLP
          if (wsWf.suggestionIndicator) {
            let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView) ? this.wfDirectionMailboxViewMap.get(this.strMailboxView) : "IN";
            if (matchingWfExists) {
              let matchingwf = inq.workflows.find(x => x.direction == matchingWfExists && x.assignedGroupName == inq.openGroups);
              if (matchingwf) {
                this.rowData[this.inquiryIndex].suggestionIndicator = matchingwf.suggestionIndicator;
              }
            } else if (this.isDirectionAgnosticView()) { // C153176-4447: refactoring
              // myviews or dashboard inline view or chart view or search result - C153176-4447
              let matchingwf = inq.workflows.find(x => x.assignedGroupName == inq.openGroups);
              if (matchingwf) {
                this.rowData[this.inquiryIndex].suggestionIndicator = matchingwf.suggestionIndicator;
              }
            }
          }
          // Different workflows have different assigned groupids
          if (wsWf.action == "Lock" && wsWf.lock == "Y") {
            //this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
            this.rowData[this.inquiryIndex].lock = "Y";
            this.rowData[this.inquiryIndex].lockedBy = wsWf.lockedBy; // fix for C153176-5462 Websocket -Unlock menu is not available if inquiry is locked
            // this.rowData[this.inquiryIndex].lastActionTime = wsWf.lockedDate.$date;
  
          }
          // Fix for JIRA C153176-4003 Start
          if (wsWf.action == "FollowUp" && wsWf.followUp && wsWf.followUp.flag == "Generic") {
            let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView) ? this.wfDirectionMailboxViewMap.get(this.strMailboxView) : "IN";
            if (matchingWfExists) {
              let matchingwf = inq.workflows.find(x => x.direction == matchingWfExists && x.assignedGroupName ===
                this.rowData[this.inquiryIndex].openGroups && x.action == "FollowUp");
              if (matchingwf) {
                if (wsWf.followUp && wsWf.followUp.flag) {
                  this.rowData[this.inquiryIndex].followUp = "Y";
                }
              }
            } else if (this.isDirectionAgnosticView()) { // C153176-4447: refactoring
              // myviews or dashboard inline view or chart view or search result - C153176-4447
              // In myviews, no matching WF exists on direction, look up without comparing direction
              let matchingwf = inq.workflows.find(x => x.assignedGroupName === this.rowData[this.inquiryIndex].openGroups && x.action == "FollowUp");
              if (matchingwf) {
                if (wsWf.followUp && wsWf.followUp.flag) {
                  this.rowData[this.inquiryIndex].followUp = "Y";
                }
              }
            }
          }
          if (wsWf.action == "RemoveFollowUp") {
            let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView) ? this.wfDirectionMailboxViewMap.get(this.strMailboxView) : "IN";
            if (matchingWfExists) {
              let matchingwf = inq.workflows.find(x => x.direction == matchingWfExists && x.assignedGroupName ===
                this.rowData[this.inquiryIndex].openGroups && x.action == "RemoveFollowUp");
              if (matchingwf) {
                this.rowData[this.inquiryIndex].followUp = null;
              }
            } else if (this.isDirectionAgnosticView()) { // C153176-4447: refactoring
              // myviews or dashboard inline view or chart view or search result - C153176-4447
              // In myviews or dashboard inline or chart view, no matching WF exists on direction, look up without comparing direction
              let matchingwf = inq.workflows.find(x => x.assignedGroupName === this.rowData[this.inquiryIndex].openGroups && x.action == "RemoveFollowUp");
              if (matchingwf) {
                // C153176-4447: no check of followUp flag at unfollowup
                this.rowData[this.inquiryIndex].followUp = null;
              }
            }
          }
          // Fix for JIRA C153176-4003 End
          if (wsWf.action == "Unlock") {
            // this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
            if (this.rowData[this.inquiryIndex].lock) {
              this.rowData[this.inquiryIndex].lock = null;
  
            }
  
          }
  
          if (wsWf.action == "Assign Inquiry Source") {
            let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView) ? this.wfDirectionMailboxViewMap.get(this.strMailboxView) : "IN";
            if (matchingWfExists) {
              let matchingwf = inq.workflows.find(x => x.direction == matchingWfExists && x.assignedGroupName ===
                this.rowData[this.inquiryIndex].openGroups && x.action == "Assign Inquiry Source");
              if (matchingwf) {
                this.rowData[this.inquiryIndex].inquirySource = matchingwf.inquirySource;
              }
            } else if (this.isDirectionAgnosticView()) { // C153176-4447: refactoring
              // myviews or dashboard inline view or chart view or search result - C153176-4447
              let matchingwf = inq.workflows.find(x => x.assignedGroupName ===
                this.rowData[this.inquiryIndex].openGroups && x.action == "Assign Inquiry Source");
              if (matchingwf) {
                this.rowData[this.inquiryIndex].inquirySource = matchingwf.inquirySource;
              }
            }
          }
          if (wsWf.action !== "Lock") {
            // this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
            if (this.rowData[this.inquiryIndex].lastActionTime) {
              //this.rowData[this.inquiryIndex].lastActionTime = wsWf.lastActionTime;
  
            }
  
  
          }
          if (wsWf.action == "Assign Owner") {
            // this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
            //this.rowData[this.inquiryIndex].openUsers = wsWf.assignedUserName;
            //let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
            //if (matchingWfExists) {
            let matchingwf = inq.workflows.find(x => /* x.direction == matchingWfExists && */ x.assignedGroupName ===
              this.rowData[this.inquiryIndex].openGroups && x.action == "Assign Owner");
            if (matchingwf && matchingwf.assignedUserName) {
              this.rowData[this.inquiryIndex].openUsers = matchingwf.assignedUserName.split("[")[0];
            }
            //}
  
          }
          if (wsWf.action == "Take Ownership") {
            //this.rowData[this.inquiryIndex].openUsers = inq.workflows[0].assignedUserName;
            /*  let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
             if (matchingWfExists) { */
            let matchingwf = inq.workflows.find(x => /* x.direction == matchingWfExists && */ x.assignedGroupName ===
              this.rowData[this.inquiryIndex].openGroups && x.action == "Take Ownership");
            if (matchingwf && matchingwf.assignedUserName) {
              this.rowData[this.inquiryIndex].openUsers = matchingwf.assignedUserName.split("[")[0];
            }
            /*  } */
          }
          if (wsWf.action == "Assign Processing Region") {
            //this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
            this.rowData[this.inquiryIndex].processingRegion = wsWf.processingRegion;
  
          }
          if (wsWf.action == "Assign Request Type") {
            //this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
            this.rowData[this.inquiryIndex].requestTypeStr = wsWf.requestType;
          }
          if (wsWf.action == "Self Assign") {
            // this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
            // this.rowData[this.inquiryIndex].openUsers = inq.workflows[0].assignedUserName.split("[")[0];
            /*Start C153176-4624 Mobile (CR) - Snooze */
            let matchingwf = inq.workflows.find(x => /* x.direction == matchingWfExists && */ x.assignedGroupName ===
              this.rowData[this.inquiryIndex].openGroups && x.action == "Self Assign");
            if (matchingwf && matchingwf.assignedUserName) {
              this.rowData[this.inquiryIndex].openUsers = matchingwf.assignedUserName.split("[")[0];
            }
            /*End C153176-4624 Mobile (CR) - Snooze */
          }
          if (wsWf.action == "Assign Tag") {
            // this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
            this.rowData[this.inquiryIndex].tag = wsWf.tag;
            this.rowData[this.inquiryIndex].lastActionby = wsWf.lastActionby;
            //TODO need to display date as per profile settings
            let date = Object(this.dateFormatPipe.transform(wsWf.lastActionTime.$date, this.guserPrefDateFormat));
            this.rowData[this.inquiryIndex].lastActionTime = { "$date": date };
            // remove inq from tags tab if tag changed to someother tag
            this.updateOpenTagsTab(inq);
          }
          if (wsWf.action == "De-escalate") {
            this.rowData[this.inquiryIndex].escalationReason = "";
            this.rowData[this.inquiryIndex].isEscalated = "N";
  
          }
          if (wsWf.action == "Assign GFPID/GFCID") {
            //  this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
            this.rowData.forEach(rowInq => {
              if (rowInq._id == inq._id) {
                rowInq.gfcid = inq.gfcid;
                rowInq.gfcName = inq.gfcName;
                rowInq.gpName = inq.gpName;
                rowInq.gpNum = inq.gpNum;
              }
            });
            this.rowData[this.inquiryIndex].openUsers = wsWf.assignedUserName ? wsWf.assignedUserName.split("[")[0] : undefined;
  
          }
          /* //update NLP if exists
          if (wsWf[0].suggestionIndicator && wsWf[0].suggestionIndicator !="") {
            this.rowData[this.inquiryIndex].suggestionIndicator = wsWf[0].suggestionIndicator;
          } */
          //Pending Approval reAge
          if (wsWf.workflowStatus && wsWf.direction == "PND_REAGE") {
            this.rowData[this.inquiryIndex].workflowStatus = wsWf.workflowStatus;
            if (wsWf.responseTimeEscalationFlag == 'Y') {
              this.rowData[this.inquiryIndex].escalationReason = wsWf.responseTimeEscalationReason;
              this.rowData[this.inquiryIndex].isEscalated = "Y";
            }
          }
          if (inq && inq._id && inq.exceptionId && inq.exceptionId > 0) {
  
            this.rowData.forEach(rowInq => {
              if (rowInq._id == inq._id) {
                rowInq.exceptionId = inq.exceptionId;
              }
            });
  
          }
  
          // this.rowData[this.inquiryIndex].lastActionTime =  wsWf.lastActionTime;
          this.rowData[this.inquiryIndex].lastActionby = wsWf.modBy;
          // C153176-4663: update inquiry version if needed
          this.updateInqVersion(this.rowData[this.inquiryIndex], inq);
  
  
          //remove approved or rejected inquiry from pending approval grid
          if (this.strMailboxView == "PENDING APPROVAL") {
  
            if (wsWf.workflowStatus && wsWf.workflowStatus == "Rejected - Outbound email") {
              this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id);
              if (this.inquiryIndex > -1) {
                this.rowData.splice(this.inquiryIndex, 1);
                this.gridApi.setRowData(this.rowData);
              }
            }
          }
          // JIRA  C153176-4878 Snooze - phase 2
          if (this.strMailboxView == "SNOOZED") {
            let matchingwf = inq.workflows.filter(x => x.direction === "IN" && x.action === "Snooze");
            matchingwf.forEach(mwf => {
              let indexToUpdate = this.getCurrentIndexFromRowData(inq._id, mwf.assignedGroupName);
              this.updateRowData(indexToUpdate, true, "snoozedFromWs");
            });
          }
  
          // JIRA C153176-4596 Start
          // In case of reply/forward/replyall inquiry should come at top
          if ((wsWf.action == "Reply" || wsWf.action == "ReplyAll" || wsWf.action == "Forward" /* || wsWf.action == "New Inquiry" */)
            || (inq.action === "Reply" && wsWf.action === "New Inquiry") || (inq.action === "Forward" && wsWf.action === "New Inquiry")
            || (inq.action === "Reply" && this.shouldMoveInqToTop(wsWf) ||
              // C153176-5664 Inquiry row is not getting updated on self created inquires reply from sentr
              (inq.action === "ReplyAll" && this.shouldMoveInqToTop(wsWf))) // C153176-5635 Any incoming mail from outlook to a respective DL should update the inquiry to the top in the grid
          ) {
            if ((wsWf.direction == "IN" /* && (this.strMailboxView == "INBOX" || this.strMailboxView == "NON INQUIRIES" || this.acceptedByMyView) */) // C153176-4798 support myview with 'acceptedByMyView'
              /* || (wsWf.direction == "IN" && (this.strMailboxView == "POTENTIAL ESCALATIONS" || this.acceptedByMyView)) */) { // C153176-5575 All Mailbox folders, Grid columns to have websocket/autorefresh implemented
              this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id);
              if (this.inquiryIndex > -1 && (this.loginUserInfo.userId === inq.latestUserId ||
                (wsWf.direction == "IN" && (this.strMailboxView == "POTENTIAL ESCALATIONS" || this.acceptedByMyView)))) {//C153176-5287: support myview with 'acceptedByMyView'
  
                if ((this.loginUserInfo.userId === inq.latestUserId ||
                  (wsWf.direction == "IN" && (this.strMailboxView == "POTENTIAL ESCALATIONS" || this.acceptedByMyView)))//C153176-5287: support myview with 'acceptedByMyView'
  
                ) {
  
                  // JIRA C153176-4596  Start  
                  let nodeToDelete = [];
                  let existingInq: any;
                  let matchingWfDir = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
                  this.gridApi.forEachNode(function (node) {
                    let isMatchingDirExists = node.data.workflows.filter(wf => wf.direction === wsWf.direction);
                    if (isMatchingDirExists) {
                      if (inq._id === node.data._id) {
                        nodeToDelete.push(node.data)
                        existingInq = node.data;
                      }
                    }
                  });
                  if (nodeToDelete.length > 0) {
                    // C153176-4456: check one of the nodes has 'expanded' and update inq before removing the existing one
                    const expanded = nodeToDelete.filter(e => e.expanded).length > 0;
                    let updatedInqArray = this.updateExistingInqToMovetotop(inq, existingInq, expanded);
                    // remove existing inq to avoid duplicates
                    let res = this.gridApi.updateRowData({ remove: nodeToDelete });
                    updatedInqArray.forEach(updatedInq => {
                      this.updateInqByWf(updatedInq);
                      /* this.rowData[this.inquiryIndex].subject = inq.subject; */
                      /* this.rowData[this.inquiryIndex].subject = inq.subject; */
                      this.addItemsAtTop(updatedInq);
                    });
                  }
                  // C153176-5600 Email view automatically jumps from one to another
                  // to select first inq of grid
                  if (this.loginUserInfo.userId === inq.latestUserId) {
                    this.inboxStateChanged(null);
                  }
  
                }
  
              }
  
  
              if ((wsWf.direction == "IN" && (this.strMailboxView == "INBOX" || this.acceptedByMyView))  // C153176-4798 support myview with 'acceptedByMyView'
                && this.loginUserInfo.userId !== inq.latestUserId) {
                // remove existing inq to avoid duplicates
                let nodeToDelete = [];
                let existingInq: any;
                let matchingWfDir = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
                this.gridApi.forEachNode(function (node) {
                  let isMatchingDirExists = node.data.workflows.filter(wf => wf.direction === wsWf.direction);
                  if (isMatchingDirExists) {
                    if (inq._id === node.data._id) {
                      nodeToDelete.push(node.data)
                      existingInq = node.data;
                    }
                  }
                });
                if (nodeToDelete.length > 0) {
                  // C153176-4456: check one of the nodes has 'expanded' and update inq before removing the existing one
                  const expanded = nodeToDelete.filter(e => e.expanded).length > 0;
                  let updatedInqArray = this.updateExistingInqToMovetotop(inq, existingInq, expanded);
  
                  // in case where we have to retain selection where someone else in grp replied to same inq
                  const selectedGridRow = this.gridApi.getSelectedRows();
                  const selectedInqs = [];
                  selectedGridRow.forEach(x => {
                    let inq = { 'selectedInqId': x._id, 'assignedGroupName': x.openGroups };
                    selectedInqs.push(inq)
                  });
  
                  var updatedInqHavingSelecion = [];
                  let res = this.gridApi.updateRowData({ remove: nodeToDelete });
                  updatedInqArray.forEach(updatedInq => {
                    this.updateInqByWf(updatedInq);
                    let result = selectedInqs.filter(si => si.selectedInqId === updatedInq._id && si.assignedGroupName === updatedInq.openGroups)
                    if (result.length > 0) {
                      updatedInqHavingSelecion.push(result);
                    }
  
                    /* this.rowData[this.inquiryIndex].subject = inq.subject; */
                    /* this.rowData[this.inquiryIndex].subject = inq.subject; */
                    this.addItemsAtTop(updatedInq);
                  });
  
                  // to restore selection after moving inq on top when someone else replied on same inq
                  if (updatedInqHavingSelecion.length) {
                    const me = this; //Jira - C153176-4876 mail box stats issue
                    this.gridApi.forEachNode(function (node) {
                      let res = updatedInqHavingSelecion[0].find(ui => ui.selectedInqId === node.data._id && ui.assignedGroupName === node.data.openGroups)
                      if (res) {
                        me.selectOpenConvInquiryNode(node, true); //Jira - C153176-4876 mail box stats issue
                      }
                    });
                  }
                }
  
              }
  
  
            } else if ((wsWf.direction == "OUT" && (this.strMailboxView == "SENT" || this.acceptedByMyView))) {  // C153176-4798 support myview with 'acceptedByMyView'
  
              // remove existing inq to avoid duplicates
              let nodeToDelete = [];
              let existingInq: any;
              let matchingWfDir = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
              this.gridApi.forEachNode(function (node) {
                let isMatchingDirExists = node.data.workflows.filter(wf => wf.direction === wsWf.direction);
                if (isMatchingDirExists) {
                  if (inq._id === node.data._id) {
                    nodeToDelete.push(node.data)
                    existingInq = node.data;
                  }
                }
              });
              if (nodeToDelete.length > 0) {
                // C153176-4456: check one of the nodes has 'expanded' and update inq before removing the existing one
                const expanded = nodeToDelete.filter(e => e.expanded).length > 0;
                let updatedInqArray = this.updateExistingInqToMovetotop(inq, existingInq, expanded);
                let res = this.gridApi.updateRowData({ remove: nodeToDelete });
                updatedInqArray.forEach(updatedInq => {
                  this.updateInqByWf(updatedInq);
                  /* this.rowData[this.inquiryIndex].subject = inq.subject; */
                  /* this.rowData[this.inquiryIndex].subject = inq.subject; */
                  this.addItemsAtTop(updatedInq);
                });
              }
            }
  
          }
          // Forward should be at top of inbox for if looged in user forwarded
          if ((wsWf.direction == "IN" && (this.strMailboxView == "INBOX" || this.acceptedByMyView) && inq.action == "Forward") &&  // C153176-4798 support myview with 'acceptedByMyView'
            this.loginUserInfo.userId === inq.latestUserId) {
  
            // fix for JIRA C153176-5057 duplicate email
            // select  forwarded inq from grid to be removed avoid duplication
            /* 
            in case of forward inq assignment is getting changed we have to remove existing inq assigned to old group to avoid duplicates.
            */
            let nodeToDelete = [];
            this.gridApi.forEachNode(function (node) {
              if (inq._id === node.data._id) {
                nodeToDelete.push(node.data);
              }
  
            });
            // remove forwarded inq from grid  avoid duplication
            // fix for JIRA C153176-5057 email got duplicated on forward or reassign
            this.removeInqFromAgGrid(nodeToDelete, true);
  
            // Format Date as per profile
            this.tranformDateWithProfile(inq);
            this.addItemsAtTop(inq);
            // fix for JIRA C153176-5057 email got duplicated on forward or reassign
            // First row selected
            let rowNode = this.gridApi.getDisplayedRowAtIndex(0);
            if (rowNode) {
              rowNode.setSelected(true);
            }
            // remove forwarded inq from grid  avoid duplication
          }
          // fix for JIRA C153176-4441 start
          // to update NLP if suggetion indicator exists
          if (wsWf.suggestionIndicator && wsWf.suggestionIndicator !== "N") {
            let inquiryIndexWithNLP = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId
              == wsWf.assignedGroupId);
            if (inquiryIndexWithNLP !== -1) {
              this.rowData[inquiryIndexWithNLP].suggestionIndicator = wsWf.suggestionIndicator;
              this.refreshGridUpdateRow(inquiryIndexWithNLP);
            }
          }
  
          if (wsWf.action == "Resolve") {
            let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView) ? this.wfDirectionMailboxViewMap.get(this.strMailboxView) : "IN";
            if (matchingWfExists) {
              let matchingwf = inq.workflows.find(x => x.direction == matchingWfExists && x.assignedGroupName ===
                this.rowData[this.inquiryIndex].openGroups && x.action == "Resolve");
              if (matchingwf) {
                this.rowData[this.inquiryIndex].status = "Resolved";
              }
            }
          }
          // update last action By
          wsWf.modBy ? this.rowData[this.inquiryIndex].lastActionby = wsWf.lastActionby : "";
          // fix for JIRA C153176-4441 Ends
          //this.redrawUpdatedRows(this.inquiryIndex)
  
          // update existing Inq by Workflow
          this.updateExistingInqByWf(inq, this.inquiryIndex);
          this.refreshGridUpdateRow(this.inquiryIndex);
          this.CollpaseAllDetailNodes(true);
          this.removeResolvedInquiry(inq);
        }
        catch (exp) {
          console.log(exp);
        }
      }
  
  
      );
  
      // C153176-5120: select first inquiry if nothing is selected
      if (this.gridApi) {
        let selectedRows = this.gridApi.getSelectedRows();
        if (!selectedRows || !selectedRows.length) {
          this.inboxStateChanged(null);
        }
      }
      // to update detail cell renderer secondary columns
      this.gridOprService.setisGridUpdated(true);
    }
    // fix for C153176-4991 31 Jan Prod Issue - Web socket issue Unassigned and Assigned queues are not updated
    // update inq by workflows
    updateInqByWf(inq, inqWf = null, direction = null, matchedWf = null) {
      /* let concernedWf = inq.workflows */
      // fix for C153176-5297 Tabs opened from the dashboard charts shows incorrect data
      if (this.isCurrentTabOpenMacthCriterian(QmaConstant.QMA_OPEN_INQ_BYREQUESTTYPE)) {
        direction = "IN"
      }
      let matchingWfExists = direction ? direction : this.wfDirectionMailboxViewMap.get(this.strMailboxView);
  
      if (matchingWfExists) {
        // fix for C153176-4991 31 Jan Prod Issue - Web socket issue Unassigned and Assigned queues are not updated
        let matchingwf = matchedWf ? matchedWf : inq.workflows.find(x => x.direction == matchingWfExists && x.assignedGroupName == inq.openGroups);
  
        // C153176-4441 | Websocket for NLP Icon
        if (matchingwf && matchingwf.suggestionIndicator) {
          inq.suggestionIndicator = matchingwf.suggestionIndicator;
        }
  
        if (matchingwf && matchingwf.requestType) {
          inq.requestTypeStr = matchingwf.requestType;
        } else { // fix for JIRA C153176-4903
          inq.requestTypeStr = "";
        }
        if (matchingwf && matchingwf.processingRegion) {
          inq.processingRegion = matchingwf.processingRegion;
        }
        if (matchingwf && matchingwf.inquirySource) {
          inq.inquirySource = matchingwf.inquirySource;
        }
        if (matchingwf && matchingwf.rootCause) {
          inq.rootCause = matchingwf.rootCause;
        }
        if (matchingwf && matchingwf.convCount) {
          inq.convCount = matchingwf.convCount;
        }
        // fix for JIRA C153176-4903
        if (matchingwf && matchingwf.tag) {
          inq.tag = matchingwf.tag;
        }
        if (matchingwf && matchingwf.queryCount) {
          inq.queryCount = matchingwf.queryCount;
        }
        // fix for C153176-4991 31 Jan Prod Issue - Web socket issue Unassigned and Assigned queues are not updated
        if (inqWf && inqWf.assignedUserName) {
          inq.openUsers = inqWf.assignedUserName;
        }
        // fix for C153176-5390 UI issues
        if (inqWf && inqWf.resolveTime) {
          inq.resolveTime = inqWf.resolveTime;
        }
        // C153176-4663: update inquiry version if needed
        this.updateInqVersion(inq);
      }
  
  
    }
    // JIRA C153176-4596  Start 
    // update existing inq by workflows
    updateExistingInqByWf(inq, inqIndexToUpdate) {
      /* let concernedWf = inq.workflows */
      let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
  
      if (matchingWfExists) {
        let matchingwf = inq.workflows.find(x => x.direction == matchingWfExists && x.assignedGroupName == inq.openGroups);
        if (matchingwf && matchingwf.requestType) {
          this.rowData[inqIndexToUpdate].requestTypeStr = matchingwf.requestType;
        }
        if (matchingwf && matchingwf.processingRegion) {
          this.rowData[inqIndexToUpdate].processingRegion = matchingwf.processingRegion;
        }
        if (matchingwf && matchingwf.inquirySource) {
          this.rowData[inqIndexToUpdate].inquirySource = matchingwf.inquirySource;
        }
        if (matchingwf && matchingwf.rootCause) {
          this.rowData[inqIndexToUpdate].rootCause = matchingwf.rootCause;
        }
        if (matchingwf && matchingwf.rootCause) {
          this.rowData[inqIndexToUpdate].convCount = matchingwf.convCount;
        }
        if (matchingwf && matchingwf.queryCount) {
          this.rowData[inqIndexToUpdate].queryCount = matchingwf.queryCount;
        }
        // C153176-4663: update inquiry version if needed
        this.updateInqVersion(this.rowData[inqIndexToUpdate], inq);
      }
  
  
    }
    // JIRA C153176-4596  End
    preprocessNewInq(inq) {
      inq.workflows.forEach(wsWf => {
        // fix for JIRA C153176-4991 31 Jan Prod Issue - Web socket issue Unassigned and Assigned queues are not updated
        // null check
        if (!wsWf.action || !wsWf.followUp) {
          return;
        }
        if ((wsWf.action && wsWf.action == "FollowUp" || wsWf.action && wsWf.action == 'New Inquiry') && wsWf.followUp && wsWf.followUp.flag == "Generic") {
          //this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
  
          inq.followUp = "Y";
        }
        // fix for JIRA C153176-4991 31 Jan Prod Issue - Web socket issue Unassigned and Assigned queues are not updated
        if (wsWf.action && wsWf.action == "RemoveFollowUp") {
          //this.inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.workflows[0].assignedGroupId == wsWf.assignedGroupId);
          inq.followUp = null;
  
        }
      });
    }
  
    createViewForDrafts() {
  
  
    }
  
  
    removeExistingItem(key) {
      return AppUtils.removeCachedItem(key);
    }
  
    addNewItem(key, value) {
      try {
        if (AppUtils.getCachedItem(key) === null) {
          AppUtils.cacheItem(key, JSON.stringify(value));
        }
      }
      catch (exp) {
  
      }
    }
  
    getExistingItem(key) {
      return sessionStorage.getItem(key);
    }
  
    onColumnResized(params) {
      // C153176-5675 Column width should also save on click of save column button
      if (params.source === 'uiColumnDragged') {
        this.isDefaultColumns = false;
      }
      let gridNewCol = [];
      let columnState = params.api.gridOptionsWrapper.gridOptions.columnApi.getColumnState();
      let gridCol = this.columnDefs;
      columnState.forEach(element => {
        gridCol.forEach(individualCol => {
          // C153176-4660,4663: handling column id mismatches
          if (this.matchColIdAndField(element.colId, individualCol.field)) {
            individualCol.width = element.width;
            gridNewCol.push(individualCol);
          }
        });
      });
      this.colDefsService.setcolDefs(this.strMailboxView, gridNewCol);
  
      let expandedNode;
      if (this.openedConvHistInq) {
        expandedNode = this.openedConvHistInq.node;
      }
  
      this.gridApi.forEachNode(x => {
        x.setExpanded(false);
      });
      // C153176-4664: re-expand conv hist if any
      if (expandedNode) {
        setTimeout(() => {
          this.reExpandNode(expandedNode);
        }, 200);
      }
    }
  
    ColumnMovedEvent(params) {
      let gridNewCol = [];
      let columnState = params.api.gridOptionsWrapper.gridOptions.columnApi.getColumnState();
      let gridCol = this.columnDefs;
      columnState.forEach(element => {
        gridCol.forEach(individualCol => {
          // C153176-4660,4663: handling column id mismatches
          if (this.matchColIdAndField(element.colId, individualCol.field)) {
            individualCol.width = element.width;
            gridNewCol.push(individualCol);
          }
        });
      });
      this.colDefsService.setcolDefs(this.strMailboxView, gridNewCol);
      let expandedNode;
      if (this.openedConvHistInq) {
        expandedNode = this.openedConvHistInq.node;
      }
  
      this.gridApi.forEachNode(x => {
        x.setExpanded(false);
      });
      // C153176-4664: re-expand conv hist if any
      if (expandedNode) {
        setTimeout(() => {
          this.reExpandNode(expandedNode);
        }, 200);
      }
    }
  
    onGridReady(params) {
      let gridNewCol = [];
      let gridColWidth: any;
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      let gridColState = this.gridApi.gridOptionsWrapper.gridOptions.columnApi.getColumnState();
      let gridCol = this.colDefsService.getStoredArrColDefs(this.strMailboxView);
      // null check
      if (gridCol) {
        gridCol.forEach(element => {
          gridColWidth = gridColState.forEach(individualCol => {
            // C153176-4660,4663: handling column id mismatches
            if (this.matchColIdAndField(individualCol.colId, element.field)) {
              element.width = individualCol.width;
            }
          });
          gridNewCol.push(element);
        });
        this.colDefsService.setcolDefs(this.strMailboxView, gridNewCol);
      }
      /*  this.gridColumnApi.setColumnVisible("requestTypeStr", false);
       this.gridColumnApi.setColumnVisible("processingRegion", false);
       this.gridColumnApi.setColumnVisible("modDate", false); */
      if (this.isDraftsView) {
  
        this.createViewForDrafts();
        this.enableGrouping();
        this.gridColumnApi.setColumnVisible("modDate", true);
  
      }
  
      /*  let restorefromDB=this.setColumnsAsperDB();
       this.gridApi.setHeaderHeight(45);
       if(!restorefromDB){
        this.autoFitGridColumns();
       } */
  
      /* this.autosizeHeaders(params); */
      // C153176-5040: save pending stored coldefs if any
      if (this.gridColumnApi && this.pendingStoredColDefs) {
        this.gridColumnApi.applyColumnState({
          state:this.pendingStoredColDefs,
          applyOrder: true,
        });
        this.storedColdefs = true;
        this.isColsPreferencesinDb = true;
        this.pendingStoredColDefs = undefined;
        this.currentWidth = this.asSplitAreaMailboxEl.nativeElement.clientWidth; // C153176-5191
        this.colDefsService.adjustColumnWidths(this.strMailboxView, this.currentWidth, this.gridColumnApi, this.inboxView === 'horizontal');
      }
      if (this.isWebSocketRedesignEnable) {
        this.sortByColumn('modDate.$date', 'desc');
      }
  
    }
  
    onMobileGridReady(params) {
      this.gridApiMobile = params.api;
    }
  
    enableGrouping() {
      this.columnDefs.forEach(col => {
        col.enableRowGroup = true;
        col.suppressMovable = false;
      });
    }
  
    //Jira 4456 - If inquiry has only 1 conversation then mark inquiry as read after it moved to another inquiry
    onRowSelected(event: any) {
      // symphony changes
      if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0]) {
        if (this.checkIfSymphonyRow(this.gridApi.getSelectedRows()[0])) {
          return;
        }
      }
      // C153176-4903 : Draft should not be read in Draft folder.
      if (!this.isDraftsView && event && event.node && !event.node.selected &&
        (!this.openedConvHistInq || (event.node.data._id !== this.openedConvHistInq.data._id) ||
          (event.node.data.workflows[0].assignedGroupId !== this.openedConvHistInq.data.workflows[0].assignedGroupId) ||
          (event.node.data.openGroups !== this.openedConvHistInq.data.openGroups))) {
        // C153176-4660: if a node is de-selected and its conversation history not opened check whether it is in the last selected mail and with single conversation
        if (this.inqData && this.inqData.id === event.data._id && this.inqData.selectInqAssignedGroupId[0] === event.data.workflows[0].assignedGroupId) {
          if (this.selectedMail && this.selectedMail.conversationList && this.selectedMail.conversationList.length === 1) {
            const data = event.data;
            this.contextMenu.markInquiryAsRead([data], this.loginUserInfo, [data], this.cdr);
            // C170665-544 2 additional calls post getAllInquiryConversation
            this.gridUpdateService.setreadInqIds([{ inqId: this.inqData.id, agrpId: this.inqData.selectInqAssignedGroupId[0], callFrom: 'contextMenu' }]);
          }
        }
      }
  
      // C170665-660 | Disable getAllInquiryConversation call if user is selecting multiple inquiries in the grid
      if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows().length === 0 && null == this.selectedConvId) {
        this.resetMailDetailView();
      }
    }
  
    /**
     * C153176-4456: handling rowClicked event to collapse conversation history on other inquiries if any
     */
    onRowClicked(event: any) {
      try {
        // symphony changes
        if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0]) {
          if (this.checkIfSymphonyRow(this.gridApi.getSelectedRows()[0])) {
            return;
          }
        }
        // C153176-4903: There is not conversation history in Draft view.
        if (!this.isDraftsView && event && event.node && event.node.data) {
          // C153176-5263: save draft is user clicked on a different row than inline reply if any
          if (this.inlineReply && !this.inlineReplySendData && event.node.data._id !== this.selectedInqId) {
            this.saveDraft();
            this.inlineReply = false;
            this.onRowClicked(event);
            return;
          }
          // C153176-4456: if the newly clicked node is NOT the node with currently opened Conversation History, close the conversation history of the latter.
          if (event.node.data && this.openedConvHistInq &&
            ((event.node.data._id !== this.openedConvHistInq.data._id) ||
              (event.node.data.workflows[0].assignedGroupId !== this.openedConvHistInq.data.workflows[0].assignedGroupId) ||
              (event.node.data.openGroups !== this.openedConvHistInq.data.openGroups))
          ) {
            const rowIndex = Math.max(0, this.openedConvHistInq.node.rowIndex - 1);
            if (this.gridApi) {
              const node = this.gridApi.getDisplayedRowAtIndex(rowIndex);
              if (node) {
                node.expanded = false;
                this.gridApi.onGroupExpandedOrCollapsed(rowIndex);
              }
            }
          }
          // C153176-4660: set inquiry selection by clicking event only
          this.gridOprService.setInquirySelection(event.node.data);
          this.gridOprService.clearSelectedConversation(this.strMailboxView);
  
          let selectedGridRows = this.gridApi.getSelectedRows();
          if (selectedGridRows && selectedGridRows.length == 1 && (!event.event || !event.event.shiftKey)) {
  
            // C153176-4910: tracking conversation loading of the clicked inquiry
            let me = this;
            let clickedInquiryId = event.node.data._id;
            let clickedInquiryGroups = event.node.data.openGroups;
            // C153176-5120: verify the clicked row is NOT already selected
            if (clickedInquiryId && clickedInquiryGroups && (!this.selectedRowData ||
              this.selectedRowData._id !== clickedInquiryId || this.selectedRowData.openGroups !== clickedInquiryGroups)) {
              this.clickedInquiryId = clickedInquiryId;
              this.clickedInquiryGroups = clickedInquiryGroups;
              setTimeout(() => {
                me.startTrackingConvLoading(clickedInquiryId, clickedInquiryGroups);
              }, 500);
            }
          }
        }
      }
      catch (exception) {
        console.log("Exception occured while Switching inq (onRowClicked) :-" + exception.stack);
      }
    }
  
    /**
     * C153176-4910: start tracking conversation loading
     */
    startTrackingConvLoading(inquiryId, openGroups) {
      if (this.clickedInquiryId !== inquiryId || this.clickedInquiryGroups !== openGroups) {
        //skip since there were other rows clicked after this one
        return;
      }
      if (!this.loadingConversationInProgress && this.selectedInqId !== inquiryId || this.assignedGroup !== openGroups) {
        this.loadingConversationInProgress = true;
        // // this.showSpinner = true;
        // C153176-5120: force reset showSpinner to prevent infinite pending on conv retrieval
        setTimeout(() => {
          if (this.loadingConversationInProgress) {
            this.endTrackingConvLoading(inquiryId, openGroups, true);
          }
        }, 30000);
      }
    }
  
    /**
     * C153176-4910: end tracking conversation loading
     */
    endTrackingConvLoading(inqId, groups, match = false) {
      // C153176-5120: handling request timeout case with 'match' flag to true
      if (match && (this.clickedInquiryId !== inqId || this.clickedInquiryGroups !== groups)) {
        return;
      }
      // reset state attrs for loading status
      // C170665-524 Performance Issue - While switching an inquiry in the grid
      // this.showSpinner = false;
      this.loadingConversationInProgress = false;
      // reset clicked attrs only if matched
      if (this.clickedInquiryId === inqId && this.clickedInquiryGroups === groups) {
        this.clickedInquiryId = undefined;
        this.clickedInquiryGroups = undefined;
      }
    }
  
  
    /**
     * Method to call the inbox service to get the inquiry details of the selected inquiry and pass the details to mail-view-component.
     * 
     * @param event 
     */
    onSelectionChanged(event?: any) {
      try {
        let startTime = performance.now();
        // reset clc on selection change
        // C170665-542 Issue-When a Template is applied on a Reply conversation the entire mail body is wiped out.
        if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0] && 
        this.gridApi.getSelectedRows()[0]._id) {
          this.trades = null;
          this.tabDataService.clcData.set(this.gridApi.getSelectedRows()[0]._id, null);
        }
        
        // C170665-524 Performance Issue - While switching an inquiry in the grid
        // Fix for ws insert focus change
        if (this.inqAddedByWs && this.gridApi &&
          this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0] &&
          this.gridApi.getSelectedRows()[0].workflows &&
          this.mailDetailViewComp && this.mailDetailViewComp.inquiry && this.mailDetailViewComp.inquiry.workflows &&
          this.mailDetailViewComp.inquiry.workflows[0] &&
          !this.isSelectionChanged
            ({
              currentinqId: this.gridApi.getSelectedRows()[0]._id,
              currentAssignedGroupId: this.gridApi.getSelectedRows()[0].workflows[0].assignedGroupId
            }, {
              prevInqId: this.mailDetailViewComp.inquiry._id,
              previousAssignedGroupId: this.mailDetailViewComp.inquiry.workflows[0].assignedGroupId
            })) {
          this.inqAddedByWs = false;
          return;
        }
        this.inqAddedByWs = false;
  
        // symphony changes
        if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0]) {
           // C170665-1282 Navigation : Extend Keyboard shortcut option
            // set focus on first column so keyboard navigation will trigger without user to click on row on load
            // this.setFocusOnFirstCellOfSelectedRow(prevfocusedCell);
          if (this.checkIfSymphonyRow(this.gridApi.getSelectedRows()[0])) {
            this.processChatRoomSelection(this.gridApi.getSelectedRows()[0]);
            return;
          } else {
            this.enableOtherOptions();
          }
        }
  
        // C153176-6088 | Mobile: Clicking on an inquiry in Inbox doesn't show up. This scenario would not occured for mobile view
        if (!this.isMobile) {
          //TODO:Performance :: RE-factor below if else code.
          if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0] && !this.gridApi.getSelectedRows()[0].isProgrammaticExpand) {
            sessionStorage.removeItem("selectedConv_" + this.strMailboxView);
            delete this.gridApi.getSelectedRows()[0].isProgrammaticExpand;
          } else {
            if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0] && this.gridApi.getSelectedRows()[0].isProgrammaticExpand)
              delete this.gridApi.getSelectedRows()[0].isProgrammaticExpand;
            return;
          }
        }
  
        // C153176-4457 : Check if Inline reply is already open is yes then save the changes to Draft and open selected inquiry
        // C153176-4683: skip saving draft if it is DRAFTS view
        if (this.inlineReply && !this.inlineReplySendData && this.strMailboxView !== "DRAFTS") {
          // C153176-5263: save draft trigger is moved to row click event handler
          return;
        }
        else {
          // // C170665-524 Performance Issue - While switching an inquiry in the grid
          // this.convSelectionStarted = true;
          // // this.showSpinner = true;
  
          let selectedGridRow;
          if (this.isMobile) {
            selectedGridRow = (!(this.selectedMailMobile instanceof Array)) ? [this.selectedMailMobile] : this.selectedMailMobile;
          } else {
            selectedGridRow = this.gridApi.getSelectedRows();
          }
          //TODO:Performance :: If there is multiple selection in the grid then stop calling getAllInquiryConversation and disable the spinner and return from this location.
          // C170665-660 | Disable getAllInquiryConversation call if user is selecting multiple inquiries in the grid
          if (selectedGridRow.length > 1) {
            this.resetMailDetailView();
            return;
          }
  
          const selGridRow = selectedGridRow.length > 0 ? selectedGridRow[0] : null;
  
          // C170665-677 | Disable endpoint call when user performs right click in grid
          // If user click from conversation history and then Inquiry. It should refresh.
          if (selectedGridRow.length == 1 && selGridRow && selGridRow._id == this.selectedInqId
            && selGridRow.workflows[0].assignedGroupName == this.assignedGroup && !this.selectedConvId) {
            return;
          }
  
          // C153176-6033 Resolve not working from Conversation pane
          this.selectedRowData = selectedGridRow;
          if (this.isDraftsView) {
            //TODO:Performance :: If there is multiple selection in the grid then stop calling getAllInquiryConversation and disable the spinner and return from this location.
            let selGridRowDraft = selectedGridRow.length > 0 ? selectedGridRow[selectedGridRow.length - 1] : null;
            // C153176-4681 | Reset Draft Detail conversation.
            if (selGridRowDraft) {
              let inquiryDataDraft = {
                draftId: selGridRowDraft._id
              };
  
              // C170665-524 Performance Issue - While switching an inquiry in the grid
              this.convSelectionStarted = true;
              // this.showSpinner = true;
              console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.onSelectionChanged");
  
              // Send request to get the all the inquiry conversation for the selected inquiry Id.
              this.inboxService.getDraftById(inquiryDataDraft).subscribe(draftDetail => {
                this.selectedDraftMail = draftDetail;
                this.convSelectionStarted = false; // C170665-580 | Draft Content should show
                this.showSpinner = false; // C170665-580 | Stop the spinner.
                console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.onSelectionChanged");
              }, error => {
                this.convSelectionStarted = false; // C170665-580 | Draft Content should show
                this.showSpinner = false; // C170665-580 | Stop the spinner.
                console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.onSelectionChanged");
                console.error('Error while getting response from getDraftById()');
              });
            } else {
              this.selectedDraftMail = null;
            }
  
          } else {
            if (selGridRow) {
              let selectInqAssignedGroupId = [];
  
              //[C153176-419]User should see only conversations for selected  groups only
              //TODO:Performance :: Refactor below 'if' code as backend sends only assigned group id data for any selected row. it can be replaced with let selectInqAssignedGroupId = [selGridRow.workflows[0].assignedGrpId]
              // Performance : Updated
              selectInqAssignedGroupId.push(selGridRow.workflows[0].assignedGroupId);
  
              const assignedGroup = selGridRow.openGroups;
              // C153176-4903: Set the Selected assigned group Name
              // C153176-6039 | Add note issue. - Focus lost
              this.assignedGroupName = assignedGroup;
  
              // Workflow properties
              // C153176-5298 | Pass lock and lockedBy from selected inquiry.
              let workflow = {
                queryCount: selGridRow.queryCount,
                requestType: selGridRow.requestTypeStr || null,
                inquirySource: selGridRow.inquirySource,
                processingRegion: selGridRow.processingRegion,
                rootCause: selGridRow.rootCause,
                tag: selGridRow.tag,
                lock: selGridRow.lock,
                lockedBy: selGridRow.lockedBy,
                groupId: selectInqAssignedGroupId[0]
              };
              let curInq = this.viewNotificationData != null ? this.viewNotificationData : selGridRow;
  
              // null check for workflows
              let hasNominatedOwnership = false;
              // C153176-5223 | conversation loading issue for nomination
              // null check for workflows
              if (selGridRow && selGridRow.workflows && selGridRow.workflows[0]) {
                hasNominatedOwnership = selGridRow.workflows[0].hasNominatedOwnership !== undefined ? selGridRow.workflows[0].hasNominatedOwnership : false;
              }
              let conversationIdforInquiry = this.micrfrontendInboxService.getConversationIdForInquiry(curInq._id);
              let inquiryData = {
                id: curInq._id,
                selectInqAssignedGroupId: this.viewNotificationData != null ? [this.viewNotificationData.latestGroupId] : selectInqAssignedGroupId,
                isAllConvContent: false, // C153176-4660:
                hasNominatedOwnership: hasNominatedOwnership,
                conversationIds :conversationIdforInquiry ? conversationIdforInquiry : ''
              };
              // C153176-4660: verify whether the selected row is allowed to display its details, C153176-4614: restore match selection logic to handle only conv selection case here
              //TODO:Performance :: below if is not getting executed as second condition in below if always returns false.
              // C170665-674 | Not executing
              // if (!this.viewNotificationData && this.gridOprService.matchSelectedConversation(true, this.strMailboxView, selGridRow, assignedGroup, selectInqAssignedGroupId)) {
              //   console.debug("Skip loading inquiry detail, selGridRow=", selGridRow._id);
              //   if (this.isMobile) {
              //     this.selectedNodes = this.selectedMailMobile;
              //   }
              //   else {
              //     this.selectedNodes = this.gridApi.getSelectedNodes();
              //   }
              //   // C153176-4910: end conversation loading status tracking
              //   //TODO:Performance :: if this if block is getting executed, is below function still getting used or not ?
              //   this.endTrackingConvLoading(inquiryData.id, assignedGroup); // C153176-4614: fix on group id vs. name mismatch 
              //   return;
              // }
              // C153176-4660: check conversation request is required to be made
              // C153176-5120: check match with clicked inquiry id as another condition to skip the query
              // ws redesign commented
              //TODO:Performance :: Confirm whether below 'if' code is really getting used now or not
              // C170665-674 | Not executing           
              // if(!this.isWebSocketRedesignEnable) {
              //   if (this.clickedInquiryId === selGridRow._id && this.clickedInquiryGroups === assignedGroup
              //     && !this.gridOprService.updateConversationRequest(inquiryData)) {
              //     console.debug('Skip loading inquity details, id =' + inquiryData.id + ',group =', inquiryData.selectInqAssignedGroupId);
              //     if (this.isMobile) {
              //       this.selectedNodes = this.selectedMailMobile;
              //     }
              //     else {
              //       this.selectedNodes = this.gridApi.getSelectedNodes();
              //     }
              //     // C153176-4910: end conversation loading status tracking
              //     this.endTrackingConvLoading(inquiryData.id, assignedGroup);  // C153176-4614: fix on group id vs. name mismatch
              //   // C170665-524 Performance Issue - While switching an inquiry in the grid
              //     this.convSelectionStarted = false;
              //   // this.showSpinner = false;
              //     return;
              //   }
              // }
              //TODO:Performance :: Starting spinner at this location will reduce spinner time on UI
                // for chatview restrict email getAllInquiryConversations
              // separate call will be made from chatview component
              // C170665-1616 email as a chat loader handling in exception and api failure
              this.selectedInqId = null;
              this.loadingConversationInProgress = false;
              if (this.isChatView) {
                // to call ngonchanges of chatview so inturn it will call getAllInquiryConversations in chatview
                this.assignedGroup = assignedGroup;
                this.selectedMail = {...this.createInqWithGridInq({},selGridRow)};
                this.inqData = {...inquiryData};
                // C170665-1766 provide Email as a Chat view in profile settings so user can save it to default.
                // when loaded as defaultview passing required data for onchange
                this.cdr.detectChanges();
                return;
              }
              // C170665-524 Performance Issue - While switching an inquiry in the grid
              this.convSelectionStarted = true;
              // this.showSpinner = true;
              console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.onSelectionChanged");
              this.notificationService.setNotificationInquiry(selGridRow); // C153176-5295: cache entire inquiry
              // loader on detailview
              // [C170665-1262] Loading: QMA loader should appear on Load email view panel only
              this.convSelectionStarted =true;
              this.loadingConversationInProgress = true;
              this.inboxService.getInquiryIdExtSelected(inquiryData, curInq).subscribe(inquiryDetail => {
                // C170665-1169 QMA2 UI Inquiry View Rendering Problem - BOT domain Emails
                inquiryDetail = this.commentHTMLHeadStyleIfExists(inquiryDetail);
                // JIRA  C153176-4878 Snooze - phase 2
                // update snooze details in rowdata
                //TODO:Performance :: Why below Snooze logic is needed in response of getAllInquiryConersation
                //This particular code related to snooze should be handled by websocket not here. This is un-necessarily adding performace factors.
                //TODO: Commented here- Need to process on webscoket update.
                if (this.strMailboxView === "SNOOZED") {
                  let inqIndexForSnooze = this.getCurrentIndexFromRowData(selGridRow._id, selGridRow.openGroups);
                  if (inqIndexForSnooze > -1) {
                    this.updateRowData(inqIndexForSnooze, inquiryDetail.inquiry.snoozeDuration ? inquiryDetail.inquiry.snoozeDuration : ""
                      , "snoozeDuration");
                  }
                }
  
                //TODO:Performance :: Remove below caching call as its not currently getting used.
                // TODO: Commented - To be removed.
                // this.requestCacheService.updateInquiryCache(curInq, inquiryData.isAllConvContent, inquiryDetail);
                this.inqData = inquiryData;
                console.log('Got inquiry details, id =' + inquiryData.id + ',group =', inquiryData.selectInqAssignedGroupId);
                // console.log(inquiryDetail);
                // redesign change
                // this.selectedMail = inquiryDetail;
                //TODO:Performance :: For below call, check if we can populate same data from backend as expected by UI.
                this.selectedMail = this.updateConversationInqWithGridInq(inquiryDetail, selGridRow)
                this.assignedGroup = assignedGroup;
                // C153176-6033 Resolve not working from Conversation pane
                this.selectedInqId = inquiryDetail.inquiry ? inquiryDetail.inquiry._id : null;
                this.selectedConvId = null;
                this.inquiryWorkflow = workflow;
                // C153176-4910: end conversation loading status tracking
                //TODO:Performance :: Validate below method call
                this.endTrackingConvLoading(inquiryData.id, assignedGroup); // C153176-4614: fix on group id vs. name mismatch
                this.setClcDetails(selGridRow.workflows[0].assignedGroupId);
                // C153176-4660: populate subject / total conv count separately from other attrs.
                this.selectedInquirySubject = inquiryDetail.inquiry ? inquiryDetail.inquiry.subject : '';
                this.inquiryReportedConvCount = curInq.convCount ? curInq.convCount : 0; // C153176-5257: inquiry-reported conv count
                if (inquiryDetail.conversationList && inquiryDetail.conversationList.length) {
                  curInq.convCount = inquiryDetail.conversationList.length;
                  if (inquiryDetail.inquiry) {
                    inquiryDetail.inquiry.convCount = inquiryDetail.conversationList.length;
                  }
                }
  
                // C170665-673 | Update the selected inquiry details.
                this.selInquiryDetails = this.getSelectedInquiryDetails();
  
                //TODO:Performance :: Can we remove below change detection? because it asks angular to detect changes and re-render the UI. Anil Please investigate
                // Also check if, we can create single input object which will change the reference and auto detect changes.
                //this.cdr.detectChanges(); //Jira - C153176-4876 mail box stats issue
                //TODO:Performance :: Why below function call is needed ?, Anil, please check
                this.gridOprService.clearDetailViewConversation(this.strMailboxView);
                // this.notificationService.setNotificationCount(null);
                this.viewNotificationData = null;
                if (this.doubleClickedInqId && this.doubleClickedInqId === this.selectedInqId) {
                  // this is a double-clicked item, opening tab for the inquiry
                  this.tabDataService.setInquiryData({
                    selectedMail: this.selectedMail,
                    assignedGroup: this.assignedGroup,
                    selectedConvId: this.selectedConvId,
                    selectedInqId: this.selectedInqId,
                    inquiryWorkflow: this.inquiryWorkflow,
                    // C153176-4999: add subject in the inquiry data so it may be carried over to mail detail view
                    inquirySubject: this.selectedInquirySubject,
                    inquiryReportedConvCount: curInq.convCount || 0, // C153176-5308
                    mailContainerHeight: this.currentHeight,
                    inboxViewMode: 'tab',
                    // C153176-4985: add viewname
                    inboxViewName: this.getViewType(this.strMailboxView)
                  });
                  this.tabDataService.sendTabData("INQUIRY:" + this.doubleClickedInqId, 0);
                  this.doubleClickedInqId = undefined;
                }
                // C170665-524 Performance Issue - While switching an inquiry in the grid
                this.convSelectionStarted = false;
                this.showSpinner = false
                console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.onSelectionChanged");
  
                let endTime = performance.now();
                console.debug(`QMA2.0 Performance :: On Selection Change time taken : ${Math.ceil(endTime - startTime)} miliseconds, for Inquiry Id: ${this.selectedInqId}, assign Id: ${this.assignedGroup}`);
              },
                error => {
                  // C153176-4910: end conversation loading status tracking
                  this.endTrackingConvLoading(inquiryData.id, assignedGroup); // C153176-4614: fix on group id vs. name mismatch
                  console.error('Error while getting response from getInquiryIdExtSelected()');
                  //TODO:Performance :: Ideally this block should also contains spinner = false code or whatever code which removes spinner.
                  // C170665-937 | Spinner not stopping issues due to service throws error.
                  this.showSpinner = false;
                  console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.onSelectionChanged");
  
                  // If any error occur, conversation view should be reset and not conversation data should rendered.
                  this.convSelectionStarted = false;
                  this.selectedMail = null;
                  this.inqData = null;
                  this.assignedGroup = null;
                  this.selectedInqId = null;
                  this.selectedConvId = null;
                  this.inquiryWorkflow = null;
                  this.selInquiryDetails = this.getSelectedInquiryDetails();
                });
            }
          }
          if (this.isMobile) {
            this.selectedNodes = this.selectedMailMobile;
          }
          else {
            //TODO:Performance :: Why we are using this api, what's the use of this API ?
            this.selectedNodes = this.gridApi.getSelectedNodes();
          }
  
        }
      }
      catch (exception) {
        this.showSpinner = false;
        console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.onSelectionChanged");
        console.log("Exception occured while switching Inq :- " + exception.stack);
      }
    }
  
    /**
     * Method to reset mail detail or draft view if multiple inquires or no inquiry selected.
     */
    private resetMailDetailView() {
      this.convSelectionStarted = false;
      this.showSpinner = false;
      console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.resetMailDetailView");
      this.selectedDraftMail = null;
      this.selectedMail = null;
      this.selInquiryDetails = this.getSelectedInquiryDetails();
    }
  
    markInquiryAsRead(node) {
      if (!node || !this.contextMenu || !this.loginUserInfo) {
        return;
      }
      const data = node.data;
      if (!data) {
        return;
      }
      // to mark inquiry as unread only first inquiry will be marked as unread
      // on initial load first selected inquiry should not be marked as read
      this.contextMenu.markInquiryAsRead([data], this.loginUserInfo, [data], this.cdr);
    }
  
    onKey(event: any) {
      this.gridApi.setQuickFilter(event.target.value);
    }
    callUsage(){
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Context Search", "Click","Grid Search", 0);
    }
    filterIconClick() {
      this.filterIconHide = true;
      if (this.inboxView == "horizontal") {
        this.filterIconHideHorizontal = true;
      }
    }
  
    filterCloseClick() {
      this.filterIconHide = false;
      this.filterIconHideHorizontal = false;
      this.filterText = "";
      this.gridApi.setQuickFilter("");
    }
    onHorizontalImgClick() {
      // if (this.isChatView == true && this.chatComponent.editorApp) {
      //   this.chatText = this.chatComponent.editorApp.getData();
      //   // C170665-1766 provide Email as a Chat view in profile settings so user can save it to default.
      //   // if selected mail details are not fetched get it  from api
      //   this.isChatView = false;
      //   this.onSelectionChanged();
      // }
      this.inboxView = "horizontal";
      // this.maxSelectionColumnChooser = 50;
      this.isChatView = false;
      //this.onSelectionChanged();
      this.viewBeforeSymphonyRowSelection.viewName = "EMAILVIEW";
      //this.arrangeColumnForHorizontalView();
      if (!this.isColsPreferencesinDb) {
        window.setTimeout(() => {
          this.arrangeColumnForHorizontalView();   //<<<---    using ()=> syntax
          //this.gridApi.sizeColumnsToFit();
          if (this.strMailboxView === "SEARCH") {
            if (this.gridApi) {
              //Splitter change
              // this.gridApi.sizeColumnsToFit(); 
              this.sizeToFit();
            }
          } else {
            this.autoFitGridColumns();
          }
        }, 300);
      } else {
        window.setTimeout(() => {
          this.setGridwidthLessThansixColumns();
        }, 300);
      }
      this.onInitialHorizontalView();
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Horizontal View", "Click", "Horizontal View", 0);
    }
    onInitialHorizontalView() {
      window.setTimeout(() => {
        if (this.asSplitAreaMailboxEl) {
          this.currentHeight = this.asSplitAreaMailboxEl.nativeElement.clientHeight;
          this.inboxService.currentViewHeight = this.currentHeight; // C153176-5295
          // C153176-4399: aget grid width
          this.currentWidth = this.asSplitAreaMailboxEl.nativeElement.clientWidth;
          console.log('this.currentHeight/currentWidth', this.currentHeight, this.currentWidth);
          // C153176-4399: adjust horizontal extra space if any
          this.colDefsService.adjustColumnWidths(this.strMailboxView, this.currentWidth, this.gridColumnApi, true);
        }
      }, 300);
    }
    onVerticleImgClick() {
      // if (this.isChatView == true && this.chatComponent.editorApp) {
      //   this.chatText = this.chatComponent.editorApp.getData();
      //   // C170665-1766 provide Email as a Chat view in profile settings so user can save it to default.
      //   // if selected mail details are not fetched get it  from api
      //   this.isChatView = false;
      //   this.onSelectionChanged();
      // }
      this.maxSelectionColumnChooser = 50;
      this.inboxView = "vertical";
      this.isChatView = false;
      //this.onSelectionChanged();
      this.viewBeforeSymphonyRowSelection.viewName = "EMAILVIEW";
      window.setTimeout(() => {
        this.arrangeColumnForVerticleView();
        if (this.gridApi) {
          //Splitter change
          // this.gridApi.sizeColumnsToFit(); 
          // C153176-4399: restore original column widths
          this.currentWidth = this.asSplitAreaMailboxEl.nativeElement.clientWidth; // C153176-5191
          this.colDefsService.adjustColumnWidths(this.strMailboxView, this.currentWidth, this.gridColumnApi, false);
          // fix for C153176-5411 Grid is not responsive. On 1st download need to always reset for it to fit all columns    
          if (this.gridColumnApi && this.isDefaultNumberOfColumns(this.columnConfig)) {
            this.gridAutoFitDefaultColumnsByResolution();
          }
          this.sizeToFit();
  
        }
      }, 300);
      // this.gridApi.sizeColumnsToFit();
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Vertical View", "Click", "Vertical View", 0);
    }
  
    onChatImgClick() {
      this.inboxView = "vertical";
      this.isChatView = true;
      // C153176-4022 : Add check for Inquiry Id.
      // C170665-1766 provide Email as a Chat view in profile settings so user can save it to default.
      if (this.selectedMail && this.selectedMail.inquiry && this.currentInquirySelection !== (this.selectedMail && this.selectedMail.inquiry.id)) {
        this.currentInquirySelection = this.selectedMail.inquiry.id;
        this.chatText = "";
      }
      window.setTimeout(() => {
        this.arrangeColumnForVerticleView();   //<<<---    using ()=> syntax
        if (this.gridApi) {
          //this.gridApi.sizeColumnsToFit();
          this.autoFitGridColumns();
        }
      }, 300);
      if (this.gridApi) {
        //this.gridApi.sizeColumnsToFit();
        this.autoFitGridColumns();
      }
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Chat View", "Click", "Chat View", 0);
    }
  
    clearChatText() {
      this.chatText = "";
    }
  
    getContextMenuItems = (params: any) => {
      let loginUserData = JSON.parse(AppUtils.getCachedItem(QmaConstant.inboxConfiguration.contextMenu));
      let objgridMenuItems = new GridMenuItems(loginUserData);
      var selectedRows = this.isMobile ? this.gridApiMobile.getSelectedRows() : this.gridApi.getSelectedRows();
      var selectedNodes = this.isMobile ? this.gridApiMobile.getSelectedNodes() : this.gridApi.getSelectedNodes();;
      if (selectedNodes.length == 0 || selectedNodes.length == 1) {
        if (selectedNodes[0]) {
          selectedNodes[0].setSelected(false);
        }
        params.node.setSelected(true); //TO select row on right click  
      }
      // symphony changes
      if (this.checkIfSymphonyRow(selectedNodes[0].data)) {
        return this.getContextMenuForSymphony(selectedNodes[0].data)
      }
  
      let compObj = {
        gfidComp: this.gfidSearchComponent,
        linkExceptionComp: this.linkExceptionComponent,
        grpDetailsComp: this.groupDetailsComponent,
        reAgeComp: this.reAgeComponent,
        //updateMemoComp:this.updateMemoComponent,
        ownershipComp: this.ownershipComponent,
        resolveInquiryComp: this.resolveInquiryComponent,
        actionPopupComp: this.actionPopupComponent,
        rejInq: this.rejInqComponent,
        notifyComp: this.notifyComponent,
        inquiryComp: this.createRuleComponent,
        snoozeInq: this.snoozeInqComponent
      };
      selectedRows = this.isMobile ? this.gridApiMobile.getSelectedRows() : this.gridApi.getSelectedRows();
      if (params.menu && params.menu == "secondaryMenu") {
        this.childInqueryarr = params.childInqueryarr;
        selectedRows = params.api.getSelectedRows();
        // C153176-5120: clone new selected rows so that the original rows are NOT altered.
        let clonedSelectdRows = [];
        selectedRows.forEach((selectedRow, key) => {
          let cloned = Object.assign({}, selectedRow);
          let filteredRows = this.rowData.filter(inq => inq._id === selectedRow.inquiryId && inq.openGroups === selectedRow.openGroups);
          if (filteredRows && filteredRows.length && filteredRows[0].direction) {
            cloned.direction = filteredRows[0].direction;
          }
          // C153176-5120: update cloned entry only
          cloned['openGroups'] = this.childInqueryarr[selectedRow.inquiryId].openGroups;
          cloned['workflows'] = this.childInqueryarr[selectedRow.inquiryId].workflows;
          // C153176-4669: populate 'direction' on selectedRows which is needed for context menu generation in default
          // folders.
          // C153176-5120: update cloned entry only
          cloned['direction'] = this.childInqueryarr[selectedRow.inquiryId].direction;
          if (!selectedRows[key]['direction'] && selectedRows[key]['workflows'] && selectedRows[key]['workflows'].length) {
            selectedRows[key]['workflows'].forEach(wf => {
              if (wf && wf.assignedGroupId === selectedRows[key].assignedGroupId) {
                if (!selectedRows[key].direction) {
                  // C153176-5120: update cloned entry only                
                  cloned.direction = wf.direction;
                }
              }
            });
            if (!selectedRows[key]['direction']) {
              cloned['direction'] = cloned['workflows'][cloned['workflows'].length - 1].direction;
            }
          }
          // C153176-5120: update cloned entry only
          cloned['type'] = 'conversatationHistory';
          cloned['_id'] = selectedRow.inquiryId;
          clonedSelectdRows.push(cloned);
        });
        selectedRows = clonedSelectdRows; // C153176-5120: assign cloned ones to 'selectedRows'
      }
      return this.contextMenu.getAllContextMenuItems(selectedRows, this.loginUserInfo, compObj, selectedRows, this.getViewType(this.strMailboxView), loginUserData, this.selectedMail, this.assignMenu);
    }
  
    /**
     * C153176-4946: GetView Type, to take into account of myView searc-in type
     */
    getViewType(viewName) {
      let request = this.dataCache.requestObj;
      if (!request || !request.myViewSearchInType || this.viewType !== -1) {
        return viewName;
      }
      return request.myViewSearchInType.toUpperCase();
    };
  
    autosizeHeaders(event) {
      // if (event.finished !== false) {   
      //   event.api.setHeaderHeight(25);
      //   const headerCells = document.querySelectorAll('#inboxGrid .ag-header-cell-label');
      //   let minHeight = 25;
      //   headerCells.forEach(cell => {
      //       minHeight = Math.max(minHeight, cell.scrollHeight);
      //   });
      //   event.api.setHeaderHeight(minHeight);
      // }
    }
  
    // inboxGroupBy(){
    //   this.columnDefs=inboxGridAllColumnDefs;
    //   this.gridApi.sizeColumnsToFit();
  
  
    // }
    imgGroupByClick(event, overlaypanel) {
      overlaypanel.hide();
      this.hideGrpByMenu = false;
  
    }
    inboxStateChanged(params) {
      /* this.gridColumnApi.autoSizeColumns();
      this.gridApi.sizeColumnsToFit(); */
      //to keep first column selected
      if (this.isWebSocketRedesignEnable) {
        // dont select first raw on ws updates
        if (params && params.type === "componentStateChanged" && !this.isGridInitialLoad) {
          return;
        }
      }
  
      this.inboxService.stopViewLoading();
      console.log('inboxStateChanged', params);
      try {
        const me = this;
        let nodeSelected = false;
        if (this.gridApi) { // C153176-5344 - add null check
          this.gridApi.forEachNode(function (node) {
            if (node.rowIndex == 0) { // To select the first row in the grid, the index should be 0 instead of 1.
              nodeSelected = me.selectOpenConvInquiryNode(node, true);
            }
            else {
              node.setSelected(false);
            }
  
          });
        }
        if (!nodeSelected) {
          // C153176-5189: if node selection is not done, reset conversation view
          this.resetConversationView();
        }
      }
      catch (e) {
        console.log(e)
      }
      // To keep first selected Inq as unread on initial Load
      // dont select first raw on ws updates
      if (this.isWebSocketRedesignEnable) {
        if (params && params.type === "componentStateChanged" && this.isGridInitialLoad) {
          this.isGridInitialLoad = false;
        }
      }
  
  
    }
    getSelectedBox(selectedItems: any) {
      if (selectedItems && selectedItems.length > 0) {
        this.gridColumnApi.getRowGroupColumns().forEach(i => {
          this.gridColumnApi.removeRowGroupColumn(i.colId);
        })
  
        this.columnDefs = new MailBoxGridColdefs().getMailboxColDefs();
        //this.disableGrpBy=true;
        this.isGroupedBy = true;
        this.selectedColumn = selectedItems[selectedItems.length - 1].name;
        this.hideGrpByMenu = false;
  
        var foundIndex = this.columnDefs.findIndex(x => x.headerName == selectedItems[selectedItems.length - 1].name);
        var grpByColumn = this.columnDefs[foundIndex];
        this.columnDefs[foundIndex] = grpByColumn;
        this.gridColumnApi.addRowGroupColumn(grpByColumn.field);
        this.gridApi.setColumnDefs(this.columnDefs);
        /* this.gridApi.sizeColumnsToFit(); */
      }
  
    }
    clearGroupBy() {
      /* this.gridColumnApi.getRowGroupColumns().forEach(i => {
        this.gridColumnApi.removeRowGroupColumn(i.colId);
      })
      this.isGroupedBy = false;
      // this.disableGrpBy=false;
      this.columnDefs = inboxGridColumnDefs;
      this.checkboxListComponent.first.resetCheckList(); */
    }
  
    showFilterPopup(event, overlaypanel) {
      this.renderFilterBody = !overlaypanel.visible;
      overlaypanel.toggle(event);
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Inquiry Filter", "Click", "Inquiry Filter", 0);
    }
    showPopup(event, overlaypanel) {
      overlaypanel.toggle(event);
  
      /* this.checkboxListComponent.last.clearFilterText();
      this.checkboxListComponent.last.resetCheckList(); */
      this.primaryColumns.clearFilterText();
      this.primaryColumns.resetCheckList();
  
      /* let objinboxColumnChooserHelper = new MailboxColumnChooserHelper(); */
      /* this.lstmailBoxColumnChooser = objinboxColumnChooserHelper.createColumnChooserList(this.columnDefs); */
      this.lstmailBoxColumnChooser = this.createColumnChooserList(this.columnDefs);
      //Selected columns count
      this.columnChooserSelectedColumns = [];
      this.lstmailBoxColumnChooser.forEach(column => {
        if (column.binary) {
          this.columnChooserSelectedColumns.push(column);
        }
      });
      this.primaryColumns.items = this.lstmailBoxColumnChooser;
      this.primaryColumns.assignCopy();
      // to unselect select select all checkbox based on selection
      this.lstmailBoxColumnChooser.filter(cb => !cb.binary).length === 0 ?
        this.primaryColumns.checkboxes.first.nativeElement.checked = true : this.primaryColumns.checkboxes.first.nativeElement.checked = false;
      this.primaryColumns.selectAllBinary = this.primaryColumns.checkboxes.first.nativeElement.checked;
    }
    showPopupSecondaryClmns(event, overlaypanel) {
      overlaypanel.toggle(event);
      this.checkboxListComponent.first.clearFilterText();
      this.checkboxListComponent.first.resetCheckList();
      // let currentTab = AppUtils.getCurrentTab().toUpperCase();
      let currentTab = this.folderName;
  
      this.lstsecondaryCols = null;//reseting existing array;
      this.colDefsService.getcolDefs().subscribe(tabName => {
        //getting reference issue therefore changed
        //this.lstsecondaryCols = this.colDefsService.myMap.get(currentTab);
        let secCols = this.colDefsService.getStoredColDefs(currentTab);
  
        if (secCols) {
          /* Array.prototype.push.apply(this.lstsecondaryCols, this.colDefsService.myMap.get(currentTab)); */
          this.lstsecondaryCols = [];//reseting existing array;
          this.lstsecondaryCols.push([...secCols]);
          let objMailBoxSecColumnChooser = new MailBoxSecColumnChooser();
          this.lstmailBoxSecondaryColumnChooser = [...objMailBoxSecColumnChooser.getSecondaryColumnslst(this.lstsecondaryCols[0])];
        }
        //pick default secondary columns for first time 
        else if (this.colDefsService.getStoredColDefs("default").length > 0) {
          let objMailBoxSecColumnChooser = new MailBoxSecColumnChooser();
          //getting reference issue therefore changed
          //this.lstsecondaryCols = this.colDefsService.myMap.get("default");
          secCols = this.colDefsService.getStoredColDefs("default");
  
          /* Array.prototype.push.apply(this.lstsecondaryCols, secCols); */
          this.lstsecondaryCols = [];//reseting existing array;
  
          this.lstsecondaryCols.push([...secCols]);
          //this.lstsecondaryCols=secCols;
          this.lstmailBoxSecondaryColumnChooser = [...objMailBoxSecColumnChooser.getSecondaryColumnslst(this.lstsecondaryCols[0])];
        }
        //Add logic to show selected checkboxes
        this.SecondarycolumnChooserSelectedColumns = [];
        this.lstmailBoxSecondaryColumnChooser.forEach(column => {
          if (column.binary) {
            this.SecondarycolumnChooserSelectedColumns.push(column);
          }
        });
  
        this.checkboxListComponent.first.items = this.lstmailBoxSecondaryColumnChooser;
        this.checkboxListComponent.first.assignCopy();
        this.checkboxListComponent.first.selectAllCheckboxOption(); // JIRA C153176-4800 Select All option for Column choosers
      });
  
  
    }
  
    clmnChooserLoad(event, overlaypanel) {
      let expandedNode = undefined;
      if (this.openedConvHistInq && this.openedConvHistInq.node) {
        expandedNode = this.openedConvHistInq.node;
      }
  
      this.gridApi.forEachNode(x => {
        x.setExpanded(false);
      });
  
      let columnNumber = 0;
      let selectedColumns = this.primaryColumns.selectedValue;
      this.updateColumnDef();
      this.columnDefs.forEach(x => {
  
        if (x.headerName.toUpperCase() == "RECEIVED") {
          console.log(x.headerName.toUpperCase());
        }
        x.headerName = x.headerName.toUpperCase();
        if (x.headerName == "ID") {
          console.log('ID')
        }
        let index = selectedColumns.findIndex((item: any) => item.value == x.headerName);
  
        if (x.headerName == "AGE") {
          console.log(x.headerName);
        }
  
        if (x.headerName == "ANNEX" || x.field == "cheveron" || x.field == "expander" ||
          x.field == "cheveron_1" || x.field == "category" || x.field == "attchFlag" || x.field == "isSymphonyChatGridCol") {
          return;
        }
        if (index == -1 && x.headerName != "ANNEX") {
          if (this.gridColumnApi) {
            this.gridColumnApi.setColumnVisible(x.field, false);
            x.hide = true;
            x.showInSecondary = true;
          }
        } /* C153176-5019 AGE(DAYS) --> AGE
        else if (index == -1 && x.headerName == "AGE") {
          if (this.gridColumnApi) {
            this.gridColumnApi.setColumnVisible("age", false);
            x.hide = true;
          }
        } */
        else if (index != -1 && x.headerName == "AGE") { // C153176-5019 AGE(DAYS) --> AGE
          // verify whether the flag is a customized column (in a customized view) before enabling it
          if (!this.customViewFlag || this.isCustomizedColumn(x)) {
            if (this.gridColumnApi) {
              this.gridColumnApi.setColumnVisible("age", true);
              /*  this.gridApi.sizeColumnsToFit(); */
              x.hide = false;
              x.showInSecondary = false;
            }
          }
        }
        else if (x.headerName != "AGE") {// C153176-5019 AGE(DAYS) --> AGE
          // verify whether the flag is a customized column (in a customized view) before enabling it
          //C153176-4393: Do not check customViewFlag nor customized columns, let the selected columns show on grid
          columnNumber = columnNumber + 1;
          if (columnNumber == 1) {
            // x.cellRenderer = "agGroupCellRenderer";
          }
          else {
            //x.cellRenderer = "";
          }
          if (this.gridColumnApi) {
            this.gridColumnApi.setColumnVisible(x.field, true);
            x.hide = false;
            x.showInSecondary = false;
          }
        }
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Column Chooser", "Click", "Column Chooser", 0);
      })
  
      this.colDefsService.setcolDefs(this.strMailboxView, this.columnDefs);
      let objinboxGroupByHelper = new InboxGroupByHelper();
      this.lstmailBoxGrpByChooser = objinboxGroupByHelper.createGroupByList(this.columnDefs);
      /* his.checkboxListComponent.first.items = this.lstmailBoxGrpByChooser;
      this.checkboxListComponent.first.assignCopy(); */
      //to change the columns after initialisation
      //this.gridApi.setColumnDefs(this.columnDefs);
      /* this.gridApi.sizeColumnsToFit(); */
      let gridNewCol = [];
      let columnState = this.gridApi.gridOptionsWrapper.gridOptions.columnApi.getColumnState();
      let gridCol = this.columnDefs;
      columnState.forEach(element => {
        gridCol.forEach(individualCol => {
          // C153176-4660,4663: handling column id mismatches
          if (this.matchColIdAndField(element.colId, individualCol.field)) {
            individualCol.width = element.width;
            gridNewCol.push(individualCol);
          }
        });
      });
      this.colDefsService.setcolDefs(this.strMailboxView, gridNewCol);
  
      overlaypanel.hide();
      this.setGridwidthLessThansixColumns();
  
      this.lstmailBoxColumnChooser = this.createColumnChooserList(this.columnDefs);
      //Selected columns count
      this.columnChooserSelectedColumns = [];
      this.lstmailBoxColumnChooser.forEach(column => {
        if (column.binary) {
          this.columnChooserSelectedColumns.push(column);
        }
      });
      this.primaryColumns.items = this.lstmailBoxColumnChooser;
      this.primaryColumns.selectedValue = this.columnChooserSelectedColumns;
      this.primaryColumns.assignCopy();
  
      // override default columns
      this.isDefaultColumns = false;
      // C153176-4664: re-open expanded node if any
      this.reExpandNode(expandedNode);
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Column Chooser - Load", "Click", "Column Chooser - Load", 0);
    }
  
    secondaryClmnChooserLoad(event, overlaypanel) {
      let expandedNode = undefined;
      if (this.openedConvHistInq && this.openedConvHistInq.node) {
        expandedNode = this.openedConvHistInq.node;
      }
      this.gridApi.forEachNode(x => {
        x.setExpanded(false);
      });
      //retriving serivice map to restore
  
  
      let selectedColumns = this.checkboxListComponent.first.selectedValue;
      this.lstsecondaryCols[0].forEach(x => {
        let index = selectedColumns.findIndex((item: any) => item.value == x.headerName);
        if (index == -1) {
          x.showInSecondary = false;
        } else {
          x.showInSecondary = true;
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Secondary Column Chooser - ", "Click", "Secondary Column Chooser", 0);
        }
      });
      this.colDefsService.setcolDefs(this.strMailboxView, this.lstsecondaryCols[0]);
      //restoring orignal values serivice map
  
      overlaypanel.hide();
      // C153176-4664: re-open expanded node if any
      this.reExpandNode(expandedNode);
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Secondary Column Chooser - Load", "Click", "Secondary Column Chooser - Load", 0);
    }
  
    resetClick(event, overlaypanel) {
      overlaypanel.hide();
      let columnNumber = 0;
      let objMailBoxGridColdefs = new MailBoxGridColdefs();
      this.isDefaultColumns = true;
      // Please donnt use this block for search or cutom view add specific condition and write required login in that
      // because its creating multiple issues
      if (this.inboxView === "vertical") {
        /* let defaultColsState=objMailBoxGridColdefs.getDefaultColumnState();
        this.setColumnsAsperDB(defaultColsState); */
        this.columnDefs.forEach(x => {
          if (x.headerName === "FROM" || x.headerName === "SUBJECT" || x.headerName === "ASSIGNED TO"
            || x.headerName === "ASSIGNED FROM" || x.headerName === "AGE" || x.headerName === "RECEIVED" || // C153176-5019 AGE(DAYS) --> AGE
            x.headerName === "ANNEX" || x.field === "cheveron" || x.field == "expander" || x.field === "cheveron_1" ||
            x.field === "isSymphonyChatGridCol") {
            this.gridColumnApi.setColumnVisible(x.field, true);
            x.hide = false;
            x.showInSecondary = false;
          } else {
            this.gridColumnApi.setColumnVisible(x.field, false);
            x.hide = true;
            x.showInSecondary = true;
          }
        });
      } else if (this.inboxView === "horizontal") {
        this.columnDefs.forEach(x => {
          if (x.headerName === "FROM" || x.headerName === "SUBJECT" || x.headerName === "ASSIGNED TO"
            || x.headerName === "ASSIGNED FROM" || x.headerName === "AGE" || x.headerName === "RECEIVED" || // C153176-5019 AGE(DAYS) --> AGE
            x.headerName === "ANNEX" || x.field === "cheveron" || x.field === "cheveron_1" || x.field == "expander"
            || x.headerName === "REQUEST TYPE" || x.headerName === "PROCESSING REGION" || x.field === "isSymphonyChatGridCol") {
            this.gridColumnApi.setColumnVisible(x.field, true);
            x.hide = false;
          } else {
            this.gridColumnApi.setColumnVisible(x.field, false);
            x.hide = true;
          }
        });
      } else {
        this.columnDefs.forEach(x => {
  
          if (x.headerName != "ANNEX") {
            this.gridColumnApi.setColumnVisible(x.field, false);
            x.hide = true;
          }
          if (this.inboxView != "horizontal") {
            if (x.headerName == "FROM" || x.headerName == "SUBJECT" || x.headerName == "ASSIGNED TO"
              || x.headerName == "ASSIGNED FROM" || x.headerName == "AGE" || (x.headerName === "STATE" && this.strMailboxView === "SEARCH")
            ) {
              // verify whether the flag is a customized column (in a customized view) before enabling it            
              if (!this.customViewFlag || this.isCustomizedColumn(x)) {
                columnNumber = columnNumber + 1;
                this.gridColumnApi.setColumnVisible(x.field, true);
                x.hide = false;
                if (x.headerName != "ANNEX" && x.headerName != "AGE") {
                  x.cellRenderer = undefined;
                }
                if (columnNumber == 1 && x.cellRenderer != "dragGripCellRenderer") {
                  x.cellRenderer = "dragGripCellRenderer";
                }
                if (columnNumber == 2 && x.cellRenderer != "agGroupCellRenderer") {
                  x.cellRenderer = "agGroupCellRenderer";
                }
              }
            }
          }
          else if (this.inboxView == "horizontal") {
            if (x.headerName == "FROM" || x.headerName == "SUBJECT" || x.headerName == "ASSIGNED TO"
              || x.headerName == "ASSIGNED FROM" || x.headerName == "AGE" || x.headerName == "OWNERSHIP"
              || x.headerName == "REQUEST TYPE" || x.headerName == "PROCESSING REGION" ||
              (x.headerName === "STATE" && this.strMailboxView === "SEARCH") ||
              (x.headerName === "STATE" && this.isMatchingViewName("intensityHeatMap"))
            ) {
              // verify whether the flag is a customized column (in a customized view) before enabling it
              if (!this.customViewFlag || this.isCustomizedColumn(x)) {
                columnNumber = columnNumber + 1;
                this.gridColumnApi.setColumnVisible(x.field, true);
                x.hide = false;
  
                if (x.headerName != "ANNEX" && x.headerName != "AGE") {
                  x.cellRenderer = undefined;
                }
                if (columnNumber == 1 && x.cellRenderer != "dragGripCellRenderer") {
                  x.cellRenderer = "dragGripCellRenderer";
                }
                if (columnNumber == 2 && x.cellRenderer != "agGroupCellRenderer") {
                  x.cellRenderer = "agGroupCellRenderer";
                }
              }
            }
          }
          else if (this.isCustomizedColumn(x)) {
            columnNumber = columnNumber + 1;
            this.gridColumnApi.setColumnVisible(x.field, true);
            x.hide = false;
  
            if (x.headerName != "ANNEX" && x.headerName != "AGE") {
              x.cellRenderer = undefined;
            }
            if (columnNumber == 1 && x.cellRenderer != "dragGripCellRenderer") {
              x.cellRenderer = "dragGripCellRenderer";
            }
            if (columnNumber == 2 && x.cellRenderer != "agGroupCellRenderer") {
              x.cellRenderer = "agGroupCellRenderer";
            }
          }
        });
      }
  
      /* this.gridApi.setColumnDefs(this.columnDefs); */
  
      /*  this.autoFitGridColumns(); */
      this.lstmailBoxColumnChooser = [];
      let objinboxColumnChooserHelper = new MailboxColumnChooserHelper();
      this.lstmailBoxColumnChooser = objinboxColumnChooserHelper.createColumnChooserList(this.columnDefs);
      this.colDefsService.setcolDefs(this.strMailboxView, this.columnDefs);
      // C153176-5411 Grid is not responsive. On 1st download need to always reset for it to fit all columns
      if (this.gridColumnApi) {
        this.gridAutoFitDefaultColumnsByResolution();
      }
      this.gridApi.sizeColumnsToFit();
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Column Chooser - Reset", "Click", "Column Chooser - Reset", 0);
    }
    secondaryClmnresetClick(event: any, opSecondaryColumnChooser: any) {
      console.log("secondaryClmnresetClick");
      console.log("secondaryClmnresetClick");
      this.checkboxListComponent.first.clearFilterText();
      this.checkboxListComponent.first.resetCheckList();
      //Add logic to show selected checkboxes
      this.SecondarycolumnChooserSelectedColumns = [];
      this.lstmailBoxSecondaryColumnChooser.forEach(column => {
        column.binary = true;
        delete column["checkedStatus"];
        this.SecondarycolumnChooserSelectedColumns.push(column);
      });
      this.checkboxListComponent.first.selectAllCheckBoxws();
      this.checkboxListComponent.first.items = this.lstmailBoxSecondaryColumnChooser;
      this.checkboxListComponent.first.assignCopy();
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Secondary Column Chooser - Reset", "Click", "Secondary Column Chooser - Reset", 0);
    }
  
    resizeGrid() {
      //this.gridApi.sizeColumnsToFit();
      this.autoFitGridColumns();
    }
  
    getElement() {
      if (this.theElement) {
        return this.theElement.nativeElement;
      }
    }
    setText(selector, text) {
      document.querySelector(selector).innerHTML = text;
    }
    setLastButtonDisabled(disabled) {
      //document.querySelector("#btLast").disabled = disabled;
    }
    /** Start - Operation emitted from mail-view components are handled here */
  
    /**
     * Method to handled the attachment event emmitted from mail-view component and populate the attachmentList.
     */
    onAttachment(attachments: any): void {
      this.attachmentsList = attachments || [];
    }
  
    /**
    * Method to handled the note event emmitted from mail-view component and populate the notes.
    */
    onNote(notes: any): void {
      let notesDetails = notes || [];
      if (this.selectedMail && this.selectedMail.inquiry) {
        this.selectedInquiryId = this.selectedMail.inquiry._id; // Unable to show add note.
      } else if (this.selectedDraftMail) {
        this.selectedInquiryId = this.selectedDraftMail.draft.id;
      }
  
      // C153176-6021: Update the grid when nlp reject reason is added.
      if (notesDetails.length > 0) {
        let addedNote = notesDetails.filter(note => note.origin == 'nlp');
        if (addedNote && addedNote.length > 0) {
          this.onNotesAdded(addedNote[0]);
  
        }
      }
      this.notesList = [];
      Array.prototype.push.apply(this.notesList, notesDetails);
  
      if (!this.cdr['destroyed']) {  // C153176-4614: check whether the view is destroyed before enforcing change
        this.cdr.detectChanges();
      }
    }
  
    /**
    * Method to handled the audit event emmitted from mail-view component and populate the audits.
    */
    onAudit(audits: any): void {
      this.auditsList = audits || [];
      if (!this.cdr['destroyed']) {// C153176-4614: check whether the view is destroyed before enforcing change
        this.cdr.detectChanges();
      }
    }
  
    /**
    * Method to set CLC object on inquiry selection.
    */
    setClcDetails(groupId: any): void {
      let clcData = {
        inquiryId: this.selectedInqId,
        groupId: groupId,
        groupName: this.assignedGroup,
        tabId: this.selectedInqId
      };
      this.clc = { ...clcData };
    }
    /** End - Operation emitted from mail-view components are handled here */
    arrangeColumnForHorizontalView() {
      this.hideColumns();
      this.unHideColumnsHorizontalView();
      let objinboxColumnChooserHelper = new MailboxColumnChooserHelper();
      this.lstmailBoxColumnChooser = [];
      this.lstmailBoxColumnChooser = objinboxColumnChooserHelper.createColumnChooserList(this.columnDefs);
      let objinboxGroupByHelper = new InboxGroupByHelper();
      this.lstmailBoxGrpByChooser = objinboxGroupByHelper.createGroupByList(this.columnDefs);
      this.checkboxListComponent.first.items = this.lstmailBoxGrpByChooser;
      this.checkboxListComponent.first.assignCopy();
    }
  
    arrangeColumnForVerticleView() {
      this.hideColumns();
      this.unHideColumnsVerticalView();
      let objinboxColumnChooserHelper = new MailboxColumnChooserHelper();
      this.lstmailBoxColumnChooser = [];
      this.lstmailBoxColumnChooser = objinboxColumnChooserHelper.createColumnChooserList(this.columnDefs);
      let objinboxGroupByHelper = new InboxGroupByHelper();
      this.lstmailBoxGrpByChooser = objinboxGroupByHelper.createGroupByList(this.columnDefs);
      this.checkboxListComponent.first.items = this.lstmailBoxGrpByChooser;
      this.checkboxListComponent.first.assignCopy();
    }
  
    hideColumns() {
      /*  if (this.isDraftsView) {
         // skipping hideColumns if draft view
         return;
       }
       let storedColdefs = JSON.parse(sessionStorage.getItem(this.strMailboxView + "col_state"));
       //dont reset columns visibility if its already saved in DB
       if (storedColdefs) {
         return;
       }
       this.columnDefs.forEach(x => {
         if (x.headerName != "ANNEX" && x.headerName != "AGE" && x.hide == false) {
           if (this.gridColumnApi) {
             this.gridColumnApi.setColumnVisible(x.field, false);
             x.hide = true;
           }
         }
   
       }); */
    }
  
    hideColumnsForDraftsView() {
      this.columnDefs.forEach(x => {
        this.gridColumnApi.setColumnVisible(x.field, false);
        this.gridColumnApi.setColumnVisible("avgResolutionTimeQMA", false);
        x.hide = true;
      });
    }
  
    unHideColumnsForDraftsView() {
      this.gridColumnApi.setColumnVisible("avgResolutionTimeQMA", false);
      this.gridColumnApi.setColumnVisible("urgentFlag", true);
      this.gridColumnApi.setColumnVisible("attchFlag", true);
      this.gridColumnApi.setColumnVisible("_id", true);
      this.gridColumnApi.setColumnVisible("subject", true);
      this.gridColumnApi.setColumnVisible("crtDate", true);
      this.gridColumnApi.setColumnVisible("modDate", true);
      this.gridColumnApi.setColumnVisible("age", true);
      this.gridColumnApi.setColumnVisible("modDate.$date", true);
    }
  
    unHideColumnsHorizontalView() {
      /*  var colNumber = 0;
       for (let col of this.columnDefs) {
         colNumber = colNumber + 1;
         if (colNumber == 8) {
           break;
         }
         this.gridColumnApi.setColumnVisible(col.field, true);
         col.hide = false;
         }; */
      if (this.isDraftsView) {
        return;
      }
      // fix for C153176-4558	
      if (this.storedColdefs) {
        return;
      }
      this.columnDefs.forEach(col => {
        if (col.headerName == "FROM" || col.headerName == "SUBJECT" || col.headerName == "ASSIGNED TO" || col.headerName == "ASSIGNED FROM"
          || col.headerName == "AGE" || col.headerName == "ANNEX" || col.headerName == "" || col.headerName == "RECEIVED"// C153176-5019 AGE(DAYS) --> AGE
          || col.headerName == "PROCESSING REGION" || col.headerName == "REQUEST TYPE" || (col.headerName === "STATE" && this.strMailboxView === "SEARCH")) {
          // unhide if it is not customized view or it is a customized column
          if (!this.customViewFlag || this.isCustomizedColumn(col)) {
            if (this.gridColumnApi) {
              col.hide = false;
              this.gridColumnApi.setColumnVisible(col.field, true);
            }
          }
        }
        else {
          // hide if it is not customized view or it is a not customized colum (in a customized view)
          if ((!this.customViewFlag || !this.isCustomizedColumn(col)) && !this.isColumnSelectedByChooser(col)) {
            if (this.gridColumnApi) {
              col.hide = true;
              this.gridColumnApi.setColumnVisible(col.field, false);
            }
          }
          else if (this.isCustomizedColumn(col)) {
            if (this.gridColumnApi) {
              col.hide = false;
              this.gridColumnApi.setColumnVisible(col.field, true);
            }
          }
        }
      })
    }
    unHideColumnsVerticalView() {
      if (this.isDraftsView) {
        return;
      }
  
      /* let storedColdefs = JSON.parse(sessionStorage.getItem(this.strMailboxView + "col_state")); */
      //dont reset columns visibility if its already saved in DB
      // fix for C153176-4558
      if (this.storedColdefs) {
        return;
      }
      this.columnDefs.forEach(col => {
        if (col.headerName == "FROM" || col.headerName == "SUBJECT" || col.headerName == "ASSIGNED TO" || col.headerName == "ASSIGNED FROM"
          || col.headerName == "AGE" || col.headerName == "ANNEX" || col.headerName == "" || col.headerName == "RECEIVED"// C153176-5019 AGE(DAYS) --> AGE
          || (col.headerName === "STATE" && this.strMailboxView === "SEARCH") ||
          (col.headerName === "STATE" && this.isMatchingViewName("intensityHeatMap"))) {
          // unhide if it is not customized view or it is a customized column
          if (!this.customViewFlag || this.isCustomizedColumn(col)) {
            if (this.gridColumnApi) {
              col.hide = false;
              this.gridColumnApi.setColumnVisible(col.field, true);
            }
          }
        }
        else {
          // hide if it is not customized view or it is a not customized colum (in a customized view)
          if ((!this.customViewFlag || !this.isCustomizedColumn(col)) && !this.isColumnSelectedByChooser(col)) {
            if (this.gridColumnApi) {
              col.hide = true;
              this.gridColumnApi.setColumnVisible(col.field, false);
            }
          }
          else if (this.isCustomizedColumn(col)) {
            if (this.gridColumnApi) {
              col.hide = false;
              this.gridColumnApi.setColumnVisible(col.field, true);
            }
          }
        }
      })
      //this.gridApi.sizeColumnsToFit();
      /* window.setTimeout(() => {
       this.gridApi.sizeColumnsToFit();   //<<<---    using ()=> syntax
       //this.gridApi.sizeColumnsToFit();
     }, 300); */
  
    }
  
    // C153176-4457 : Check if Inline reply is already open is yes then save the changes to Draft and open the double clicked Inquiry in new Tab
    rowDoubleClicked(event: any) {
      try {
        // symphony changes
        if (this.gridApi && this.gridApi.getSelectedRows() && this.gridApi.getSelectedRows()[0]) {
          if (this.checkIfSymphonyRow(this.gridApi.getSelectedRows()[0])) {
            return;
          }
        }
        if (this.inlineReply) {
          this.saveDraft();
          this.inlineReply = false;
          this.rowDoubleClicked(event);
        }
        else {
          if (this.isDraftsView) {
            const selectedGridRow = this.gridApi.getSelectedRows();
            const selGridRow = selectedGridRow.length == 1 ? selectedGridRow[0] : null;
            let inquiryData = {
              draftId: selGridRow._id
  
            };
            this.newMessageService.setDraftSubject(inquiryData.draftId);
            this.tabDataService.sendTabData("DRAFT:" + inquiryData.draftId, 0)
          } else {
            if (event && event.data && event.data._id) {
              this.doubleClickedInqId = event.data._id;
              const dblClickedId = event.data._id;
              // this timeout function handles the case when the double-clicked inquiry was not queried
              // from backend service, here it will re-trigger the query
              setTimeout(() => {
                if (event.data._id === dblClickedId) { // doubleClickedInqId becomes undefined some times.
                  this.doubleClickedInqId = dblClickedId;
                  console.log('re-triggering tab vieof the double-clicked inquiry', event.data._id);
                  let selectInqAssignedGroupId = [];
                  let selGridRow = event.data;
                  if (selGridRow.workflows) {
                    selGridRow.workflows.forEach(workflow => {
                      let assignedGroupId = workflow.assignedGroupId;
                      if (this.userDataService.userGroupIdList.indexOf(assignedGroupId) > -1) {
                        selectInqAssignedGroupId.push(assignedGroupId);
                      }
                    });
                  }
                  const assignedGroup = selGridRow.openGroups;
                  // C153176-5298 | Pass lock and lockedBy from selected inquiry.
                  let workflow = {
                    queryCount: selGridRow.queryCount,
                    requestType: selGridRow.requestTypeStr || null,
                    inquirySource: selGridRow.inquirySource,
                    processingRegion: selGridRow.processingRegion,
                    rootCause: selGridRow.rootCause,
                    tag: selGridRow.tag,
                    lock: selGridRow.lock,
                    lockedBy: selGridRow.lockedBy,
                    groupId: selectInqAssignedGroupId // C153176-5889 | Pass groupId for CLC and NLP suggestion.
                  };
                  let curInq = this.viewNotificationData != null ? this.viewNotificationData : selGridRow;
  
                  // C153176-5223 | conversation loading issue for nomination
                  let hasNominatedOwnership = selGridRow.workflows[0].hasNominatedOwnership !== undefined ? selGridRow.workflows[0].hasNominatedOwnership : false;
  
                  let inquiryData = {
                    id: curInq._id,
                    selectInqAssignedGroupId: this.viewNotificationData != null ?
                      [this.viewNotificationData.latestGroupId] : selectInqAssignedGroupId,
                    isAllConvContent: false, // C153176-4660: do not load all content upon first load of conv view
                    hasNominatedOwnership: hasNominatedOwnership
                  };
                  this.notificationService.setNotificationInquiry(selGridRow);// C153176-5295: cache entire inquiry
                  // Send request to get the all the inquiry conversation for the selected inquiry Id.
                  this.inboxService.getInquiryIdExtSelected(inquiryData, curInq).subscribe(inquiryDetail => {
                    this.requestCacheService.updateInquiryCache(curInq, inquiryData.isAllConvContent, inquiryDetail);
                    // C153176-6119 - Set inquiry to data while passing to tab service.
                    inquiryData['inquiry'] = inquiryDetail.inquiry;
                    this.inqData = inquiryData;
                    console.log('Successfully get the Inquiry details, id = : ' + inquiryData.id);
                    this.selectedMail = inquiryDetail;
                    this.assignedGroup = assignedGroup;
                    this.selectedInqId = inquiryDetail.inquiry ? inquiryDetail.inquiry.id : null;
                    this.selectedConvId = null;
                    this.inquiryWorkflow = workflow;
                    this.cdr.detectChanges();
                    this.viewNotificationData = null;
                    // C153176-4910: end conversation loading status tracking
                    this.endTrackingConvLoading(inquiryData.id, assignedGroup); // C153176-4614: fix on group id vs. name mismatch
                    this.gridOprService.clearDetailViewConversation(this.strMailboxView);
                    // re-trigger tab with the current selected row
                    this.tabDataService.setInquiryData({
                      selectedMail: this.selectedMail,
                      assignedGroup: this.assignedGroup,
                      selectedConvId: this.selectedConvId,
                      selectedInqId: this.selectedInqId,
                      inquiryWorkflow: this.inquiryWorkflow,
                      // C153176-4999: add subject in the inquiry data so it may be carried over to mail detail view
                      inquirySubject: this.selectedInquirySubject,
                      inquiryReportedConvCount: curInq.convCount || 0, // C153176-5308
                      mailContainerHeight: this.currentHeight,
                      inqData: this.inqData || null, // C153176-5308
                      inboxViewMode: 'tab',
                      // C153176-4985: add viewname
                      inboxViewName: this.getViewType(this.strMailboxView),
                      selectedRowData: [selGridRow] // C153176-5901 | Pass selected row to poplate resolve popup.
  
                    });
                    this.tabDataService.sendTabData("INQUIRY:" + this.doubleClickedInqId, 0);
                    this.doubleClickedInqId = undefined;
                  },
                    error => {
                      // C153176-4910: end conversation loading status tracking
                      this.endTrackingConvLoading(inquiryData.id, assignedGroup); // C153176-4614: fix on group id vs. name mismatch
                    });
                }
              }, 2500);
            }
          }
        }
      }
      catch (exception) {
        console.log("Exception occured on inq DoubleClicked :-" + exception.stack);
      }
    }
  
    ngOnDestroy() {
      // unsubscribe to ensure no memory leaks
      this.gridApi.hidePopupMenu();
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
  
      if (this.gridOprSubscription) {
        this.gridOprSubscription.unsubscribe();
      }
      if (this.tabClickedSubscription) {
        this.tabClickedSubscription.unsubscribe();
      }
      if (this.inlineSusbscription) {
        this.inlineSusbscription.unsubscribe();
      }
      // C153176-4660 clear cache on this mailbox
      this.gridOprService.clearSelectedConversation(this.strMailboxView);
      this.gridOprService.clearDetailViewConversation(this.strMailboxView);
      // C153176-4660 clear request cache from this mailbox
      this.requestCacheService.cleanupCache(true);
      if (this.wsWorker) {
        console.debug(`webworker: tab: ${this.currentTabName} terminating web worker on tab close`);
        this.wsWorker.terminate();
        this.wsWorker = null;
      }
      if(this.inboxService.wsQueue){
        this.inboxService.wsQueue.delete(this.currentTabName);
      }
      // if(this.userDataSubscription){
      //   this.userDataSubscription.unsubscribe();
      // }
    }
  
    resetMailboxColumnsVisibility() {
      this.columnDefs.forEach(col => {
        if (col.headerName == "FROM" || col.headerName == "SUBJECT" || col.headerName == "ASSIGNED TO" || col.headerName == "ASSIGNED FROM"
          || col.headerName == "AGE" || col.headerName == "ANNEX" || col.headerName == "" || col.headerName == "RECEIVED"// C153176-5019 AGE(DAYS) --> AGE
          || (col.headerName === "STATE" && this.strMailboxView === "SEARCH")) {
          // verify whether the flag is a customized column (in a customized view) before enabling it
          if (!this.customViewFlag || this.isCustomizedColumn(col)) {
            col.hide = false;
          }
        }
        else if (this.isCustomizedColumn(col)) {
          col.hide = false;
        }
        else {
          col.hide = true;
        }
        /* col.resizable=true; */
      })
    }
  
    onConversationHistorySelected(selectedInqConv: any): void {
      if (selectedInqConv.length > 0 && this.strMailboxView === selectedInqConv[0].currentTab) {
  
        let inquiryData = {
          id: selectedInqConv[0].selectedInqId,
          selectInqAssignedGroupId: selectedInqConv[0].assignedGrpId,
          inquiry: selectedInqConv[0].inquiry, // C153176-4660 augment request data to be cached
        };
  
        let assignedGrp = selectedInqConv[0].assignedGroup;
        let selWorkFlow = selectedInqConv[0].workflow;
  
        // Workflow properties
        // C153176-5298 | Pass lock and lockedBy from selected inquiry.
        let workflow = {
          queryCount: selWorkFlow.queryCount,
          requestType: selWorkFlow.requestType || null,
          inquirySource: selWorkFlow.inquirySource,
          processingRegion: selWorkFlow.processingRegion,
          rootCause: selWorkFlow.rootCause,
          tag: selWorkFlow.tag, // C153176-5317 - Pass tag for conversation.
          lock: selWorkFlow.lock || null,
          lockedBy: selWorkFlow.lockedBy || null,
          groupId: selectedInqConv[0].assignedGrpId
        };
        // C153176-4910: check conversation request is required to be made
        // webSocket redesign
        if (!this.isWebSocketRedesignEnable) {
          if (!this.gridOprService.updateConversationRequest(inquiryData, selectedInqConv[0].selectedConvId)) {
            console.debug('Skip loading inquity details, id =' + inquiryData.id + ',group =', inquiryData.selectInqAssignedGroupId
              + ", convId =" + selectedInqConv[0].selectedConvId);
            // C153176-4910: end conversation loading status tracking
            this.endTrackingConvLoading(inquiryData.id, assignedGrp); // C153176-4614: fix on group id vs. name mismatch
            return;
          }
        }
  
        let conversationInquiryData = {
          convId: selectedInqConv[0].selectedConvId,
          inquiryId: selectedInqConv[0].selectedInqId,
          groupId: selectedInqConv[0].assignedGrpId
        };
        // Send request to get the inquiry conversation by conversation Id.
        // this.inboxService.getInquiryIdExtSelected(inquiryData).subscribe(inquiryDetail => {
        // C170665-524 Performance Issue - While switching an inquiry in the grid
        // this.showSpinner = true;
        console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.onConversationHistorySelected");
        this.convSelectionStarted = true;
         this.loadingConversationInProgress = true;
         this.inboxService.getInquiryConversationById(conversationInquiryData).subscribe(conversationDetail => {
           // [C170665-1262] Loading: QMA loader should appear on Load email view panel only
          this.loadingConversationInProgress = false;
          // C170665-524 Performance Issue - While switching an inquiry in the grid
          this.convSelectionStarted = false;
          // C153176-5948 | Update conversation panel when select conversation from conversation history
          let selectConv = conversationDetail;
          selectConv.inquiry = inquiryData.inquiry;
          this.selectedMail = { ...selectConv };
          this.selectedInquirySubject = conversationDetail.conversationList[0].subject;
  
          this.inqData = inquiryData; // C153176-4660 set request object for conv view usage
          this.assignedGroup = assignedGrp;
          this.inquiryWorkflow = workflow;
          this.selectedConvId = selectedInqConv[0].selectedConvId;
          this.selectedInqId = selectedInqConv[0].selectedInqId;
  
          // C153176-4910: end conversation loading status tracking if any
          this.endTrackingConvLoading(inquiryData.id, assignedGrp); // C153176-4614: fix on group id vs. name mismatch
          this.setClcDetails(selectedInqConv[0].assignedGrpId);
          if (this.gridApi) {
            this.gridApi.deselectAll();
          }
  
          // C170665-673 | Update the selected inquiry details.
          this.selInquiryDetails = this.getSelectedInquiryDetails();
  
          this.gridOprService.setDetailViewConversation(this.strMailboxView, this.selectedInqId,
            this.selectedConvId, this.assignedGroup, [selectedInqConv[0].assignedGrpId])
        },
          error => {
            // C153176-4910: end conversation loading status tracking if any
            this.endTrackingConvLoading(inquiryData.id, assignedGrp); // C153176-4614: fix on group id vs. name mismatch
            console.error('Error while getting response from getInquiryConversationById()');
          });
      }
    }
  
    createColumnChooserList(mailboxColumn): MailBoxColumnChooser[] {
      let lstmailBoxColumnChooser: MailBoxColumnChooser[] = new Array();
      mailboxColumn.forEach((cols) => {
  
        if (cols.headerName == "ANNEX") {
          /*C153176-4570 class added for annex column when advance search*/
          if (this.strMailboxView == "SEARCH") {
            cols.headerClass = "qma-mailbox-header";
          } else {
            //cols.headerClass = "qma-mailbox-cell";        //// annex column resize issue -C153176-4904
          }
        }
        if (cols.headerName !== "ANNEX" && cols.headerName !== "Attachment" && cols.headerName !== "Notes"
          && cols.headerName !== "Follow Up" && cols.headerName !== "Suggestion Indicator"
          && cols.headerName !== "Attachement" && cols.headerName !== "Category") {
          if (cols.hide == false && cols.field !== "cheveron" && cols.field !== "cheveron_1" &&
            cols.field !== "checkbox" && cols.field !== "expander" && cols.field !== "isSymphonyChatGridCol") {
            lstmailBoxColumnChooser.push(
              {
                binary: true,
                label: cols.headerName.toUpperCase(),
                name: cols.headerName.toUpperCase(),
                value: cols.headerName.toUpperCase()
              }
  
            );
          }
          else if (cols.field !== "cheveron" && cols.field !== "cheveron_1" && cols.field !== "checkbox" && cols.field !== "expander"
            && cols.field !== "isSymphonyChatGridCol") {
            lstmailBoxColumnChooser.push(
              {
                binary: false,
                label: cols.headerName.toUpperCase(),
                name: cols.headerName.toUpperCase(),
                value: cols.headerName.toUpperCase()
              })
          }
        }
  
      })
      return lstmailBoxColumnChooser;
  
    }
  
    filterChanged(params) {
      //params.api.rowModel.rowsToDisplay[0].data.latestUserName="<span class='highlight'>Highlighted</span>"
      // C153176-4300 - On filter inbox items.
      const filteredRow = params.api.getModel().rootNode.childrenAfterFilter;
      if (filteredRow.length === 0) {
        this.selectedMail = null;
        this.inqData = null;
        this.gridOprService.clearDetailViewConversation(this.strMailboxView);
        // C153176-4660: reset mail request and conversation detail cache
        this.gridOprService.clearConversationRequestData();
      } else {
        const selectedGridRow = this.gridApi.getSelectedRows();
        const rows = selectedGridRow && selectedGridRow.length ?
          filteredRow.filter(row => row.data._id === selectedGridRow[0]._id && row.data.openGroups === selectedGridRow[0].openGroups) : [];
        if (rows.length > 0) {
          this.onSelectionChanged();
        }
        else if (rows.length === 0) {
          // C153176-4482 : If the search result doesn't contain selected row. We need to select the first item.
          if (filteredRow.length > 0) {
            this.gridApi.deselectAll();
            // Select the row once updated
            const me = this; //Jira - C153176-4876 mail box stats issue
            this.gridApi.forEachNode(function (node) {
              if (node.data._id === filteredRow[0].data._id && node.data.openGroups === filteredRow[0].data.openGroups) {
                me.selectOpenConvInquiryNode(node, true);
              }
            });
          }
          else {
            this.selectedMail = null;
            this.inqData = null; // C153176-4660: reset mail request and conversation detail cache
            this.gridOprService.clearDetailViewConversation(this.strMailboxView);
            this.gridOprService.clearConversationRequestData();
          }
        }
      }
      this.updateCountLabels();
    }
  
    firstDataRendered(params) {
  
    }
  
    setGridwidthLessThansixColumns() {
      var visibleCols = this.columnDefs.filter(col => col.hide == false);
      if (this.inboxView === "vertical") {
        if (visibleCols.length < 11) {
          //this.gridApi.sizeColumnsToFit();
          this.autoFitGridColumns();
        }
      } else if (this.inboxView === "horizontal") {
        if (visibleCols.length < 14) {
          //this.gridApi.sizeColumnsToFit();
          this.autoFitGridColumns();
        }
      }
  
    }
    selectedFilter = {};
    filterInbox(event, overlaypanel) {
      // Get a reference to the name filter instance
      /*  let filterInstance = this.gridApi.getFilterInstance("openGroups");
       filterInstance.setModel({filterType: "text", type: "equals", filter: "*GT IN ICG IN ExpMgmt XMC Manual Group2"});
       this.gridApi.onFilterChanged(); */
      this.gridApi.setFilterModel(null);
  
      let _selectedFilter = {};
      let tempSelAssignedGrps: string[] = [];
      if (this.selAssignedGrps && this.selAssignedGrps.length > 0) {
        let filterInstance = this.getFilterInstance("openGroups");
        let filterModel = this.getMultiFilterModel();
        this.selAssignedGrps.forEach((element, index) => {
          if (this.selAssignedGrps.length === 1) {
            filterInstance.setModel(this.getSingleFilterModel(element.label));
            this.gridApi.onFilterChanged();
          } else {
            this.getMultiFilterData(filterModel, index, element.label)
  
          }
  
        });
        if (this.selAssignedGrps.length > 1) {
          filterInstance.setModel(filterModel);
          this.gridApi.onFilterChanged();
        }
  
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by assigned groups", "Click", "Filter by assigned group", 0);
  
      }
      let tempAssignedOwners: any[] = [];
      if (this.selOwner && this.selOwner.length > 0) {
        let filterInstance = this.getFilterInstance("openUsers");
        let filterModel = this.getMultiFilterModel();
        this.selOwner.forEach((element, index) => {
          if (this.selOwner.length === 1) {
            filterInstance.setModel(this.getSingleFilterModel(element.label));
            this.gridApi.onFilterChanged();
          } else {
            this.getMultiFilterData(filterModel, index, element.label)
  
          }
  
        });
        if (this.selOwner.length > 1) {
          filterInstance.setModel(filterModel);
          this.gridApi.onFilterChanged();
        }
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by assigned owners", "Click", "Filter by assigned owners", 0);
      }
  
      let tempSelReqTypes: string[] = [];
      if (this.selReqTypes && this.selReqTypes.length > 0) {
        let filterInstance = this.getFilterInstance("requestTypeStr");
        let filterModel = this.getMultiFilterModel();
  
        this.selReqTypes.forEach((element, index) => {
  
          if (this.selReqTypes.length === 1) {
            filterInstance.setModel(this.getSingleFilterModel(element.label));
            this.gridApi.onFilterChanged();
          } else {
            this.getMultiFilterData(filterModel, index, element.label)
  
          }
        });
        if (this.selReqTypes.length > 1) {
          filterInstance.setModel(filterModel);
          this.gridApi.onFilterChanged();
        }
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by request type", "Click", "Filter by request type", 0);
      }
      let tempSelTags: string[] = [];
      if (this.selTags && this.selTags.length > 0) {
        let filterInstance = this.getFilterInstance("tag");
        let filterModel = this.getMultiFilterModel();
  
        this.selTags.forEach((element, index) => {
          if (this.selTags.length === 1) {
            filterInstance.setModel(this.getSingleFilterModel(element.label));
            this.gridApi.onFilterChanged();
          } else {
            this.getMultiFilterData(filterModel, index, element.label)
  
          }
        });
        if (this.selReqTypes.length > 1) {
          filterInstance.setModel(filterModel);
          this.gridApi.onFilterChanged();
        }
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by tags", "Click", "Filter by tags", 0);
      }
      this.rowData.forEach(element => {
        if (!element.openUsers) {
          element.openUsers = null;
        }
      });
  
      let tempSelEmail: string[] = [];
      if (this.selEmail && this.selEmail.length > 0) {
        this.selEmail.forEach(element => {
          tempSelEmail.push(element.label);
          if (element.value === "FollowUp") {
            let filterInstance = this.getFilterInstance("followUp");
            filterInstance.setModel(this.getSingleFilterModel("Y"));
            this.gridApi.onFilterChanged();
            PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by email flags - followup", "Click", "Filter by email flags - followup", 0);
          }
          if (element.value === "Attachement") {
            let filterInstance = this.getFilterInstance("attchFlag");
            filterInstance.setModel(this.getSingleFilterModel("Y"));
            this.gridApi.onFilterChanged();
            PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by email flags - attachment", "Click", "Filter by email flags - attachment", 0);
          }
          if (element.value === "Read") {
            let filterInstance = this.getFilterInstance("readBy");
            filterInstance.setModel(this.getSingleFilterModel(this.loginUserInfo.userId));
            this.gridApi.onFilterChanged();
            PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by email flags - read", "Click", "Filter by email flags - read", 0);
          }
          if (element.value === "Unread") {
            _selectedFilter['readBy'] = {
              type: "notContains",
              filter: this.loginUserInfo.userId,
              filterTo: null
            };
            let filterInstance = this.getFilterInstance("readBy");
            filterInstance.setModel({ filterType: "text", type: "notContains", filter: this.loginUserInfo.userId });
            this.gridApi.onFilterChanged();
            PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by email flags - unread", "Click", "Filter by email flags - unread", 0);
          }
  
          let selAssigned: any = [];
          this.rowData.forEach(ele => {
            if (ele.openUsers != undefined && ele.openUsers != '' && ele.openUsers.length > 0) {
              if (selAssigned.indexOf(ele.openUsers) === -1) selAssigned.push(ele.openUsers);
            }
          });
          if (element.value === "Assigned") {
            _selectedFilter['openUsers'] = selAssigned;
            let filterInstance = this.getFilterInstance("openUsers");
            filterInstance.setModel({ filterType: "text", type: "contains", filter: this.loginUserInfo.userId });
            this.gridApi.onFilterChanged();
            PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by email flags - assigned", "Click", "Filter by email flags - assigned", 0);
          }
  
          if (element.value === "Unassigned") {
  
            let filterInstance = this.getFilterInstance("openUsers");
            filterInstance.setModel({ filterType: "text", type: "notContains", filter: this.loginUserInfo.userId });
            this.gridApi.onFilterChanged();
            PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by email flags - unassigned", "Click", "Filter by email flags - unassigned", 0);
          }
        });
  
        _selectedFilter['emailFlags'] = tempSelEmail;
      }
  
      let tempSelDays = [];
      let tempDay = [];
      if (this.selDays && this.selDays.length > 0) {
        let filterInstance = this.getFilterInstance("age");
        let filterModel = { "filterType": "number", "operator": "OR" };
  
        this.selDays.forEach((element, index) => {
  
          tempSelDays.push(parseInt(element.value));
          tempDay.push(element.label);
          if (this.selDays.length === 1) {
            filterInstance.setModel({ filterType: "number", type: "greaterThan", filter: parseInt(element.value) });
            this.gridApi.onFilterChanged();
          } else {
            this.getMultiFilterDataNumber(filterModel, index, element.label)
  
          }
        });
        if (this.selDays.length > 1) {
          filterInstance.setModel(filterModel);
          this.gridApi.onFilterChanged();
          PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by age days", "Click", "Filter by age days", 0);
        }
        _selectedFilter['age'] = {
          type: "greaterThan",
          filter: Math.min(...tempSelDays),
          filterTo: null,
          dummyValue: tempDay
        };
      }
      let tempSelHours = [];
      let tempHrs = [];
      if (this.selHours && this.selHours.length > 0) {
        let filterInstance = this.getFilterInstance("ageInHrs");
        let filterModel = { "filterType": "number", "operator": "OR" };
  
        this.selHours.forEach((element, index) => {
          tempSelHours.push(parseInt(element.value));
          tempHrs.push(element.label);
  
          if (this.selHours.length === 1) {
            filterInstance.setModel({ filterType: "number", type: "greaterThan", filter: parseInt(element.value) });
            this.gridApi.onFilterChanged();
          } else {
            this.getMultiFilterDataNumber(filterModel, index, element.label)
  
          }
        });
        if (this.selHours.length > 1) {
          filterInstance.setModel(filterModel);
          this.gridApi.onFilterChanged();
        }
        _selectedFilter['ageInHrs'] = {
          type: "greaterThan",
          filter: Math.min(...tempSelHours),
          filterTo: null,
          dummyValue: tempHrs
        };
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by age hours", "Click", "Filter by age hours", 0);
      }
      if (this.customCategory && this.customCategory.length > 0) {
        let filterInstance = this.getFilterInstance("category");
        let filterModel = this.getMultiFilterModel();
        this.customCategory.forEach((element, index) => {
          if (this.customCategory.length === 1) {
            filterInstance.setModel(this.getSingleFilterModel(element.label));
            this.gridApi.onFilterChanged();
          } else {
            this.getMultiFilterData(filterModel, index, element.label)
  
          }
  
        });
        if (this.customCategory.length > 1) {
          filterInstance.setModel(filterModel);
          this.gridApi.onFilterChanged();
        }
        PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Filter by client category", "Click", "Filter by client category", 0);
      }
      this.selectedFilter = {};
      this.selectedFilter = _selectedFilter;
      /* this.gridApi.setFilterModel(_selectedFilter);
      this.gridApi.onFilterChanged(); */
  
      overlaypanel.hide();
      this.isFilterFromApply = true;
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Apply Filter", "Click", "Apply Filter", 0);
    }
  
    filterDialogHide(overlaypanel) {
      if (!this.isFilterFromApply) {
        this.hideModal(overlaypanel)
      }
      this.isFilterFromApply = false;
      this.renderFilterBody  = false;
    }
  
    clearInboxFilter(event, overlaypanel) {
      this.gridApi.setFilterModel(null);
      this.gridApi.onFilterChanged();
  
      this.tag.clearFilterText();
      this.owner.clearFilterText();
      this.assignedG.clearFilterText();
      this.customCategoryfltr.clearFilterText();
      this.request.clearFilterText();
  
      let compArray = ["groups", "owner", "request", "tag", "email", "hours", "days", "customCategory"];
      this.checkboxListComponent.forEach((comp) => {
        let index = (compArray.indexOf(comp.name));
        if (index > -1) {
          comp.resetCheckList();
        }
      });
  
      this.selectedFilter = {};
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Clear Filter", "Click", "Clear Filter", 0);
    }
  
    hideModal(overlaypanel) {
      this.checkboxListComponent.forEach((comp) => {
  
        switch (comp.name) {
          case "groups":
            comp.setCheckedOnly(this.selectedFilter['openGroups']);
            break;
          case "owner":
            comp.setCheckedOnly(this.selectedFilter['openUsers']);
            break;
          case "request":
            comp.setCheckedOnly(this.selectedFilter['requestTypeStr']);
            break;
          case "tag":
            comp.setCheckedOnly(this.selectedFilter['tag']);
            break;
          case "email":
            comp.setCheckedOnly(this.selectedFilter['emailFlags']);
            break;
          case "hours":
            if (typeof this.selectedFilter['ageInHrs'] != "undefined") {
              comp.setCheckedOnly(this.selectedFilter['ageInHrs'].dummyValue);
            }
            else {
              comp.setCheckedOnly([]);
            }
            break;
          case "days":
            if (typeof this.selectedFilter['age'] != "undefined") {
              comp.setCheckedOnly(this.selectedFilter['age'].dummyValue);
            }
            else {
              comp.setCheckedOnly([]);
            }
            break;
        }
      });
      overlaypanel.hide();
    }
    // createLabeledArray(arr:any){
    //   let labledArray = [];
    //   arr.forEach(element => {
    //     labledArray.push({label: element});
    //   });
    //   return labledArray;
    // }
    setUserConversationView() {
      if (this.conversationViewId === "Horizontal")
        this.inboxView = "horizontal";
      else if (this.conversationViewId === "Vertical")
        this.inboxView = "vertical";
  
      this.maxSelectionColumnChooser = 50;
      // C170665-1766 provide Email as a Chat view in profile settings so user can save it to default.
      if(this.conversationViewId && typeof this.conversationViewId === 'string' &&   QmaConstant.CHAT_VIEW !== this.conversationViewId.toUpperCase()) {
        this.isChatView = false;
      } else if(this.conversationViewId && typeof this.conversationViewId === 'string' && QmaConstant.CHAT_VIEW === this.conversationViewId.toUpperCase()) {
        this.inboxView = "vertical";
      }
      //this.arrangeColumnForHorizontalView();
      if (this.inboxView == "horizontal") {
        window.setTimeout(() => {
          this.arrangeColumnForHorizontalView();   //<<<---    using ()=> syntax
          if (this.gridApi) {
            //this.gridApi.sizeColumnsToFit();
            this.autoFitGridColumns()
          }
        }, 300);
      } else if (this.inboxView == "vertical") {
        window.setTimeout(() => {
          this.arrangeColumnForVerticleView();   //<<<---    using ()=> syntax
          if (this.gridApi) {
            //this.gridApi.sizeColumnsToFit();
            this.autoFitGridColumns()
          }
        }, 300);
      }
  
    }
    //resize
    dragEnd(unit, { sizes }) {
      if (sizes[0] > 50) {
        //this.resizeGrid();
      }
  
    }
    // Null value check function
    isNull(input) {
      if (input === "" || input === undefined || input == null) {
        return true;
      }
      return false;
    }
  
    /**
     * update row data specified by index with the specified draft
     * @param index
     * @param draft
     */
    updateRowDataForDraft(index: number, draft: any) {
      if (!draft) {
        return;
      }
      const row = this.rowData[index];
      if (!row) {
        return;
      }
      row.subject = draft.subject;
      row.urgentFlag = draft.urgentFlag;
      if (draft.crtDate) {
        const timestamp = draft.crtDate;
        row.crtDate = { $date: new Date(timestamp).toLocaleString() };
      }
      if (draft.modDate) {
        const timestamp = draft.modDate;
        row.modDate = { $date: new Date(timestamp).toLocaleString() };
      }
    }
  
    /**
     * Convert new draft message to rowData format (_id, crtDate, modDate)
     */
    convertNewDraft(draft: any) {
      if (!draft) {
        return undefined;
      }
      if (draft.id !== null && draft.id !== undefined && draft._id === undefined) {
        draft._id = draft.id;
      }
      if (draft.crtDate && !draft.crtDate.$date) {
        const timestamp = draft.crtDate;
        draft.crtDate = { $date: new Date(timestamp).toLocaleString() };
      }
      if (draft.modDate && !draft.modDate.$date) {
        const timestamp = draft.modDate;
        draft.modDate = { $date: new Date(timestamp).toLocaleString() };
      }
      return draft;
    }
  
    tranformDateWithProfile(inq) {
      /* if (!this.isNull(inq.crtDate))
        inq.crtDate.$date = Object(this.dateFormatPipe.transform(inq.crtDate.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.modDate))
        inq.modDate.$date = Object(this.dateFormatPipe.transform(inq.modDate.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.reAgeDate))
        inq.reAgeDate.$date = Object(this.dateFormatPipe.transform(inq.reAgeDate.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.lockedDate))
        inq.lockedDate.$date = Object(this.dateFormatPipe.transform(inq.lockedDate.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.lastActionTime))
        inq.lastActionTime.$date = Object(this.dateFormatPipe.transform(inq.lastActionTime.$date, this.guserPrefDateFormat));
      if (!this.isNull(inq.reOpenDate))
        inq.reOpenDate.$date = Object(this.dateFormatPipe.transform(inq.reOpenDate.$date, this.guserPrefDateFormat)); */
  
    }
  
    addCheverontoInq(inq) {
      inq.cheveron = "";
      inq.checkbox = "";
      inq.cheveron_1 = "";
    }
  
    // close loader on grid complete
    rowDataChanged(params) {
      this.showSpinner = false;
      console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.rowDataChanged");
      this.sizeToFit();
      if (this.isWebSocketRedesignEnable) {
        this.restoreGridStateToBeforeWsUpdate();
      }
  
    }
    rowDataChangedMobile(params) {
      this.showSpinner = false;
      console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.rowDataChangedMobile");
    }
  
    refreshGridUpdateRow(inquiryIndex: number) {
      var api = this.gridApi;
      let rowNode = this.gridApi.getDisplayedRowAtIndex(inquiryIndex);
      this.refreshRow(rowNode, api);
    }
  
    callRefreshAfterMillis(params, millis, gridApi) {
      setTimeout(() => {
        gridApi.refreshCells(params);
        if (this.isWebSocketRedesignEnable) {
          // restore column filters
          if (this.objGridStateBeforeWsUpdate.sortModel) {
            this.loadGridColumnsSortData(this.objGridStateBeforeWsUpdate.sortModel)
            // this.gridApi.setSortModel(this.objGridStateBeforeWsUpdate.sortModel);
          }
        }
  
      }, millis);
    }
  
    refreshRow(rowNode, api) {
      var rowNodes = [rowNode];
      var params = {
        force: true,
        rowNodes: rowNodes
      };
      this.callRefreshAfterMillis(params, 1, api);
    }
  
    //to collapse all nodes after web socket update
    public CollpaseAllDetailNodes(value: boolean) {
      /*  this.gridApi.forEachNode(x => {
         x.setExpanded(false);
       }); */
    }
  
    // Add new inq at top
    addItemsAtTop(inq) {
      this.preprocessNewInq(inq);
      inq.cheveron = "";
      inq.checkbox = "";
      inq.cheveron_1 = "";
      inq.openUsers = this.processAssgnTo(inq.openUsers);
      // fix for  sort on the “Received” column for websocket
      inq.modDate.$date = new Date(inq.modDate.$date).toISOString();
      const source = inq;
      let dummyInq = Object.assign({}, this.rowData[0]);
      dummyInq = this.clearObject(dummyInq);
      let objCopy = Object.assign(dummyInq, source);
      objCopy.convCount = inq.workflows[0].convCount;
      if (!objCopy.convCount) {
        objCopy.convCount = 1;
      }
      if (!objCopy.websocket) {
        objCopy.websocket = true;
      }
      inq.latestUserName = this.formatDisplayName(inq.latestUserName, inq.latestEmail);
      // fix for JIRA C153176-4903
      // in case of multiple assigned group create multiple inq with same inq id
      let arrAssignedGroup = this.getAssignedGroupNames(objCopy);
      // C153176-5309: null check on assigned groups
      if (!arrAssignedGroup || !arrAssignedGroup.length) {
        return;
      }
      if (arrAssignedGroup.length > 1) {// in case of multiplpe assigned group
        this.addInqByAssgnedgrp(objCopy);
      } else if (this.isLoggedInUserConsistAssignedGrp(null, arrAssignedGroup[0])) {
        // fix for JIRA C153176-4903
        // in case of multiple assigned group create multiple inq with same inq id// in case of only one  assigned group
        this.rowData.unshift(objCopy);
        var res = this.gridApi.updateRowData({
          add: [objCopy],
          addIndex: 0
        });
        if (objCopy.expanded) {
          // C153176-4456: set 'expanded' to true on the newly added node and trigger opening of Conv History
          const rowNode = this.gridApi.getDisplayedRowAtIndex(0);
          if (rowNode) {
            rowNode.expanded = true;
            this.gridApi.onGroupExpandedOrCollapsed(0);
          }
        } else {
          // C153176-4642 UI Scattered for INbox after sending Inquiry
          // to programmatically open child grid
          //this.expandPreviouslyOpenedNode();
          // only re-expand if the newly added node was not expanded.
          setTimeout(() => {
            this.reExpandeConvHistNode(objCopy);
          }, 100);
        }
        this.updateCountLabels(objCopy);
      }
    }
    // 
    // Add new inq at top
    // C153176-5108 Websocket - Tag: inquiry is not updating in tag folder through websocket if creates a rule
    addItemsAtIndex(inq, index) {
      this.preprocessNewInq(inq);
  
      inq.cheveron = "";
      inq.checkbox = "";
      inq.cheveron_1 = "";
      inq.openUsers = this.processAssgnTo(inq.openUsers);
      // fix for  sort on the “Received” column for websocket
      inq.modDate.$date = new Date(inq.modDate.$date).toISOString();
      const source = inq;
      let dummyInq = Object.assign({}, this.rowData[0]);
      dummyInq = this.clearObject(dummyInq);
      let objCopy = Object.assign(dummyInq, source);
      objCopy.convCount = inq.workflows[0].convCount;
      if (!objCopy.convCount) {
        objCopy.convCount = 1;
      }
      if (!objCopy.websocket) {
        objCopy.websocket = true;
      }
      // fix for JIRA C153176-4903
      // in case of multiple assigned group create multiple inq with same inq id
      let arrAssignedGroup = this.getAssignedGroupNames(objCopy);
      // C153176-5309: null check on assigned groups
      if (!arrAssignedGroup || !arrAssignedGroup.length) {
        return;
      }
      if (arrAssignedGroup.length > 1) {// in case of multiplpe assigned group
        this.addInqByAssgnedgrpAtIndex(objCopy, index);
      } else if (this.isLoggedInUserConsistAssignedGrp(null, arrAssignedGroup[0])) {
        // fix for JIRA C153176-4903
        // in case of multiple assigned group create multiple inq with same inq id// in case of only one  assigned group
  
        // this.rowData.unshift(objCopy);
        this.rowData.splice(index, 0, objCopy);
        var res = this.gridApi.updateRowData({
          add: [objCopy],
          addIndex: index
        });
        if (objCopy.expanded) {
          // C153176-4456: set 'expanded' to true on the newly added node and trigger opening of Conv History
          const rowNode = this.gridApi.getDisplayedRowAtIndex(0);
          if (rowNode) {
            rowNode.expanded = true;
            this.gridApi.onGroupExpandedOrCollapsed(0);
          }
        } else {
          // C153176-4642 UI Scattered for INbox after sending Inquiry
          // to programmatically open child grid
          //this.expandPreviouslyOpenedNode();
          // only re-expand if the newly added node was not expanded.
          setTimeout(() => {
            this.reExpandeConvHistNode(objCopy);
          }, 100);
        }
        this.updateCountLabels();
      }
    }
  
    // Add new inq at top
    addItemsAtTopForNominateOwnership(inq) {
      this.preprocessNewInq(inq);
  
      inq.cheveron = "";
      inq.checkbox = "";
      inq.cheveron_1 = "";
      inq.openUsers = this.processAssgnTo(inq.openUsers);
      const source = inq;
      let dummyInq = Object.assign({}, this.rowData[0]);
      dummyInq = this.clearObject(dummyInq);
      let objCopy = Object.assign(dummyInq, source);
      let nOwnershipWf = objCopy.workflows.filter(wf => wf.direction === "NOMINATE_OWNERSHIP");
      objCopy.openGroups = "";
      nOwnershipWf.forEach(elementNOwnershipWf => {
        objCopy.openGroups = objCopy.openGroups + elementNOwnershipWf.assignedGroupName + ";"
      });
      objCopy.convCount = inq.workflows[0].convCount;
      if (!objCopy.convCount) {
        objCopy.convCount = 1;
      }
      if (!objCopy.websocket) {
        objCopy.websocket = true;
      }
      // in case of multiple assigned group create multiple inq with same inq id
      let arrAssignedGroup = this.getAssignedGroupNames(objCopy);
      // C153176-5309: null check on assigned groups
      if (!arrAssignedGroup || !arrAssignedGroup.length) {
        return;
      }
      if (arrAssignedGroup.length > 1) {// in case of multiplpe assigned group
        this.addInqByAssgnedgrp(objCopy);
      } else {// in case of only one  assigned group
        this.rowData.unshift(objCopy);
        var res = this.gridApi.updateRowData({
          add: [objCopy],
          addIndex: 0
        });
        if (objCopy.expanded) {
          // C153176-4456: set 'expanded' to true on the newly added node and trigger opening of Conv History
          const rowNode = this.gridApi.getDisplayedRowAtIndex(0);
          if (rowNode) {
            rowNode.expanded = true;
            this.gridApi.onGroupExpandedOrCollapsed(0);
          }
        } else {
          // C153176-4642 UI Scattered for INbox after sending Inquiry
          // to programmatically open child grid
          //this.expandPreviouslyOpenedNode();
          // only re-expand if the newly added node was not expanded.
          this.reExpandeConvHistNode(objCopy);
        }
        this.updateCountLabels();
      }
    }
  
    // C153176-4642 UI Scattered for INbox after sending Inquiry
    expandPreviouslyOpenedNode() {
      let rowIndex = 0;
      let expandedRowIndex = -1;
      this.gridApi.forEachNode(function (node) {
        if (node.expanded) {
          let rowIndex = Math.max(node.rowIndex - 1, 0);
          this.gridApi.onGroupExpandedOrCollapse(rowIndex);
        }
      });
  
    }
    // JIRA C153176-4596  Start
    // update existing item and move it totop
    updateExistingInqToMovetotop(updatedInq, existingInq, expanded) {
      let newInqs = [];
      let isPND_REAGE = updatedInq.workflows.filter(wf => wf.direction === "PND_REAGE");
      let matchingWfDir = ""
      if (isPND_REAGE && this.strMailboxView === "Pending Approvals") {
        matchingWfDir = "PND_REAGE"
      }
      else {
        matchingWfDir = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
      }
      matchingWfDir = matchingWfDir ? matchingWfDir : "IN";
      if (matchingWfDir) {
        // update matching workflows
        let matchingWf = updatedInq.workflows.filter(wf => wf.direction === matchingWfDir);
        if (matchingWf) {
          // create inquiries by workflows
          let inqIndex = 0;
          matchingWf.forEach(mwf => {
            let wfTOInsert = [];
            wfTOInsert.push(mwf);
            const returnedInq = Object.assign({}, updatedInq);
            returnedInq.openGroups = mwf.assignedGroupName;
            // C153176-5591 Emails are received to individuals who are not part of group
            returnedInq.openUsers = this.updateAssignedTo(returnedInq);
            returnedInq.followUp = this.returnInqFollowUpByWf(mwf) // Fix for JIRA C153176-4661
            newInqs.push(returnedInq);
            newInqs[inqIndex].workflows = wfTOInsert;
            inqIndex++;
  
          });
        }
      } else {
        // no 'matchingWfDir' found, take 'updatedInq'
        const returnedInq = Object.assign({}, updatedInq);
        newInqs.push(updatedInq);
      }
      if (newInqs.length > 0) {
        // C153176-4456: make the last inquiry inherit 'expanded' from existing inquiries (if any of them is expanded)
        newInqs[newInqs.length - 1].expanded = expanded;
      }
      return newInqs;
  
    }
    // JIRA C153176-4596  End
    // Fix for JIRA C153176-4661
    // return followup flag based on workflow
    returnInqFollowUpByWf(mwf) {
      return mwf.followUp && mwf.followUp.flag && mwf.followUp.flag.toLowerCase() === "generic" ? "Y" : "N";
    }
  
    // Add new inq at top
    addItemsAtTopINBOXForNominateOwnership(inq) {
      this.preprocessNewInq(inq);
  
      inq.cheveron = "";
      inq.checkbox = "";
      inq.cheveron_1 = "";
      inq.openUsers = this.processAssgnTo(inq.openUsers);
      const source = inq;
      let dummyInq = Object.assign({}, this.rowData[0]);
      dummyInq = this.clearObject(dummyInq);
      let objCopy = Object.assign(dummyInq, source);
      let nOwnershipWf = objCopy.workflows.filter(wf => wf.direction === "IN" && wf.status === "Open" && wf.workflowStatus === "Accepted - NOMINATE_OWNERSHIP");
      objCopy.openGroups = "";
      nOwnershipWf.forEach(elementNOwnershipWf => {
        objCopy.openGroups = objCopy.openGroups + elementNOwnershipWf.assignedGroupName + ";"
      });
      objCopy.convCount = inq.workflows[0].convCount;
      if (!objCopy.convCount) {
        objCopy.convCount = 1;
      }
      if (!objCopy.websocket) {
        objCopy.websocket = true;
      }
      // in case of multiple assigned group create multiple inq with same inq id
      let arrAssignedGroup = this.getAssignedGroupNames(objCopy);
      // C153176-5309: null check on assigned groups
      if (!arrAssignedGroup || !arrAssignedGroup.length) {
        return;
      }
      if (arrAssignedGroup.length > 1) {// in case of multiplpe assigned group
        this.addInqByAssgnedgrp(objCopy);
      } else if (this.isLoggedInUserConsistAssignedGrp(objCopy)) {// in case of only one  assigned group
        this.rowData.unshift(objCopy);
        var res = this.gridApi.updateRowData({
          add: [objCopy],
          addIndex: 0
        });
        if (objCopy.expanded) {
          // C153176-4456: set 'expanded' to true on the newly added node and trigger opening of Conv History
          const rowNode = this.gridApi.getDisplayedRowAtIndex(0);
          if (rowNode) {
            // do NOT call 'setExpanded', calling 'onGroupExpandedOrCollapsed' instead.
            rowNode.expanded = true;
            this.gridApi.onGroupExpandedOrCollapsed(0);
          }
        } else {
          // C153176-4642 UI Scattered for INbox after sending Inquiry
          // to programmatically open child grid
          //this.expandPreviouslyOpenedNode();
          // only re-expand if the newly added node was not expanded.
          this.reExpandeConvHistNode(objCopy);
        }
        this.updateCountLabels();
      }
  
    }
    // to add multiple inqs if more than one assigen grp in websocket
    addInqByAssgnedgrp(inq) {
      if (inq.openGroups.includes(";")) {
        let assignedGrp = inq.openGroups.split(";");
        for (let i = 0; i < assignedGrp.length; i++) {
          if (assignedGrp[i].length > 0) {
  
            // remove existing inq
            let inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.openGroups === assignedGrp[i]);
            if (inquiryIndex > -1) {
              this.rowData.splice(inquiryIndex, 1);
              this.gridApi.setRowData(this.rowData);
            }
            // add updated inq
            const inqToInsert = Object.assign({}, inq);
            inqToInsert.openGroups = assignedGrp[i];
            if (this.isLoggedInUserConsistAssignedGrp(inqToInsert)) {
              this.rowData.unshift(inqToInsert);
              let res = this.gridApi.updateRowData({
                add: [inqToInsert],
                addIndex: 0
              });
            }
  
            if (inqToInsert.expanded) {
              // C153176-4456: set 'expanded' to true on the newly added node and trigger opening of Conv History
              const rowNode = this.gridApi.getDisplayedRowAtIndex(0);
              if (rowNode) {
                // do NOT call 'setExpanded', calling 'onGroupExpandedOrCollapsed' instead.
                rowNode.expanded = true;
                this.gridApi.onGroupExpandedOrCollapsed(0);
              }
            }
          }
        }
        this.updateCountLabels();
      }
    }
    // C153176-5108 Websocket - Tag: inquiry is not updating in tag folder through websocket if creates a rule
    // to add multiple inqs if more than one assigen grp in websocket
    addInqByAssgnedgrpAtIndex(inq, index) {
      if (inq.openGroups.includes(";")) {
        let assignedGrp = inq.openGroups.split(";");
        for (let i = 0; i < assignedGrp.length; i++) {
          if (assignedGrp[i].length > 0) {
  
            // remove existing inq
            let inquiryIndex = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.openGroups === assignedGrp[i]);
            if (inquiryIndex > -1) {
              this.rowData.splice(inquiryIndex, 1);
              this.gridApi.setRowData(this.rowData);
            }
            // add updated inq
            const inqToInsert = Object.assign({}, inq);
            inqToInsert.openGroups = assignedGrp[i];
            if (this.isLoggedInUserConsistAssignedGrp(inqToInsert)) {
              this.rowData.unshift(inqToInsert);
              let res = this.gridApi.updateRowData({
                add: [inqToInsert],
                addIndex: index
              });
            }
  
            if (inqToInsert.expanded) {
              // C153176-4456: set 'expanded' to true on the newly added node and trigger opening of Conv History
              const rowNode = this.gridApi.getDisplayedRowAtIndex(0);
              if (rowNode) {
                // do NOT call 'setExpanded', calling 'onGroupExpandedOrCollapsed' instead.
                rowNode.expanded = true;
                this.gridApi.onGroupExpandedOrCollapsed(0);
              }
            }
          }
        }
        this.updateCountLabels();
      }
    }
  
    //TO remove existing row from Grid
    removeRowFromGrid(inquiryId) {
      let selectedRows = [];
      this.gridApi.forEachNode(function (node) {
        if (node.data._id === inquiryId) {
          selectedRows.push(node);
        }
      });
      if (selectedRows.length > 0) {
        this.gridApi.updateRowData({ remove: selectedRows });
      }
    }
  
    clearObject(yourobject) {
      for (var key in yourobject) {
        if (yourobject.hasOwnProperty(key)) {
          yourobject[key] = null;
  
        }
      }
      return yourobject;
    }
  
    handleSplitter(splitView) {
      this.splitdirection = splitView;
      // C153176-5191: reset split percentages
      if (splitView !== 'horizontal') {
        this.splitAreaMailboxDefaultSize = 50;
        this.splitAreaDetailDefaultSize = 50;
      } else {
        this.splitAreaMailboxDefaultSize = 60;
        this.splitAreaDetailDefaultSize = 40;
      }
      if (this.asSplitAreaMailboxEl !== undefined && this.inboxView === 'vertical') {
        this.inboxService.setDefautScreen(this.asSplitAreaMailboxEl.nativeElement.clientHeight > 264);
      }
    }
    //currentHeight: any = ''; 
    log(x) {
      //this.currentHeight = x.sizes;  
      this.currentHeight = this.asSplitAreaMailboxEl.nativeElement.clientHeight;
      this.inboxService.currentViewHeight = this.currentHeight; // C153176-5295
      if (this.inboxView === 'horizontal') {
        this.userDataService.setSplitterChange(true);
      }
  
      this.isDragged = true;
      if (this.inboxView === 'vertical') {
        window.setTimeout(() => {
          this.setGridwidthLessThansixColumns();
        }, 300);
      }
    }
    styleObject() {
      if (this.inboxView === 'horizontal' && this.asSplitAreaMailboxEl !== undefined && this.currentHeight !== undefined) {
        // let smallScreen = this.asSplitAreaMailboxEl.nativeElement.clientHeight - 10;
        // let height = smallScreen + "%";
        // let diffSize = Number(this.currentHeight[1]) - Number(this.currentHeight[0]);
        // if(this.currentHeight !== "" && this.currentHeight !== undefined && diffSize > 1) {
        //   let dynamicHeight = diffSize;
        //   let hight = smallScreen - dynamicHeight/4;
        //   height = hight +'%';
        let screenHeight = this.currentHeight - 70;
        return { height: screenHeight + 'px' };
      } else {
        // C153176-4565: compute height based on tab header row height
        let tabRows = this.tabDataService.checkTabHeaderRows(); 
        // let tabRows = 4 
        if (tabRows > 1) {
          let delta = Math.round(1.0 * (200 + (tabRows - 1) * 31)); // C153176-5191
          return { height: 'calc(100vh - ' + delta + 'px' };//C153176-5191: 100vh --> 120vh
        } else {
          return {};
        }
      }
    }
  
    getMailboxContainerStyle() {
      if (this.inboxView === 'vertical') {
        // C153176-4565: compute height based on tab header row height
        let tabRows = this.tabDataService.checkTabHeaderRows();
        // let tabRows = 4
        if (tabRows > 1) {
          let delta = Math.round(1.0 * (133 + (tabRows - 1) * 31)); // C153176-5191
          return { height: 'calc(100vh - ' + delta + 'px' };//C153176-5191: 100vh --> 120vh
        }
      }
      return {};
    }
  
    getStyleObjectMobile() {
  
      return { height: "100%", width: "100%" };
    }
  
    updateInqVersion(row, srcInq = undefined) {
      if (!row) {
        return;
      }
      if (!row.version || typeof row.version !== 'number') {
        row.version = 1;
      } else {
        row.version++;
      }
      // C153176-4660: if the incoming (websocket) inquiry has a later version, take it to the current 'row'.
      if (srcInq && srcInq.version && srcInq.version > row.version) {
        row.version = srcInq.version;
      }
    }
  
    /**
    * Method to be called when new note is added.
    */
    onNotesAdded(addedNote: any): void {
      console.log('Newly added note : ', JSON.stringify(addedNote));
      if (addedNote.comments) {
        // C153176-4903: Select Inquiry row of matching induiry id and assigned group and both the grid should updated with noptes icon.
        let noteAddedRows = this.rowData.filter(row => row._id === addedNote.inquiryId);
        noteAddedRows.forEach(updateRow => {
          let index = this.rowData.findIndex(row => row._id === updateRow._id && row.openGroups === updateRow.openGroups);
          if (index >= 0) {
            const row = this.rowData[index];
            row.notesFlag = 'Y';
            //C153176-4653 notes are getting disappeare
            this.updateInqVersion(row);
  
            const openedConvHist = this.openedConvHistInq;
            //When notes are added from right nav the conversation history closes
            this.refreshGridUpdateRow(index);
  
            // Select the row once updated
            this.gridApi.forEachNode(function (node) {
              // null check, also do NOT match on index, matching on _id and openGroups suffices
              if (node.data && node.data._id === addedNote.inquiryId && node.data.openGroups === addedNote.assignedGroupName) { // C153176-4903: Select Inquiry row of matching induiry id and assigned group
                node.setSelected(true);
              }
              else {
                node.setSelected(false);
              }
            });
  
            if (openedConvHist) {
              setTimeout(() => {
                this.reExpandNode(openedConvHist.node);
              }, 200);
            }
          }
        });
  
        // C153176-6110 | Notes disappear from UI when user switch between the Inquiries.
        // Clean the cache once the notes is added.
        this.requestCacheService.cleanupSpecificInquiryCache(addedNote.inquiryId);
      }
    }
  
    /**
     * Handle kyboard event
     * @param event
     */
    @HostListener('document:keydown.alt.j', ['$event'])
    @HostListener('document:keydown.alt.a', ['$event'])
    @HostListener('document:keydown.alt.c', ['$event'])
    @HostListener('document:keydown.alt.p', ['$event'])
    @HostListener('document:keydown.alt.n', ['$event'])
    @HostListener('document:keydown.control.c', ['$event'])
    @HostListener('document:keydown.arrowup', ['$event'])
    @HostListener('document:keydown.arrowdown', ['$event'])
    onKeyDown(event) {
      // C170665-1126 Issue- Unable to copy inquiry id form the conversation panel of inquiry in any grid.
      // to allow copy paste 
      if(!event.ctrlKey && event.key !== 'c') {
        event.stopPropagation();
        event.preventDefault();
      }
      if (event && event.altKey && !event.ctrlKey && !event.shiftKey && !event.metaKey) {
        if (event.key === 'j' || event.key === 'a') {
          // reject/approve pending approval inquiry
          if (this.strMailboxView === 'Pending Approval'.toUpperCase()) {
            const loginUserData = JSON.parse(AppUtils.getCachedItem(QmaConstant.inboxConfiguration.contextMenu));
            const objgridMenuItems = new GridMenuItems(loginUserData);
            const selectedRows = this.gridApi.getSelectedRows();
            if (selectedRows && selectedRows.length) {
              if (event.key === 'j') {
                this.contextMenuService._httpRejectInquiry(this.rejInqComponent,
                  selectedRows,
                  'Reject',
                  selectedRows,
                  loginUserData
                ).subscribe(res=>{
                  // this block is to trigger api call
                });
                return false;
              } else if (event.key === 'a') {
                this.contextMenu._getApproveInquiry('Approve', selectedRows,
                  selectedRows,
                  this.actionPopupComponent,
                  loginUserData
                );
                return false;
              }
            }
          }
        } else if (event.key === 'c') {
          if (this.strMailboxView !== 'Resolved'.toUpperCase() &&
            this.strMailboxView !== 'Drafts'.toUpperCase() &&
            this.strMailboxView !== 'Pending Approval'.toUpperCase() &&
            this.strMailboxView !== 'Sent'.toUpperCase() &&
            this.strMailboxView !== 'Outbox'.toUpperCase()) {
            const selectedRows = this.gridApi.getSelectedRows();
            this.contextMenu.handleResolveInquiry(this.strMailboxView, selectedRows, this.resolveInquiryComponent);
            return false;
          }
        }
      } else if (event && event.ctrlKey && event.key === 'c' && !event.shiftKey && !event.metaKey && !event.altKey
        && event.srcElement.name != 'note') // Jira : 4632 (Copy and Paste notes then auto attachment issue)
      {
        // C153176-4705: proceed Ctr-C handling only if a cell is focused in the grid
        let focusedCell = this.gridApi ? this.gridApi.getFocusedCell() : null;
        if (focusedCell && focusedCell.rowIndex >= 0) {
          // let currentTab = AppUtils.getCurrentTab();
          let currentTab = this.folderName;
          // C153176-4705: if this inboxView is not currently selected tab, skip handling of Ctrl-C
          // i.e., skip copying curConvId and curInqSubject to sessionStorage.
          if (currentTab && this.strMailboxView === currentTab.toUpperCase()) {
            let objMailBoxHelper = new MailBoxHelper();
            sessionStorage.setItem("curConvId", this.selectedMail.conversationList[0].id);
            // C153176-4706: call helper function 'getSubject' to retrive subject of inquiry
            sessionStorage.setItem("curInqSubject", objMailBoxHelper.getSubject(this.selectedMail.inquiry));
            sessionStorage.setItem("groupId", this.selectedMail?.inquiry?.workflows[0]?.assignedGroupId);
            sessionStorage.setItem("hasNominatedOwnership", this.selectedMail?.inquiry?.workflows[0]?.hasNominatedOwnership);
  
          }
        } else {
          console.log('Skipping Ctrl-C: focusedCell=', focusedCell, ', view=' + this.strMailboxView);
        }
      } else if (event && !event.altKey && !event.ctrlKey && !event.metaKey && !event.shiftKey) {
        // C153176-5062: shortcut key up/down handling from 'onKeyDown' (instead of going through ag-grid)
        if (event.code === 'ArrowUp' || event.code === "ArrowDown") {
          let selectedNodes = this.gridApi.getSelectedNodes();
          if (selectedNodes && selectedNodes.length === 1) {
            this.moveSelection(selectedNodes[0], event.code === "ArrowUp");
            // returning false as this event has been handled
            return false;
          }
        }
      } // C170665-1126 Issue- Unable to copy inquiry id form the conversation panel of inquiry in any grid.
      if(event.ctrlKey && event.key === 'c') { 
        return true;
      } else {
        return false;
      }
      
    }
  
    //It will reduce performance need to be from service side
    processAssgnTo(assignedTo) {
      //remove assigned group from assignedTo
      if (assignedTo && assignedTo.includes("[")) {
        return assignedTo.split("[")[0]
      } if (assignedTo && !assignedTo.includes("[")) {//if assigned to not contains group do nothing
        return assignedTo;
      }
  
      //Adding whitespace so will be availale in grp By
      if (!assignedTo) {
        return "";
      }
  
    }
    // to save columns as per view in DB
    onSaveColumns(viewName: string) {
      let reqObj = {
        'viewName': viewName,
        'columnConfig': this.gridColumnApi.getColumnState()
      };
      reqObj.columnConfig.push({ isDefaultColumns: this.isDefaultColumns });
      console.log("from servriec:-" + reqObj);
      this.inboxService.saveColumnPreferences(reqObj).subscribe(res => {
        if (res.success) {
          // Show success message
          this.hideSavedColumntoDBModal(true);
        }
      });
    }
    setColumnsAsperDB(storedColdefs) {
      if (storedColdefs && storedColdefs.length > 0) { // C153176-5040 - move the null check to below
        // C153176-6070 Issue- Grid column display is altered when all columns are selected.
        // incase colid have only resolveTime append $date
        if (storedColdefs.find(sc => sc.colId === "resolveTime")) {
          storedColdefs.find(sc => sc.colId === "resolveTime").colId = "resolveTime.$date"
        }
        // if resolve time is at first position move it before annex
        if (storedColdefs.findIndex(sc => sc.colId === "resolveTime.$date") < storedColdefs.findIndex(sc => sc.colId === "expander")) {
          // move resolve time column
          storedColdefs.splice(48, 0, storedColdefs.find(sc => sc.colId === "resolveTime.$date"))
          // remove resolvetime forst position
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "resolveTime.$date"), 1)
        }
        //C170665-6 if time in queue is at first position move it before annex
        let timeInQueueIdx = storedColdefs.findIndex(sc => sc.colId === "timeInQueue");
        if (timeInQueueIdx > -1 && timeInQueueIdx < storedColdefs.findIndex(sc => sc.colId === "expander")) {
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, storedColdefs.find(sc => sc.colId === "timeInQueue"));
          // remove time in queue from first position
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "timeInQueue"), 1);
        } else {
          if(timeInQueueIdx === -1 && this.viewType!=-1){
             storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, { "hide": true, "pinned": null, "rowGroupIndex": null, "width": 150, "aggFunc": null, "pivotIndex": null, "colId": "timeInQueue", "headerName": "" });
          }
        }
        // move recent notes column to before annex if not exist in DB
        this.addNonDBColumnBeforeAnnex("userNotes",storedColdefs);
        // Symphony change
        if ((this.strMailboxView == "INBOX" || this.strMailboxView == "CHAT") && this.symphonyEnabled) {
          // if Symphony Image is at first position move it before annex
          if (storedColdefs.findIndex(sc => sc.colId === "isSymphonyChatGridCol") !== -1
            && storedColdefs.findIndex(sc => sc.colId === "isSymphonyChatGridCol") < storedColdefs.findIndex(sc => sc.colId === "expander")) {
            // move resolve time column
            storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "cheveron") + 1, 0, storedColdefs.find(sc => sc.colId === "isSymphonyChatGridCol"));
            // remove Symphony Image first position
            storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "isSymphonyChatGridCol"), 1);
          }
          // if symphony image column not in db
          if (!storedColdefs.find(sc => sc.colId === "isSymphonyChatGridCol")) {
            storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "cheveron") + 1, 0, { "hide": false, "pinned": null, "rowGroupIndex": null, "width": 40, "aggFunc": null, "pivotIndex": null, "colId": "isSymphonyChatGridCol", "headerName": "" });
          }
        }
  
        // C170665-85| Adjust the REPLY COUNT in the Grid.
        let replyCountIdx = storedColdefs.findIndex(sc => sc.colId === "citiReplyCountFromQMA");
        if (replyCountIdx > -1 && replyCountIdx < storedColdefs.findIndex(sc => sc.colId === "expander")) {
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, storedColdefs.find(sc => sc.colId === "citiReplyCountFromQMA"));
          // remove reply count index from first position.
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "citiReplyCountFromQMA"), 1);
        } else if (replyCountIdx == -1) {
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, { "hide": true, "pinned": null, "rowGroupIndex": null, "width": 80, "aggFunc": null, "pivotIndex": null, "colId": "citiReplyCountFromQMA", "headerName": "" });
        }
  
        // C170665-5| Adjust the SAFEKEEPING ACCOUNT in the Grid.
        let skAccountIdx = storedColdefs.findIndex(sc => sc.colId === "skAccountNo");
        if (skAccountIdx > -1 && skAccountIdx < storedColdefs.findIndex(sc => sc.colId === "expander")) {
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, storedColdefs.find(sc => sc.colId === "skAccountNo"));
          // remove safe keeping account index from first position.
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "skAccountNo"), 1);
        } else if (skAccountIdx == -1) {
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, { "hide": true, "pinned": null, "rowGroupIndex": null, "width": 80, "aggFunc": null, "pivotIndex": null, "colId": "skAccountNo", "headerName": "" });
        }
  
        // C170665-5| Adjust the BRANCH in the Grid.
        let branchIdx = storedColdefs.findIndex(sc => sc.colId === "branch");
        if (branchIdx > -1 && branchIdx < storedColdefs.findIndex(sc => sc.colId === "expander")) {
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, storedColdefs.find(sc => sc.colId === "branch"));
          // remove branch index from first position.
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "branch"), 1);
        } else if (branchIdx == -1) {
          storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, { "hide": true, "pinned": null, "rowGroupIndex": null, "width": 80, "aggFunc": null, "pivotIndex": null, "colId": "branch", "headerName": "" });
        }
  
         // C170665-1719| Adjust the Inquiry Sub status in the Grid.
         let subStatusIdx = storedColdefs.findIndex(sc => sc.colId === "inquirySubStatus");
         if (subStatusIdx > -1 && subStatusIdx < storedColdefs.findIndex(sc => sc.colId === "expander")) {
           storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, storedColdefs.find(sc => sc.colId === "inquirySubStatus"));
           // remove Inquiry Sub status index from first position.
           storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "inquirySubStatus"), 1);
         } else if (subStatusIdx == -1) {
           storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "clntCstmCategory") + 1, 0, { "hide": true, "pinned": null, "rowGroupIndex": null, "width": 80, "aggFunc": null, "pivotIndex": null, "colId": "inquirySubStatus", "headerName": "inquirySubStatus" });
         }
  
        //fix for C153176-4558	
        this.storedColdefs = true;
        let defaultCOlObjIndex = storedColdefs.findIndex(col => col.isDefaultColumns);
        if (defaultCOlObjIndex > -1) {
          if (!this.customViewFlag && !this.columnChooserSelectedColumns.length) {
            // set 'isDefaultColumns' only if it is NOT customized view
            let isDefault = storedColdefs.find(defaul => defaul.isDefaultColumns);
            this.isDefaultColumns = isDefault.isDefaultColumns;
          }
          storedColdefs.splice(defaultCOlObjIndex, 1);
        }
  
        storedColdefs.forEach((cdef) => {
          if (this.isCustomizedDBColumn(cdef.colId)) {
            cdef.hide = false;
          } else if (this.customViewFlag) {
            // if this is a customized view, but the column is NOT in the customized DB, hide it
            cdef.hide = true;
          }
        });
        // C153176-5040: check gridColumnApi here right before using it
        if (this.gridColumnApi) {
          this.gridColumnApi.applyColumnState({
            state:storedColdefs,
            applyOrder: true,
          });
          this.storedColdefs = true;
          this.isColsPreferencesinDb = true;
          this.currentWidth = this.asSplitAreaMailboxEl.nativeElement.clientWidth; // C153176-5191
          this.colDefsService.adjustColumnWidths(this.strMailboxView, this.currentWidth, this.gridColumnApi, this.inboxView === 'horizontal');
        } else {
          // if the API is not there, saved the coldefs for later action (when it is available)
          this.pendingStoredColDefs = storedColdefs;
        }
      if (!this.isMobile) {
        this.arrangeGridPrimaryColumns()
      }
        return true;
      } else {
        return false;
      }
    }
  
    /**
     * Check whether a DB column is part of the customized columns
     */
    isCustomizedDBColumn(colId) {
      if (!this.customViewFlag || !this.customizedColumnDefs || !colId) {
        return false;
      }
      let index = this.customizedColumnDefs.findIndex(c_def => c_def.id === colId || (c_def.id + '.$date' === colId) ||
        c_def.label === colId.toUpperCase() || (c_def.label === 'ANNEX' && colId.toUpperCase() === 'FOLLOWUP')); // C153176-5165
      return index >= 0;
    }
  
  
    sizeToFit() {
      if (this.isDefaultColumns) {
        if (this.gridApi) {
          this.gridApi.sizeColumnsToFit();
        }
  
      } else {
        if (this.gridColumnApi) {
          this.gridColumnApi.autoSizeColumns();
  
        }
      }
    }
  
    moveSelection(node, upOrDown) {
      if (!this.gridApi || !this.rowData || !this.rowData.length) {
        return;
      }
      const firstDisplayedId = this.gridApi.getFirstDisplayedRow();
      const lastDisplayedId = this.gridApi.getLastDisplayedRow();
      // C153176-5062: get the selected id from node arg, default to '0'
      let selectedId = node ? node.id : "0";
      if (upOrDown) {
        // move up selection
        selectedId = '' + Math.max(firstDisplayedId, (parseInt(selectedId) - 1))
      } else {
        // move down selection
        selectedId = '' + Math.min(lastDisplayedId, (parseInt(selectedId) + 1));
      }
      const nextSelectedNode = this.gridApi.getRowNode(selectedId);
      if (nextSelectedNode) {
        nextSelectedNode.setSelected(true, true);
      } else {
        console.log("Failed to identify next selected node, currently selectedId=", selectedId);
      }
    }
  
    autoFitGridColumns() {
      if (this.gridApi) {
        this.gridApi.sizeColumnsToFit();
      }
    }
  
    handleRowDragEnter(event: any) {
      if (!event || !event.node || !event.node.data) {
        console.log("handleRowDragEnter, invalid event", event);
        return;
      }
      const selGrpIds = [];
      if (event.node.data.workflows && event.node.data.workflows.length) {
        event.node.data.workflows.forEach(workflow => {
          let assignedGroupId = workflow.assignedGroupId;
          if (this.userDataService.userGroupIdList.indexOf(assignedGroupId) > -1) {
            selGrpIds.push(assignedGroupId);
          }
        });
      }
      // C153176-5223 | conversation loading issue for nomination
      let hasNominatedOwnership = event.node.data.workflows[0].hasNominatedOwnership !== undefined ? event.node.data.workflows[0].hasNominatedOwnership : false;
      let conversationIdforInquiry = this.micrfrontendInboxService.getConversationIdForInquiry(event.node.data._id)
      let inquiryData = {
        id: event.node.data._id,
        selectInqAssignedGroupId: selGrpIds,
        isAllConvContent: false, // C153176-4660: do not load all content since only metadata is needed
        hasNominatedOwnership: hasNominatedOwnership,
        conversationIds :conversationIdforInquiry ?conversationIdforInquiry : ''
      };
      // Send request to get the all the inquiry conversation for the selected inquiry Id.
      this.inboxService.getInquiryIdExtSelected(inquiryData, event.node.data).subscribe((inquiryDetail: any) => {
        this.requestCacheService.updateInquiryCache(event.node.data, inquiryData.isAllConvContent, inquiryDetail);
        console.log('handleRowDragEnter: get the Inquiry details for the inquiry id : ' + inquiryData.id, inquiryDetail);
        if (inquiryDetail) {
          if (inquiryDetail.conversationList && inquiryDetail.conversationList.length) {
            sessionStorage.setItem("curConvId", inquiryDetail.conversationList[0].id);
            event.curConvId = inquiryDetail.conversationList[0].id;
          }
          if (inquiryDetail.inquiry) {
            let objMailBoxHelper = new MailBoxHelper();
            // C153176-4706: call helper function 'getSubject' to retrive subject of inquiry
            sessionStorage.setItem("curInqSubject", objMailBoxHelper.getSubject(inquiryDetail.inquiry));
            event.curInqSubject = inquiryDetail.inquiry.origSubject;
          }
        }
      }, error => {
        // C153176-4910: end conversation loading status tracking if any
        this.endTrackingConvLoading(inquiryData.id, event.node.data.workflows[0].assignedGroupName); // C153176-4614: fix on group id vs. name mismatch
        console.error('Error from getInquiryIdExtSelected() from drag source');
      });
    }
    updateOpenTagsTab(inq: any) {
      if (this.finalCriteria) {
        let finalCriteria = JSON.parse(this.finalCriteria);
        if (finalCriteria && finalCriteria.$and && finalCriteria.$and[0] && finalCriteria.$and[0].workflows &&
          finalCriteria.$and[0].workflows.$elemMatch && finalCriteria.$and[0].workflows.$elemMatch.tag) {
          let qualifiedTag = finalCriteria.$and[0].workflows.$elemMatch && finalCriteria.$and[0].workflows.$elemMatch.tag;
          inq.workflows.forEach(wf => {
            // insert if not exist for PE Client Chaser
            if (qualifiedTag === wf.tag) {
              // to avoid duplicate inqs
              let inqIndex = this.rowData.findIndex(rdata => inq._id === rdata._id && rdata.openGroups === wf.assignedGroupName);
              if (inqIndex === -1) {
                const inqToInsert = Object.assign({}, inq);
                inqToInsert.openGroups = wf.assignedGroupName;
                this.updateInqByWf(inqToInsert, null, "IN", wf);
                let objAppUtils = new AppUtils();
                let receivedDt = objAppUtils.convertDate(inq.modDate.$date);
                let inqIndexPosition = -1;
                var BreakException = {};
                let datePipe = new DatePipe("en-US");
                try {
                  this.rowData.forEach(function (rd, index) {
                    let wsInqDate = datePipe.transform(inq.modDate.$date, 'dd/MMM/yy h:mm:ss a');
                    let existinginqDate = datePipe.transform(rd.modDate.$date, 'dd/MMM/yy h:mm:ss a');
                    if (wsInqDate > existinginqDate) {
                      inqIndexPosition = index;
                      throw BreakException;
                    }
                  })
                } catch (e) {
                  console.log(e);
                }
  
                if (inqIndexPosition > -1) {
                  this.addItemsAtIndex(inqToInsert, inqIndexPosition);
                }
  
              }
  
            }
            else {
              let nodeToDelete = [];
              this.gridApi.forEachNode(function (node) {
                if (inq._id === node.data._id && node.data.openGroups === wf.assignedGroupName) {
                  nodeToDelete.push(node.data);
                }
  
              });
              if (nodeToDelete.length > 0) {
                this.removeInqFromAgGrid(nodeToDelete);
                this.updateCountLabels();
              }
            }
  
  
          });
        }
      }
  
    }
    updateCategoryFolder(inq: any) {
      if (this.finalCriteria) {
        let finalCriteria = JSON.parse(this.finalCriteria);
        // C153176-5431 Client Inquiry segregation based on client Category
        if (finalCriteria && finalCriteria.$and && finalCriteria.$and[0] && (finalCriteria.$and[0]['workflows.customClientCategory.categoryName']
          || finalCriteria.$and && finalCriteria.$and[0] && finalCriteria.$and[0]['workflows.clientCategory.categoryName'])) {
          // for custom client category  
          let qualifiedCategory = finalCriteria.$and[0]['workflows.customClientCategory.categoryName'];
          // for case of client category.
          if (!qualifiedCategory) {
            qualifiedCategory = finalCriteria.$and && finalCriteria.$and[0] && finalCriteria.$and[0]['workflows.clientCategory.categoryName']
          }
          inq.workflows.forEach(wf => {
            let inqCategoryObj = this.getClientCategoryGrid(inq)
            if (inqCategoryObj && inqCategoryObj.categoryName)
              if (qualifiedCategory === inqCategoryObj.categoryName && wf.direction === "IN" && wf.assignedGroupId === inqCategoryObj.assignedGroupId) {
                // to avoid duplicate inqs
                let inqIndex = this.rowData.findIndex(rdata => inq._id === rdata._id && rdata.openGroups === wf.assignedGroupName);
                if (inqIndex === -1) {
                  const inqToInsert = Object.assign({}, inq);
                  inqToInsert.openGroups = wf.assignedGroupName;
                  this.updateInqByWf(inqToInsert, null, "IN", wf);
                  let objAppUtils = new AppUtils();
                  let receivedDt = objAppUtils.convertDate(inq.modDate.$date);
                  let inqIndexPosition = -1;
                  var BreakException = {};
                  let datePipe = new DatePipe("en-US");
                  try {
                    this.rowData.forEach(function (rd, index) {
                      let wsInqDate = datePipe.transform(inq.modDate.$date, 'dd/MMM/yy h:mm:ss a');
                      let existinginqDate = datePipe.transform(rd.modDate.$date, 'dd/MMM/yy h:mm:ss a');
                      if (wsInqDate > existinginqDate) {
                        inqIndexPosition = index;
                        throw BreakException;
                      }
                    })
                  } catch (e) {
                    console.log(e);
                  }
  
                  if (inqIndexPosition > -1) {
                    this.addItemsAtIndex(inqToInsert, inqIndexPosition);
                  }
  
                }
  
              }
              else {
                let nodeToDelete = [];
                this.gridApi.forEachNode(function (node) {
                  if (inq._id === node.data._id && node.data.openGroups === wf.assignedGroupName) {
                    nodeToDelete.push(node.data);
                  }
  
                });
                if (nodeToDelete.length > 0) {
                  this.removeInqFromAgGrid(nodeToDelete);
                  this.updateCountLabels();
                }
              }
  
  
          });
        }
      }
  
    }
    // C153176-5567 Inquiry should be remove from escalation :client chaser
    updateEscalationFolder(inq) {
      if (this.finalCriteria) {
        let finalCriteria = JSON.parse(this.finalCriteria);
        if (finalCriteria && finalCriteria.$and && finalCriteria.$and[0] && finalCriteria.$and[0].workflows &&
          finalCriteria.$and[0].workflows.$elemMatch && finalCriteria.$and[0].workflows.$elemMatch.direction &&
          finalCriteria.$and[0].workflows.$elemMatch.direction.$in) {
          let qualifiedDirection = finalCriteria.$and[0].workflows.$elemMatch.direction.$in;
          let isClientChaserTab = finalCriteria.$and[1].isClientChaseEscalation;
          // insert only in PE client chanser tab
          if (qualifiedDirection && isClientChaserTab === "Y") {
            inq.workflows.forEach(wf => {
              // insert if not exist for PE Client Chaser
              if (qualifiedDirection.includes(wf.direction) && wf.clientChaseCounter && wf.clientChaseCounter > 0) {
                // to avoid duplicate inqs
                let inqIndex = this.rowData.findIndex(rdata => inq._id === rdata._id && rdata.openGroups === wf.assignedGroupName);
                if (inqIndex === -1) {
                  const inqToInsert = Object.assign({}, inq);
                  inqToInsert.openGroups = wf.assignedGroupName;
                  this.updateInqByWf(inqToInsert);
                  this.addItemsAtTop(inqToInsert);
                }
  
              }
              else {
                let nodeToDelete = [];
                this.gridApi.forEachNode(function (node) {
                  if (inq._id === node.data._id && node.data.openGroups === wf.assignedGroupName) {
                    nodeToDelete.push(node.data);
                  }
  
                });
                if (nodeToDelete.length > 0) {
                  this.removeInqFromAgGrid(nodeToDelete);
                  this.updateCountLabels();
                }
              }
  
  
            });
          }
  
        }
      }
    }
    // C153176-5651 Snooze- Inquiry not moving from snooze folder if user replied on it
    updateOpenSnoozTab(inq: any) {
      if (this.finalCriteria) {
        let finalCriteria = JSON.parse(this.finalCriteria);
        if (finalCriteria && finalCriteria.$and && finalCriteria.$and[0] && finalCriteria.$and[0].workflows &&
          finalCriteria.$and[0].workflows.$elemMatch && finalCriteria.$and[0].workflows.$elemMatch.snoozeAction) {
          let qualifiedCriteria = finalCriteria.$and[0].workflows.$elemMatch.snoozeAction;
          inq.workflows.forEach(wf => {
            // insert if not exist for PE Client Chaser
            if (wf.action && qualifiedCriteria === wf.action) {
              // to avoid duplicate inqs
              let inqIndex = this.rowData.findIndex(rdata => inq._id === rdata._id && rdata.openGroups === wf.assignedGroupName);
              if (inqIndex === -1) {
                const inqToInsert = Object.assign({}, inq);
                inqToInsert.openGroups = wf.assignedGroupName;
                this.updateInqByWf(inqToInsert, null, "IN", wf);
                let objAppUtils = new AppUtils();
                let receivedDt = objAppUtils.convertDate(inq.modDate.$date);
                let inqIndexPosition = -1;
                var BreakException = {};
                let datePipe = new DatePipe("en-US");
                try {
                  this.rowData.forEach(function (rd, index) {
                    let wsInqDate = datePipe.transform(inq.modDate.$date, 'dd/MMM/yy h:mm:ss a');
                    let existinginqDate = datePipe.transform(rd.modDate.$date, 'dd/MMM/yy h:mm:ss a');
                    if (wsInqDate > existinginqDate) {
                      inqIndexPosition = index;
                      throw BreakException;
                    }
                  })
                } catch (e) {
                  console.log(e);
                }
  
                if (inqIndexPosition > -1) {
                  this.addItemsAtIndex(inqToInsert, inqIndexPosition);
                }
  
              }
  
            }
            else {
              let nodeToDelete = [];
              this.gridApi.forEachNode(function (node) {
                if (inq._id === node.data._id && node.data.openGroups === wf.assignedGroupName) {
                  nodeToDelete.push(node.data);
                }
  
              });
              if (nodeToDelete.length > 0) {
                this.removeInqFromAgGrid(nodeToDelete);
                this.updateCountLabels();
              }
            }
  
  
          });
        }
      }
  
    }
  
    processNonInqWsUpdate(inq: any) {
      inq.workflows.forEach(wf => {
        let inqIndex = this.rowData.findIndex(rdata => inq._id === rdata._id && rdata.openGroups === wf.assignedGroupName);
        if (inqIndex === -1 && this.strMailboxView === "INBOX" && wf.direction === "IN" && wf.action === "Mark as Inquiry") {
          const inqToInsert = Object.assign({}, inq);
          inqToInsert.openGroups = wf.assignedGroupName;
          this.updateInqByWf(inqToInsert, null, "IN", wf);
          let objAppUtils = new AppUtils();
          let receivedDt = objAppUtils.convertDate(inq.modDate.$date);
          let inqIndexPosition = -1;
          var BreakException = {};
          let datePipe = new DatePipe("en-US");
          try {
            this.rowData.forEach(function (rd, index) {
              let wsInqDate = datePipe.transform(inq.modDate.$date, 'dd/MMM/yy h:mm:ss a');
              let existinginqDate = datePipe.transform(rd.modDate.$date, 'dd/MMM/yy h:mm:ss a');
              if (wsInqDate > existinginqDate) {
                inqIndexPosition = index;
                throw BreakException;
              }
            })
          } catch (e) {
            console.log(e);
          }
  
          if (inqIndexPosition > -1) {
            this.addItemsAtIndex(inqToInsert, inqIndexPosition);
          }
  
        }
  
        else if (this.strMailboxView === "NON INQUIRIES") {
          let nodeToDelete = [];
          this.gridApi.forEachNode(function (node) {
            if (inq._id === node.data._id && node.data.openGroups === wf.assignedGroupName && wf.direction === "IN" && !wf.rulesFlag) {
              nodeToDelete.push(node.data);
            }
  
          });
          if (nodeToDelete.length > 0) {
            this.removeInqFromAgGrid(nodeToDelete);
            this.updateCountLabels();
          }
        }
  
        else if (this.strMailboxView !== "NON INQUIRIES") {
          let nodeToDelete = [];
          this.gridApi.forEachNode(function (node) {
            if (inq._id === node.data._id && node.data.openGroups === wf.assignedGroupName && wf.direction === "IN"
              && wf.rulesFlag && wf.rulesFlag.markAsNonInquiry) {
              nodeToDelete.push(node.data);
            }
  
          });
          if (nodeToDelete.length > 0) {
            this.removeInqFromAgGrid(nodeToDelete);
            this.updateCountLabels();
          }
        }
  
      });
    }
    /**
     * Method to get the selected trades populated from the CLC
     * @param trades 
     */
    onTradesAdded(trades: any): void {
      this.trades = trades || null;
    }
  
    hideSavedColumntoDBModal(isSuccess: boolean) {
      this.saveedColumnstoDBFlag = false;
      if (isSuccess) {
        this.saveedColumnstoDBFlag = true;
        //  Timeout Message after Saved Profile Setting
        setTimeout(() => { this.saveedColumnstoDBFlag = false; }, 3000);
      }
    }
  
    convChange() {
      if (this.isMobile) { // added for mobile performance
        return;
      }
      this.colDefsService.getChangeConv().subscribe(currentConvData => {
        if (Object.entries(currentConvData).length > 0) {
          this.readPrevConv(currentConvData, false);
        }
      })
    }
  
    //Conversation history read / unread. Jira 4456
    readPrevConv(currentConvData, initialLoad) {
      let previousConv = this.colDefsService.getPrevConv();
      let readInqIdList = new Array();
      if (previousConv.length !== 0) {
        this.contextMenuService.httpMarkConvHistoryReadInquiry(previousConv, '', '', true).subscribe(result => {
          if (result) {
            let readConv = this.markConvRead(previousConv);
            this.colDefsService.setConvRow(readConv);
            if (initialLoad) {
              // C153176-5120: only if the previous conversation wad marked 'inquiry read', shall the inquiry to set to read
              if (previousConv && previousConv.length && previousConv[0].inqReadReady) {
                this.gridUpdateService.setreadInqIds([{ inqId: previousConv[0].parentInqueryId, agrpId: previousConv[0].parentAssignedGroudId, callFrom: 'chevron' }]);
              } else {
                console.debug('skipping setreadInqIds on ', previousConv[0].parentInqueryId, previousConv[0].parentAssignedGroudId);
              }
              this.colDefsService.setPrevConv([]);
            } else {
              currentConvData.data.forEach(rData => {
                if (rData.readBy && rData.readBy.includes(this.loginUserInfo.userId)) {
                  readInqIdList.push({ inqId: rData.inquiryId, agrpId: rData.workflows[0].assignedGroupId });
                }
              });
              // C153176-5120, mark whether inquiry is ready to be marked READ in *next round* if there is only one left unread and it is the current conversation
              if ((readInqIdList.length + 1) >= currentConvData.data.length
                && (currentConvData.conv.readBy == null || !currentConvData.conv.readBy.includes(this.loginUserInfo.userId))) {
                currentConvData.conv.inqReadReady = true;
              }
              this.colDefsService.setPrevConv([currentConvData.conv]);
            }
            if (currentConvData && currentConvData.data && currentConvData.data.length && readInqIdList.length == currentConvData.data.length) {
              let rowData = this.rowData;
              let isInqReadFirstTime = false;
              if (rowData && !rowData.filter(rData => rData._id === currentConvData.data[0].inquiryId)[0].readBy) {
                isInqReadFirstTime = true;
              } else if (rowData && rowData.filter(rData => rData._id === currentConvData.data[0].inquiryId)[0].readBy.length === 0) {
                isInqReadFirstTime = true;
              }
              else if (!rowData.filter(rData => rData._id === currentConvData.data[0].inquiryId)[0].readBy.includes(this.loginUserInfo.userId)) {
                isInqReadFirstTime = true;
              }
              if (isInqReadFirstTime) {
                this.isInqReadFirstTime({ id: previousConv[0].inquiryId, openGroups: previousConv[0].openGroups });
              }
  
  
            }
            if (currentConvData && currentConvData.data && currentConvData.data.length && readInqIdList.length == currentConvData.data.length) {
              // if all the convs are READ, mark the inquiry READ
              this.gridUpdateService.setreadInqIds([{ inqId: currentConvData.data[0].inquiryId, agrpId: currentConvData.parentGridData.workflows[0].assignedGroupId, callFrom: 'contextMenu' }]);
  
            }
          }
        });
      }
      // C153176-4660: always consider setPrevConv regardless whether initial load
      if (Object.entries(currentConvData).length > 0) {
        currentConvData.conv.parentInqueryId = currentConvData.parentGridData._id;
        currentConvData.conv.parentAssignedGroudId = currentConvData.parentGridData.workflows[0].assignedGroupId;
        // C153176-5120: if there is *at most* one conversation not read by current user in the inquiry, mark the inquiry ready-to-mark-read
        if (currentConvData && currentConvData.data && this.loginUserInfo && this.loginUserInfo.userId) {
          let convReadCount = 0;
          currentConvData.data.forEach(rData => {
            if (rData.readBy && rData.readBy.includes(this.loginUserInfo.userId)) {
              convReadCount++;
            }
          });
          if (((convReadCount + 1) >= currentConvData.data.length) &&
            (currentConvData.conv.readBy == null || !currentConvData.conv.readBy.includes(this.loginUserInfo.userId))) {
            currentConvData.conv.inqRedReady = true;
          } else {
            currentConvData.conv.inqRedReady = false;
          }
        }
        this.colDefsService.setPrevConv([currentConvData.conv]);
      }
    }
  
    markConvRead(conversionRecord) {
      let readConv = [];
      conversionRecord.forEach(element => {
        if (element.readBy === null) {
          element['readBy'] = [this.loginUserInfo.userId];
        } else {
          element.readBy.push(this.loginUserInfo.userId);
        }
        readConv.push(element);
      });
      return readConv;
    }
    // calculate width of grid columns based on container width
    // C153176-5411 Grid is not responsive. On 1st download need to always reset for it to fit all columns
    gridAutoFitDefaultColumnsByResolution() {
      if (this.gridColumnApi) {
        let existingDefaultColState = this.gridColumnApi.getColumnState();
        if (existingDefaultColState) {
          let numberofColumnsVisible = existingDefaultColState.filter(colstate =>
            colstate.colId !== QmaConstant.MailBoxGridFeaturedCols.colIdCheckbox &&
            colstate.colId !== QmaConstant.MailBoxGridFeaturedCols.colIdXheveron &&
            colstate.colId !== QmaConstant.MailBoxGridFeaturedCols.colIdDrag && !colstate.hide
          ).length;
          if (numberofColumnsVisible) {
            let calculatedWidthPerColumn = this.calculateMailboxGridColWidthByResolution(numberofColumnsVisible);
            if (calculatedWidthPerColumn) {
              let calcultedDefaultColState = existingDefaultColState.map(colstate => {
                if (colstate.colId !== QmaConstant.MailBoxGridFeaturedCols.colIdCheckbox &&
                  colstate.colId !== QmaConstant.MailBoxGridFeaturedCols.colIdXheveron &&
                  colstate.colId !== QmaConstant.MailBoxGridFeaturedCols.colIdDrag && !colstate.hide) {
                  colstate.width = calculatedWidthPerColumn;
                }
                return colstate
              });
              if (calcultedDefaultColState) {
                this.gridColumnApi.applyColumnState({
                  state:calcultedDefaultColState,
                  applyOrder: true,
                });
              }
            }
  
          }
        }
  
      }
  
    }
  
    calculateMailboxGridColWidthByResolution(numberOfclmns: number): number {
      // deduct width of featured columns
      let totalGridAvailableContainerWidth = this.mailBoxDesktopGridContainer.nativeElement.offsetWidth -
        (QmaConstant.MailBoxGridFeaturedCols.colIdCheckboxWidth +
          QmaConstant.MailBoxGridFeaturedCols.colIdXheveronWidth +
          QmaConstant.MailBoxGridFeaturedCols.colIdDragWidth);
      return totalGridAvailableContainerWidth / numberOfclmns;
  
    }
  
    //Updating columDef if conversation is open, Jira 4456
    updateColumnDef() {
      let columnDefs = [];
      this.columnDefs.forEach(column => {
        switch (column.field) {
          case 'inquiryId':
            column.field = '_id';
            break;
          case 'crtDate':
            column.field = 'crtDate.$date';
            break;
          case 'reOpenDate':
            column.field = 'reOpenDate.$date';
            break;
          case 'lastActionTime':
            column.field = 'lastActionTime.$date';
            break;
          case 'modDate':
            column.field = 'modDate.$date';
            break;
          case 'reAgeDate':
            column.field = 'reAgeDate.$date';
            break;
        };
        //columnDefs.push(column);
      });
      //this.columnDefs = columnDefs;
    }
  
    // C153176-4457 : Check inline reply is open or close
    inlineReplyOpen(reply: any) {
      this.inlineReply = reply;
      this.inlineReplySendData = false;
    }
  
    // C153176-4457 : Get updated data from New Message component
    getDataFromNewMessage(data) {
      this.newMessaagedata = data;
      // console.log('Data :',this.newMessaagedata);
      // C153176-4998 - Top Contacts not appearing for New Message and reply/reply all
      this.groupNameFrom = data.groupName;
      if (!this.cdr['destroyed']) {// C153176-4614: check whether the view is destroyed before enforcing change
        this.cdr.detectChanges();
      }
    }
  
    // C153176-4457 : Save the inquiry to draft is user selects another Inquiry
    saveDraft() {
      // C170665-473 | Catch the exception, so that it doesn't break the flow.
      try {
        let token = AppUtils.getToken();
        let groupName = (this.isNull(this.newMessaagedata.groupName)) ? '' : this.newMessaagedata.groupName;
        let inquirySource = (this.isNull(this.newMessaagedata.inquirySource)) ? '' : this.newMessaagedata.inquirySource;
        let rootCause = (this.isNull(this.newMessaagedata.rootCause)) ? '' : this.newMessaagedata.rootCause;
        let processingRegion = (this.isNull(this.newMessaagedata.processingRegion)) ? '' : this.newMessaagedata.processingRegion;
        let tags = (this.isNull(this.newMessaagedata.tags)) ? '' : this.newMessaagedata.tags;
        let queryCount = (this.isNull(this.newMessaagedata.queryCount)) ? '' : this.newMessaagedata.queryCount;
        let gfpId = (this.isNull(this.newMessaagedata.gfpId)) ? '' : this.newMessaagedata.gfpId;
        let gfpName = (this.isNull(this.newMessaagedata.gfpName)) ? '' : this.newMessaagedata.gfpName;
        let gfcId = (this.isNull(this.newMessaagedata.gfcId)) ? '' : this.newMessaagedata.gfcId;
        let gfcName = (this.isNull(this.newMessaagedata.gfcName)) ? '' : this.newMessaagedata.gfcName;
        let subject = (this.isNull(this.newMessaagedata.subject)) ? '' : this.newMessaagedata.subject;
        let requestType = (this.isNull(this.newMessaagedata.requestType)) ? '' : this.newMessaagedata.requestType;
        let attachment = (this.isNull(this.newMessaagedata.attachments)) ? [] : this.newMessaagedata.attachments;
        let content = this.newMessaagedata.editorData || "";
        let fontStyle = this.newMessaagedata.fontStyle;
        let skAccountNo = (this.isNull(this.newMessaagedata.skAccountNo)) ? '' : this.newMessaagedata.skAccountNo;
        let branch = (this.isNull(this.newMessaagedata.branch)) ? '' : this.newMessaagedata.branch;
  
        // let save = new SaveDraft(null, this.newMessaagedata.toUsers, this.newMessaagedata.ccUsers, groupName,
        //   "N", subject, requestType, attachment, "NEW DRAFT", false, false, token, true, fontStyle.name, fontStyle.size,
        //   inquirySource, rootCause, processingRegion, tags, queryCount, gfpId, gfpName, gfcId, gfcName, this.newMessaagedata.bccUsers, []);
  
        // // C170665-5 | Add Account and Branch
        // save.skAccountNo = skAccountNo;
        // save.branch = branch;
  
        // this.newMessageService.saveDraft(save, content).subscribe(result => {
        //   console.log('result of saving draft:', result);
        //   this.newMessageService.setInsertedDraftIds(result); //C153176-4457 : update drafts after saving the inquiry
        //   this.msgsGrowl = [];
        //   this.msgsGrowl.push({ severity: 'success', summary: '', detail: 'Inquiry Saved to Draft' }); //(C153176-4457) : Prompt User to Save the changes before closing the inline reply view
        // },
        //   (error) => {
        //     console.log('error while saving draft:', error);
        //   }
        // );
      } catch (e) {
        console.log('Exception while forming save draft request', e);
      }
    }
  
    /**
     * Method to reset the conversation view for the below scenarios.
     * 
     * When user perform Resolve inquiry in Inbox.
     * When user perform Approve inquiry in Pending Approval
     * When user perform De-Escalate inquiry in Pending Approval
     */
    resetConversationView(inq = null, isDeleted = null): void {
      this.selectedMail = null;
      this.inqData = null; // C153176-4660: reset request data
      this.assignedGroup = null;
      this.selectedInqId = null;
      this.selectedConvId = null;
      this.inquiryWorkflow = null;
  
      // Clear Right Navigation bar
      this.attachmentsList = [];
      this.notesList = [];
      this.auditsList = [];
      this.clc = {};
      if (!this.cdr['destroyed']) {// C153176-4614: check whether the view is destroyed before enforcing change
        this.cdr.detectChanges();
      }
      this.updateCountLabels(inq, isDeleted); // C153176-5428 fix for Websocket issue for pagination and view issue raised by user
    }
  
    // fix for JIRA C153176-4568
    // check to restrict insertion of inquiry if logged in user is not part of new inq assigned group
    isLoggedInUserConsistAssignedGrp(inq, openGroup = null): boolean {
      return inq ? this.loginUserInfo.myGroups.map(x => x.groupName).includes(inq.openGroups) :
        this.loginUserInfo.myGroups.map(x => x.groupName).includes(openGroup)
    }
  
    // C153176-4605 - Fwd functionality issue fixed
    getDataFromNewMessageSend(data) {
      console.log('Data inside inbox:', data);
      this.inlineReplySendData = data;
    }
  
    // C153176-4456: conversation history fix - caching opened Conv Hist Inq
    setOpenedConvHistInquiry(newInq) {
      if (!this.openedConvHistInq || this.openedConvHistInq.data._id !== newInq.data._id ||
        this.openedConvHistInq.data.openGroups !== newInq.data.openGroups) {
        this.openedConvHistInq = newInq;
        this.openedConvHistInqRefCount = 1;
      } else if (this.openedConvHistInq.data._id === newInq.data._id &&
        this.openedConvHistInq.data.openGroups === newInq.data.openGroups) {
        if (newInq.setrefcount) {
          this.openedConvHistInqRefCount++;
        }
        this.openedConvHistInq = newInq;
      }
    }
  
    // C153176-4456: conversation history fix - resetting opened Conv Hist Inq
    resetOpenedConvHistInquiry(resetInq) {
      if (!resetInq || !this.openedConvHistInq ||
        (resetInq.data._id === this.openedConvHistInq.data._id &&
          resetInq.data.openGroups === this.openedConvHistInq.data.openGroups)) {
        this.openedConvHistInqRefCount--;
        this.openedConvHistInqRefCount = Math.max(0, this.openedConvHistInqRefCount);
        if (this.openedConvHistInqRefCount <= 1) {
          // only if refCount is 0, will the cached 'openedConvHistInq' be reset.
          this.openedConvHistInq = undefined;
          this.openedConvHistInqRefCount = 0;
          return true;
        } else {
          return false;
        }
      }
      // return true in other cases (when reset failed to be done, which is considered that this inq is no longer cached.)
      return true;
    }
  
  
    /**
     * @param  {any} selectedRow 
     */
    rowSelected(selectedRow: any) {
      // let tabName = AppUtils.getCurrentTab();
      let tabName = this.folderName
      this.isDraftsView = (tabName.toUpperCase() == "DRAFTS");
      //C153176-4463- Added to display New Message on click of Draft
      if (this.isDraftsView) {
        let inquiryData = { draftId: selectedRow._id };
        this.newMessageService.setDraftSubject(inquiryData.draftId);
        this.tabDataService.sendTabData("DRAFT:" + inquiryData.draftId, 0);
      }
      else {
        this.selectedMailMobile = selectedRow;
        this.showConversationView = true;
        this.onSelectionChanged();
      }
    }
  
    hideConversationView(evt?: any) {
      this.showConversationView = false;
      // C170665-890 | Mobile issues - Hide the spinner in mobile view
      this.showSpinner = false;
      console.debug(":SPINNER:: " + this.showSpinner + " ::inboxView.hideConversationView");
    }
  
    /**
     * Event handler when mobile view is ready
     */
    mobileViewReady(event: any) {
      // this.gridApi = new MobileViewAPI();
      this.gridApi.viewComponent = event.viewComponent;
    }
  
    /**
     * C153176-4456: Normalize opened tab: align dashboard-opened views to INBOX on websocket handling
     */
    normalizeTabName(tabName) {
      if (tabName && (tabName.indexOf("OPEN INQUIRIES ") !== -1 || this.dashboardInlineTab || this.acceptedByMyView)) {  // C153176-4798 support myview with 'acceptedByMyView'
        return "INBOX";
      } else {
        return tabName;
      }
    }
  
    // C153176-4664: re-open expanded node if any
  
    reExpandNode(node) {
      if (node) {
        const rowIndex = Math.max(node.rowIndex - 1, 0)
        if (this.gridApi) {
          let c_node = this.gridApi.getDisplayedRowAtIndex(rowIndex);
          if (c_node) {
            if (!c_node.expanded) {
              c_node.expanded = true;
              this.gridApi.onGroupExpandedOrCollapsed(rowIndex);
            } else {
              // C153176-4642: force refreshing if the node was already in expanded mode
              c_node.setExpanded(false);
              c_node.setExpanded(true);
            }
          }
        }
      }
    }
  
    reExpandeConvHistNode(srcInq = undefined) {
      // C153176-4660: skip re-expanding node if it is the same as the specified 'srcInq'
      if (this.openedConvHistInq && (!srcInq || srcInq._id !== this.openedConvHistInq.node.data._id)) {
        this.reExpandNode(this.openedConvHistInq.node);
      }
    }
  
    // C153176-4660: handling conversation history closed by user
    onRowGroupOpened(event: any) {
  
      if (event && event.node && event.node.expanded === false) {
        if (event.node.data) {
          this.gridOprService.clearSelectedConversation(this.strMailboxView, event.node.data._id);
        } else {
          this.gridOprService.clearSelectedConversation(this.strMailboxView);
        }
      }
    }
  
    updatePaginationPanel(paginationDetail: any) {
      this.lbCurrentPage = paginationDetail.CurrentPage ? paginationDetail.CurrentPage : this.lbCurrentPage;
      this.lbCurrentPage = Math.max(1, this.lbCurrentPage);
    }
  
    processGetGriviewDataResponse(gridViewDataModel: GridViewDataModel, requestObj) {
      let inquiryList = this.retrieveGetGridViewResponse(gridViewDataModel, requestObj).inquiryList;
      // clearing data of previous page
      this.rowData = [];
      // Adding data of current page
      this.rowData = inquiryList;
      this.notifyMobileDataReady();
    }
  
    notifyMobileDataReady() {
      if (this.isMobile) {
        this.inboxService.notifyGridDataReady(this.strMailboxView);
      }
    }
  
    retrieveGetGridViewResponse(gridViewDataModel: GridViewDataModel, requestObj, columnUpdateFlag = true) {
  
      // Websocket Monitoring for Final Criteria
      if (this.checkIfMonitorEnabled(this.loginUserInfo) && gridViewDataModel) {
        let currentTab = this.strMailboxView;
        console.debug("QMA2.0: Websocket:: GridViewDataModel : final criteria : recieved as Response :- " + gridViewDataModel.finalCriteria + "Tab-> " + currentTab);
  
        if (null == gridViewDataModel.finalCriteria || !gridViewDataModel.finalCriteria) {
          this.inboxService.saveUILog("QMA2.0: Websocket:: GridViewDataModel : final criteria : recieved as Response :- " + JSON.stringify(gridViewDataModel) + "Tab-> " + currentTab).subscribe(res => {
            console.log(res);
          });
        }
      }
  
      // set initial count
      if (gridViewDataModel && gridViewDataModel.totalRecords !== undefined && requestObj.pageNum === 1) {
        this.setGridTotalCount(gridViewDataModel.totalRecords, gridViewDataModel.totalUnreadRecords); // C153176-5106 refactoring
        // C153176-5107: notified totalUnreadRecords
        this.tabDataService.setUnReadCount(this.strMailboxView, gridViewDataModel.totalUnreadRecords);
        let initialUnReadCountAtUI = 0;
  
      }
      if (requestObj && requestObj.isChartView) {
        this.isChartView = requestObj.isChartView;
      }
      if (requestObj && requestObj.isRequestFromMailBoxStats) {
        this.isDashboardInlineView = true;
      }
      // C153176-4447: set searchView flag to true if the request is global search or adv search
      this.searchView = (requestObj && (requestObj.advanceSearchData || requestObj.solrSearchText)) ? true : false;
      // call to update Pagination panel
      this.updatePaginationPanel({ CurrentPage: requestObj.pageNum });
      // restore previously saved Column Configuration
      //console.log("From DB" + JSON.parse(gridViewDataModel.columnConfig.columnConfig).columnConfig)
      // begin saved column preference update in the colDef of primary and sec cols
      // if (columnUpdateFlag && (this.strMailboxView !== "DRAFTS") && (this.strMailboxView !== "SEARCH") &&
      // (!this.isMatchingViewName("intensityHeatMap"))) {
      if (columnUpdateFlag && (this.strMailboxView !== "DRAFTS")) {
        if (gridViewDataModel && gridViewDataModel.columnConfig && gridViewDataModel.columnConfig.columnConfig) {
          // C153176-5043: disable an optimization for mobile, the columnConfig is needed for mobile
          this.columnConfig = JSON.parse(gridViewDataModel.columnConfig.columnConfig).columnConfig;
          this.setColumnsAsperDB(this.columnConfig);
          // fix for C153176-5411 Grid is not responsive. On 1st download need to always reset for it to fit all columns
          // C153176-6071 Issue-If all columns are selected in My view Columns are not loaded with default width.
          // added customViewFlag to skip custom view
          if (!this.customViewFlag && this.gridColumnApi && this.columnConfig && this.isDefaultNumberOfColumns(JSON.parse(gridViewDataModel.columnConfig.columnConfig).columnConfig)) {
            this.gridAutoFitDefaultColumnsByResolution();
          }
          this.columnDefs.forEach(colDefselement => {
            if (colDefselement.headerName.length > 0) {
              this.columnConfig.forEach(elementConfig => {
                //if(colDefselement.headerName == elementConfig.colId) {
                if (colDefselement.field == elementConfig.colId) {
                  colDefselement.hide = elementConfig.hide;
                  colDefselement.showInSecondary = elementConfig.hide;
                }
              });
            }
          });
          // Keep always checkbox, chevron, drag drop icon
          this.columnDefs.forEach(x => {
            if ((x.field == 'expander') || (x.field == 'cheveron') || (x.field == 'cheveron_1') ||
              (x.field == 'isSymphonyChatGridCol' && this.strMailboxView == "INBOX" && this.symphonyEnabled)
              || (x.field == 'isSymphonyChatGridCol' && this.strMailboxView == "CHAT" && this.symphonyEnabled)
            ) {
              if (this.gridColumnApi) {
                this.gridColumnApi.setColumnVisible(x.field, true);
                x.hide = false;
              }
            }
          });
        }
      }
      let inquiryList = gridViewDataModel.inquiryDataList;
      if (!inquiryList || inquiryList.length === 0) {
        inquiryList = [];
        // C153176-4300 -- Conversation View should be empty
        this.selectedMail = null;
        this.inqData = null; // C153176-4660 reset request data
      }
      this.finalCriteria = gridViewDataModel.finalCriteria;
      // save criteria fields for MyView
      if (this.viewType === -1 && !AppUtils.isUndefinedOrNull(this.customViewFlag) && this.customViewFlag) {
        this.myViewCriteria = gridViewDataModel.finalCriteria;
        this.myViewDefaultCriteria = gridViewDataModel.defaultUserCriteria;
        // C170665-370 My View -modified width of column is not showing after refresh start
        // current column state 
        if (this.gridColumnApi) { // C170665-535 | Proceed only if gridColumnApi is initialized.
          let currentGridState = this.gridColumnApi.getColumnState();
          let savedState = JSON.parse(gridViewDataModel.columnDefs);
          let updatedColumns = [];
          let startingIndex = currentGridState.findIndex(cs => cs.colId === "cheveron");
          savedState.forEach((st: any) => {
            startingIndex++;
            let IndexclmnToUpdate = currentGridState.findIndex(cs => cs.colId === st.field);
            if (IndexclmnToUpdate && st.field !== "followUp") {
              let columntoUpdate = currentGridState.splice(IndexclmnToUpdate, 1);
              if (columntoUpdate.length > 0) {
                columntoUpdate[0].width = st.width;
                updatedColumns.push(columntoUpdate[0]);
                currentGridState.splice(startingIndex, 0, columntoUpdate[0]);
              }
            }
          });
  
          // C170665-568 | My view - Annex column is coming as first column for newly created view
          let annexColIdx = currentGridState.findIndex(cs => cs.colId === "followUp");
          if (annexColIdx) {
            // Push the followUp to the end of the array
            currentGridState.push(currentGridState.splice(annexColIdx, 1).pop());
          }
  
          // restore new grid state from DB for my views
          this.gridColumnApi.applyColumnState({
            state:currentGridState,
            applyOrder: true,
          });
        }
        // C170665-370 My View -modified width of column is not showing after refresh End
      }
      // C153176-5081: cache finalUICritieria
      this.finalUICriteria = gridViewDataModel.finalUICriteria;
      // apply filter
      let reqFilter = this.dataCache.getRequestFilter(requestObj);;
      // remove filter for SENT and RESOLVED
      if(!this.isFilterRequired(requestObj)) {
        reqFilter.type = "";
      }
      inquiryList = this.inboxViewFilters.filterGridViewData(inquiryList,
        requestObj,reqFilter , gridViewDataModel, this.assignedGroups, this.inboxService, this.loginUserInfo.userId);
      // to update escalation reason and isEscalated flag
      let escalationReasonStart = performance.now();
      inquiryList.forEach(lst => {
  
        if (lst.workflows && lst.workflows.length > 0) {
          lst.workflows.forEach(wf => {
            let strEscalation: string = '';
  
            if (!this.isNull(wf.generalEscalationReason)) {
              lst.isEscalated = "Y";
              strEscalation = wf.generalEscalationReason;
            }
            if (!this.isNull(wf.responseTimeEscalationReason)) {
              lst.isEscalated = "Y";
              strEscalation += wf.responseTimeEscalationReason;
            }
            if (!this.isNull(wf.manualEscalationReason)) {
              lst.isEscalated = "Y";
              strEscalation += wf.manualEscalationReason;
            }
            lst.escalationReason = strEscalation;
  
  
          });
          let category = this.getClientCategoryGrid(lst);
          lst.category = (category && category.categoryName) ? category.categoryName : "";
  
        }
        //to Remove group from assigned to
        lst.openUsers = this.processAssgnTo(lst.openUsers);
        // maintin read unread inqs at UI level
        if (lst.readBy && lst.readBy.length && lst.readBy.length > 0 && lst.readBy.includes(this.loginUserInfo.userId)) {
  
          this.inqUnreadStatusMap.set(lst._id + ":" + lst.workflows[0].assignedGroupId, false);
        } else {
          if (lst && lst._id && lst.workflows) {
  
            this.inqUnreadStatusMap.set(lst._id + ":" + lst.workflows[0].assignedGroupId, true);
          }
  
        }
  
        // Add Category in list
  
      })
  
      let escalationReasonEnd = performance.now();
      console.debug("Call to generate escalation reason took " + (escalationReasonEnd - escalationReasonStart) + " milliseconds.");
      let additionalClmsStart = performance.now();
      inquiryList.forEach(inq => {
        inq.cheveron = "";
        inq.cheveron_1 = "";
        inq.checkbox = "";
        // symphony change
        if ((this.strMailboxView == "INBOX" || this.strMailboxView == "CHAT") && this.symphonyEnabled) {
          inq.isSymphonyChatGridCol = ""
          this.symphonyGridView.markChatAsUnread(inq);
        }
  
  
      });
      var additionalClmsEnd = performance.now();
      console.debug("Call to Add additional columns took " + (additionalClmsEnd - additionalClmsStart) + " milliseconds.");
  
      let exportData = inquiryList;
      exportData.forEach(inq => {
        if (inq.followUp) {
          inq.followUp = "Y"
        } else {
          inq.followUp = "N"
        }
        if (inq.age === "") {
          //objMailBoxHelper.calculateAndSetAge(inq);
        }
      });
      this.dataExportToExcel = exportData;
  
      if (!inquiryList || !inquiryList.length) {
        // stop loading spinner here only if there is no data
        this.inboxService.stopViewLoading();
      }
      // C153176-4885: if this is page 1 and inquiry count is less than initial count, and the initial count is less than page size,
      // update the initial count
      if (requestObj.pageNum === 1 && this.initialCount < requestObj.pageSize && inquiryList && inquiryList.length < this.initialCount) {
        this.setGridTotalCount(inquiryList.length, undefined); // C153176-5106 refactoring - do not pass totalUnreadRecords here since it was already taken into account
      }
      // if (this.isWebSocketRedesignEnable) {
      //   this.setgridDetailsObject(AppUtils.getCurrentTab(), gridViewDataModel.finalCriteria, +gridViewDataModel.viewType);
      //   // Websocket Monitoring for Final Criteria
      //   if (this.checkIfMonitorEnabled(this.loginUserInfo) && gridViewDataModel) {
      //     let currentTab = AppUtils.getCurrentTab();
      //     console.debug("QMA2.0: Websocket:: GridViewDataModel : final criteria : After setgridDetailsObject():- " + gridViewDataModel.finalCriteria + "Tab-> " + currentTab);
      //     if (null == this.gridDetailsObject.criteria || !this.gridDetailsObject.criteria) {
      //       this.inboxService.saveUILog("QMA2.0: Websocket:: GridViewDataModel : final criteria : After setgridDetailsObject() :- " + JSON.stringify(gridViewDataModel) + "Tab-> " + currentTab).subscribe(res => {
      //         console.log(res);
      //       });
      //     }
      //   }
      // }
  
      return { inquiryList: inquiryList, totalPages: 0 };
    }
    setgridDetailsObject(tabName, criteria, viewType, selInq = null) {
      this.gridDetailsObject.name = tabName;
      this.gridDetailsObject.criteria = criteria;
      this.gridDetailsObject.viewType = viewType;
      this.gridDetailsObject.selInq = selInq ? selInq : this.gridDetailsObject.selInq;
    }
    removeResolvedInquiry(inq: any) {
      if (this.dataCache.requestObj.viewName && this.dataCache.requestObj.viewName === "intensityHeatMap") {
  
        return;
      }
      let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
      let nodeToDelete = [];
      let multigrpInqWfs;
      if (matchingWfExists) {
        multigrpInqWfs = inq.workflows.filter(wf => wf.status === "Resolved" &&
          wf.direction === matchingWfExists);
      } else if (this.isDirectionAgnosticView()) { // C153176-4447: refactoring
        // myviews or dashboard inline view or chart view or search result - C153176-4447
        multigrpInqWfs = inq.workflows.filter(wf => wf.status === "Resolved");
      }
      if (multigrpInqWfs) {
        this.gridApi.forEachNode(function (node) {
          if (inq._id === node.data._id && multigrpInqWfs.findIndex(wf => wf.assignedGroupName === node.data.openGroups) > -1) {
            nodeToDelete.push(node.data)
  
          }
        });
      }
      // remove resolved inqs from inbox
      if (nodeToDelete.length > 0) {
        const selectedGridRow = this.gridApi.getSelectedRows();
        let res = this.gridApi.updateRowData({ remove: nodeToDelete });
  
        // after removing Inq from grid remove it from row data.
        if (nodeToDelete.length > 0) {
          this.removeInqFromRowData(nodeToDelete);
        }
  
        this.updateCountLabels(nodeToDelete[0], true); // fix for C153176-5428 Websocket issue for pagination and view issue raised by user
  
        let isOpenWfExists = inq.workflows.find(wf => wf.direction === matchingWfExists && wf.status === "Open");
        // C153176-4639: check whether the row is currently selected. If yes, select row 0 since this one will be removed.
        if (selectedGridRow && selectedGridRow.length && !isOpenWfExists) {
          const deletedRow = selectedGridRow.filter(row => nodeToDelete.some(node => node && node._id === row._id));
          if (deletedRow && deletedRow.length === selectedGridRow.length) {
            // select row 0
            let rowNode = this.gridApi.getDisplayedRowAtIndex(0);
            if (rowNode) {
              rowNode.setSelected(true);
            }
          }
        }
      }
      // C153176-5757 Websocket issue- Nominate ownership
      this.processAcceptedNominateOwnership(inq);
      this.processReassignedInq(inq)
    }
    // C153176-5757 Websocket issue- Nominate ownership
    processAcceptedNominateOwnership(inq) {
      switch (this.strMailboxView) {
        case "INBOX": {
          let acceptedNominateOwnership = inq.workflows.filter(wf => wf.workflowStatus && wf.workflowStatus === "Accepted - NOMINATE_OWNERSHIP");
          if (acceptedNominateOwnership) {
            acceptedNominateOwnership.forEach(wf => {
              if (wf.status && wf.status === "Open" && wf.direction === "IN") {
                let inqIndex = this.rowData.findIndex(rdata => inq._id === rdata._id && rdata.openGroups === wf.assignedGroupName);
                if (inqIndex === -1) {
                  const inqToInsert = Object.assign({}, inq);
                  inqToInsert.openGroups = wf.assignedGroupName;
                  this.updateInqByWf(inqToInsert);
                  this.addItemsAtTop(inqToInsert);
                }
              }
  
            })
          }
        }
      }
  
    }
    // C153176-5771 Inquiry retains back in Assign to me folder
    processReassignedInq(inq) {
      switch (this.strMailboxView) {
        case "Assigned To Me": case "INBOX": {
          let insertInGrid = inq.workflows.filter(wf => wf.direction == "IN" && wf.status === "Open");
          if (insertInGrid) {
            let insertedinqs = 0;
            insertInGrid.forEach(wf => {
              let isInqExists = this.rowData.findIndex(irowData => irowData._id === inq._id &&
                irowData.openGroups === wf.assignedGroupName);
              if (isInqExists === -1) {
                insertedinqs++;
                const inqToInsert = Object.assign({}, inq);
                inqToInsert.openGroups = wf.assignedGroupName;
                this.updateInqByWf(inqToInsert);
                this.addItemsAtTop(inqToInsert);
              }
  
            });
            if (insertedinqs > 0 && inq.latestUserId && this.loginUserInfo.userId === inq.latestUserId) {
              this.inboxStateChanged(null);
            }
          }
        }
      }
  
    }
  
    // C153176-4663: matching columnId vs. field (handling mismatches)
    matchColIdAndField(colId, field) {
      if (colId == field) {
        return true;
      }
      if (!colId || !field) {
        return false;
      }
      if (colId === '_id' && field === 'inquiryId') {
        return true;
      }
      if (colId.includes('$date') && colId.replace('.$date', '') === field) {
        return true;
      }
      return false;
    }
  
    initializeWebWorker(){
      if (!this.wsWorker && this.isWebWorkerEnable) {
        
        console.debug(`webworker: tab: ${this.currentTabName} initializing worker instance inboxView.subscribeToWebSocketUpdate`);
        this.wsWorker = new InlineWorker(WebSocketUtil.wsWorkerInstance);
        this.isWebWorkerBusy = false;
  
        this.wsWorker.onmessage().subscribe((data) => {
          this.showWsProcessingErrorIfExists(data);
          let webworkerPerformanceRecieveWsUpdatePostWebworkerStart = performance.now();
          console.debug(`webworker performance: recieved total ${data.data.length} inquiries from worker thread tab: ${this.currentTabName}`);
          this.performGridOperationOnWorkerResponse(data);
          //setTimeout(this.performGridOperationOnWorkerResponsePerRecord, 0, data);
          let webworkerPerformanceRecieveWsUpdatePostWebworkerStop = performance.now() - webworkerPerformanceRecieveWsUpdatePostWebworkerStart;
          console.debug(`webworker performance: tab: ${this.currentTabName} Main thread took ${webworkerPerformanceRecieveWsUpdatePostWebworkerStop} ms to process data after getting data from web worker tab:  ${this.gridDetailsObject.name}`);
          console.debug(`webworker performance: tab: ${this.currentTabName}---------------------------------------------------------------------------------------------------------`);
          this.isWebWorkerBusy = false;
        });
  
        this.wsWorker.onerror().subscribe((data) => {
          console.error(`webworker: tab: ${this.currentTabName} error in inboxView.subscribeToWebSocketUpdate.onError`, data);
          this.isWebWorkerBusy = false;
        });
      }
    }
  
    // subscribeToWebSocketUpdate() {
    //   console.debug(`websocketupdate: subscribing to ws updates in inboxview.subscribeToWebSocketUpdate tab: ${this.currentTabName}`);
    //   this.webSocketSubscription = this.wsService.getwebSocketUpdate().subscribe(
    //     (response: MessageEvent): any => {
    //       try {
    //         console.debug(`websocketupdate: recievied ws updates in inboxview.subscribeToWebSocketUpdate.subscribe tab: ${this.currentTabName}`);
    //         let webworkerPerformanceRecieveWsUpdateStart = performance.now();
  
    //         // add null check //Jira - C153176-3381  
    //         if (response.data) {
    //           // check for redesigned or old ws update
    //           if (this.isWebSocketRedesignEnable) {
    //             // logging ws updatae
    //             if (this.checkIfMonitorEnabled(this.loginUserInfo)) {
    //               let currentTab = this.strMailboxView;
    //               console.debug(" QMA2.0 : Websocket::Received web-socket message for processing from on message :- : "
    //                 + response + " and corresponding data is :" + response.data + "Processing Tab-> " + currentTab);
    //               this.inboxService.saveUILog("QMA2.0 : Websocket::Received web-socket message for processing from on message :-"
    //                 + response.data + "Processing Tab-> " + currentTab).subscribe(res => {
    //                   console.log(res);
    //                 });
    //             }
    //             let inqIdArray = JSON.parse(response.data);
  
    //             console.debug(`websocket performance: recieved total ${inqIdArray.wsInquiryList.length} inquiries from websocket tab: ${this.gridDetailsObject.name}`);
    //             // symphony changes
    //             if ((this.strMailboxView == "INBOX" || this.strMailboxView == "CHAT") && this.symphonyEnabled
    //               && inqIdArray.symphonyNotificationList && inqIdArray.symphonyNotificationList.length > 0) {
    //               this.processSymphonyWebSocketUpdate(inqIdArray.symphonyNotificationList);
    //             }
    //             else if (inqIdArray && inqIdArray.wsInquiryList && inqIdArray.wsInquiryList.length > 0) {
    //               // skip websocket update for symphont rows if symphony is disabled
    //               //TODO:Performance :: below check can be avoided by websocket changes in service side. New :: Check backend code.
    //               if (!this.symphonyEnabled) {
    //                 inqIdArray.wsInquiryList = inqIdArray.wsInquiryList.filter(inq => !inq.isSymphonyChatroom)
    //               }
  
    //               let t0 = performance.now();
    //               if (this.wsWorker && this.isWebWorkerEnable && !this.isMobile) { 
    //                 this.wsQueue.push(inqIdArray);
    //                 console.debug(`webworker ws queue: tab: ${this.currentTabName} pushing batch to queue`);
    //                 this.consumeWsUpdateQueue();
    //                 console.debug(`webworker ws queue: tab: ${this.currentTabName} pushing ws update in queue, current size is ${this.wsQueue.length} and subscribing to timer`);
    //               } else {
    //                 //in case of browser does not support web worker
    //                 this.processWebSocketUpdate(inqIdArray.wsInquiryList);
    //                 let t1 = performance.now();
    //                 //TODO:Performance :: Check if we can remove below call, ANIL : Please check
    //                 this.inboxService.triggerHttpSuccess(200);
    //                 console.log(`Websocket:: processing for inq id:- ${inqIdArray.wsInquiryList[0]._id}  and tab:- ${this.strMailboxView} took:- ${Math.ceil((t1 - t0))} miliseconds`);
    //                 // C153176-6093 Mobile- auto refresh is not working
    //                 // C153176-4925: notify mobile mailbox of rowdata update
    //                /*  if (this.isMobile) {
    //                   this.inboxService.notifyGridDataUpdate(this.strMailboxView);
    //                 } */
    //               }
  
    //             }
    //             // C153176-6093 Mobile- auto refresh is not working
    //             // C153176-4925: notify mobile mailbox of rowdata update
    //             if (this.isMobile) {
    //               this.strMailboxView = AppUtils.getCurrentTab();
    //               this.inboxService.notifyGridDataUpdate(this.strMailboxView);
    //             }
    //           }
    //           else { //TODO:Performance :: Remove this code as this is not needed now.
    //             let data = JSON.parse(response.data);
    //             if (data.length > 0) {
    //               console.log("Websocket::Processing for inq_id:-" + data[0]._id + " started");
    //               console.log("Websocket::Processing for Tab:-" + this.strMailboxView + " started");
    //               let t0 = performance.now()
    //               this.updateMailBoxFromWebSocket(data);
    //               this.inboxService.triggerHttpSuccess(200);
    //               let t1 = performance.now();
    //               console.log("Websocket::Processing Call to ws processig for inq:- " + data[0]._id + " took milliseconds:- " + (t1 - t0))
    //             }
    //           }
    //         }
    //       }
    //       catch (exception) {
    //         console.log(exception);
    //         // additional logs to capture actual message failed
    //         let currentTab = this.strMailboxView;
  
    //         if (response && response.data) {
    //           console.log("QMA2.0 : Websocket:: exception in processing websocket response at root level batch will be discarded:-:-" + response.data);
    //           this.inboxService.saveUILog("QMA2.0 : Websocket:: exception in processing websocket response at root level batch will be discarded::"
    //             + "<br> and the ERROR is " + response.data + "Processing tab->" + currentTab).subscribe(res => {
    //               console.log(res);
    //             });
    //         }
    //         else if (response) {
    //           console.log("QMA2.0 : Websocket:: exception in processing websocket response at root level batch will be discarded:-" + response);
    //           this.inboxService.saveUILog("QMA2.0 : Websocket:: exception in processing websocket response at root level batch will be discarded::  "
    //             + "<br> and the ERROR is " + response + "Processing tab->" + currentTab).subscribe(res => {
    //               console.log(res);
    //             });
    //         }
    //       }
    //     }
    //   );
    // }
  
  subscribeToTimer(){
    this.inboxService.readWsQueue.subscribe((readFlag)=>{
      if(readFlag){
        this.subscribeToWsQueue();
      }
    });
  }  
  
    subscribeToWsQueue () {
      let inqIdArray = this.inboxService.wsQueue.get(this.currentTabName);
      try {
        let webworkerPerformanceRecieveWsUpdateStart = performance.now();
        
        if (inqIdArray) {
          // check for redesigned or old ws update
          if (this.isWebSocketRedesignEnable) {
            // logging ws updatae
            if (this.checkIfMonitorEnabled(this.loginUserInfo)) {
              let currentTab = this.strMailboxView;
              console.debug(" QMA2.0 : Websocket::Received web-socket message for processing from on message :- : "
                 + " and corresponding data is :" + inqIdArray + "Processing Tab-> " + currentTab);
              this.inboxService.saveUILog("QMA2.0 : Websocket::Received web-socket message for processing from on message :-"
                + inqIdArray + "Processing Tab-> " + currentTab).subscribe(res => {
                  console.log(res);
                });
            }
           // let inqIdArray = JSON.parse(response.data);
  
            console.debug(`webworker performance: recieved total ${inqIdArray.length} inquiries from websocket tab: ${this.gridDetailsObject.name}`);
            // symphony changes
            if ((this.strMailboxView == "INBOX" || this.strMailboxView == "CHAT") && this.symphonyEnabled
              && inqIdArray && inqIdArray.length > 0) {
                for(let i=0;inqIdArray.length>0;i++){
                  this.processSymphonyWebSocketUpdate(inqIdArray[i]['symphonyNotificationList']);
                }
            }
            else if (inqIdArray && inqIdArray['wsInquiryList'] && inqIdArray['wsInquiryList'].length > 0) {
              // skip websocket update for symphont rows if symphony is disabled
              //TODO:Performance :: below check can be avoided by websocket changes in service side. New :: Check backend code.
              if (!this.symphonyEnabled) {
                inqIdArray['wsInquiryList'] = inqIdArray['wsInquiryList'].filter(inq => !inq.isSymphonyChatroom)
              }
  
              let t0 = performance.now();
              if (this.wsWorker && this.isWebWorkerEnable) {
                //this.wsQueue.push(inqIdArray);
                this.consumeWsUpdateQueueFromNotification();
                console.debug(`webworker ws queue: tab: ${this.currentTabName} pushing ws update in queue, current size is ${this.wsQueue.length} and subscribing to timer`);
              } else {
                //in case of browser does not support web worker
                this.processWebSocketUpdate(inqIdArray['wsInquiryList']);
                let t1 = performance.now();
                //TODO:Performance :: Check if we can remove below call, ANIL : Please check
                this.inboxService.triggerHttpSuccess(200);
                console.log(`Websocket:: processing for inq id:- ${inqIdArray['wsInquiryList'][0]._id}  and tab:- ${this.strMailboxView} took:- ${Math.ceil((t1 - t0))} miliseconds`);
                // C153176-6093 Mobile- auto refresh is not working
                // C153176-4925: notify mobile mailbox of rowdata update
                if (this.isMobile) {
                  this.inboxService.notifyGridDataUpdate(this.strMailboxView);
                }
              }
  
            }
          }
        }
      }
      catch (exception) {
        console.log(exception);
        // additional logs to capture actual message failed
        let currentTab = this.strMailboxView;
  
        if (inqIdArray) {
          console.log("QMA2.0 : Websocket:: exception in processing websocket response at root level batch will be discarded:-:-" + inqIdArray);
          this.inboxService.saveUILog("QMA2.0 : Websocket:: exception in processing websocket response at root level batch will be discarded::"
            + "<br> and the ERROR is " + inqIdArray + "Processing tab->" + currentTab).subscribe(res => {
              console.log(res);
            });
        }
      }
    }
  
    performGridOperationOnWorkerResponse = (data) => {
      try {
      //console.debug(`webworker: delaying execution by 0 ms inboxView.performGridOperationOnWorkerResponse` + new Date());
      let inquiryList: Array<object> = data.data;
      inquiryList.forEach((inq) => {
        let action = inq['action'];
        if (action == 'add') {
          let grpInq = inq['grpInq'];
          this.AddInqToGrid(grpInq, 0);
        } else if (action == 'update') {
          let existingInqIndex = inq['existingInqIndex'];
          let wsInq = inq['wsInq'];
          this.updateGridAsynch(existingInqIndex, wsInq);
        } else if (action == 'remove') {
          let existingInqIndex = inq['existingInqIndex'];
          let wsInq = inq['wsInq'];
          this.removeInqFromGrid(wsInq, existingInqIndex);
        }
      });
    }
    catch(exception) {
      console.error("Exception in performGridOperationOnWorkerResponse"+exception);
    }
    }
  
    performGridOperationOnWorkerResponsePerRecord = (data) => {
      //console.debug(`webworker: delaying execution by 0 ms inboxView.performGridOperationOnWorkerResponse` + new Date());
      let inq = data.data;
      let action = inq['action'];
      if (action == 'add') {
        let grpInq = inq['grpInq'];
        this.AddInqToGrid(grpInq, 0);
      } else if (action == 'update') {
        let existingInqIndex = inq['existingInqIndex'];
        let wsInq = inq['wsInq'];
        this.updateGridAsynch(existingInqIndex, wsInq);
      } else if (action == 'remove') {
        let existingInqIndex = inq['existingInqIndex'];
        let wsInq = inq['wsInq'];
        this.removeInqFromGrid(wsInq, existingInqIndex);
      }
    }
  
  
    processWebSocketUpdate(inqIdArray) {
      //this.printInquiryIdsReceived(inqIdArray);
      if (!this.isUndefinedOrNull(inqIdArray) && inqIdArray.length > 0) {
        // will be used in logging
        let unProcessedInqIds = inqIdArray.map(inq => inq._id);
        //TODO:Performance :: If criteria is blank/empty/null then stop further ws processing and notify user
        let criteria = this.gridDetailsObject.criteria ? this.gridDetailsObject.criteria : "criteria is blank";
        if (criteria === "criteria is blank") {
        let data ={data:[]};
        data.data = [...inqIdArray];
        data.data.push({hasWsBatchProcessingError:true,error:'criteria is blank'});
        this.showWsProcessingErrorIfExists(data);
        return;
        }
        // get grid status before ws update
        //TODO:Performance :: Please remove below method call as this is currently not needed.
        // we  need this to restore sort by date on  grid after websocket update
        // and also user applied sorts if any
        this.gridStateBeforeWsUpdate();
  
        try {
          let currTabName = this.gridDetailsObject.name;
  
          // step 1 create subset of matching inq id ws update
          //TODO:Performance :: Why deep copy and create subset here, why not directly updating wss inquiries in the data source itself
          let wsMatchingInqSubset = [];
          if (this.rowData && this.rowData.length > 0) {
  
            // performance improvement change
            /*     this.rowData.forEach(rd => {
                if (inqIdArray.some(d2 => d2._id == rd._id)) {
                  let inqCopy = $.extend(true, {}, rd);
                  inqCopy.markForDeletion = true;
                  // step 2 mark subset of matching inq to delete
                  wsMatchingInqSubset.push(inqCopy);
                }
              }); */
  
            // for bulk opearations with diff inq ids
            this.rowData.forEach(rd => {
              var inqData = _.filter(inqIdArray, (inq) => {
                return rd._id == inq._id;
              });
              if (inqData.length > 0) {
                let inqCopy = $.extend(true, {}, rd);//deep copy of inquiry
                inqCopy.markForDeletion = true;
                // step 2 mark subset of matching inq to delete
                wsMatchingInqSubset.push(inqCopy);
              }
            });
          }
  
          for (let inqIndex = 0; inqIndex < inqIdArray.length; inqIndex++) {
  
            let inq = inqIdArray[inqIndex];
            inq.id = inq._id;
            // symphonyChanges
            // explicitly mark chat as read
            /*  if(this.symphonyEnabled && inq.isSymphonyChatroom) {
               inq.readBy = ['XXXXX'];
             } */
            //this method evaluate the criteria, on true adds the inquiries to grid
            this.multiLineProcessMessage(inq,
              this.gridDetailsObject.viewType,
              /*  allGridDetailsArray[i].grid, */
              this.gridDetailsObject.criteria,
              this.rowData, 0, wsMatchingInqSubset);
            /* this.gridApi.setRowData(this.rowData); */
  
            // remove processed ids
            if (unProcessedInqIds && unProcessedInqIds.length > 0 && inq._id) {
              let indexToRemove = unProcessedInqIds.indexOf(inq._id);
              if (indexToRemove > -1) {
                unProcessedInqIds.splice(unProcessedInqIds.indexOf(inq._id), 1)
              }
            }
  
          }
          wsMatchingInqSubset.forEach(wsInq => {
            let existingInqIndex = this.rowData.findIndex(rd => rd._id === wsInq._id &&
              rd.workflows[0].assignedGroupId === wsInq.workflows[0].assignedGroupId
              && rd.workflows[0].direction === wsInq.workflows[0].direction);
            // update existing  Unqualified inq
            if (!wsInq.markForDeletion) {
  
              delete wsInq.markForDeletion;
              this.updateGridAsynch(existingInqIndex, wsInq);
  
  
            } else if (wsInq.markForDeletion) { // remove existing inq from grid which is no more qualifies
  
              this.removeInqFromGrid(wsInq, existingInqIndex)
            }
          })
        }
        catch (err) {
          let data ={data:[]};
          data.data = [...inqIdArray];
          data.data.push({hasWsBatchProcessingError:true,error:'criteria is blank'});
          this.showWsProcessingErrorIfExists(inqIdArray);
         // this.showWebSocketProcessingFailureMsg = true;
          let currentTab = this.strMailboxView;
          console.log("QMA2: Websocket:: onMessage, error in processing data:::  " + "Input Ids"
            + JSON.stringify(inqIdArray)
            + "<br> and the ERROR is " + err.stack + "Processing tab->" + currentTab + "  Unprocessed Ids:-"
            + JSON.stringify(unProcessedInqIds) + " criteria evaluated causing error:->"
            + criteria);
          this.inboxService.saveUILog("QMA2.0 : Websocket:: onMessage, error in processing data:::  " +
            "Processing tab->" + currentTab + "  Unprocessed Ids:-" + JSON.stringify(unProcessedInqIds) +
            "Input Ids:-" + JSON.stringify(inqIdArray) + "<br> and the ERROR is " + err.stack
            + " criteria evaluated causing error:->" + criteria).subscribe(res =>
              console.log(res));
        }
  
  
      }
    }
    restoreGridStateToBeforeWsUpdate() {
      // restore column filters
      if (this.objGridStateBeforeWsUpdate.sortModel) {
        this.loadGridColumnsSortData(this.objGridStateBeforeWsUpdate.sortModel)
        // this.gridApi.setSortModel(this.objGridStateBeforeWsUpdate.sortModel);
      }
      // select first row if selected rows got deleted
      this.selectFirstInqIfNothingSelected();
    }
  
    selectFirstInqIfNothingSelected() {
      // select first row if selected rows got deleted
      let isAnythigSelected = this.isAnyRowSelected();
  
      if (this.gridApi && !isAnythigSelected) {
        this.selectFirstInquiry();
      }
  
    }
  
    private isAnyRowSelected() {
      let isAnythigSelected = true;
      let selectedNodes = this.gridApi.getSelectedNodes();
      if (selectedNodes && selectedNodes.length === 0) {
        isAnythigSelected = false;
  
      }
      // check conv grid if available
      if (this.gridApi.detailGridInfoMap) {
        let detailGrids = Object.keys(this.gridApi.detailGridInfoMap);
        detailGrids.forEach((dg) => {
          let detailGrid = this.gridApi.detailGridInfoMap[dg];
          if (detailGrid) {
            if (detailGrid.api.rowModel.rowsToDisplay.some(rd => rd.selected)) {
              // set flag here
              isAnythigSelected = true;
            }
  
          }
        });
      }
  
      // check sessionstorage when conv grid not yet ready
      // 170665-406 Focus movement in grid
      if (sessionStorage.getItem("selectedConv_" + this.strMailboxView)) {
        isAnythigSelected = true;
      }
      return isAnythigSelected;
    }
  
    selectFirstInquiry() {
      if (this.gridApi) {
        let rowNode = this.gridApi.getDisplayedRowAtIndex(0);
        if (rowNode && !rowNode.allChildrenCount) {
          rowNode.setSelected(true);
        } else if (rowNode && rowNode.allChildrenCount && rowNode.allChildrenCount > 0) { // in case of group by
          this.gridApi.getRowNode(this.gridApi.getDisplayedRowAtIndex(0).allLeafChildren[0].data._id + '_' +
            this.gridApi.getDisplayedRowAtIndex(0).allLeafChildren[0].data.workflows[0].assignedGroupId + '_' +
            this.gridApi.getDisplayedRowAtIndex(0).allLeafChildren[0].data.workflows[0].direction).setSelected(true);
        }
      }
    }
    gridStateBeforeWsUpdate() {
      this.objGridStateBeforeWsUpdate = new ClsGridStateBeforeWsUpdate();
      if (this.gridColumnApi) {
        this.objGridStateBeforeWsUpdate.sortModel = this.gridColumnApi.getColumnState();
      //  this.objGridStateBeforeWsUpdate.sortModel = this.gridApi.getSortModel();
      }
    }
    //process the message/inquiry received by websocket - this method evalaute the criteria, on true adds the inquiries to grid
    multiLineProcessMessage(grpInq, viewType, criteria, data, originalDataSelectIndex, wsMatchingInqSubset) {
      let isMatchingCriteria = this.isInquiryMatchingCriteria(viewType, grpInq, criteria, wsMatchingInqSubset);
  
      //Find the original select row index before applying the pub sub
      if (isMatchingCriteria) // add the record if the criteria true
      {
        //TODO:Performance :: please remove below index if not getting used
        let indexKey = grpInq._id.toString()
          + grpInq.workflows[0].assignedGroupId.toString();
  
        let existingInqIndex = -1;
        if (this.rowData) {
          existingInqIndex = this.rowData.findIndex(rd => rd._id === grpInq.id &&
            rd.workflows[0].assignedGroupId === grpInq.workflows[0].assignedGroupId
            && rd.workflows[0].direction === grpInq.workflows[0].direction);
        }
  
        if (existingInqIndex > -1) {
          // step 3 matching criteria tru and inq already exists mark subst inq to delete false
          // Its an update scenario
  
          wsMatchingInqSubset.forEach((inq, index) => {
            if (inq._id === grpInq.id &&
              inq.workflows[0].assignedGroupId === grpInq.workflows[0].assignedGroupId
              && inq.workflows[0].direction === grpInq.workflows[0].direction) {
  
              // replace inq with ws inq
              //TODO:Performance :: Below two lines are not needed as it already contains same deep copied object. or avoid deep copy below
              let inqCopy = $.extend(true, {}, grpInq);//deep copy of inquiry
              wsMatchingInqSubset[index] = inqCopy;
              wsMatchingInqSubset[index].markForDeletion = false;
            }
          });
  
        } else {
          // ignore search folder
          if (this.gridDetailsObject.viewType === QmaConstant.typeEnum.GLOBALSEARCH.TYPE) {
            return;
          }
          // Add Inq
          this.AddInqToGrid(grpInq, 0);
  
          // push as new row in case of new inquiry etc
  
        }
  
      }
  
    }
  
    updatePaginationPanleForWs(operation) {
      let displayLength = this.getLength(this.gridApi.rowRenderer.rowModel.rowsToDisplay);
      this.lbPageSize = displayLength;
      if (operation === "increment") {
        /*  ++this.lbPageSize; */
        ++this.initialCount;
        this.lbTotalRecords = this.initialCount.toLocaleString();
      }
      if (operation === "decrement") {
        /*  --this.lbPageSize; */
        --this.initialCount;
        this.lbTotalRecords = this.initialCount.toLocaleString();
      }
    }
  
    removeInqFromGrid(grpInq, existingInqIndex) {
      // ignore search folder
      if (this.gridDetailsObject.viewType === QmaConstant.typeEnum.GLOBALSEARCH.TYPE) {
        return;
      }
      let nodeToDelete = [];
      /* this.gridApi.forEachNode(function (node) {
        if (grpInq._id === node.data._id && node.data.workflows[0].assignedGroupId === grpInq.workflows[0].assignedGroupId
          && node.data.workflows[0].direction === grpInq.workflows[0].direction) {
          nodeToDelete.push(node.data)
  
        }
      }); */
      let rowNode = this.gridApi.getRowNode(grpInq._id + '_' + grpInq.workflows[0].assignedGroupId + '_' + grpInq.workflows[0].direction);
      if (rowNode) {
        nodeToDelete.push(rowNode.data);
      }
      if (nodeToDelete.length > 0) {
        let selectedNodes = this.gridApi.getSelectedNodes();
        let res = this.gridApi.updateRowData({ remove: nodeToDelete });
        this.rowData.splice(existingInqIndex, 1);
        let selectedNodesAfterDelete = this.gridApi.getSelectedNodes();
        // update pagination panel
        this.updatePaginationPanleForWs("decrement");
        // update unread count on tab and left nav
        if (this.checkIfInqUnread(grpInq)) {
          this.DecrementUnReadCount({ id: grpInq._id, agrpId: grpInq.workflows[0].assignedGroupId });
        }
  
        //this.selectFirstInqIfNothingSelected();
        //select next record or first record if removed recored is last
        this.selectNextInq(existingInqIndex,selectedNodes);
      }
  
    }
  
    selectNextInq(removedIndex,selectedNodes) {
      // select first row if selected rows got deleted
      let isAnythigSelected = this.isAnyRowSelected();
  
      if (this.gridApi && !isAnythigSelected) {
        this.selectNextOrFirstInquiry(removedIndex,selectedNodes);
      }
  
    }
    selectNextOrFirstInquiry(removedIndex,selectedNodes) {
      if (this.gridApi) {
        let indexNumber = selectedNodes.length-1;
        let indexToBeRemoved = selectedNodes[indexNumber].childIndex;
        // let rowNode = this.gridApi.getDisplayedRowAtIndex(removedIndex);
        let rowNode = this.gridApi.getDisplayedRowAtIndex(indexToBeRemoved);
        if(AppUtils.isUndefinedOrNull(rowNode)){
          //select last record if there is no next record
          rowNode = this.gridApi.getDisplayedRowAtIndex(removedIndex-1);
          //this.gridApi.ensureIndexVisible(rowNode.rowIndex, 'top');
        }
        if (rowNode && !rowNode.allChildrenCount) {
          rowNode.setSelected(true);
        } else if (rowNode && rowNode.allChildrenCount && rowNode.allChildrenCount > 0) { // in case of group by
          this.gridApi.getRowNode(this.gridApi.getDisplayedRowAtIndex(0).allLeafChildren[0].data._id + '_' +
            this.gridApi.getDisplayedRowAtIndex(0).allLeafChildren[0].data.workflows[0].assignedGroupId + '_' +
            this.gridApi.getDisplayedRowAtIndex(0).allLeafChildren[0].data.workflows[0].direction).setSelected(true);
        }
      }
    }
  
    updateGridAsynch(existingInqIndex, grpInq) {
      let itemToUpdate = this.rowData[existingInqIndex];
      this.updateSummaryDataWithVersionlevelDataForGrid(grpInq, existingInqIndex);
      this.UpdateData(grpInq);
  
    }
  
    updateConversationView(existingInqIndex) {
      // to trigger ngonchnage of detailview
      let copyOfSelectedMail = $.extend(true, {}, this.selectedMail);// deep copy of inquirythis.selectedMail;
      copyOfSelectedMail.inquiry = this.rowData[existingInqIndex];
      this.selectedMail = null;
      this.selectedMail = copyOfSelectedMail;
      this.assignedGroup = null;
      this.assignedGroup = this.rowData[existingInqIndex].openGroups;
      this.inquiryWorkflow = null;
      this.inquiryWorkflow = this.rowData[existingInqIndex].workflows[0];
      this.cdr.detectChanges();
    }
  
    // to make api call to refresh detailview
    refreshDetailView() {
      this.onSelectionChanged();
    }
  
    UpdateData(inq) {
      let rowNode = this.gridApi.getRowNode(inq._id + '_' + inq.workflows[0].assignedGroupId + '_' + inq.workflows[0].direction);
      if (rowNode) {
        let selectedConvIdBeforeUpdate = this.selectedConvId
        if (rowNode.data.isProgrammaticExpand) {
          rowNode.setData(inq);
          rowNode.data.isProgrammaticExpand = true;
        } else {
          rowNode.setData(inq);
          rowNode.data.isProgrammaticExpand = false;
        }
        this.gridApi.refreshClientSideRowModel('sort');
        // for hori and ver view
        let isEditMode = (!this.isChatView && this.inlineReply) ? true : false;
        // if (this.isChatView && this.chatComponent && this.chatComponent.editorApp && this.chatComponent.bottomBarExpand) {
        //   isEditMode = true;
        // }
        // incase of reply replyall forward update conv history by closing and opening cheveron
        if (inq.action && (inq.action === "ReplyAll") || (inq.action === "Reply") ||
          (inq.action === "Forward")) {
            if (rowNode.expanded || rowNode.data.isProgrammaticExpand) {
            if (selectedConvIdBeforeUpdate) {
              rowNode.data.isProgrammaticExpand = true;
            }
            setTimeout(() => {
              this.gridApi.setRowNodeExpanded(rowNode,false)
              setTimeout(() => {
                this.gridApi.setRowNodeExpanded(rowNode,true)
              }, 5);
            }, 0);
          }
          // refresh detail view only if ws update is selected inq
  
          if (!selectedConvIdBeforeUpdate && !isEditMode && this.inquiryWorkflow.groupId == inq.workflows[0].assignedGroupId && this.selectedInqId === inq._id) {
            // this.refreshDetailView();
            // C170665-570 Email as chat: inquiry is not moving on top if replied from chat view
            if (this.mailDetailViewComp) {
              this.mailDetailViewComp.forceRefresh = true; // C170665-148 Reading pane Focus moving itself
            }
  
          } else {
            // C170665-570 Email as chat: inquiry is not moving on top if replied from chat view
            if (this.mailDetailViewComp) {
              this.mailDetailViewComp.forceRefresh = false; // C170665-148 Reading pane Focus moving itself
            }
          }
        }
        else if (!isEditMode && this.inquiryWorkflow.groupId == inq.workflows[0].assignedGroupId && this.selectedInqId === inq._id) {
          // this.updateConversationDetailView(inq); // C170665-148 Reading pane Focus moving itself
        }
      }
      /*  this.gridApi.forEachNode((rowNode) => {
        if (inq._id === rowNode.data._id && inq.workflows[0].assignedGroupId === rowNode.data.workflows[0].assignedGroupId
          && inq.workflows[0].direction === rowNode.data.workflows[0].direction) {
            let selectedConvIdBeforeUpdate = this.selectedConvId
             rowNode.setData(inq);
             // for hori and ver view
             let isEditMode = (!this.isChatView && this.inlineReply)?true:false;
            // for chatview
            if(this.isChatView && this.chatComponent && this.chatComponent.bottomBarExpand) {
              isEditMode = true;
            }
          // incase of reply replyall forward update conv history by closing and opening cheveron
          if (inq.action && (inq.action === "ReplyAll") || (inq.action === "Reply") ||
            (inq.action === "Forward")) {
            if (rowNode.expanded) {
              if(selectedConvIdBeforeUpdate) {
                rowNode.data.isProgrammaticExpand=true;
              }
              rowNode.setExpanded(false);
              rowNode.setExpanded(true);
            }
            // refresh detail view only if ws update is selected inq
            
            if(!selectedConvIdBeforeUpdate  && !isEditMode && this.inquiryWorkflow.groupId == inq.workflows[0].assignedGroupId && this.selectedInqId === inq._id) {
              this.refreshDetailView();
            }
          }
          else if(!isEditMode && this.inquiryWorkflow.groupId == inq.workflows[0].assignedGroupId && this.selectedInqId === inq._id) {
            this.updateConversationDetailView(inq);
          }
        }
       
      }); */
      // C153176-6030 Focus lost -Expand conversation history and reply
      this.selectFirstInqIfNothingSelected();
  
      if (this.objGridStateBeforeWsUpdate.sortModel) {
        this.loadGridColumnsSortData(this.objGridStateBeforeWsUpdate.sortModel)
        // this.gridApi.setSortModel(this.objGridStateBeforeWsUpdate.sortModel);
      }
      // C153176-6033 Resolve not working from Conversation pane
      this.updateSelectedRowData();
  
    }
  
    updateConversationDetailView(wsInq) {
      // update conv detail inq with ws Inq
      let workflowAudit = this.selectedMail.inquiry.workflowAudit;
      this.selectedMail.inquiry = wsInq;
      this.selectedMail.inquiry.workflowAudit = this.WsupdateInqWorkflowAudit(wsInq, workflowAudit);
      let tempSelectedMail = this.selectedMail;
      this.selectedMail = { ...tempSelectedMail };
  
    }
    WsupdateInqWorkflowAudit(wsInq, workflowAudit) {
      if (wsInq.workflowAuditSize && wsInq.workflowAuditSize > 0 && wsInq.workflowAuditSize > workflowAudit.length) {
        let deltaAuditSize = wsInq.workflowAuditSize - workflowAudit.length;
        for (let i = wsInq.workflowAuditLimitedToRecentFive.length - deltaAuditSize; i < wsInq.workflowAuditLimitedToRecentFive.length; i++) {
          wsInq.workflowAuditLimitedToRecentFive[i].modDate = Date.parse(wsInq.workflowAuditLimitedToRecentFive[i].modDate.$date);
          workflowAudit.push(wsInq.workflowAuditLimitedToRecentFive[i]);
        }
      }
      return workflowAudit
    }
    // C153176-6033 Resolve not working from Conversation pane
    updateSelectedRowData() {
      this.selectedRowData = null;
      this.selectedRowData = [...this.gridApi.getSelectedRows()];
    }
    AddInqToGrid(grpInq, index) {
      this.addCustomColumnsToInq(grpInq);
      this.rowData.unshift(grpInq);
      // C170665-524 Performance Issue - While switching an inquiry in the grid
      // Fix for ws insert focus change
      this.inqAddedByWs = true;
      var res = this.gridApi.updateRowData({
        add: [grpInq],
        addIndex: index
      });
      this.updatePaginationPanleForWs("increment");
      // to update unread count of tab and left nav 
      if (this.checkIfInqUnread(grpInq)) {
        this.IncrementUnReadCount({ inqId: grpInq._id, agrpId: grpInq.workflows[0].assignedGroupId });
      }
    }
    getIndexOfWsInqInGrid(grpInqId, grpInq_assignedGroupId, direction) {
      return this.gridApi.rowModel.rowsToDisplay.map(rd => rd).map(rn => rn.data).map(
  
        data => {
          return {
            'assignedGroupId': data.workflows[0].assignedGroupId,
            '_id': data._id,
            'direction': data.workflows[0].direction
          }
        }
      ).findIndex(inq => inq._id === grpInqId && inq.assignedGroupId === grpInq_assignedGroupId && inq.direction === direction);
    }
    updateSummaryDataWithVersionlevelDataForGrid(inqCopy, origRowIndex) {
      this.addCustomColumnsToInq(inqCopy);
  
      // to Remove group from assigned to Should be moved to service
      this.rowData[origRowIndex].openUsers = this.processAssgnTo(inqCopy.openUsers);
      inqCopy.openUsers = this.processAssgnTo(inqCopy.openUsers);
  
      // update escalation
      if (inqCopy.workflows && inqCopy.workflows.length > 0) {
        inqCopy.workflows.forEach(wf => {
          let strEscalation: string = '';
  
          if (!this.isNull(wf.generalEscalationReason)) {
            inqCopy.isEscalated = "Y";
            strEscalation = wf.generalEscalationReason;
          }
          if (!this.isNull(wf.responseTimeEscalationReason)) {
            inqCopy.isEscalated = "Y";
            strEscalation += wf.responseTimeEscalationReason;
          }
          if (!this.isNull(wf.manualEscalationReason)) {
            inqCopy.isEscalated = "Y";
            strEscalation += wf.manualEscalationReason;
          }
          inqCopy.escalationReason = strEscalation;
  
  
        });
  
  
      }
      // update rowdata with ws inq
      this.rowData[origRowIndex] = inqCopy;
    }
    //replace attachment notes and important flag with icons.
    addDirectionToGrid(viewType, grpInq) {
      if (QmaConstant.typeEnum.GLOBALSEARCH.TYPE == viewType || QmaConstant.typeEnum.FOLDER.TYPE == viewType || QmaConstant.typeEnum.ESCALATION.TYPE == viewType) {
        // group level inquiry will always have single workflow so no need to
        // loop it
        if (this.isUndefinedOrNull(grpInq.direction)) {
          grpInq.direction = grpInq.workflows[0].direction;
        }
        if (this.inquiryDirectionEnum.IN === grpInq.direction) {
          grpInq.mailboxDirection = QmaConstant.typeEnum.INBOX.INBX;
  
          // If inquiry direction is IN check for inquiry status if RESOLVED it will go to RESOLVED BOX else INBOX.
          if (grpInq.status === QmaConstant.inquiryStatusEnum.RESOLVED) {
            grpInq.mailboxDirection = QmaConstant.typeEnum.RESOLVEDBOX.RELBX;
          }
          else {
            grpInq.mailboxDirection = QmaConstant.typeEnum.INBOX.INBX;
          }
        }
        else if (QmaConstant.typeEnum.INQUIRYDIRECTION.OUT === grpInq.direction) {
          grpInq.mailboxDirection = QmaConstant.typeEnum.OUTBOX.OUTBX;
        }
        else if (QmaConstant.typeEnum.INQUIRYDIRECTION.PENDINGAPPROVAL === grpInq.direction || QmaConstant.typeEnum.INQUIRYDIRECTION.PND_REAGE === grpInq.direction) {
          grpInq.mailboxDirection = QmaConstant.typeEnum.PENDINGAPPROVAL.PENDING;
        }
  
      }
    }
    calcAvgResolutionTime(rowData) {
      if (!this.isUndefinedOrNull(rowData) && !this.isUndefinedOrNull(rowData.totalResolveTimeQMA) && !this.isUndefinedOrNull(rowData.resolveCountQMA)) {
        // console.log('Total Resolution time ::'+rowData.totalResolveTimeQMA+' , resolutionCount ::'+rowData.resolveCountQMA);
        var avgResolutionTimeQMA = (rowData.resolveCountQMA == 0) ? 0 : rowData.totalResolveTimeQMA / rowData.resolveCountQMA;
        rowData.avgResolutionTimeQMA = this.convertSecsToDayHourMin(avgResolutionTimeQMA);
      }
    }
    calcResponeTime(rowData) {
      if (!this.isUndefinedOrNull(rowData) && !this.isUndefinedOrNull(rowData.responseTimeQMA) && !this.isUndefinedOrNull(rowData.replyCountQMA)) {
        // console.log('Resp time ::'+rowData.responseTimeQMA+' , ReplyCnt ::'+rowData.replyCountQMA);
        var avgResponseTimeQMA = rowData.responseTimeQMA / rowData.replyCountQMA;
        rowData.responseTimeQMA = this.convertSecsToDayHourMin(rowData.responseTimeQMA);
        rowData.avgResponseTimeQMA = this.convertSecsToDayHourMin(avgResponseTimeQMA);
      }
    }
  
  
    convertSecsToDayHourMin(seconds) {
      let timeDisplay = '';
      /* if (!this.isUndefinedOrNull(seconds)){
        let days = Math.floor(seconds / (3600*24));
        let hrs   = Math.floor((seconds / 3600)-(days*24)).toString();
        let mnts = Math.floor((seconds - (days*24*3600) -(hrs * 3600)) / 60).toString();
       // var secs = Math.floor(seconds - (days*24*3600) -(hrs * 3600) - (mnts * 60));
        if (hrs   < 10) {hrs   = "0"+hrs;}
        if (mnts < 10) {mnts = "0"+mnts;}
       // if (secs < 10) {secs = "0"+secs;}
       
        if(!let.isUndefinedOrNull(days) && days   < 10)
       {
          days   = "0"+days;
       }
        days=days+'d' +":";
        var HHMMSSDisplay=hrs+':'+mnts;//+':'+secs;
        timeDisplay=days+HHMMSSDisplay;
      } */
      // console.log('Timedisplay ::'+timeDisplay);
      return timeDisplay;
    }
  
    /**
     * This function calculates re-open age of an inquiry
     */
    calculateAndSetReOpenAge(rowData) {
      if (this.isUndefinedNullOrBlankStrAfterTrim(rowData.reOpenAge) && this.isUndefinedNullOrBlankStrAfterTrim(rowData.reOpenAgeInHrs)) {
        if (!this.isUndefinedOrNull(this.loginUserInfo.ageBasedCalculation) && this.loginUserInfo.ageBasedCalculation) {
          rowData.reOpenAge = '';
          rowData.reOpenAgeInHrs = '';
          if (this.isHolidayBasedAgeCalculationEnabled(rowData)) {
            this.calculateReOpenAgeBasedOnShiftAndHolidays(rowData);
          } else {
            this.calculateReOpenAgeOnNonShiftBasedConfiguration(rowData);
          }
        } else {
          this.calculateReOpenAgeOnNonShiftBasedConfiguration(rowData);
        }
      }
    }
  
    calculateReOpenAgeOnNonShiftBasedConfiguration(rowData) {
      var type = this.gridDetailsObject.viewType;
      rowData.reOpenAge = '';
      let isGroupLevelPreference = this.getUserPreference("groupLevelViewId");
      if (!this.isUndefinedOrNull(type)) {
        if (isGroupLevelPreference == 'Y' && !this.isUndefinedNullOrBlank(rowData.workflows) && !this.isUndefinedNullOrBlank(rowData.workflows[0])
          && !this.isUndefinedNullOrBlank(rowData.workflows[0].reOpenDate)) {
          var reOpenDate = new Date(rowData.workflows[0].reOpenDate.$date);
  
          if (this.isUndefinedOrNull(reOpenDate)) {
            reOpenDate = new Date(rowData.workflows[0].reOpenDate);
          }
  
          if (!this.isUndefinedOrNull(reOpenDate)) {
            let endDate = new Date();
            if (rowData.workflows[0].status == QmaConstant.inquiryStatusEnum.RESOLVED) {
              endDate = new Date(rowData.workflows[0].modDate.$date);
              if (this.isUndefinedOrNull(endDate)) {
                endDate = new Date(rowData.workflows[0].modDate);
              }
            }
            if (!this.isUndefinedOrNull(reOpenDate)) {
              rowData.reOpenDate = new Date(reOpenDate);
              var inquiryReopenAge = this.getInquiryAge(reOpenDate, endDate.getTime());
              rowData.reOpenAge = inquiryReopenAge;
            }
          }
        }
      }
    }
  
    calculateReOpenAgeBasedOnShiftAndHolidays(inquiryRow) {
      if (!this.isUndefinedOrNull(inquiryRow) && !this.isUndefinedOrNull(inquiryRow.workflows)
        && !this.isUndefinedOrNull(inquiryRow.workflows[0] && !this.isUndefinedOrNull(inquiryRow.workflows[0].assignedGroupId))) {
        const groupConfig = this.getAllHolidayAndShiftConfigForGroup(inquiryRow.workflows[0].assignedGroupId);
        if (!this.isUndefinedOrNull(groupConfig)) {
          this.initiateReOpenAgeCalcuation(groupConfig, inquiryRow);
        }
      }
    }
  
    initiateReOpenAgeCalcuation(groupConfig, inquiryRow) {
      var startDate = this.getDateForCalculation(inquiryRow, "startDate", groupConfig.timeZone, "reOpenAge");
      const reOpenDate = startDate;
      var endDate = this.getDateForCalculation(inquiryRow, "endDate", groupConfig.timeZone, "reOpenAge");
      if (!this.isUndefinedOrNull(startDate) && !this.isUndefinedOrNull(endDate)) {
        startDate = this.updateDateBasedOnHolidayConfig(startDate, endDate, groupConfig);
        const diff = this.findDateDifferenceInNoOfHoursAndDays(startDate, endDate, groupConfig);
        if (!this.isUndefinedOrNull(diff)) {
          if (!this.isUndefinedOrNull(diff[0])) {
            inquiryRow.reOpenAgeInHrs = diff[0];
          }
          if (!this.isUndefinedOrNull(diff[1])) {
            inquiryRow.reOpenAge = diff[1];
          }
        }
        if (!this.isUndefinedOrNull(reOpenDate)) {
          inquiryRow.reOpenDate = new Date(reOpenDate);
        }
      }
    }
  
    /**
     * This method calculates age of an inquiry
     */
    calculateAndSetAge(rowData) {
      rowData.age = 0;
      rowData.ageInHrs = 0;
      if (this.isHolidayBasedAgeCalculationEnabled(rowData)) {
        this.calculateAgeBasedOnShiftAndHolidays(rowData);
      } else {
        this.calculateAgeOnNonShiftBasedConfiguration(rowData)
      }
  
    }
  
    getUserPreference(preferenceId) {
      for (var index = 0; index < this.loginUserInfo.preferences.length; index++) {
        if (this.loginUserInfo.preferences[index].key == preferenceId) {
          return this.loginUserInfo.preferences[index].value;
        }
      }
      //keep Group level as default if there are no preferences attached.
      return this.loginUserInfo.groupLevelDefaultView.value;
    }
    /**
     * This function calculates inquiry age based on original way of age calculation
     */
    calculateAgeOnNonShiftBasedConfiguration(rowData) {
  
  
      if (!this.isUndefinedOrNull(rowData.crtDate)) {
        var type = this.gridDetailsObject.viewType;
        if (!this.isUndefinedOrNull(type)) {
          let isGroupLevelPreference = this.getUserPreference("groupLevelViewId");
          if (isGroupLevelPreference == 'Y' && !this.isUndefinedNullOrBlank(rowData.workflows) && !this.isUndefinedNullOrBlank(rowData.workflows[0])
            && !this.isUndefinedNullOrBlank(rowData.workflows[0].modDate) && !this.isUndefinedNullOrBlank(rowData.workflows[0].crtDate)) {
  
            var modDate = new Date(rowData.workflows[0].modDate.$date);
            var crtDate = new Date(rowData.workflows[0].crtDate.$date);
  
            if (this.isUndefinedOrNull(modDate)) {
              modDate = new Date(rowData.workflows[0].modDate);
            }
            if (this.isUndefinedOrNull(crtDate)) {
              crtDate = new Date(rowData.workflows[0].crtDate);
            }
  
            this.setAgeColumn(type, crtDate, modDate, rowData);
  
          } else {
            this.setAgeColumn(type, rowData.crtDate, rowData.modDate, rowData);
          }
        }
      }
    }
  
    // set value of age is age column based on create date and mod date .
    setAgeColumn(type, crtDate, modDate, rowData) {
      let currentTime = new Date();
      let inquiryAge = 0;
      /*[C153176-1307]:Re-age functionality*/
      if (!this.isUndefinedOrNull(rowData.workflows) && !this.isUndefinedNullOrBlank(rowData.workflows[0].reAgeDate)) {
        crtDate = new Date(rowData.workflows[0].reAgeDate.$date);
      }
      else if (!this.isUndefinedNullOrBlank(rowData.reAgeDate)) {
        crtDate = new Date(rowData.reAgeDate);
      }
      if (!this.isUndefinedOrNull(rowData.workflows) && rowData.workflows[0].status == 'Resolved') {
        if (!this.isUndefinedOrNull(crtDate) && !this.isUndefinedOrNull(modDate)) {
          inquiryAge = this.getInquiryAge(crtDate, modDate);
          rowData.age = inquiryAge;
        }
      }
      else {
        if (!this.isUndefinedOrNull(crtDate)) {
          inquiryAge = this.getInquiryAge(crtDate, currentTime.getTime());
          rowData.age = inquiryAge;
        }
      }
  
    }
    /**
     * This function calculates the age of of inquiry based on shift and holidays
     */
    calculateAgeBasedOnShiftAndHolidays(inquiryRow) {
      if (!this.isUndefinedOrNull(inquiryRow) && !this.isUndefinedOrNull(inquiryRow.workflows)
        && !this.isUndefinedOrNull(inquiryRow.workflows[0] && !this.isUndefinedOrNull(inquiryRow.workflows[0].assignedGroupId))) {
        const groupConfig = this.getAllHolidayAndShiftConfigForGroup(inquiryRow.workflows[0].assignedGroupId);
        if (!this.isUndefinedOrNull(groupConfig)) {
          this.initiateAgeCalcuation(groupConfig, inquiryRow);
        }
      }
    }
  
    /**
     * This function provide whole holiday and shift configuration for the requested group
     */
    getAllHolidayAndShiftConfigForGroup(groupId) {
      const groupConfig = this.getHolidayConfigForGroup(groupId);
      if (!this.isUndefinedOrNull(groupConfig)) {
        return groupConfig;
      }
    }
    initiateAgeCalcuation(groupConfig, inquiryRow) {
      var startDate = this.getDateForCalculation(inquiryRow, "startDate", groupConfig.timeZone, "age");
      var endDate = this.getDateForCalculation(inquiryRow, "endDate", groupConfig.timeZone, "age");
      if (!this.isUndefinedOrNull(startDate) && !this.isUndefinedOrNull(endDate)) {
        startDate = this.updateDateBasedOnHolidayConfig(startDate, endDate, groupConfig);
        const diff = this.findDateDifferenceInNoOfHoursAndDays(startDate, endDate, groupConfig);
        if (!this.isUndefinedOrNull(diff)) {
          if (!this.isUndefinedOrNull(diff[0])) {
            inquiryRow.ageInHrs = diff[0];
          }
          if (!this.isUndefinedOrNull(diff[1])) {
            inquiryRow.age = diff[1];
          }
        }
      }
    }
  
    /*This function finds difference between two dates (startDate-endDate) in no of hours falling between shift start and end time*/
    findDateDifferenceInNoOfHoursAndDays(startDate, endDate, groupConfig) {
      if (!this.isUndefinedOrNull(startDate) && !this.isUndefinedOrNull(endDate)
        && !this.isUndefinedOrNull(groupConfig.shiftStartTime) && !this.isUndefinedOrNull(groupConfig.shiftEndTime)) {
        const difference = this.findDateDifference(startDate, endDate, groupConfig);
        return difference;
      }
    }
    /* This function converts provided date to provided timeZone */
    convertDateToTargetTimeZone(inputDate, targetTimeZone) {
      let convertedDate;
      if (!this.isUndefinedOrNull(inputDate) && !this.isUndefinedOrNull(targetTimeZone)) {
        const convertedDateStr = new Date(inputDate).toLocaleString("en-US", { timeZone: targetTimeZone });
        convertedDate = new Date(convertedDateStr);
      }
      return convertedDate;
    }
    /*This function converts and provides any date according to given shift time HH:mm*/
    getShiftTimedDate(shift, date) {
      let newDate = new Date(date)
      if (this.isUndefinedOrNull(newDate)) {
        newDate = this.getTodaysDate();
      }
      if (!this.isUndefinedOrNull(shift)) {
        const values = shift.split(":");
        newDate.setHours(values[0], values[1], 0, 0);
      }
      return newDate;
    }
  
    /*This function returns today's date*/
    getTodaysDate() {
      const today = new Date();
      return today;
    }
    updateDateBasedOnHolidayConfig(startDate, endDate, groupConfig) {
      const dlEndTime = this.getShiftTimedDate(groupConfig.shiftEndTime, startDate);
      if (startDate.getTime() > dlEndTime.getTime()) {
        startDate = startDate.setDate(parseInt(startDate.getDate()) + 1);
        startDate = this.getShiftTimedDate(groupConfig.shiftStartTime, startDate);
      }
      while (startDate < endDate) {
        if (this.dateFallsOnHolidaysOrWeeklyOff(startDate, groupConfig)) {
          startDate = startDate.setDate(parseInt(startDate.getDate()) + 1);
          startDate = this.getShiftTimedDate(groupConfig.shiftStartTime, startDate);
        } else {
          break;
        }
      }
      return startDate;
    }
  
    dateFallsOnHolidaysOrWeeklyOff(inputate, groupConfig) {
      return this.dateFallsOnWeeklyOff(inputate, groupConfig) || this.dateFallsOnHolidays(inputate, groupConfig);
    }
    dateFallsOnHolidays(inputDate, groupConfig) {
      const dlHolidays = groupConfig.holidays;
      let flag = false;
      if (!this.isUndefinedOrNull(dlHolidays) && dlHolidays.length > 0) {
        $.each(dlHolidays, (index, currentHoliday) => {
          if (!this.isUndefinedOrNull(currentHoliday) && this.isSourceDateAndTargetDateAreSame(inputDate, new Date(currentHoliday.holidayDate))) {
            flag = true;
            return false;
          }
        });
      }
      return flag;
    }
    /*This function checks if sourceDate and targetDate are same date or not.(ignores time stamp)*/
    isSourceDateAndTargetDateAreSame(sourceDate, targetDate) {
      var firstDate = new Date(sourceDate);
      var secondDate = new Date(targetDate);
      if (!this.isUndefinedOrNull(firstDate) && !this.isUndefinedOrNull(secondDate)) {
        firstDate.setHours(0, 0, 0, 0);
        secondDate.setHours(0, 0, 0, 0);
        return firstDate.getTime() == secondDate.getTime();
      }
    }
    dateFallsOnWeeklyOff(inputDate, groupConfig) {
      const weeklyOffDays = groupConfig.weeklyOffDays;
      if (!this.isUndefinedOrNull(weeklyOffDays)) {
        const dayOfAWeek = "" + this.getDayOfAWeek(inputDate);
        return !this.isUndefinedOrNull(dayOfAWeek) && weeklyOffDays.includes(dayOfAWeek);
      }
      return false;
    }
    /*This function returns day of a week in numeric format*/
    getDayOfAWeek(date) {
      /*0-Sunday, 1-Monday, 2-Tuesday, 3-Wednesday, 4-Thursday, 5-Friday, 6-Saturday*/
      const d = new Date(date);
      const day = '' + d.getDay();
      return this.getIntValue(day);
    }
    /*This function returns integer value*/
    getIntValue(val) {
      return parseInt(val, 10);
    }
  
    findDateDifference(startDate, endDate, groupConfig) {
      let differenceInHours = 0;
      let differenceInDays = 0;
      let currentTime = new Date();
      currentTime = this.convertDateToTargetTimeZone(currentTime, groupConfig.timeZone);
      try {
        let shiftStartDate = new Date(startDate)
        const shiftStartTimeValue = groupConfig.shiftStartTime.split(":");
        shiftStartDate.setHours(shiftStartTimeValue[0], shiftStartTimeValue[1], 0, 0);
        const shiftEndDate = new Date(endDate)
        const shiftEndTimeValue = groupConfig.shiftEndTime.split(":");
        shiftEndDate.setHours(shiftEndTimeValue[0], shiftEndTimeValue[1], 0, 0);
  
        while (shiftStartDate <= shiftEndDate) {
          shiftStartDate = this.updateDateBasedOnHolidayConfig(shiftStartDate, shiftEndDate, groupConfig);
          if (startDate.getTime() > shiftStartDate.getTime() && differenceInHours == 0) {
            shiftStartDate.setHours(startDate.getHours(), startDate.getMinutes(), 0, 0);
          }
          if (startDate.getTime() < shiftStartDate.getTime() && currentTime.getTime() < shiftStartDate.getTime()) {
            break;
          }
          var sameDayEndShift = new Date(shiftStartDate)
          sameDayEndShift.setHours(shiftEndTimeValue[0], shiftEndTimeValue[1], 0, 0);
          if (sameDayEndShift.getTime() > endDate.getTime()) {
            sameDayEndShift = endDate;
            differenceInDays--;
          }
          differenceInHours += (sameDayEndShift.getTime() - shiftStartDate.getTime());
          shiftStartDate.setDate(shiftStartDate.getDate() + 1);
          shiftStartDate.setHours(shiftStartTimeValue[0], shiftStartTimeValue[1], 0, 0);
          differenceInDays++;
          if (shiftStartDate.getTime() > currentTime.getTime() || shiftStartDate.getTime() > endDate.getTime()) {
            break;
          }
        }
      } catch (e) {
        differenceInHours = 0;
        differenceInDays = 0;
      }
      var difference = [0, 0];
      if (!this.isUndefinedOrNull(differenceInHours) && differenceInHours > 0) {
        difference[0] = differenceInHours / this.oneHour;
      }
      if (!this.isUndefinedOrNull(differenceInDays)) {
        difference[1] = differenceInDays;
      }
      return difference;
  
    }
    getDateForCalculation(inquiryRow, type, groupTimeZone, calculationFor) {
      let date;
      try {
        const inquiryStatus = this.getInquiryStatus(inquiryRow);
        if (!this.isUndefinedOrNull(inquiryStatus) && type == "startDate") {
          date = this.getStartDateForCalculation(calculationFor, inquiryRow);
        } else if (type == "endDate") {
          date = this.getEndDateForCalculation(calculationFor, inquiryRow, inquiryStatus);
        }
        if (!this.isUndefinedOrNull(date)) {
          date = this.convertDateToTargetTimeZone(date, groupTimeZone);
        }
      } catch (e) {
        date = null;
      }
  
      return date;
    }
    getEndDateForCalculation(calculationFor, inquiryRow, inquiryStatus) {
      let date;
      if (!this.isUndefinedOrNull(calculationFor) && calculationFor == "age") {
        date = this.getEndDateForAgeCalculation(inquiryStatus, inquiryRow);
      } else if (!this.isUndefinedOrNull(calculationFor) && calculationFor == "reOpenAge") {
        date = this.getEndDateForReOpenAgeCalculation(inquiryStatus, inquiryRow);
      }
      return date;
    }
  
    getEndDateForReOpenAgeCalculation(inquiryStatus, inquiryRow) {
      let date;
      if (inquiryStatus == QmaConstant.inquiryStatusEnum.OPEN) {
        date = new Date();
      } else if (inquiryStatus == QmaConstant.inquiryStatusEnum.RESOLVED) {
        date = this.getModifiedDate(inquiryRow);
      }
      return date;
    }
    getModifiedDate(inquiryRow) {
      let date;
      if (!this.isUndefinedOrNull(inquiryRow.workflows[0].modDate)) {
        if (!this.isUndefinedOrNull(inquiryRow.workflows[0].modDate.$date)) {
          date = new Date(inquiryRow.workflows[0].modDate.$date);
        }
        if (this.isUndefinedOrNull(date)) {
          date = new Date(inquiryRow.workflows[0].modDate);
        }
      }
      return date;
    }
  
    getEndDateForAgeCalculation(inquiryStatus, inquiryRow) {
      let date;
      if (inquiryStatus == QmaConstant.inquiryStatusEnum.RESOLVED) {
        if (!this.isUndefinedOrNull(inquiryRow.workflows[0].modDate)) {
          if (!this.isUndefinedOrNull(inquiryRow.workflows[0].modDate.$date)) {
            date = new Date(inquiryRow.workflows[0].modDate.$date);
          }
          if (this.isUndefinedOrNull(date)) {
            date = new Date(inquiryRow.workflows[0].modDate);
          }
        }
      } else if (inquiryStatus == QmaConstant.inquiryStatusEnum.OPEN) {
        date = new Date();
      }
      return date;
    }
    getStartDateForCalculation(calculationFor, inquiryRow) {
      let date;
      if (!this.isUndefinedOrNull(calculationFor) && calculationFor == "age") {
        date = this.getStartDateForAgeCalculation(inquiryRow);
      } else if (!this.isUndefinedOrNull(calculationFor) && calculationFor == "reOpenAge") {
        date = this.getStartDateForReOpenAgeCalculation(inquiryRow);
      }
      return date;
    }
  
    getStartDateForReOpenAgeCalculation(inquiryRow) {
      let date;
      if (!this.isUndefinedOrNull(inquiryRow.workflows[0].reOpenDate)) {
        if (!this.isUndefinedOrNull(inquiryRow.workflows[0].reOpenDate.$date)) {
          date = new Date(inquiryRow.workflows[0].reOpenDate.$date);
        }
        if (this.isUndefinedOrNull(date)) {
          date = new Date(inquiryRow.workflows[0].reOpenDate);
        }
      }
      return date;
    }
    getStartDateForAgeCalculation(inquiryRow) {
      let date;
      if (!this.isUndefinedOrNull(inquiryRow.workflows[0].crtDate)) {
        if (!this.isUndefinedOrNull(inquiryRow.workflows[0].crtDate.$date)) {
          date = new Date(inquiryRow.workflows[0].crtDate.$date);
        }
        if (this.isUndefinedOrNull(date)) {
          date = new Date(inquiryRow.workflows[0].crtDate);
        }
      }
      if (!this.isUndefinedOrNull(inquiryRow.workflows[0].reAgeDate)) {
        if (!this.isUndefinedOrNull(inquiryRow.workflows[0].reAgeDate.$date)) {
          date = new Date(inquiryRow.workflows[0].reAgeDate.$date);
        }
        if (this.isUndefinedOrNull(date)) {
          date = new Date(inquiryRow.workflows[0].reAgeDate);
        }
        if (this.isUndefinedOrNull(date) && !this.isUndefinedOrNull(inquiryRow.reAgeDate)) {
          date = new Date(inquiryRow.reAgeDate);
        }
      }
      return date;
    }
    getInquiryStatus(inquiryRow) {
      if (!this.isUndefinedOrNull(inquiryRow) && !this.isUndefinedOrNull(inquiryRow.workflows[0])
        && !this.isUndefinedOrNull(inquiryRow.workflows[0].status)) {
        return inquiryRow.workflows[0].status;
      }
    }
    /**
     * This function identifies whether shift and holiday based age calculation is 
     * enabled for provided inquiry or not. 
     */
    isHolidayBasedAgeCalculationEnabled(inquiryRow) {
      let isHolidayBasedAgeCalculation = false;
      if (!this.isUndefinedOrNull(inquiryRow) && !this.isUndefinedOrNull(inquiryRow.workflows)
        && !this.isUndefinedOrNull(inquiryRow.workflows[0] && !this.isUndefinedOrNull(inquiryRow.workflows[0].assignedGroupId))) {
        const groupConfig = this.getHolidayConfigForGroup(inquiryRow.workflows[0].assignedGroupId);
        if (!this.isUndefinedOrNull(groupConfig) && !this.isUndefinedOrNull(groupConfig.holidayBasedAgeCalculation)) {
          isHolidayBasedAgeCalculation = groupConfig.holidayBasedAgeCalculation
        }
      }
      return isHolidayBasedAgeCalculation;
    }
  
    /**
     * This function provides group Configuration of holidays for provided groupId
     */
    getHolidayConfigForGroup(groupId) {
      let groupConfig = null;
      if (!this.isUndefinedOrNull(groupId) && !this.isUndefinedOrNull(this.loginUserInfo.myGroups)) {
        $.each(this.loginUserInfo.myGroups, (index, currentGrp) => {
          if (!this.isUndefinedOrNull(currentGrp.id) && currentGrp.id == groupId
            && !this.isUndefinedOrNull(currentGrp.holidayAndShiftDetails)) {
            groupConfig = currentGrp.holidayAndShiftDetails;
            if (!this.isUndefinedOrNull(groupConfig)) {
              groupConfig.country = currentGrp.country;
              groupConfig.timeZone = this.getGroupTimeZone(currentGrp.timeZone);
              groupConfig.holidays = this.getGroupCountryHolidays(currentGrp.country);
            }
            return false;
          }
        });
      }
      return groupConfig;
    }
    getGroupCountryHolidays(countryCode) {
      let countryHolidays = [];
      if (!this.isUndefinedOrNull(countryCode) && !this.isUndefinedOrNull(this.loginUserInfo.holidays)) {
        countryHolidays = this.loginUserInfo.holidays[countryCode];
      }
      return countryHolidays;
    }
    getGroupTimeZone(groupTimeZoneCode) {
      let grpTimeZone;
      if (!this.isUndefinedOrNull(groupTimeZoneCode) && !this.isUndefinedOrNull(this.loginUserInfo.timeZones)) {
        $.each(this.loginUserInfo.timeZones, (index, currentTZ) => {
          if (!this.isUndefinedOrNull(currentTZ.timeZoneCode) && currentTZ.timeZoneCode == groupTimeZoneCode
            && !this.isUndefinedOrNull(currentTZ.timeZone)) {
            grpTimeZone = currentTZ.timeZone;
            return false;
          }
        });
      }
      return grpTimeZone;
    }
    isInquiryMatchingCriteria(viewType, inq, criteria, gridData) {
      //Sumanth- This piece is added to filter OUTBOX Resolved Version from Search/Folder Views
      // Both Load View and Pub Sub Flows call this method
      /*var isValidInquiry=!util.isUndefinedOrNull(inq) && !util.isUndefinedOrNull(inq.workflows) && !util.isUndefinedOrNull(inq.workflows[0]);
      if(!util.isUndefinedOrNull(viewType) && (viewType == gVars.typeEnum.GLOBALSEARCH.TYPE || viewType == gVars.typeEnum.FOLDER.TYPE) &&  isValidInquiry &&
          'OUT'==inq.workflows[0].direction && 'Resolved' ==inq.workflows[0].status)
      {
        console.log("Websocket:: ~~@@@ OUTBOX Resolved Row-Filtered in for view type-"+viewType+" & inqId="+inq._id);
        return false;
      }*/
  
      var expr = [""];
      var op = [];
      //TODO:Performance :: Instead of paring criteria every time here, parse it once when grid gets loaded and save it in json format and use it here.
      this.convertToBooleanExpression(JSON.parse(criteria), op, expr, inq, viewType, gridData);
      var exprResult = eval(expr[0]);
      //console.log("Websocket:: expr : " + expr[0] + " ---> " + exprResult);
  
      return exprResult;
    }
  
    convertToBooleanExpression(criteria, op, expr, inq, viewType, gridData) {
      // Object.keys(value).forEach((keydata) => {
      /* for (let key in criteria) */
      Object.keys(criteria).forEach((key) => {
  
  
        let value = criteria[key];
  
        if (value instanceof Array) // [ case
        {
          if (key == '$and') op.push('&&');
          else if (key == '$or') op.push('||');
  
          expr[0] = expr[0] + ' ' + ' (';
          this.tokenizeArrayExpr(value, op, expr, inq, viewType, gridData); // Tokenize Array expression
          expr[0] = expr[0] + ' ' + ')';
  
          if (op.length > 0) {
            var operator = op[op.length - 1];
            op.pop(key);// remove the group operator on encounter of close bracket
            expr[0] = expr[0].replace(operator + ' ' + ')', ')'); // remove extra operator added while adding last operand
          }
        }
        else {
          ///[C153176-160] changes for saved search with work flow Criteria query
          expr[0] = expr[0] + ' ' + this.evalIndividualExpr(key, value, inq, op[op.length - 1], viewType, gridData) + ' ' + op[op.length - 1];
        }
      })
      //console.log("Websocket:: convertToBooleanExpression "+expr[0]);
    }
  
    evalIndividualExpr(key, value, inq, userOperator, viewType, gridData)///[C153176-160] changes for saved search with work flow Criteria query
    {
  
      var inqArray = [inq];
  
      //console.log(key+' ----> '+value);
      if (value instanceof Object)  // like regex, date etc, not simple comparison (i.e not just key, value
      {
        var key1 = Object.keys(value)[0];
        var value1 = value[key1];
        var stringValue = JSON.stringify(value1);
        if (this.isUndefinedOrNull(stringValue)) {
          stringValue = " ";
        }
        //console.log("key: " + key + ", value: " + value +", key1: " + key1 + ", value1: " + value1 +" , userOperator: "+ userOperator + ", viewType: " + viewType);
        //console.log(key1+' ----> '+value1);
  
  
        if (key1 == '$elemMatch')  // SPECIFIC EMBEDDED OBJECT FIELDS
        {
  
          // inner level filter
          inqArray = _.filter(inqArray, (inq) => {
            var wf = _.filter(inq.workflows, (workflow) => {
              //Required to show updates in Tags tab..
              //Code to evaluate workflow tags in the incoming inquiry.
              //If tags are present, basicCriteria is built based on them. Else, it follows the already present logic.
              var hasValidBasicCriteria = false;
              if (!this.isUndefinedOrNull(value1.tag) && stringValue.indexOf("-1000100") == -1) {
                hasValidBasicCriteria = workflow.tag == value1.tag
                  && value1.assignedGroupId === workflow.assignedGroupId;
              }
              else if (stringValue.indexOf("-1000100") == -1) {
                //C153176-368-Resolved Inquiry Cannot be found with OUT version
                if (!this.isUndefinedOrNull(viewType) && viewType == QmaConstant.inboxGridViewType.Outbox.viewType) {
                  //hasValidBasicCriteria=workflow.direction == value1.direction 
                  hasValidBasicCriteria = this.checkDirectionCriteria(workflow, value1)
                    && _.contains(value1.assignedGroupId.$in, workflow.assignedGroupId);
                }
                //This will be used in future when we will enable escalation for all the box types right now only enabling it for Inbox.
                //							else if(!util.isUndefinedOrNull(viewType) && viewType == gVars.typeEnum.ESCALATION.TYPE){
                //								hasValidBasicCriteria =  workflow.status == value1.status  								
                //								&& _.contains(value1.assignedGroupId.$in, workflow.assignedGroupId);
                //								if(hasValidBasicCriteria && value1 && value1.$or){
                //									hasValidBasicCriteria = checkEscalationCriteria(hasValidBasicCriteria, value1, value1.$or,workflow);
                //								}
                //							}
                else {
                  hasValidBasicCriteria = workflow.status == value1.status
                    //&& workflow.direction == value1.direction 
                    && this.checkDirectionCriteria(workflow, value1)
                    && _.contains(value1.assignedGroupId.$in, workflow.assignedGroupId);
                  if (hasValidBasicCriteria && value1 && value1.$or) {
                    hasValidBasicCriteria = this.checkEscalationCriteria(value1.$or, workflow);
                  }
                  if (hasValidBasicCriteria && value1 && value1.followUp) {
                    hasValidBasicCriteria = this.checkFollowUpCriteria(workflow);
                  }
                }
              }
  
              //Rules only apply for Inbox Types of View. It doent apply on Resolvedbox , Outbox and Peding Approval Box. 
              if (workflow.status == 'Open' && workflow.direction == 'IN' && this.isUndefinedOrNull(value1.tag) && stringValue.indexOf("-1000100") == -1) {
                if (value1.snoozeAction && value1.snoozeAction.$exists === false) {
                  if (workflow.snoozeAction && workflow.snoozeAction === "Snooze") {
                    hasValidBasicCriteria = false;
                  }
                }
                else if (value1.snoozeAction) {
                  if (workflow.snoozeAction && workflow.snoozeAction === "Snooze") {
                    hasValidBasicCriteria = true;
                  } else {
                    hasValidBasicCriteria = false;
                  }
                }
                //the changes to incorporate rules requirement
                var hasValidRulesCriteria = false;
  
                if (value1.rulesFlag) //Case - Views : Inbox Types
                {
                  if (value1.rulesFlag.$exists == false) {
                    hasValidRulesCriteria = this.isUndefinedOrNull(workflow.rulesFlag);
                  }
                }
                else if (value1["rulesFlag.markAsNonInquiry"] && value1["rulesFlag.markAsNonInquiry"] == true) //Case - Viwe : Non Inquiry Inbox
                {
                  if (workflow.rulesFlag && workflow.rulesFlag.markAsNonInquiry) {
                    hasValidRulesCriteria = workflow.rulesFlag.markAsNonInquiry;
                  }
                }
                else if (value1["rulesFlag.markForDeletion"] && value1["rulesFlag.markForDeletion"] == true) //Case - Viwe : Delete Inquiry Inbox
                {
                  if (workflow.rulesFlag && workflow.rulesFlag.markForDeletion) {
                    hasValidRulesCriteria = workflow.rulesFlag.markForDeletion;
                  }
                }
                //end - rules requirement
  
                return hasValidBasicCriteria && hasValidRulesCriteria;//return combined evaluation
              } else if (stringValue.indexOf("-1000100") > -1) {
                hasValidBasicCriteria = this.checkUserViewctr(workflow, value1, userOperator, viewType)
              }
  
              return hasValidBasicCriteria;
            });
            return wf.length > 0;
          });
        }
        // Any column filter of type Date needs to be added here inorder to be matched and shown in the UI
        else if (key == 'crtDate' || key == 'modDate' || key == 'lastActionTime' || key == 'resolveTime' || key == 'reAgeDate' || key == 'ackEscalationTime')  // DATES
        {
          inqArray = _.filter(inqArray, function (obj) {
            return this.compareDates(value1, obj[key], key1)
          });
  
          var length = Object.keys(value).length;
  
          if (length > 1) //BETWEEN OPERATOR
          {
            var key2 = Object.keys(value)[1];
            var value2 = value[key2];
            inqArray = _.filter(inqArray, function (obj) {
              return this.compareDates(value2, obj[key], key2)
            });
          }
        }
        else if (key1 == '$regex')  // STRINGS
        {
          inqArray = _.filter(inqArray, function (obj) {
            if (obj[key]) {
              //below line is commented as just matching is not case insensitive
              //return obj[key].match(value1);
  
              //ignore case matching is handled by converting to lower case and matching 
              var inqValue = obj[key].toLowerCase();
              var crtValue = value1.toLowerCase();;
              return inqValue.match(crtValue);
            }
          });
        }
        else if (key1 == '$regularExpression')  // STRINGS
        {
          inqArray = _.filter(inqArray, function (obj) {
            if (obj[key]) {
              //below line is commented as just matching is not case insensitive
              //return obj[key].match(value1);
  
              //ignore case matching is handled by converting to lower case and matching 
              var inqValue = obj[key].toLowerCase();
              var crtValue = value1.pattern.toLowerCase();
              return inqValue.match(crtValue);
            }
          });
        }
        else if (key1 == '$ne')  // STRINGS
        {
          inqArray = _.filter(inqArray, (obj) => {
            return obj[key] != value1;
          });
        }
        else if (key1 == '$nin')  // STRINGS
        {
          return !(_.contains(value1, inq.readyBy));
        }
        else if (key1 == '$exists')  // STRINGS
        {
          inqArray = _.filter(inqArray, (obj) => {
            var exists;
  
            if (key == 'workflows.assignedUserId') {
              var userId = obj['workflows'][0].assignedUserId;
  
              exists = !this.isUndefinedOrNull(userId);
            }
            else {
              exists = !this.isUndefinedOrNull(obj[key]);
            }
  
            return exists === value1;
          });
        }
        /*else if (key == '_id' && key1 == '$in')  // SOLR Search
        {
          return value1.indexOf(inq._id) > -1;
        }*/
        else if (key == 'workflows.assignedGroupId' && key1 == '$in')  // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, (inq) => {
            var wf = _.filter(inq.workflows, (workflow) => {
              return value1.indexOf(workflow.assignedGroupId) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.requestType' && key1 == '$in')  // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, (inq) => {
            var wf = _.filter(inq.workflows, (workflow) => {
              return value1.indexOf(workflow.requestType) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.tag' && key1 == '$in')  // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, (inq) => {
            var wf = _.filter(inq.workflows, (workflow) => {
              return value1.indexOf(workflow.tag) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.status' && key1 == '$in')  // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, (inq) => {
            var wf = _.filter(inq.workflows, (workflow) => {
              return value1.indexOf(workflow.status) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.assignedUserId' && key1 == '$in')  // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return value1.indexOf(workflow.assignedUserId) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.resolver' && key1 == '$in')  // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return value1.indexOf(workflow.resolver) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.inquirySource' && key1 == '$in')  // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return value1.indexOf(workflow.inquirySource) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.rootCause' && key1 == '$in')  // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return value1.indexOf(workflow.rootCause) > -1;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.processingRegion' && key1 == '$in')  // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return value1.indexOf(workflow.processingRegion) > -1;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.attchFlag' && key1 == '$eq') // Home SOLR Search 
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return workflow.attchFlag == value1;
            });
            return wf.length > 0;
          });
        }
        else  // INTEGERS
        {
          inqArray = _.filter(inqArray, (obj) => {
            return this.compareNumbers(value1, obj[key], key1);
          });
        }
      }
      else { // just key , value
  
        if (key == 'userFolders.folderName')  // inner level filter
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.userFolders, function (userFolder) {
              return userFolder.folderName == value;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.assignedUserId')  // inner level filter
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return workflow.assignedUserId == value;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.queryCount')  // inner level filter
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return workflow.queryCount == value;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.status')  // inner level filter
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return workflow.status == value;
            });
            return wf.length > 0;
          });
        } else if (key == 'workflows.convCount')  // inner level filter
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return workflow.convCount == value;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'urgentFlag')  // inner level filter
        {
          return "Y" == value;
        }
        else if (key == 'attchFlag')  // inner level filter
        {
          return "Y" == value;
        }
        else if (key == 'suggestionIndicator')  // inner level filter
        {
          return "O" == value || "G" == value || "R" == value || "B" == value;
        }
        else if (key == 'workflows.clientCategory.categoryName')  // inner level filter
        {
          if (this.wsAlreadyUpdatedData(inqArray, gridData)) {
            return false;
          }
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return workflow.clientCategory.categoryName == value;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.customClientCategory.categoryName')  // inner level filter
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              let customClientCategoryList = workflow.customClientCategory;
              let cc = _.filter(customClientCategoryList, function (customClientCategory) {
                return customClientCategory.categoryName == value;
              });
              return cc.length > 0;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.direction') // inner level filter
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return workflow.direction == value;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.requestType') // inner level filter
        {
          inqArray = _.filter(inqArray, function (inq) {
            var wf = _.filter(inq.workflows, function (workflow) {
              return workflow.requestType == value;
            });
            return wf.length > 0;
          });
        }
        else if (key == 'workflows.ageInDays') // inner level filter
        {
          inqArray = _.filter(inqArray, function (inq) {
            if (value == "0-1 day") {
              return inq.age >= 0 && inq.age <= 1;
            } else if (value == "2 days") {
              return inq.age = 2;
            } else if (value == "3-5 days") {
              return inq.age >= 3 && inq.age <= 5;
            } else if (value == "6-10 days") {
              return inq.age >= 6 && inq.age <= 10;
            } else if (value == "11-30 days") {
              return inq.age >= 11 && inq.age <= 30;
            } else if (value == "30+ days") {
              return inq.age > 30;
            }
          });
        }
        else {
          inqArray = _.filter(inqArray, function (obj) {
            return obj[key] == value;
          });
        }
  
      }
  
      return inqArray.length > 0;
    }
    compareNumbers(num1, num2, key1) {
  
      //console.log('Websocket::Numbers ---> ' + num1 + ' ' + key1 + ' ' + num2);
  
      if (key1 == '$gt') return num2 > num1;
      else if (key1 == '$gte') return num2 >= num1;
      else if (key1 == '$lt') return num2 < num1;
      else if (key1 == '$lte') return num2 <= num1;
      else if (key1 == '$eq') return num2 == num1;
      else if (key1 == '$ne') return num2 != num1;
    }
    compareDates(date1, date2, key1) {
  
      //console.log('Websocket::Dates Before ---> ' + date1 + ' ' + key1 + ' ' + date2);
  
      date1 = date1.split('/')
      date1 = new Date(date1[0], date1[1], date1[2]); // date1 format  - "yyyy/mm/dd"
  
      date2 = new Date(date2); // date2 format - long number
      date2 = new Date(date2.getUTCFullYear(), date2.getUTCMonth() + 1, date2.getUTCDate());
  
      //console.log('Websocket::Dates After ---> ' + date1 + ' ' + key1 + ' ' + date2);
  
      if (key1 == '$gt') return date2 > date1;
      else if (key1 == '$lt') return date2 < date1;
      else if (key1 == '$gte') return +date2 >= +date1;
      else if (key1 == '$lte') return +date2 <= +date1;
      else if (key1 == '$eq') return +date2 == +date1;
      else if (key1 == '$ne') return +date2 != +date1;
    }
    ///[C153176-160] changes for saved search with work flow items start validate workflow criteria
    checkUserViewctr(workflow, ctrValue, userOperator, viewType) {
  
      var isUserCtrSucess = false;
      var booleanArray = [];
      if (!this.isUndefinedOrNull(workflow) && !this.isUndefinedOrNull(ctrValue)) {
        if (!this.isUndefinedOrNull(ctrValue.assignedUserName)) {///[C153176-436]Case Insensitive is not working for Begins with and Contains condition for Request Type & Tag
          this.compareWorkflowStringField(workflow.assignedUserName, ctrValue.assignedUserName, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.requestType)) {
          this.compareWorkflowStringField(workflow.requestType, ctrValue.requestType, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.resolver)) {
          this.compareWorkflowStringField(workflow.resolver, ctrValue.resolver, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.assignedGroupName)) {
          this.compareWorkflowStringField(workflow.assignedGroupName, ctrValue.assignedGroupName, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.tag)) {
          this.compareWorkflowStringField(workflow.tag, ctrValue.tag, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.linkId)) {
          this.compareWorkflowIntegerField(workflow.linkId, ctrValue.linkId, booleanArray)
  
        }
        if (!this.isUndefinedOrNull(ctrValue.queryCount)) {
          this.compareWorkflowIntegerField(workflow.queryCount, ctrValue.queryCount, booleanArray);
  
        }
        if (!this.isUndefinedOrNull(ctrValue.rootCause)) {
          this.compareWorkflowStringField(workflow.rootCause, ctrValue.rootCause, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.processingRegion)) {
          this.compareWorkflowStringField(workflow.processingRegion, ctrValue.processingRegion, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.inquirySource)) {
          this.compareWorkflowStringField(workflow.inquirySource, ctrValue.inquirySource, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.crtDate))//[C153176-160] Saved Search filter criteria is not working for all columns
        {
          this.compareUserViewDate(workflow.crtDate, ctrValue.crtDate, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.modDate)) {
          this.compareUserViewDate(workflow.modDate, ctrValue.modDate, booleanArray)
        }
        if (!this.isUndefinedOrNull(ctrValue.age$gt) || !this.isUndefinedOrNull(ctrValue.age$lt) || !this.isUndefinedOrNull(ctrValue.age$eq)) {
          this.compareUserViewageCalculation(workflow, ctrValue, booleanArray, viewType)
        }
        if (!this.isUndefinedOrNull(ctrValue.isSubjectEscalation)) {
          this.compareWorkflowStringField(workflow.isSubjectEscalation, ctrValue.isSubjectEscalation, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.isConvCountEscalation)) {
          this.compareWorkflowStringField(workflow.isConvCountEscalation, ctrValue.isConvCountEscalation, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.isClientChaseEscalation)) {
          this.compareWorkflowStringField(workflow.isClientChaseEscalation, ctrValue.isClientChaseEscalation, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.responseTimeEscalationFlag)) {
          this.compareWorkflowStringField(workflow.responseTimeEscalationFlag, ctrValue.responseTimeEscalationFlag, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.isManualEscalation)) {
          this.compareWorkflowStringField(workflow.isManualEscalation, ctrValue.isManualEscalation, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.manualEscalationReason)) {
          this.compareWorkflowStringField(workflow.manualEscalationReason, ctrValue.manualEscalationReason, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.lastActionTime)) {
          this.compareUserViewDate(workflow.lastActionTime, ctrValue.lastActionTime, booleanArray)
        }
        if (!this.isUndefinedOrNull(ctrValue.resolveTime)) {
          this.compareUserViewDate(workflow.resolveTime, ctrValue.resolveTime, booleanArray)
        }
        if (!this.isUndefinedOrNull(ctrValue.reAgeDate)) {
          this.compareUserViewDate(workflow.reAgeDate, ctrValue.reAgeDate, booleanArray)
        }
        if (!this.isUndefinedOrNull(ctrValue.reOpenDate)) {
          this.compareUserViewDate(workflow.reOpenDate, ctrValue.reOpenDate, booleanArray)
        }
        if (!this.isUndefinedOrNull(ctrValue.workflowStatus)) {
          this.compareWorkflowStringField(workflow.workflowStatus, ctrValue.workflowStatus, booleanArray);
        }
        if (!this.isUndefinedOrNull(ctrValue.suggestionIndicator)) {
          this.compareWorkflowStringField(workflow.suggestionIndicator, ctrValue.suggestionIndicator, booleanArray);
        }
        for (var idx = 0; idx < booleanArray.length; idx++) {
          if (idx == 0) {
            isUserCtrSucess = booleanArray[idx];
          }
          else {
            if (userOperator == '&&') {
              isUserCtrSucess = isUserCtrSucess && booleanArray[idx];
            }
            else {
              isUserCtrSucess = isUserCtrSucess || booleanArray[idx];
            }
          }
        }
      }
  
      return isUserCtrSucess;
    }
  
    compareUserViewDate(workflowDtVal, ctrDtVal, booleanArray) {
      if (!this.isUndefinedOrNull(workflowDtVal) && !this.isUndefinedOrNull(ctrDtVal)) {
        if (!this.isUndefinedOrNull(ctrDtVal.$lt)) {
          booleanArray.push(workflowDtVal.$date < ctrDtVal.$lt.$date);
        }
        if (!this.isUndefinedOrNull(ctrDtVal.$gt)) {
          booleanArray.push(workflowDtVal.$date > ctrDtVal.$gt.$date);
        }
  
        if (!this.isUndefinedOrNull(ctrDtVal.$lte)) {
          booleanArray.push(workflowDtVal.$date <= ctrDtVal.$lte.$date);
        }
        if (!this.isUndefinedOrNull(ctrDtVal.$gte)) {
          booleanArray.push(workflowDtVal.$date >= ctrDtVal.$gte.$date);
        }
      }
  
    }
    //ctrValue is criteria value
    compareUserViewageCalculation(workflow, ctrValue, booleanArray, viewType) {
      var modDate = new Date();
      var crtDate = new Date(workflow.crtDate.$date);
      if (QmaConstant.inboxGridViewType.Outbox.viewType == viewType && workflow.status == 'Resolved') {
        modDate = new Date(workflow.modDate.$date);
      }
      if (!this.isUndefinedOrNull(ctrValue.age$gt)) {
        booleanArray.push(this.getInquiryAge(crtDate, modDate) > ctrValue.age$gt.$nin[1]);
      }
      if (!this.isUndefinedOrNull(ctrValue.age$lt)) {
        booleanArray.push(this.getInquiryAge(crtDate, modDate) < ctrValue.age$lt.$nin[1]);
      }
      if (!this.isUndefinedOrNull(ctrValue.age$eq)) {
        booleanArray.push(this.getInquiryAge(crtDate, modDate) == ctrValue.age$eq.$nin[1]);
      }
  
    }
    getInquiryAge(crtDate, currentDateLong) {
      let diffDays = Math.floor(Math.abs((currentDateLong - crtDate) / (this.oneDay)));
      //console.log('Inquiry age : '+diffDays);
      return diffDays;
    }
    compareWorkflowIntegerField(workflowVal, ctrVal, booleanArray) {
      if (!this.isUndefinedOrNull(workflowVal)) {
        if (!this.isUndefinedOrNull(ctrVal.$gt)) {
          booleanArray.push(workflowVal > ctrVal.$gt);
        }
        if (!this.isUndefinedOrNull(ctrVal.$lt)) {
          booleanArray.push(workflowVal < ctrVal.$lt);
        }
        if (!this.isUndefinedOrNull(ctrVal.$eq)) {
          booleanArray.push(workflowVal == ctrVal.$eq);
        }
      }
      else {
        booleanArray.push(false);
      }
  
    }
  
    compareWorkflowStringField(workflowVal, ctrVal, booleanArray) {
      // not equal case
      if (!this.isUndefinedOrNull(ctrVal.$ne)) {
        if (!this.isUndefinedOrNull(workflowVal)) {
          let regx = new RegExp(ctrVal.$ne.$regex, ctrVal.$ne.$options);
          let match = workflowVal.match(regx);
          booleanArray.push(this.isUndefinedOrNull(match) || match.length == 0);
        }
        else {
          booleanArray.push(true);
        }
      }
      else {
        if (!this.isUndefinedOrNull(workflowVal)) {
          var regx = new RegExp(ctrVal.$regex, ctrVal.$options);
          var match = workflowVal.match(regx);
          booleanArray.push(!this.isUndefinedOrNull(match) && match.length > 0);
        }
        else {
          booleanArray.push(false);
        }
      }
    }
    checkEscalationCriteria(escalationOrCondition, workflow) {
      var esc1 = escalationOrCondition[0];
      var esc2 = escalationOrCondition[1];
      var esc3 = escalationOrCondition[2];
      var esc4 = escalationOrCondition[3];
      var esc5 = escalationOrCondition[5];
      return (esc1.isConvCountEscalation === workflow.isConvCountEscalation
        || esc2.isClientChaseEscalation === workflow.isClientChaseEscalation || esc3.isSubjectEscalation === workflow.isSubjectEscalation
        || esc4.responseTimeEscalationFlag === workflow.responseTimeEscalationFlag
        || esc5.isManualEscalation === workflow.isManualEscalation);
    }
    checkFollowUpCriteria(workflow) {
      var criteriaMatched = false;
      if (workflow.followUp && "Generic" === workflow.followUp.flag) {
        criteriaMatched = true;
      }
      return criteriaMatched;
    }
    /*
     * Match workflow direction 
     * If the view direction has array then use $in to match or match directly
     * 
     */
    checkDirectionCriteria(workflow, value) {
      var criteriaMatched = false;
      if (!value.direction) {
        criteriaMatched = true;
      }
      else if (workflow.direction && value.direction && $.isArray(value.direction.$in)) {
        criteriaMatched = _.contains(value.direction.$in, workflow.direction)
      }
      else {
        criteriaMatched = workflow.direction == value.direction
  
      }
  
      return criteriaMatched;
    }
    tokenizeArrayExpr(objects, op, expr, inq, viewType, gridData) {
  
      Object.keys(objects).forEach((key) => {
        let value = objects[key];
        if (value instanceof Array) {
  
          if (key == '$and') op.push('&&');
          else if (key == '$or') op.push('||');
  
          expr[0] = expr[0] + ' ' + ' (';
          this.tokenizeArrayExpr(value, op, expr, inq, viewType, gridData); // in QMA1.0 viewType not passed here.
          expr[0] = expr[0] + ' ' + ')';
          if (op.length > 0) {
            let operator = op[op.length - 1];
            op.pop(key);// remove the group operator on encounter of close bracket
            expr[0] = expr[0].replace(operator + ' ' + ')', ')'); // remove extra operator added while adding last operand
          }
        }
        else if (value instanceof Object) {
          this.convertToBooleanExpression(value, op, expr, inq, viewType, gridData)
        }
      });
    }
    // This will return true if passed variable is null/undefined false
    // otherwise.
    isUndefinedOrNull(val: any) {
      return val === undefined || val === null ? true : false;
    }
    printInquiryIdsReceived(inqIdArray) {
      if (!this.isUndefinedOrNull(inqIdArray) && inqIdArray.length > 0) {
        //var inquiryListReceived = "";
        // filter user group related inquiry as ,now we get bulk data .
        for (var inqIndex = 0; inqIndex < inqIdArray.length; inqIndex++) {
          var inq = inqIdArray[inqIndex];
          inq.id = inq._id;
          //inquiryListReceived = inquiryListReceived + inq._id + ", "
        }
      }
  
    }
    getWorkFlowsForLoginUser(inqWorkFlowAll) {
      var userLevelWorkFlowForInq = [];
      if (!this.isUndefinedOrNull(inqWorkFlowAll)) {
        for (var idx = 0; idx < inqWorkFlowAll.length; idx++) {
          if (this.userGroupIdList.indexOf(inqWorkFlowAll[idx].assignedGroupId) > -1) {
            userLevelWorkFlowForInq.push(inqWorkFlowAll[idx])
          }
        }
      }
      return userLevelWorkFlowForInq;
    }
    getUserGroupIds() {
      var userGroupId = [];
      if (!this.isUndefinedOrNull(this.loginUserInfo) && !this.isUndefinedOrNull(this.loginUserInfo.myGroups)) {
        this.loginUserInfo.myGroups.forEach(function (fromData) {
          userGroupId.push(fromData.id);
        });
      }
      return userGroupId;
    }
  
    updateSummaryDataWithVersionlevelData(inqCopy) {
      let wfVersionCopy = inqCopy.workflows[0]
      inqCopy.status = wfVersionCopy.status;
      inqCopy.openUsers = wfVersionCopy.assignedUserName;
      if (!this.isUndefinedOrNull(wfVersionCopy.crtDate.$date)) {
        inqCopy.crtDate = new Date(wfVersionCopy.crtDate.$date);
      }
      if (!this.isUndefinedOrNull(wfVersionCopy.modDate.$date)) {
        // QMA2.0 specific change
        inqCopy.modDate.$date = new Date(wfVersionCopy.modDate.$date).toISOString();
      }
      if (inqCopy.openUsers) {
        inqCopy.openUsers = inqCopy.openUsers.substr(0, inqCopy.openUsers.indexOf("["));
      }
      inqCopy.requestTypeStr = wfVersionCopy.requestType;
      inqCopy.linkId = wfVersionCopy.linkId;
      inqCopy.openGroups = this.getGrpNameFromId(wfVersionCopy.assignedGroupId);
  
      // If the version action present then we need to show version action
      // If not ,show blank at group Level	
      inqCopy.action = wfVersionCopy.action;
      inqCopy.tag = wfVersionCopy.tag;
      if (!this.isUndefinedOrNull(wfVersionCopy.lastActionTime)) {
        inqCopy.lastActionTime = new Date(wfVersionCopy.lastActionTime.$date);;
      }
      inqCopy.lastActionby = wfVersionCopy.lastActionby;
      inqCopy.convCount = wfVersionCopy.convCount;
      inqCopy.resolver = wfVersionCopy.resolver;
      inqCopy.rootCause = wfVersionCopy.rootCause;
      //[C153176-793] Add Processing Region as a drop-down in Group Admin screen
      inqCopy.processingRegion = wfVersionCopy.processingRegion;
      inqCopy.queryCount = wfVersionCopy.queryCount;
      if (!this.isUndefinedOrNull(wfVersionCopy.resolveTime)) {
        inqCopy.resolveTime = new Date(wfVersionCopy.resolveTime.$date);
      }
  
      inqCopy.responseTimeQMA = wfVersionCopy.responseTimeQMA;
      inqCopy.avgResponseTimeQMA = wfVersionCopy.avgResponseTimeQMA;
      inqCopy.replyCountQMA = wfVersionCopy.replyCountQMA;
      inqCopy.inquirySource = wfVersionCopy.inquirySource; //[C153176-854] - Add new drop-down: Inquiry Source
      inqCopy.totalResolveTimeQMA = wfVersionCopy.totalResolveTimeQMA;
      inqCopy.resolveCountQMA = wfVersionCopy.resolveCountQMA;
      inqCopy.clientChaseCounter = wfVersionCopy.clientChaseCounter;
  
      inqCopy.lock = wfVersionCopy.lock;
      inqCopy.lockedBy = wfVersionCopy.lockedBy;
      inqCopy.lockedDate = wfVersionCopy.lockedDate;
      //[C153176-1014] - Add Escalation functionality
      inqCopy.isConvCountEscalation = wfVersionCopy.isConvCountEscalation;
      inqCopy.isClientChaseEscalation = wfVersionCopy.isClientChaseEscalation;
      inqCopy.isSubjectEscalation = wfVersionCopy.isSubjectEscalation;
      inqCopy.generalEscalationReason = wfVersionCopy.generalEscalationReason;
      inqCopy.responseTimeEscalationFlag = wfVersionCopy.responseTimeEscalationFlag;
      inqCopy.ispendingApprovalEscalation = wfVersionCopy.ispendingApprovalEscalation;
      inqCopy.responseTimeEscalationReason = wfVersionCopy.responseTimeEscalationReason;
      inqCopy.isManualEscalation = wfVersionCopy.isManualEscalation;
      inqCopy.manualEscalationReason = wfVersionCopy.manualEscalationReason;
      inqCopy.direction = wfVersionCopy.direction;
      inqCopy.followUp = wfVersionCopy.followUp;
      if (wfVersionCopy.followUp && wfVersionCopy.followUp.flag) {
        inqCopy.followUp = "Y";
      }
      inqCopy.workflowStatus = wfVersionCopy.workflowStatus;
      if (!this.isUndefinedOrNull(wfVersionCopy.reAgeDate)) {
        inqCopy.reAgeDate = new Date(wfVersionCopy.reAgeDate.$date);
      }
      inqCopy.suggestionIndicator = wfVersionCopy.suggestionIndicator;
      //[C153176-1694] - Add Acknowledge Escalation Functionality
      inqCopy.isAckEscalation = wfVersionCopy.isAckEscalation;
      inqCopy.ackEscalationBy = wfVersionCopy.ackEscalationBy;
      if (!this.isUndefinedOrNull(wfVersionCopy.ackEscalationTime)) {
        inqCopy.ackEscalationTime = new Date(wfVersionCopy.ackEscalationTime.$date);
      }
  
      inqCopy.escalationReason = this.calcEsclationreasonWsRedesign(wfVersionCopy);
      this.setClientCategoryForWorkflows(inqCopy);
      // fix for C153176-5406 Standardize external email "From" so it always shows client name first
      let updatedDisplayName = this.formatDisplayName(inqCopy.latestUserName, inqCopy.latestEmail);
      inqCopy.latestUserName = updatedDisplayName ? updatedDisplayName : inqCopy.latestUserName;
  
    }
    // update rowdata to refesh GRID
  
    getGrpNameFromId(grpId) {
      var addGrpToFrom = '';
      if (!this.isUndefinedNullOrBlank(grpId)) {
        for (let reqTypeIdx = 0; reqTypeIdx < this.loginUserInfo.myGroups.length; reqTypeIdx++) {
          let fromData = this.loginUserInfo.myGroups[reqTypeIdx];
          if (!this.isUndefinedNullOrBlank(fromData) && !this.isUndefinedNullOrBlank(fromData.groupName) && fromData.id == grpId) {
            addGrpToFrom = fromData.groupName;
            break;
          }
        }
      }
  
      return addGrpToFrom;
    }
  
    // This method will accepts string and returns true if string is
    // null/undefined/empty false
    // otherwise.
    isUndefinedNullOrBlank(val) {
      return val === undefined || val === null || val === '';
    }
  
    calcEsclationreasonWsRedesign(rowData) {
      var escReason = "";
      if (!this.isUndefinedOrNull(rowData)) {
  
        if (!this.isUndefinedNullOrBlankStrAfterTrim(rowData.generalEscalationReason)) {
          escReason = rowData.generalEscalationReason + " ";
        }
        if (!this.isUndefinedNullOrBlankStrAfterTrim(rowData.responseTimeEscalationReason)) {
          escReason = escReason + rowData.responseTimeEscalationReason + " ";
        }
        if (!this.isUndefinedNullOrBlankStrAfterTrim(rowData.manualEscalationReason)) {
          escReason = escReason + rowData.manualEscalationReason;
        }
  
      }
      return escReason;
    }
    isUndefinedNullOrBlankStrAfterTrim(val) {
      return val === undefined || val === null || ('' + val).trim() === '' ? true : false;
    }
    updateAssignedTo(inqToInsert: any) {
      let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView) ?
        this.wfDirectionMailboxViewMap.get(this.strMailboxView) : 'IN';
      if (matchingWfExists) {
        let matchingwf = inqToInsert.workflows.find(x => x.direction == matchingWfExists && x.assignedGroupName == inqToInsert.openGroups);
        if (matchingwf && matchingwf.assignedUserName) {
          return matchingwf.assignedUserName.split("[")[0];
        }
      } else if (this.isDirectionAgnosticView()) { // C153176-4447: refactoring
        // myviews or dashboard inline view or chart view or search result - C153176-4447
        let matchingwf = inqToInsert.workflows.find(x => x.assignedGroupName == inqToInsert.openGroups);
        if (matchingwf && matchingwf.assignedUserName) {
          return matchingwf.assignedUserName.split("[")[0];
        } else {
          return "";
        }
      }
    }
  
    removeInqFromRowData(nodeToDelete: any) {
      nodeToDelete.forEach(deletedInquiry => {
        // remove from rowdata  
        let inquiryIndextoDelete = this.rowData.findIndex(irowData => irowData._id === deletedInquiry._id &&
          irowData.openGroups === deletedInquiry.openGroups);
        this.rowData.splice(inquiryIndextoDelete, 1);
      });
    }
  
    // Fix for jira C153176-4921 remove from grid
    removeInqFromAgGrid(nodeToDelete: any, isInqForwarded: boolean = false) {
      if (nodeToDelete.length > 0) {
        const selectedGridRow = this.gridApi.getSelectedRows();
        let res = this.gridApi.updateRowData({ remove: nodeToDelete });
        nodeToDelete.forEach(deletedInquiry => {
          // remove from rowdata  
          let inquiryIndextoDelete = this.rowData.findIndex(irowData => irowData._id === deletedInquiry._id &&
            irowData.openGroups === deletedInquiry.openGroups);
          this.rowData.splice(inquiryIndextoDelete, 1);
          if (!isInqForwarded) { // fix for C153176-5057  email got duplicated on forward or reassign
  
            // to reset conversation view  
            if (deletedInquiry._id === this.selectedInqId && deletedInquiry.openGroups === this.assignedGroup) {
              this.resetConversationView();
            }
          }
        });
        // C153176-4639: check whether any row deleted is currently selected. If yes, select row 0 since this one will be removed.
        if (!isInqForwarded) { // fix for C153176-5057  email got duplicated on forward or reassign
          if (selectedGridRow && selectedGridRow.length) {
            const deletedRow = selectedGridRow.filter(row => nodeToDelete.some(node => node && node._id === row._id));
            if (deletedRow && deletedRow.length === selectedGridRow.length) {
              // select row 0
              let rowNode = this.gridApi.getDisplayedRowAtIndex(0);
              if (rowNode) {
                rowNode.setSelected(true);
  
              }
            }
          }
        }
  
      }
    }
  
    updateInqWorkflow(inqToInsert) {
      let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
  
      if (matchingWfExists) {
        let matchingwf = inqToInsert.workflows.find(x => x.direction == matchingWfExists && x.assignedGroupName == inqToInsert.openGroups);
        inqToInsert.workflows = [];
        if (matchingwf) {// C153176-5309: null check
          inqToInsert.workflows.push(matchingwf);
          return true;
        }
      } else if (this.isDirectionAgnosticView()) { // C153176-4447: refactoring
        // myviews or dashboard inline view or chart view or search result - C153176-4447
        let matchingwf = inqToInsert.workflows.find(x => x.assignedGroupName == inqToInsert.openGroups);
        inqToInsert.workflows = [];
        if (matchingwf) { // C153176-5309: null check
          inqToInsert.workflows.push(matchingwf);
          return true;
        }
      }
      return false;
    }
  
    getLength(arr) {
      return arr ? arr.length : -1;
    }
  
    /**
     * C153176-4700: compare scrollHeight vs clientHeight in combination with row count comparison
     */
    showLoadMore() {
      if (!this.gridViewPort || !this.gridViewPort.length) {
        this.gridViewPort = $('#inboxGrid .ag-body-viewport');
      }
      if (this.gridApi && this.gridApi.clientSideRowModel && this.gridApi.clientSideRowModel.rootNode) {
        const rootNode = this.gridApi.clientSideRowModel.rootNode;
        let allLeafChildren = this.getLength(rootNode.allLeafChildren);
        let afterSort = this.getLength(rootNode.childrenAfterSort);
        let afterGroup = this.getLength(rootNode.childrenAfterGroup);
        let afterFilter = this.getLength(rootNode.childrenAfterFilter);
        let scrollHeight = (this.gridViewPort && this.gridViewPort.length) ? this.gridViewPort[0].scrollHeight : 0;
        let clientHeight = (this.gridViewPort && this.gridViewPort.length) ? this.gridViewPort[0].clientHeight : 0;
        if ((allLeafChildren && (afterGroup < allLeafChildren || //afterSort < allLeafChildren ||
          afterFilter < allLeafChildren)) ||
          // C153176-4700: if the scrollbar is not shown but all leafChildren is less than total records, some records are not shown
          (scrollHeight > 0 && scrollHeight === clientHeight && allLeafChildren < this.initialCount)) { // C153176-5308 check condition only when scrollHeight is populated
          return true;
        }
      }
      return false;
    }
  
    fewerRowsDisplayed() {
      if (this.gridApi && this.gridApi.clientSideRowModel && this.gridApi.clientSideRowModel.rootNode) {
        const rootNode = this.gridApi.clientSideRowModel.rootNode;
        let allLeafChildren = this.getLength(rootNode.allLeafChildren);
        let afterSort = this.getLength(rootNode.childrenAfterSort);
        let afterGroup = this.getLength(rootNode.childrenAfterGroup);
        let afterFilter = this.getLength(rootNode.childrenAfterFilter);
        if (allLeafChildren && (afterGroup < allLeafChildren || //afterSort < allLeafChildren ||
          afterFilter < allLeafChildren)) {
          return true;
        }
      }
      return false;
    }
    /*  Start C153176-4673 Server side pagination  */
    onBodyScroll(params) {
      const allRowsRendered = params.api.rowRenderer.rowModel.rowsToDisplay.length > 0
        ? params.api.rowRenderer.rowModel.rowsToDisplay.filter(rd => !rd.alreadyRendered) : true;
      let allLeafChildren = this.getLength(params.api.clientSideRowModel.rootNode.allLeafChildren);
      let displayLength = this.getLength(params.api.rowRenderer.rowModel.rowsToDisplay);
      // C170665-1005 Introduce control over scrolling the the Grid
      if (!this.dataCache.isGetGridViewDataAPICallComplete) {
        return;
      }
      //console.debug('onBodyScroll, first/lastRenderedRow=',
      //  params.api.rowRenderer.firstRenderedRow, params.api.rowRenderer.lastRenderedRow,
      //  ', displayLength, allLeafChildren, start/endPage=', 
      //  displayLength, allLeafChildren, this.dataCache.startPage, this.dataCache.endPage);
      if ((displayLength && params.api.rowRenderer.lastRenderedRow === displayLength - 1) ||
        (this.dataCache.endPage > 0 && params.api.rowRenderer.lastRenderedRow >=
          this.dataCache.endPage * this.dataCache.defaultPageSize - 1)) { // C153176-4986: pagination boundary fix
        this.requestPage(false, allLeafChildren - 1);
      } else if (displayLength && params.api.rowRenderer.firstRenderedRow == 0) {
        this.requestPage(true, 0);
      }
    }
    /*  Start C153176-4673 Server side pagination  */
    onLoadMoreInquiries() {
      if (!this.gridApi || !this.gridApi.clientSideRowModel || !this.gridApi.clientSideRowModel.rootNode) {
        return;
      }
      let allLeafChildren = this.getLength(this.gridApi.clientSideRowModel.rootNode.allLeafChildren);
      this.dataCache.requestNextPage(allLeafChildren - 1);
    }
  
    /**
     *  C153176-5076 - return boolean indicating whether the new data is requested
     *  @return true if request was truly sent, otherwise false
     */
    requestPage(prevOrNext, requestedIndex) {
      if (prevOrNext) {
        return this.dataCache.requestPrevPage(); // C153176-5076
      } else if (requestedIndex < this.initialCount - 1) {
        return this.dataCache.requestNextPage(requestedIndex); // C153176-5076
      } else {
        console.debug('ignore scrolling - already fetched all data');
      }
      return false; // C153176-5076
    }
  
    /**
     * C153176-4229: Method to handle the contact push from contact list to new-message
     */
    onPushContact(pushContact: any): void {
      this.pushContact = pushContact;
    }
  
    public detectChanges() {
      this.cdr.detectChanges();
    }
    /**
     * C153176-4660: fetch and update inquiry subject and conversation count
     * @param inq
     */
    fetchConvHistSummary(inq) {
      if (!inq) {
        return;
      }
      // C170665-524 Performance Issue - While switching an inquiry in the grid
      // Fix for ws insert focus change
      if (this.inqAddedByWs) {
        return;
      }
      let selectInqAssignedGroupId = [];
      if (inq.workflows) {
        inq.workflows.forEach(workflow => {
          let assignedGroupId = workflow.assignedGroupId;
          if (this.userDataService.userGroupIdList.indexOf(assignedGroupId) > -1) {
            selectInqAssignedGroupId.push(assignedGroupId);
          }
        });
      }
  
      // C153176-5223 | conversation loading issue for nomination
      let hasNominatedOwnership = inq.workflows[0].hasNominatedOwnership !== undefined ? inq.workflows[0].hasNominatedOwnership : false;
  
      let inquiryData = {
        id: inq._id,
        selectInqAssignedGroupId: selectInqAssignedGroupId,
        isAllConvContent: false, // C153176-4660: do not load all content since only metadata is needed
        hasNominatedOwnership: hasNominatedOwnership
      };
      // Send request to get the all the inquiry conversation for the selected inquiry Id.
      this.inboxService.getInquiryIdExtSelected(inquiryData, inq).subscribe(inquiryDetail => {
        this.requestCacheService.updateInquiryCache(inq, inquiryData.isAllConvContent, inquiryDetail);
        this.selectedInquirySubject = inquiryDetail.inquiry ? inquiryDetail.inquiry.subject : '';
        this.inquiryReportedConvCount = inq.convCount ? inq.convCount : 0; // C153176-5257: inquiry-reported conv count
        if (inquiryDetail.conversationList && inquiryDetail.conversationList.length) {
          inq.convCount = inquiryDetail.conversationList.length;
          if (inquiryDetail.inquiry) {
            inquiryDetail.inquiry.convCount = inquiryDetail.conversationList.length;
          }
        }
        console.debug('Successfully fetched Inquiry details, id / subject / total conversations : ' + inquiryData.id,
          this.selectedInquirySubject, this.inquiryReportedConvCount);
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      },
        error => {
          // C153176-4910: end conversation loading status tracking if any
          this.endTrackingConvLoading(inquiryData.id, inq.workflows[0].assignedGroupName); // C153176-4614: fix on group id vs. name mismatch
          console.error('Error while getting response from getInquiryIdExtSelected()');
        });
    }
  
    /**
     * C153176-4660: check whether any of the node's conversation is selected before selecting the node.
     * If there is conversation selected, do NOT select the node. Keep the conversation selected.
     */
    selectOpenConvInquiryNode(node, flag): boolean { // C153176-5189: return boolean on whether node selection is done
  
      // C153176-4903: Select first row in Draft folder.
      if (this.isDraftsView && node) {
        // skip processing in draft view
        node.setSelected(flag);
        return true;// C153176-5189
      }
  
      if (!node || !node.data || !node.data.workflows || !node.data.workflows.length) {
        return false;// C153176-5189
      }
      //Jira - C153176-4660  
      let groupId = node.data.workflows && node.data.workflows.length ? [node.data.workflows[0].assignedGroupId] : []
      if (this.gridOprService.matchSelectedConversation(false, this.strMailboxView,
        node.data, node.data.openGroups, groupId)) {
        this.fetchConvHistSummary(node.data);
      } else if (this.gridOprService.matchSelectedInquiry(this.selectedRowData, node.data)) {
        // C153176-4614: if the inquiry is already selected, trigger onSelectedChanged call.
        this.onSelectionChanged();
      }
      // C153176-5120: always set selection state on the node
      node.setSelected(flag);
      return true;// C153176-5189
    }
  
    setTotalRecordCount() {
      try {
        let totalCount = this.initialCount;
        if (totalCount) {
          // C153176-4994: refactor so as to return a number 'totalCount'
          if (typeof totalCount === 'string') {
            totalCount = parseInt(totalCount);
          }
          this.lbTotalRecords = totalCount.toLocaleString();
        } else {
          this.lbTotalRecords = '0';
        }
        return totalCount;
      } catch (err) {
        console.log(err)
        return 0;
      }
    }
  
    updateCountLabels(inq = null, isDeleted = null) {
      if (!this.gridApi) {
        return;
      }
      if (this.fewerRowsDisplayed()) {
        let displayLength = this.getLength(this.gridApi.rowRenderer.rowModel.rowsToDisplay);
        if (this.gridApi.clientSideRowModel && this.gridApi.clientSideRowModel.rootNode) {
          const rootNode = this.gridApi.clientSideRowModel.rootNode;
          let allLeafChildren = this.getLength(rootNode.allLeafChildren);
          this.lbTotalRecords = allLeafChildren.toLocaleString();
          // C153176-4994: make sure lbPageSize no greater than total count
          this.lbPageSize = Math.min(displayLength, allLeafChildren);
        }
      } else {
        let lbPageSize = this.rowData ? this.rowData.length : -1; // fix for C153176-5428 Websocket issue for pagination and view issue raised by user
        // lbPageSize = Math.min(lbPageSize, this.dataCache.maxFetchedRecords);
        let totalCount = this.setTotalRecordCount();
        // C153176-4994: make sure lbPageSize no greater than total count
        this.lbPageSize = lbPageSize; // fix for C153176-5428 Websocket issue for pagination and view issue raised by user 
      }
      this.updateLblTotalRecords(); // fix for C153176-5428 Websocket issue for pagination and view issue raised by user
      // maintin read unread inqs at UI level
      // C153176-5577 Blue Circle on tab is not showing correct number
      if (!this.rowData) {
        return;
      }
      // for websocketUpdate update read unread count insert scenario
      if (inq && !isDeleted) {
        if (inq.readBy && inq.readBy.length && inq.readBy.length > 0 && inq.readBy.includes(this.loginUserInfo.userId)) {
          /* do nothing */
        } else {
          if (inq && inq._id && inq.workflows) {
            if (inq.workflows.find(wf => wf.assignedGroupName === inq.openGroups) && inq.workflows.find(wf => wf.assignedGroupName === inq.openGroups).assignedGroupId) {
              let agrpId = inq.workflows.find(wf => wf.assignedGroupName === inq.openGroups).assignedGroupId
              this.IncrementUnReadCount({ inqId: inq._id, agrpId: agrpId });
            }
          }
        }
      }
  
      // websocket update read unread count delete scenario
      // for websocketUpdate update read unread count delete scenario
      if (inq && isDeleted) {
        if (inq.readBy && inq.readBy.length && inq.readBy.length > 0 && inq.readBy.includes(this.loginUserInfo.userId)) {
          this.removeReadInqFromMap(inq);
        } else {
          if (inq && inq._id && inq.workflows) {
            if (inq.workflows.find(wf => wf.assignedGroupName === inq.openGroups) && inq.workflows.find(wf => wf.assignedGroupName === inq.openGroups).assignedGroupId) {
              this.removeUnreadInqFromMap(inq);
            }
          }
        }
      }
      // for service side pagination
      this.rowData.forEach(lst => {
        if (lst.readBy && lst.readBy.length && lst.readBy.length > 0 && lst.readBy.includes(this.loginUserInfo.userId)) {
          this.inqUnreadStatusMap.set(lst._id + ":" + lst.workflows[0].assignedGroupId, false);
        } else {
          if (lst && lst._id && lst.workflows) {
            this.inqUnreadStatusMap.set(lst._id + ":" + lst.workflows[0].assignedGroupId, true);
          }
        }
      });
    }
    // fix for C153176-5428 Websocket issue for pagination and view issue raised by user
    // need to be flaged based
    updateLblTotalRecords() {
      if (this.isWebSocketRedesignEnable) {
        return;
      }
      this.lbTotalRecords = this.dataCache.maxFetchedRecords < this.lbPageSize ?
        ((this.lbPageSize - this.dataCache.maxFetchedRecords) + this.initialCount).toLocaleString() :
        (this.dataCache.maxFetchedRecords === this.lbPageSize) ? this.initialCount.toLocaleString() :
          (this.initialCount - (this.dataCache.maxFetchedRecords - this.lbPageSize)).toLocaleString(); // fix for C153176-5428 Websocket issue for pagination and view issue raised by user 
  
    }
    // export to excel  C153176-4458
    onBtExport() {
      // C153176-5296: differentiate search folders vs. other folders. For other folders, use export API to download.
      if (!this.dataCache.requestObj) {
        console.debug("Cannot export data due to missing request")
      }
  
      // C153176-5394
      let viewName = this.dataCache.requestObj.viewName;
      let isChartView = this.dataCache.requestObj.isChartView;
      let dashboardViews = [QmaConstant.QMA_OPEN_INQ_BYASSIGNEDGROUP, QmaConstant.QMA_OPEN_INQ_BYREQUESTTYPE, QmaConstant.QMA_OPEN_INQ_BY_OWNER];
  
      /* if ( this.dataCache.requestObj.solrSearchText) {
        console.debug('search folder, export config=', this.getExportToExcelConfig());
        this.gridApi.exportDataAsCsv(this.getExportToExcelConfig());
      } */
      // C153176-5394 : Issue 2 - Download from Open inquiries by Assigned group, Request type and owners from Dashboard
      if (QmaConstant.typeEnum.DRAFTS.DRFT == viewName) {
        console.debug('Dashboard charts, export config=', this.getExportToExcelConfig());
        this.gridApi.exportDataAsCsv(this.getExportToExcelConfig());
      }
      else {
        let viewType = this.dataCache.requestObj.viewType;
        if (viewType !== undefined && viewType !== null && typeof viewType === 'string') {
          viewType = parseInt(viewType);
        }
        // C153176-5394 : Add isRequestFromMailBoxStats for mailbox statistics
        let requestObj: any = { viewName: viewName, viewType: viewType, isRequestFromMailBoxStats: this.dataCache.requestObj.isRequestFromMailBoxStats };
        if (requestObj.viewName == 'Assigned To Me' || requestObj.viewName == 'Assigned To me') {
          requestObj.viewName = 'Assigned To me';
          requestObj.assignedToMe = 'Y';
          requestObj.viewType = undefined;
        } else if (requestObj.viewName == 'Unassigned') {
          requestObj.unAssignedMessage = 'Y';
          requestObj.viewType = undefined;
        } else if (requestObj.viewName == 'Pending Approval') {
          requestObj.pendingApproval = 'Y';
          requestObj.viewType = undefined;
        } // C153176-5296 : Tag Download request
        else if (requestObj.viewType == undefined && this.dataCache.requestObj.tagSearch && this.dataCache.requestObj.groupName) {
          requestObj.tagSearch = this.dataCache.requestObj.tagSearch;
          requestObj.groupName = this.dataCache.requestObj.groupName;
          requestObj.viewType = QmaConstant.typeEnum.TAG.TYPE;
        }
        // C153176-5394 - Prepare request for service request for dashboard charts.
        else if ((dashboardViews.includes(viewName) && 'Y' == isChartView)) {
          requestObj.groupId = this.dataCache.requestObj.groupId;
          requestObj.isChartView = isChartView;
          requestObj.isReqFromQma2Dashboard = this.dataCache.requestObj.isReqFromQma2Dashboard;
  
          // Request for Owner type
          if (QmaConstant.QMA_OPEN_INQ_BY_OWNER == viewName) {
            requestObj.assignedOwnerId = this.dataCache.requestObj.assignedOwnerId;
            requestObj.ageBand = this.dataCache.requestObj.ageBand;
          }
          else if (QmaConstant.QMA_OPEN_INQ_BYREQUESTTYPE == viewName) {
            requestObj.requestType = this.dataCache.requestObj.requestType;
          }
        }
        // C153176-5486 Download CSV based on filter applied
        if (this.dataCache.requestObj.advanceSearchData || this.dataCache.requestObj.solrSearchText ||
          this.dataCache.requestObj.viewName.toUpperCase() === "INTENSITYHEATMAP") {
          let req = this.dataCache.requestObj
          delete req.pageNum;
          delete req.pageSize
          // C153176-5486 Download CSV based on filter applied if ui filter applied export ui filtered records only
          if (Object.keys(this.gridApi.getFilterModel()).length !== 0) {
            this.gridApi.exportDataAsCsv(this.getExportToExcelConfig());
          } else {
            this.inboxService.exportInquiriesToFile(req);
          }
  
        } else {
          // // C153176-5486 Download CSV based on filter applied if ui filter applied export ui filtered records only
          if (Object.keys(this.gridApi.getFilterModel()).length !== 0) {
            this.gridApi.exportDataAsCsv(this.getExportToExcelConfig());
          } else {
            this.inboxService.exportInquiriesToFile(requestObj);
          }
  
        }
  
      }
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Download", "Click", "Download", 0);
    }
  
    getExportToExcelConfig() {
      return {
        fileName: "Export",
        sheetName: "Export",
        allColumns: false,
        columnGroups: true,
        onlySelected: false,
        onlySelectedAllPages: false,
        shouldRowBeSkipped: false,
        skipFooters: false,
        skipGroups: false,
        skipHeader: false,
        skipPinnedBottom: false,
        skipPinnedTop: false,
        columnKeys: this.getGridDataColums(),
        // fix for JIRA C153176-3135
        processCellCallback: (data) => {
          return this.formatExportToExcel(data)
        }
      };
    }
  
    // to export all visible columns without expander and other utility columns
    getGridDataColums() {
      return this.gridApi.columnModel.displayedColumns.filter
        (col => col.colId !== "expander" && col.colId !== "cheveron" && col.colId !== "cheveron_1"
          && col.colId !== "isSymphonyChatGridCol").map(x => x.colId);
    }
    // fix for JIRA C153176-3135
    formatExportToExcel(params) {
      // process only date columns
      if (params.value && params.column.userProvidedColDef.field.includes('$date')) {
        return this.objAppUtils.convertDate(params.value);
      } else {
        return params.value;
      }
    }
  
    /** 
     * C153176-5081 - refactoring to support criteria-based filtering on more folders than myviews
     */
    filterByViewCriteria(inq, filter, viewCriteria, defaultCriteria) {
      // setting 'acceptedByMyView' with filter criteria on MyView
      let workflows = inq.workflows;
      if (!workflows || workflows.length <= 1) {
        // if there is no or at most 1 workflow in inquiry, proceed with inquiry-level filtering
        let filterResult = filter.call(this, this.viewType, inq, viewCriteria, defaultCriteria);
        if (this.viewType === -1) {
          this.acceptedByMyView = filterResult; // C153176-5081: set flag for myview only
        }
        return { result: filterResult, inq: inq };
      } else {
        // C153176-5309: there are more than 1 workflow, perform myviews filtering at per workflow basis
        let originalInq = inq;
        // clone existing inquiry, do not alter the original one
        inq = Object.assign({}, inq);
        workflows = [...workflows];
        let resultWorkflows = [];
        // C153176-5710 Websocket- inquiry is coming in view even if its not included in view
        let exceludedAgrps = [];
        // C153176-5591 Emails are received to individuals who are not part of group
        let includeAgrps = [];
        if (defaultCriteria && defaultCriteria.$and && defaultCriteria.$and[0]
          && defaultCriteria.$and[0]['workflows.assignedGroupName']
          && defaultCriteria.$and[0]['workflows.assignedGroupName'].$ne
        ) {
          exceludedAgrps = defaultCriteria.$and.map(dc => dc['workflows.assignedGroupName'].$ne);
        }
  
  
        if (defaultCriteria && defaultCriteria.$and && defaultCriteria.$and[0]
          && defaultCriteria.$and[0]['workflows.assignedGroupName']
  
        ) {
          includeAgrps = defaultCriteria.$and.map(dc => dc['workflows.assignedGroupName']);
        }
  
        workflows.forEach(wf => {
          // clear existing workflows from inquiry
          inq.workflows = [];
          // assign workflow to inquiry, one at a time
          if (wf) {
            if (exceludedAgrps && exceludedAgrps.length && exceludedAgrps.length > 0) {
              if (inq.openGroups.includes(";") // multiple assigned groups
                && inq.openGroups.split(";").some(x => exceludedAgrps.includes(x)
                )) {
                /* inq.openGroups =  inq.openGroups.split(";").filter(og=> og!= defaultCriteria.$and[0]['workflows.assignedGroupName'].$ne)
                                  .join(";"); */
                inq.openGroups = inq.openGroups.split(";").filter(d => !exceludedAgrps.includes(d)).join(";");
              } else { // single assigned group
                if (exceludedAgrps.includes(inq.openGroups)) {
                  inq.openGroups = "";
                }
              }
  
            }
            if (!resultWorkflows.some(workflow => workflow.assignedGroupId === wf.assignedGroupId)) {
              inq.workflows.push(wf);
              //inq.openUsers = wf.assignedUserName?this.updateAssignedTo(inq):'';
              let filterResult = filter.call(this, this.viewType, inq, viewCriteria, defaultCriteria);
              if (filterResult) {
                resultWorkflows.push(wf);
              }
            }
  
          }
        });
        // C153176-5591 Emails are received to individuals who are not part of group
        workflows.forEach(wf => {
          // clear existing workflows from inquiry
          inq.workflows = [];
          // assign workflow to inquiry, one at a time
          if (wf) {
            if (includeAgrps && includeAgrps.length && includeAgrps.length > 0) {
              if (inq.openGroups.includes(";") // multiple assigned groups
                && inq.openGroups.split(";").some(x => includeAgrps.includes(x)
                )) {
                /* inq.openGroups =  inq.openGroups.split(";").filter(og=> og!= defaultCriteria.$and[0]['workflows.assignedGroupName'].$ne)
                                  .join(";"); */
                inq.openGroups = inq.openGroups.split(";").filter(d => includeAgrps.includes(d)).join(";");
              } else { // single assigned group
                if (includeAgrps.includes(inq.openGroups)) {
                  // Do Nothing
                }
              }
  
            }
            if (!resultWorkflows.some(workflow => workflow.assignedGroupId === wf.assignedGroupId)) {
              inq.workflows.push(wf);
              // inq.openUsers = wf.assignedUserName?this.updateAssignedTo(inq):'';
              let filterResult = filter.call(this, this.viewType, inq, viewCriteria, defaultCriteria);
              if (filterResult) {
                resultWorkflows.push(wf);
              }
            }
  
          }
        });
        if (resultWorkflows.length) {
          // at least one workflow matches the criteria
          inq.workflows = resultWorkflows;
          if (inq.workflows && inq.workflows.length > 0) {
            // C153176-5591 Emails are received to individuals who are not part of group
            inq.openGroups = inq.workflows.map(x => x.assignedGroupName).join(";")
          }
          return { result: true, inq: inq };
        } else {
          // no workflow matches the criteria
          return { result: false, inq: originalInq };
        }
      }
    }
  
    // C153176-4798: filter myView criteria
    // C153176-5150: filter against current user's assigned groups
    // C153176-5309: myview filter at per workflow basis
    filterByCriteria(inq) {
      // filter assigned group first
      if (!this.inboxViewFilters.filterAssignedGroups(inq, this.assignedGroups)) {
        console.debug('Ignore websocket update: ', inq, 'assigned groups', this.assignedGroups);
        return false;
      }
      // important to save the result for subsequent use
      if (this.viewType === -1) {
        let filtered = this.filterByViewCriteria(inq, InboxViewHelper.filterByMyViewCriteria, this.myViewCriteria, this.myViewDefaultCriteria);
        this.acceptedByMyView = filtered ? filtered.result : false; // C153176-5081: set flag for myview only
        return filtered;
      } else if (this.strMailboxView === 'RESOLVED') {
        return this.filterByViewCriteria(inq, InboxViewHelper.filterByViewCriteria, this.finalUICriteria, null);
      } else {
        // C153176-5082: do NOT set 'this.acceptedByMyView' for the time being - before finalUICriteria is used for filtering on all the folders
        return { result: true, inq: inq };
      }
    }
  
    // C153176-4447: return boolean flag indicating whether in this view the workflow direction is agnostic for websocket matching
    isDirectionAgnosticView() {
      return this.viewType === -1 // myviews
        || this.isDashboardInlineView || this.isChartView || this.searchView
        || this.viewType === 11 // escalation views
        || this.viewType === '6' // assigned to me
        || this.viewType === '7' // unassigned
        || ((this.viewType === '0' || this.viewType === 0) && this.strMailboxView === 'NON INQUIRIES'); // non-inquiries
    }
  
    insertUnsnoozedInq(inq) {
      let matchingWfExists = this.wfDirectionMailboxViewMap.get(this.strMailboxView);
      let sonnzedWf = inq.workflows.filter(wf => (wf.action === "UnSnooze" && wf.direction === matchingWfExists) ||
        (wf.action === "Un-Snooze" && wf.direction === matchingWfExists));
      if (sonnzedWf.length > 0) {
        sonnzedWf.forEach(si => {
          let isInqExists = this.rowData.findIndex(irowData => irowData._id === inq._id && irowData.openGroups === si.assignedGroupName);
          if (isInqExists === -1) {
            inq.openGroups = si.assignedGroupName;
            this.addItemsAtTop(inq);
          }
  
        })
  
  
      }
    }
    // fix for C153176-4991 31 Jan Prod Issue - Web socket issue Unassigned and Assigned queues are not updated
    isLoggedInUserConsistAssignedGroup(openGroups): boolean {
      return this.loginUserInfo.myGroups.map(x => x.groupName).includes(openGroups);
    }
    // JIRA  C153176-4878 Snooze - phase 2
    getCurrentIndexFromRowData(inqId, openGroups): number {
      return this.rowData.findIndex(rdata => rdata._id === inqId && openGroups === rdata.openGroups);
    }
    // JIRA  C153176-4878 Snooze - phase 2
    updateRowData(index, data, fieldtoUpdate) {
      this.rowData[index][fieldtoUpdate] = data;
    }
    // JIRA  C153176-4878 Snooze - phase 2
    markWsSnoozedInquiry(snoozedInq) {
      if (snoozedInq.length === 0) {
        return;
      }
      snoozedInq.forEach(si => {
        let inqIndex = this.getCurrentIndexFromRowData(si._id, si.openGroups);
  
      });
    }
    // fix for C153176-5057
    // check if  inqs qualified for concerned group
    isInqQualifiedTOInsertByGroup(inq: any): boolean {
      return (this.dataCache.requestObj.viewName === QmaConstant.QMA_OPEN_INQ_BYASSIGNEDGROUP
        && this.dataCache.requestObj.isChartView === "Y" && this.dataCache.requestObj.isReqFromQma2Dashboard
        && inq.workflows.some(g => g.assignedGroupId == this.dataCache.requestObj.groupId && g.direction === "IN")
      );
  
    }
    // fix for C153176-5057
    // remove unqualified inqs from open inq by assigned group folder
    processMatchedGroup(inq) {
      if (this.isInqQualifiedTOInsertByGroup(inq)) {
        // fix for C153176-5297 Tabs opened from the dashboard charts shows incorrect data
        if (inq.openGroups && inq.openGroups.includes(";")) {
          let opentabGroupName = this.loginUserInfo.myGroups.filter(
            g => g.id == this.dataCache.requestObj.groupId).map(x => { return x.groupName }
            )[0]
          let assignedGrp = inq.openGroups.split(";").filter(g => g == opentabGroupName).join(";");
          inq.openGroups = assignedGrp;
        }
      }
      else {
        inq.openGroups = "";
      }
    }
    // fix for C153176-5297 Tabs opened from the dashboard charts shows incorrect data
    processRequestType(inq) {
      let isMatchingReqTypr = inq.workflows.filter(
        x => x.requestType && x.requestType === this.dataCache.requestObj.requestType && x.direction === "IN").length > 0;
      if (!isMatchingReqTypr) {
        inq.openGroups = "";
      }
  
    }
  
    // fix for C153176-5057
    // to check if current tab is open INq by Grp
    isCurrentTabOpenInqByGrp() {
      return this.dataCache.requestObj.viewName === QmaConstant.QMA_OPEN_INQ_BYASSIGNEDGROUP;
    }
    // fix for C153176-5297 Tabs opened from the dashboard charts shows incorrect data
    isCurrentTabOpenMacthCriterian(criteraia: string) {
      return this.dataCache.requestObj.viewName.toLocaleLowerCase() === criteraia.toLocaleLowerCase()
    }
  
    // C153176-5309: retrieve / filter assigned group names
    getAssignedGroupNames(inq) {
      inq.openGroups = inq.openGroups.replace("undefined", "");
      let arrAssignedGroup = (inq && inq.openGroups) ? inq.openGroups.split(";") : [];
      if (this.acceptedByMyView && inq.workflows) {
        // C153176-5309: in myviews, filter the assigned groups against allocated workflows
        let wfAssignedGroups = inq.workflows.map(wf => wf.assignedGroupName);
        if (wfAssignedGroups && wfAssignedGroups.length) {
          arrAssignedGroup = arrAssignedGroup.filter(grp => wfAssignedGroups.includes(grp));
        }
      }
      return arrAssignedGroup;
    }
  
    // C153176-5106: taking into account request filter in setting the total count
    setGridTotalCount(count, unReadCount): void {
      let filter = this.dataCache.getRequestFilter(this.dataCache.requestObj);
      if (!filter || filter.type !== 'readBy' || unReadCount === null || unReadCount === undefined) {
        this.initialCount = count;
        return;
      }
      let hasRead = filter.value;
      if (hasRead) {
        // derive READ count
        this.initialCount = Math.max(0, count - unReadCount);
      } else {
        // set UNREAD count
        this.initialCount = unReadCount || 0;
      }
    }
    // C153176-5040 | On Save Column Configuration
    onSaveColConfig(): void {
      let viewNameReq = (this.dataCache.requestObj.viewName && this.dataCache.requestObj.viewName.toUpperCase() === "INTENSITYHEATMAP")
        ? this.dataCache.requestObj.viewName.toUpperCase() : this.folderName;
      if (this.folderName.toLocaleLowerCase().includes('tag:')) {
        this.saveColumnConfirm = true;
      }
      else {
        // C153176-4558 : Save Columns not working for all folder tabs
        if (this.folderName == "Sent") {
          viewNameReq = "Outbox";
        }
        if (this.folderName == "Chat") {
          viewNameReq = "ChatView";
        }
        // C153176-5040: no altering of viewName for pending approval
        this.onSaveColumns(viewNameReq);
      }
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Save Columns", "Click", "Save Columns", 0);
    }
  
    // C153176-5040 | Handle Accept and Close.
    onAcceptColConfig(): void {
      this.onCancelColConfig();
      let viewNameReq = this.folderName;
      if (this.folderName.toLocaleLowerCase().includes('tag:')) {
        viewNameReq = viewNameReq.split(':')[0];
      }
      this.onSaveColumns(viewNameReq);
    }
  
    onCancelColConfig(): void {
      this.saveColumnConfirm = false;
    }
    // fix for C153176-5247 Webbsocket issue for Non Inquiry rules
    updateWfForNonInq(inq) {
      // fix for C153176-5247 Webbsocket issue for Non Inquiry rules
      let inqWfs = inq.workflows.filter(wf => !wf.rulesFlag)
      inq.workflows = [];
      inq.workflows = inqWfs;
    }
    // fix for C153176-5428 Websocket issue for pagination and view issue raised by user
    removeSnoozedInq(inq) {
      let nodeToDelete = [];
      const multigrpInqWfSnoozed = inq.workflows.filter(wf => (wf.action === "Snooze" && wf.direction === "IN"));
      multigrpInqWfSnoozed.forEach(matchinqWf => {
        this.gridApi.forEachNode(function (node) {
          if (inq._id === node.data._id && node.data.openGroups === matchinqWf.assignedGroupName) {
            nodeToDelete.push(node.data);
  
          }
  
        });
      });
      // remove unsnoozed inqs  folder
      if (nodeToDelete.length > 0) {
        let res = this.gridApi.updateRowData({ remove: nodeToDelete });
      }
      // Remove the conversation if Selected Inquiry Id is removed
      nodeToDelete.forEach(deletedInquiry => {
        // remove from rowdata  
        let inquiryIndextoDelete = this.rowData.findIndex(irowData => irowData._id === deletedInquiry._id &&
          irowData.openGroups === deletedInquiry.openGroups);
        this.rowData.splice(inquiryIndextoDelete, 1);
  
        // to reset conversation view  
        if (deletedInquiry._id === this.selectedInqId && deletedInquiry.openGroups === this.assignedGroup) {
          this.resetConversationView(deletedInquiry, true);
        }
      });
    }
  
    isDefaultNumberOfColumns(columnConfig) {
      // C153176-5675 Column width should also save on click of save column button
      if (columnConfig && columnConfig.filter(x => Object.keys(x).includes("isDefaultColumns"))
        && columnConfig.filter(x => Object.keys(x).includes("isDefaultColumns"))[0]) {
        this.isDefaultColumns = columnConfig.filter(x => Object.keys(x).includes("isDefaultColumns"))[0].isDefaultColumns;
        return this.isDefaultColumns;
      } else {
        // C153176-5411 Grid is not responsive. On 1st download need to always reset for it to fit all columns
        if (columnConfig) {
          return this.columnConfig.filter(x => x.hide === false).length === 10;
        } else {
          return null;
        }
      }
    }
    // fix for C153176-5406 Standardize external email "From" so it always shows client name first
    formatDisplayName(latestUserName: string, latestEmail: string): string {
      if (!latestUserName || !latestEmail) {
        return;
      }
      let formattedLatestUserName = latestUserName;
      try {
        let latestUserNameTrim = latestUserName.trim();
        let latestEmailTrim = latestEmail.trim();
        let isCitiDomain = true;
        let domain = null;//
        if (latestEmailTrim && latestEmailTrim.includes("@")) {
          isCitiDomain = this.loginUserInfo.citiDomainList.some(x => latestEmail.split('@')[1].toUpperCase().includes(x));
        }
        if (!isCitiDomain) {
          domain = this.getExternalEmailDomain(latestEmailTrim);
          if (domain && latestUserNameTrim.startsWith("[External]")) {
            formattedLatestUserName = latestUserNameTrim.replace("[External]", "[" + domain + "]");
          } else if (domain && latestUserNameTrim && !latestUserNameTrim.startsWith("[")) {
            formattedLatestUserName = "[" + domain + "] " + latestUserNameTrim;
          }
        }
  
      } catch (e) {
        console.log(e);
      }
      return formattedLatestUserName;
    }
    // fix for C153176-5406 Standardize external email "From" so it always shows client name first
    getExternalEmailDomain(email: string): string {
      if (email && email.includes("@")) {
  
        return (email.split('@')[1]).toUpperCase();
      }
      return "";
  
    }
    setClientCategoryForWorkflows(inq) {
      try {
        inq.workflows.forEach(workflow => {
          let clientPriority = null;
          if (inq && inq.clientPriority) {
            clientPriority = inq.clientPriority;
          }
          if (clientPriority && ("Platinum".toUpperCase() === clientPriority.toUpperCase()
            || "Supercharge".toUpperCase() === clientPriority.toUpperCase())) {
  
            let basicObject = {
              'categoryName': "Platinum",
              'colorCode': "#F00F9F"
            };
            workflow.clientCategory = basicObject;
  
          } else if (clientPriority && "Priority".toUpperCase() === clientPriority.toUpperCase()) {
  
            let basicObject = {
              'categoryName': "Priority",
              'colorCode': "#609FFF"
            };
            workflow.clientCategory = basicObject
  
  
          } else if (this.isOtherClientCategory(inq)) {
            let basicObject = {
              'categoryName': "Other",
              'colorCode': "#A52EE2"
            };
            workflow.clientCategory = basicObject
  
          }
  
        })
  
      } catch (e) {
        console.log("Exception while setting client priority workflow level:", e);
      }
    }
  
    isOtherClientCategory(inquiry) {
      let status = false;
      try {
        let gpNum = null;
        let gpName = null;
        let clientName = null;
        if (inquiry.gpNum) {
          gpNum = inquiry.gpNum;
        }
        if (inquiry.gpName) {
          gpName = inquiry.gpName;
        }
        if (inquiry.clientName) {
          clientName = inquiry.clientName;
        }
        if (gpNum && gpNum.length > 0 && gpName && gpName.length > 0 && clientName && clientName.length > 0) {
          status = true;
        }
      } catch (e) {
        console.log("Exception in isOtherClientCategory", e);
      }
  
      return status;
  
    }
    getSingleFilterModel(data) {
      return { filterType: "text", type: "equals", filter: data }
    }
    getMultiFilterModel() {
      return { "filterType": "text", "operator": "OR" };
    }
    getMultiFilterData(filterModel, index, data) {
      filterModel['condition' + (index + 1)] = { "filterType": "text", "type": "equals", "filter": data }
    }
    getMultiFilterDataNumber(filterModel, index, data) {
      filterModel['condition' + (index + 1)] = { "filterType": "number", "type": "greaterThan", "filter": data }
    }
    getFilterInstance(clmnName) {
      return this.gridApi.getFilterInstance(clmnName)
    }
    bindCategoryFilterOptions() {
      // this.customCategories = this.userDataService.getClientCategory()
      //   .map(cc => { return { label: cc.categoryName, value: cc.categoryName, text: cc.categoryName } });
      // this.customCategories = this.customCategories.concat(this.loginUserInfo.myGroups.map((mg) => mg.customClientCategory).filter(x => x != null).flat()
      //   .map(cc => { return { label: cc.categoryName, value: cc.categoryName, text: cc.categoryName } }));
    }
    getClientCategoryGrid(params) {
      // C153176-4566 : Show System Defualt or Custom client category. System client category have precedence over custom client.
      // System Client Category
      let clientCategory = null;
      if (params.workflows) {
        const clntCtgryWf = params.workflows.find(x => x.clientCategory);
        if (clntCtgryWf && clntCtgryWf.clientCategory) {
          clientCategory = clntCtgryWf.clientCategory;
          clientCategory.assignedGroupId = clntCtgryWf.assignedGroupId;
        }
      }
  
  
      // Default Client Category
      let customClientCategory = null;
      if (params.workflows) {
        const cstmCtgryWf = params.workflows.find(x => x.customClientCategory);
        if (cstmCtgryWf && cstmCtgryWf.customClientCategory) {
          customClientCategory = cstmCtgryWf.customClientCategory[0];
          customClientCategory.assignedGroupId = cstmCtgryWf.assignedGroupId;
        }
      }
      let finalClientCategory = clientCategory ? clientCategory : customClientCategory;
      return finalClientCategory;
    }
    onInqUnread(inq) {
      // this.isInqReadFirstTime(inq);
    }
    // C153176-5635 Any incoming mail from outlook to a respective DL should update the inquiry to the top in the grid
    // check received date if latest then return true
    shouldMoveInqToTop(wsWf) {
      let inqIndexPosition = -1;
      var BreakException = {};
      let datePipe = new DatePipe("en-US");
      inqIndexPosition = this.rowData.findIndex(rData => datePipe.transform(wsWf.modDate.$date, 'dd/MMM/yy h:mm:ss a') > datePipe.transform(rData.modDate.$date, 'dd/MMM/yy h:mm:ss a'))
      return inqIndexPosition === 0 ? true : false;
    }
    // C153176-5577 Blue Circle on tab is not showing correct number
    isInqReadFirstTime(inq) {
      let rowData = this.rowData;
      let isInqReadFirstTime = false;
      if (rowData && rowData.filter(rData => rData._id === inq.id)[0] && !rowData.filter(rData => rData._id === inq.id)[0].readBy) {
        isInqReadFirstTime = true;
      } else if (rowData.filter(rData => rData._id === inq.id)[0] && rowData.filter(rData => rData._id === inq.id)[0].readBy.length === 0) {
        isInqReadFirstTime = true;
      }
      else if (rowData.filter(rData => rData._id === inq.id)[0] && !rowData.filter(rData => rData._id === inq.id)[0].readBy.includes(this.loginUserInfo.userId)) {
        isInqReadFirstTime = true;
      }
      if (isInqReadFirstTime) {
        this.DecrementUnReadCount({ id: inq.id, openGroups: inq.openGroups });
      }
    }
    // C153176-5577 Blue Circle on tab is not showing correct number
    IncrementUnReadCount(inq) {
      // if inq is already unread at UI do nothing
      let isUnReadATUI = this.inqUnreadStatusMap.get(inq.inqId + ":" + inq.agrpId)
      if (isUnReadATUI) {
        return;
      }
      this.inqUnreadStatusMap.set(inq.inqId + ":" + inq.agrpId, true);
      // this.unreadCount = this.unreadCount + 1;
      // this.updatedUnReadCount.emit({updatedUnread:this.unreadCount,tabName:this.strMailboxView});
      //TODO:Performance :: Below is not needed. Please remove
      // this.dashboardService.setUpdatedUnreadCount({ updatedUnread: this.unreadCount, tabName: this.currentTabName });
    }
    // C153176-5577 Blue Circle on tab is not showing correct number
    DecrementUnReadCount(inq) {
      // ws redesign change
      let agrpId = 0;
      if (inq.openGroups && this.loginUserInfo.myGroups.find(mg => mg.groupName === inq.openGroups)) {
        agrpId = this.loginUserInfo.myGroups.find(mg => mg.groupName === inq.openGroups).id;
      } else {
        agrpId = inq.agrpId;
      }
  
      let isUnReadATUI = this.inqUnreadStatusMap.get(inq.id + ":" + agrpId)
      if (!isUnReadATUI) {
        return;
      }
      this.inqUnreadStatusMap.set(inq.id + ":" + agrpId, false)
      // this.unreadCount = this.unreadCount - 1;
      // this.updatedUnReadCount.emit({updatedUnread:this.unreadCount,tabName:this.strMailboxView});
      // this.dashboardService.setUpdatedUnreadCount({ updatedUnread: this.unreadCount, tabName: this.currentTabName });
    }
  
    removeUnreadInqFromMap(inq) {
      let inqid = inq.id ? inq.id : inq._id;
      let agrpId = this.loginUserInfo.myGroups.find(mg => mg.groupName === inq.openGroups).id;
      this.inqUnreadStatusMap.delete(inqid + ":" + agrpId);
      // this.unreadCount = this.unreadCount - 1;
      // this.dashboardService.setUpdatedUnreadCount({ updatedUnread: this.unreadCount, tabName: this.currentTabName });
    }
  
    removeReadInqFromMap(inq) {
      let inqid = inq.id ? inq.id : inq._id;
      let agrpId = this.loginUserInfo.myGroups.find(mg => mg.groupName === inq.openGroups).id;
      this.inqUnreadStatusMap.delete(inqid + ":" + agrpId);
    }
    // C153176-5771 Inquiry retains back in Assign to me folder
    processReplyAllResolve(strMailboxView, inq) {
      switch (this.strMailboxView) {
        case "ASSIGNED TO ME":
        case "INBOX":
          {
            let isReplyAllResolve = inq.workflows.some(wf => wf.action === "ReplyAll Resolve" && wf.direction === "IN");
            if (isReplyAllResolve) {
              let insertedinqs = 0;
              inq.workflows.filter(wf => wf.status === "Open" && wf.direction === "IN").forEach(wf => {
                let isInqExists = this.rowData.findIndex(irowData => irowData._id === inq._id &&
                  irowData.openGroups === wf.assignedGroupName);
                if (isInqExists === -1) {
                  insertedinqs;
                  const inqToInsert = Object.assign({}, inq);
                  inqToInsert.openGroups = wf.assignedGroupName;
                  this.updateInqByWf(inqToInsert, null, "IN", wf);
                  this.addItemsAtTop(inqToInsert);
                }
              })
            }
          }
      }
  
    }
  
    isMatchingViewName(viewName) {
      return this.dataCache.requestObj.viewName && this.dataCache.requestObj.viewName === viewName ? true : false;
    }
    insertInqToGridAtIndex(inq, index) {
      var res = this.gridApi.updateRowData({
        add: [inq],
        addIndex: index
      });
    }
    addCustomColumnsToInq(inq) {
      inq.cheveron = "";
      inq.checkbox = "";
      inq.cheveron_1 = "";
      /* if(!this.isUndefinedOrNull(inq.modDate.$date))
      {
        // QMA2.0 specific change
        inq.modDate.$date =  new Date(inq.modDate.$date).toISOString();
      } */
      // to Remove group from assigned to Should be moved to service
      inq.openUsers = this.processAssgnTo(inq.openUsers);
    }
  
    sortByColumn(colid, sortOrder) {
      let sort = [{ colId: 'modDate.$date', sort: 'desc' }];
      this.loadGridColumnsSortData(sort)
      // this.gridApi.setSortModel(sort);
    }
  
    //check if already data updated by web socket it is for intensity heat map grid only
    wsAlreadyUpdatedData(inqArray, wsMatchingInqSubset) {
      var flag = _.find(wsMatchingInqSubset, function (row) {
        return (row._id == inqArray[0]._id)
          && (row.workflows[0].assignedGroupId == inqArray[0].workflows[0].assignedGroupId
            && row.markForDeletion == false);
      });
      return flag;
    }
  
    checkIfInqUnread(inq) {
      if (inq.readBy && inq.readBy.length && inq.readBy.length > 0 && inq.readBy.includes(this.loginUserInfo.userId)) {
        return false;
      }
      else {
        return true;
      }
    }
  
    getRowNodeId(data) {
      if (data && data._id && data.workflows && data.workflows[0] && data.workflows[0].assignedGroupId &&
        data.workflows[0].direction) {
        return data._id + '_' + data.workflows[0].assignedGroupId + '_' + data.workflows[0].direction
      }
      else if (data && data._id) {
        return data._id;
      }
  
    };
  
    updateConversationInqWithGridInq(convInquiryDetail, gridInq) {
      // update conv detail inq with Grid Inq
      let workflowAudit = convInquiryDetail.inquiry.workflowAudit;
      let userNotes = convInquiryDetail.inquiry.userNotes || [];
  
      // Keep the SkAccount Number and Branch;
      let skAccountNo = convInquiryDetail.inquiry.skAccountNo || null;
      let branch = convInquiryDetail.inquiry.branch || null;
  
      convInquiryDetail.inquiry = gridInq;
      convInquiryDetail.inquiry.skAccountNo = skAccountNo;
      convInquiryDetail.inquiry.branch = branch;
      convInquiryDetail.inquiry.workflowAudit = workflowAudit;
  
      // C153176-6110 | Update the user notes to convInquiryDetail
      try {
        if (userNotes.length > 0) {
          let noteList = userNotes.map(note => {
            // When we recieve the inquiry from backend the comment date is not in 'commentDate.$date' format that cause the date time issue.
            if (!(note.commentDate instanceof Object)) {
              let commentDate = note.commentDate;
              note.commentDate = {
                "$date": new Date(commentDate).toISOString()
              };
            }
            return note;
          });
          convInquiryDetail.inquiry.userNotes = [...noteList];
        }
      } catch (e) {
        console.error("Exception in QMA 2 while updateConversationInqWithGridInq() : ", e);
        convInquiryDetail.inquiry.userNotes = [...userNotes];
      }
      return convInquiryDetail;
    }
  
    showSympCreateChatPopup() {
      if (!this.sympcrtChatComp) {
        return;
      }
      if (this.sympcrtChatComp.resetCreateInqForm) {
        this.sympcrtChatComp.resetCreateInqForm();
      }
      this.sympcrtChatComp.displayChatRoomPopup(this);
      PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Mailboxes", "Create chat", "Click", "Create chat", 0);
    }
  
    checkIfSymphonyRow(selectedRow) {
      // in case symphony services are down still we need to proceed with selection
      // this.symphonyEnabled
      if ((this.strMailboxView == "INBOX" || this.strMailboxView == "CHAT") && selectedRow.isSymphonyChatroom && selectedRow.isSymphonyChatroom === "Y") {
        this.processSymphonyView(true);
        return true;
      } else {
        this.processSymphonyView(false);
        return false;
      }
    }
  
    processSymphonyView(isSymphonyView) {
      // disable chatview 
      /* this.isChatView = !isSymphonyView; */
      this.isSymphonyView = isSymphonyView;
      if(!isSymphonyView)// apply previously selected view to non symphony row 
      {
        if (this.viewBeforeSymphonyRowSelection.viewName  === "EMAIL_AS_A_CHATVIEW") {
          this.isChatView = true;
        }
      }
    }
    disableOtherOptionsIfChatSelected() {
       // if symphony row selected retain prior row selection which can be used for non symphony rows later on 
       if (this.isChatView) {
        this.viewBeforeSymphonyRowSelection.viewName = "EMAIL_AS_A_CHATVIEW";
      } else {
        this.viewBeforeSymphonyRowSelection.viewName = "EMAILVIEW";
      }
      this.isChatView = false;
      // disable optins like verticle view etc
      $(".tab-view-wrap").addClass("disableOptions");
    }
    enableOtherOptions() {
      $(".tab-view-wrap").removeClass("disableOptions");
    }
    // symphony change
    processChatRoomSelection(selectedRow) {
      /* if(!this.selectedMail) { 
        this.selectedMail = $.extend(true, {},SelectedMailModel);
      };
      if(!this.inqData) { $.extend(true, {},SelectedMailRequest);}; */
      this.disableOtherOptionsIfChatSelected();
      this.selectedMail = null;
      this.inqData = null;
      // C170665-1616 email as a chat loader handling in exception and api failure
      this.selectedInqId = null;
      this.assignedGroup = null;
      let selectedChatSymphony = $.extend(true, {}, SelectedMailModel);
      let selectedChatRequest = $.extend(true, {}, SelectedMailRequest);
      selectedChatRequest.streamId = selectedRow.symphonyStreamId;
      selectedChatSymphony.inquiry.subject = selectedRow.symphonyChatroomName;
      selectedChatRequest.selectedMailboxRow = selectedRow;
      // to refesh notes on right navigation
      this.selectedInquiryId = selectedRow._id;
      this.assignedGroupName = selectedRow.openGroups;
      // group list mapping need to confirm
      // selectedChatSymphony.inquiry.recipientGrps = selectedRow.assigendgroup;
      // selectedChatSymphony.inquiry.recipientSoeIds = selectedRow.toSoeIds;
      this.selectedMail = { ...selectedChatSymphony };
      this.selectedMail.inquiry._id = selectedRow._id;
      this.inqData = { ...selectedChatRequest };
      this.cdr.detectChanges();
      this.symphonyGridView.markChatAsReadInGrid(selectedRow, this);
    }
    // symphony change
    getContextMenuForSymphony(selectedRow: any) {
      let selectedRowObj = {
        "subject": "",
        "streamId": "",
        "from": "",
        "isContainsUnreadChat": false,
        "assigendgroup": "",
        "toSoeIds": [],
        "mailboxGridRow": {}
      }
      let assGrpNames = "";
      if (selectedRow.groupList) {
        selectedRow.groupList.forEach((grp: any) => {
          let selGrp = this.userDataService.loggedInUserInfo.myGroups.find(mg => mg.id === grp);
          if (selGrp) {
            assGrpNames += selGrp.groupName + ";";
          }
        });
      }
  
      selectedRowObj.streamId = selectedRow.symphonyStreamId;
      selectedRowObj.subject = selectedRow.subject;
      selectedRowObj.from = selectedRow.latestUserName,
        selectedRowObj.assigendgroup = assGrpNames;
      selectedRowObj.toSoeIds = selectedRow.symphonyChatRoomMember;
      selectedRowObj.mailboxGridRow = selectedRow;
      this.symphonyGridView.selectedRow = selectedRowObj;
      return this.symphonyGridView.getChatGridContextMenu(null, this);
    }
    processSymphonyWebSocketUpdate(symphonyNotificationList) {
      this.symphonyGridView.notifyUnreadSymphonyChats(symphonyNotificationList, this);
    }
    //C170665-363 for save column in myview
    getMyviewColumn() {
      this.myViewService.getUserViewSubj().subscribe(userView => {
        this.userFilterColumns = userView.filterColumns;
        if (userView.filterColumns) {
          this.myViewColumns = [];
          userView.filterColumns.forEach(elem => {
            this.myViewColumns.push(elem);
          });
        }
      })
    }
    //C170665-7 Save option to be included in 'My View' for the grid
    onSaveMyViewColumnConfig(): void {
      try {
        let viewNameReq = (this.dataCache.requestObj.viewName) ? this.dataCache.requestObj.viewName.toUpperCase() : this.folderName;
        let displayedColumn = this.gridColumnApi.getAllDisplayedColumns();
        let count: number = 0;
        let req: any = [];
        try {
          for (let i = 0; i < displayedColumn.length; i++) {
            let header = displayedColumn[i]['userProvidedColDef']['headerName'];
            let attributeName = displayedColumn[i]['userProvidedColDef']['field'];
            if (header) {
              attributeName = attributeName.indexOf('date') > -1 ? attributeName.split('.')[0] : attributeName;
              let myviewColumn = this.myViewColumns.find((val) => val.id == attributeName);
              let displayName = myviewColumn.label;
              let dataType = displayedColumn[i]['userProvidedColDef']['field'];
              let width = displayedColumn[i]['userProvidedColDef']['width'];
              let viewColumn = {
                "attributeName": attributeName,
                "displayOrder": count++,
                "displayName": displayName,
                "dataType": dataType.indexOf('date') > -1 ? 'Date' : 'String',
                "width": displayedColumn[i].actualWidth
              }
              req.push(viewColumn);
            }
          }
        }
        catch (exception) {
          console.log(exception);
        }
        let request = {
          "viewName": viewNameReq,
          "columnsToShow": req,
          "isViewDefault": false
        }
        this.inboxService.saveMyViewColumnPreferences(request).subscribe(res => {
          let isSuccess = JSON.stringify(res);
          if (isSuccess) {
            this.hideSavedColumntoDBModal(true);
          }
        });
      } catch (exec) {
        console.log('exception in onSaveMyViewColumnConfig ' + exec.message);
      }
    }
    isSelectionChanged(currentInq, PrevInq) {
      return !(currentInq.currentinqId === PrevInq.prevInqId &&
        currentInq.currentAssignedGroupId === PrevInq.previousAssignedGroupId);
    }
    checkIfMonitorEnabled(loginUserInfo) {
      return loginUserInfo && loginUserInfo.cvWebsocketConfig &&
        loginUserInfo.cvWebsocketConfig.wsMonitorUserList &&
        loginUserInfo.cvWebsocketConfig.wsMonitorUserList.includes(loginUserInfo.userId) ? true : false;
    }
  
    /**
     * Method to return a selected inquiry details that we pass to mail detail view.
     */
    getSelectedInquiryDetails(): any {
      let inquiryDetail = {};
      inquiryDetail["selectedMailRequest"] = this.inqData;
      inquiryDetail["selectedMail"] = this.selectedMail;
      inquiryDetail["assignedGroup"] = this.assignedGroup;
      inquiryDetail["selectedInqId"] = this.selectedInqId;
      inquiryDetail["selectedConvId"] = this.selectedConvId;
      inquiryDetail["inquiryWorkflow"] = this.inquiryWorkflow;
      inquiryDetail["inboxViewName"] = this.getViewType(this.strMailboxView);
      inquiryDetail["inquirySubject"] = this.selectedInquirySubject;
      inquiryDetail["inquiryReportedConvCount"] = this.inquiryReportedConvCount;
      inquiryDetail["inboxViewMode"] = this.inboxView;
      inquiryDetail["selectedRowData"] = this.selectedRowData;
      inquiryDetail["parentComponent"] = this.thisComponent;
  
      return inquiryDetail;
    }
  
    consumeWsUpdateQueue() {
      let wsQueueReadTimer = observableTimer(0, this.delayWorkerResponseExecutionInMs)
      .subscribe(() => {
          let webworkerPerformanceRecieveWsUpdateStart = performance.now();
          console.debug(`webworker ws queue: tab: ${this.currentTabName} reading from wsQueue after delay of ${this.delayWorkerResponseExecutionInMs} ms, current size is ${this.wsQueue.length}`);
          if (this.wsWorker && this.isWebWorkerEnable) {
            let criteria = this.gridDetailsObject.criteria ? this.gridDetailsObject.criteria : "criteria is blank";
            if (this.gridDetailsObject && this.gridDetailsObject.criteria) {
              if (this.wsQueue && this.wsQueue.length > 0) {
                if (!this.isWebWorkerBusy) {
                  //let inqIdArrayFromQueue = this.wsQueue.shift();
                  let inqIBatchFromQueue = this.wsQueue.splice(0,10);//[...this.wsQueue];
                  //let inqIBatchFromQueue:Array<Object> = [];
                  //inqIBatchFromQueue.push(inqIdArrayFromQueue);
                  //this.wsQueue = [];
                  console.debug(`webworker ws queue: tab: ${this.currentTabName} picking ws update from queue, now current size is ${this.wsQueue.length}`);
                  //console.debug(`webworker ws queue: tab: ${this.currentTabName} total ${inqIdArrayFromQueue['wsInquiryList'].length} inquiries pushed to web worker`);
                  let qmaConstant = {
                    GLOBALSEARCH: QmaConstant.typeEnum.GLOBALSEARCH.TYPE,
                    Outbox: QmaConstant.inboxGridViewType.Outbox.viewType
                  };
                  //to retain sorting of column
                  this.gridStateBeforeWsUpdate();
                  this.isWebWorkerBusy = true;
                  this.wsWorker.postMessage({
                    inqIdArray: inqIBatchFromQueue, gridDetailsObject: this.gridDetailsObject,
                    rowData: this.rowData, qmaConstant: qmaConstant,
                    delayWorkerResponseExecutionInMs: this.delayWorkerResponseExecutionInMs
                  });
                  
                  let webworkerPerformanceRecieveWsUpdatePostWebworkerStop = performance.now() - webworkerPerformanceRecieveWsUpdateStart;
                  console.debug(`webworker performance: Main thread took ${webworkerPerformanceRecieveWsUpdatePostWebworkerStop} ms before posting data to web worker :websocket tab: ${this.gridDetailsObject.name}`);
                } else {
                  console.debug(`webworker: tab: ${this.currentTabName} webworker is busy ${this.isWebWorkerBusy}`);
                }
              } else {
                console.debug(`webworker ws queue: tab: ${this.currentTabName} wsQueue is empty, unsubscribing to timer`);
                wsQueueReadTimer.unsubscribe();
              }
            } else {
              console.debug(`webworker: tab: ${this.currentTabName} grid criteria must not be blank to process ws update`);
            }
  
          } else {
            console.debug(`webworker: tab: ${this.currentTabName} worker is null or isWebWorkerEnable is false, unsubscribing to timer`);
            wsQueueReadTimer.unsubscribe();
          }
        });
    }
  
    consumeWsUpdateQueueFromNotification() {
      let wsQueueReadTimer = observableTimer(0, this.delayWorkerResponseExecutionInMs)
      .subscribe(() => {
          let webworkerPerformanceRecieveWsUpdateStart = performance.now();
          console.debug(`webworker ws queue: tab: ${this.currentTabName} reading from wsQueue after delay of ${this.delayWorkerResponseExecutionInMs} ms, current size is ${this.wsQueue.length}`);
          if (this.wsWorker && this.isWebWorkerEnable) {
            let criteria = this.gridDetailsObject.criteria ? this.gridDetailsObject.criteria : "criteria is blank";
            if (this.gridDetailsObject && this.gridDetailsObject.criteria) {
              //if (this.wsQueue && this.wsQueue.length > 0) {
                if (this.inboxService.wsQueue && this.inboxService.wsQueue.get(this.currentTabName).length > 0) {
                if (!this.isWebWorkerBusy) {
                  //let inqIdArrayFromQueue = this.wsQueue.shift();
                  let inqIBatchFromQueue = [...this.inboxService.wsQueue.get(this.currentTabName)];//[...this.wsQueue];
                  console.debug(`webworker ws queue: tab: ${this.currentTabName} processing batch of ${inqIBatchFromQueue.length}`);
                  this.inboxService.wsQueue.set(this.currentTabName, []);
                  //this.wsQueue = [];
                  console.debug(`webworker ws queue: tab: ${this.currentTabName} picking ws update from queue, now current size is ${this.wsQueue.length}`);
                  //console.debug(`webworker ws queue: tab: ${this.currentTabName} total ${inqIdArrayFromQueue['wsInquiryList'].length} inquiries pushed to web worker`);
                  let qmaConstant = {
                    GLOBALSEARCH: QmaConstant.typeEnum.GLOBALSEARCH.TYPE,
                    Outbox: QmaConstant.inboxGridViewType.Outbox.viewType
                  };
                  //to retain sorting of column
                  this.gridStateBeforeWsUpdate();
                  this.isWebWorkerBusy = true;
                  this.wsWorker.postMessage({
                    inqIdArray: inqIBatchFromQueue, gridDetailsObject: this.gridDetailsObject,
                    rowData: this.rowData, qmaConstant: qmaConstant,
                    delayWorkerResponseExecutionInMs: this.delayWorkerResponseExecutionInMs
                  });
                  
                  let webworkerPerformanceRecieveWsUpdatePostWebworkerStop = performance.now() - webworkerPerformanceRecieveWsUpdateStart;
                  console.debug(`webworker performance: Main thread took ${webworkerPerformanceRecieveWsUpdatePostWebworkerStop} ms before posting data to web worker :websocket tab: ${this.gridDetailsObject.name}`);
                } else {
                  console.debug(`webworker: tab: ${this.currentTabName} webworker is busy ${this.isWebWorkerBusy}`);
                }
              } else {
                console.debug(`webworker ws queue: tab: ${this.currentTabName} wsQueue is empty, unsubscribing to timer`);
                wsQueueReadTimer.unsubscribe();
              }
            } else {
              console.debug(`webworker: tab: ${this.currentTabName} grid criteria must not be blank to process ws update`);
            }
  
          } else {
            console.debug(`webworker: tab: ${this.currentTabName} worker is null or isWebWorkerEnable is false, unsubscribing to timer`);
            wsQueueReadTimer.unsubscribe();
          }
        });
    }
    // subscribeToWSConnectionStatus() {
    //   this.wsService.getWSConnectionStatus().subscribe(status=>{
    //     this.isWsConnectionEstablished = status;
    //   })
    // }
    // grid have some data and ws processing fails then show error message
    showWsProcessingErrorIfExists(data) {
      try{
        if (data && data.data && data.data.some(d => d.hasWsBatchProcessingError) && this.gridApi && this.gridApi.rowModel &&
          this.gridApi.rowModel.rowsToDisplay && this.gridApi.rowModel.rowsToDisplay.length > 0) {
          this.showWebSocketProcessingFailureMsg = true;
        }
      } catch(exception) {
        console.error("exception in showWsProcessingErrorIfExists()" + exception);
      }
      
    }
  
    isFilterRequired(reqObj) {
      let filterRequired = true;
      if(reqObj && reqObj.isRequestFromMailBoxStats &&  reqObj.viewName && reqObj.viewType) {
        if ((reqObj.viewName === "Outbox" && reqObj.viewType === "1") || (reqObj.viewName === "Resolved" && reqObj.viewType === "2")) {
          filterRequired = false;
        }
      } else {
        filterRequired = true;
      }
      return filterRequired;
    }
     // comment out conflicting global style of message content
    // C170665-1169 QMA2 UI Inquiry View Rendering Problem - BOT domain Emails
    commentHTMLHeadStyleIfExists(inquiryDetail) {
      if (inquiryDetail && inquiryDetail.conversationList && inquiryDetail.conversationList.length > 0) {
        inquiryDetail.conversationList.forEach((conv:any) => {
          let el = document.createElement('html');
          el.innerHTML = conv.content;
          if(el.getElementsByTagName('head') && el.getElementsByTagName('head')[0] 
           && el.getElementsByTagName('head')[0].getElementsByTagName('style') &&
           el.getElementsByTagName('head')[0].getElementsByTagName('style')[0]) {
            let documentStyle = el.getElementsByTagName('head')[0].getElementsByTagName('style')[0].innerHTML;
            let commentedStyle = '/*' + documentStyle + '*/';
            el.getElementsByTagName('head')[0].getElementsByTagName('style')[0].innerHTML = commentedStyle;
            conv.content = el.innerHTML;
          }
    
        });
      }
      return inquiryDetail;
    }
  
    addNonDBColumnBeforeAnnex(nonCUstomeColumn,storedColdefs) {
      let nonDbColumn = storedColdefs.findIndex(sc => sc.colId === nonCUstomeColumn);
      if (nonDbColumn === -1 ) {
        storedColdefs.splice(storedColdefs.findIndex(sc => sc.colId === "followUp"),
         0, { "hide": true, "pinned": null, "rowGroupIndex": null, "width": 150, "aggFunc": null,
            "pivotIndex": null, "colId": "userNotes", "headerName": "RECENT NOTE" });
      } 
    }
    createInqWithGridInq(convInquiryDetail, gridInq) {
      // update conv detail inq with Grid Inq
      let workflowAudit = gridInq.workflowAudit;
      let userNotes = gridInq.userNotes || [];
  
      // Keep the SkAccount Number and Branch;
      let skAccountNo = gridInq.skAccountNo || null;
      let branch = gridInq.branch || null;
  
      convInquiryDetail.inquiry = gridInq;
      convInquiryDetail.inquiry.id = gridInq._id;
      convInquiryDetail.inquiry.skAccountNo = skAccountNo;
      convInquiryDetail.inquiry.branch = branch;
      convInquiryDetail.inquiry.workflowAudit = workflowAudit;
  
      // C153176-6110 | Update the user notes to convInquiryDetail
      try {
        if (userNotes.length > 0) {
          let noteList = userNotes.map(note => {
            // When we recieve the inquiry from backend the comment date is not in 'commentDate.$date' format that cause the date time issue.
            if (!(note.commentDate instanceof Object)) {
              let commentDate = note.commentDate;
              note.commentDate = {
                "$date": new Date(commentDate).toISOString()
              };
            }
            return note;
          });
          convInquiryDetail.inquiry.userNotes = [...noteList];
        }
      } catch (e) {
        console.error("Exception in QMA 2 while updateConversationInqWithGridInq() : ", e);
        convInquiryDetail.inquiry.userNotes = [...userNotes];
      }
      return convInquiryDetail;
    }
  
    setFocusOnFirstCellOfSelectedRow(prevfocusedCell) {
      if(!this.gridApi || !this.gridApi.getSelectedNodes() || !this.gridApi.getSelectedNodes()[0] || !this.gridColumnApi ||
       !this.gridColumnApi.getAllDisplayedColumns()[0]) {
        return;
      }
      const selectedRowIndex = this.gridApi.getSelectedNodes()[0].rowIndex;
      const firstCol = this.gridColumnApi.getAllDisplayedColumns()[0]; 
      let colid = prevfocusedCell && prevfocusedCell.column && prevfocusedCell.column.colId?prevfocusedCell.column.colId:null;
      this.gridApi.setFocusedCell(selectedRowIndex, colid, null);
    }

    checkClearMailDetail(selectedMail) {
      if (selectedMail) {
        this.resetMailDetailView();
      }
    }

    toggleSearch(event?) {
      this.reattchach();
      this.showFilterFlag = false;
      this.advanceSearchVisible = !this.advanceSearchVisible;
      if (event == true) {
        this.loadGridData()
      }
      this.advanceSearchToggle.emit(this.advanceSearchVisible);
      // if(this.advanceSearchVisible == true) {
      //   PiwikProUtils.trackJsonDataForEvent(this.userDataService.loggedInUserInfo.pivotProConfigEnabled,this.userDataService.loggedInUserInfo.userId, "Search", "Advanced Search", "Click", "Advanced Search", 0);
      // }
    }
  
    reattchach() {
      this.cdr.reattach();
    }

    getInquiryByConversation(conversationInquiryData) {
      this.inboxService.getInquiryConversationById(conversationInquiryData).subscribe(conversationDetail => {
        // [C170665-1262] Loading: QMA loader should appear on Load email view panel only
       this.loadingConversationInProgress = false;
       // C170665-524 Performance Issue - While switching an inquiry in the grid
       this.convSelectionStarted = false;
       // C153176-5948 | Update conversation panel when select conversation from conversation history
       let selectConv = conversationDetail;
      //  selectConv.inquiry = inquiryData.inquiry;
       this.selectedMail = { ...selectConv };
       this.selectedInquirySubject = conversationDetail.conversationList[0].subject;

      //  this.inqData = inquiryData; // C153176-4660 set request object for conv view usage
      //  this.assignedGroup = assignedGrp;
      //  this.inquiryWorkflow = workflow;
      //  this.selectedConvId = selectedInqConv[0].selectedConvId;
      //  this.selectedInqId = selectedInqConv[0].selectedInqId;

      //  // C153176-4910: end conversation loading status tracking if any
      //  this.endTrackingConvLoading(inquiryData.id, assignedGrp); // C153176-4614: fix on group id vs. name mismatch
      //  this.setClcDetails(selectedInqConv[0].assignedGrpId);
       if (this.gridApi) {
         this.gridApi.deselectAll();
       }

       // C170665-673 | Update the selected inquiry details.
       this.selInquiryDetails = this.getSelectedInquiryDetails();

      //  this.gridOprService.setDetailViewConversation(this.strMailboxView, this.selectedInqId,
      //    this.selectedConvId, this.assignedGroup, [selectedInqConv[0].assignedGrpId])
     },
       error => {
         // C153176-4910: end conversation loading status tracking if any
        //  this.endTrackingConvLoading(inquiryData.id, assignedGrp); // C153176-4614: fix on group id vs. name mismatch
         console.error('Error while getting response from getInquiryConversationById()');
       });
    }
    getAssignedGroupRequestTypeTags(loginUserInfo) {
      loginUserInfo.myGroups.forEach(myGroup => {
        // adv search assigned group user list
        if (myGroup) {
          let listItem = myGroup.tags? myGroup.tags.map(item => new ListCount(item, 0)) : [];
          let textValue = new NameCount(myGroup.groupName, myGroup.groupName, myGroup.id, 0, listItem);
          this.userDataService.assignedGroups.push(textValue);
          this.userDataService.requestObj.groupList.push(textValue);          
        };
        // adv search request type
        if (myGroup.groupName && myGroup.requestTypes) {
          myGroup.requestTypes.forEach(requestType => {
            if (requestType) {
              let textValue = new TextValue(requestType, requestType, requestType);
              this.userDataService.requestTypes.push(textValue);
            }
          });
        }
        // adv search tags 
        if (myGroup.groupName && myGroup.tags) {
          myGroup.tags.forEach(tag => {
            let textValue = new TextValue(tag, tag, tag);
            this.userDataService.tags.push(textValue);
          });
        }

        // Assigned group to loggedInUser
        this.userDataService.userGroupIdList.push(myGroup.id);
      });
    }

    // code for multiLink to deal button position near Save column
    onLinkOrDelinkEntity(linkType) {
      let linkEntity = true;
      let action  = ''
      let inquiryIds : any [] = [];
      let inquiryIdConvIds : any [] = [];
      let entityDetails = this.entityDetails ? this.entityDetails : []
      let entityType = this.entityTypeParam ? this.entityTypeParam : 'deal'
      let dealIds = [];
      let taxIds = [];
      let baseAccountNumber = [];
      entityDetails.forEach(entityData => {
        if (entityData.entityId) dealIds.push(entityData.entityId)
        if (entityData.clientId) taxIds.push(entityData.clientId)
        if (entityData.baseAccountNumber) baseAccountNumber.push(entityData.baseAccountNumber)
      });
      if (linkType == 'link') {
          action  = 'link deal'
      } else {
          action = 'de-link deal'
      }
      let selectedNode = this.gridApi.getSelectedNodes()
      if (selectedNode.length > 0) {
        selectedNode.forEach(rowData => {
          inquiryIdConvIds.push(rowData.data._id + ':latestConvId')
          inquiryIds.push(rowData.data._id)
        });
      } else if (selectedNode.length == 0) {
        let inquiryData = this.selectedInqId + ':' + this.selectedConvId;
        inquiryIdConvIds.push(inquiryData)
        inquiryIds.push(this.selectedInqId)
      }
      let mandatoryFieldMessage = 'Following fields are mandatory for ' + action + ': ' ; 
      if (dealIds.length == 0 || taxIds.length == 0|| baseAccountNumber.length == 0 ){
          linkEntity = false;
          if(taxIds.length == 0) {
              mandatoryFieldMessage = mandatoryFieldMessage + 'Tax Id '
          }
          if (dealIds.length == 0) {
              mandatoryFieldMessage = mandatoryFieldMessage +'Deal Id '
          }
          if (baseAccountNumber.length == 0) {
              mandatoryFieldMessage = mandatoryFieldMessage +'Base Number'
          }
      }
      let requestObject = {
          "entityType" : entityType,
          "type" : linkType,
          "entityDetails" : entityDetails,
          "groupId" : this.groupIdFromProductFamily,
          "inquiryIds" : inquiryIds,
          "inquiryIdConvIds" : inquiryIdConvIds
      };
      let message = {
          'appName' : 'QMA',
          'status' : '',
      };
      if (linkEntity) {
          this.micrfrontendInboxService.onLinkDeLinkEntity(requestObject).subscribe(linkDetail => {
              if(linkDetail.success == 'true') {
                  message.status = 'success';
                  let displayMessage= 'The '+ action +' action on inquiry ' +inquiryIds+' and ' +entityType+' ' +dealIds+ ' are successfully sent to Droid.';
                  this.messageService.add({ severity: 'success', key: 'qmaToast', summary: '', detail: displayMessage });
                  window.parent.postMessage(message, window.location.origin);
              } else {
                  message.status = 'failed';
                  let displayMessage = 'The ' + action + ' action on inquiry ' +inquiryIds+ ' and ' +entityType+' ' +dealIds+' failed to sent to Droid.';
                  this.messageService.add({ severity: 'error', key: 'qmaToast', summary: '', detail: displayMessage });
                  window.parent.postMessage(message, window.location.origin);
              }
          },error => {
              message.status= 'failed';
              let displayMessage = 'The ' + action + ' action on inquiry ' +inquiryIds+' and ' +entityType+ ' ' +dealIds+ ' failed to sent to Droid.'
              this.messageService.add({ severity: 'error', key: 'qmaToast', summary: '', detail: displayMessage });
              window.parent.postMessage(message, window.location.origin);
          });
      } else {
          this.messageService.add({ severity: 'error', key: 'qmaToast', summary: '', detail: mandatoryFieldMessage });
          window.parent.postMessage(message, window.location.origin);
      }
    }
    public loadGridColumnsSortData(sortConfigData) {
      if(sortConfigData && sortConfigData.length) {
        this.gridColumnApi.applyColumnState({
          state:sortConfigData,
          applyOrder: true,
        })
      }
    }
    arrangeGridPrimaryColumns () {
      this.gridColumnApi.moveColumns(['expander'], 0);
      this.gridColumnApi.moveColumns(['cheveron'], 1);
      this.gridColumnApi.moveColumns(['cheveron_1'], 2);
    }
  }
  //header checkbox selection for first column on column drag
  function isFirstColumn(params) {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }
