<p-dialog [(visible)]="isOpen" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal profileModal'"
  [closable]="false" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{'height':'740px','max-width':'1350px', 'width': '90%'}"
  [resizable]="false">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between" style="padding: 20px 27px 0px 24px;">
      <span class="gfid-title" style="font-size: 20px;width: 13em;">USER GROUP ASSIGNMENT</span>
      <!-- <span *ngIf="showGroupName"
        style="font-size: 13px;color: gray;font-family: 'interstateLight';width: 71em;">{{selectedAdminGroupName}}</span> -->
      <button type="button" (click)="closeModal()" class="d-flex flex-row close qma-text" style="border:none !important;opacity:1">
        <img src="assets/Inbox/close_icon_u73.svg" style="opacity:1" alt="Close">
        <h6>CLOSE</h6>
      </button>
    </div>
  </p-header>
  <div class="gfid-body admin-gfid-padding">
    <ng-template #customLoadingTemplate>
      <div class="custom-class-loader">
        <div class="spinner-container">
          <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
            <circle cx="170" cy="170" r="160" stroke="#021e48" />
            <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
            <circle cx="170" cy="170" r="110" stroke="#021e48" />
            <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
          </svg>
        </div>
      </div>
    </ng-template>
    <ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="customLoadingTemplate">
    </ngx-loading>
    <div class="w-80">
      <p-tabView class="adminview userGroupAssignment" #profileTabs (onChange)="onTabChange($event)" [styleClass]="'user-admin-tabview'"
        [scrollable]="false" [style]="{'height':'640px','width':'90%','max-width':'1200px','margin-left':'3rem',overflow: 'hidden', 'position': 'relative'}"
        [activeIndex]="actTabIdx">
        <p-tabPanel header="USER GROUP ASSIGNMENT" class="tab-heading">
          <div class="d-flex flex-row">
            <div class="flex-row flex-nowrap align-items-end w-100">
              <label for="from" class="flex-grow label_font mt-2">User Details:</label>
            </div>
            <div style="padding-left:19.8em; padding-right: 15px; padding-top: 25px;" class="d-flex flex-row">
              <img src="assets/Inbox/info-icon.svg" class="icon-setting-out" (mouseover)="showAddtoGrpPanel($event, info_preferences)"
                (mouseout)="info_preferences.hide()" alt="Info">
              <p-overlayPanel [styleClass]="'icon-info'" [appendTo]="'body'" #info_preferences [baseZIndex]="9999">
                <div class="d-flex flex-row mb-0.3">
                  <span>
                                            <img class="qma-text icon-setting-in" src="assets/Inbox/info-icon.svg" alt="Info">
                                        </span>
                  <span class="info-content">{{infoPreferences}}</span>
                </div>
              </p-overlayPanel>
            </div>
          </div>
          <div class="d-flex flex-row mt-2 mrg-left-4em mrg-lef mrg-left-7em">
            <div class="w-20 d-flex flex-row align-items-start ">
              <label for="from" class="flex-grow label_font">SOEID: </label>
              <span style="width:330px;margin-left: 78px;">
                          <input type="text" style="width: 92% !important;" [(ngModel)]="newUserSoeid" #soeId [ngModelOptions]="{standalone: true}" placeholder="Enter SOEID..." class="w-50 t-box inp-txt-mng group-details-input-mng"
                          />
                      </span>

              <span style="padding-top:10px;">
                          <button style="width: 86px !important;opacity:1" type="button" class="close qma-text grp-details-mng-lookup"
                              (click)="callLookupSercive()">
                              <img class="qma-text" style="opacity:1" src="assets/Inbox/search_icon_selected_u42.svg" alt="Search">
                              <h6 style="font-size:14px;padding-left:3px;">Search</h6>
                          </button>
                      </span>
            </div>
          </div>
          <div *ngIf="notFound" class="alert alert-danger" role="alert">
            {{validationError}}
          </div>
          <div *ngIf="newUserSoeIdValidationFlag" class="alert alert-danger" role="alert">
            {{blankStringValidationMsg}}
          </div>
          <ng-container *ngIf="userNotFound">
            <div class="d-flex flex-row mt-2 mrg-left-4em mrg-lef mrg-left-7em">
              <div class="w-20 d-flex flex-row align-items-start ">
                <label for="from" class="flex-grow label_font">Name: </label>
                <span style="width:330px;margin-left: 78px;">
                              <input type="text" style="width: 92% !important;" #name="ngModel" [(ngModel)]="userDetails.userName" readonly
                              [ngModelOptions]="{standalone: true}"  class="w-50 t-box inp-txt-mng group-details-input-mng"
                              />
                          </span>
              </div>


            </div>
            <div class="d-flex flex-row">
              <div class="flex-row flex-nowrap align-items-end w-100">
                <label for="from" class="flex-grow label_font mt-2">User Group:</label>
              </div>
            </div>
            <div class="d-flex flex-row mt-2 mrg-left-4em mrg-lef mrg-left-7em">
              <div class="w-20 d-flex flex-row align-items-start ">
                <label for="from" class="flex-grow label_font">Role: </label>
                <span style="width:330px;margin-left: 90px;">
                  <p-dropdown [styleClass]="'prime-dd-override group-details-width'" class="drop-down group-details-inp drop-admin"
                  [(ngModel)]="selectedRole" (onChange)="groupChangesForInit($event.value)" [options]="userGroupRoleListData">
              </p-dropdown>
                          </span>
              </div>


            </div>
            <!-- <div class="d-flex flex-row mt-2 mrg-left-4em mrg-lef mrg-left-7em">
              <div class="w-20 d-flex flex-row align-items-start ">
                <label for="from" class="flex-grow label_font">Search Group: </label>
                <span style="width:330px;margin-left: 24px;">
                            <input type="text" style="width: 92% !important;" [(ngModel)]="newUserSoeid" #soeId [ngModelOptions]="{standalone: true}" placeholder="Enter SOEID..." class="w-50 t-box inp-txt-mng group-details-input-mng"
                            />
                        </span>

                <span style="padding-top:10px;">
                            <button style="width: 86px !important;opacity:1" type="button" class="close qma-text grp-details-mng-lookup"
                                (click)="callLookupSercive()">
                                <img class="qma-text" style="opacity:1" src="assets/Inbox/search_icon_selected_u42.svg">
                                <h6 style="font-size:14px;padding-left:3px;">Search</h6>
                            </button>
                        </span>
                <div *ngIf="notFound" class="alert alert-danger w-50" role="alert">
                  {{validationError}}
                </div>
                <!-- <span *ngIf="validationFlag" style="color: red;padding-top:10px;">{{validationMsg}} The userid entered is not valid </span> -->
            <!-- </div>


            </div>  -->

            <div class="p-2">
              <p-pickList [source]="allGroupDisplayList" [target]="userGroupDisplayList" sourceHeader="Available User Groups" targetHeader="Selected User Groups"
                [responsive]="true" dragdrop="true"  [sourceStyle]="{'height':'200px'}" [targetStyle]="{'height':'200px'}" [showSourceControls]="false" [showTargetControls]="false">
                <ng-template let-item pTemplate="item">
                  <div class="ui-helper-clearfix">
                    <span>{{item}}</span>
                  </div>
                </ng-template>
              </p-pickList>
            </div>

          </ng-container>


        </p-tabPanel>
        <p-footer class="fix-footer">
          <div class="d-flex flex-row footer-btn-container"></div>
          <div class="d-flex flex-row flex-wrap bottom-button-holder px-3 pb-2 pt-3 justify-content-end">
            <button type="submit" class="btn save-btn mr-4" (click)="saveGroupUserAdminDataToDB()">Submit</button>
            <button class="btn clear-btn" (click)="reset()">Reset</button>
          </div>
        </p-footer>
      </p-tabView>
    </div>
  </div>
  <p-toast></p-toast>
</p-dialog>
<!-- for sucess message -->
<p-dialog [(visible)]="displaySuccess" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal successModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Success Confirmation</h6>
    </div>
  </p-header>
  <div class="success-msg">{{successMessage}}</div>
</p-dialog>
<!--for error message-->
<p-dialog [(visible)]="errorMessage" [modal]="true" [responsive]="true" [maximizable]="false" [appendTo]="'body'" [styleClass]="'contextMenuModal failureModal ModelClose'"
  [closable]="true" [dismissableMask]="true" [baseZIndex]="10000" [closeOnEscape]="true" [style]="{width: '30%'}">
  <p-header>
    <div class="d-flex flex-row align-items-center justify-content-between">
      <h6 class="success-title">Information</h6>
    </div>
  </p-header>
  <div class="success-msg">{{errorMessage}}</div>
</p-dialog>