<div [ngClass]="styleClass">
    <div *ngIf="search" class="input-group d-flex py-3 px-2 justify-content-center">
      <input class="px-2 py-1 w-100 input-text" type="text" #myInput placeholder='{{placeholder}}' (input)="filterItem(myInput.value)">
      <div class="input-group-btn searchBox">
          <button [hidden]="!searchIcon" class="btn btn-default">
              <span>
                 <img style="width:18px;height:24px;" src='assets/core/layout/header/search_active.svg' alt="Search">
              </span>
          </button>
        </div> 
    </div>
    <div [ngStyle]="listStyle">
      <ng-scrollbar class="checklist-scrollbar" [invertY]="false" [trackX]="false" [compact]="false" [shown]="shown"
        [autoUpdate]="true" [disableOnBreakpoints]="false">
        <ul class="list-disp pl-2 pr-1">
          <li *ngFor="let item of filteredItems; let i = index" class="mb-2" [ngSwitch]="controlType">
              <div class="custom-control custom-radio" *ngSwitchCase="'radio'">
                <input #checkboxes type="radio" class="custom-control-input" [attr.value]="filteredItems[i].label" [attr.id]="assignDynamicId(filteredItems[i], i)"
                  [attr.name]="name" (click)="getSelection($event)">
                <label class="custom-control-label" [attr.for]="assignDynamicId(filteredItems[i], i)">{{filteredItems[i].label}}</label>
              </div>
            <div class="custom-control custom-checkbox" *ngSwitchDefault style="display:inline-block; text-align:center;width:100%">
              <div style="float:left">
              <input #checkboxes type="checkbox" class="custom-control-input" [attr.value]="filteredItems[i].label"
                [attr.id]="assignDynamicId(filteredItems[i],i)" [attr.name]="name" [checked]="filteredItems[i].binary" (click)="getSelection($event)" (change)="updateSelectedItems($event)" >
              <label class="custom-control-label" [attr.for]="assignDynamicId(filteredItems[i],i)">{{filteredItems[i].label}}</label>
              </div>
              <div style="text-align:end">
                <input type="text" maxlength="4" placeholder='{{placeholderin}}' [attr.value]="filteredItems[i].tagLimit" class="t-box" style="text-align:center; width:5.7em !important" (change)="getChangeInput($event, filteredItems[i].name)"/>                      
              </div>
            </div>          
          </li>
        </ul>
      </ng-scrollbar>
    </div>
  </div>