<div class="pl-3 padding-btm mobile-margin">
        <p-tabView #tabs (onChange)="handleChange($event)" [controlClose]="true" (onClose)="handleClose($event)" [styleClass]="setHeader()">
            <p-tabPanel *ngFor="let item of items; let i = index" [header]="item.text" [selected]="item.text == selectedHeaderName"
            [closable]="(i!== 0)" [headerStyleClass]="headerStyle">
                <ng-template pTemplate="header">
                    <div [ngClass]="isDragDropHighlight(item.text)? 'd-flex flex-row flex-nowrap align-items-center content-holder px-1 more-field-drop-target-tab' :
                    'd-flex flex-row flex-nowrap align-items-center content-holder px-1'">
                        <div *ngIf="item.value" class="ui-menuitem-icon custom-badge"  data-toggle="tooltip" data-placement="right" [attr.title]="item.value">
                            <span tabDragDrop  (onRowDragEventOver)="handleRowDragOver($event)"
                                (onRowDragEventDrop)="handleRowDragDrop($event)" >{{displayEllipsis(item.value)}}</span></div> 
                        <div class="ui-menuitem-text px-2 m-0" tabDragDrop 
                                (onRowDragEventOver)="handleRowDragOver($event)" (onRowDragEventDrop)="handleRowDragDrop($event)">
                            {{item.text}}
                        </div>
                    </div>
                </ng-template>                
                <div [ngSwitch]="true">
                    <div *ngSwitchCase="item.text.includes('CALENDAR')" class="dashboardWrapper">
                        <app-personal-calendar></app-personal-calendar>
                    </div>
                    <div *ngSwitchCase="item.text.includes('DASHBOARD')" class="dashboardWrapper">
                        <app-dashboard-view></app-dashboard-view>
                    </div>
                    <!-- <div *ngSwitchCase="item.text.includes('Chat')" class="dashboardWrapper">
                           
                        <app-symphony-grid-view></app-symphony-grid-view>
                    </div> -->
                    <!-- && !item.text.includes('Chat') -->
                    <div *ngSwitchCase="isInquiry(item.text) &&!item.text.includes('CALENDAR')" class="inquiryTabWrapper">
                        <!-- C153176-5308: cache multiple double-click-opened inquiries, fix missing conv count and expand/collapse-->
                        <!-- C153176-5901 | Pass selected row to poplate resolve popup-->
                        <mail-detail-view 
                          [selectedMail]="currentInquiries[item.text].selectedMail"
                          [assignedGroup]="currentInquiries[item.text].assignedGroup"
                          [selectedConvId]="currentInquiries[item.text].selectedConvId"
                          [selectedInqId]="currentInquiries[item.text].selectedInqId"
                          [inquiryWorkflow]="currentInquiries[item.text].inquiryWorkflow"
                          [inboxViewMode]="currentInquiries[item.text].inboxViewMode"
                          [inboxViewName]="currentInquiries[item.text].inboxViewName"
                          [inquirySubject]="currentInquiries[item.text].inquirySubject"
                          [inquiryReportedConvCount]="currentInquiries[item.text].inquiryReportedConvCount"
                          [selectedMailRequest]="currentInquiries[item.text].inqData"
                          [selectedRowData]="currentInquiries[item.text].selectedRowData" 
                          [mailContainerHeight]="currentInquiries[item.text].mailContainerHeight"> <!-- C153176-4983: add view name-->
                        </mail-detail-view>
                    </div>
                    <div *ngSwitchCase="!isInboxOrNewMessage(item.text) && !item.text.includes('DASHBOARD') && !isInquiry(item.text) && !item.text.includes('Chat') &&!item.text.includes('CALENDAR') " class="tab-view-holder">
                    </div>
                    <div *ngSwitchCase="isSymphonyChat(item.text)" class="inquiryTabWrapper">
                            <!-- C153176-5308: cache multiple double-click-opened inquiries, fix missing conv count and expand/collapse-->
                            <!-- C153176-5901 | Pass selected row to poplate resolve popup-->
                            <app-symphony-chat-view 
                            [assignedGroup]="currentInquiries[item.text].assignedGroup" 
                            [setChatText]="currentInquiries[item.text].chatText" 
                            [tabNameforChatView]="currentInquiries[item.text].tabNameforChatView"
                            [parentComponent]="null" 
                            [selectedMail]="currentInquiries[item.text].selectedMail" 
                            [selectedMailRequest]="currentInquiries[item.text].inqData" #symphonyChatViewComponant>
                            </app-symphony-chat-view>
                           
                        </div>
                    <!-- && !item.text.includes('Chat') -->
                    <div *ngSwitchCase="!isInboxOrNewMessage(item.text) && !item.text.includes('DASHBOARD') &&!item.text.includes('CALENDAR') && !isInquiry(item.text) && !isSymphonyChat(item.text)" class="tab-view-holder">
                            <div class="d-flex flex-row d-flex justify-content-center tab-view-wrap">
                                    <div class="d-flex flex-column justify-content-center align-item-center px-2 pr-4"
                                    [ngClass]="{'detail-view-container':inboxView === 'vertical', 'detail-view-container-horizontal':inboxView === 'horizontal'}">
                                        <a href="#" class="text-center">
                                            <!-- Jira - 4578 - Tooltip has been added -->
                                                <img  (click)="onImgClick(item.text, 'vertical')" pTooltip="Vertical view" tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text'
                                                    src="assets/core/layout/header/vertical_icon_selected.svg" [ngClass]="{'inbox-view-icon-unselected':((mailBoxView == 'horizontal')) || ((mailBoxView == 'chatview'))}" alt="Vertical">
                                            </a>
                                    </div>
                                    <div class="d-flex flex-column justify-content-center align-item-center px-2 pr-4"
                                    [ngClass]="{'grid-container-horizontal':inboxView === 'horizontal','grid-container-vertical':inboxView === 'vertical'}">
                                        <a href="#" class="text-center">
                                                <!-- Jira - 4578 - Tooltip has been added -->
                                                <img   (click)="onImgClick(item.text, 'horizontal')" pTooltip="Horizontal view" tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text'
                                                    src="assets/core/layout/header/horizontal_icon_selected.svg" 
                                                    [ngClass]="{'inbox-view-icon-unselected':((mailBoxView == 'vertical')) || ((mailBoxView == 'chatview'))}"
                                                    alt="Horizontal" >
                                            </a>
                                    </div>

                                    <!-- Enable Chat View-->
                                    <div class="d-flex flex-column justify-content-center align-item-center"
                                    [ngClass]="{'detail-view-container':inboxView === 'vertical', 'detail-view-container-horizontal':inboxView === 'horizontal'}">
                                        <a href="#" class="text-center">
                                                <img (click)="onImgClick(item.text, 'chatview')"  pTooltip="Simplified Email Message view" tooltipPosition="left" tooltipStyleClass='leftTooltip ui-tooltip-text'
                                                    src="assets/core/layout/header/chat_icon_selected.svg" 
                                                    [ngClass]="{'inbox-view-icon-unselected':((mailBoxView == 'vertical')) || ((mailBoxView == 'horizontal'))}"
                                                    alt="Chat"
                                                 >
                                            </a>
                                    </div>
                            </div>
                        <!-- C153176-4558, C153176-5107: remove binding with item.value -->
                        <inboxView-component [messageInstance]="item.text" [unreadCount]="item.value" (updatedUnReadCount)="getUpdatedUnreadCount($event)"></inboxView-component>
                    </div>
                    <!-- && !item.text.includes('Symphony Chats') -->
                    <div *ngSwitchCase="isInboxOrNewMessage(item.text) && !item.text.includes('DASHBOARD') && !isInquiry(item.text)">
                        <newMessageview-component [fromNewMessage]="triggeredFromMessage" [count]="i" [messageInstance]="item.text" [symphonyData]="item.inputData"
                          [skip_focus]="true"> <!--C153176-4475: show placeholder text with skip_focus to true-->
                        </newMessageview-component>
                    </div>
                    
                </div>
            </p-tabPanel>
        </p-tabView>
            <!-- C153176-3684 refactor mobile action footer here, C153176-4796: do not show foot in search mode --><!-- C153176-5031 null check--><!-- C153176-5266: hide action bar while in search-->
            <div *ngIf="isMobile && !inMobileSearch && selectedHeaderName && !selectedHeaderName.startsWith('NEW MESSAGE')"
                class="mailbox-footer d-flex flex-row align-items-end justify-content-between px-4">
                <div *ngFor="let item of tabDataService.footerMailboxArray" (click)="switchTab(item)" [ngClass]="{'footer-active':item.isActiveTab}">
                    <img *ngIf="item.isActiveTab" [src]="item.image" [alt]="item.label" [title]="item.label" alt="">
                    <img *ngIf="!item.isActiveTab" [src]="item.disableImage" [alt]="item.label" [title]="item.label" alt="">
                </div>
            </div>
     </div>
    <p-confirmDialog #cd header="Confirm Action" icon="pi pi-exclamation-triangle" [styleClass]="'confirmaction-section'" width="425" >
        <p-footer>
            <button type="button" pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
            <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button>
            <button type="button" pButton icon="pi pi-times" label="Cancel" (click)="cd.hide()"></button>
        </p-footer>
    </p-confirmDialog>
