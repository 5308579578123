import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DatabaseAdminService } from 'src/app/services/database-admin/database-admin.service';
import { AllModules, Module } from '@ag-grid-enterprise/all-modules';
import { MessageService } from 'primeng/api';


@Component({
  selector: 'app-database-admin',
  templateUrl: './database-admin.component.html',
  styleUrls: ['./database-admin.component.scss']
})
export class DatabaseAdminComponent implements OnInit {
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() isOpen: boolean = false;
  @Input() isDBAdmin: boolean = false;
  showSpinner: boolean = false;
  databaseCollection = [];
  selectedCollection: any;
  configCollection = []; 
  selectedConfigCollection: any;
  documentId: any; 
  refreshStatus: any;
  document: any;
  jsonStatus: boolean;
  jsonInvalid: boolean = false;
  validationFailureMessage: any;
  validationSuccessMessage: string;
  refreshReport: any;
  searchStatus: boolean;
  searchFailureMessage: any;
  modules: Module[] = AllModules;
  showGroupName: boolean = false;
  inquiryFlag: any;
  defaultColDef = {
    resizable: false,
    filter: true,
    headerComponentParams: {
      menuIcon: 'fa-bars',
      template:
      '<div class="ag-cell-label-container" role="presentation">' +
      '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
      '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
      '    <span ref="eText" class="ag-header-cell-text mailbox-header" role="columnheader" ></span>' +
      '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order" ></span>' +
      '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>' +
      '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>' +
      '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>' +
      '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
      '  </div>' +
      '</div>'
    },
    suppressColumnVirtualisation: true
  }
  columnDefs = [
    { headerName: 'Host', field: 'message', width: 490 },
    { headerName: 'Status', field: 'success', width: 68 }
  ];
  rowStyle = {
    color: "rgb(0,45,114)"
  }
  gridApi: any;
  actTabIdx: number = 0;
  // for error message
  displayError: boolean = false;
  errMsg: String = '';
  // for success message
  displaySuccessMessage :boolean = false;
  displaySuccess: boolean = false;
  successMessage: String = '';
  errorMessage: String = "Technical issue has occurred! Please contact Support!"
  constructor(private databaseAdminService: DatabaseAdminService, private messageService: MessageService) { }
  
  ngOnInit() {   
    this.configCollection = [{ label: "All", value: "All" },{ label: "Static", value: "Static" }, { label: "Cache", value: "Cache" }];
    this.getDatabaseCollections();    
  }

  getDatabaseCollections() {
    try {
      this.showSpinner = true;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.getDatabaseCollections");
      this.databaseAdminService.getAdminDbCollections().subscribe(result => {
        this.databaseCollection = result.map(collection => { return { label: collection, value: collection } });
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.getDatabaseCollections");
      }, (error) => {
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.getDatabaseCollections");
        this.messageService.add({ severity: 'error', summary: 'Database Admin', detail: `An error occured while fetching collection list. Error: ${error.message}` });
      });
    }
    catch (error) {
      this.errorMessageDialog(this.errorMessage);
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.getDatabaseCollections");
    }
  }

  search() {
    try {
      this.showSpinner = true;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.search");
      const documentId = isNaN(parseInt(this.documentId)) ? this.documentId : parseInt(this.documentId)
      this.databaseAdminService.getDBDocumentForID({ "collection": this.selectedCollection, "documentId": documentId }).subscribe(result => {
        this.document = JSON.stringify(result, undefined, 4);
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.search");
      }, (error) => {
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.search");
        this.searchStatus = true;
        this.errorMessageDialog(this.errorMessage);
      });
    }
    catch (error) {
      this.errorMessageDialog(this.errorMessage);
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.search");
    }
  }

  backup() {
    try {
      this.showSpinner = true;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.backup");
      this.databaseAdminService.createBackupCollection({ collection: this.selectedCollection }).subscribe(result => {
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.backup");
        this.successMessage = 'Created back-up for collection:' + this.selectedCollection;
        this.displaySuccess = true;
      }, (error) => {
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.backup");
        this.errorMessageDialog(this.errorMessage);
      });
    }
    catch (error) {
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.backup");
      this.errorMessageDialog(this.errorMessage);
    }
  }

  validate() {
    if (this.document) {
      try {
        JSON.parse(this.document);
        this.successMessage = 'The text contain valid json';
        this.displaySuccess = true;
      }
      catch (error) {
        this.errMsg = error.message;
        this.displayError = true;
        return false;
      }
    }
    return true;
  }

  update() {
    try {
      if (!this.document) {
        let message = "Can't update empty document";
        this.errorMessageDialog(message);
      }
      else if (this.validateJson()) {
        this.showSpinner = true;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.update");
        this.databaseAdminService.updateDBCollectionDocument({ collection: this.selectedCollection, jsonDoc: this.document }).subscribe(result => {
          this.showSpinner = false;
          console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.update");
          this.successMessage = 'Updated collection:' + this.selectedCollection;
          this.displaySuccess = true;
        }, (error) => {
          this.showSpinner = false;
          console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.update");
          let message = "An error occured while updating collection";
          this.errorMessageDialog(message);
        });
      }
    }
    catch (error) {
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.update");
      this.errorMessageDialog(this.errorMessage);
    }
  }

  refresh() {
    try {
      this.showSpinner = true;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.refresh");
      this.databaseAdminService.reloadDbCollectionUI(this.selectedConfigCollection).subscribe(result => {
        this.refreshReport = result;        
        if(this.refreshReport[0].success !== "FAIL"){
          this.displaySuccessMessage = true;
          this.refreshStatus = this.refreshReport[0].refreshType + " Refresh is Successful";
        } else{
            this.displaySuccessMessage = false;
            this.refreshStatus = this.refreshReport[0].refreshType + " Refresh Failed";
        }      
         
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.refresh");
      }, (error) => {
        this.showSpinner = false;
        console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.refresh");
        let message = "An error occured while refreshing collection";
        this.errorMessageDialog(message);
      });
    }
    catch (error) {
      this.showSpinner = false;
      console.debug(":SPINNER:: "+ this.showSpinner+" ::database-admin.refresh");
      this.errorMessageDialog(this.errorMessage);
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
  }

  reset() {
    this.modalClosed.emit();
  }

  closeModal() {
    this.modalClosed.emit();
    console.log('it is close modal ' + this.isOpen);
  }
  validateJson() {
    if (this.document) {
      try {
        JSON.parse(this.document);
      }
      catch (error) {
        console.log('The text does not contain valid json' + error.message);
        return false;
      }
    }
    return true;
  }
  onTabChange(data) {
    console.log(data);
  }
  errorMessageDialog(str: String) {
    this.errMsg = str;
    this.displayError = true;
  }
}
