import { ContextMenuService } from "../../services/contextMenu/context-menu.service";
import { ContextMenuModel, ContextSubMenuModel } from "../../services/contextMenu/context.model";
import { GridUpdateService } from "src/app/services/mailbox/mailbox-grid-update.service";
import { Injectable, ChangeDetectorRef, ElementRef } from '@angular/core';
import { InboxService } from 'src/app/services/inbox.service';
import { ContextMenuValidator } from 'src/app/services/contextMenu/context-menu-validator';
import { QmaConstant } from "src/app/constant/qma-constant";
import { AppUtils } from '../../common/utility/appUtil';
import { MessageService } from 'primeng/api';
import  * as _ from 'underscore';
import { UserDataService } from 'src/app/services/user-data.service';
import { TaskizeAuthService } from "src/app/services/taskize-auth.service";

@Injectable({
    providedIn: 'root'
})
export class ContextMenu {
    contextServiceObject: ContextMenuService;
    noCommonProcessingRegion = "No common processing regions found for multiple groups";
    noAssignOwner = "No common user found for multiple groups";
    noCommonRequestType = "No common request types found for multiple groups";
    noCommonTags = "No common tags found for multiple groups";
    noProcessingRegion = "No processing region found";
    noAssignOwnerFound = "No user found";
    enableInquirySubStatusFlag: any;
    noCommonRootCause ="No common root cause found for multiple groups";

    constructor(private userDataService: UserDataService,private contextMenuService: ContextMenuService, private inboxService: InboxService, private gridUpdateService: GridUpdateService, private messageService: MessageService,private taskizeService : TaskizeAuthService) {
        this.contextServiceObject = contextMenuService;
    }

    /* Assign Source */
    getAssignInquirySourceItems(_selectedRow: any) {
        let ITEMS = ['Email', 'Fax', 'Phone', 'Swift', 'Lock'];
        let itemName = "Assign Inquiry Source";
        let itemsArray = [];
        ITEMS.forEach((val) => {
            let action = () => {
                this.contextServiceObject.httpSetAssignInquirySource(_selectedRow, val).subscribe(res => this.logResult(itemName, res));
            }
            let subMenu = new ContextSubMenuModel(val, action);
            itemsArray.push(subMenu);
        });
        let img = this.createContextImage("assign-inquiry-source");
        return (new ContextMenuModel(itemName, itemsArray, img));
    }

     /* Assign Sub-Status */
     getAssignInquirySubStatusItems(_selectedRow: any, _loginUserInfo: any) {
         console.log(_loginUserInfo);
         let ITEMS = [];
         let inquirySubStatus: any[] = _loginUserInfo.inquirySubStatusList;
         if (inquirySubStatus != null && inquirySubStatus.length > 0) {
             for(let i=0; i<_loginUserInfo.inquirySubStatusList.length; i++){
                if(inquirySubStatus[i] === 'Completed' 
                || inquirySubStatus[i] === 'Reopen - To be reviewed'
                || inquirySubStatus[i] === 'Message to review'
                || inquirySubStatus[i] === 'New'){

                }else{
                    ITEMS.push(inquirySubStatus[i]);
                }
             }
          } else {
            console.log('Inquiey SubStatus list is null');
          }
        //let ITEMS = ['Further Research Required','Message to review','New','Pending – Claim','Pending – External','Pending – Internal','Pending – Other Internal Dept','Reopen – To be reviewed','Completed'];
        let itemName = "Assign Inquiry Sub-Status";
        let itemsArray = [];
        ITEMS.forEach((val) => {
            let action = () => {
                this.contextServiceObject.httpSetAssignInquirySubStatus(_selectedRow, val).subscribe(res => {
                    this.logResult(itemName, res);
                },
                (error) => {
                    let displayMessage = 'Something went wrong. Please contact Support'
                    this.messageService.add({ severity: 'error', key: 'qmaToast', summary: '', detail: displayMessage });
                    console.log('error while assigning inquiry Substatus:', error);
                });
            }
            let subMenu = new ContextSubMenuModel(val, action);
            itemsArray.push(subMenu);
        });
        let img = this.createContextImage("inquiry-sub-status");
        return (new ContextMenuModel(itemName, itemsArray, img));
    }

    /* Assign Owner */
    getAssignOwner(_selectedRow: any, _loginUserInfo: any, assignMenu: any) {
        let userList = [], groupUsers = [], subItemArray = [];
        let itemName = "Assign Owner";
        let img = this.createContextImage("assign-owner");
        var getGroups = this.checkAndReturnGroups(_selectedRow)
        if (getGroups.length > 1) { return this.displayCommonGroupMessage(itemName, this.noAssignOwner, img) };

        let selectedG = assignMenu.find(x => x.groupName == getGroups[0]);
        if (!(typeof selectedG == 'undefined') && !selectedG) { return this.displayCommonGroupMessage(itemName, this.noAssignOwnerFound, img) };
        try {
            selectedG.groupOwner.forEach((item) => {
                let action = () => {
                    this.contextServiceObject.httpSetAssignOwner(_selectedRow, item.value).subscribe(res => this.logResult(itemName, res));
                }
                let subMenu = new ContextSubMenuModel(item.text, action)
                groupUsers.push(subMenu)
            })
        }
        catch (exe) {

        }

        let subItem = new ContextMenuModel(_selectedRow[0].openGroups, groupUsers);
        subItemArray.push(subItem);

        return (new ContextMenuModel(itemName, subItemArray, img));
    }

    /* Processing Region */
    getAssignProcessingRegion(_selectedRow: any, _loginUserInfo: any) {
        let processingList = [], subItemArray = [], processingRegionList = [];
        let itemName = "Assign Processing Region";
        let img = this.createContextImage("assign-processing-region");
        let commonGrp = this.checkAndReturnGroups(_selectedRow);
        if (commonGrp.length > 1) {
            // C153176-5919 | Processing regions are not getting assigned to multiple groups on bulk select
            // count the number of groups the unique processing regions are present in
            let regionsCount = {};
            _loginUserInfo.myGroups.forEach(group => {
                if (commonGrp.includes(group.groupName) && group.processingRegionList) {
                    group.processingRegionList.forEach(prcRegion => {
                        regionsCount[prcRegion] = regionsCount[prcRegion] ? regionsCount[prcRegion] + 1 : 1;
                    });
                }
            });

            // find if all the groups have a common processing region or not
            _loginUserInfo.myGroups.forEach(group => {
                if (group.processingRegionList) {
                    group.processingRegionList.forEach(prcRegion => {
                        if (regionsCount[prcRegion] == commonGrp.length && processingRegionList.indexOf(prcRegion) == -1) {
                            processingRegionList.push(prcRegion);
                        }
                    });
                }
            });
            if (processingRegionList.length == 0) {
                return this.displayCommonGroupMessage(itemName, this.noCommonProcessingRegion, img);
            }
        } else {
            processingRegionList = this.getGroupSpecificData(_loginUserInfo.myGroups, commonGrp, 'processingRegionList');
        }
        processingRegionList.forEach((item) => {
            let action = () => {
                this.contextServiceObject.httpSetProcessingOwner(_selectedRow, item).subscribe(res => this.logResult(itemName, res));
            }
            let subMenu = new ContextSubMenuModel(item, action)
            processingList.push(subMenu)
        });

        if (processingList.length < 1) { return this.displayCommonGroupMessage(itemName, this.noProcessingRegion, img) }
        return (new ContextMenuModel(itemName, processingList, img));
    }

    /* Assign Request Type */
    getAssignRequestType(_selectedRow: any, _loginUserInfo: any) {
        let requestTypes: Array<string>, subItemArray = [], requestTypesSub = [];
        let itemName = "Assign Request Type";
        let img = this.createContextImage("assign-request-type");
        let commonGrp = this.checkAndReturnGroups(_selectedRow);
        if (commonGrp.length > 1) { return this.displayCommonGroupMessage(itemName, this.noCommonRequestType, img) };
        requestTypes = this.getGroupSpecificData(_loginUserInfo.myGroups, commonGrp, 'requestTypes');

        requestTypes.forEach((item) => {
            let action = () => {
                this.contextServiceObject.httpSetRequestType(_selectedRow, item).subscribe(res => this.logResult(itemName, res));
            }
            let subMenu = new ContextSubMenuModel(item, action)
            requestTypesSub.push(subMenu)
        });

        let subItem = new ContextMenuModel(_selectedRow[0].openGroups, requestTypesSub);
        subItemArray.push(subItem);

        return (new ContextMenuModel("Assign Request Type", subItemArray, img));
    }

    /* Assign Root Cause */
    getAssignRootCause(_selectedRow: any, _loginUserInfo: any) {
        let rootCause: Array<string>, subItemArray = [], rootCauseSub = [];
        let itemName = "Assign Root Cause";
        let img = this.createContextImage("assign-root-cause");
        let commonGrp = this.checkAndReturnGroups(_selectedRow);
        if (commonGrp.length > 1) { return this.displayCommonGroupMessage(itemName, this.noCommonRootCause, img) };
        rootCause = this.getGroupSpecificData(_loginUserInfo.myGroups, commonGrp, 'rootCauseList');

        rootCause.forEach((item) => {
            let action = () => {
                this.contextServiceObject.httpSetRootCause(_selectedRow, item).subscribe(res => this.logResult(itemName, res));
            }
            let subMenu = new ContextSubMenuModel(item, action)
            rootCauseSub.push(subMenu)
        });

        let subItem = new ContextMenuModel(_selectedRow[0].openGroups, rootCauseSub);
        subItemArray.push(subItem);

        return (new ContextMenuModel("Assign Root Cause", subItemArray, img));
    }


    /* Assign Tags */
    getAssignTag(_selectedRow: any, _loginUserInfo: any) {
        let tags = [], subItemArray = [], assignTagSub = [];
        let itemName = "Assign Tags";
        let img = this.createContextImage("assign-tags");
        let commonGrp = this.checkAndReturnGroups(_selectedRow)
        if (commonGrp.length > 1) { return this.displayCommonGroupMessage(itemName, this.noCommonTags, img) };
        tags = this.getGroupSpecificData(_loginUserInfo.myGroups, commonGrp, 'tags');

        tags.forEach((item) => {
            let action = () => {
                this.contextServiceObject.httpSetTags(_selectedRow, item).subscribe(res => this.logResult(itemName, res));
            }
            let subMenu = new ContextSubMenuModel(item, action)
            assignTagSub.push(subMenu)
        });

        let subItem = new ContextMenuModel(_selectedRow[0].openGroups, assignTagSub);
        subItemArray.push(subItem);

        return (new ContextMenuModel(itemName, subItemArray, img));
    }

    /* Assign To Me */
    getAssignToMe(_selectedRow: any, _loginUserInfo: any) {
        let subItemArray = [];
        let itemName = "Assign To Me";
        let img = this.createContextImage("assign-to-me");
        let userId = _loginUserInfo.userId;
        let getGroups = this.checkAndReturnGroups(_selectedRow)
        if (getGroups.length > 1) { return this.displayCommonGroupMessage(itemName, this.noAssignOwner, img) };

        let action = () => {
            this.contextServiceObject.httpSetAssignOwner(_selectedRow, userId).subscribe(res => this.logResult(itemName, res));
        }
        let subItem = new ContextSubMenuModel(_selectedRow[0].openGroups, action);
        subItemArray.push(subItem);

        return (new ContextMenuModel(itemName, subItemArray, img));
    }

    /* Assign GFID */
    getAssignGfid(_selectedRow: any, _gfpIDComp: any) {
        let action = () => {
            _gfpIDComp.selectedMenuItem = _selectedRow;
            // C170665-5 | Select the group if multiple inquiries.
            if (_selectedRow.length > 1) {
                let groupNames = _selectedRow.map(inqRow => inqRow['openGroups']);
                groupNames = _.uniq(groupNames);
                _gfpIDComp.groupName = groupNames.length == 1 ? groupNames[0] : null;
            } else {
                _gfpIDComp.groupName = _selectedRow[0]['openGroups'];
            }
            _gfpIDComp.triggerFalseClick();
        }
        let img = this.createContextImage("assign-GFPID-GFCID");
        return (new ContextSubMenuModel("Assign GFPID/GFCID", action, img));
    }

    /* Get Lock Unlock Inquiry */
    getLockUnlockInquiry(_selectedRow: any, _loginUserInfo: any, _selectedRows: any) {
        let strMenuOption = "";
        let showUnLock: boolean = false;
        let showLock: boolean = false;
        let img: any;

        // C153176-5298: When user A applies a Lock on an inquiry , user B should not be able to unlock the same.
        for (let row = 0; row < _selectedRows.length; row++) {
            if (_selectedRows[row].lock == "Y" && _selectedRows[row].lockedBy === _loginUserInfo.userName) {
                showUnLock = true;
            }
            else if (null == _selectedRows[row].lock) {
                showLock = true;
            }
        };

        if (showUnLock && showLock) {
            strMenuOption = "";
        }
        else if (showUnLock) {
            strMenuOption = "Unlock";
            img = this.createContextImage("unlock");
        }
        else if (showLock) {
            strMenuOption = "Lock";
            img = this.createContextImage("lock");
        }

        let action = () => {
            this.contextServiceObject.httpLockUnlockInquiry(_selectedRow, { action: strMenuOption }).subscribe(
                result => {
                    console.log({ action: strMenuOption } + "::", result);
                    // C153176-4954: close mobile context menu
                    this.logResult(strMenuOption, result);
                }
            );
        }
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    /* Link Exception */
    getLinkException(_selectedRow: any, _linkExceptionComp: any) {
        let action = () => {
            _linkExceptionComp.triggerFalseClick();
            _linkExceptionComp.selectedMenuItem = _selectedRow;
        }
        let img = this.createContextImage("link-exception");
        return (new ContextSubMenuModel("Link Exception", action, img));
    }

    /* Group Details */
    getGroupDetails(_selectedRow: any, _grpDetailsComp: any) {
        let action = () => {
            _grpDetailsComp.selectedMenuItem = _selectedRow;
            _grpDetailsComp.triggerFalseClick();
        }
        let img = this.createContextImage("group-details");
        return (new ContextSubMenuModel("Group Details", action, img));
    }

    /* Re-Age */
    getReAge(_selectedRow: any, _reAgeComp: any) {
        let action = () => {
            _reAgeComp.selectedMenuItem = _selectedRow;
            _reAgeComp.triggerFalseClick();
        }
        let img = this.createContextImage("re-age");
        return (new ContextSubMenuModel("Re-Age", action, img));
    }

    /* Update Memo */
    getUpdateMemo(_selectedRow: any, _updateMemoComp: any) {
        let action = () => {
            _updateMemoComp.triggerFalseClick();
            _updateMemoComp.selectedMenuItem = _selectedRow;
        }
        let img = this.createContextImage("update-memo");
        return (new ContextSubMenuModel("Update Memo", action, img));
    }

    /* Ownership */
    getOwnership(_selectedRow: any, _ownershipComp: any, assignMenu: any, _isMulti: any) {
        let action = () => {
            _ownershipComp.ownershipGroup = assignMenu;
            _ownershipComp.selectedMenuItem = _selectedRow;
            _ownershipComp.isMultiGroupSelected = _isMulti;
            _ownershipComp.triggerFalseClick();

        }
        let img = this.createContextImage("take-ownership");
        return (new ContextSubMenuModel("Ownership", action, img));
    }

    /* Ownership */
    getNotify(_selectedRow: any, _notifyComp: any, assignMenu: any) {
        let action = () => {
            _notifyComp.applyNotifyGroup(_selectedRow);
            _notifyComp.triggerFalseClick();
            _notifyComp.selectedMenuItem = _selectedRow;
        }
        let img = this.createContextImage("notify");
        return (new ContextSubMenuModel("Notify", action, img));
    }
    getSnooze(_selectedRow: any, _snoozeInq: any, assignMenu: any) {
        let action = () => {
            // _snoozeInq.notifyGroup = assignMenu;
            // _snoozeInq.triggerFalseClick();
            _snoozeInq.selectedMenuItem = _selectedRow;
            // JIRA C153176-4624 Mobile (CR) - Snooze
            //_snoozeInq.selectedOption = null;
            _snoozeInq.selectedOption = this.getCommonSnoozedDuration(_selectedRow);
            console.log("snooze");
            _snoozeInq.triggerFalseClick("Snooze Until");
            return;
        }
        let img = this.createContextImage("snooze");
        return (new ContextSubMenuModel("Snooze", action, img));
    }

    /*  Start UnSnooze Inquiry C153176-4624 Mobile (CR) - Snooze  */
    getUnSnoozeInquiry(_selectedRow: any, _loginUserInfo: any, _selectedRows: any) {
        let strMenuOption = "Un-Snooze";
        let img = this.createContextImage("snooze");
        let action = () => {
            let sub1 = this.contextServiceObject.httpSnoozeInquiry(_selectedRow, strMenuOption, null, _selectedRow).subscribe(result => {
                if (result.success) {
                    this.messageService.add({ key: 'qmaToast', severity: 'success', summary: 'Success', detail: result.message });
                }

            });
        }
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }
    /*  End UnSnooze Inquiry C153176-4624 Mobile (CR) - Snooze  */
    /* Create Rule */
    getInquiryRule(_selectedRow: any, _inquiryComp: any, getGroups: any, _loginUserInfo: any, selectedMail?: any) {
        let action = () => {
            _inquiryComp.groupName = getGroups[0];
            _inquiryComp.selectedMail = selectedMail;
            _inquiryComp.triggerFalseClick();
            _inquiryComp.selectedMenuItem = _selectedRow;
            _inquiryComp.loginUserInfo = _loginUserInfo
        }
        let img = this.createContextImage("create-rule");
        return (new ContextSubMenuModel("Create Rule", action, img));
    }

    /* Open In new Tab */
    getNewTabData(_selectedRow, _selectedMail) {
        let action = () => {
            this.inboxService.print("newWindow");
        }
        let img = this.createContextImage("open-in-new-window");
        return (new ContextSubMenuModel("Open in new window", action, img));
    }

    getAllContextMenuItems(selectedRow: any, _loginUserInfo: any, _compObj: any, selectedRows: any, type?: any, loginUserData?: any, selectedMail?: any, assignMenu?: any) {
        let contextMenuArray = new Array();
        let getGroups = this.checkAndReturnGroups(selectedRow);
        //console.log(getGroups);
        let isMultiGroupSelected = getGroups.length > 1;

        switch (type) {
            case "PENDING APPROVAL": {
                // C153176-4952: pass in 'type' for escalations
                this.preparePendingApprovalContextMenu(selectedRow, selectedRows, _compObj, loginUserData,
                    selectedMail, contextMenuArray, getGroups, isMultiGroupSelected, type);

                break;
            }
            case "INBOX": {
                this.prepareInboxContextMenu(selectedRow, contextMenuArray, _loginUserInfo, assignMenu, _compObj, getGroups, selectedMail, type, selectedRows, isMultiGroupSelected);

                break;
            }
            case "SNOOZED": {
                this.prepareSnoozedContextMenu(selectedRow, contextMenuArray, _loginUserInfo, assignMenu, _compObj, getGroups, selectedMail, type, selectedRows, isMultiGroupSelected);
                break;
            }
            case "SENT": {
                this.prepareSentContextMenu(selectedRow, contextMenuArray, _loginUserInfo, assignMenu, _compObj, getGroups, selectedMail, type, selectedRows, isMultiGroupSelected);

                break;
            }
            case "DRAFTS": {
                this.prepareDraftContextMenu(selectedRow, selectedRows, contextMenuArray);
                break;
            }
            case "PENDING APPROVALS": {
                let linkException = this.getLinkException(selectedRow, _compObj.linkExceptionComp);
                contextMenuArray.push(linkException);
                if (selectedRow.length == 1) {
                    let openInNewTab = this.getNewTabData(selectedRow, selectedMail);
                    contextMenuArray.push(openInNewTab);
                }
                if (!isMultiGroupSelected) {

                    let groupDetails = this.getGroupDetails(selectedRow, _compObj.grpDetailsComp);
                    contextMenuArray.push(groupDetails);

                    /*   let updateMemo = this.getUpdateMemo(selectedRow, _compObj.updateMemoComp);
                      contextMenuArray.push(updateMemo); */
                }
                break;
            }
            case "RESOLVED": {
                this.prepareResolvedContextMenu(selectedRow, contextMenuArray, _loginUserInfo, assignMenu, _compObj, getGroups, selectedMail, selectedRows, isMultiGroupSelected);
                break;
            }
            case "TASKIZE" : {
                this.prepareTaskizeContextMenu(selectedRow, contextMenuArray, _loginUserInfo, assignMenu, _compObj,selectedRows);
                break;
            }
            default: {
                let direction = selectedRow[0].direction;
                // C153176-5190: handle undefined direction - attempt to populate from workflow
                if (!direction && selectedRow[0].workflows && selectedRow[0].openGroups) {
                    selectedRow[0].workflows.forEach(wf => {
                        if (!direction && wf && wf.direction && wf.assignedGroupName && (selectedRow[0].openGroups.indexOf(wf.assignedGroupName) > -1)) {
                            direction = wf.direction;
                        }
                    });
                    // reassign direction back to the selected row
                    selectedRow[0].direction = direction;
                }
                if (QmaConstant.typeEnum.INQUIRYDIRECTION.IN === direction) {
                    this.prepareInboxContextMenu(selectedRow, contextMenuArray, _loginUserInfo, assignMenu, _compObj, getGroups, selectedMail, type, selectedRows, isMultiGroupSelected);
                }
                else if (QmaConstant.typeEnum.INQUIRYDIRECTION.OUT === direction) {
                    this.prepareSentContextMenu(selectedRow, contextMenuArray, _loginUserInfo, assignMenu, _compObj, getGroups, selectedMail, type, selectedRows, isMultiGroupSelected);
                }
                else if (QmaConstant.typeEnum.INQUIRYDIRECTION.PENDINGAPPROVAL === direction || QmaConstant.typeEnum.INQUIRYDIRECTION.PND_REAGE === direction) {
                    // C153176-4952: pass in 'type' for escalations
                    this.preparePendingApprovalContextMenu(selectedRow, selectedRows, _compObj, loginUserData, selectedMail,
                        contextMenuArray, getGroups, isMultiGroupSelected, type);
                }

                // Add Delete Option to Personal Mail Box
                if (QmaConstant.QMA_PERSONAL == type) {
                    let deleteEWSMenu = this.getDeleteEWSMail(selectedRow, selectedRows);
                    contextMenuArray.push(deleteEWSMenu);
                }
            }
        }
        return contextMenuArray;
    }

    private prepareInboxContextMenu(selectedRow: any, contextMenuArray: any[], _loginUserInfo: any, assignMenu: any, _compObj: any, getGroups: any, selectedMail: any, type: any, selectedRows: any, isMultiGroupSelected: boolean) {

        this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
            this.enableInquirySubStatusFlag = loginUserInfo.enableInquirySubStatusFlag;
          });
        let resolveInquiry = this.getResolveInquiry(selectedRow, _compObj.resolveInquiryComp);
        contextMenuArray.push(resolveInquiry);
        let assignInquirySource = this.getAssignInquirySourceItems(selectedRow);
        contextMenuArray.push(assignInquirySource);
        if(this.enableInquirySubStatusFlag){
            let assignInquirySubStatus = this.getAssignInquirySubStatusItems(selectedRow,_loginUserInfo);
            contextMenuArray.push(assignInquirySubStatus);
        }
        let assignOwner = this.getAssignOwner(selectedRow, _loginUserInfo, assignMenu);
        contextMenuArray.push(assignOwner);
        let processingRegion = this.getAssignProcessingRegion(selectedRow, _loginUserInfo);
        contextMenuArray.push(processingRegion);
        let requestTypes = this.getAssignRequestType(selectedRow, _loginUserInfo);
        contextMenuArray.push(requestTypes);
        let rootCause = this.getAssignRootCause(selectedRow, _loginUserInfo);
        contextMenuArray.push(rootCause);
        let assignTags = this.getAssignTag(selectedRow, _loginUserInfo);
        contextMenuArray.push(assignTags);
        let assignToMe = this.getAssignToMe(selectedRow, _loginUserInfo);
        contextMenuArray.push(assignToMe);

        this.hideShowLinkUnlinkMenu(contextMenuArray, selectedRows);
        let assignGfid = this.getAssignGfid(selectedRow, _compObj.gfidComp);
        contextMenuArray.push(assignGfid);
        let createRule = this.getInquiryRule(selectedRow, _compObj.inquiryComp, getGroups, _loginUserInfo, selectedMail);
        contextMenuArray.push(createRule);
        let lockUnlockInquiry = this.getLockUnlockInquiry(selectedRow, _loginUserInfo, selectedRows);
        if (lockUnlockInquiry.name != "") {
            contextMenuArray.push(lockUnlockInquiry);
        }
        // C153176-4952: handling escalation types and De-escalate menu if it is one of the types,
        // and if the assigned group has Supervisor row
        //C170665-121:QMA Deescalate option should appear in all the views
        if (this.contextServiceObject.checkCommonGroupAndRole('Supervisor', selectedRow, _loginUserInfo)) {
            let deescalationMenu = this.getDeEscalate(selectedRow, _loginUserInfo, selectedRows);
            contextMenuArray.push(deescalationMenu);
        }
        let followUpInquiry = this.getFollowUpInquiry(selectedRow, selectedRows);
        if (followUpInquiry.name != "") {
            contextMenuArray.push(followUpInquiry);
        }
        let linkException = this.getLinkException(selectedRow, _compObj.linkExceptionComp);
        contextMenuArray.push(linkException);
        let readInquirey = this.getReadInquiry(selectedRow, _compObj.linkExceptionComp, selectedRows);
        contextMenuArray.push(readInquirey);
        let Unread = this.getUnReadInquiry(selectedRow, _compObj.linkExceptionComp, selectedRows);
        contextMenuArray.push(Unread);

        // C153176-5632 | Add option for Mark as Non-Inquiry
        if (type !== "NON INQUIRIES") {
            let nonInquiry = this.getMarkAsNonInquiry(selectedRow, selectedRows);
            contextMenuArray.push(nonInquiry);
        } else {
            let markInquiry = this.getMarkAsInquiry(selectedRow, selectedRows);
            contextMenuArray.push(markInquiry);
        }

        if (!isMultiGroupSelected && selectedRow.length == 1) {
            let openInNewTab = this.getNewTabData(selectedRow, selectedMail);
            contextMenuArray.push(openInNewTab);
        }
        let reAge = this.getReAge(selectedRow, _compObj.reAgeComp);
        contextMenuArray.push(reAge);

        if (type !== "POTENTIAL ESCALATIONS") {
            let ownership = this.getOwnership(selectedRow, _compObj.ownershipComp, assignMenu, isMultiGroupSelected);
            contextMenuArray.push(ownership);
        }

        if (!isMultiGroupSelected) {
            let groupDetails = this.getGroupDetails(selectedRow, _compObj.grpDetailsComp);
            contextMenuArray.push(groupDetails);
            /*   let updateMemo = this.getUpdateMemo(selectedRow, _compObj.updateMemoComp);
              contextMenuArray.push(updateMemo); */
            let notify = this.getNotify(selectedRow, _compObj.notifyComp, assignMenu);
            contextMenuArray.push(notify);
        }

        if (type !== "NON INQUIRIES") {
            let snooze = this.getSnooze(selectedRow, _compObj.snoozeInq, assignMenu);
            contextMenuArray.push(snooze);
        }
    }

    private prepareSnoozedContextMenu(selectedRow: any, contextMenuArray: any[], _loginUserInfo: any, assignMenu: any, _compObj: any, getGroups: any, selectedMail: any, type: any, selectedRows: any, isMultiGroupSelected: boolean) {
        let resolveInquiry = this.getResolveInquiry(selectedRow, _compObj.resolveInquiryComp);
        contextMenuArray.push(resolveInquiry);
        let assignInquirySource = this.getAssignInquirySourceItems(selectedRow);
        contextMenuArray.push(assignInquirySource);
        let assignOwner = this.getAssignOwner(selectedRow, _loginUserInfo, assignMenu);
        contextMenuArray.push(assignOwner);
        let processingRegion = this.getAssignProcessingRegion(selectedRow, _loginUserInfo);
        contextMenuArray.push(processingRegion);
        let requestTypes = this.getAssignRequestType(selectedRow, _loginUserInfo);
        contextMenuArray.push(requestTypes);
        let rootCause = this.getAssignRootCause(selectedRow, _loginUserInfo);
        contextMenuArray.push(rootCause);
        let assignTags = this.getAssignTag(selectedRow, _loginUserInfo);
        contextMenuArray.push(assignTags);
        let assignToMe = this.getAssignToMe(selectedRow, _loginUserInfo);
        contextMenuArray.push(assignToMe);
        let assignGfid = this.getAssignGfid(selectedRow, _compObj.gfidComp);
        contextMenuArray.push(assignGfid);
        let createRule = this.getInquiryRule(selectedRow, _compObj.inquiryComp, getGroups, _loginUserInfo, selectedMail);
        contextMenuArray.push(createRule);
        let lockUnlockInquiry = this.getLockUnlockInquiry(selectedRow, _loginUserInfo, selectedRows);
        if (lockUnlockInquiry.name != "") {
            contextMenuArray.push(lockUnlockInquiry);
        }
        if (this.contextServiceObject.checkCommonGroupAndRole('Supervisor', selectedRow, _loginUserInfo)) {
            let deescalationMenu = this.getDeEscalate(selectedRow, _loginUserInfo, selectedRows);
            contextMenuArray.push(deescalationMenu);
        }
        let followUpInquiry = this.getFollowUpInquiry(selectedRow, selectedRows);
        if (followUpInquiry.name != "") {
            contextMenuArray.push(followUpInquiry);
        }
        let linkException = this.getLinkException(selectedRow, _compObj.linkExceptionComp);
        contextMenuArray.push(linkException);
        let readInquirey = this.getReadInquiry(selectedRow, _compObj.linkExceptionComp, selectedRows);
        contextMenuArray.push(readInquirey);
        let Unread = this.getUnReadInquiry(selectedRow, _compObj.linkExceptionComp, selectedRows);
        contextMenuArray.push(Unread);
        if (!isMultiGroupSelected && selectedRow.length == 1) {
            let openInNewTab = this.getNewTabData(selectedRow, selectedMail);
            contextMenuArray.push(openInNewTab);
        }
        let reAge = this.getReAge(selectedRow, _compObj.reAgeComp);
        contextMenuArray.push(reAge);
        if (!isMultiGroupSelected) {
            let groupDetails = this.getGroupDetails(selectedRow, _compObj.grpDetailsComp);
            contextMenuArray.push(groupDetails);
            let ownership = this.getOwnership(selectedRow, _compObj.ownershipComp, assignMenu, isMultiGroupSelected);
            contextMenuArray.push(ownership);
            let notify = this.getNotify(selectedRow, _compObj.notifyComp, assignMenu);
            contextMenuArray.push(notify);
        }
        // JIRA C153176-4878 Snooze - phase 2
        if (!(selectedRow.filter(sr => sr.snoozedFromWs).length > 0)) {
            let snooze = this.getSnooze(selectedRow, _compObj.snoozeInq, assignMenu);
            contextMenuArray.push(snooze);
        }

        let unSnooze = this.getUnSnoozeInquiry(selectedRow, _compObj.snoozeInq, assignMenu);
        contextMenuArray.push(unSnooze);
    }

    private prepareSentContextMenu(selectedRow: any, contextMenuArray: any[], _loginUserInfo: any, assignMenu: any, _compObj: any, getGroups: any, selectedMail: any, type: any, selectedRows: any, isMultiGroupSelected: boolean) {
        let assignInquirySource = this.getAssignInquirySourceItems(selectedRow);
        contextMenuArray.push(assignInquirySource);
        let assignOwner = this.getAssignOwner(selectedRow, _loginUserInfo, assignMenu);
        contextMenuArray.push(assignOwner);
        let processingRegion = this.getAssignProcessingRegion(selectedRow, _loginUserInfo);
        contextMenuArray.push(processingRegion);
        let requestTypes = this.getAssignRequestType(selectedRow, _loginUserInfo);
        contextMenuArray.push(requestTypes);
        let rootCause = this.getAssignRootCause(selectedRow, _loginUserInfo);
        contextMenuArray.push(rootCause);
        let assignTags = this.getAssignTag(selectedRow, _loginUserInfo);
        contextMenuArray.push(assignTags);
        let assignToMe = this.getAssignToMe(selectedRow, _loginUserInfo);
        contextMenuArray.push(assignToMe);
        this.hideShowLinkUnlinkMenu(contextMenuArray, selectedRows);
        let assignGfid = this.getAssignGfid(selectedRow, _compObj.gfidComp);
        contextMenuArray.push(assignGfid);
        let createRule = this.getInquiryRule(selectedRow, _compObj.inquiryComp, getGroups, _loginUserInfo, selectedMail);
        contextMenuArray.push(createRule);
        /* if (type !== "SENT" && type !== "RESOLVED") {
            let lockUnlockInquiry = this.getLockUnlockInquiry(selectedRow, _loginUserInfo, selectedRows);
            if (lockUnlockInquiry.name != "") {
                contextMenuArray.push(lockUnlockInquiry);
            }
        } */
        // C153176-4952: handling escalation types and De-escalate menu if it is one of the types,
        // and if the assigned group has Supervisor row
        //C170665-121:QMA Deescalate option should appear in all the views
        if (this.contextServiceObject.checkCommonGroupAndRole('Supervisor', selectedRow, _loginUserInfo)) {
            let deescalationMenu = this.getDeEscalate(selectedRow, _loginUserInfo, selectedRows);
            contextMenuArray.push(deescalationMenu);
        }
        let followUpInquiry = this.getFollowUpInquiry(selectedRow, selectedRows);
        if (followUpInquiry.name != "") {
            contextMenuArray.push(followUpInquiry);
        }
        let linkException = this.getLinkException(selectedRow, _compObj.linkExceptionComp);
        contextMenuArray.push(linkException);

        if (!isMultiGroupSelected && selectedRow.length == 1) {
            let openInNewTab = this.getNewTabData(selectedRow, selectedMail);
            contextMenuArray.push(openInNewTab);
        }
        // C170665-325 | Hide the Resolve option from SENT folder Context Menu.
        // let resolveInquiry = this.getResolveInquiry(selectedRow, _compObj.resolveInquiryComp);
        // contextMenuArray.push(resolveInquiry);

        if (!isMultiGroupSelected) {
            let groupDetails = this.getGroupDetails(selectedRow, _compObj.grpDetailsComp);
            contextMenuArray.push(groupDetails);

            /*   let updateMemo = this.getUpdateMemo(selectedRow, _compObj.updateMemoComp);
              contextMenuArray.push(updateMemo); */
            let notify = this.getNotify(selectedRow, _compObj.notifyComp, assignMenu);
            contextMenuArray.push(notify);
        }
    }

    private prepareDraftContextMenu(selectedRow: any, selectedRows: any, contextMenuArray: any[]) {
        let deleteDraftMenu = this.getDeleteInquiry(selectedRow, selectedRows);
        contextMenuArray.push(deleteDraftMenu);
    }

    private prepareResolvedContextMenu(selectedRow: any, contextMenuArray: any[], _loginUserInfo: any, assignMenu: any, _compObj: any, getGroups: any, selectedMail: any, selectedRows: any, isMultiGroupSelected: boolean) {
        let assignInquirySource = this.getAssignInquirySourceItems(selectedRow);
        contextMenuArray.push(assignInquirySource);
        let assignOwner = this.getAssignOwner(selectedRow, _loginUserInfo, assignMenu);
        contextMenuArray.push(assignOwner);
        let processingRegion = this.getAssignProcessingRegion(selectedRow, _loginUserInfo);
        contextMenuArray.push(processingRegion);
        let requestTypes = this.getAssignRequestType(selectedRow, _loginUserInfo);
        contextMenuArray.push(requestTypes);
        let rootCause = this.getAssignRootCause(selectedRow, _loginUserInfo);
        contextMenuArray.push(rootCause);
        let assignTags = this.getAssignTag(selectedRow, _loginUserInfo);
        contextMenuArray.push(assignTags);
        let assignToMe = this.getAssignToMe(selectedRow, _loginUserInfo);
        contextMenuArray.push(assignToMe);
        this.hideShowLinkUnlinkMenu(contextMenuArray, selectedRows);
        let assignGfid = this.getAssignGfid(selectedRow, _compObj.gfidComp);
        contextMenuArray.push(assignGfid);
        let createRule = this.getInquiryRule(selectedRow, _compObj.inquiryComp, getGroups, _loginUserInfo, selectedMail);
        contextMenuArray.push(createRule);
        let linkException = this.getLinkException(selectedRow, _compObj.linkExceptionComp);
        contextMenuArray.push(linkException);
        let readInquirey = this.getReadInquiry(selectedRow, _compObj.linkExceptionComp, selectedRows);
        contextMenuArray.push(readInquirey);
        let Unread = this.getUnReadInquiry(selectedRow, _compObj.linkExceptionComp, selectedRows);
        contextMenuArray.push(Unread);
        let reopen = this.getReopenInquiry(selectedRow, selectedRows);
        contextMenuArray.push(reopen);
        if (selectedRow.length == 1) {
            let openInNewTab = this.getNewTabData(selectedRow, selectedMail);
            contextMenuArray.push(openInNewTab);
        }
        if (!isMultiGroupSelected && selectedRow.length == 1) {
            let groupDetails = this.getGroupDetails(selectedRow, _compObj.grpDetailsComp);
            contextMenuArray.push(groupDetails);
        }
    }
    // C153176-4952: add 'type' arg to determine escalation type
    private preparePendingApprovalContextMenu(selectedRow: any, selectedRows: any, _compObj: any, loginUserData: any, selectedMail: any,
        contextMenuArray: any[], getGroups: any, isMultiGroupSelected: boolean, type: any) {
        this.hideShowLinkUnlinkMenu(contextMenuArray, selectedRows);
        let approveInquiryMenu = this.getApproveInquiry(selectedRow, selectedRows, _compObj.actionPopupComp, loginUserData, selectedMail);
        contextMenuArray.push(approveInquiryMenu);
        let createRule = this.getInquiryRule(selectedRow, _compObj.inquiryComp, getGroups, loginUserData, selectedMail);
        contextMenuArray.push(createRule);
        // C153176-4952: handling escalation types and De-escalate menu if it is one of the types,
        // and if the assigned group has Supervisor row
        //C170665-121:QMA Deescalate option should appear in all the views
        if (this.contextServiceObject.checkCommonGroupAndRole('Supervisor', selectedRow, loginUserData)) {
            let deescalationMenu = this.getDeEscalate(selectedRow, loginUserData, selectedRows);
            contextMenuArray.push(deescalationMenu);
        }
        let linkException = this.getLinkException(selectedRow, _compObj.linkExceptionComp);
        contextMenuArray.push(linkException);
        if (selectedRow.length == 1) {
            let openInNewTab = this.getNewTabData(selectedRow, selectedMail);
            contextMenuArray.push(openInNewTab);
        }
        if (!isMultiGroupSelected) {
            let groupDetails = this.getGroupDetails(selectedRow, _compObj.grpDetailsComp);
            contextMenuArray.push(groupDetails);
            /*   let updateMemo = this.getUpdateMemo(selectedRow, _compObj.updateMemoComp);
              contextMenuArray.push(updateMemo); */
        }
        let rejectInquiryMenu = this.getRejectInquiry(selectedRow, selectedRows,  _compObj.actionPopupComp, loginUserData, selectedMail);
        contextMenuArray.push(rejectInquiryMenu);
    }

    getUnReadInquiry(_selectedRow: any, _loginUserInfo: any, _selectedRows: any) {
        let strMenuOption = "Mark as Unread";
        let action: any;
        if (_selectedRow[0].type && _selectedRow[0].type == 'conversatationHistory') {
            action = this.getConvHistoryReadInquiry(_selectedRow, strMenuOption, _selectedRows, false);
        } else {
            action = () => {
                this.contextServiceObject.httpMarkUnreadInquiry(_selectedRow, { action: strMenuOption }, _selectedRows).subscribe(
                    result => {
                        console.log({ action: strMenuOption } + "::", result)
                        let unreadInqIdList = new Array();
                        let unreadInqIds = new Array();
                        _selectedRows.forEach(selectedRow => {
                            unreadInqIdList.push({ inqId: selectedRow._id, agrpId: selectedRow.workflows[0].assignedGroupId });
                            unreadInqIds.push({ inqId: selectedRow._id, status: 'unread' });
                        });
                        if (result.processingstatus) {
                            this.gridUpdateService.setunreadInqIds(unreadInqIdList);
                            this.gridUpdateService.setReadUnreadInqIds(unreadInqIds);
                        }
                        this.logResult(strMenuOption, result); //To close context Menu mobile
                    }
                );
            };
        }
        let img = this.createContextImage("mark-as-unread");
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }
    // to mark Inq uread without context menu
    markUnReadInquiry(_selectedRow: any, _loginUserInfo: any, _selectedRows: any) {
        const strMenuOption = "Mark as Unread";
        this.contextServiceObject.httpMarkUnreadInquiry(_selectedRow, { action: strMenuOption }, _selectedRows).subscribe(
            result => {
                console.log({ action: strMenuOption } + "::", result)
                let unreadInqIdList = new Array();
                let unreadInqIds = new Array();
                _selectedRows.forEach(selectedRow => {
                    unreadInqIdList.push({ inqId: selectedRow._id, agrpId: selectedRow.workflows[0].assignedGroupId });
                    unreadInqIds.push({ inqId: selectedRow._id, status: 'unread' });
                });
                if (result.processingstatus) {
                    this.gridUpdateService.setunreadInqIds(unreadInqIdList);
                    this.gridUpdateService.setReadUnreadInqIds(unreadInqIds);
                }
                this.logResult(strMenuOption, result); //To close context Menu mobile
            }
        );
    }

    getReadInquiry(_selectedRow: any, _loginUserInfo: any, _selectedRows: any) {
        let action: any;
        let strMenuOption = "Mark as Read";
        if (_selectedRow[0].type && _selectedRow[0].type == 'conversatationHistory') {
            action = this.getConvHistoryReadInquiry(_selectedRow, strMenuOption, _selectedRows, true);
        } else {
            action = () => {
                // C170665-544 2 additional calls post getAllInquiryConversation
                this.contextServiceObject.httpMarkReadInquiry(_selectedRow, { action: strMenuOption }, _selectedRows,_loginUserInfo).subscribe(
                    result => {
                        console.log({ action: strMenuOption } + "::", result);
                        let readInqIdList = new Array();
                        let readInqIds = new Array();
                        _selectedRows.forEach(selectedRow => {
                            readInqIdList.push({ inqId: selectedRow._id, agrpId: selectedRow.workflows[0].assignedGroupId });
                            readInqIds.push({ inqId: selectedRow._id, status: 'read' });
                        });
                        if (result.processingstatus) {
                            this.gridUpdateService.setreadInqIds(readInqIdList);
                            this.gridUpdateService.setReadUnreadInqIds(readInqIds);
                        }
                        this.logResult(strMenuOption, result);       //To close context Menu mobile             
                    }
                );
            }
        }

        let img = this.createContextImage("mark-as-read");
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    getConvHistoryReadInquiry(_selectedRow, strMenuOption, _selectedRows, markAsRead) {
        let action = () => {
            this.contextServiceObject.httpMarkConvHistoryReadInquiry(_selectedRow, { action: strMenuOption }, _selectedRows, markAsRead).subscribe(
                result => {
                    if (result) {
                        this.gridUpdateService.setReadUnreadConvInqIds({ 'data': _selectedRow, 'readStatus': markAsRead });
                    }
                }
            );
        };

        return action;
    }

    // C153176-4985: mark conversation history read or unread
    markConvHistoryAsReadUnread(_selectedRow, strMenuOption, _selectedRows, markAsRead) {
        this.contextServiceObject.httpMarkConvHistoryReadInquiry(_selectedRow, { action: strMenuOption }, _selectedRows, markAsRead).subscribe(
            result => {
                if (result) {
                    this.gridUpdateService.setReadUnreadConvInqIds({ 'data': _selectedRow, 'readStatus': markAsRead });
                }
            }
        );
    }

    getDeleteInquiry(_selectedRow: any, _selectedRows: any) {
        let strMenuOption = "Delete";

        let action = () => {
            this.contextServiceObject.httpDeleteInquiry(_selectedRow, { action: strMenuOption }, _selectedRows).subscribe(
                result => {
                    console.log({ action: strMenuOption } + "::", result);
                    let draftIdList = new Array();
                    _selectedRows.forEach(selectedRow => {
                        draftIdList.push(selectedRow._id);
                    });

                    if (result.processingstatus == "true") {
                        this.gridUpdateService.setdeletedDraftIds(draftIdList);
                    }

                }
            );
        }
        let img = this.createContextImage("delete");
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    // C153176-5632 | Method to perform Mark as Non-Inquiry
    getMarkAsNonInquiry(_selectedRow: any, _selectedRows: any) {
        let strMenuOption = "Mark as Non-Inquiry";

        let action = () => {
            this.contextServiceObject.httpMarkAsNonInquiry(_selectedRow, { action: strMenuOption }, _selectedRows).subscribe(
                result => {
                    console.log({ action: strMenuOption } + "::", result);
                    this.logResult(strMenuOption, result); //To close context Menu mobile
                }
            );
        }
        let img = this.createContextImage("mark-as-non-inquiry");
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    // C153176-5632 | Method to perform Mark as Inquiry
    getMarkAsInquiry(_selectedRow: any, _selectedRows: any) {
        let strMenuOption = "Mark as Inquiry";

        let action = () => {
            this.contextServiceObject.httpMarkAsInquiry(_selectedRow, { action: strMenuOption }, _selectedRows).subscribe(
                result => {
                    console.log({ action: strMenuOption } + "::", result);
                    this.logResult(strMenuOption, result); //To close context Menu mobile
                }
            );
        }
        let img = this.createContextImage("mark-as-inquiry");
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    getFollowUpInquiry(_selectedRow: any, _selectedRows: any) {
        let strMenuOption = "";
        let showUnFollow: boolean = false;
        let showFollow: boolean = false;
        for (let row = 0; row < _selectedRows.length; row++) {
            if ((_selectedRows[row].followUp && _selectedRows[row].followUp && _selectedRows[row].followUp == "Y") ||
                (_selectedRows[row].followUp && _selectedRows[row].followUp && _selectedRows[row].followUp.flag
                    && _selectedRows[row].followUp.flag === "Generic")
            ) {
                showUnFollow = true;
            }
            else {
                showFollow = true;
            }
        };
        if (showUnFollow && showFollow) {
            strMenuOption = "";
        }
        else if (showUnFollow) {
            strMenuOption = "Unfollow";
        }
        else if (showFollow) {
            strMenuOption = "Follow Up";
        }
        if (showFollow) {
            let action = () => {
                this.contextServiceObject.httpFollowUpInquiry(_selectedRow, { action: strMenuOption }, _selectedRows).subscribe(
                    result => {
                        this.logResult(strMenuOption, result); //To close context Menu mobile
                    }
                );
            }
            let img = this.createContextImage("follow-up");
            return (new ContextSubMenuModel(strMenuOption, action, img));
        } else if (showUnFollow) {
            let action = () => {
                this.contextServiceObject.httpUnFollowInquiry(_selectedRow, { action: "RemoveFollowUp" }, _selectedRows).subscribe(
                    result => {
                        this.logResult(strMenuOption, result); //To close context Menu mobile
                    }
                );
            }
            let img = this.createContextImage("unfollow");
            return (new ContextSubMenuModel(strMenuOption, action, img));
        } else {
            let action = () => { this.logResult(strMenuOption, ""); }; //To close context Menu mobile
            let img = this.createContextImage("follow-up");
            return (new ContextSubMenuModel(strMenuOption, action, img));
        }
    }

    getApproveInquiry(_selectedRow: any, _selectedRows: any, actionPopupComp: any, loginUserData?: any, _selectedMail?: any) {
        let strMenuOption = "Approve";

        let action = () => {
            this._getApproveInquiry(strMenuOption, _selectedRow, _selectedRows, actionPopupComp, loginUserData, _selectedMail);
        }
        let img = this.createContextImage("approve");
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    _getApproveInquiry(strMenuOption: string, _selectedRow: any, _selectedRows: any, actionPopupComp: any, loginUserData?: any, _selectedMail?: any) {
        let validator = new ContextMenuValidator();
        let result = validator.validateApproveAction(strMenuOption, _selectedRows, actionPopupComp, loginUserData, _selectedMail);
        if (result && result.res) {
            //commented not required follow up call
            /* this.contextServiceObject.httpApproveInquiry(_selectedRow, {action:strMenuOption}, _selectedRows, loginUserData, _selectedMail).subscribe(
            (r) => console.log({action:strMenuOption} + "::", r)); */
        } else if (actionPopupComp) {
            actionPopupComp.open('Validation Error',
                validator.errorMessage ? validator.errorMessage : result.message, false, true, false, false, false);
        }
    }

    getRejectInquiry(_selectedRow: any, _selectedRows: any, actionPopupComp: any, loginUserData?: any, _selectedMail?: any) {
        let strMenuOption = "Reject";

        let action = () => {
            this._getRejectInquiry(strMenuOption, _selectedRow, _selectedRows, actionPopupComp, loginUserData, _selectedMail);
        }
        let img = this.createContextImage("reject");
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    _getRejectInquiry(strMenuOption: string, _selectedRow: any, _selectedRows: any, actionPopupComp: any, loginUserData?: any, _selectedMail?: any) {
        let validator = new ContextMenuValidator();
        let result = validator.validateRejectAction(strMenuOption, _selectedRows, actionPopupComp, loginUserData, _selectedMail);
        if (result && result.res) {
            /*this.contextServiceObject.httpRejectInquiry(_compObj, _selectedRow, { action: strMenuOption }, _selectedRows, loginUserData).subscribe(
                result => console.log({ action: strMenuOption } + "::", result)
            );*/
        } else if (actionPopupComp) {
            actionPopupComp.open('Validation Error',
                validator.errorMessage ? validator.errorMessage : result.message, false, true, false, false, false);
        }   
    }

    getReopenInquiry(_selectedRow: any, _selectedRows: any) {
        let strMenuOption = "Reopen";
        let str = ["Reopen"];
        let action = () => {
            this.contextServiceObject.httpReopenInquiry(_selectedRow, str).subscribe(
                result => console.log({ action: strMenuOption } + "::", result)
            );
        }
        let img = this.createContextImage("reopen");
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    getResolveInquiry(_selectedRow: any, _resolveComp: any) {
        let img = this.createContextImage("resolved");
        let action = () => {
            this._getResolveInquiry(_selectedRow, _resolveComp);
        }
        return (new ContextSubMenuModel("Resolve", action, img));
    }

    _getResolveInquiry(_selectedRow: any, _resolveComp: any) {
        _resolveComp.selectedMenuItem = _selectedRow;
        _resolveComp.triggerFalseClick();
    }

    private nonResolveTabs = ['RESOLVED', 'PENDING APPROVALS', 'PENDING APPROVAL', 'DRAFTS'];

    handleResolveInquiry(tabName, _selectedRow: any, _resolveComp: any) {
        if (!this.nonResolveTabs.includes(tabName) && _selectedRow && _selectedRow.length) {
            this._getResolveInquiry(_selectedRow, _resolveComp);
        }
    }

    markInquiryAsRead(_selectedRow: any, _loginUserInfo: any, _selectedRows: any, cdr: ChangeDetectorRef) {
        let strMenuOption = "Mark as Read";
        this.contextServiceObject.httpMarkReadInquiry(_selectedRow, { action: strMenuOption }, _selectedRows, _loginUserInfo).subscribe(
            result => {
                console.log({ action: strMenuOption } + "::", result)
                if (result && result.processingstatus) {
                    let readInqIdList = new Array();
                    let readInqIds = new Array();
                    _selectedRows.forEach(selectedRow => {
                        readInqIdList.push({ inqId: selectedRow._id, agrpId: selectedRow.workflows[0].assignedGroupId });
                        readInqIds.push({ inqId: selectedRow._id, status: 'read' });
                    });
                    if (result.processingstatus) {
                        this.gridUpdateService.setreadInqIds(readInqIdList);
                        this.gridUpdateService.setReadUnreadInqIds(readInqIds);
                    }
                    if (cdr) {
                        cdr.detectChanges();
                    }
                }
            }
        ), error => {
            console.error("QMA 2.0 : Error while Mark Inquiry As Read ",error);
        };

    }
    getDeEscalate(_selectedRow: any, _loginUserInfo: any, _selectedRows: any) {
        let strMenuOption = "De-escalate";
        let img: any = this.createContextImage("deescalate");;

        let action = () => {
            this.contextServiceObject.httpDeEscalateInquiry(_selectedRow, strMenuOption).subscribe(
                result => console.log({ action: strMenuOption } + "::", result)
            );
        }
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    private removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject = {};

        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    private checkAndReturnGroups(_selectedRow: any) {
        let commonGroup = _selectedRow.map(val => val.openGroups).filter(function (elem, index, self) {
            return index === self.indexOf(elem);
        })
        return commonGroup;
    }

    private displayCommonGroupMessage(_text: string, _commonMsg: string, _img: any) {
        let subM = [new ContextSubMenuModel(_commonMsg)];
        return (new ContextMenuModel(_text, subM, _img));
    }

    private getGroupSpecificData(_grp: any, _lookUp: any, _node: any): Array<string> {
        let _arr = []
        _grp.forEach((val) => {
            let grpName = val.groupName;
            if (_lookUp.includes(grpName)) {
                if (val[_node]) {
                    _arr.push(val[_node]);
                }
            }
        })
        return [].concat.apply([], _arr);
    }

    private logResult(_name: string, _result: any) {
        console.log(_name + " :: " + _result);
        /* Trigger False click on context menu to close the context Menu */
        if (window.innerWidth <= QmaConstant.MOBILE_WIDTH) {
            let d: HTMLElement = document.querySelector('.mobile_header_text');
            d.click();
        }
    }

    private createContextImage(_src: any) {
        return '<img border="0" src="assets/context/' + _src + '.svg"/>';
    }

    getLinkInquiry(_selectedRow: any) {
        console.log('Selected Rows for Link :', _selectedRow);
        let inquiryIds = [];
        let linkInquiryData = [];
        _selectedRow.forEach(row => {
            inquiryIds.push(row._id);
            let obj = {
                "inquiryId": row._id,
                "groupId": row.workflows[0].assignedGroupId
            };
            linkInquiryData.push(obj);
        });
        let data = {
            "inquiryIds": inquiryIds,
            "linkId": 0,
            "action": "Link",
            "linkInquiryData": linkInquiryData
        }
        console.log('Link data :', data);
        let img = this.createContextImage("link");
        let action = () => {
            this.contextServiceObject.httpLinkInquiry(data).subscribe(result => {
                console.log("result of Link Inquiry:", result);
                if (result.processingstatus === "true") {
                    this.messageService.add({ severity: 'success', key: 'qmaToast', summary: 'Link Inquiry', detail: 'Inquiries Linked successfully' });
                }
            },
                (error) => {
                    console.log('error while Link Inquiry :', error);
                }
            );
        }
        return (new ContextSubMenuModel("Link", action, img));
    }

    getDeLinkInquiry(_selectedRow: any) {
        console.log('Selected Rows for De-Link :', _selectedRow);
        let inquiryIds = [];
        let linkInquiryData = [];
        _selectedRow.forEach(row => {
            inquiryIds.push(row._id);
            let obj = {
                "inquiryId": row._id,
                "groupId": row.workflows[0].assignedGroupId
            };
            linkInquiryData.push(obj);
        });
        let data = {
            "inquiryIds": inquiryIds,
            "linkId": _selectedRow[0].linkId,
            "action": "De-Link",
            "linkInquiryData": linkInquiryData
        }
        console.log('Link data :', data);
        let img = this.createContextImage("delink");
        let action = () => {
            this.contextServiceObject.httpLinkInquiry(data).subscribe(result => {
                console.log("result of De-Link Inquiry:", result);
                if (result.processingstatus === "true") {
                    this.messageService.add({ severity: 'success', key: 'qmaToast', summary: 'De-Link Inquiry', detail: 'Inquiries De-Linked successfully' });

                }
            },
                (error) => {
                    console.log('error while De-Link Inquiry :', error);
                }
            );
        }
        return (new ContextSubMenuModel("De-Link", action, img));
    }

    hideShowLinkUnlinkMenu(contextMenuArray: any[], selectedRows: any) {
        let linkIds = [];
        let showLinkOnly = true;
        let isFirstLoop = true;
        //if only one inquiry is selected then no need to show link unlink 

        if (selectedRows.length > 1) {
            for (let idx = 0; idx < selectedRows.length; idx++) {
                let dataItem = selectedRows[idx];
                if (!AppUtils.isUndefinedNullOrBlankStrAfterTrim(dataItem.workflows[0].linkId)) {
                    if (linkIds.indexOf(dataItem.workflows[0].linkId) === -1) {
                        linkIds.push(dataItem.workflows[0].linkId);
                    }
                }
                else {
                    showLinkOnly = false;
                }
            }
            if (linkIds.length === 1 && showLinkOnly)// if only inquiry selcted have combination of single link id and empity link id
            {
                let deLinkInquiry = this.getDeLinkInquiry(selectedRows);
                contextMenuArray.push(deLinkInquiry);
            }
            else if ((linkIds.length === 1 || linkIds.length === 0) && !showLinkOnly) // if inquiry selcted have either single link id or empity link id only 
            {
                let linkInquiry = this.getLinkInquiry(selectedRows);
                contextMenuArray.push(linkInquiry);
            }
        }
        else if (!(AppUtils.isUndefinedOrNull(selectedRows)) && selectedRows.length === 1) {
            let dataItem = selectedRows[0];
            if ((!AppUtils.isUndefinedOrNull(dataItem) && !AppUtils.isUndefinedOrNull(dataItem.workflows) && dataItem.workflows[0].linkId > 0) || (!AppUtils.isUndefinedOrNull(dataItem) && !AppUtils.isUndefinedOrNull(dataItem.workflows) && dataItem.linkId > 0)) {
                let deLinkInquiry = this.getDeLinkInquiry(selectedRows);
                contextMenuArray.push(deLinkInquiry);
            }
            else {
                //hide both
            }
        }
        else {
            //hide both
        }
    }
    // JIRA C153176-4624 Mobile (CR) - Snooze
    getCommonSnoozedDuration(selectedRow) {
        if (selectedRow.length > 1) {
            return selectedRow.filter(s => s.snoozeDuration === selectedRow[0].snoozeDuration).length === selectedRow.length
                ? selectedRow[0].snoozeDuration : null;
        }
        if (selectedRow.length === 1) {
            return selectedRow[0].snoozeDuration;
        }
        return null;
    }

    /**
     * C153176-4796: right-swipe context menu for mobile
     */
    getRSwipeContextMenuItems(selectedRow: any, _loginUserInfo: any, _compObj: any, selectedRows: any, type?: any, loginUserData?: any, selectedMail?: any, assignMenu?: any) {
        let contextMenuArray = new Array();
        let getGroups = this.checkAndReturnGroups(selectedRow);
        let isMultiGroupSelected = getGroups.length > 1;

        if (type === 'PENDING APPROVAL' || type === 'PENDING APPROVALS') {
            this.prepareRSwipePendingApprovalContextMenu(selectedRow, selectedRows, _compObj, loginUserData,
                selectedMail, contextMenuArray, getGroups, isMultiGroupSelected);
        } else if (type === 'DRAFTS' || type === 'SENT') {
            // do nothing
        } else {
            this.prepareRSWipeDefaultContextMenu(selectedRow, selectedRows, _compObj, loginUserData,
                selectedMail, contextMenuArray, getGroups, isMultiGroupSelected);
        }
        return contextMenuArray;
    }

    /**
     * C153176-4796: right-swipe context menu for mobile - default, non-pending approval, non-drafts, non-sent
     */
    private prepareRSWipeDefaultContextMenu(selectedRow, selectedRows, _compObj, loginUserData,
        selectedMail, contextMenuArray, getGroups, isMultiGroupSelected) {
        // C153176-4796: check whether any selectedRow (inquiry) is marked READ/UnREAD and create menu items accordingly
        let currentUserId = loginUserData ? loginUserData.userId : null;
        let isRead = false;
        if (selectedRow && selectedRow.length) {
            selectedRow.forEach(lst => {
                if (lst && lst.readBy && currentUserId && lst.readBy.includes(currentUserId)) {
                    isRead = true;
                }
            });
        }
        if (!isRead) {
            let readInquirey = this.getReadInquiry(selectedRow, _compObj.linkExceptionComp, selectedRows);
            contextMenuArray.push(readInquirey);
        } else {
            let unread = this.getUnReadInquiry(selectedRow, _compObj.linkExceptionComp, selectedRows);
            contextMenuArray.push(unread);
        }
    }

    /**
     * C153176-4796: right-swipe context menu for mobile - pending approval
     */
    private prepareRSwipePendingApprovalContextMenu(selectedRow, selectedRows, _compObj, loginUserData,
        selectedMail, contextMenuArray, getGroups, isMultiGroupSelected) {

        let rejectInquiryMenu = this.getRejectInquiry(selectedRow, selectedRows, _compObj, loginUserData, selectedMail);
        contextMenuArray.push(rejectInquiryMenu);
        // C153176-4796: refactoring
        this.prepareRSWipeDefaultContextMenu(selectedRow, selectedRows, _compObj, loginUserData,
            selectedMail, contextMenuArray, getGroups, isMultiGroupSelected);
    }

    /**
     * Method to delete the ews mail from personal folder.
     * 
     * @param _selectedRow 
     * @param _selectedRows 
     */
    getDeleteEWSMail(_selectedRow: any, _selectedRows: any) {
        let strMenuOption = "Delete";

        let action = () => {
            this.contextServiceObject.httpDeleteEWSMail(_selectedRow, { action: strMenuOption }, _selectedRows).subscribe(
                result => {
                    console.log({ action: strMenuOption } + "::", result);
                    let ewsIdList = new Array();
                    _selectedRows.forEach(selectedRow => {
                        ewsIdList.push(selectedRow._id);
                    });

                    if (result.processingstatus == "true") {
                        this.gridUpdateService.setdeletedDraftIds(ewsIdList);
                    }
                }
            );
        }
        let img = this.createContextImage("delete");
        return (new ContextSubMenuModel(strMenuOption, action, img));
    }

    private prepareTaskizeContextMenu(selectedRow: any, contextMenuArray: any[], _loginUserInfo: any, assignMenu: any, _compObj: any,selectedRows:any) {  
        let currentTab = sessionStorage.getItem('currentTab')
        if (this.userDataService.loggedInUserInfo.isTaskizeEnabledForUser?.toUpperCase() == 'Y' ) {
            this.taskizeService.checkTaskizeTokenExpiry(this.userDataService.loggedInUserInfo)
        }  
        this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
            this.enableInquirySubStatusFlag = loginUserInfo.enableInquirySubStatusFlag;
        });
        if (currentTab.toUpperCase() == 'RESOLVED' ) {
            let reopen = this.getReopenInquiry(selectedRow, selectedRows);
            contextMenuArray.push(reopen);
        } else {
            let resolveInquiry = this.getResolveInquiry(selectedRow, _compObj.resolveInquiryComp);
            contextMenuArray.push(resolveInquiry);
        }
        if(this.enableInquirySubStatusFlag){
            let assignInquirySubStatus = this.getAssignInquirySubStatusItems(selectedRow,_loginUserInfo);
            contextMenuArray.push(assignInquirySubStatus);
        }
        let assignOwner = this.getAssignOwner(selectedRow, _loginUserInfo, assignMenu);
        contextMenuArray.push(assignOwner);
        let processingRegion = this.getAssignProcessingRegion(selectedRow, _loginUserInfo);
        contextMenuArray.push(processingRegion);
        let requestTypes = this.getAssignRequestType(selectedRow, _loginUserInfo);
        contextMenuArray.push(requestTypes);
        let rootCause = this.getAssignRootCause(selectedRow, _loginUserInfo);
        contextMenuArray.push(rootCause);
        let assignGfid = this.getAssignGfid(selectedRow, _compObj.gfidComp);
        contextMenuArray.push(assignGfid);
    }
}