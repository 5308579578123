<ng-template #cmcLoading>
  <div class="custom-class-loader">
      <div class="spinner-container">
          <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
              <circle cx="170" cy="170" r="160" stroke="#021e48" />
              <circle cx="170" cy="170" r="135" stroke="#00bdf2" />
              <circle cx="170" cy="170" r="110" stroke="#021e48" />
              <circle cx="170" cy="170" r="85" stroke="#00bdf2" />
          </svg>
      </div>
  </div>
</ng-template> 
<ngx-loading [show]="showSpinner" [config]="{ backdropBorderRadius: '3px' }" [template]="cmcLoading"></ngx-loading>

<form [formGroup]="searchForm">
<div class="search-header align-items-start d-flex">
  <label class="mr-2">Search By:</label>
  <div class="form-check form-check-inline">
    <input class="form-check-input"  (click)="searchByClick('dl')" type="radio" name="searchBy" id="inlineRadio1" value="dl" formControlName="searchBy">
    <label class="form-check-label" for="inlineRadio1">DL</label>
  </div>
  <div class="form-check form-check-inline">
    <input class="form-check-input"(click)="searchByClick('userDetails')"  type="radio" name="searchBy" id="inlineRadio2" value="userDetail" formControlName="searchBy">
    <label class="form-check-label" for="inlineRadio2">User Details</label>
  </div>
</div>
<div class="search-criteria-dl" [ngClass]="{'hide-search':selectedSeachOption !== 'dl'}">
  <input type="text" placeholder="DL Name" formControlName="dlName" class="txt-dl-name"> 
  <button type="submit" class="btn search-btn ml-2 corp-add-search" (click)="searchByDL()">Search</button>
  <div [ngClass]="{'error-msg':isFieldInValid('dlName')}" *ngIf="isFieldInValid('dlName')">
    Please enter correct details!!
  </div>
</div>
<div class="search-criteria-dl" [ngClass]="{'hide-search':selectedSeachOption !== 'userDetails'}">
    <input type="text" placeholder="FirstName" formControlName="firstName" class="txt-first-name"> 
    <input type="text" placeholder="LastName" formControlName="lastName"> 
    <button type="submit" class="btn search-btn ml-2 corp-add-search" (click)="searchByUserDetails()">Search</button>
    <div [ngClass]="{'error-msg':isBothFieldsInValid('firstName','lastName')}" *ngIf="isBothFieldsInValid('firstName','lastName')">
      Please enter correct details!!
    </div>
</div>
</form>
<div class="search-result" [ngClass]="{'hide-search':!searchByDLFlag}">
  <div class="d-flex flex-row mt-4 inquiry-rule">
    <!-- Jira : C153176-4600 Issue- CMC search -->
    <ag-grid-angular #inquiryGrid style="width:100%; height:35vh; " class="ag-theme-balham adminview" id="cmcSearch"
      [rowData]="dlList" [columnDefs]="dlDataColumn"[stopEditingWhenGridLosesFocus]="true"
      [suppressRowClickSelection]="true" [pagination]="true"  [rowSelection]="'multiple'"
      [paginationPageSize]="100" (gridReady)="onGridReadyDL($event)" suppressPaginationPanel=true
      (paginationChanged)="onPaginationChangedDL()"
      [suppressDragLeaveHidesColumns]="true" [headerHeight]="44" [suppressHorizontalScroll]="false" [modules]="modules" [defaultColDef]="dlDefaultDataColumn" >
    </ag-grid-angular>
  </div>
  <div style="margin-top: 1%;" class="d-flex justify-content-end qma-mailbox-pagination-container">
    <span class="value" id="lbPageSize">{{lbPageSize}}</span>
    <span class="separator"> of </span>
    <span class="value separator" id="lbTotalRecords">{{lbTotalRecords}}</span>
    <span class="separator separator-right"> items </span>
    <span>
      <img [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtFirst()" id="btFirst" class="pagination-img"
        src="assets/Inbox/pagination-arrow-first.svg" />
    </span>
    <span class="separator-right">
      <img id="btPrevious" [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtPrevious()"
        class="pagination-img" src="assets/Inbox/pagination-arrow-prev.svg" />
    </span>
    <span class="separator-right pagination-text-padding">Page </span>
    <span id="lbCurrentPage" class="separator-right pagination-text-padding">{{lbCurrentPage}}</span>
    <span class="separator-right pagination-text-padding">of</span>
    <span id="lbTotalPages" class="separator-right pagination-text-padding">{{lbTotalPages}}</span>
    <span>
      <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btNext" (click)="onBtNext()" class="pagination-img"
        src="assets/Inbox/pagination-arrow-dark.svg" />
    </span>
    <span class="separator-right">
      <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btLast" (click)="onBtLast()" class="pagination-img"
        src="assets/Inbox/pagination-arrow-dark1.svg" />
    </span>
  </div>
  <div class="search-btn-cls">
    <button class="btn search-btn mr-4 selbtn" (click)="selectContactsDL()" [disabled]="displayError">Select</button>
  </div>
</div>

<div class="search-result-user-details" [ngClass]="{'hide-search':!searchByUserDetailsFlag}">
  <div class="d-flex flex-row mt-4 inquiry-rule">
    <!-- Jira : C153176-4600 Issue- CMC search -->
    <ag-grid-angular #inquiryGrid style="width:100%; height:35vh; " class="ag-theme-balham adminview" id="cmcSearch"
      [rowData]="userDetailsList" [columnDefs]="userDetailsDataColumn"[stopEditingWhenGridLosesFocus]="true"
      [suppressRowClickSelection]="true" [pagination]="true"  [rowSelection]="'multiple'"
      [paginationPageSize]="100" (gridReady)="onGridReadyUserDetails($event)" suppressPaginationPanel=true
      (paginationChanged)="onPaginationChangedUserDetails()"
      [suppressDragLeaveHidesColumns]="true" [headerHeight]="44" [suppressHorizontalScroll]="false" [modules]="modules"  [defaultColDef]="userDefaultDetailsDataColumn">
    </ag-grid-angular>
  </div>
  <div style="margin-top: 1%;" class="d-flex justify-content-end qma-mailbox-pagination-container">
    <span class="value" id="lbPageSize">{{lbPageSizeUDContacts}}</span>
    <span class="separator"> of </span>
    <span class="value separator" id="lbTotalRecords">{{lbTotalRecordsUDContacts}}</span>
    <span class="separator separator-right"> items </span>
    <span>
      <img [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtFirstUDContacts()" id="btFirst" class="pagination-img"
        src="assets/Inbox/pagination-arrow-first.svg" />
    </span>
    <span class="separator-right">
      <img id="btPrevious" [ngClass]="{'pagination-img-disabled':disablePaginationPrevFirst}" (click)="onBtPreviousUDContacts()"
        class="pagination-img" src="assets/Inbox/pagination-arrow-prev.svg" />
    </span>
    <span class="separator-right pagination-text-padding">Page </span>
    <span id="lbCurrentPage" class="separator-right pagination-text-padding">{{lbCurrentPageUDContacts}}</span>
    <span class="separator-right pagination-text-padding">of</span>
    <span id="lbTotalPages" class="separator-right pagination-text-padding">{{lbTotalPagesUDContacts}}</span>
    <span>
      <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btNext" (click)="onBtNextUDContacts()" class="pagination-img"
        src="assets/Inbox/pagination-arrow-dark.svg" />
    </span>
    <span class="separator-right">
      <img [ngClass]="{'pagination-img-disabled':disablePaginationNextLast}" id="btLast" (click)="onBtLastUDContacts()" class="pagination-img"
        src="assets/Inbox/pagination-arrow-dark1.svg" />
    </span>
  </div>
  <div class="search-btn-cls">
    <button class="btn search-btn mr-4 selbtn" (click)="selectContactsUserDetsils()" [disabled]="displayError">Select</button>
  </div>
</div>
<div class="search-result-user-details" [ngClass]="{'hide-search':!displayError}">
      <br><p>{{errorMsg}}</p>
</div>