import * as moment from '../../../../node_modules/moment/moment.js';
import { DatePipe } from '@angular/common';
import { QmaConstant } from 'src/app/constant/qma-constant';
import { environment } from 'src/environments/environment';
import { AllToCcDBUser } from 'src/app/model/LoginUserInfo/AllToCcDBUser.js';

export class AppUtils {
    private static cachedMap: any = {};
    public dateFormatterMap: Map<string, string>;
    private static statsMap: any = {};
    constructor() {
        this.dateFormatterMap = new Map<string, string>();
        this.dateFormatterMap.set('dd/MMM/yy h:mm:ss tt', 'dd/MMM/yy h:mm:ss a');
        this.dateFormatterMap.set('M/dd/yyyy h:mm:ss tt', 'M/dd/yyyy h:mm:ss a');
        this.dateFormatterMap.set('dd/M/yyyy h:mm:ss tt', 'dd/M/yyyy h:mm:ss a');
        this.dateFormatterMap.set('dd/M/yyyy hh:mm:ss', 'dd/M/yyyy hh:mm:ss');
        this.dateFormatterMap.set('dd/MM/yyyy HH:mm:ss zzzz', 'dd/MM/yyyy HH:mm:ss zzzz');
        this.dateFormatterMap.set('dd Month', 'dd MMMM');
        this.dateFormatterMap.set('dd Mmm yy hh:mm tt', 'dd MMM YY hh:mm a' );
    }



    public static escapeSpecialCharSolrValue(query) {
        if (!query) {
            var QUOTE = '"';
            var regEx = /([\!\*\+\&&\&\|\(\)\[\]\{\}\^\~\?\:\/])/g;
            var match = regEx.test(query); 
            if (match) {
                query = QUOTE + query + QUOTE;
            }

        }

        return query;
    }


    public static isEmptyObject(obj) {
        return (obj && (Object.keys(obj).length === 0));
    }

    public static getToken() {
        let token = Math.random().toString(36).substr(2);
        console.log("getToken=[" + token + "]");
        return token;
    }

    public static isCitiDomainEmail(email, citiDomains) {
        let flag = false;
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validEmail = false;
        //Check for null and trim if not null
        if (!this.isUndefinedNullOrBlank(email)) {
            email = email.trim();
            validEmail = re.test(email);
            //Single Quote fix
            if (!validEmail && email.charAt(0) === "'" && email.charAt(email.length - 1) === "'") {
                email = email.substring(1, email.length - 1);
                validEmail = re.test(email);
            }
            if (validEmail) {
                let emailDomain = email.substring(email.lastIndexOf("@") + 1, email.length);
                if (!this.isUndefinedNullOrBlank(citiDomains) && citiDomains.length > 0) {
                    for (let index = 0; index < citiDomains.length; index++) {
                        flag = (emailDomain.toUpperCase().indexOf(citiDomains[index]) > -1);
                        if (flag) {
                            break;
                        }
                    }
                }
            }
        }
        return flag;
    }

    // Null value check function
    public static isUndefinedNullOrBlank(input) {
        if (input === "" || input === undefined || input == null) {
            return true;
        }
        return false;
    }
    // Function to check for valid user-name: Allows letters; numbers; ","; "."; "'"; "-"; white spaces
    public static userNameValidation(inputtxt) {
        const letterNumber = /^[0-9a-zA-Z\.\-\'\,\s]+$/;
        if ((inputtxt.match(letterNumber))) {
            return true;
        }
        else {
            return false;
        }
    }

    public static validateEmail(email) {
        let isEmail = false;
        if (!this.isUndefinedNullOrBlankStrAfterTrim(email)) {
            email = email.trim();
            let expr = /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([\w]+(\-[\w]+)*\.)+[a-zA-Z]{2,}))$/;
            isEmail = expr.test(email);
            //Single quote fix
            if (!isEmail && email.charAt(0) === "'" && email.charAt(email.length - 1) === "'") {
                email = email.substring(1, email.length - 1);
                isEmail = expr.test(email);
            }
        }
        return isEmail;
    }

    public static isUndefinedNullOrBlankStrAfterTrim(val) {
        return val === undefined || val === null || ('' + val).trim() === '';
    }

    public static isUndefinedOrNull(val) {
        return val === undefined || val === null
    }

    public static isObjetEmpty(val) {
        return val == {};
    }

    public static formatDate(date?: any, longFormat?: boolean) {
        if (longFormat) {
            let options = { year: 'numeric', month: 'long', day: 'numeric' };
            let d = (date ? date : new Date());
            return d.toDateString();
        } else {
            let d = (date ? date : new Date()),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            return [month, day, year].join('/');
        }
    }

    public static isDomainPresentInList(email, domainList) {
        let flag = false;
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validEmail = false;
        //Check for null and trim if not null
        if (email !== null && email !== undefined && email !== "") {
            email = email.trim();
            validEmail = re.test(email);
            //Single Quote fix
            if (!validEmail && email.charAt(0) === "'" && email.charAt(email.length - 1) === "'") {
                email = email.substring(1, email.length - 1);
                validEmail = re.test(email);
            }
            if (validEmail) {
                let domain = email.substring(1 + email.indexOf('@'));
                if (domainList !== null && domainList !== undefined && domainList.indexOf(domain) > -1) {
                    flag = true;
                }
                else {
                    flag = false;
                }
            }
        }
        return flag;
    }

    public static dashboardInlineView = false;

    public static getCurrentTab() {
        let currentTab;
        if (AppUtils.dashboardInlineView) {
            currentTab = sessionStorage.getItem("dashboardInlineTab");
            if (!currentTab) {
                // fallback check, will not do after all codebase has been updated.
                currentTab = sessionStorage.getItem("currentTab");
            }
            return currentTab;
        } else {
            currentTab = sessionStorage.getItem("currentTab");
            if (!currentTab) {
                // fallback check, will not do after all codebase has been updated.
                currentTab = sessionStorage.getItem("dashboardInlineTab");
            }
        }
        return currentTab;
    }

    public static getCachedItem(key: string) {
        if (key && AppUtils.cachedMap[key]) {
            return AppUtils.cachedMap[key];
        }
        return sessionStorage.getItem(key);
    }

    public static cacheItem(key: string, value: string) {
        if (key && value) {
            AppUtils.cachedMap[key] = value;
        }
        // if the key is not found in the cachedMap, cache in sessionStorage
        if (!AppUtils.cachedMap[key]) {
            sessionStorage.setItem(key, value);
        }
    }

    public static removeCachedItem(key: string) {
        let result = false;
        if (key && AppUtils.cachedMap[key]) {
            delete AppUtils.cachedMap[key];
            result = true;
        }
        if (sessionStorage.getItem(key) !== null) {
            sessionStorage.removeItem(key);
            result = true;
        }
        return result;
    }

    public static createGridColumnFilter(fieldName, operator, value) {
        var filter: any = {};
        if (fieldName && operator && value) {
            filter.field = fieldName;
            filter.field.type = operator;
            filter.field.filter = value;
        }
        //-Graphs should take Re-open age into account for items that are reopened
        //Fix for including not null operator in criteria builder
        else if (fieldName && operator && (operator === 'isnotnull' || operator === 'isnull')) {
            filter.field = fieldName;
            filter.operator = operator;
        } else {
            console.log('Invalid filter values passed : fieldName : ' + fieldName + ' operator : ' + operator + ' value : ' + value);
        }

        return filter;
    }

    public static createGridFilterWithAndOrOperator(andOrOperator, columnfileterList) {
        var filter = undefined;
        if ("and" !== andOrOperator || "or" !== andOrOperator) {
            console.log('Invalid filter values passed : andOrOperator : ' + andOrOperator);
        }
        if (andOrOperator && columnfileterList) {
            filter = {};
            filter.logic = andOrOperator;
            filter.filters = [];
            for (var index = 0; index < columnfileterList.length; index++) {
                filter.filters.push(columnfileterList[index]);
            }
        }

        return filter
    }

    public static getInquiryAge(crtDate, currentDateLong) {
        var oneDay = 24 * 60 * 60 * 1000;
        const diffDays = Math.floor(Math.abs((currentDateLong - crtDate) / (oneDay)));
        //console.log('Inquiry age : '+diffDays);
        return diffDays;
    }

    public static setDateFormarInSession(value) {
        sessionStorage.setItem("upDateFormat", value);
    }

    public static isMobileView(): boolean {
        if (window.innerWidth <= QmaConstant.MOBILE_WIDTH) {
            return true;
        } else {
            return false;

        }
    }
    public convertDate(unixDate) {
        let prevtimeTaken = sessionStorage.getItem("receivedDateTimeTaken");
        let t0 = performance.now();
        const dateFormat = sessionStorage.getItem("upDateFormat");

        //| Date formate issue.
        // For conversation history dates are already converted in detail-cell-render component.
        let dateValue = unixDate;
        try {
            let format = this.dateFormatterMap.get(dateFormat);
            let datePipe = new DatePipe("en-US");
            dateValue = datePipe.transform(unixDate, format);
        } catch (ex) {
            console.log("Date Value : ", dateValue);
        }

        let t1 = performance.now();
        if (prevtimeTaken) {
            const newtimeTaken = JSON.parse(sessionStorage.getItem("receivedDateTimeTaken")) + (t1 - t0);
            sessionStorage.setItem("receivedDateTimeTaken", newtimeTaken);
        } else {
            sessionStorage.setItem("receivedDateTimeTaken", JSON.stringify(t1 - t0));
        }
        return dateValue;
    }
    // fix for Observation- Limitations with filter not being provided at column level
    public convertDateFormat(unixDate, format) {
        let datePipe = new DatePipe("en-US");
        let dateValue = datePipe.transform(unixDate, format);
        return dateValue;
    }
    // fix for Observation- Limitations with filter not being provided at column level
    public applyGridDateFilter(filterLocalDate, cellValue) {
        const dateAsString = this.convertDateFormat(cellValue, 'dd/MM/yyyy');
        if (dateAsString == null) { return - 1; };
        const dateParts = dateAsString.split('/');
        const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );
        if (filterLocalDate.getTime() == cellDate.getTime()) {
            return 0;
        }
        if (cellDate < filterLocalDate) {
            return -1;
        }
        if (cellDate > filterLocalDate) {
            return 1;
        }
    }
    // Using Moment    
    /* public static convertDate(unixDate) {
        const dateFormat = sessionStorage.getItem("upDateFormat");
        const dateFormatterUnixMap = new Map<string, string>();
        dateFormatterUnixMap.set('dd/MMM/yy h:mm:ss tt', 'DD/MMM/YY h:mm:ss a');
        dateFormatterUnixMap.set('M/dd/yyyy h:mm:ss tt', 'M/DD/YYYY h:mm:ss a');
        dateFormatterUnixMap.set('dd/M/yyyy h:mm:ss tt', 'DD/M/YYYY h:mm:ss a');
        dateFormatterUnixMap.set('dd/M/yyyy hh:mm:ss', 'DD/M/YYYY hh:mm:ss');
        dateFormatterUnixMap.set('yyyy/mm/dd hh:mm:ss', 'DD/mm/YYYY hh:mm:ss');
        let format = "";
    
        dateFormatterUnixMap.forEach((value: string, key: string) => {
            if (key === dateFormat) {
                format = value;
            }
        });
        
        return moment(unixDate).format(format);
    } */
    public static millisToMinutesAndSeconds(millis) {
        if (!environment.production) {
            let minutes = Math.floor(millis / 60000);
            let seconds = parseInt(((millis % 60000) / 1000).toFixed(0));
            return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        }
        return 0;
    }

    /**
     * Take 'now' stats:
     * - key: name of the stats
     * - start: start time in milliseconds
     * - end: end time in milliseconds
     */
    public static now(stats: any) {
    }

    public static _now(stats: any) {
        if (environment.production) {
            return;
        }
        const key = stats.key;
        if (!key) {
            return;
        }
        let cached_stat = AppUtils.statsMap[key];
        if (!cached_stat) {
            cached_stat = { key: key };
        }
        if (stats.start) {
            cached_stat['start'] = stats.start;
        }
        if (stats.end) {
            cached_stat['end'] = stats.end;
            if (cached_stat.start) {
                cached_stat['milliseconds'] = stats.end - cached_stat.start;
                cached_stat['minsecs'] = AppUtils.millisToMinutesAndSeconds(cached_stat.milliseconds);
            }
        }
        if (cached_stat) {
            AppUtils.statsMap[key] = cached_stat;
        }
    }

    public static logstats() {
    }

    public static _logstats() {
        if (environment.production) {
            return;
        }
        const keys = Object.keys(AppUtils.statsMap).sort((ka, kb) => {
            const a = AppUtils.statsMap[ka];
            const b = AppUtils.statsMap[kb];
            if (a.milliseconds === undefined) {
                return -1;
            } else if (b.milliseconds === undefined) {
                return 1;
            } else if (a.milliseconds === b.milliseconds) {
                return 0;
            } else {
                return a.milliseconds < b.milliseconds ? -1 : 1;
            }
        });
        if (keys) {
            keys.forEach(k => {
                const stat = AppUtils.statsMap[k];
                console.log('item:' + stat.key + ', minsecs = ' + stat.minsecs + ' (ms = ' + stat.milliseconds + '), endtime=' + stat.end);
            });
        }
    }

    /**
     *handle additional ESCALATION view types
     */
    public static isEscalationType(type) {
        return type && [
            'POTENTIAL ESCALATIONS',
            'ESC: CLIENT CHASER',
            'ESC: CONVERSATION COUNT',
            'ESC: RESPONSE TIME',
            'ESC: SUBJECT BASED',
            'ESC: PENDING APPROVAL',
            'INBOX',
            'TAG',
            'PLATINUM',
            'OTHER',
            'PRIORITY',
            'SNOOZED',
            'CUST_CAT1'
        ].includes(type.toUpperCase());
    }
    /**
     * return whether an event has been stopped propagation
     */
    public static isStoppedPropagation(event) {
        return event && event.__zone_symbol__propagationStopped;
    }
    // C170665-255 Restrict specific file extensions in QMA 2.0
    public static getValidFilesTobeUploaded(files) {
        let validFiles = [];
        let inValidFiles = [];
        for (let i = 0; i < files.length; i++) {
            let blob = files[i];
            let fileType = blob.type;
            if (QmaConstant.QMA_VALID_FILES.includes(fileType)) {
                validFiles.push(files[i]);
            } else {
                inValidFiles.push(files[i])
            }
        }
        return {
            validFiles: validFiles,
            inValidFiles: inValidFiles
        }
    }
  
    public static getValidFilesTobeUploadedByExtension(files, allowedFileExtensions, blockedFileNameCharacters) {
        let QMA_VALID_FILE_EXTENSIONS = allowedFileExtensions ? allowedFileExtensions : QmaConstant.QMA_VALID_FILE_EXTENSIONS;
        let validFiles = [];
        let inValidFiles = [];
        let filenameContainingRestrictedChars= []
        let fileNameWithMultipleDots = []
        for (let i = 0; i < files.length; i++) {
            let blob = files[i];
            if (blob && blob.name && QMA_VALID_FILE_EXTENSIONS.includes(blob.name.split('.').pop().toLowerCase())) {
                validFiles.push(files[i]);
            } else {
                inValidFiles.push(files[i]);
            }

            if(blob && blob.name && blockedFileNameCharacters && Array.isArray(blockedFileNameCharacters) && blockedFileNameCharacters.some(char=> blob.name.split('').includes(char))) {
                filenameContainingRestrictedChars.push(files[i])
            }
            // if file name contains multiple . replace it with _ for all other than extension.
            let dotCount = files[i].name.match(/\./g) || [];
            if(dotCount.length > 1) {
                fileNameWithMultipleDots.push(files[i]);
            }
        }
        
        return {
            validFiles: validFiles,
            inValidFiles: inValidFiles,
            filenameContainingRestrictedChars:filenameContainingRestrictedChars,
            fileNameWithMultipleDots:fileNameWithMultipleDots
        }
    }
  
    public static isExternalEmailInToCc(email, citiDomainList): boolean {
        let isExternal: boolean = false;
        if (!AppUtils.isUndefinedOrNull(email)) {

            // If User enter soeid who has access to QMA application
            let emailList = email.split(';');
            emailList.forEach(emailValue => {
                emailValue = emailValue.trim();
                if (emailValue && !AppUtils.isCitiDomainEmail(emailValue, citiDomainList)) {
                    isExternal = true;
                }
            });
        }
        return isExternal;
    }

    

    // validate soeid and user name
    public static validateNonQmaCitiUserSoeId(value: string): boolean {
        let userNameAndSoeIdRegEx = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 ,]*)$/;
        return userNameAndSoeIdRegEx.test(value);//result;
    }

    // validate DL ame starting with *
    public static validateDLName(value: string): boolean {
        //validate string starts from *
        let dlNameRegEx = /^[*].*$/;
        return dlNameRegEx.test(value);//result;
    }
    public static isDuplicate(item, source) {
        let found = false;
        source.forEach(element => {
            if (element.email === item.email) {
              found = true;
            }
          });
        return found;
      }

      public static extractEmailAddrOrName(type: string, email: string): string {
        const EMAIL_ADDR = "EMAIL_ADDR";
        const USER_NAME = "USER_NAME";
        let emailValue = email;
        if (EMAIL_ADDR === type && email.indexOf('<') > 0) {
            let id = email.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
            emailValue = id ? id[0] : '';
        }
        else if (USER_NAME === type && email.indexOf('<') > -1) {
            let endIdx = email.indexOf('<') - 1;
            emailValue = email.substring(0, endIdx);
        }
        return emailValue;
    }
    // C170665-1886 Issue-- On Reply all screen on hit of Send button, unable to identify the email address for id in To field .
    // extract email id of group from myGroups by Id if email id is null
    public static getGroupEmailIdFromGroupId(myGroups, groupId) {
        if (myGroups && Array.isArray(myGroups)) {
            let group = myGroups.find(mg => mg.id === groupId)
            return group ? group.groupEmail : null;
        } else {
            return null;
        }

    }

    public static getFileNamesWithBlockedCharacters(files, Qma_blocked_file_name_chars) {
        let filenameContainingRestrictedChars = []
        for (let i = 0; i < files.length; i++) {
            if (files[i].name && Qma_blocked_file_name_chars && Array.isArray(Qma_blocked_file_name_chars) && Qma_blocked_file_name_chars.some(char => files[i].name.split('').includes(char))) {
                filenameContainingRestrictedChars.push(files[i]);
            }
        }
        if(filenameContainingRestrictedChars.length > 0) {
            return filenameContainingRestrictedChars;
        } else {
            return null;
        }
       
        
    }

    public static getFormattedDate(date) {

        let dtStr = null;
        if (date != null && date != '') {
            let dt = new Date(date);
            if (dt != null) {
                dtStr = (dt.getMonth()+1) + '/' + dt.getDate() + '/' + dt.getFullYear();
            }
        }
        return dtStr;
    }

    public static isValidDates(inputDate,operation,allowedDateRange) {
        try {
        let operationType = operation.operator.type;
        let isValidDate = false;
        let date1 = Array.isArray(inputDate) ? moment(new Date(inputDate[0])):moment(new Date(inputDate))
        // start date future date validation
        // let date1 = moment(new Date(inputDate));
        let currentDate = moment(new Date());
        let dateRange =  currentDate.diff(date1, 'days');
        isValidDate = dateRange >=0 ? true:false;
        if(!isValidDate) {
            return isValidDate;
        }
        switch(operationType) {
            case 'greater': {
                // todays date as invalid for greater condition
                if(dateRange === 0) {
                    isValidDate = false;
                    break;
                }
                let monthRange = currentDate.diff(date1, 'months');
                // isValidDate = dateRange+1 <= allowedDateRange ? true:false;
                isValidDate = monthRange <= allowedDateRange ? true:false;
                break;
            }
            case 'between': {
                // to restict last three months from now
                let monthRange = currentDate.diff(date1, 'months');
                isValidDate = monthRange <= allowedDateRange ? true:false;
                if(!isValidDate) {
                    break;
                }

                let endDate = moment(new Date(inputDate[1]));
                // end Date can not be a future Date
                let endDateRange = currentDate.diff(endDate, 'days');
                isValidDate = endDateRange >=0 ? true:false;
                if(!isValidDate) {
                    break;
                }
                break;
            }
            case 'less': {
                let monthRange = currentDate.diff(date1, 'months');
                // isValidDate = dateRange+1 <= allowedDateRange ? true:false;
                isValidDate = monthRange <= allowedDateRange ? true:false;
                break;
            }
            
        }
        return isValidDate;
       
    } catch(e) {
        console.error("exception in validating date:- " +e);
    }


    }
}
