import { Component, OnInit, Input, Inject, AfterViewInit, ElementRef, SimpleChanges, SimpleChange, OnChanges, ViewChildren, QueryList, ChangeDetectionStrategy } from '@angular/core';
import { UserDataService } from '../../services/user-data.service';
import { MyGroup } from '../../model/LoginUserInfo/MyGroup';
import { GetContactsService } from '../../services/newMessage/get-contacts.service';
import { AllToCcDBUser } from '../../model/LoginUserInfo/AllToCcDBUser';
import { AllActiveGroup } from '../../model/LoginUserInfo/AllActiveGroup';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { OverlayPanel } from "primeng//overlaypanel";
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { isNull } from 'util';
import { GfidSearchComponent } from "src/app/common/component/gfid-search/gfid-search.component";
import { ViewChild, Output, EventEmitter } from "@angular/core";
import { NewMessageService } from '../../services/newMessage/new-message.service';
import { QmaConstant } from '../../constant/qma-constant';
import { Subscription } from 'rxjs';
import { InboxService } from '../../services/inbox.service';
import { AppUtils } from '../../common/utility/appUtil';
import { MessageService } from 'primeng/api';
/* import { CmcsearchComponent } from '../cmcsearch/cmcsearch.component'; */
import { CmcsearchComponent } from 'src/app/new-message/cmcsearch/cmcsearch.component';
import { MyFavoriteContact, GroupMemberContact } from '../../model/LoginUserInfo/MyFavoriteContact';
import { CheckboxListComponent } from '../../common/component/checkbox-list/checkbox-list.component';
import { ClcService } from '../../services/clc/clc.service';
import { UserSignature } from "src/app/model/LoginUserInfo/UserSignature";
import { environment } from 'src/environments/environment';
import { GlobalDirectoryService } from 'src/app/services/global-directory/global-directory.service';
import { TopContacts } from 'src/app/model/LoginUserInfo/TopContacts';

interface ConversationOption {
  name: string,
  code: string
}

interface attachmentOption {
  name: string,
  id: string,
  fileInfo: any
}



@Component({
  selector: 'symphony-message-recipent',
  templateUrl: './symphony-message-recipent.component.html',
  styleUrls: ['./symphony-message-recipent.component.scss'],
  providers: [MessageService]
})
export class SymphonyMessageRecipentComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChildren(CheckboxListComponent) checkboxListComponents: QueryList<CheckboxListComponent>
  constructor(private userDataService: UserDataService, private getContactsService: GetContactsService, private builder: FormBuilder, private newMessageService: NewMessageService,
    private inboxService: InboxService, private messageService: MessageService,
    private globalDirectoryService: GlobalDirectoryService) {}

  groupNames: any[] = []; // C153176-5197
  selectedGroupName: any; // C153176-5197
  filteredToUsersMultiple: any[] = [];
  allToCcDBUser: AllToCcDBUser[] = [];
  allActiveGroups: AllActiveGroup[] = [];
  toUsersList: AllToCcDBUser[] = [];
  ccUsersList: AllToCcDBUser[] = [];
  bccUsersList: AllToCcDBUser[] = [];
  inquirySourceList = [];
  requestTypes = [];
  processingRegions = [];
  rootCauses = [];
  attachmentsOptions: any = [];
  requestTypeMap = new Map<string, string[]>();
  processingRegionMap = new Map<string, string[]>();
  rootCauseMap = new Map<string, string[]>();
  tagsMap = new Map<string, string[]>();
  query: any;
  note: string = '';
  attachmentLength: any = 0;
  isElipsisShow: boolean = false;
  @Input() parentForm: FormGroup;
  @ViewChild(GfidSearchComponent) GfidSearchComponent: any;
  postDataForAttachmentFile: Array<any> = [];
  @Input() postDataForAttachment: any;
  @Input() template: any;
  @Input() messageInstance: any;
  asyncLoad = new BehaviorSubject("");
  isFirstInstance: boolean = true;
  fromwhere: string = '';
  subManager = new Subscription();

  showContact: boolean = false;
  closeFlagInvalid: boolean = false;
  isClosed:boolean = true;
  isChevronUp: boolean = false; // Jira : C153176-5194 this flag should stay false initially (for web version) 
  //C153176-4703 : Auto select dropdown values in case of single value in new message
  countCheck: number=2; //Jira : C153176-4703 : The selected Processing region gets unselect after Submit and Refresh
  toggleccbcc: boolean = false; // Jira : C153176-5194 Mobile -To/CC/ BCC changes
  selectedContactId: string = '';
  // Emitter for Notes
  @Output() notesEvent = new EventEmitter();
  @Output() messageContent = new EventEmitter();
  @Output() conversationObj = new EventEmitter();
  @Output() resizeMailBody = new EventEmitter();
  @Input() fromNewMessage: boolean = false;
  @Input() toCcBccData: any;
  @Input() isInlineReply: boolean = false;

  // For disclaimer change event
  @Output() disclaimerEvent = new EventEmitter();
  @Output() expandEvent = new EventEmitter();


  // Notes list to keep the entered notes;
  notesList: any[] = [];

  userName: string = '';
  citiDomains: any[];
  // @ViewChild('gfpidgfcid') gfpidgfcid: ElementRef;
  gfpidgfcid = '';
  mandatoryForResolveFlags = [];
  queryCountMsg: string = '';
  @ViewChild(CmcsearchComponent) cmcsearchComponent: any;
  loggedInUser: string = '';
  @Output() secure = new EventEmitter();
  @Output() group = new EventEmitter();

  // Property to for disable AutoComplete
  isAutoCompleteDisabled: boolean = false;

  // name of the tab in which this component is used
  tabName: string = undefined;
  @Input() resolve: any;
  /** Defined the variable for Add to Top contacts and show member list */
  addToTopFlag: boolean = false;
  addOptions: any = [];
  setData: any;
  groupMembers: any = [];
  displaySuccess: boolean = false;
  groupMemberList: any = [];
  displayError: boolean = false;
  noMember: boolean = false;
  @ViewChild('groupMember') checkboxlistcomp: CheckboxListComponent;
  /**Top Contacts variable definition ended */
  replyActionObj: any = {};
  @Input() editorData: any;
  tags = [];
  borderDragDropHighlight = false;
  @Output() newMessageData = new EventEmitter();
  isMobile: boolean = false;
  @Input() pushContact: any; // C153176-4229 : Push Contact as recipients
  currentField : string; //C153176-4229 for cuurent field to add member  
  selectedMember: any = []; //C153176-4229 to store selected member
  // C153176-5262: refacor email validation regex
  email_regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//below is added to show /hide contact info functionlity
  GDIR_DISPLAY_INFO: boolean;

  recipientsMap = new Map<string, any[]>();
  extRecipients = [];

  displayCMCSearchPopup = false;

  //display suggestion popup
  displaySuggestion: boolean = false;
  suggestionRecords: any[];
  suggestionField: string = '' ;
  assignedGroups: MyGroup[] = [];

  // C153176-5746 : Top contact save or error dialog flag
  contactSuccess = false;
  contactError = false;
  @Output() topContactAddEvent = new EventEmitter();
  @Output() recipientUpdateEvent = new EventEmitter(); // C153176-5896 | On Remove Recipient
  isRecUpdated: boolean = false;
  //below will allow only letter,digit,space and ',' 
  userNameAndSoeIdRegEx = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 ,]*)$/;
  ngOnInit() {
  
    this.getUserData();
    // this.getContactsService.getContact().subscribe((item) => {
    //   let pushContact = new AllToCcDBUser();
    //   if (item[0]) {
    //     pushContact.text = item[0].name;
    //     pushContact.value = item[0].email;
    //     pushContact.email = item[0].email;
    //     this.pushToTo(pushContact, item[1]);
    //   }
    // });

    // this.subscribingRecipientData();
    this.subscribingToCcBccFor();

    // C153176-4202 : Get the auto complete user preference
    this.isAutoCompleteDisabled = this.userDataService.loggedInUserInfo.disableAutoComplete;

    const globalDirectoryConfig = this.userDataService.loggedInUserInfo.globalDirectoryConfig;
    this.GDIR_DISPLAY_INFO = globalDirectoryConfig.contactInfoConfig.gdirDisplayInfo;

    // XXXXX - Why we need it here
    // this.newMessageService.getInquiryReplyData().subscribe(result => {
    //   if (result) {
    //     console.log('result :', result);
    //     this.replyActionObj = result;
    //   }
    // })

    // inquiry drag highlight handling
    this.inboxService.inquiryDragEvent.subscribe((evt :any) => {
      if (evt && evt.qma) {
        this.borderDragDropHighlight = evt.active;
      }
    });
    //this.onValueChanges();
  }

  clearToCcBcc(val) {
    switch (val) {
      case 'to':
        this.toUsersList = [];
        this.parentForm.get('toUsers').setValue([]);
        break;
      case 'cc':
        this.ccUsersList = [];
        this.parentForm.get('ccUsers').setValue([]);
        break;
      case 'bcc':
        this.bccUsersList = [];
        this.parentForm.get('bccUsers').setValue([]);
        break;
    }
    // C153176-5896 | let the other components knows that recipients are removed.
    this.isRecUpdated = !this.isRecUpdated; 
    this.recipientUpdateEvent.emit(this.isRecUpdated);
  }
  subscribingRecipientData(data) {
    if (!data) {
      console.log('new-message-recipient: empty recipientData', data);
      return;
    }
    let recdata = data.recipientData;
    this.fromwhere = recdata.fromWhere;
    if (!this.isNull(this.fromwhere)) {
      try {
        if (!this.isNull(recdata) && recdata.fromWhere === "messages") {
          this.parentForm.get('groupName').setValue(recdata.from);
          // C153176-2907 Subscribing for groupName for top contact
          this.getContactsService.setGroupContact(recdata.from);
          this.parentForm.get('queryCount').setValue(recdata.queryCount);
         
          // C153176-5302 : Auto Population if single data is available
          this.parentForm.get('requestType').setValue(this.getRequestType(recdata));
          this.parentForm.get('inquirySource').setValue(this.getInquirySrc(recdata));
          this.parentForm.get('processingRegion').setValue(this.getProcessingRegion(recdata));
          this.parentForm.get('rootCause').setValue(this.getRootCause(recdata));

          this.populateTags(recdata.from);
          this.parentForm.get('tags').setValue(recdata.tag);

          this.parentForm.get('gfcName').setValue(recdata.gfcName);
          this.parentForm.get('gfcId').setValue(recdata.gfcid);
          this.parentForm.get('gfpName').setValue(recdata.gfpName);
          this.parentForm.get('gfpId').setValue(recdata.gfpid);
          if (!this.isNull(recdata.gfpid) && !this.isNull(recdata.gfcid))
            this.gfpidgfcid = recdata.gfpid + " / " + recdata.gfcid;
          this.postDataForAttachmentFile = data.attachments;
          this.parentForm.get("attachments").setValue(this.postDataForAttachmentFile);

          // C153176-5496 | Populate the recipients for the group, that will availbale as suggestion.
          this.populateRecipients(recdata.from);
        }
      } catch (e) {
        //  console.log(e)
      }
    }
    //});

  }
  // Null value check function
  isNull(input) {
    if (input === "" || input === undefined || input == null) {
      return true;
    }
    return false;
  }

  subscribingToCcBccFor() {
    this.newMessageService.getInquiryReplyData().subscribe(data => {
      if (Object.entries(data).length === 0 && data.constructor === Object) {
        return;
      }
      let inquiryId = data.inquiryId;
      let inquiryAction = data.inquiryAction; // Tab name
      let templateData = data.templateData[0];

      if (this.messageInstance.includes(inquiryId) && (inquiryAction.startsWith(QmaConstant.REASSIGN) === this.messageInstance.startsWith(QmaConstant.REASSIGN))) {
        this.setRecipentFields(templateData);
        this.subscribingRecipientData(data);
        this.messageContent.emit(templateData);
        this.conversationObj.emit(data);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // C153176-5766 | Clicking on Resolve duplicates the attachment.
    if (changes && changes.postDataForAttachment) {
      if (!this.postDataForAttachmentFile) {
        this.postDataForAttachmentFile = [];
      }
      if (this.postDataForAttachment && this.postDataForAttachment.length > 0) {
        // C153176-4707: copy only new attachment(s) to postDataForAttachmentFile list
        this.postDataForAttachment.forEach(attch => {

          // C153176-5616 | Multiple Files not attaching with same names.
          //&& this.postDataForAttachmentFile.findIndex(elem => elem && elem.name === attch.name) === -1
          if (attch) {
            this.postDataForAttachmentFile.push(Object.assign({}, attch));
          }
        });
        this.getAttachmentData();
      }
    }
    this.getResolveEnquiry();

    //C153176-5002 - the deleted email is reappearing in to/cc/bcc
    try {
      if (changes && changes.template) {
        this.setTemplateData();
      }
    } catch (e) { }

    // C153176-4229 : Push Contact as recipients
    if (changes.pushContact && changes.pushContact.currentValue) {
      const singleContact = changes.pushContact.currentValue;
      this.pushToTo(singleContact.contact, singleContact.field);
    }
  }

  setTemplateData() {
    let template = this.template;
    try {
      if (!isNull(template)) {
        //template[0].templateContent;
        this.setRecipentFields(template);
        // t.unsubscribe();
      }
    } catch (e) {
      //console.log(e)
    }
  }
  getAttachmentData() {
    this.parentForm.get('attachments').setValue(this.postDataForAttachmentFile);
    let attachFields = {
      fileldFlag: this.postDataForAttachmentFile.length > 0,
      fieldName: "attachment"
    }
    this.resizeMailBody.emit(attachFields);
  }
  checkOverFlow(element) {
    if (element.clientWidth < element.scrollWidth) {
      this.attachmentLength = Math.floor(element.clientWidth / 127);
      return true;
    }
  }
  showRemainingAttchment(event, overlaypanel: OverlayPanel, attachmentList: any): void {
    this.attachmentsOptions = [];
    for (let i = this.attachmentLength; i < attachmentList.length; i++) {
      let attachInfo: attachmentOption = {
        name: attachmentList[i].name,
        id: attachmentList[i].id,
        fileInfo: attachmentList[i]
      };
      this.attachmentsOptions.push(attachInfo);
    }
    overlaypanel.toggle(event);
  }
  // delete attached file when click on delete icon ;
  deleteAttachedFile(id) {
    let elem = document.getElementById(id);
    elem.remove();
    if (this.postDataForAttachmentFile.length === this.attachmentLength + 1) {
      this.isElipsisShow = true;
    }
    for (let i = 0; i < this.postDataForAttachmentFile.length; i++) {
      if (this.postDataForAttachmentFile[i].id === id) {
        this.postDataForAttachmentFile.splice(i, 1);
      }
    }
    for (let i = 0; i < this.postDataForAttachment.length; i++) {
      if (this.postDataForAttachment[i].id === id) {
        this.postDataForAttachment.splice(i, 1);
      }
    }

    this.parentForm.get('attachments').setValue(this.postDataForAttachmentFile);
    console.log('attached files :', this.postDataForAttachmentFile);
    let attachFields = {
      fileldFlag: this.postDataForAttachmentFile.length > 0,
      fieldName: "attachment"
    }
    this.resizeMailBody.emit(attachFields);

  }

  //delete attached file present inside overlay panel
  deleteAttachedFilePanel(id) {


    for (let i = 0; i < this.attachmentsOptions.length; i++) {
      if (this.attachmentsOptions[i].id === id) {
        this.attachmentsOptions.splice(i, 1);
      }
    }
    for (let i = 0; i < this.postDataForAttachmentFile.length; i++) {
      if (this.postDataForAttachmentFile[i].id === id) {
        this.postDataForAttachmentFile.splice(i, 1);
      }
    }



    this.parentForm.get('attachments').setValue(this.postDataForAttachmentFile);
    console.log('attached files :', this.postDataForAttachmentFile);
  }

  setRecipentFields(inquiryData: any) {

    this.parentForm.patchValue({
      "subject": inquiryData.templateSubject
    });

    if (this.check(inquiryData.toList)) {
      this.toUsersList = inquiryData.toList;
      this.parentForm.controls["toUsers"].markAsTouched();
      this.parentForm.controls["toUsers"].markAsDirty();
      this.parentForm.controls["toUsers"].updateValueAndValidity();
    }
    else
      this.toUsersList = [];

    if (this.check(inquiryData.ccList)) {
      this.ccUsersList = inquiryData.ccList;
      this.parentForm.controls["ccUsers"].markAsTouched();
      this.parentForm.controls["ccUsers"].markAsDirty();
      this.parentForm.controls["ccUsers"].updateValueAndValidity();
    }
    else
      this.ccUsersList = [];

    if (this.check(inquiryData.bccList)) {
      this.bccUsersList = inquiryData.bccList;
      this.parentForm.controls["bccUsers"].markAsTouched();
      this.parentForm.controls["bccUsers"].markAsDirty();
      this.parentForm.controls["bccUsers"].updateValueAndValidity();
    }
    else
      this.bccUsersList = [];


  }

  check(x) {
    if (x == null) {
      return false;
    }

    if (x === null) {
      return false;
    }

    if (typeof x === 'undefined') {
      return false;
    }
    return true;
  }

  splitMultipleEmailIds(item, field) {

    let emailPattern = /\<([^\>]+)\>/g;
    let match = "";
    let newItem = new AllToCcDBUser();
    let result = item.value.split(';');
    for (let str of result) {
      str = str.trim();

      //check for patter => '"Name one" <foo@domain.com>
      //let temp = emailPattern.exec(str);
      //str = temp[1];


      newItem = {
        text: str,
        value: str,
        email: str,
        id: '',
        active: false
      };
      if (field === 'to' && !this.isDuplicate(newItem, 'to'))
        this.toUsersList.push(newItem);
      else if (field === 'cc' && !this.isDuplicate(newItem, 'cc'))
        this.ccUsersList.push(newItem);
      else if (field === 'bcc' && !this.isDuplicate(newItem, 'bcc'))
        this.bccUsersList.push(newItem);
    }
  }

  pushToTo(item: AllToCcDBUser, field: string) {
    
    // C153176-5262: validate email id
    if (item && item.value && item.value.trim()) { // C153176-5288: check whether value is empty
      let emails = item.value.split(';');
      for (let email of emails) {
        email = email.trim();

        // C153176-5719 | Drag Drop of Group
        // Consider group emails - for group value have id i.e. number
        if (NaN != Number(email) && Number(email) > 0 ) {
          let grpdIdx = this.assignedGroups.findIndex(grp => grp.id == Number(email));
          
          // Check if the group tried to push from contact list but not in assigned group
          if (grpdIdx > -1) {
            email = this.assignedGroups[grpdIdx].groupEmail;
          } else {
            email = item.email;
          }
        }
        
        if (email && !this.email_regex.test(email)) {
          this.closeFlagInvalid = true;
          setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
          return;
        }
      }
    } else {
      // C153176-5288: prevent empty email id from breaking into new-message address fields
      this.closeFlagInvalid = true;
      setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
      return;
    }

    switch (field) {
      case 'to':
        if (!this.isDuplicate(item, 'to')) {
          if (item.value.indexOf(';') > -1) {
            this.splitMultipleEmailIds(item, 'to');
          }
          else
            this.toUsersList.push(item);
          this.parentForm.controls["toUsers"].markAsTouched();
          this.parentForm.controls["toUsers"].markAsDirty();
          this.parentForm.controls["toUsers"].updateValueAndValidity();
        }
        break;
      case 'cc':
        if (!this.isDuplicate(item, 'cc')) {
          if (item.value.indexOf(';') > -1) {
            this.splitMultipleEmailIds(item, 'cc');
          }
          else
            this.ccUsersList.push(item);
          this.parentForm.controls["ccUsers"].markAsTouched();
          this.parentForm.controls["ccUsers"].markAsDirty();
          this.parentForm.controls["ccUsers"].updateValueAndValidity();
        }
        break;
      case 'bcc':
        if (!this.isDuplicate(item, 'bcc')) {
          if (item.value.indexOf(';') > -1) {
            this.splitMultipleEmailIds(item, 'bcc');
          }
          else
            this.bccUsersList.push(item);
          this.parentForm.controls["bccUsers"].markAsTouched();
          this.parentForm.controls["bccUsers"].markAsDirty();
          this.parentForm.controls["bccUsers"].updateValueAndValidity();
        }
        break;
    }
  }

  isDuplicate(item, toCcBcc) {
    let found = false;
    switch (toCcBcc) {
      case 'to':
        this.toUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
      case 'cc':
        this.ccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
      case 'bcc':
        this.bccUsersList.forEach(element => {
          if (element.email === item.email) {
            found = true;
          }
        });
        break;
    }
    return found;
  }

  onGroupNameChange(groupName) {
    this.getContactsService.setGroupContact(groupName.value);
    this.populateRequestType(groupName.value);
    this.populateProcessingRegion(groupName.value);
    this.populateRootCause(groupName.value);
    this.populateInquirySource();
    this.populateTags(groupName.value);

    // C153176-5496 | Populate the recipients for the group, that will availbale as suggestion.
    this.populateRecipients(groupName.value);

    // Update disclaimer on change of group name
    const disclaimer = this.getDisclaimerByGroup(groupName.value);
    this.disclaimerEvent.emit(disclaimer);
    this.group.emit(groupName.value);
  }

  getValidatedRequestType(requestType) {
    const reqTypes = this.requestTypes.filter(e => e && e.value === requestType);
    if (reqTypes && reqTypes.length) {
      return requestType;
    } else {
      return '';
    }
  }
  populateRequestType(groupName) {
    this.requestTypes = [];
    this.requestTypes.push({ label: 'Select', value: '' });

    this.requestTypeMap.forEach((value: string[], key: string) => {
      if (!isNull(value)) {
        if (key === groupName) {
          for (let i = 0; i < value.length; i++) {
            this.requestTypes.push({ label: value[i], value: value[i] });
          }
        }
      }
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.requestTypes.length === this.countCheck) {
      this.parentForm.get('requestType').setValue(this.requestTypes[1].value);
    } else {
      this.parentForm.get('requestType').setValue(''); // C153176-5737 | If no record is available.
    }

  }

  populateProcessingRegion(groupName) {
    this.processingRegions = [];
    this.processingRegions.push({ label: 'Select', value: '' });

    this.processingRegionMap.forEach((value: string[], key: string) => {
      if (!isNull(value)) {
        if (key === groupName) {
          for (let i = 0; i < value.length; i++) {
            this.processingRegions.push({ label: value[i], value: value[i] });
          }
        }
      }
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.processingRegions.length === this.countCheck) {
      this.parentForm.get('processingRegion').setValue(this.processingRegions[1].value);
    } else {
      this.parentForm.get('processingRegion').setValue(''); // C153176-5737 | If no record is available.
    }
  }

  populateRootCause(groupName) {
    this.rootCauses = [];
    this.rootCauses.push({ label: 'Select', value: '' });

    this.rootCauseMap.forEach((value: string[], key: string) => {
      if (!isNull(value)) {
        if (key === groupName) {
          for (let i = 0; i < value.length; i++) {
            this.rootCauses.push({ label: value[i], value: value[i] });
          }
        }
      }
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.rootCauses.length === this.countCheck) {
      this.parentForm.get('rootCause').setValue(this.rootCauses[1].value);
    } else {
      this.parentForm.get('rootCause').setValue(''); // C153176-5737 | If no record is available.
    }
  }

  populateInquirySource() {
    this.inquirySourceList = [];
    this.inquirySourceList.push({ label: 'Select', value: '' });
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      //Get Inquiry Source
      loginUserInfo.inquirySourceList.forEach(inquirySource => {
        this.inquirySourceList.push({ label: inquirySource, value: inquirySource });
      });
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.inquirySourceList.length === this.countCheck) {
      this.parentForm.get('inquirySource').setValue(this.inquirySourceList[1].value);
    } else {
      this.parentForm.get('inquirySource').setValue(''); // C153176-5737 | If no record is available.
    }
  }

  populateTags(groupName) {
    this.tags = [];
    this.tags.push({ label: 'Select', value: '' });

    this.tagsMap.forEach((value: string[], key: string) => {
      if (!this.isNull(value)) {
        if (key === groupName) {
          for (let i = 0; i < value.length; i++) {
            this.tags.push({ label: value[i], value: value[i] });
          }
        }
      }
    });
    // C153176-4703 : Auto select dropdown values in case of single value in new message
    if (this.tags.length === this.countCheck) {
      this.parentForm.get('tags').setValue(this.tags[1].value);
    } else {
      this.parentForm.get('tags').setValue(''); // C153176-5737 | If no record is available.
    }
  }

  // C153176-5496 : Populate the extRecipients for the selected group.
  populateRecipients(groupName): void {
    this.extRecipients = [];
    this.extRecipients = this.recipientsMap.get(groupName) || [];
  }

  getUserData() {
    let groupNameTmp = [];
    groupNameTmp.push({ label: 'Select', value: '' });
    this.inquirySourceList.push({ label: 'Select', value: '' });
    this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
      this.citiDomains = loginUserInfo.citiDomainList;
      this.loggedInUser = loginUserInfo.userId;
      this.userName = loginUserInfo.userName;
      this.assignedGroups =  loginUserInfo.myGroups;

      loginUserInfo.myGroups.forEach(myGroup => {
        groupNameTmp.push({ label: myGroup.groupName, value: myGroup.groupName });
        this.requestTypeMap.set(myGroup.groupName, myGroup.requestTypes);
        this.processingRegionMap.set(myGroup.groupName, myGroup.processingRegionList);
        this.rootCauseMap.set(myGroup.groupName, myGroup.rootCauseList);
        this.tagsMap.set(myGroup.groupName, myGroup.tags);

        // C153176-5496 | Get the recipients of the group.
        let recipents = null;
        // Populate the recipients only when predictiveRecipients flag is true for the group.
        if (myGroup.predictiveRecipients && myGroup.recipients) {
          recipents = myGroup.recipients.map(rec => ({ text: rec, value: rec, email: rec }));
          this.recipientsMap.set(myGroup.groupName, recipents);
        }
      });

      //Get To,CC,BCC users list
      loginUserInfo.allToCcDBUsers.forEach(toCcBccUsers => {
        let individualRecipent: AllToCcDBUser = { ...toCcBccUsers };
        individualRecipent.email = individualRecipent.value;
        this.allToCcDBUser.push(individualRecipent);
      });

      //Get All groups
      loginUserInfo.allActiveGroups.forEach(activeGroup => {
        this.allActiveGroups.push(activeGroup);
      });

     // this.populateInquirySource();

      // Once all the drop down is loaded successfully. Populate the dropdown as per default group
      let groupName = this.parentForm.get('groupName').value;
      if (groupName) {
        this.populateRequestType(groupName);
        this.populateProcessingRegion(groupName);
        this.populateRootCause(groupName);
        this.populateTags(groupName);

        // C153176-5496 | Populate the recipients for the group, that will availbale as suggestion.
        this.populateRecipients(groupName);
        this.group.emit(groupName);
      }
    });
    this.groupNames = groupNameTmp;
    // // C153176-5197: auto select first group if there is one
    // if (!this.selectedGroupName && (this.groupNames.length > 1)) {
    //   // C153176-5197: select the first group
    //   let defaultGroup: any = this.groupNames[1];
    //   this.selectedGroupName = defaultGroup.value;
    //   this.onGroupNameChange(this.groupNames[1]);
    // }
  }

  // C153176-4930: refactored method, used by multiple callers
  getFilteredToCCUsers(query) {
    let filtered = [];

    this.allToCcDBUser.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0) || (record.id.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    this.allActiveGroups.forEach(record => {
      if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
        filtered.push(record);
      }
    });

    //C153176-5496 | External Recipents for suggestion.
    if (this.extRecipients) {
      this.extRecipients.forEach(record => {
        if ((record.text.toLowerCase().indexOf(query.toLowerCase()) >= 0)) {
          filtered.push(record);
        }
      });
    }
    return filtered;
  }

  filterUsers(event) {
    let query = event.query;
    // C153176-4930: refactored method
    this.filteredToUsersMultiple = this.getFilteredToCCUsers(query);
  }

  popup = "";

  showPopup(event, field, overlaypanel) {
    this.popup = field;
    overlaypanel.toggle(event);
    this.showContact = true;
  }

  onBlur(event: any, field) {
    // C153176-2906, C153176-4930: upon leaving the field, handle case of external email without breaking suggesstion handling
    let tokenInput = event.srcElement as any;
    let email = tokenInput.value.trim();
    // first check whether the current input match any suggestions
    let suggestions = this.getFilteredToCCUsers(email);
    if (!suggestions || !suggestions.length) {
      // C153176-4930: does NOT have any suggestions, check whether it's a valid external email. If yes, take it.
      // C153176-4930: check / accept internal email too
      this.closeFlagInvalid = false;
      let extEmail = this.isExternalEmailInToCc(email);
      if (email) {
        this.handleEmail(tokenInput, email, field);
        if (!this.closeFlagInvalid) {
          // C153176-4930: if the closeFlagInvalid is false, the input is valid email id(s)
          if (extEmail) {
            this.secure.emit(true);
          } else {
            this.secure.emit(false);
          }
          // !important to return now so as to keep the input field
          return;
        }
      }
      // C153176-4930: in other cases
      event.srcElement.value = "";
    } else {
      // C153176-4930: has suggestions, clear the field
      event.srcElement.value = "";
      this.resizeMailBody.emit(true);
    }
  }

  onKeyUp(event: KeyboardEvent, field) {
    let extEmail: boolean = false;
    this.closeFlagInvalid = false;
    if (event.key === "Enter") {
      let tokenInput = event.srcElement as any;
      let email = tokenInput.value.trim();
      // C153176-4930: refactoring the processing of email input to separate method 'handleEmail'
      extEmail = this.isExternalEmailInToCc(email);
      this.handleEmail(tokenInput, email, field);
    }
    if (extEmail) {
      this.secure.emit(true);
    } else {
      this.secure.emit(false);
    }
  }

  /**
   * C153176-4930: refactored method, handling user input on To/Cc/Bacc, shared between onBlur and onKeyUp
   */
  handleEmail(tokenInput, Temail, field) {
    this.filteredToUsersMultiple = [];
    Temail = Temail.split(';');
    let invalid = [], email = [];
    let isNonQmaCitiUser : boolean = false;
    email = Temail.filter(value => {
      value = value.trim();

      //test and extract email from <foo@bar.com>
      if (/\<([^\>]+)\>/g.test(value)) {
        let tempArr = /\<([^\>]+)\>/g.exec(value);
        value = tempArr[1];
      }

      // C153176-5006 : If User enter soeid who has access to QMA application
      const appUser = this.allToCcDBUser.find(user => user.id === value);
      if (appUser) {
        return true;
      }

      //check for non QMA citi user
      isNonQmaCitiUser = this.validateNonQmaCitiUserSoeId(value);
      if (isNonQmaCitiUser) {
        return true;
      }
      // Push invalid email or soied to display invalid message.
      if (value !== "" && !this.email_regex.test(value)) { // C153176-5262: refactor regex
        invalid.push(value);
        return false;
      } else {
        return true;
      }
    });
    if (invalid.length > 0) {
      tokenInput.value = "";
      this.closeFlagInvalid = true;
      setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
    }

    if (email.length) {
      email.forEach(async element => {
        element = element.trim();
        if (/\<([^\>]+)\>/g.test(element)) {
          let tempArr = /\<([^\>]+)\>/g.exec(element);
          element = tempArr[1];
        }
        if (element !== "") {
          let item = new AllToCcDBUser();

          // C153176-5006 : If User enter soeid who has access to QMA application
          const appUser = this.allToCcDBUser.find(user => user.id === element);
          if (appUser) {
            item = { ...appUser };
          } else if(isNonQmaCitiUser) {
          // check for non QMA and Citi user
          const nonQmaCitiUser = await this.nonQmaCitiUser(element, field);
          if(nonQmaCitiUser){
            item = { ...nonQmaCitiUser };
          } else {
            tokenInput.value = "";
            this.closeFlagInvalid = true;
            setTimeout(() => { this.closeFlagInvalid = false; }, 3000);
            return;
          }
        } else {
            item = { text: element, value: element, email: element, id: '', active: false };
          }

          switch (field) {
            case "to":
              if (!this.isDuplicate(item, 'to'))
                this.toUsersList.push(item);
              this.parentForm.get('toUsers').patchValue(this.toUsersList);
              tokenInput.value = "";
              break;
            case "cc":
              if (!this.isDuplicate(item, 'cc'))
                this.ccUsersList.push(item);
              this.parentForm.get('ccUsers').patchValue(this.ccUsersList);
              tokenInput.value = "";
              break;
            case "bcc":
              if (!this.isDuplicate(item, 'bcc'))
                this.bccUsersList.push(item);
              this.parentForm.get('bccUsers').patchValue(this.bccUsersList);
              tokenInput.value = "";
              break;

          }
        }
      });
    }
  }
  

  onHideContactPopUp(e: any) {
    this.query = '';
    this.isClosed = true;
  }
  toggleAdd: boolean = false;
  toggleMoreFields: boolean = false;
  showHideAddNote() {
    this.toggleAdd = !this.toggleAdd;
    let addFields = {
      fileldFlag: this.toggleAdd,
      fieldName: "addNote"
    }
    this.resizeMailBody.emit(addFields);
  }
  showHideMoreFields() {
    this.toggleMoreFields = !this.toggleMoreFields;
    let moreFields = {
      fileldFlag: this.toggleMoreFields,
      fieldName: "moreField"
    }
    this.resizeMailBody.emit(moreFields);
  }
 // Jira : C153176-5194 Mobile -To/CC/ BCC changes
  showccbcc(){
    this.toggleccbcc = !this.toggleccbcc;
  }

  cancelAddNote() {
    this.toggleAdd = false;
    this.note = '';
    this.parentForm.get('note').setValue('');
    let addFields = {
      fileldFlag: this.toggleAdd,
      fieldName: "addNote"
    }
    this.resizeMailBody.emit(addFields);
  }

  callGFIDWindow() {
    this.GfidSearchComponent.defaultVal = this.parentForm.get('gfcId').value;
    this.GfidSearchComponent.triggerFalseClick();
  }

  getGFID(val: any) {
    //  document.getElementById("gfpidgfcid").innerHTML = val.gfpId + " / " + val.gfcId;
    this.gfpidgfcid = val.gfpId + " / " + val.gfcId;;
    this.parentForm.get('gfcId').setValue(val.gfcId);
    this.parentForm.get('gfcName').setValue(val.gfcName);
    this.parentForm.get('gfpId').setValue(val.gfpId);
    this.parentForm.get('gfpName').setValue(val.gfpName);

    this.messageService.add({ key: 'custom', summary: 'Success Confirmation', detail: 'Record added successfully !' });
  }

  onReject() {
    this.messageService.clear('c');
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.getQueryCountValidation();
    }, 200);
    //this.getAttachmentData();
  }

  /**
   * Method to add note in new message.
   */
  addNote(): void {
    this.toggleAdd = false;
    const addedNote = this.parentForm.get('note').value;
    console.log("Entered note : ", addedNote);

    let currentDate = new Date();
    let addNote = {
      commentDate: currentDate.getTime(),
      userName: this.userName,
      comments: addedNote
    };

    this.parentForm.get('note').setValue('');
    if (addedNote) // Jira : 4634 - blank note will not be added.
      this.notesList.push(addNote);
    this.notesEvent.emit(this.notesList);
    let addFields = {
      fileldFlag: this.toggleAdd,
      fieldName: "addNote"
    }
    this.resizeMailBody.emit(addFields);
  }

  downloadAttachment(docId: string, docName: string, convId: number, groupId: number, hasNominatedOwnership: boolean): void {
    this.inboxService.downloadAttachment(docId, docName, convId, groupId, hasNominatedOwnership);
  }

  isExternalEmailInToCc(email): boolean {
    let isExternal: boolean = false;
    if (!AppUtils.isUndefinedOrNull(email)) {

      // C153176-5006 : If User enter soeid who has access to QMA application
      let emailList = email.split(';');
      emailList.forEach(emailValue => {
        emailValue = emailValue.trim();
        const appUser = this.allToCcDBUser.find(user => user.id === emailValue);
        let isNonQmaCitiUser = this.validateNonQmaCitiUserSoeId(emailValue);
        if (emailValue && !AppUtils.isCitiDomainEmail(emailValue, this.citiDomains) && !appUser && !isNonQmaCitiUser) {
          isExternal = true;
        }
      });
    }
    return isExternal;
  }

  getContactStatus(value) {
    if (!isNaN(+value)) {
      return "bold";
    } else if (this.isExternalEmailInToCc(value)) {
      return "external";
    } else {
      return "";
    }
  }

  onShowContactPopUp(e: any) {
    this.query = '';
    this.isClosed = false;
  }

  trackById(index: number, item: any) {
    return item.id;
  }

  allowDrop(event) {
    event.preventDefault();
  }

  drag(event, emailField, data) {
    let jsonObject = JSON.stringify(data);
    event.dataTransfer.setData("data", jsonObject);
    event.dataTransfer.setData("emailField", emailField);
  }

  drop(event, emailField) {
    event.preventDefault();
    if (this.isInquiryDrop(event, emailField)) {
      return this.handleInquiryDrop(event);
    }
    if (emailField !== "parent") {
      // C153176-5343 - null check on data from drop dataTransfer object
      let data = event.dataTransfer.getData("data");
      let obj = data? JSON.parse(data) : undefined;
      if (obj) {
        let setEmailField = event.dataTransfer.getData("emailField");
        this.popEmailAddress(obj, setEmailField);
        this.pushToTo(obj, emailField);
      } else {
        let setEmailField = event.dataTransfer.getData("emailField");
        obj = event.dataTransfer.items;
        // C153176-5343: support multiple items in dataTransfer obj
        for (let i = 0; i < obj.length; i++) {
          let item = obj[i].getAsString((s) => {
            if (s)  {
              this.popEmailAddress(s, setEmailField);
              this.pushToTo(s, emailField);
            }
          });
        }
      }
    }
  }

  popEmailAddress(emailObject, dragEmail) {

    let emailIndex = -1;
    switch (dragEmail) {
      case 'to':
        emailIndex = this.toUsersList.findIndex(x => x.value === emailObject.value);
        if (emailIndex > -1) {
          this.toUsersList.splice(emailIndex, 1);
        }
      case 'cc':
        emailIndex = this.ccUsersList.findIndex(x => x.value === emailObject.value);
        if (emailIndex > -1) {
          this.ccUsersList.splice(emailIndex, 1);
        }
      case 'bcc':
        emailIndex = this.bccUsersList.findIndex(x => x.value === emailObject.value);
        if (emailIndex > -1) {
          this.bccUsersList.splice(emailIndex, 1);
        }
    }

  }

  mandatoryForResolveParametersForGroupName(grpName) {
    this.mandatoryForResolveFlags = [];
    let isRootCauseMandatory = false;
    let isRootCauseMandatoryWOReply = false;
    let isProcessingRegionMandatory = false;
    let isInqSourceMandatory = false;
    let isGfidMandatory = false;
    var isTagMandatory = false;

    if (!AppUtils.isUndefinedNullOrBlank(grpName)) {
      this.userDataService.LocalGetLoginUserInfo().subscribe(loginUserInfo => {
        loginUserInfo.myGroups.forEach(fromData => {
          if (!AppUtils.isUndefinedNullOrBlank(fromData) && !AppUtils.isUndefinedNullOrBlank(fromData.groupName) && fromData.groupName.toUpperCase() == grpName.toUpperCase()) {
            if (fromData.isRootCauseMandatory) {
              isRootCauseMandatory = true;
              if (fromData.isRootCauseMandatoryWOReply) {
                isRootCauseMandatoryWOReply = true;
              }
            }
            if (fromData.isProcessingRegionMandatory) {
              isProcessingRegionMandatory = true;
            }
            if (fromData.isInquirySourceMandatory) {
              isInqSourceMandatory = true;
            }
            if (fromData.isGfidMandatory) {
              isGfidMandatory = true;
            }
            if (fromData.isTagMandatory) {
              isTagMandatory = true;
            }
          }
        });
      });
    }
    this.mandatoryForResolveFlags.push({
      isRootCauseMandatory: isRootCauseMandatory,
      isProcessingRegionMandatory: isProcessingRegionMandatory,
      isInqSourceMandatory: isInqSourceMandatory,
      isRootCauseMandatoryWOReply: isRootCauseMandatoryWOReply,
      isGfidMandatory: isGfidMandatory,
      isTagMandatory: isTagMandatory
    });
  }

  validateFormFields(resolveFlag): boolean {
    this.mandatoryForResolveParametersForGroupName(this.parentForm.value.groupName);
    let valid = false;

    if (resolveFlag === "Y") {
      if (this.mandatoryForResolveFlags[0].isProcessingRegionMandatory &&
        (this.parentForm.controls["processingRegion"].untouched || this.parentForm.controls["processingRegion"].pristine) &&
        (this.isNull(this.parentForm.controls["processingRegion"].value))) {
        this.parentForm.controls["processingRegion"].setValidators(Validators.required);
        this.parentForm.controls["processingRegion"].setErrors({ notUnique: true });
        this.parentForm.controls["processingRegion"].updateValueAndValidity();
        console.log('Processing region should be mandatory');
        valid = true;
      }
      else {
        this.parentForm.get("processingRegion").clearValidators();
        this.parentForm.get("processingRegion").setErrors(null);
        this.parentForm.get("processingRegion").updateValueAndValidity();
      }

      if ((this.mandatoryForResolveFlags[0].isRootCauseMandatory || this.mandatoryForResolveFlags[0].isRootCauseMandatoryWOReply) &&
        (this.parentForm.controls["rootCause"].untouched || this.parentForm.controls["rootCause"].pristine) &&
        (this.isNull(this.parentForm.controls["rootCause"].value))) {
        this.parentForm.controls["rootCause"].setValidators(Validators.required);
        this.parentForm.controls["rootCause"].setErrors({ notUnique: true });
        this.parentForm.controls["rootCause"].updateValueAndValidity();
        console.log('Root Cause should be mandatory');
        valid = true;
      }
      else {
        this.parentForm.get("rootCause").clearValidators();
        this.parentForm.get("rootCause").setErrors(null);
        this.parentForm.get("rootCause").updateValueAndValidity();
      }

      if (this.mandatoryForResolveFlags[0].isInqSourceMandatory && (this.parentForm.controls["inquirySource"].untouched || this.parentForm.controls["inquirySource"].pristine)) {
        this.parentForm.controls["inquirySource"].setValidators(Validators.required);
        this.parentForm.controls["inquirySource"].setErrors({ notUnique: true });
        this.parentForm.controls["inquirySource"].updateValueAndValidity();
        console.log('Inquiry Source should be mandatory');
        valid = true;
      }
      else {
        this.parentForm.get("inquirySource").clearValidators();
        this.parentForm.get("inquirySource").setErrors(null);
        this.parentForm.get("inquirySource").updateValueAndValidity();
      }

      let isGfidMandatory = this.mandatoryForResolveFlags[0].isGfidMandatory;
      if (isGfidMandatory && this.isNull(this.parentForm.value.gfpId) && this.isNull(this.parentForm.value.gfcId)) {
        console.log("GFPID/GFCID is mandatory for resolve");
        this.parentForm.controls["gfpId"].setValidators(Validators.required);
        this.parentForm.controls["gfpId"].setErrors({ notUnique: true });
        this.parentForm.controls["gfpId"].updateValueAndValidity();
        this.parentForm.controls["gfcId"].setValidators(Validators.required);
        this.parentForm.controls["gfcId"].setErrors({ notUnique: true });
        this.parentForm.controls["gfcId"].updateValueAndValidity();
        this.toggleMoreFields = false;
        this.showHideMoreFields();
        valid = true;
      }
    }
    else if (resolveFlag === "N") {
      this.parentForm.get("processingRegion").clearValidators();
      this.parentForm.get("processingRegion").setErrors(null);
      this.parentForm.get("processingRegion").updateValueAndValidity();

      this.parentForm.get("rootCause").clearValidators();
      this.parentForm.get("rootCause").setErrors(null);
      this.parentForm.get("rootCause").updateValueAndValidity();

      this.parentForm.get("inquirySource").clearValidators();
      this.parentForm.get("inquirySource").setErrors(null);
      this.parentForm.get("inquirySource").updateValueAndValidity();

      this.parentForm.get("gfpId").clearValidators();
      this.parentForm.get("gfpId").setErrors(null);
      this.parentForm.get("gfpId").updateValueAndValidity();

      this.parentForm.get("gfcId").clearValidators();
      this.parentForm.get("gfcId").setErrors(null);
      this.parentForm.get("gfcId").updateValueAndValidity();

      this.toggleMoreFields = true;
      this.showHideMoreFields();
    }
    if (this.parentForm.controls.requestType.errors && (this.parentForm.controls.requestType.pristine || this.parentForm.controls.requestType.untouched))
      valid = true;
    return valid;
  }

  getResolveEnquiry() {
    try {
      if (this.resolve && !this.isNull(this.resolve))
        this.validateFormFields(this.resolve);
    } catch (e) {
    }
  }

  isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getQueryCountValidation() {
    this.newMessageService.getQueryCountValidation().subscribe(message => {
      try {
        if (message !== "test") {
          this.queryCountMsg = message;
        }
        else {
          this.queryCountMsg = '';
        }
      } catch (e) {
      }
    });
  }

  callCmcSearchWindow() {
    this.cmcsearchComponent.loadCMCSearch(this.popup, this.loggedInUser);
    /* this.displayCMCSearchPopup = true; */
   /*  this.cmcsearchComponent.display = true; */
  }

  getCmcContactData(cmcData) {
    cmcData.selectedContacts.forEach(data => {
      let item = {
        id: '',
        active: false,
        text: data.text,
        value: data.value,
        email: data.value
      }
      if (this.isExternalEmailInToCc(data.value))
        this.secure.emit(true);
      this.pushToTo(item, cmcData.toCcBcc);
    });
  }

  onClear(event) {
    console.log('event :', event);
  }

  /**Right Click method for open panel */
  //C153176-4229 - to get current field  
  addToTop(event, overlaypanel: OverlayPanel, value: any, field): void {
    this.addOptions = [];
    this.addOptions.push({ name: "Add To Top Contacts" }, { name: "Show Member List" });
    let jsonObject = value;
    this.setData = jsonObject;
    this.currentField = field; //C153176-4229 - to get current field
    //event.dataTransfer.setData("data", jsonObject);   
    overlaypanel.toggle(event);
  }

  // C153176-5746 : Unable to add external contact using right click add to top contact
  /** To add the contacts in Top contacts */
  addTopContacts(event) {
    // C153176-5746 : Unable to add external contact using right click add to top contact
    let selectedGroup = this.parentForm.get('groupName').value;
    let groupIdList = this.userDataService.getAssignedGroupIdList();
    let topContacts = this.userDataService.getTopContacts();
    let nxtIdx = topContacts.length;

    // New top contact object.
    let items = {
      name: this.setData.text,
      email: this.setData.email == null ? this.setData.value : this.setData.email,
      mapToGroupsName: selectedGroup ? selectedGroup : "",
      index: nxtIdx,
      sharedToGroupsName:"",
      createdBy:  this.userDataService.getLoggedInUser()
    };

    let updatedTopContacts = topContacts.concat([items]);
    let topContact = new TopContacts(updatedTopContacts, groupIdList);

    // Service call to add contact as top contact.
    this.newMessageService.saveTopContact(topContact).subscribe(result => {
      if (result.processingstatus === "true") {
        // Update top Contacts to log in user.
        this.userDataService.updateFavoriteContacts(updatedTopContacts);
        console.log('Contact is saved as top contact successfully : ' + JSON.stringify(items));
        this.contactSuccess = true;
        this.topContactAddEvent.emit(items);
      }
    }, (error) => {
      console.error('Failed to save contact as top contact', error);
      this.contactError = true;
    });
  }

  closeModal(_str:any){
    if(_str == "success"){this.contactSuccess = false;}
    if(_str == "error"){this.contactError = false;}
  }  


  showMemberList(groupDetail: AllActiveGroup): void {
    let groupMemberList: GroupMemberContact[] = [];      
    
    let groupData = {
      'groupName': this.setData.text
    };

    this.userDataService.getGroupMemberList(groupData).subscribe(groupMemberDetails => {
      if (groupMemberDetails && groupMemberDetails !== null) {
        this.displaySuccess = true;
        groupMemberDetails.forEach(memberContact => {
          groupMemberList.push({ name: memberContact.userName, email: memberContact.email });
        });
        groupMemberList.forEach(item => {
          this.groupMemberList.push({ label: item.name, name: item.name, value: item.name, email: item.email })
        })
      } else if (groupMemberDetails === null) {
        this.displaySuccess = true;
        this.noMember = true;
      }
      this.groupMembers = [];
      //this.groupMemberList = groupMemberList; 
      this.checkboxlistcomp.items = this.groupMemberList;
      this.checkboxlistcomp.assignCopy();
    }, error => {
      //this.errors = error;
      this.displayError = true;
    });
    groupDetail.toggle = !groupDetail.toggle;
  }
  cancelTopContact() {
    this.displaySuccess = false;
    this.groupMemberList = [];

    // When there is no member in the list, so checkbox would not be available.
    if(this.checkboxlistcomp){
      this.checkboxlistcomp.items = this.groupMemberList;
      this.checkboxlistcomp.assignCopy();
    }
  }

  addToTopContact() {    
    //C153176-4229 - add the show member list contacts
    this.selectedMember.forEach(member => {
      let contact = new AllToCcDBUser();
      contact.text = member['name'];
      contact.value = member['email'];
      contact.email = member['email'];
      this.pushToTo(contact, this.currentField);
    });
    let index = this.toUsersList.findIndex((item:any) => item.text == this.setData.text);
    this.toUsersList.splice(index, 1);   
    this.displaySuccess = false;
    this.groupMemberList = [];
    
    // When there is no member in the list, so checkbox would not be available.
    if (this.checkboxlistcomp) {
      this.checkboxlistcomp.items = this.groupMemberList;
      this.checkboxlistcomp.assignCopy();
    }
    this.selectedMember = []; //C153176-4229 - remove selected member
  }
  
  getSelectedMember(selectedMember) {
    if (selectedMember.checkedStatus) {
      this.selectedMember.push({ name: selectedMember.value, email: selectedMember.email });
    } else {
      let index = this.selectedMember.map(function (member) { return member.value }).indexOf(selectedMember.value);
      this.selectedMember.splice(index, 1);
    }
  }
  OkToClose() {
    this.displayError = false;
  }

  /**
   * Method to get the disclaimer as per group name
   */
  getDisclaimerByGroup(groupName: string): string {
    const disclaimer = this.userDataService.getDisclaimerForNewInquiry(groupName);
    return '<div id="disclaimerDiv"> ' + disclaimer + '</div>';;
  }


  expand() {
    const templateData = [
      {
        templateContent: this.editorData.getData(),
        clcContent: null,
        templateSubject: this.parentForm.value.subject,
        toList: this.parentForm.value.toUsers,
        ccList: this.parentForm.value.ccUsers,
        bccList: this.parentForm.value.bccUsers,
        nlpContent: null // C153176-5420: Placeholder should not be there if NLP content.
        // inquiryId:conversation.inquiryId
      }
    ];

    const recipientData = {
      from: this.parentForm.value.groupName,
      queryCount: this.parentForm.value.queryCount,
      requestType: this.parentForm.value.requestType,
      inquirySource: this.parentForm.value.inquirySource,
      processingRegion: this.parentForm.value.processingRegion,
      rootCause: this.parentForm.value.rootCause,
      tag: this.parentForm.value.tags,
      gfcName: this.parentForm.value.gfcName,
      gfcid: this.parentForm.value.gfcId,
      gfpName: this.parentForm.value.gfpName,
      gfpid: this.parentForm.value.gfpId,
      fromWhere: 'messages'
    };

    const expReplyObject = {
      templateData: templateData,
      content: this.editorData.getData(),
      recipientData: recipientData,
      attachments: this.parentForm.value.attachments
    };

    this.expandEvent.emit(expReplyObject);
  }

  isInquiryDrop(e, emailField) {
    // TBD will use 'emailField' if needed
    const data = e.dataTransfer.getData('text');
    return data && data.startsWith(QmaConstant.QMA_DND_HEADER);
  }

  handleInquiryDrop(e) {
    const data = e.dataTransfer.getData('text');
    this.editorData.sendEvent.emit({ data: data, action: 'drop' });
    this.inboxService.triggerInquiryDrag({ qma: true, active: false });
   // this.parentComponent.dropInProcessing = true;
    e.stopPropagation();
  }
  hideCcBcc() {
    this.isChevronUp = !this.isChevronUp;
    this.resizeMailBody.emit('mobile');   //c-4475 -newmessage mobile tinymce height&width
  }

  //Listen to Form changes 
  onValueChanges(): void {
    this.parentForm.valueChanges.subscribe(val => {
      this.newMessageData.emit(val);
    })

  }

  // display contact info on hover to contact
  displayContactInfo(event, opContactInfo: OverlayPanel, value: any) {
    if(this.GDIR_DISPLAY_INFO)
      if (value && value.id) {
        this.selectedContactId = value.id.toUpperCase();
        opContactInfo.toggle(event);
      }
    }

  // C153176-5302 : Auto Population if single data is available - Request Type
  getRequestType(recdata: any): string {
    this.populateRequestType(recdata.from);
    let requestType = this.getValidatedRequestType(recdata.requestType);
    if (this.requestTypes.length === this.countCheck && this.isNull(requestType)) {
      requestType = this.requestTypes[1].value;
    }
    return requestType;
  }

   // C153176-5302 : Auto Population if single data is available - Inquiry Source
  getInquirySrc(recdata: any): string {
    this.populateInquirySource();
    let inquirySrc = recdata.inquirySource || "";
    if(this.inquirySourceList.length === this.countCheck && this.isNull(inquirySrc)){
      inquirySrc = this.inquirySourceList[1].value;
    }
    return inquirySrc;
  }

  // C153176-5302 : Auto Population if single data is available - Processing Region
  getProcessingRegion(recdata: any): string {
    this.populateProcessingRegion(recdata.from);
    let processRegion = recdata.processingRegion || "";
    if(this.processingRegions.length === this.countCheck && this.isNull(processRegion)){
      processRegion = this.processingRegions[1].value;
    }
    return processRegion;
  }

  // C153176-5302 : Auto Population if single data is available - Root Cause
  getRootCause(recdata: any): string {
    this.populateRootCause(recdata.from);
    let rootCause = recdata.rootCause || "";
    if(this.rootCauses.length === this.countCheck  && this.isNull(rootCause)){
      rootCause = this.rootCauses[1].value;
    }
    return rootCause;
  }

  // validate soeid and user name
  validateNonQmaCitiUserSoeId(value: string): boolean {
    return this.userNameAndSoeIdRegEx.test(value);//result;
  }

  //get non QMA Citi user 
  async nonQmaCitiUser(element: string, field: string) : Promise<AllToCcDBUser> {
    let citiUser = null;
    try {
      let auId = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.auid;
      let contactInfoEndPoint = this.userDataService.loggedInUserInfo.globalDirectoryConfig.contactInfoConfig.contactInfoEndPoint;

      const suggestionData = await this.globalDirectoryService.getContactSuggestion(element.toUpperCase(), "BASIC", auId, contactInfoEndPoint).toPromise();
      
      let jsonObj = this.globalDirectoryService.parseXmlToJson(suggestionData.toString());
      if(jsonObj && jsonObj.people && jsonObj.people.Person && jsonObj.people.Person instanceof Array){
        //show popup
        console.log('show popup');
        this.displaySuggestion = true;
        this.suggestionField = field;
        this.suggestionRecords = jsonObj['people']['Person'];
      }else if(jsonObj && jsonObj.people && jsonObj.people.Person && jsonObj.people.Person instanceof Object){
        let personObj = jsonObj['people']['Person'];
        citiUser = new AllToCcDBUser();
        citiUser.value = citiUser.email = personObj['EmailAddress']['_text'];
        if(citiUser.value.length === 0){
          citiUser = null;
          return citiUser;
        }
        citiUser.id = personObj['SoeId']['_text'];
        citiUser.active = false;
        citiUser.text = personObj['Name']['_text'];
      }
    } catch (error) {
      console.error('Error in nonQmaCitiUser ', error);
      citiUser = null;
    }
    return citiUser;
  }

  addSuggestionToRecipient(citiUser:AllToCcDBUser){
    if(citiUser && citiUser.value && citiUser.value.length > 0){
      switch (this.suggestionField) {
        case "to":
          if (!this.isDuplicate(citiUser, 'to'))
            this.toUsersList.push(citiUser);
          this.parentForm.get('toUsers').patchValue(this.toUsersList);
          break;
        case "cc":
          if (!this.isDuplicate(citiUser, 'cc'))
            this.ccUsersList.push(citiUser);
          this.parentForm.get('ccUsers').patchValue(this.ccUsersList);
          break;
        case "bcc":
          if (!this.isDuplicate(citiUser, 'bcc'))
            this.bccUsersList.push(citiUser);
          this.parentForm.get('bccUsers').patchValue(this.bccUsersList);
          break;
      }
    }
    
    this.displaySuggestion = false;
  }

  /**
   * C153176-5896 | On Remove Recipient.
   * 
   * Method to call on unselect recipient from the recipient.
   */
  onRemoveRecipient(recipient: any): void {
    console.log("Remove Recipent : ", recipient);
    this.onUpdateRecipient(recipient);
  }

  /**
   * Method to select the recipient from suggestion list.
   * 
   * @param recipient 
   */
  onSelectRecipient(recipient: any): void {
    console.log("Select Recipent : ", recipient);
    this.onUpdateRecipient(recipient);
  }

  /**
   * Method to update recipient.
   * 
   * @param recipient 
   */
  onUpdateRecipient(recipient: any): void {
    try {
      let extEmail = this.isExternalEmailInToCc(recipient.email);
      if (extEmail) {
        this.isRecUpdated = !this.isRecUpdated;
        this.recipientUpdateEvent.emit(this.isRecUpdated);
      }
    } catch (e) {
      console.error("Fail to fetch email : ", e);
      this.isRecUpdated = !this.isRecUpdated;
      this.recipientUpdateEvent.emit(this.isRecUpdated);
    }
  }
}
