import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { QmaConstant } from "src/app/constant/qma-constant";
import { GroupAdminDetails } from "src/app/model/group-admin/groupAdminDetails";
import { Observable } from "rxjs";
import { SaveInquiryRules } from 'src/app/model/group-admin/saveInquiryRules';

const httpFormPostOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://dev-qmaui.dev.qwyn.deltacapita.net',
    'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
    'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
    'Access-Control-Allow-Credentials': 'true'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GroupAdminService {

  groupURL: string;
  userURL: string;

  constructor(private http: HttpClient) {
    this.groupURL = QmaConstant.BASE_URL + '/group';
    this.userURL = QmaConstant.BASE_URL + '/users';
  }

  /**
   * Method to save the group admin details.
   * 
   * @param groupAdminDetails - Group Admin Details.
   */
  saveGroupData(groupAdminDetails: GroupAdminDetails): Observable<any> {
    return this.http.post(this.groupURL + '/saveGroupData', JSON.stringify(groupAdminDetails), httpFormPostOptions);
  }

  saveInquiryData(saveInquiryRules: SaveInquiryRules): Observable<any> {
    return this.http.post(this.groupURL + '/saveInquiryGroupRules', JSON.stringify(saveInquiryRules), httpFormPostOptions);
  }

  /** added for show release note--- start C153176-4567 */
  saveReleaseData(): Observable<any> {
    return this.http.post(this.userURL + '/saveCurrentReleaseDocumentName', httpFormPostOptions);
  }
  /** added for show release note ---end C153176-4567 */

  getValidateBulkUsers(requestObj: any): Observable<any> {
    return this.http.post(this.userURL + '/validateBulkUsers', requestObj, httpFormPostOptions);
  }

  getBulkAssignUsersToGroups(requestObj: any): Observable<any> {
    return this.http.post(this.groupURL + '/bulkAssignUsersToGroups', requestObj, httpFormPostOptions);
  }

  getUserDataById(requestObj: any): Observable<any> {
    let body = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.http.post(this.userURL + '/getUserData', requestObj, httpFormPostOptions);
  }

  getGroupDataByGroup(groupName): Observable<any> {
    let requestObj = {
      'groupName': groupName
    };
    let req = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.http.post(this.groupURL + '/getGroupDataById', req, httpFormPostOptions);
  }

  getGroupDataEntitlement(groupName): Observable<any> {
    let requestObj = {
      'groupName': groupName
    };
    let req = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.http.post(this.groupURL + '/getGroupData', req, httpFormPostOptions);
  }

  updateGroupRecipient(requestObj: any): Observable<any> {
    return this.http.post(this.groupURL + '/updateGroupRecipient', requestObj, httpFormPostOptions);
  }
  saveGroupAdminInfo(formData) {
    return this.http.post(this.groupURL + '/saveGroupAdminInfo', formData, httpFormPostOptions);
  }
  //C153176-5633 regarding restore button implementation for restore rules
  getGroupRestoreRulesDataById(groupName): Observable<any> {
    let requestObj = {
      'groupName': groupName
    };
    let req = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.http.post(this.groupURL + '/getGroupRestoreRulesDataById', req, httpFormPostOptions);
  }

  saveUserAdminData(requestObj: any) {
    let req = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.http.post(this.userURL + '/saveUserAdminData', req, httpFormPostOptions);
  }
  saveUserGroupsBulk(requestObj: any) {
    //let req = "request=" + encodeURIComponent(JSON.stringify(requestObj));
    return this.http.post(this.groupURL + '/saveUserGroupsBulk', JSON.stringify(requestObj), httpFormPostOptions);
  }
  getAllActiveDBGroups(): Observable<any> {
    return this.http.post(this.groupURL + '/getAllActiveDBGroups', httpFormPostOptions);
  }

}
